<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="addGroupFaculty" v-else>
      <b-form @submit.prevent="enrollFacultyToGroup">
        <b-form-group>
          <multiselect v-model="selectedFaculty" tag-placeholder="Add this as new tag"
            :placeholder="'Add Faculty to ' + groupModal.name" label="name" track-by="code" :options="facultiesOptions"
            :multiple="true" :taggable="true" @tag="addTag" :disabled="submitBtn.disabled"></multiselect>
        </b-form-group>
        <b-form-group>
          <div class="d-flex justify-content-between">
            <b-button variant="primary" size="sm" type="submit" :disabled="submitBtn.disabled"
              v-if="selectedFaculty.length != 0">{{ submitBtn.text }}</b-button>
            <b-button variant="danger" size="sm" @click="$emit('addFacultyStatus', false)"
              :disabled="submitBtn.disabled">Cancel</b-button>
          </div>
        </b-form-group>
      </b-form>
    </div>
  </div>
</template>

<script>
export default {
  props: ["groupModal", "listOfUsersEnrolled"],
  async created() {
    this.fetchData = true;
    await this.getExistingFacultyObject();
    await this.getFacultyDetails();
    this.fetchData = false;
  },
  data() {
    return {
      fetchData: false,
      facultiesOptions: [],
      selectedFaculty: [],
      faculty: [],
      existingFaculty: {},
      submitBtn: {
        text: "Submit",
        disabled: false,
      },
    };
  },
  methods: {
    async enrollFacultyToGroup() {
      this.submitBtn = {
        text: "Please wait....",
        disabled: true,
      };
      let facultyData = {
        maingroup_id: this.groupModal.id,
        maingrouprole_id: 2,
        institution_users: [],
      };
      this.selectedFaculty.forEach((users) => {
        facultyData.institution_users.push(users.id);
      });

      const url = this.$store.getters.getAPIKey.mainAPI + `/maingroupusers`;

      await this.$axios
        .post(url, facultyData)
        .then((response) => {
          response;
          this.$toast.success(`Users added to ${this.groupModal.name}`, {
            position: "top",
            duration: 3000,
          });
          this.$emit("addFacultyStatus", true);
        })
        .catch((error) => {
          error;
        });
      this.submitBtn = {
        text: "Submit",
        disabled: false,
      };
    },
    async getFacultyDetails() {
      this.fetchData = true;
      const url = this.$store.getters.getAPIKey.instituitionFaculty;
      await this.$axios
        .get(url)
        .then((response) => {
          this.facultiesOptions = [];
          this.faculty = response.data;
          this.faculty.forEach((staff) => {
            if (!this.existingFaculty[staff.id] && staff.enroll_status == 'active') {
              this.facultiesOptions.push({
                name: staff.first_name + " " + (staff.last_name || "") + `( ${staff.email} )`,
                id: staff.id,
                code: staff.id,
              });
            }
          });
          this.facultiesOptions.sort((a, b) => a.name.localeCompare(b.name));
          this.fetchData = false;
          response;
        })
        .catch((error) => {
          this.fetchData = false;
          error;
        });
    },
    async getExistingFacultyObject() {
      this.existingFaculty = {};
      await this.listOfUsersEnrolled.forEach((faculty) => {
        this.$set(
          this.existingFaculty,
          faculty.institution_user_id,
          faculty.institution_user_id
        );
      });
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
        id: newTag,
      };
      this.facultiesOptions.push(tag);
      this.selectedFaculty.push(tag);
    },
  },
};
</script>
