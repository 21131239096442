<template>
  <div>
    <choose-institute :myIinstitutionEnrollmets="myIinstitutionEnrollmets"
      :instituteApprovalPending="getInstitutionsStatus.institionApproval"
      :defaultInstitutionUserId="defaultInstitutionUserId" @defaultInst="defaultInst"
      @addInstitute="$store.state.addInstitute = false"></choose-institute>

    <b-modal id="userDataCompletion" no-close-on-backdrop no-close-on-keyboard no-close-on-esc hide-footer size="lg"
      title="Instititute Questions" centered>
      <UserDataCompleted :currentRole="currentRole" @answersSubmitted="$bvModal.hide('userDataCompletion')"
        @skipUserProfileQuestions="skipUserProfileQuestions" :userDataQuestions="instituteAttachedQuestions" />
    </b-modal>
    <div class="loading" v-if="fetchData || loadInstitutes">
      <load-data></load-data>
    </div>
    <div class="mainContent p-4" v-else>
      <check-institute v-if="instituteCreationStatus || selectedInstitute.approved == 0"
        @closeInstituteCreation="$store.commit('enableInstituteCreation')" :selectedInstitute="selectedInstitute"
        :myIinstitutionEnrollmets="myIinstitutionEnrollmets"
        :instituteApprovalStatus="instituteApprovalStatus"></check-institute>
      <div v-else>
        <b-container id="institutionUsersList" v-if="screenWidth > 600">
          <b-row>
            <b-col :md="12">
              <div id="instituteDash">
                <div class="settings" v-if="getInstituteSettingsStatus">
                  <institution-settings :selectedInstitute="selectedInstitute"
                    @updatedSelectedInstitute="updatedSelectedInstitute"></institution-settings>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
        <b-row no-gutters>
          <b-col md="12" :style="{ display: getInstituteSettingsStatus ? 'none' : 'block' }">
            <div class="div" v-if="screenWidth <= 600">
              <h4 class="text-center text-info" style="font-weight: 600; font-size: 24px" v-b-toggle.instituteSidebar>
                {{ selectedInstitute.name }}
              </h4>
            </div>

            <div class="instituteDetails">
              <div id="instituteShadowEffect">
                <institute-staff-groups @sufficientTabs="sufficientTabs" :instituteSubTab="instituteSubTab"
                  ref="institutionTabList" :selectedInstitute="selectedInstitute"
                  :myIinstitutionEnrollmets="myIinstitutionEnrollmets"
                  :filteredUniqueInstitutions="filteredUniqueInstitutions" :defaultInstituteId="selectedInstitute.id"
                  :defaultInstitutionUserId="defaultInstitutionUserId" :instituitionAdmin="instituitionAdmin"
                  :canInviteUsers="canInviteUsers" :sendInfo="sendInfo"
                  @changeInstituteRole="changeInstituteRole"></institute-staff-groups>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import InstituitionStaffList from "../components/templates/Dashboard/Institute/InstituitionStaffList.vue";
import InstitutionSettings from "../components/templates/Dashboard/Institute/settings/InstitutionSettings.vue";
import CheckInstitution from "../components/templates/Dashboard/Institute/CheckInstitution.vue";
import InstitutePageSidebar from "../components/templates/Dashboard/Institute/InstitutePageSidebar.vue";
import UserDataCompleted from "./UserDataCompleted.vue";
import acl from "../authorization/acl.js";

export default {
  computed: {
    loadInstitutes() {
      return this.$store.getters.getInstituteStatus;
    },
    myIinstitutionEnrollmets() {
      return this.$store.getters.getInstitutes;
    },
    getInstitutionsStatus() {
      return this.$store.getters.getInstitutions;
    },
    instituteCreationStatus() {
      return this.$store.getters.instituteCreationStatus;
    },
    instituteApprovalStatus() {
      return this.$store.getters.getInstitutions.institionApproval;
    },
    selectedInstitute() {
      return this.$store.getters.getCurrentInstitution;
    },
    defaultInstitutionUserId() {
      return this.$store.getters.getDefaultInstituteUserId;
    },
    filteredUniqueInstitutions() {
      return this.$store.getters.getFilteredUniqueInstitutions;
    },
    getInstituteSettingsStatus() {
      return this.$store.getters.getInstituteSettings;
    },
  },

  async created() {
    this.fetchData = true;

    if (this.myIinstitutionEnrollmets.length == 0) {
      await this.$store.dispatch("setUpInstitutes");

    }
    await this.handlePermissions();

    await window.addEventListener("resize", this.handleResize);
    await this.handleResize();

    //------------------------------------------------------------------------
    if (this.institutionUserDataObject.length != 0) {
      return (this.fetchData = false);
    }
    await this.getUserInstitutionEnrolls(); //list of enrollment of current user in diffrent institutions with diffrent roles

    if (!this.instituteApprovalStatus && this.selectedInstitute.approved == 0) {
      return (this.fetchData = false);
    }

    const checkUserProfleQuestions = await this.getAttachedQuestions();

    if (checkUserProfleQuestions) {
      let checkProfiledSkipped = await localStorage.getItem(
        "userProfileSkipped"
      );
      let profileStatus;
      if (checkProfiledSkipped == null) {
        profileStatus = false;
      } else {
        profileStatus = true;
      }

      if (
        this.institutionUserDataObject[this.defaultInstitutionUserId] &&
        !this.institutionUserDataObject[this.defaultInstitutionUserId]
          .user_data_completed &&
        !profileStatus &&
        this.instituteAttachedQuestions.length != 0 &&
        (this.institutionUserDataObject[this.defaultInstitutionUserId].role ==
          "student" ||
          this.institutionUserDataObject[this.defaultInstitutionUserId].role ==
          "teacher" ||
          this.institutionUserDataObject[this.defaultInstitutionUserId].role ==
          "admin")
      ) {
        this.currentRole = this.institutionUserDataObject[
          this.defaultInstitutionUserId
        ].role;
        this.$bvModal.show("userDataCompletion");
      }
    }

    this.fetchData = false;

    this.$emit("currentInstitute", {
      selectedInstitute: this.selectedInstitute,
      instituitionAdmin: this.instituitionAdmin,
    });
  },
  components: {
    "institute-staff-groups": InstituitionStaffList,
    "institution-settings": InstitutionSettings,
    "check-institute": CheckInstitution,
    "choose-institute": InstitutePageSidebar,
    UserDataCompleted,
  },

  data() {
    return {
      inviteInstitutionUsers: false,
      screenWidth: 0,
      mobileView: false,
      canInviteUsers: null,
      sendInfo: null,
      instituteChecking: "",
      fetchData: false,

      instituitionAdmin: null,
      settings: this.$store.getters.defaultInstitute.instituteSettings,
      instituteImage: "",
      institutions: [],
      activeIndex: undefined,

      defaultInstitute: null,
      defaultButton: {
        text: "Default",
        disabled: false,
      },
      defaultInstituteId: null,

      instituteSubTab: null,
      totalUsers: null,
      instituteId: null,

      instituteStatus: false,
      institutionUserDataObject: {},
      currentRole: null,
      instituteAttachedQuestions: [],
    };
  },
  methods: {
    async handlePermissions() {
      let userPermissionsData = localStorage.getItem("userPermissions");
      if (userPermissionsData) {
        localStorage.removeItem("userPermissions");
      }


      const isInstitutionAdmin = await acl.isUserGranted(
        "editInstituitionSettings"
      );
      this.instituitionAdmin = isInstitutionAdmin;

      const canInviteUsers = await acl.isUserGranted("canInviteUsers");

      this.canInviteUsers = canInviteUsers;

      const sendInfo = await acl.isUserGranted("sendSmsEmail");
      this.sendInfo = sendInfo;
      this.$forceUpdate();
    },
    async updatedSelectedInstitute(institute) {
      this.selectedInstitute = await institute;
      this.$store.state.selectedInstitute = await institute;
    },
    async getSmsCountBalance() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        "/institution/setting/sms_balence";
      await this.$axios
        .get(url)
        .then((response) => {
          this.$store.state.smsBalance = response.data;
          response;
        })
        .catch((error) => {
          error;
        });
    },
    skipUserProfileQuestions() {
      localStorage.setItem("userProfileSkipped", true);
      this.$bvModal.hide("userDataCompletion");
    },

    async getAttachedQuestions() {
      let status = null;

      const url =
        this.$store.getters.getAPIKey.mainAPI +
        "/institutionuser/institutionuserquestions";
      await this.$axios
        .get(url)
        .then((response) => {
          this.instituteAttachedQuestions = [];
          let questions = response.data;

          questions.forEach((ques) => {
            if (
              this.institutionUserDataObject[this.defaultInstitutionUserId]
                .role == ques.role
            ) {
              this.instituteAttachedQuestions.push({
                id: ques.id,
                description: ques.description,
                name: ques.name,
                role: ques.role,
                input_attributes: ques.input_attributes,
                institution_attributes: ques.institution_attributes,
                answers: ques.answers,
              });
            }
          });

          status = true;
          response;
        })
        .catch((error) => {
          status = false;

          error;
        });

      return status;
    },
    async changeInstituteRole(roleId) {
      await this.$store.dispatch("setDefaultInstitutionUserId", roleId);
      await this.handlePermissions();
      await this.$store.dispatch("setUpInstitutes");
    },
    async inviteUsers() {
      this.inviteInstitutionUsers = await true;
      await this.inviteInstitutionUsers;
      await this.$refs.inviteUsers.prepareInviteUsers();
    },
    sufficientTabs(value) {
      this.instituteSubTab = value;
    },
    async defaultInst(value) {
      await this.setDefaultInstitutionUserId(value);
    },
    handleResize() {
      this.screenWidth = window.innerWidth;
    },
    async getUserInstitutionEnrolls() {
      await this.myIinstitutionEnrollmets.forEach((user) => {
        this.$set(this.institutionUserDataObject, user.id, {
          role: user.institution_role.name,
          user_data_completed: user.user_data_completed == 1 ? true : false,
        });
      });
    },



    fileSelect(e) {
      this.instituteImage = e.target.files[0];
      this.instituteImage = URL.createObjectURL(this.instituteImage);
    },
  },
};
</script>

<style >
#chooseInstituteMobile {
  display: none;
}

#institutionHeading {
  display: none;
  transition: 0.2s;
}

div #institutionUsersList {
  width: 100%;
  /* Full width */
}

#instituteInfo {
  position: relative;
}

#chooseInstitute {
  position: absolute;
  left: -125px;
  top: 270px;
  transform: rotate(-90deg);
  width: 300px;
  border-style: none;
  color: #0066a6;
  font-size: 24px;
}

.instituteDetails {
  background-color: #eeeeeb;
  border-radius: 5px;
}
</style>
