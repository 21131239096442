<template>
  <div>
    <b-overlay
      :show="loadTeachers"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="md"
    >
      <div class="resetTeachers text-center" v-if="subgroupTeachers.length != 0">
        <b-button variant="danger" @click="subgroupTeacherList()" size="sm"
          >Clear all</b-button
        >
      </div>
      <div class="d-flex mt-3" style="height: 200px">
        <div class="subgroupNames w-50" style="overflow-y: scroll">
          <b-form-checkbox
            size="sm"
            v-for="(subgroup, subgroupIndex) in subgroupsTeachersOptions"
            :key="subgroupIndex"
            v-model="selectedSubgroupsTeachers"
            :value="subgroup.value"
            :disabled="subgroup.disabled"
            @change="getSubgroupTeachers(subgroup.value)"
            >{{ subgroup.text }}</b-form-checkbox
          >
        </div>
        <div
          class="subgroupTeachers w-50"
          v-if="subgroupTeachers.length != 0"
          style="overflow-y: scroll"
        >
          <div class="users d-flex flex-wrap">
            <p
              v-for="(teacher, teacherIndex) in subgroupTeachers"
              :key="teacherIndex"
              class="bg-success p-2 text-white rounded ml-2"
              style="font-size: 12px"
            >
              {{ teacher.course + "-" + teacher.instructor }}
              <span
                class="text-white btn m-0 p-0"
                @click="subgroupTeachers.splice(teacherIndex, 1)"
                ><i class="far fa-times-circle ml-2"></i
              ></span>
            </p>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
export default {
  props: ["subgroupsForTeachers", "surveyId"],
  async created() {
    await this.subgroups;
    await this.subgroupTeacherList();
  },

  data() {
    return {
      loadTeachers: false,
      subgroupsTeachersOptions: this.subgroups,
      selectedSubgroupsTeachers: [],
      subgroupTeachers: [],
    };
  },
  methods: {
    async subgroupTeacherList() {
      this.subgroupsTeachersOptions = [];
      this.subgroupTeachers = [];
      this.selectedSubgroupsTeachers = [];
      this.subgroupsTeachersOptions = this.subgroupsForTeachers;
      this.subgroupsTeachersOptions.forEach((subgroup) => {
        subgroup.disabled = false;
      });
    },
    async prepareSyncTeachersPostData() {
      //Variable defined for API
      const course_users = [];
      await this.subgroupTeachers.forEach((teacher) => {
        course_users.push(teacher.course_user_id);
      });

      //********** Calling Sync Teachers API **********/
      const syncTeachers = await this.syncTeachersWithFeedbackSurvey(course_users);

      //***************API RESPONSE ****************
      //***************SUCCESS RESPONSE ****************
      if (syncTeachers) {
        this.$toast.success(`Linking course teachers successfully done.`, {
          position: "top",
          duration: 3000,
        });
      }
      //***************ERROR RESPONSE ****************
      if (!syncTeachers) {
        this.$toast.error(`Linking course teachers failed, Please try again`, {
          position: "top",
          duration: 3000,
        });
      }
    },
    //Syncing Course Teachers with Survey
    async syncTeachersWithFeedbackSurvey(course_users) {
      let syncUrl = this.$store.getters.getAPIKey.studentFeedbackSurveySyncTeachers;
      const url = syncUrl.replace("survey_id", this.surveyId);
      let status = null;
      await this.$axios
        .post(url, { course_users: course_users, course_teachers: [] })
        .then((response) => {
          status = true;
          response;
        })
        .catch((error) => {
          status = false;
          error;
        });
      return status;
    },
    async getSubgroupTeachers(subgroupId) {
      this.loadTeachers = true;
      const subgroupTeacherId = await this.selectedSubgroupsTeachers.findIndex(
        (subgroup) => {
          return subgroup == subgroupId;
        }
      );
      const subgroupUsersAdded = await this.subgroupsTeachersOptions.findIndex(
        (subgroup) => {
          return subgroup.value == subgroupId;
        }
      );
      const url = `${this.$store.getters.getAPIKey.mainAPI}/usersubgroup/${subgroupId}/courses/withinstructors`;
      await this.$axios.get(url).then((response) => {
        const subgroupUsers = response.data;
        if (subgroupUsers.length == 0) {
          this.$toast.warning("No users found!", {
            position: "top",
            duration: 3000,
          });
          this.selectedSubgroupsTeachers.splice(subgroupTeacherId, 1);
        } else {
          this.subgroupsTeachersOptions[subgroupUsersAdded].disabled = true;
          const checkTeachersExist = [];
          subgroupUsers.forEach((course) => {
            course.institution_users.forEach((instructor) => {
              if (instructor.pivot.courserole_id == 1) {
                this.subgroupTeachers.push({
                  course: course.name,
                  instructor: instructor.first_name,
                  course_user_id: instructor.course_user_id,
                });
                checkTeachersExist.push(instructor.id);
              }
            });
          });
          if (checkTeachersExist.length == 0) {
            this.$toast.warning("No users found!", {
              position: "top",
              duration: 3000,
            });
            this.subgroupsTeachersOptions[subgroupUsersAdded].disabled = false;
            this.selectedSubgroupsTeachers.splice(subgroupTeacherId, 1);
          }
        }
        response;
      });
      this.loadTeachers = false;
    },
  },
};
</script>
