<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="userProfileQuesAns" v-else>
      <div class="questions text-left d-flex flex-wrap justify-content-between">
        <div
          class="question"
          v-for="(ques, quesIndex) in questions[userProfileInfo.id]"
          :key="quesIndex"
        >
          <div class="quesAns">
            <div class="ques font-weight-bolder text-dark">
              {{ ques.name }}
            </div>
            <div class="ans ">
              <span
                class="text-secondary"
                v-if="questionsAnswer[userProfileInfo.id][ques.id] != null"
              >
                {{ questionsAnswer[userProfileInfo.id][ques.id] }}
              </span>
              <span class="text-danger" v-else>
                Not Updated
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["userProfileInfo"],
  async created() {
    this.fetchData = true;
    await this.getUserQuestions();
    await this.getUserAnswers();
    // this.$emit('answerSheet', {})
    this.fetchData = false;
  },
  data() {
    return {
      fetchData: false,
      questions: {},
      answers: [],
      answerSheet: [],
      questionsAnswer: {},
    };
  },
  methods: {
    async getUserQuestions() {
      this.$set(this.questionsAnswer, this.userProfileInfo.id, {});
      this.$set(this.questions, this.userProfileInfo.id, []);
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/institutionusers/${this.userProfileInfo.id}/institutionuserquestions`;
      await this.$axios
        .get(url)
        .then((response) => {
          const questions = response.data;
          questions.forEach((ques) => {
            this.questions[this.userProfileInfo.id].push(ques);
            this.$set(
              this.questionsAnswer[this.userProfileInfo.id],
              ques.id,
              null
            );
          });
          response;
          console.log(this.questions);
        })
        .catch((error) => {
          error;
        });
    },
    async getUserAnswers() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/institutionusers/${this.userProfileInfo.id}/institutionuserdatas`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.answers = response.data;
          this.answers.forEach((ans) => {
            if (this.questionsAnswer[this.userProfileInfo.id]) {
              this.questionsAnswer[this.userProfileInfo.id][ans.question_id] =
                ans.answer;
            }
          });

          response;
        })
        .catch((error) => {
          error;
        });
    },
  },
};
</script>

<style >
.userProfileQuesAns .ques,
.userProfileQuesAns .ans {
  font-size: 14px;
  font-weight: 600;
}

.userProfileQuesAns .question {
  width: 33%;
}
</style>
