<template>
  <div>
    <div class="addCourseAdmin">
      <b-form @submit.prevent="createIntructors()" class="border-0">
        <b-form-group>
          <div class="enrollFacultyList">
            <multiselect v-model="selectedAdmins" tag-placeholder="Add this as new tag" :placeholder="
              'Choose course instructors for ' + courseAdminModal.name
            " label="name" track-by="code" :options="facultyOptions" :close-on-select="false" :multiple="true"
              :taggable="true" @tag="addFaculty" :disabled="addAdminBtn.disabled"></multiselect>
          </div>
        </b-form-group>

        <b-form-group>
          <div class="d-flex justify-content-between flex-wrap">
            <b-button v-if="selectedAdmins.length != 0" variant="primary" size="sm" :disabled="addAdminBtn.disabled"
              type="submit">{{ addAdminBtn.text }}</b-button>
            <b-button variant="danger" @click="$emit('addAdminStatus', false)" size="sm"
              :disabled="addAdminBtn.disabled">Cancel</b-button>
          </div>
        </b-form-group>
      </b-form>
    </div>
  </div>
</template>

<script>
export default {
  props: ["courseAdminModal", "faculties", "courseAdminList"],
  async created() {
    await this.getExistingAdmins();
    await this.getInstitutionFaculties();
  },
  data() {
    return {
      addAdminBtn: {
        text: "Submit",
        disabled: false,
      },
      selectedAdmins: [],
      adminsObject: {},
      facultyOptions: [],
    };
  },
  methods: {
    async getExistingAdmins() {
      await this.courseAdminList.forEach((admin) => {
        this.$set(
          this.adminsObject,
          admin.pivot.institution_user_id,
          admin.pivot.institution_user_id
        );
      });
    },
    async createIntructors() {
      this.addAdminBtn = {
        text: "Please wait...",
        disabled: true,
      };
      let mainUrl = this.$store.getters.getAPIKey.createIntructor;
      const url = mainUrl.replace("course_id", this.courseAdminModal.id);
      const instructors = {
        institution_users: [],
      };
      await this.selectedAdmins.forEach((instructor) => {
        instructors.institution_users.push(instructor.id);
      });

      await this.$axios
        .post(url, instructors)
        .then(() => {
          this.$toast.success(
            `Course Instructors added to ${this.courseAdminModal.name}`,
            {
              position: "top",
              duration: 3000,
            }
          );
          this.$emit("addAdminStatus", true);
          // console.log(response);
        })
        .catch(() => {
          // console.log(error);
        });
      this.addAdminBtn = {
        text: "Submit",
        disabled: false,
      };
    },
    addFaculty(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
        id: newTag,
      };
      this.facultyOptions.push(tag);
      this.selectedFaculty.push(tag);
    },
    async getInstitutionFaculties() {
      this.faculty = [];
      this.faculty = this.faculties;
      await this.faculty.forEach((staff) => {
        if (!this.adminsObject[staff.id] &&staff.enroll_status== 'active' ) {
          this.facultyOptions.push({
            name: staff.first_name + " " + (staff.last_name || "") + `( ${staff.email})`,
            id: staff.id,
            code: staff.id,
          });
        }
      });
      await this.facultyOptions.sort((a, b) => a.name.localeCompare(b.name));
    },
  },
};
</script>
