<template>
  <div class="container">
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <b-container>
      <b-row>
        <b-col></b-col>
        <b-col></b-col>
        <b-col></b-col>
      </b-row>
    </b-container>
    <div v-if="!fetchData">
      <div>
        <h3 style="text-align: center; font-size:18px !important;">
          Link attendance to activity : {{ activityName }}
        </h3>

        <b-spinner style="text-align: center;" v-if="loadData" label="Spinning"></b-spinner>

        <b-row>
          <b-col sm="12">
            <table class="table  table-striped table-bordered">
              <tr>
                <th></th>
                <th>
                  Date
                </th>
                <th>
                  Session
                </th>
              </tr>
              <tr v-for="(attdate, attKey) in attendancedates" :key="attKey">
                <td>
                  <b-form-checkbox @change="syncActivityAttendances(attdate.id);"
                    :checked="activityAttIds.includes(attdate.id)"></b-form-checkbox>

                  <!-- <input class="form-check-input" type="checkbox"  :name="attdate.id" 
                    @change="
                      activityAttIds.push(attdate.id);
                      syncActivityAttendances();
                    "
                    :checked="activityAttIds.includes(attdate.id)"> -->
                </td>
                <td>{{ attdate.date_time }}</td>
                <td>{{ sessionOptions[attdate.session_id].name }}</td>
              </tr>
            </table>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import createModule from "./ModuleCreation";
export default {
  props: ["activityIdForAttendance", "activityName"],
  async created() {
    this.fetchData = true;
    await this.getSessions();
    this.activityId = await this.activityIdForAttendance;
    await this.getActivityAttendances();
    this.fetchData = false;
  },
  data() {
    return {
      fetchData: true,
      loadData: false,
      activityId: null,
      attendancedates: {},
      activityAttIds: [],
      sessionOptions: {},
    };
  },
  methods: {
    async getActivityAttendances() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/exams/${this.activityId}/attendancedates` + '?deliverytype=tutorial';
      await axios
        .get(url)
        .then((response) => {
          this.attendancedates = response.data.attendancedates;
          this.activityAttIds = response.data.activityAttendanceDateIds;
          this.$forceUpdate();
        })
        .catch(() => {
          this.$toast.error("Failed to get Data", {
            position: "top",
            duration: 3000,
          });
        });
    },
    async syncActivityAttendances(attdateId) {
      this.loadData = true;

      if (this.activityAttIds.includes(attdateId)) {
        this.activityAttIds.splice(this.activityAttIds.indexOf(attdateId), 1);
      } else {
        this.activityAttIds.push(attdateId);
      }
      let activityAttIds = [];
      this.attendancedates.forEach(attdate => {
        if (this.activityAttIds.includes(attdate.id)) activityAttIds.push(attdate.id);
      });
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/exams/${this.activityId}/attendancedates`;
      await axios
        .post(url, { activityAttendancedateIds: activityAttIds })
        // .then((response) => {
        // })
        .catch(() => {
          this.$toast.error("Failed to update", {
            position: "top",
            duration: 3000,
          });
        });

      this.loadData = false;
    },

    getSessions() {
      const url = this.$store.getters.getAPIKey.createSessions;
      axios
        .get(url)
        .then((response) => {
          let sessions = response.data;
          sessions.forEach((session) => {
            this.sessionOptions[session.id] = {
              id: session.id,
              name: session.name,
            };
          });
        })
        .catch(() => {
          this.$toast.error("Failed to update", {
            position: "top",
            duration: 3000,
          });
        });
    },
  },
};
</script>

<style >
.tag-button button {
  font-size: 9px !important;
  padding: 3px;
}
</style>
