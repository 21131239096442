<template>
  <div>
    <b-skeleton-table v-if="fetchData" :rows="3" :columns="2" :table-props="{ bordered: true, striped: true }">
    </b-skeleton-table>
    <div class="detailsOfAssignment" v-else>
      <b-overlay :show="loadReport" spinner-variant="primary" spinner-type="grow" spinner-small rounded="md">
        <div class="submitAssignments mb-3" v-if="selectedSubgroups.length != 0">
          <!-- <div class="selectReportType mb-2 d-flex justify-content-center">
            <b-form-radio v-model="reportType" :value="0"
              >Oridinary Report</b-form-radio
            >
            <b-form-radio v-model="reportType" :value="1" class="ml-3"
              >ISO Report</b-form-radio
            >
          </div> -->
          <b-button variant="danger" size="sm" @click="downloadStudentTimetable()">Download PDF</b-button>
        </div>
        <div class="table-responsive">
          <table class="table table-striped table-bordered">
            <thead class="thead-dark">
              <tr>
                <th class="align-middle"></th>
                <th class="align-middle">Choose from list</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="subgroups.length == 0" class="align-middle">
                <td colspan="100" class="text-center font-weight-bolder text-danger">
                  No data found!
                </td>
              </tr>
              <tr v-else v-for="(subgroup, subgroupIndex) in subgroups" :key="subgroupIndex">
                <td class="align-middle">
                  <b-form-checkbox v-model="selectedSubgroups" :value="subgroup.id"></b-form-checkbox>
                </td>
                <td class="align-middle">
                  {{ subgroup.name }} ({{ subgroup.usergroup.code }})
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
export default {
  props: ["maingroupReportId"],
  async created() {
    this.fetchData = true;
    await this.getSubgroups();
    this.fetchData = false;
  },
  data() {
    return {
      subgroups: [],
      selectedSubgroups: [],
      reportType: 0,
      fetchData: false,
      loadReport: false,
    };
  },
  methods: {
    async downloadStudentTimetable() {
      this.loadReport = true;

      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/courseschedules/maingroupfaculties/report/pdfexport?custom_report=${this.reportType}&usersubgroups=${this.selectedSubgroups}`;
      await this.$axios
        .post(
          url,
          {
            maingroup: this.maingroupReportId,
            usersubgroups: this.selectedSubgroups,
          },
          { responseType: "blob" }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "file.pdf");
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch((error) => {
          let errorText = "Failed to Download, please check connectivity";
          if (error.response != undefined)
            errorText = error.response.status == 422 ? 'Custom report is not configured by admin' : "Failed to Download, please try again";
          this.$toast.error(errorText, {
            position: "top",
            duration: 3000,
          });
        });
      this.loadReport = false;
    },

    async getSubgroups() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/maingroup/usersubgroups?maingroup=${this.maingroupReportId}`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.subgroups = response.data;
        })
        .catch((error) => {
          error;
        });
    },
  },
};
</script>
