<template>
  <div>
    <div class="assignment border border-info rounded p-2">
      <div
        class="checkAssignment d-flex justify-content-between align-items-baseline mb-2"
      >
        <b-row>
          <b-col class="sm-12">
            <div class="d-flex">
              <b-form-checkbox class="ml-2" v-model="assignment.checkAssignment"
                ><p class="btn p-0 m-0 text-info">
                  Assignment
                </p></b-form-checkbox
              >
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="sm-6">
            <div class="" v-if="assignment.checkAssignment">
              <label for="scoreForAssessment">Score:</label>
              <b-form-input
                id="scoreForAssessment"
                v-model="assignment.scoreForAssignment"
                placeholder="Enter Score"
                :class="{
                  'form-group-error': $v.assignment.scoreForAssignment.$error,
                }"
              ></b-form-input>
              <span
                class="input-error-message"
                v-if="
                  !$v.assignment.scoreForAssignment.required &&
                  $v.assignment.scoreForAssignment.$dirty
                "
                >Score is mandatory!</span
              >
            </div>
          </b-col>
          <b-col class="sm-6">
            <div class="" v-if="assignment.checkAssignment">
              <label for="best_of_assscores">Best of score:</label>
              <b-form-input
                id="best_of_assscores"
                v-model="assignment.best_of_scores"
                placeholder="Optional"
                type="number"
                min="0"
                value=""
              ></b-form-input>
              <small id="best_of_assscores" class="form-text text-muted"
                >Number of best scores will be considerd for total score</small
              >
            </div>
          </b-col>
        </b-row>
        <span></span>
      </div>
      <b-collapse
        id="assignmentConfig"
        v-model="assignment.checkAssignment"
        class="mt-2"
      >
        <div class="selectScoreType mb-2">
          <span class="text-dark font-weight-bolder">Choose Score Type:</span>
        </div>
        <table class="table table-bordered table-striped">
          <thead class="thead-dark">
            <tr>
              <th></th>
              <th>Assignment</th>

              <th v-if="!activity_simple_calculation">Percent Weightage</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="assignmentsFromApi.length == 0">
              <td colspan="100" class="text-danger text-center align-middle">
                No Data Found!
              </td>
            </tr>
            <tr
              v-for="(assignment, assignmentIndex) in assignmentsFromApi"
              :key="assignmentIndex"
              v-else
            >
              <td class="align-middle">
                <b-form-checkbox
                  v-model="assignmentConfigStructure[assignment.id].checkbox"
                >
                </b-form-checkbox>
              </td>
              <td class="align-middle">
                {{ assignment.name }}
              </td>

              <td
                v-if="!activity_simple_calculation"
                class="text-center align-middle"
              >
                <b-form-input
                  v-if="assignmentConfigStructure[assignment.id].checkbox"
                  placeholder="Enter Percentage"
                  v-model="assignmentConfigStructure[assignment.id].input"
                ></b-form-input>
              </td>
            </tr>
          </tbody>
        </table>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { required, requiredIf } from "vuelidate/lib/validators";
required;
import moment from "moment";
export default {
  props: ["saved_configs", "courseReport", "activity_simple_calculation"],
  async created() {
    await this.getAssignments();
    // this.activitySimpleCalculation = this.activity_simple_calculation;
    let savedAssignConfig =
      this.saved_configs.activity_catagory_configs.assignments;
    if (savedAssignConfig.enable) {
      this.assignment = {
        checkAssignment: false,
        scoreForAssignment: savedAssignConfig.score,
        best_of_scores: savedAssignConfig.best_of_scores,
      };
      let selectedAssigns = savedAssignConfig.resourses.exam;
      // console.log(selectedAssigns);

      for (const key in selectedAssigns) {
        if (key in this.assignmentConfigStructure) {
          // console.log(key, 'in');
          this.assignmentConfigStructure[key].checkbox = true;
          this.assignmentConfigStructure[key].input =
            selectedAssigns[key].persantage_of_wightage;
        }else{

          // console.log(key, ' not in');
        }
      }
    }
    this.assignment.checkAssignment = savedAssignConfig.enable;
  },
  // watch: {
  //   activity_simple_calculation() {
  //     this.activitySimpleCalculation = this.activity_simple_calculation;
  //   },
  // },

  data() {
    return {
      // activitySimpleCalculation: false,
      fetchData: true,
      assignment: {
        checkAssignment: false,
        scoreForAssignment: 20,
        best_of_scores: "",
      },
      assignmentsFromApi: [],
      assignmentConfigStructure: {},
    };
  },
  validations: {
    assignment: {
      scoreForAssignment: {
        required: requiredIf(function () {
          if (this.assignment.checkAssignment) {
            return true;
          }
        }),
      },
    },
  },
  methods: {
    async checkValidation() {
      await this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      }
    },
    async getAssignments() {
      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/course/${this.courseReport.course_id}/exams`;
      await this.$axios
        .get(url)
        .then((response) => {
          let assignments = [];

          assignments = response.data;
          this.assignmentConfigStructure = {};
          assignments.forEach((assignment) => {
            if (assignment.activity_type == "assignment") {
              this.$set(this.assignmentConfigStructure, assignment.id, {
                checkbox: false,
                input: "", //weigtage
              });
              this.assignmentsFromApi.push({
                id: assignment.id,
                name: assignment.name,
                description: assignment.summary,
                date:
                  assignment.close_at != null
                    ? moment(new Date(assignment.end_at))
                    : null,
                maximum_mark: assignment.maximum_mark,
                course: assignment.course_id,
                respond_after_close:
                  assignment.respond_after_close == 1 ? true : false,
                hidden: assignment.hidden == 1 ? true : false,
              });
            }
          });

          // console.log(response);
        })
        .catch(() => {
          // console.log(error.response);
        });
    },
  },
};
</script>

<style >
.assignment p {
  font-size: 16px;
  font-weight: 600;
}

.assignment p small {
  font-size: 10px;
  font-weight: 600;
}
.assignment span {
  font-size: 12px;
  font-weight: 600;
}
</style>
