<template>
  <div>
    <b-skeleton-table v-if="fetchData" :rows="5" :columns="4"
      :table-props="{ bordered: true, striped: true }"></b-skeleton-table>
    <div class="tableView bg-white p-2 mt-2" v-else>
      <b-button class="mb-2" @click="exportToExcel('xlsx')" variant="primary">Export to Excel</b-button>

      <div class="table-responsive">
        <table class="table table-bordered table-striped" id="institutionUserDetails">
          <thead class="thead-dark">
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Mobile</th>
              <!-- <th>DOB</th> -->
              <th>Admission No.</th>
              <th>Board/University Reg. No.</th>
              <!-- <th>Apartment</th>
              <th>Street</th>
              <th>City</th>
              <th>State</th>
              <th>Country</th>
              <th>Pincode</th> -->
              <th v-for="(question, questionIndex) in questions" :key="questionIndex">
                {{ question.name }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(user, userIndex) in users" :key="userIndex">
              <td :class="{ 'align-middle': true, 'text-danger': user.enroll_status != 'active' }">
                {{ user.first_name || " " }} {{ user.last_name || " " }}
              </td>
              <td class="align-middle">{{ user.email }}</td>
              <td class="align-middle">{{ user.mobile }}</td>
              <!-- <td class="align-middle">{{ user.birth_date }}</td> -->
              <td class="align-middle">{{ user.admission_no }}</td>
              <td class="align-middle">{{ user.register_no }}</td>
              <!-- <td class="align-middle">Apartment</td>
              <td class="align-middle">{{ user.user.address_street }}</td>
              <td class="align-middle">{{ user.address_city }}</td>
              <td class="align-middle">{{ user.address_state }}</td>
              <td class="align-middle">{{ user.address_country }}</td>
              <td class="align-middle">{{ user.address_postcode }}</td> -->
              <td class="align-middle" v-for="(question, questionIndex) in questions" :key="questionIndex">
                <span v-if="
                  questionsAnswer[user.pivot.institution_user_id][
                  question.id
                  ] != null
                ">
                  {{
    questionsAnswer[user.pivot.institution_user_id][question.id]
                  }}
                </span>
                <span v-else>
                  -
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import XLSX from "xlsx";
export default {
  props: ["users"],
  async created() {
    this.fetchData = true;

    await this.users.forEach((user) => {
      this.$set(this.questionsAnswer, user.pivot.institution_user_id, {});
    });
    await this.getUserQuestions();
    await this.questions.forEach((question) => {
      this.users.forEach((user) => {
        this.$set(
          this.questionsAnswer[user.pivot.institution_user_id],
          question.id,
          null
        );
      });
    });
    await this.getUserAnswers();
    this.fetchData = false;
  },
  data() {
    return {
      questions: [],
      asnswers: {},
      questionsAnswer: {},
      fetchData: false,
    };
  },
  methods: {
    async getUserQuestions() {
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/institutionuserquestions`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.questions = response.data;
          this.questions = this.questions.filter((question) => {
            return question.role == "student";
          });
          response;
        })
        .catch((error) => {
          error;
        });
    },
    async getUserAnswers() {
      for (let user in this.users) {
        const url =
          this.$store.getters.getAPIKey.mainAPI +
          `/institutionusers/${this.users[user].pivot.institution_user_id}/institutionuserdatas`;
        await this.$axios
          .get(url)
          .then((response) => {
            const answers = response.data;
            answers.forEach((ans) => {
              if (
                this.questionsAnswer[this.users[user].pivot.institution_user_id]
              ) {
                this.questionsAnswer[
                  this.users[user].pivot.institution_user_id
                ][ans.question_id] = ans.answer;
              }
            });

            response;
          })
          .catch((error) => {
            error;
          });
      }
    },
    async exportToExcel(type, fn, dl) {
      var elt = document.getElementById("institutionUserDetails");
      var wb = await XLSX.utils.table_to_book(elt, { sheet: "Sheet JS" });

      return dl
        ? XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" })
        : XLSX.writeFile(
          wb,
          fn || "institutionUserDetails." + (type || "xlsx")
        );
    },
  },
};
</script>
