<template>
  <div>
    <b-overlay :show="loadSurveyReport" spinner-variant="primary" spinner-type="grow" spinner-small rounded="md">
      <b-modal id="checkReportType" hide-footer hide-header centered>
        <b-form-select class="mb-3" :options="surveyReportType" v-model="selectedReportType"></b-form-select>
        <div v-if="selectedReportType == 1" class="selectReportType mb-2 d-flex justify-content-center">
          <span>Download report as single/individual files: &nbsp; &nbsp; &nbsp;
          </span>

          <b-form-radio v-model="singleorindividual" :value="'single'">Single</b-form-radio>
          <b-form-radio v-model="singleorindividual" :value="'individual'" class="ml-3">Individual</b-form-radio>
        </div>
        <!-- <div class="selectReportType mb-2 d-flex justify-content-center">
          <b-form-radio v-model="reportType" :value="0">Oridinary Report</b-form-radio>
          <b-form-radio v-model="reportType" :value="1" class="ml-3">ISO Report</b-form-radio>
        </div> -->
        <div class="text-center">
          <b-button @click="generateReport()" variant="danger" size="sm">Download</b-button>
        </div>
      </b-modal>
      <div class="d-flex justify-content-between">
        <div class="printReport mb-2 text-right">
          <b-button variant="danger" :disabled="faculties.length == 0" size="sm" @click="checkReportTypeForSurvey()">
            Download Survey Report</b-button>
        </div>
        <div class="w-50 d-flex flex-column">
          <b-form-select :options="groupsWithSubgroupsOptions" v-model="selectedGroupSubgroup"
            @change="getCourseReportBasedOnSubgroup"></b-form-select>
          <b-button variant="danger" v-if="selectedGroupSubgroup != null" size="sm" @click="clearSubgroup()">Clear
          </b-button>
        </div>
        <div class="printReport mb-2 text-right" :disabled="faculties.length == 0">
          <b-button variant="primary" size="sm" @click="printReport()">Print</b-button>
        </div>
      </div>

      <p class="p-4 text-info" style="font-size: 14px; font-weight: 600">
        <strong>Equation Used for the report:</strong>
        <br />
        <strong>Avg. score of a question = sum(score*no. of students selected)/ total
          no. of students <br> Teaching effectiveness (%) = sum(avg. score of a
          question) / sum(maximum score of each question) *100</strong>
      </p>
      <div class="loading" v-if="fetchData">
        <load-data></load-data>
      </div>
      <div id="surveyCourseReport" class="p-4" v-else>
        <div v-if="!temporaryTutorOnlypermission">
          <h6 class="text-danger" style="font-size: 20px; font-weight: 600" v-if="faculties.length == 0">
            No Data Found!
          </h6>
          <!--SURVEY REPORT TABLE-->
          <div v-for="(course, courseIndex) in faculties" :key="courseIndex" style="margin-bottom: 50px" v-else>
            <div v-for="(faculty, facultyIndex) in course.faculties" :key="facultyIndex"
              class="border border-info p-2 rounded" style="page-break-after: always">
              <div class="instituteDetails mb-3 text-center">
                <h5 style="
                    font-family: 'Times New Roman', Times, serif;
                    font-size: 32px;
                    font-weight: 600;
                  " class="text-uppercase mb-0">
                  {{ selectedInstitute.name }}
                </h5>
                <h6 v-if="selectedInstitute.name_2 != null" style="font-size: 12px; font-weight: 600" class="p-0 m-0">
                  {{ selectedInstitute.name_2 }}
                </h6>
                <h6 v-if="selectedInstitute.name_3 != null" style="font-size: 12px; font-weight: 600" class="p-0 m-0">
                  {{ selectedInstitute.name_3 }}
                </h6>
              </div>
              <div>
                <br />
                <h5 style="font-size: 20px; font-weight: 600">
                  ~
                  <span v-if="selectedSurveyType == 'student_feedback'">Student Feedback</span>
                  <span v-if="selectedSurveyType == 'course_exit'">Course Exit</span>
                  <span v-if="selectedSurveyType == 'programme_exit'">Program Exit</span>
                  Survey Report for: {{ course.name }}-{{
                  faculty.first_name
                  }} {{
                  faculty.last_name
                  }}
                  (<span class="text-info">{{
                  course.group + "-" + course.subgroup
                  }}</span>)
                </h5>

                <table class="table table-striped table-bordered rounded">
                  <thead class="thead-dark">
                    <tr>
                      <th class="align-middle" colspan="10">Sr No.</th>
                      <th class="align-middle">Question</th>
                      <th class="align-middle">Choices</th>
                      <th class="align-middle">Scores</th>
                      <th class="align-middle">No of selections</th>
                      <th class="align-middle">Avg. Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="quesChoices.length == 0">
                      <td colspan="100" class="align-middle text-center text-danger">
                        No Data Found!
                      </td>
                    </tr>
                    <tr v-for="(question, questionIndex) in quesChoices" :key="questionIndex" class="w-100" v-else>
                      <td colspan="10" class="align-middle text-center">
                        {{ questionIndex + 1 }}
                      </td>
                      <td class="align-middle">
                        {{ question.name }}
                      </td>
                      <td class="align-middle">
                        <div class="d-flex">
                          <div class="d-flex flex-column">
                            <p v-for="(choice, choiceIndex) in question.choices" :key="choiceIndex" class="p-0 m-0">
                              {{ choice.name }}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td class="align-middle">
                        <div class="d-flex">
                          <div class="d-flex flex-column">
                            <p v-for="(choice, choiceIndex) in question.choices" :key="choiceIndex" class="p-0 m-0">
                              {{ choice.score }}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td class="align-middle text-center">
                        <div class="d-flex flex-column">
                          <p v-for="(choice, choiceIndex) in question.choices" :key="choiceIndex" class="p-0 m-0">
                            {{
                            answersNumber[course.id][faculty.id][question.id]
                            .choices[choice.id].length
                            }}
                          </p>
                        </div>
                      </td>
                      <td class="align-middle text-center">
                        {{
                        answersNumber[course.id][faculty.id][question.id]
                        .avarage
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="14" class="text-center">
                        Total No. of Students Attended
                      </td>
                      <td class="text-center">
                        {{ faculty.studentCount }}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="14" class="text-center">Teacher Score</td>
                      <td class="text-center">
                        {{ faculty.teacherScore }}
                      </td>
                    </tr>

                    <tr>
                      <td colspan="14" class="text-center">
                        Teacher Score Percentage
                      </td>
                      <td class="text-center">
                        {{ faculty.teacherScorePersentage + "%" }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import acl from "../../../../../authorization/acl.js";
export default {
  props: ["survey_id", "groupsWithSubgroupsOptions", "selectedSurveyType"],
  async created() {
    this.selectedInstitute = this.$store.getters.getCurrentInstitution;
    await this.getOrignalSurveyReport();
    await this.temporaryTutorOnlypermissionCheck();
    if (this.groupsWithSubgroupsOptions.length > 1) {

      this.selectedGroupSubgroup = await this.groupsWithSubgroupsOptions[1]
        .value;
      await this.getCourseReportBasedOnSubgroup();
    }
    if (this.temporaryTutorOnlypermission) {
      this.surveyReportType = [
        { value: 2, text: "Individual participant sheet" },
      ];
    }
  },
  data() {
    return {
      fetchData: false,
      selectedInstitute: {},
      loadSurveyReport: false,
      selectedGroupSubgroup: null,
      selectedGroupSubgroupCode: null,
      students: [],
      quesChoices: [],
      faculties: [],
      answersNumber: {},
      reportType: 0,
      surveyReportType: [
        { value: 1, text: "Final survey Report" }, //
        { value: 2, text: "Individual participant sheet" },
        { value: 3, text: "Consolidated Survey Report" },
      ],
      selectedReportType: 2,
      temporaryTutorOnlypermission: false,
      singleorindividual: "single", // temp setup to solve , tutoec an download survey form only
    };
  },
  watch: {
        "selectedGroupSubgroup": function () {
            this.groupsWithSubgroupsOptions.forEach(subgroup => {
                if (subgroup.value == this.selectedGroupSubgroup ) {
                    this.selectedGroupSubgroupCode = subgroup.code
                }
            });
        },
    },
  methods: {

    generateCoursesSummaryReport() {
      this.loadSurveyReport = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/studfbsurveys/usersubgroup/coursessummaryreport/pdfexport`;
      this.$axios
        .post(
          url,
          {
            usersubgroup: this.selectedGroupSubgroup,
            studfbsurvey: this.survey_id
          },
          { responseType: "blob" }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download",`Consolidate Survey Report-${this.selectedGroupSubgroupCode}.pdf` )
          document.body.appendChild(link);
          link.click();
          link.remove();
          this.loadSurveyReport = false;
        })
        .catch(() => {
          this.loadSurveyReport = false;
        });
    },
    // async getTeacherFeedbackReport(surveyId) {
    //   this.survey_id = surveyId;
    //   this.loadSurveyReport = true;
    //   const url =
    //     this.$store.getters.getAPIKey.mainAPI +
    //     `/studfbsurveys/${surveyId}/report`;
    //   await this.$axios
    //     .post(url)
    //     .then((response) => {
    //       let responseData = response.data;

    //       this.students = responseData.students;
    //       this.questionsChoices = responseData.questions_choices;
    //       this.courseFaculties = responseData.courses_faculties;
    //       this.answersCounts = responseData.students_in_question_choices;
    //       this.survey = responseData.survey;
    //       if (this.selectedSurveyType == "student_feedback") {
    //         this.$bvModal.show("surveyCourses");
    //       }

    //       this.loadSurveyReport = false;
    //     })
    //     .catch((error) => {
    //       error;
    //       this.loadSurveyReport = false;
    //     });
    // },
    async temporaryTutorOnlypermissionCheck() {
      const institutionAdmin = await acl.isUserGranted("institutionAdmin");
      if (institutionAdmin) return;
      const groupAdmin = await acl.isUserGranted("groupAdmin");

      if (groupAdmin) return;
      const subgroupAdmin = await acl.isUserGranted("subgroupAdmin");
      if (subgroupAdmin) this.temporaryTutorOnlypermission = true;
      return;
    },
    checkReportTypeForSurvey() {
      this.$bvModal.show("checkReportType");
    },
    async getOrignalSurveyReport() {
      this.fetchData = true;
      // this.quesChoices = await this.questionsChoices;
      // this.faculties = await this.courseFaculties;
      // this.answersNumber = await this.answersCounts;
      this.fetchData = false;
    },
    getCourseReportBasedOnSubgroup() {
      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/studfbsurveys/${this.survey_id}/report`;
      this.$axios
        .post(url, { usersubgroup: this.selectedGroupSubgroup })
        .then((response) => {
          this.faculties = [];
          this.quesChoices = [];
          this.answersNumber = {};
          this.faculties = response.data.courses_faculties;
          this.quesChoices = response.data.questions_choices;
          this.answersNumber = response.data.students_in_question_choices;
          this.fetchData = false;
          response;
        })
        .catch((error) => {
          this.fetchData = false;
          error;
        });
    },
    clearSubgroup() {
      this.selectedGroupSubgroup = null;
      this.getOrignalSurveyReport();
    },
    generateReport() {
      if (this.selectedGroupSubgroup == null) {
        return this.$toast.error("Please select a subgroup!", {
          position: "top",
          duration: 3000,
        });
      }
      this.$bvModal.hide("checkReportType");
      if (this.selectedReportType == 2) {
        return this.generateIndividualReport();
      }
      if (this.selectedReportType == 3) {
        return this.generateCoursesSummaryReport();
      }
      this.loadSurveyReport = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/studfbsurveys/${this.survey_id}/report/pdfexport?custom_report=${this.reportType
        }&individualfiles=${this.singleorindividual == "individual" ? 1 : 0}`;
      this.$axios
        .post(
          url,
          {
            usersubgroup:
              this.selectedGroupSubgroup != null
                ? this.selectedGroupSubgroup
                : null,
          },
          { responseType: "blob" }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          var tempFileName =
            this.singleorindividual == "individual"
              ? `Individual final survey report-${this.selectedGroupSubgroupCode}.zip`
              : `Single final survey report-${this.selectedGroupSubgroupCode}.pdf`;
          link.setAttribute("download", tempFileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
          this.loadSurveyReport = false;
        })
        .catch(() => {
          this.loadSurveyReport = false;
        });
    },
    generateIndividualReport() {
      this.loadSurveyReport = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/studfbsurveys/${this.survey_id}/participants/answerform/pdfexport?custom_report=${this.reportType}`;
      this.$axios
        .post(
          url,
          {
            usersubgroup:
              this.selectedGroupSubgroup != null
                ? this.selectedGroupSubgroup
                : null,
          },
          { responseType: "blob" }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download",`Individual Participant Sheet-${this.selectedGroupSubgroupCode}.pdf` )
          document.body.appendChild(link);
          link.click();
          link.remove();
          this.loadSurveyReport = false;
        })
        .catch(() => {
          this.loadSurveyReport = false;
        });
    },
    onProgress(progress) {
      progress;
    },
    async printReport() {
      await this.$forceUpdate();
      this.$htmlToPaper("surveyCourseReport");
    },
  },
};
</script>
