<template>
  <div>
    <div class="groupData">
      <b-modal :title="
        getCustomNames.userGroupAdmin.custom_name != null
          ? getCustomNames.userGroupAdmin.custom_name + ' List'
          : 'Usergroup Admin List'
      " size="lg" :id="'userGroupAdmins' + id" hide-footer centered @close="checkAdminsStatus">
        <ListOfAdmins :editInfo="editInfo" :adminList="adminList" :getCustomNames="getCustomNames"
          @updateInstitutionUserCount="updateInstitutionUserCount" />
      </b-modal>
      <!-- <b-modal
        :title="'Add/View Users to/from ' + editInfo.name"
        size="lg"
        id="groupUsers"
        hide-footer
        centered
      >
        <GroupUser
          :editInfo="editInfo"
          @updateInstitutionUserCount="updateInstitutionUserCount"
        />
      </b-modal> -->
      <b-overlay :show="groupLoading" spinner-variant="primary" spinner-type="grow" spinner-small rounded="md">
        <div class="">
          <table class="table table-bordered table-striped">
            <thead class="thead-dark">
              <tr>
                <th class="align-middle">
                  {{
                      getCustomNames.userGroup.custom_name != null
                        ? getCustomNames.userGroup.custom_name
                        : "Usergroup"
                  }}
                </th>
                <th>Type</th>
                <th class="align-middle">
                  {{
                      getCustomNames.userGroupAdmin.custom_name != null
                        ? getCustomNames.userGroupAdmin.custom_name + " List"
                        : "Usergroup Admin List"
                  }}
                </th>
                <th>Affiliated to</th>
                <th>Scheme</th>
                <!-- <th class="align-middle">Enroll Faculty to Group</th> -->
                <th class="align-middle">PO</th>
                <th class="align-middle">PSO</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="usergroupFromMain.length == 0">
                <td colspan="100" class="text-danger text-center font-weight-bolder">
                  {{
                      getCustomNames.userGroup.custom_name != null
                        ? "No " + getCustomNames.userGroup.custom_name + " Found!"
                        : "No Usergroup Found!"
                  }}
                </td>
              </tr>
              <tr v-for="({ id, name, code, institution_users, programme_type, affiliated_university, scheme, programme_specificoutcomes_count, programmeoutcomes_count },
              groupIndex) in usergroupFromMain" :key="groupIndex" v-else>
                <td class="align-middle">
                  <div>
                    <!--Added program configuration to each program-->
                    <span @click="$bvModal.show('UsergroupOptions' + id)"
                      class="text-info font-weight-bolder btn p-0 m-0">
                      {{ name + " (" + code + ")" }}
                      <b-modal :id="'UsergroupOptions' + id" :title="name + ' Configuration'" no-close-on-backdrop
                        no-close-on-keyboard no-close-on-esc hide-footer modal-class="coursesOptions">
                        <UsergroupsOptions :usergroup_name="name" :usergroup_id="id" />
                      </b-modal>
                    </span>
                    
                    <span class="text-info btn p-0 m-0 ml-2" @click="
                      getGroup({ id, admins: institution_users, group: true })
                    "><i class="fas fa-pen"></i></span>
                  </div>
                </td>
                <td>{{ programme_type ? programme_type.name : '' }}</td>
                <td class="align-middle">
                  <b-popover :target="'disabled-groupAdmin' + groupIndex" triggers="hover" placement="top">
                    <b-list-group style="height: 200px; overflow-y: scroll">
                      <b-list-group-item v-for="({ first_name, last_name },
                      adminIndex) in institution_users" :key="adminIndex">
                        {{ first_name.concat(" ", last_name) }}
                      </b-list-group-item>
                    </b-list-group>
                  </b-popover>
                  <span class="btn p-0 m-0 text-info font-weight-bolder" :id="'groupAdmin' + groupIndex"
                    v-if="institution_users.length == 1" @click="
                      openAdminsModal({
                        id,
                        name,
                        group: true,
                        dataChanged: false,
                      })
                    ">
                    {{
                        institution_users[0].first_name.concat(
                          institution_users[0].last_name
                        )
                    }}
                  </span>
                  <span :id="'groupAdmin' + groupIndex" class="btn p-0 m-0 text-info font-weight-bolder"
                    v-else-if="institution_users.length > 1" @click="
                      openAdminsModal({
                        id,
                        name,
                        group: true,
                        dataChanged: false,
                      })
                    ">
                    {{
                        institution_users[0].first_name.concat(
                          " ",
                          institution_users[0].last_name
                        )
                    }}
                    & {{ institution_users.length - 1 }} more
                  </span>
                  <span class="btn p-0 m-0 text-danger font-weight-bolder" @click="
                    openAdminsModal({
                      id,
                      name,
                      group: true,
                      dataChanged: false,
                    })
                  " v-else>
                    No Admins Assigned Yet!
                  </span>
                </td>
                <td>{{ affiliated_university ? affiliated_university : 'Not Added' }}</td>
                <td>{{ scheme ? scheme : 'Not Added' }}</td>
                <!-- <td class="align-middle">
                  <span
                    @click="
                      openGroupUsersModal({
                        id,
                        name,
                        institution_users_count: institution_users.length,
                        group: true,
                        orignalCount: institution_users.length,
                      })
                    "
                    :class="
                      institution_users.length > 0
                        ? 'text-info btn p-0 m-0 font-weight-bolder'
                        : 'text-danger font-weight-bolder'
                    "
                    >{{ institution_users.length }} Faculties</span
                  >
                </td> -->
                <td class="align-middle">
                  <span class="text-info btn p-0 m-0 font-weight-bolder" @click="openManagePoModal(id)">
                    {{ programmeoutcomes_count }} PO Added
                  </span>
                </td>
                <td class="align-middle">
                  <span class="text-info btn p-0 m-0 font-weight-bolder" @click="$emit('goToPSO', id)">
                    {{ programme_specificoutcomes_count }} PSO Added
                  </span>
                </td>


              </tr>
            </tbody>
          </table>
        </div>
      </b-overlay>
    </div>
    <!-- add edit delete PO s for a usergroup  -->
    <b-modal :id="('managePoModel' + id)" no-close-on-backdrop no-close-on-keyboard no-close-on-esc hide-footer centered
      size="lg" lazy>
      <UsergroupPoDashboard :usergroup_id='managePoOfUsergroup' />
    </b-modal>
  </div>
</template>

<script>
import ListOfAdmins from "./ListOfAdmins";
import UsergroupPoDashboard from "../../../usergroupProgrammeOutcome/UsergroupPoDashboard.vue";
// import GroupUser from "./GroupUser";
import UsergroupsOptions from "./UsergroupsOptions.vue"; //programme config
export default {
  props: [
    "selectedInstitute",
    "adminList",
    "groups",
    "mainGroups",
    "usergroupFromMain",
    "id",
    "getCustomNames",
  ],

  components: {
    ListOfAdmins,
    // GroupUser,
    UsergroupPoDashboard,
    UsergroupsOptions,
  },
  data() {
    return {
      editInfo: {},
      groupLoading: false,
      groupInfo: {},
      managePoOfUsergroup: null,
    };
  },
  methods: {
    async getGroup(info) {
      this.groupLoading = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/usergroups/${info.id}`;
      const mainGroup = await this.$axios.get(url).then((res) => {
        return res.data;
      });
      const groupData = {
        ...info,
        mainGroup,
        status: false,
      };
      this.$emit("getGroup", groupData);
      this.groupLoading = false;
    },
    // mainGroupCreated(status) {
    //   this.$bvModal.hide("mainGroups");
    //   status ? this.$emit("mainGroupCreated") : "";
    // },
    openManagePoModal(usergroupId) {
      this.managePoOfUsergroup = usergroupId;
      this.$bvModal.show('managePoModel' + this.id);
    },
    openAdminsModal(info) {
      this.editInfo = info;
      this.$bvModal.show(`userGroupAdmins` + this.id);
    },
    openGroupUsersModal(info) {
      this.editInfo = info;
      this.$bvModal.show("groupUsers");
    },
    updateInstitutionUserCount(status) {
      this.editInfo.dataChanged = status;
    },
    async checkAdminsStatus() {
      if (this.editInfo.dataChanged) {
        this.$emit("adminsUpdated");
      }
    },
  },
};
</script>

<style >
.groupData i {
  font-size: 12px !important;
}
</style>
