<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="submitForms" v-else>
      <div class="approvalListing">
        <b-overlay
          :show="loadApproval"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="md"
        >
          <p class="text-danger" v-if="surveyApprovals.length == 0">No Forms Found!</p>
          <div class="studentEvent" v-else>
            <div
              class="event mt-2 p-3 border border-info rounded"
              v-for="(approval, approvalIndex) in surveyApprovals"
              :key="approvalIndex"
              :style="
                approvalIndex % 2 === 0
                  ? {
                      backgroundColor: '#F0F0F0',
                      fontSize: 14 + 'px',
                      fontWeight: 600,
                    }
                  : {
                      backgroundColor: '#FFFFFF',
                      fontSize: 14 + 'px',
                      fontWeight: 600,
                    }
              "
            >
              <div class="eventName text-center mb-2">
                <span
                  :class="approval.summary != null ? 'text-info' : 'text-dark'"
                  :id="'approvalSummary' + approvalIndex"
                  >{{ approval.name }}</span
                >
                <b-tooltip
                  :target="'approvalSummary' + approvalIndex"
                  triggers="hover"
                  v-if="approval.summary != null"
                >
                  {{ approval.summary }}
                </b-tooltip>
              </div>
              <div class="date mb-2 d-flex justify-content-between">
                <div class="startDate">
                  {{ approval.start_at.format("DD/MM/YYYY") }} <br />
                  {{ approval.start_at.format("hh:mm a") }}
                </div>
                <div class="endDate">
                  {{ approval.end_at.format("DD/MM/YYYY") }} <br />
                  {{ approval.end_at.format("hh:mm a") }}
                </div>
              </div>
              <div class="submitBtn">
                <b-button
                  variant="primary"
                  size="sm"
                  block
                  style="font-size: 14px; height: 30px; text-align: center"
                  v-b-toggle="'application' + approvalIndex"
                  >Submit Form</b-button
                >
                <b-collapse :id="'application' + approvalIndex" class="mt-3">
                  <b-button
                    variant="primary"
                    v-if="
                      approval.multiple_application || approval.applications.length == 0
                    "
                    style="height: 30px; font-size: 12px"
                    class="mb-1"
                    size="sm"
                    @click="goToApplication(null, 1, approval.id)"
                    >New Application</b-button
                  >
                  <div class="d-flex flex-column">
                    <span
                      v-for="(application, applicationIndex) in approval.applications"
                      :key="applicationIndex"
                      class="mb-2"
                    >
                      <div class="d-flex justify-content-between align-items-center">
                        <span
                          >{{ applicationIndex + 1 }})
                          {{
                            application.created_at != null
                              ? convertTimeToLocal(application.created_at).format(
                                  "DD/MM/YYYY hh:mm a"
                                )
                              : ""
                          }}</span
                        >
                        <p class="text-success" v-if="application.end_at != null">
                          Submitted
                        </p>
                        <b-button
                          v-else
                          style="height: 30px; font-size: 12px"
                          size="sm"
                          variant="primary"
                          @click="
                            goToApplication(
                              application.id,
                              application.end_at,
                              approval.id
                            )
                          "
                        >
                          Continue
                        </b-button>
                      </div>
                    </span>
                  </div>
                </b-collapse>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  created() {
    this.getFormsForSubmit();
  },
  data() {
    return {
      fetchData: false,
      loadApproval: false,
      surveyApprovals: [],
    };
  },
  methods: {
    convertTimeToLocal(theTime) {
      let localTime = moment(moment.utc(theTime, "YYYY-MM-DD HH:mm:ss").toDate());
      return localTime;
    },
    getFormsForSubmit() {
      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/onlineregparticipant/onlineregistrations`;
      this.$axios
        .get(url)
        .then((response) => {
          this.surveyApprovals = [];
          const approvals = response.data;
          approvals.forEach((approval) => {
            this.surveyApprovals.push({
              id: approval.id,
              name: approval.name,
              summary: approval.summary,
              academic_year: approval.academic_year,
              start_at: moment(
                moment.utc(approval.start_at, "YYYY-MM-DD HH:mm:ss").toDate()
              ),
              end_at: moment(moment.utc(approval.end_at, "YYYY-MM-DD HH:mm:ss").toDate()),
              grouping_tag: approval.grouping_tag,
              comment_feedback: approval.comment_feedback,
              hidden: approval.hidden,
              participants_only: approval.participants_only,
              is_anonymous: approval.is_anonymous,
              multiple_application: approval.multiple_application == 1 ? true : false,
              applications: approval.applications,
            });
          });

          this.fetchData = false;
        })
        .catch(() => {
          this.fetchData = false;
        });
    },
    goToApplication(applicationId, endAt, approvalId) {
      if (endAt == null) {
        this.$router.push({
          name: "ApprovalAns",
          params: {
            approval_id: btoa(approvalId),
            application_id: btoa(applicationId),
          },
        });
      } else {
        this.$router.push({
          name: "ApprovalAns",
          params: {
            approval_id: btoa(approvalId),
            application_id: btoa(null),
          },
        });
      }
    },
  },
};
</script>
