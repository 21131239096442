<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div v-else>
      <div class="mobileStudentView" v-if="$screenSize < 600">
        <SurveyMobileView :surveyAdmin="surveyAdmin" :surveyStudent="surveyStudent" :surveys="surveys" :today="today" />
      </div>
      <div class="surveys" v-else>
        <div class="surveyCreation" v-if="surveyForm">
          <CreatingSurveys @surveyCancelled="closeSurveyForm()" :surveyInfo="surveyInfo" />
        </div>
        <div class="surveyListing" v-else>
          <div class="p-2 d-flex justify-content-between" style="
              border: 2px solid #eeeeeb;
              border-left-style: none;
              border-right-style: none;
            ">
            <b-button ref="addCourse" size="sm" variant="info" v-if="surveyAdmin" @click="surveyForm = true"><i
                class="fas fa-plus-square text-white"></i>Add New Survey</b-button>
            <!-- <b-button
              class="ml-3"
              @click="openYoutubeLinks('Survey')"
              style="backgroundColor: #FF0000; border: none"
              size="sm"
            >
              <i class="fab fa-youtube"></i>
              Video Tutorial
            </b-button> -->
          </div>
          <div class="p-3" v-if="surveys.length != 0">
            <b-overlay :show="loadSurveyReport" spinner-variant="primary" spinner-type="grow" spinner-small
              rounded="md">
              <div class="table-responsive rounded">
                <table class="table bordered table-bordered table-striped">
                  <thead class="thead-dark text-left">
                    <tr style="font-size: 14px; font-weight: 600">
                      <th>Name</th>
                      <th></th>
                      <th>Mode</th>
                      <th v-if="surveyAdmin"></th>
                      <th v-if="surveyAdmin"></th>
                      <th class="text-center">Questions</th>
                      <th>Created Date</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th v-if="surveyAdmin"></th>
                      <th v-if="surveyStudent"></th>
                      <th>Report</th>
                      <th>Academic year</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(survey, surveyIndex) in surveys" :key="surveyIndex"
                      style="font-size: 14px; font-weight: 600">
                      <td class="align-middle">
                        <div class="">
                          <span :id="'survey' + surveyIndex">{{ survey.name }}
                          </span>
                          <b-badge v-if="survey.survey_type == 'student_feedback'"
                            style="font-size: 10px; font-weight: 600; color: #ffffff" pill variant="success">Instructor
                            Feedback Survey</b-badge>
                          <b-badge v-if="survey.survey_type == 'course_exit'"
                            style="font-size: 10px; font-weight: 600; color: #ffffff" pill variant="danger">Course Exit
                            Survey</b-badge>
                          <b-badge v-if="survey.survey_type == 'programme_exit'"
                            style="font-size: 10px; font-weight: 600; color: #ffffff" pill variant="warning">Programme
                            Exit Survey</b-badge>
                            <b-badge v-if="survey.survey_type == 'alumini'"
                            style="font-size: 10px; font-weight: 600; color: #ffffff" pill variant="primary">
                            Alumini Survey</b-badge>
                            <b-badge v-if="survey.survey_type == 'employer'"
                            style="font-size: 10px; font-weight: 600; color: #ffffff" pill variant="secondary">
                            Employer Survey</b-badge>
                            <b-badge v-if="survey.survey_type == 'parent'"
                            style="font-size: 10px; font-weight: 600; color: #ffffff" pill variant="info">
                            Parent Survey</b-badge>
                            <b-badge v-if="survey.survey_type == 'faculty'"
                            style="font-size: 10px; font-weight: 600; color: #ffffff" pill variant="dark">
                            Faculty Survey</b-badge>
                        </div>
                        <br />

                        <b-tooltip :target="'survey' + surveyIndex" triggers="hover" placement="topright">
                          <p style="font-size: 14px">{{ survey.summary }}</p>
                        </b-tooltip>
                      </td>
                      <td class="align-middle" >
                        <span v-if="survey.course_id" >
                        {{survey.course.code}}-{{ survey.course.usersubgroup.code }}-{{ survey.course.usersubgroup.usergroup.code }}
                          </span>
                          <span v-if="survey.usersubgroup_id" >
                           {{ survey.usersubgroup.code }}-{{ survey.usersubgroup.usergroup.code }}
                          </span>
                      </td>
                      <td class="align-middle" >
                          <span v-if="survey.survey_mode == 'online'" class="bg-info p-1 rounded text-white m-0">
                            Online
                          </span>
                          <span v-if="survey.survey_mode == 'offline'" class="bg-info p-1 rounded text-white m-0">
                            Offline
                          </span>
                        </td>
                        <td class="align-middle" v-if="surveyAdmin"> 
                            <b-button variant="primary" size="sm" squared v-if="survey.survey_mode == 'offline'" 
                                  @click="updateResult(survey.id)">Update Result</b-button>
                          </td>
                      <td class="align-middle" v-if="surveyAdmin">
                        <span class="text-info btn m-0 p-0" @click="getSurvey(survey.id)"><i
                            class="far fa-edit"></i></span>
                      </td>
                      <td class="align-middle text-center">
                        <div v-if="surveyAdmin">
                          <b-button variant="primary" size="sm" squared
                            @click="surveyLinks(survey.id, 'SurveyQues')">Add Ques</b-button>
                          <b-button variant="primary" size="sm" class="ml-2" squared
                            @click="surveyLinks(survey.id, 'SurveyQuesExcel')">Import From Excel</b-button>
                        </div>
                      </td>
                      <td class="align-middle">{{ survey.created_at }}</td>
                      <td class="align-middle">
                        <span v-if="survey.start_at_local.isValid()">
                          {{ survey.start_at_local.format("DD/MM/YYYY") }}</span>
                        <span v-else>Date not updated</span>
                      </td>
                      <td class="align-middle">
                        <span v-if="survey.end_at_local.isValid()">
                          {{ survey.end_at_local.format("DD/MM/YYYY") }}</span>
                        <span v-else>Date not updated</span>
                      </td>
                      <td class="align-middle" v-if="surveyAdmin">
                        <div :id="'hideSurvey' + surveyIndex">
                          <b-popover :target="'hideSurvey' + surveyIndex" triggers="hover">
                            {{
                                !survey.hidden
                                  ? "This Survey is visible to students. Click to make it invisible."
                                  : "This Survey is invisible to students. Click to make it visible."
                            }}
                          </b-popover>
                          <span v-if="!survey.hidden" @click="
                            hideSurvey(
                              survey.id,
                              true,
                              survey.name,
                              survey.academic_year
                            )
                          " class="btn p-0 m-0 text-info"><i class="far fa-eye"></i></span>
                          <span @click="
                            hideSurvey(
                              survey.id,
                              false,
                              survey.name,
                              survey.academic_year
                            )
                            " class="btn p-0 m-0 text-info" v-else><i class="fas fa-eye-slash"></i></span>
                        </div>
                      </td>

                      <td class="align-middle" v-if="surveyStudent">
                        {{ survey.survey_type }}
                        <b-button variant="primary" v-if="
                          today >= survey.start_at && today <= survey.end_at
                        " size="sm" pill @click="surveyLinks(survey.id, survey.survey_type)">Take Survey</b-button>
                        <span v-else-if="today <= survey.start_at" class="text-success">Upcoming Survey</span>
                        <span v-else class="text-danger">Last Date exceeded</span>
                      </td>
                      <td class="align-middle">
                        <span class="btn p-0 m--0 text-info" v-if="survey.survey_type == 'course_exit' ||
                        survey.survey_type == 'programme_exit'"
                          @click="$bvModal.show('downloadCourseExitReportModel' + survey.id)"><i
                            class="fas fa-file-alt"></i></span>
                            <span class="btn p-0 m--0 text-info" v-if="survey.survey_type == 'alumini'
                              || survey.survey_type == 'employer' || survey.survey_type == 'parent' || survey.survey_type == 'faculty'"
                            @click="downloadCourseExitReport(
                              survey.id,
                              survey.name,
                              null,
                              survey.usersubgroup_id
                            )"><i
                              class="fas fa-file-alt"></i></span>
                        <b-modal :id="'downloadCourseExitReportModel' + survey.id" title="Score Mapping"
                          no-close-on-backdrop no-close-on-keyboard no-close-on-esc hide-footer size="md">
                          <div class="table-responsive">
                            <div class="p-1 mt-3 border-secondary border-top"></div>
                            <b-button variant="primary" class="mb-2" size="sm" @click="addValue">+ Add Level</b-button>
                            <div class="table-responsive">
                              <table class="table table-bordered table-striped">
                                <thead class="thead-dark">
                                  <tr>
                                    <th>Value</th>
                                    <th>Comparison</th>
                                    <th>Compare By</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(attainment, attainmentIndex) in attainments" :key="attainmentIndex">
                                    <td class="align-middle">
                                      <b-form-input v-model.number="attainment.value"
                                        @keypress="handleNumber"></b-form-input>
                                    </td>
                                    <td class="align-middle">
                                      <b-form-select :options="operators"
                                        v-model="attainment.comparisan"></b-form-select>
                                    </td>
                                    <td class="align-middle">
                                      <b-form-input @keypress="handleNumber"
                                        v-model="attainment.compare_by"></b-form-input>
                                    </td>
                                    <td class="align-middle">
                                      <span class="btn p-0 m-0 text-danger"
                                        @click="attainments.splice(attainmentIndex, 1)">
                                        <i class="fas fa-minus-circle"></i>
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>

                              <b-button variant="primary" class="mt-3" size="sm" @click="downloadCourseExitReport(
                                survey.id,
                                survey.name,
                                survey.course_id,
                                null
                              )"> Download PDF</b-button>
                            </div>
                          </div>
                        </b-modal>
                      </td>
                      <td class="align-middle">
                        {{ survey.academic_year }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-overlay>
          </div>
          <div class="noSurveys" v-else>
            <p class="text-danger" v-if="surveyStudent">
              No Survey Exist now, check back later!
            </p>
            <p class="text-danger" v-else>No Surveys created yet!</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import CreatingSurveys from "./CreatingSurveys.vue";
import SurveyMobileView from "./SurveyMobileView.vue";
import acl from "../../../../authorization/acl.js";
export default {
  async created() {
    this.fetchData = true;
    const adminAccess = await acl.isUserGranted("surveyAdmin");
    this.surveyAdmin = adminAccess;
    const studentAccess = await acl.isUserGranted("surveyStudent");
    this.surveyStudent = studentAccess;
    await this.getSurveys();
  },
  components: {
    CreatingSurveys,
    SurveyMobileView,
  },
  data() {
    return {
      fetchData: false,
      loadSurveyReport: false,
      today: moment(),
      instructorSurveyAccess: null,
      surveyForm: false,
      surveys: [],
      surveyInfo: {},
      surveyAdmin: null,
      surveyStudent: null,
      attainments: [
        { value: 1, comparisan: ">=", compare_by: 50 },
        { value: 2, comparisan: ">", compare_by: 65 },
        { value: 3, comparisan: ">", compare_by: 80 }
      ],
      operators: [
        { value: null, text: "Select Operator" },
        { value: "<", text: "<" },
        { value: ">", text: ">" },
        { value: "<=", text: "<=" },
        { value: ">=", text: ">=" },
      ],
    };
  },
  methods: {
    handleNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    addValue() {
      this.attainments.push({
        value: "",
        comparisan: null,
        compare_by: "",
      });
    },
    async downloadCourseExitReport(surveyId, surveyName, courseId,usersubgroupId) {
      const direct = [];
      await this.attainments.forEach((attainment) => {
        if (
          attainment.value != "" &&
          attainment.comparisan != null &&
          attainment.compare_by != ""
        ) {
          direct.push(attainment);
        }
      });
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/studfbsurveys/${surveyId}/report/pdfexport`;
      this.$axios
        .post(
          url,
          {
            rubrics: direct,
            course: courseId,
            usersubgroup: usersubgroupId,
          },
          { responseType: "blob" }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", surveyName + ".pdf");
          document.body.appendChild(link);
          link.click();
          link.remove();
          this.loadSurveyReport = false;
        })
        .catch(() => {
          this.loadSurveyReport = false;
        });
    },
    async getSurveys() {
      this.fetchData = true;
      const url = this.$store.getters.getAPIKey.studentFeedbackSurvey;
      await this.$axios
        .get(url)
        .then((response) => {
          this.surveys = [];
          const surveysData = response.data;
          surveysData.forEach((survey) => {
            if (this.surveyStudent && survey.hidden) {
              ("DO Nothing");
            } else {
              this.surveys.push({
                id: survey.id,
                name: survey.name,
                summary: survey.summary,
                course: survey.course,
                usersubgroup:survey.usersubgroup,
                academic_year: survey.academic_year,
                created_at: moment(survey.created_at).format("DD/MM/YYYY"),
                start_at: moment(survey.start_at),
                start_at_local: moment(
                  moment.utc(survey.start_at, "YYYY-MM-DD HH:mm:ss").toDate()
                ),
                end_at: moment(survey.end_at),
                end_at_local: moment(
                  moment.utc(survey.end_at, "YYYY-MM-DD HH:mm:ss").toDate()
                ),
                comment_feedback: survey.comment_feedback == 0 ? false : true,
                is_anonymous: survey.is_anonymous == 0 ? false : true,
                hidden: survey.hidden,
                time_required: survey.time_required,
                course_id: survey.course_id,
                usersubgroup_id: survey.usersubgroup_id,
                survey_type: survey.survey_type,
                survey_mode: survey.survey_mode,
              });
            }
          });
          response;
        })
        .catch((error) => {
          error;
        });
      this.fetchData = false;
    },
    async getSurvey(surveyId) {
      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.studentFeedbackSurvey + `/${surveyId}`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.survey = {};
          const surveyData = response.data;
          const date = [
            moment(
              moment.utc(surveyData.start_at, "YYYY-MM-DD HH:mm:ss").toDate()
            ).format("YYYY/MM/DD"),

            moment(
              moment.utc(surveyData.end_at, "YYYY-MM-DD HH:mm:ss").toDate()
            ).format("YYYY/MM/DD"),
          ];

          this.surveyInfo = {
            id: surveyData.id,
            name: surveyData.name,
            summary: surveyData.summary,
            academic_year: surveyData.academic_year,
            date: date,
            time_required: surveyData.time_required,
            grouping_tag: surveyData.grouping_tag,
            comment_feedback: surveyData.comment_feedback == 0 ? false : true,
            is_anonymous: surveyData.is_anonymous == 0 ? false : true,
            survey_type: surveyData.survey_type,
            survey_mode: surveyData.survey_mode,
            hidden: surveyData.hidden,
            course_id: surveyData.course_id,
            usersubgroup_id: surveyData.usersubgroup_id,
          };
          this.surveyForm = true;
          response;
        })
        .catch((error) => {
          error;
        });
      this.fetchData = false;
    },
    async hideSurvey(surveyId, status, name, year) {
      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.studentFeedbackSurvey + `/${surveyId}`;
      await this.$axios
        .put(url, { hidden: status, name: name, academic_year: year })
        .then((response) => {
          let hiddenStatus = !status ? "Survey visible." : "Survey hidden.";
          this.$toast.success(hiddenStatus, {
            position: "top",
            duration: 3000,
          });
          this.getSurveys();
          response;
        })
        .catch((error) => {
          this.$toast.error("Something went wrong, Please try again!", {
            position: "top",
            duration: 3000,
          });
          this.fetchData = false;
          error;
        });
    },
    closeSurveyForm() {
      this.getSurveys();
      this.surveyForm = false;
      this.surveyInfo = {};
    },
    surveyLinks(surveyId, type) {
      if (type == "SurveyQues") {
        let routeData = this.$router.resolve({
          name: type,
          params: {
            survey_id: surveyId,
          },
        });
        window.open(routeData.href, "_blank");
      } else {
        this.$router.push({
          name: type,
          params: {
            survey_id: surveyId,
          },
        });
      }

      // if (Link == "Questions") {
      //   this.$router.push({ path: `/survey_questions/${surveyId}` });
      // }
      // if (Link == "Answers") {
      //   if (type == "student_feedback") {
      //     this.$router.push({ path: `/survey_answers/${surveyId}` });
      //   }
      //   if (type == "course_exit") {
      //     this.$router.push({ path: `/survey_answers_course_exit/${surveyId}` });
      //   }
      //   if (type == "programme_exit") {
      //     this.$router.push({ path: `/survey_answers_programme_exit/${surveyId}` });
      //   }
      // }
      // if (Link == "Excel") {
      //   this.$router.push({ path: `/survey_questions_excel/${surveyId}` });
      // }
    },
    updateResult(surveyId) {
      let route = this.$router.resolve({
        path: `/survey_results/${surveyId}`
      });
      window.open(route.href, '_blank');
    }
  },
};
</script>

<style >
.surveyListing i {
  font-size: 12px !important;
  margin-right: 4px;
}
</style>
