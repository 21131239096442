<template>
  <div>
    <b-row>
      <b-col md="12">
        <div class="loading" v-if="fetchData">
          <load-data></load-data>
        </div>
        <div class="userDataCompleted" v-else>
          <h6 v-if="instituteAttachedQuestions.length == 0" class="text-danger">
            No questions Found!
          </h6>
          <b-form class="border-0" @submit.prevent="prepareUserDataQuestions()" v-else>
            <div class="questions p-2 border border-info rounded">
              <div
                class="question"
                v-for="(question, questionIndex) in instituteAttachedQuestions"
                :key="questionIndex"
              >
                <div class="nameAndAnswer">
                  <div class="name mb-2">
                    <span
                      :class="question.description != null ? 'text-info' : 'text-dark'"
                      :id="'description' + questionIndex"
                      >Ques: {{ question.name }}</span
                    >
                    <b-popover
                      :target="'description' + questionIndex"
                      triggers="hover"
                      placement="top"
                      v-if="question.description != null"
                    >
                      {{ question.description }}
                    </b-popover>
                  </div>
                  <div class="answer" v-if="!userAnswersObject[question.id]">
                    <b-form-input
                      v-if="question.input_type == 'text'"
                      v-model="question.userAnswer"
                      placeholder="Answer"
                      :required="question.required ? true : false"
                    ></b-form-input>
                    <b-form-radio-group
                      v-else-if="question.input_type == 'radio'"
                      :options="question.answerOptions"
                      v-model="question.userAnswer"
                      :required="question.required ? true : false"
                    ></b-form-radio-group>
                    <b-form-select
                      v-else-if="question.input_type == 'select'"
                      :options="question.answerOptions"
                      v-model="question.userAnswer"
                      :required="question.required ? true : false"
                    ></b-form-select>
                    <b-form-checkbox-group
                      size="sm"
                      v-else-if="question.input_type == 'multiselect'"
                      :options="question.answerOptions"
                      v-model="question.userAnswer"
                      :required="question.required ? true : false"
                    ></b-form-checkbox-group>
                    <b-form-textarea
                      rows="3"
                      v-else
                      v-model="question.userAnswer"
                    ></b-form-textarea>
                  </div>
                  <div class="answer" v-else>
                    <p>{{ userAnswersObject[question.id] }}</p>
                  </div>
                </div>
                <hr />
              </div>
              <div class="submitAnswers d-flex mt-2">
                <b-button
                  type="submit"
                  variant="primary"
                  size="sm"
                  :disabled="userBtn.disabled"
                  >{{ userBtn.text }}</b-button
                >
                <b-button
                  variant="danger"
                  class="ml-3"
                  size="sm"
                  :disabled="userBtn.disabled"
                  @click="$emit('skipUserProfileQuestions')"
                  >Skip</b-button
                >
              </div>
            </div>
          </b-form>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: ["userDataQuestions"],
  async created() {
    this.fetchData = true;
    await this.getUserAnswers();
    await this.getAttachedQuestions();
    this.fetchData = false;
  },
  data() {
    return {
      fetchData: false,
      instituteAttachedQuestions: [],
      userAnswersObject: {},
      userBtn: {
        text: "Submit",
        disabled: false,
      },
    };
  },
  methods: {
    async prepareUserDataQuestions() {
      this.userBtn = {
        text: "Please wait....",
        disabled: true,
      };
      let questionsPostData = [];
      await this.instituteAttachedQuestions.forEach((question) => {
        if (!this.userAnswersObject[question.id]) {
          let answer;
          if (question.input_type == "multiselect") {
            answer = question.userAnswer.join(",");
          } else {
            answer = question.userAnswer;
          }
          questionsPostData.push({
            question_id: question.id,
            answer: answer,
            attachment: null,
          });
        }
      });
      let storeAnswers = await this.submitUserDataQuestions(questionsPostData);
      if (storeAnswers) {
        this.$toast.success("Successfully Submitted", {
          position: "top",
          duration: 3000,
        });
        this.userBtn = {
          text: "Submit",
          disabled: false,
        };
        this.$emit("answersSubmitted");
      }
      if (!storeAnswers) {
        this.$toast.error("Something went wrong", {
          position: "top",
          duration: 3000,
        });
        this.userBtn = {
          text: "Retry",
          disabled: false,
        };
      }
    },
    async submitUserDataQuestions(questionsPostData) {
      let status = null;
      const url =
        this.$store.getters.getAPIKey.mainAPI + "/institutionuserdatas/storemany";
      await this.$axios
        .post(url, { institutionuserData: questionsPostData })
        .then((response) => {
          status = true;
          response;
        })
        .catch((error) => {
          error;
          status = false;
        });
      return status;
    },
    async getAttachedQuestions() {
      this.instituteAttachedQuestions = [];
      let questions = this.userDataQuestions;
      questions.forEach((ques) => {
        const answerOptions =
          ques.input_attributes.input_type == "select"
            ? [{ value: null, text: "Select Answer" }]
            : [];
        if (ques.answers.length != 0) {
          ques.answers.forEach((ans) => {
            answerOptions.push(ans);
          });
        }
        this.instituteAttachedQuestions.push({
          id: ques.id,
          description: ques.description,
          name: ques.name,
          role: ques.role,
          required:
            ques.institution_attributes != null &&
            ques.institution_attributes.required == 1
              ? true
              : false,
          userAnswer: ques.input_attributes.input_type == "multiselect" ? [] : null,
          input_type: ques.input_attributes.input_type,
          answerOptions: answerOptions,
        });
      });
    },
    async getUserAnswers() {
      const url = this.$store.getters.getAPIKey.mainAPI + `/institutionuserdatas`;
      await this.$axios
        .get(url)
        .then((response) => {
          const answers = response.data;
          answers.forEach((answer) => {
            this.$set(this.userAnswersObject, answer.question_id, answer.answer);
          });
          response;
        })
        .catch((error) => {
          error;
        });
    },
  },
};
</script>

<style >
.userDataCompleted span {
  font-size: 14px;
  font-weight: 600;
}

.userDataCompleted input {
  height: 40px;
  font-size: 16px;
}

.userDataCompleted h6 {
  font-size: 18px;
  font-weight: 600;
}

.userDataCompleted .answer p {
  font-size: 14px;
  font-weight: 600;
}
</style>
