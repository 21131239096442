<template>
  <div>
    <b-modal
      id="editCourseTeacherCombination"
      hide-footer
      hide-header
      no-close-on-backdrop
      no-close-on-keyboard
      no-close-on-esc
      size="xl"
      centered
    >
      <b-overlay
        :show="loadTeachers"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="md"
      >
        <div class="loading" v-if="fetchData">
          <load-data></load-data>
        </div>
        <div v-else>
          <div
            class="existingCombinations"
            v-if="existingCourseTeacherCombinations.length != 0"
          >
            <h5>Existing Course Teachers Combinations</h5>
            <div class="courseTeachers d-flex flex-wrap">
              <p
                v-for="(teacher, teacherIndex) in existingCourseTeacherCombinations"
                :key="teacherIndex"
                class="bg-info p-2 text-white rounded ml-2"
                style="font-size: 12px"
              >
                {{ teacher.course.name + "-" + teacher.teacher.first_name }}
                <span
                  class="text-danger btn m-0 p-0"
                  @click="deleteCourseTeacherCombination(teacher.id, teacherIndex)"
                  ><i class="fas fa-trash ml-2"></i
                ></span>
              </p>
            </div>
          </div>
          <div class="resetTeachers text-center" v-if="subgroupTeachers.length != 0">
            <b-button variant="danger" @click="subgroupTeacherList()" size="sm"
              >Clear all</b-button
            >
          </div>
          <div class="d-flex mt-3" style="height: 200px">
            <div class="subgroupNames w-50" style="overflow-y: scroll">
              <b-form-checkbox
                v-for="(subgroup, subgroupIndex) in subgroupsTeachersOptions"
                :key="subgroupIndex"
                v-model="selectedSubgroupsTeachers"
                :value="subgroup.value"
                :disabled="subgroup.disabled"
                @change="getSubgroupTeachers(subgroup.value)"
                size="sm"
                >{{ subgroup.text }}</b-form-checkbox
              >
            </div>
            <div
              class="subgroupTeachers w-50"
              v-if="subgroupTeachers.length != 0"
              style="overflow-y: scroll"
            >
              <div class="users d-flex flex-wrap">
                <p
                  v-for="(teacher, teacherIndex) in subgroupTeachers"
                  :key="teacherIndex"
                  class="bg-success p-2 text-white rounded ml-2"
                  style="font-size: 12px"
                >
                  {{ teacher.course + "-" + teacher.instructor }}
                  <span
                    class="text-white btn m-0 p-0"
                    @click="subgroupTeachers.splice(teacherIndex, 1)"
                    ><i class="far fa-times-circle ml-2"></i
                  ></span>
                </p>
              </div>
            </div>
          </div>
          <hr />
          <br />
          <div class="submitCourseTeacherCombination d-flex justify-content-between">
            <b-button
              variant="primary"
              @click="prepareSyncTeachersPostData()"
              size="md"
              :disabled="submitBtn.disabled || selectedSubgroupsTeachers.length == 0"
              >{{ submitBtn.text }}</b-button
            >
            <b-button
              variant="danger"
              size="md"
              @click="cancel()"
              :disabled="submitBtn.disabled"
              >Cancel</b-button
            >
          </div>
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: ["subgroupsForTeachers", "surveyId"],
  async created() {
    await this.subgroups;
    await this.subgroupTeacherList();
  },
  data() {
    return {
      fetchData: false,
      loadTeachers: false,
      subgroupsTeachersOptions: this.subgroups,
      selectedSubgroupsTeachers: [],
      subgroupTeachers: [],
      existingCourseTeacherCombinations: [],
      submitBtn: {
        text: "Submit",
        disabled: false,
      },
    };
  },
  methods: {
    async checkCourseTeacherCombination() {
      await this.$bvModal.show("editCourseTeacherCombination");
      await this.getCourseTeacherCombination();
    },
    getCourseTeacherCombination() {
      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.studentFeedbackSurvey +
        `/${this.surveyId}/studfbsurveycourseteachers`;
      this.$axios
        .get(url)
        .then((response) => {
          this.existingCourseTeacherCombinations = [];
          this.existingCourseTeacherCombinations = response.data;
          this.fetchData = false;
          response;
        })
        .catch((error) => {
          this.$toast.error("Something went wrong!", {
            position: "top",
            duration: 3000,
          });

          this.cancel();
          this.fetchData = false;
          error;
        });
    },
    async subgroupTeacherList() {
      this.subgroupsTeachersOptions = [];
      this.subgroupTeachers = [];
      this.selectedSubgroupsTeachers = [];
      this.subgroupsTeachersOptions = this.subgroupsForTeachers;
      this.subgroupsTeachersOptions.forEach((subgroup) => {
        subgroup.disabled = false;
      });
    },
    async prepareSyncTeachersPostData() {
      //Variable defined for API
      const course_users = [];
      const course_teachers = [];
      await this.subgroupTeachers.forEach((teacher) => {
        course_users.push(teacher.course_user_id);
      });
      if (this.existingCourseTeacherCombinations.length != 0) {
        await this.existingCourseTeacherCombinations.forEach((existingTeacher) => {
          course_teachers.push({
            course_id: existingTeacher.course_id,
            institution_user_id: existingTeacher.teacher_id,
          });
        });
      }
      this.submitBtn.text = "Please wait ...";
      this.submitBtn.disabled = true;
      this.$forceUpdate();

      //********** Calling Sync Teachers API **********/
      const syncTeachers = await this.syncTeachersWithFeedbackSurvey(
        course_users,
        course_teachers
      );

      //***************API RESPONSE ****************
      //***************SUCCESS RESPONSE ****************
      if (syncTeachers) {
        this.submitBtn.text = "Linked Successfully.";
        this.$toast.success(`Linking course teachers successfully done.`, {
          position: "top",
          duration: 3000,
        });
        this.cancel();
      }
      //***************ERROR RESPONSE ****************
      if (!syncTeachers) {
        this.$toast.error(`Linking course teachers failed, Please try again`, {
          position: "top",
          duration: 3000,
        });
        this.cancel();
      }
    },
    //Syncing Course Teachers with Survey
    async syncTeachersWithFeedbackSurvey(course_users, course_teachers) {
      let syncUrl = this.$store.getters.getAPIKey.studentFeedbackSurveySyncTeachers;
      const url = syncUrl.replace("survey_id", this.surveyId);
      let status = null;
      await this.$axios
        .post(url, {
          course_users: course_users,
          course_teachers: course_teachers,
        })
        .then((response) => {
          status = true;
          response;
        })
        .catch((error) => {
          status = false;
          error;
        });
      return status;
    },
    //********** Cancel Course Combination Submission **********/
    async cancel() {
      await this.$bvModal.hide("editCourseTeacherCombination");
      this.submitBtn.text = "Submit";
      this.submitBtn.disabled = false;
      await this.subgroupTeacherList();
    },
    //********** Delete Course Teacher Combination *********/
    async deleteCourseTeacherCombination(teacherId, arrayIndex) {
      this.loadTeachers = true;
      const url =
        this.$store.getters.getAPIKey.studentFeedbackSurvey +
        `/studfbsurveycourseteacher/${teacherId}`;
      this.$axios
        .delete(url)
        .then((response) => {
          this.$toast.success("Course Teacher Combination Removed.", {
            position: "top",
            duration: 3000,
          });
          this.existingCourseTeacherCombinations.splice(arrayIndex, 1);
          this.loadTeachers = false;
          response;
        })
        .catch((error) => {
          this.$toast.error("Something went wrong, Please try again!", {
            position: "top",
            duration: 3000,
          });
          this.loadTeachers = false;
          error;
        });
    },
    //********* Getting Course Teacher Combination *********/
    async getSubgroupTeachers(subgroupId) {
      this.loadTeachers = true;
      const subgroupTeacherId = await this.selectedSubgroupsTeachers.findIndex(
        (subgroup) => {
          return subgroup == subgroupId;
        }
      );
      const subgroupUsersAdded = await this.subgroupsTeachersOptions.findIndex(
        (subgroup) => {
          return subgroup.value == subgroupId;
        }
      );
      const url = `${this.$store.getters.getAPIKey.mainAPI}/usersubgroup/${subgroupId}/courses/withinstructors`;
      await this.$axios.get(url).then((response) => {
        const subgroupUsers = response.data;
        if (subgroupUsers.length == 0) {
          this.$toast.warning("No users found!", {
            position: "top",
            duration: 3000,
          });
          this.selectedSubgroupsTeachers.splice(subgroupTeacherId, 1);
        } else {
          this.subgroupsTeachersOptions[subgroupUsersAdded].disabled = true;
          const checkTeachersExist = [];
          subgroupUsers.forEach((course) => {
            course.institution_users.forEach((instructor) => {
              if (instructor.pivot.courserole_id == 1) {
                this.subgroupTeachers.push({
                  course: course.name,
                  instructor: instructor.first_name,
                  course_user_id: instructor.course_user_id,
                });
                checkTeachersExist.push(instructor.id);
              }
            });
          });
          if (checkTeachersExist.length == 0) {
            this.$toast.warning("No users found!", {
              position: "top",
              duration: 3000,
            });
            this.subgroupsTeachersOptions[subgroupUsersAdded].disabled = false;
            this.selectedSubgroupsTeachers.splice(subgroupTeacherId, 1);
          }
        }
        response;
      });
      this.loadTeachers = false;
    },
  },
};
</script>
