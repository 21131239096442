<template>
  <div>
    <div class="googleAuth">
      <div class="googleAuthentication">
        <b-button
          variant="primary"
          size="sm"
          block
          v-if="linkedSuccessfully"
          @click="getAuthenticationDetails()"
          :disabled="linkStatus.disabled"
          style="font-size: 12px"
          >{{ linkStatus.text }}</b-button
        >
        <b-button
          v-else-if="!linkedSuccessfully && googleAuthDetails == null"
          variant="primary"
          size="sm"
          block
          @click="startAuthentication()"
          :disabled="googleBtn.disabled"
          style="font-size: 12px"
          >{{ googleBtn.text }}</b-button
        >
        <b-button
          v-else
          variant="danger"
          size="sm"
          block
          @click="unlinkGoogle()"
          :disabled="unlink.disabled"
          style="font-size: 12px"
          >{{ unlink.text }}</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  created() {
    this.getAuthenticationDetails();
  },
  data() {
    return {
      fetchData: false,
      linkedSuccessfully: false,
      linkStatus: {
        text: "Update",
        disabled: false,
      },
      googleBtn: {
        text: "Link Google Meet Account",
        disabled: false,
      },
      unlink: {
        text: "Unlink Google",
        disabled: false,
      },
      googleAuthDetails: null,
    };
  },
  methods: {
    getAuthenticationDetails() {
      if (this.linkedSuccessfully) {
        this.$emit("linkedSuccessfully");
        this.linkStatus.text = "Checking...";
        this.linkStatus.disabled = true;
      }
      const url = this.$store.getters.getAPIKey.mainAPI + `/user/google/oauth2detailes`;
      axios
        .get(url)
        .then((response) => {
          if (response.data != "") {
            this.linkedSuccessfully = false;
            this.googleAuthDetails = response.data;
            this.googleBtn.disabled = true;
          }
          if (this.linkedSuccessfully && response.data == "") {
            this.linkStatus.text =
              "Google account did'nt linked successfully, please try again!";
            setTimeout(() => {
              this.linkStatus.text = "Update";
              this.linkStatus.disabled = false;
              this.linkedSuccessfully = false;
            }, 3000);
          }
          response;
        })
        .catch((error) => {
          error;
        });
    },
    startAuthentication() {
      this.googleBtn.text = "Please wait...";
      this.googleBtn.disabled = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/user/google/oauth2authentication`;
      axios
        .get(url)
        .then((response) => {
          this.googleBtn.text = "Link Google Meet Account`";
          this.googleBtn.disabled = false;
          let authenticationURL = response.data.auth_url;

          window.open(authenticationURL, "_blank");
          this.linkedSuccessfully = true;
          // response;
        })
        .catch((error) => {
          setTimeout(() => {
            this.googleBtn.text = "Link Google Meet Account`";
            this.googleBtn.disabled = false;
          }, 3000);
          error;
        });
    },
    unlinkGoogle() {
      this.unlink.text = "Please wait...";
      this.unlink.disabled = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/user/google/oauth2/${this.googleAuthDetails.id}`;
      axios
        .delete(url)
        .then((response) => {
          this.googleBtn.text = "Link Google Meet Account`";
          this.googleBtn.disabled = false;
          this.linkedSuccessfully = false;
          this.googleAuthDetails = null;
          this.unlink.text = "Unlink Google";
          this.unlink.disabled = false;
          this.$emit("unlinkGoogle");
          response;
          response;
        })
        .catch((error) => {
          setTimeout(() => {
            this.googleBtn.text = "Link google meet";
            this.googleBtn.disabled = false;
          }, 3000);
          error;
        });
    },
  },
};
</script>

<style >
.googleAuth button {
  color: white;
  font-size: 18px;
}
</style>
