<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="addStudents" v-else>
      <b-form @submit.prevent="createStudents">
        <div v-if="interDepartmentStudentsEnroll">
          <div class="row">
            <div class="col-sm-6 col-md-4 col-lg-3" v-for="(option, index) in subgroupOptions" :key="index">
              <b-form-checkbox v-model="fromSubgroupsIds" :value="option.value"
                @change="updateFromSubgroupsStudentsOptions(option.value)">{{
                    option.text
                }}
              </b-form-checkbox>
            </div>
            <br>
          </div>
          <table v-if="fromSubgroupsIds.length" class="table  table-striped table-bordered">
            <thead>
              <tr style="background-color: #a8a8a8;">
                <th v-for="(subgroupId, subgindex) in fromSubgroupsIds" :key="subgindex">
                  {{ subgroupOptions.find(obj => obj.value == subgroupId).text }}
                </th>
              </tr>
            </thead>
            <tbody style="font-weight: 300;">
              <tr>
                <td v-for="(subgroupId, subgindex) in fromSubgroupsIds" :key="subgindex">
                  <b-link v-if="(subgroupsStudentsOptions[subgroupId].length > 1)"
                    @click="selectAllStudentsOn(subgroupId)">Select
                    All</b-link>
                </td>
              </tr>
              <tr>
                <td v-for="(subgroupId, subgindex) in fromSubgroupsIds" :key="subgindex">
                  <span v-for="(stud, studindex) in subgroupsStudentsOptions[subgroupId]" :key="studindex">
                    <b-form-checkbox v-model="fromSubgroupsSelectedStudentIds" :value="stud.id" size="md">
                      {{ stud.name }}
                    </b-form-checkbox>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>

          <!-- <b-row>
            <b-form-group :label="'Select '+ getCustomNames.userSubgroup.custom_name != null
            ? getCustomNames.userSubgroup.custom_name: 'Subgroup'">
              <b-form-select @change="getSubgroupUsers()" :options="subgroupOptions" v-model="usersubgroup_id">
              </b-form-select>
            </b-form-group>
          </b-row>
          <b-row>
            <b-form-group label="Choose Students">
              <multiselect v-model="selectedStudents" tag-placeholder="Add this as new tag"
                :placeholder="'Add Students for ' + courseStudentModal.name" label="name" track-by="code"
                :options="StudentOptions" :close-on-select="false" :multiple="true" :taggable="true" @tag="addStudent">
              </multiselect>
            </b-form-group>
          </b-row> -->
        </div>
        <b-form-group v-else>
          <multiselect v-model="selectedStudents" tag-placeholder="Add this as new tag"
            :placeholder="'Add Students for ' + courseStudentModal.name" label="name" track-by="code"
            :options="StudentOptions" :close-on-select="false" :multiple="true" :taggable="true" @tag="addStudent">
          </multiselect>
        </b-form-group>
        <br>
        <b-form-group>
          <div class="d-flex justify-content-between flex-wrap">
            <b-button v-if="(selectedStudents.length != 0 || fromSubgroupsSelectedStudentIds != 0)" variant="primary"
              size="sm" :disabled="addAdminBtn.disabled" type="submit">{{ addAdminBtn.text }}</b-button>
            <b-button variant="danger" @click="$emit('addStudentStatus', false)" size="sm"
              :disabled="addAdminBtn.disabled">Cancel</b-button>
          </div>
        </b-form-group>
      </b-form>
    </div>
  </div>
</template>

<script>
export default {
  async created() {
    this.fetchData = true;
    this.usersubgroup_id = this.courseStudentModal.usersubgroup_id;
    await this.prepareExistingStudents();
    await this.getCourse();
    if (
      this.course.type.slug == "open_elective" ||
      this.course.type.slug == "minor" ||
      this.course.type.slug == "elective"
    ) {
      this.selectedStudents = [];
      this.StudentOptions = [];
      this.interDepartmentStudentsEnroll = true;
      await this.getAcademicYearSubgroups();
      // if (this.subgroupOptions.length > 0) {
      //   this.usersubgroup_id = this.subgroupOptions[0].value;
      //   await this.getSubgroupUsers();
      // }
    } else {
      await this.getSubgroupUsers();
    }
    this.fetchData = false;
  },
  props: ["courseStudentModal", "courseStudentsList"],
  data() {
    return {
      selectedStudents: [],
      StudentOptions: [],
      addAdminBtn: {
        text: "Submit",
        disabled: false,
      },
      course: null,
      interDepartmentStudentsEnroll: false,
      fetchData: false,
      existingStudents: {},
      subgroupOptions: [],
      fromSubgroupsIds: [],
      subgroupsStudentsOptions: {},
      fromSubgroupsSelectedStudentIds: [],
      usersubgroup_id: null,
    };
  },
  computed: {
    getCustomNames() {
      return this.$store.getters.getCustomNamings;
    },
  },
  methods: {
    selectAllStudentsOn(subgroupId) {
      this.subgroupsStudentsOptions[subgroupId].forEach(stud => {
        setTimeout(() => {
          this.fromSubgroupsSelectedStudentIds.push(stud.id);
        }, 100);
      });
    },
    updateFromSubgroupsStudentsOptions(subgroupId) {
      if (this.fromSubgroupsIds.includes(subgroupId)) {
        this.usersubgroup_id = subgroupId;
        this.getSubgroupUsers();
      }
    },
    async createStudents() {
      this.addAdminBtn = {
        text: "Please wait...",
        disabled: true,
      };
      let mainUrl = this.$store.getters.getAPIKey.createStudent;
      const url = mainUrl.replace("course_id", this.courseStudentModal.id);

      const students = {
        institution_users: this.fromSubgroupsSelectedStudentIds,
      };

      await this.selectedStudents.forEach((student) => {
        students.institution_users.push(student.id);
      });

      await this.$axios
        .post(url, students)
        .then(() => {
          this.$toast.success(
            `Students added to ${this.courseStudentModal.name}`,
            {
              position: "top",
              duration: 3000,
            }
          );
          this.$emit("addStudentStatus", true);
          // console.log(response);
        })
        .catch(() => {
          // console.log(error);
        });
      this.addAdminBtn = {
        text: "Please wait...",
        disabled: true,
      };
    },
    async getSubgroupUsers() {
      this.postCourses = true;
      let mainUrl = this.$store.getters.getAPIKey.getEnrollSubgroupUsersList;
      var url = mainUrl.replace("usersubgroup_id", this.usersubgroup_id);
      url = url + `?course=${this.courseStudentModal.id}`;
      // this.StudentOptions = [];
      // await this.getInstitutionStudents();
      await this.$axios
        .get(url)
        .then((response) => {
          this.selectedStudents = [];
          this.StudentOptions = [];
          let students = response.data;
          this.subgroupsStudentsOptions[this.usersubgroup_id] = [];
          students.forEach((staff) => {
            if (
              staff.pivot.usersubgrouprole_id == 2 &&
              !this.existingStudents[staff.pivot.institution_user_id] && staff.enroll_status == 'active'
            ) {
              if (!this.interDepartmentStudentsEnroll) {
                this.StudentOptions.push({
                  name: staff.first_name + " " + (staff.last_name || "") + `( ${staff.email} - ${staff.register_no ?? ''})`,
                  id: staff.pivot.institution_user_id,
                  code: staff.id,
                });
              } else {
                this.subgroupsStudentsOptions[this.usersubgroup_id].push({
                  name: (staff.roll_number ?? '') + ' ' + staff.first_name + " " + (staff.last_name || ""),
                  id: staff.pivot.institution_user_id,
                });
              }
            }
          });
          this.$forceUpdate();

          // console.log(response);
        })
        .catch(() => {
          // console.log(error.response);
        });

      this.fetchData = false;
    },
    async prepareExistingStudents() {
      this.existingStudents = {};
      await this.courseStudentsList.forEach((student) => {
        this.$set(
          this.existingStudents,
          student.institution_user_id,
          student.institution_user_id
        );
      });
    },
    addStudent(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
        id: newTag,
      };
      this.StudentOptions.push(tag);
      this.selectedStudents.push(tag);
    },

    async getCourse() {
      let url =
        this.$store.getters.getAPIKey.mainAPI +
        `/courses/${this.courseStudentModal.id}`;
      await this.$axios.get(url).then((response) => {
        this.course = response.data;
      });
    },

    async getAcademicYearSubgroups() {
      let url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usersubgroups?course=${this.courseStudentModal.id}`;
      await this.$axios.get(url).then((response) => {
        this.subgroupOptions = [];
        response.data.forEach((subgroup) => {
          this.subgroupOptions.push({
            text: subgroup.name + "-" + subgroup.usergroup.code,
            value: subgroup.id,
          });
        });
      });
    },
  },
};
</script>
