<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="sessions" v-else>
      <h5 class="font-weight-bolder">Delivery Types</h5>
      <b-button
        variant="primary"
        size="md"
        class="ml-4 mt-2"
        v-if="addDelivery == false"
        @click="addDelivery = !addDelivery"
        >Add Delivery Type</b-button
      >

      <div class="mt-2" ref="sessionForm"></div>
      <b-form
        class="mt-4"
        v-if="addDelivery"
        @submit.prevent="createDelivery()"
      >
        <b-form-group>
          <span
            class="alert alert-success text-center"
            v-if="successResponse != ''"
            >{{ successResponse }}</span
          >
          <h6 v-if="responseErrors.deliveryCreated" class="text-success">
            {{ responseErrors.deliveryCreated[0] }}
          </h6>
        </b-form-group>

        <b-form-group>
          <b-input
            placeholder="Delivery Name"
            v-model="delivery.name"
            :class="{ 'is-invalid': responseErrors.name }"
            class="text-center"
          ></b-input>
          <span
            class="text-danger"
            style="font-size: 14px"
            v-if="responseErrors.name"
            >{{ responseErrors.name[0] }}</span
          >
        </b-form-group>
        <b-form-group>
          <b-form-select
            v-model="delivery.view_order"
            :value="
              !delivery.view_order
                ? (delivery.view_order = null)
                : delivery.view_order
            "
            :options="viewOrderList"
            class="text-center"
            :class="{ 'is-invalid': responseErrors.view_order }"
          >
          </b-form-select>
          <span
            class="text-danger ml-4"
            style="font-size: 14px"
            v-if="responseErrors.view_order"
            >{{ responseErrors.view_order[0] }}</span
          >
        </b-form-group>

        <b-form-group>
          <div class="d-flex justify-content-between flex-wrap">
            <b-button
              type="submit"
              variant="primary"
              size="md"
              :disabled="deliveryBtn.disabled"
              >{{ deliveryBtn.text }}</b-button
            >
            <b-button
              variant="danger"
              size="md"
              class="ml-3"
              :disabled="deliveryBtn.disabled"
              @click="cancel()"
              >Cancel</b-button
            >
          </div>
        </b-form-group>
      </b-form>
      <div v-if="deliveryList.length != 0 && !addDelivery">
        <div class="table-responsive">
          <table class="table table-striped table-bordered">
            <thead class="text-left thead-dark">
              <tr>
                <th>Delivery Type</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="deliveryList.length == 0">
                <td class="text-danger" colspan="100">
                  No delivery created yet!
                </td>
              </tr>
              <tr v-else v-for="(delivery, index) in deliveryList" :key="index">
                <td class="align-middle">{{ delivery.name }}</td>
                <td class="align-middle">
                  <span
                    v-if="delivery.view_order == '0'"
                    class="btn m-0 p-0 text-info"
                    @click="getSpecificDelivery(delivery.id, { hide: true })"
                    ><i class="far fa-eye-slash"></i
                  ></span>
                  <span
                    v-else
                    class="btn m-0 p-0 text-info"
                    @click="getSpecificDelivery(delivery.id, { hide: false })"
                    ><i class="far fa-eye"></i
                  ></span>
                </td>
                <td class="align-middle">
                  <span
                    @click="getSpecificDelivery(delivery.id, undefined)"
                    class="btn m-0 p-0 text-info"
                    ><i class="far fa-edit"></i
                  ></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  created() {
    this.getDelivery();
  },
  data() {
    return {
      fetchData: false,
      deliveryId: "",
      delivery: {},
      successResponse: "",
      viewOrderList: [],
      addDelivery: false,
      deliveryList: [],
      deliveryBtn: {
        text: "Submit",
        disabled: false,
      },
      responseErrors: {},
    };
  },
  methods: {
    createDelivery() {
      this.deliveryBtn.text = "Please wait...";
      this.deliveryBtn.disabled = true;
      if (this.deliveryId != "") {
        this.updateDelivery();
      } else {
        const url = this.$store.getters.getAPIKey.deliveryType;
        axios
          .post(url, this.delivery)
          .then((response) => {
            this.successResponse =
              this.delivery.name + " is successfully created.";

            this.getDelivery();
            this.clearResponse();
            response;
          })
          .catch((error) => {
            this.responseErrors = error.response.data.errors;
            this.clearResponse();
            error;
          });
      }
    },
    getDelivery() {
      this.fetchData = true;
      const url = this.$store.getters.getAPIKey.deliveryType;

      axios
        .get(url)
        .then((response) => {
          this.deliveryList = [];
          this.deliveryList = response.data;
          this.viewOrderList = [{ value: null, text: "Select Order" }];
          if (this.deliveryList.length == 0) {
            this.viewOrderList.push({ value: "1", text: 1 });
          } else {
            for (let i = 1; i < this.deliveryList.length; i++) {
              this.viewOrderList.push({ value: i.toString(), text: i });
            }
          }

          this.fetchData = false;
          response;
        })
        .catch((error) => {
          error;
        });
    },
    getSpecificDelivery(delivery_id, hide) {
      if (hide != undefined) {
        this.fetchData = true;
        const url =
          this.$store.getters.getAPIKey.deliveryType + `/${delivery_id}`;
        axios
          .get(url)
          .then((response) => {
            // this.addDelivery = true;
            this.deliveryId = response.data.id;
            this.delivery.name = response.data.name;
            this.delivery.view_order = hide ? "0" : "1";
            this.updateDelivery();
            response;
          })
          .catch((error) => {
            error;
          });
      } else {
        this.$smoothScroll({
          scrollTo: this.$refs.sessionForm,
          duration: 1000,
          offset: -50,
        });
        const url =
          this.$store.getters.getAPIKey.deliveryType + `/${delivery_id}`;
        axios
          .get(url)
          .then((response) => {
            this.addDelivery = true;
            this.deliveryId = response.data.id;
            this.delivery.name = response.data.name;
            this.delivery.view_order = response.view_order;
            response;
          })
          .catch((error) => {
            error;
          });
      }
    },
    updateDelivery() {
      this.deliveryBtn.text = "Updating...";
      this.deliveryBtn.disabled = true;
      const url =
        this.$store.getters.getAPIKey.deliveryType + `/${this.deliveryId}`;

      axios
        .put(url, this.delivery)
        .then((response) => {
          this.responseErrors = {
            deliveryCreated: [this.delivery.name + " is successfully updated."],
          };
          this.deliveryId = "";
          this.getDelivery();
          this.clearResponse();
          response;
        })
        .catch((error) => {
          this.responseErrors = error.response.data.errors;
          this.clearResponse();
          error;
        });
    },

    cancel() {
      this.addDelivery = false;
      this.delivery.name = "";
      this.delivery.view_order = "0";
    },
    clearResponse() {
      setTimeout(() => {
        if (this.successResponse != "" || this.responseErrors.deliveryCreated) {
          this.addDelivery = false;
          this.delivery.name = "";
          this.delivery.view_order = "0";
          this.deliveryId = "";
        }
        this.responseErrors = {};
        this.successResponse = "";
        this.deliveryBtn.text = "Submit";
        this.deliveryBtn.disabled = false;
      }, 3000);
    },
  },
};
</script>

<style >
#deliveryList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

#deliveryList .list-group-item {
  width: 250px;
}
</style>
