var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.fetchData)?_c('div',{staticClass:"loading"},[_c('load-data')],1):_c('div',{staticClass:"approverForm"},[_c('b-form',{staticClass:"border-0",on:{"submit":function($event){$event.preventDefault();return _vm.prepareApproversForApi()}}},[_c('b-overlay',{attrs:{"show":_vm.loadApprovers,"spinner-variant":"primary","spinner-type":"grow","spinner-small":"","rounded":"md"}},[(!_vm.approverInfo.id)?_c('b-form-group',[_c('multiselect',{attrs:{"options":_vm.approversUsersOptions,"multiple":true,"close-on-select":false,"clear-on-select":false,"preserve-search":true,"placeholder":"Select Approvers","label":"name","track-by":"name","preselect-first":false},model:{value:(_vm.selectedApprovers),callback:function ($$v) {_vm.selectedApprovers=$$v},expression:"selectedApprovers"}},[_c('template',{slot:"selection"},[(_vm.selectedApprovers.length)?_c('span',{staticClass:"multiselect__single"},[_vm._v(_vm._s(_vm.selectedApprovers.length)+" approvers selected")]):_vm._e()])],2)],1):_vm._e(),(_vm.selectedApprovers.length != 0)?_c('b-form-group',[_c('table',{staticClass:"table table-bordered table-striped"},[_c('thead',{staticClass:"thead-dark"},[_c('tr',[_c('th',[_vm._v("Name")]),_c('th',[_vm._v("Role")]),_c('th',[_vm._v("Order")]),_c('th',[_vm._v("Special Privilege")])])]),_c('tbody',_vm._l((_vm.selectedApprovers),function(approver,approverIndex){return _c('tr',{key:approverIndex},[_c('td',{staticClass:"align-middle"},[_c('p',{staticStyle:{"font-size":"14px","font-weight":"600"}},[_vm._v(" "+_vm._s(approver.name)+" ")])]),_c('td',{staticClass:"align-middle"},[_c('b-form-input',{class:{
                      'form-group-error':
                        _vm.$v.selectedApprovers.$each[approverIndex].role.$error,
                    },staticStyle:{"height":"40px"},attrs:{"placeholder":"Enter role","disabled":_vm.approverBtn.disabled},model:{value:(approver.role),callback:function ($$v) {_vm.$set(approver, "role", $$v)},expression:"approver.role"}}),(
                    !_vm.$v.selectedApprovers.$each[approverIndex].role
                      .required &&
                    _vm.$v.selectedApprovers.$each[approverIndex].role.$dirty
                  )?_c('span',{staticClass:"input-error-message"},[_vm._v("Role is required!")]):_vm._e()],1),_c('td',{staticClass:"align-middle"},[_c('b-form-select',{class:{
                      'form-group-error':
                        _vm.$v.selectedApprovers.$each[approverIndex].order
                          .$error,
                    },staticStyle:{"height":"40px"},attrs:{"options":_vm.approvingOrderOptions,"disabled":_vm.approverBtn.disabled},model:{value:(approver.order),callback:function ($$v) {_vm.$set(approver, "order", $$v)},expression:"approver.order"}}),_c('br'),(
                    !_vm.$v.selectedApprovers.$each[approverIndex].order
                      .required &&
                    _vm.$v.selectedApprovers.$each[approverIndex].order.$dirty
                  )?_c('span',{staticClass:"input-error-message"},[_vm._v("Order is required!")]):_vm._e()],1),_c('td',{staticClass:"align-middle"},[_c('div',{staticClass:"d-flex align-items-baseline"},[_c('span',[_c('b-form-checkbox',{staticStyle:{"z-index":"-2"},model:{value:(approver.approve_all_same_order),callback:function ($$v) {_vm.$set(approver, "approve_all_same_order", $$v)},expression:"approver.approve_all_same_order"}})],1),_c('span',{staticClass:"text-info btn p-0 m-0 ml-3",attrs:{"id":'specialApproverInfo' + approverIndex}},[_c('i',{staticClass:"fas fa-info"})]),_c('b-popover',{attrs:{"target":'specialApproverInfo' + approverIndex,"triggers":"hover","placement":"top"}},[_vm._v(" If any user with special privilege approves a form, all the other approvers in the same order will be set to auto-approved for that form. ")])],1)])])}),0)])]):_vm._e(),(_vm.selectedApprovers.length != 0)?_c('b-form-group',[_c('div',[_c('b-button',{attrs:{"variant":"primary","type":"submit","size":"sm","disabled":_vm.approverBtn.disabled}},[_vm._v(_vm._s(_vm.approverBtn.text))]),_c('b-button',{staticClass:"ml-2",attrs:{"variant":"danger","size":"sm","disabled":_vm.approverBtn.disabled},on:{"click":function($event){return _vm.cancel()}}},[_vm._v("Cancel")])],1)]):_vm._e()],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }