<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="custom-naming" v-else>
      <table
        class="table table-striped table-bordered"
        v-if="activityLogs.length > 0"
      >
        <thead class="thead-dark text-left">
          <tr>
            <th>No.</th>
            <th>Activity</th>
            <th>Summary</th>
            <th>User</th>
            <th>UTC time</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(log, logIndex) in activityLogs" :key="logIndex">
            <td>{{ activityLogs.length - logIndex }}</td>
            <td class="align-middle">{{ log.activity }}</td>
            <td class="align-middle">{{ log.summary }}</td>
            <td class="align-middle">
              {{ log.institution_user.first_name }}
              {{ log.institution_user.last_name }}
            </td>
            <td class="align-middle">{{ log.created_at }}</td>
            <td v-b-tooltip.hover :title="log.model_data">
              <i class="far fa-eye"></i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  async created() {
    this.fetchData = true;
    await this.getInstitutionActivityLogs();
    this.fetchData = false;
  },
  data() {
    return {
      fetchData: false,
      activityLogs: [],
    };
  },
  methods: {
    async getInstitutionActivityLogs() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        "/institutionactivitylogs?institutionlog=1";
      await this.$axios.get(url).then((res) => (this.activityLogs = res.data));
    },
  },
};
</script>
