<template>
  <div>
    <br />

    <b-form class="" @submit.prevent="prepareDataForLiveClass()">
      <b-form-group label="Select Course">
        <multiselect
          v-model="liveClass.course_id"
          :options="coursesOptions"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          placeholder="Select Courses"
          label="text"
          track-by="value"
          :preselect-first="false"
          :class="{ 'is-invalid': responseErrors.course_id }"
          :disabled="liveClass.id ? true : false"
        >
        </multiselect>

        <span class="text-danger" v-if="responseErrors.course_id">{{
          responseErrors.course_id[0]
        }}</span>
      </b-form-group>
      <b-form-group label="Select date">
        <b-form-datepicker
          v-model="liveClassDate"
          :disabled="liveClass.id ? true : false"
        ></b-form-datepicker>
      </b-form-group>
      <b-form-group label="Select Start Time">
        <b-form-timepicker
          v-model="startTime"
          :class="{ 'is-invalid': responseErrors.course_id }"
          :disabled="liveClass.id ? true : false"
        ></b-form-timepicker>
        <span class="text-danger" v-if="responseErrors.start_datetime">{{
          responseErrors.start_datetime[0]
        }}</span>
      </b-form-group>
      <b-form-group label="Select End Time">
        <b-form-timepicker
          v-model="endTime"
          :disabled="liveClass.id ? true : false"
        ></b-form-timepicker>
      </b-form-group>

      <b-form-group>
        <b-form-textarea
          v-model="liveClass.summary"
          :class="{ 'is-invalid': responseErrors.summary }"
          placeholder="Enter summary..."
          rows="3"
          max-rows="6"
          :disabled="liveClass.id ? true : false"
        ></b-form-textarea>
        <span class="text-danger" v-if="responseErrors.summary">{{
          responseErrors.summary[0]
        }}</span>
      </b-form-group>
      <b-form-group>
        <b-form-textarea
          v-model="liveClass.description"
          placeholder="Enter description..."
          rows="3"
          max-rows="6"
          :disabled="liveClass.id ? true : false"
        ></b-form-textarea>
      </b-form-group>
      <b-form-group>
        <b-form-checkbox v-model="liveClass.notify_users" v-if="!liveClass.id">
          Notify Participants Via SMS
        </b-form-checkbox>
      </b-form-group>
      <b-form-group>
        <p
          style="font-size: 14px; font-weight: 600"
          :class="smsCount < 1000 ? 'text-danger' : 'text-dark'"
        >
          Balance SMS Count:
          <span>{{ smsCount }}</span>
        </p>
      </b-form-group>
      <b-form-group>
        <div class="d-flex align-items-baseline">
          <b-form-checkbox
            v-model="liveClass.status"
            :disabled="liveClass.notify_users"
            v-if="liveClass.id"
          >
            Cancel this Live Class
          </b-form-checkbox>
          <b-form-checkbox
            v-model="cancelSmsAndLiveClass"
            :disabled="liveClass.status"
            v-if="liveClass.id"
            class="ml-3"
          >
            Cancel Live Class with SMS alert.
          </b-form-checkbox>
        </div>
      </b-form-group>
      <b-form-group>
        <div class="d-flex justify-content-between">
          <b-button
            variant="primary"
            type="submit"
            size="md"
            :disabled="liveClassBtn.disabled"
            >{{ liveClassBtn.text }}</b-button
          >
          <b-button
            variant="danger"
            size="md"
            :disabled="liveClassBtn.disabled"
            @click="cancel()"
            >Cancel</b-button
          >
        </div>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  props: ["coursesOptions", "liveClassUpdate", "coursesObject"],
  async created() {
    this.smsCount = this.$store.getters.getSmsCount.smsBalance;

    this.liveClass = {};
    await this.$set(this.liveClass, "course_id", []);
    await this.$set(this.liveClass, "notify_users", false);
    await this.$set(this.liveClass, "status", null);
    // await this.$set(this.liveClass, "status", null);

    if (this.liveClassUpdate.id) {
      let eventDate = moment(this.liveClassUpdate.start_datetime);
      let startT = moment(this.liveClassUpdate.start_datetime);
      let endT = moment(this.liveClassUpdate.end_datetime);
      let liveClassStatus = this.liveClassUpdate.status;
      this.liveClassDate = new Date(eventDate.format("YYYY/MM/DD"));
      this.startTime = startT.format("HH:mm");
      this.endTime = endT.format("HH:mm");
      this.liveClass = this.liveClassUpdate;
      this.liveClass.status = liveClassStatus == "cancelled" ? true : null;
      let courseId = this.liveClassUpdate.course_id;
      this.liveClass.course_id = [];
      this.liveClass.course_id.push(this.coursesObject[courseId]);
    }
  },
  data() {
    return {
      smsCount: null,
      liveClassDate: "",
      liveClass: {},
      liveClassBtn: {
        text: "Submit",
        disabld: false,
      },
      cancelSmsAndLiveClass: false,
      responseErrors: {},
      responseSuccess: {},

      startTime: "",
      endTime: "",
    };
  },
  methods: {
    async prepareDataForLiveClass() {
      this.liveClassBtn.text = "Please wait...";
      this.liveClassBtn.disabled = true;
      this.liveClass.start_datetime = (await this.liveClassDate) + " " + this.startTime;
      this.liveClass.end_datetime = (await this.liveClassDate) + " " + this.endTime;
      if (moment(this.liveClass.start_datetime) > moment(this.liveClass.end_datetime)) {
        this.liveClassBtn = {
          text: "Submit",
          disabld: false,
        };
        return this.$toast.error("Start Time should be prior to End Time!", {
          position: "top",
          duration: 3000,
        });
      }
      let course_id = [];
      await this.liveClass.course_id.forEach((course) => {
        course_id.push(course.value);
      });

      let liveClassPostData = await {
        courses: course_id,
        start_datetime: this.liveClass.start_datetime,
        end_datetime: this.liveClass.end_datetime,
        summary: this.liveClass.summary,
        description: this.liveClass.description,
        notify_users: !this.liveClass.notify_users ? null : this.liveClass.notify_users,
      };
      let liveClassStatus = {};
      if (this.cancelSmsAndLiveClass == true) {
        liveClassStatus = {
          notify_users: true,
          status: "cancelled",
        };
      }
      if (this.liveClass.status == true) {
        liveClassStatus = {
          status: "cancelled",
        };
      }

      let liveClassCreation = this.liveClass.id
        ? await this.updateLiveClass(liveClassStatus)
        : await this.createGoogleEvent(liveClassPostData);
      if (liveClassCreation) {
        let msg = this.liveClass.id
          ? "Live Class is successfully updated"
          : "Live Class is successfully created";
        this.responseSuccess["created"] = ["Live Class is successfully created"];
        this.$toast.success(msg, {
          position: "top",
          duration: 3000,
        });
        this.clearResponse();
      }
      if (!liveClassCreation) {
        this.clearResponse();
      }
    },
    async createGoogleEvent(liveClassPostData) {
      let status = null;
      const url = this.$store.getters.getAPIKey.googleEvent;
      await axios
        .post(url, liveClassPostData)
        .then((response) => {
          status = true;
          response;
        })
        .catch((error) => {
          this.responseErrors = error.response.data.errors;
          status = false;
          error;
        });
      return status;
    },
    async updateLiveClass(liveClassStatus) {
      let status = null;
      const url = this.$store.getters.getAPIKey.googleEvent + `/${this.liveClass.id}`;

      await axios
        .put(url, liveClassStatus)
        .then((response) => {
          status = true;
          response;
        })
        .catch((error) => {
          this.responseErrors = error.response.data.errors;
          status = false;
          error;
        });
      return status;
    },
    clearResponse() {
      this.liveClassBtn.text = "Submit";
      setTimeout(() => {
        if (this.responseSuccess.created) {
          this.$emit("liveClassCreated");
          this.cancel();
          this.responseSuccess = {};
        }
        this.responseErrors = {};
        this.liveClassBtn.disabled = false;
      }, 5000);
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>
