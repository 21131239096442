<template>
  <div>
    <b-col sm="12">
      <h3 style="text-align: center; font-size:18px !important;">
        Edit Attendance
      </h3>
      <b-row>
        <b-col>
          <b-form-group>
            <label class=" required-label" for="inline-form-input-from">Choose Course:</label>
            <b-form-select v-model="course_id" :options="coursesOptions"></b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form inline style="border: none;" class="p-2" v-if="course_id">
            <b-form-group>
              <label class="" style="justify-content: left;" for="inline-form-input-from">From (optional):</label>
              <b-form-datepicker @input="updateAttendanceDateInput" aria-placeholder="From" id="inline-form-input-from"
                v-model="date_from" class="mr-2">
              </b-form-datepicker>
            </b-form-group>
            <b-form-group>
              <label class="" style="justify-content: left;" for="inline-form-input-to">To (optional):</label>
              <b-form-datepicker @input="updateAttendanceDateInput" aria-placeholder="To" id="inline-form-input-to"
                v-model="date_upto" class="mr-2">
              </b-form-datepicker>
            </b-form-group>

            <span v-if="!enableEditAttendanceButton" class="text-danger"
              style="font-size: 13px !important; font-weight: bold;">
              Please choose any 30 day date range and proceed to edit attendance</span>
            <b-button v-else class="my-2" variant="primary" @click="getCourseAttendanceWithReport(false)"
              size="sm">Proceed To Edit</b-button>

          </b-form>
        </b-col>
      </b-row>

      <div class="mb-1" v-if="(viewReport === true)">
        <b-button v-if="enableEdit" @click="saveAttendanceChanges" :variant="'success'">Save
          Changes{{ editedDateSessionsStudentsAttTypeCache.length ?
              '(' + editedDateSessionsStudentsAttTypeCache.length + ')' : ''
          }}</b-button>
        <b-button v-if="enableEdit" @click="cancelAttendanceEdit()" class="ml-2"
          :variant="'danger'">Cancel Attendance Editing</b-button>
        <!-- <span class="text-danger" v-if="(canEditAttendance == false)"
          style="font-size: 14px !important; font-weight: bold;">
          Please choose any 30 day date range and proceed to edit attendance</span> -->
      </div>


      <div class="table-container">
        <table v-if="(viewReport === true)" class="table table-bordered black-border  mb-5">
          <thead style="position: sticky;top: 0;">

            <tr v-if="enableEdit">
              <th style="" class="text-center text-white bg-dark"></th>
              <th style="" class="text-center text-white bg-dark"></th>
              <th v-for="(data, sessionIndex) in dateSessionsHeaderRow" :key="sessionIndex"
                class="text-center text-white bg-dark">
                <b-link @click="$bvModal.show('newLessonPlanModel')">Add new lesson</b-link>
                <multiselect @input="changeInAttendanceAttachments(data.date, data.session_id, 'lessonplans')"
                  placeholder="lesson" v-model="dateSessionsAttachments[data.date][data.session_id].lessonplans"
                  :options="[addNewLessonLabel, ...lessonplans]" :multiple="true" :close-on-select="false" track-by="id"
                  label="lesson" :searchable="true" :internal-search="true" :custom-label="lessonplansCustomLabel"
                  :show-labels="false">
                  <template v-slot:tag="{ option }">
                    <span v-if="(option.id !== null)" v-b-popover.hover.top="option.lesson"
                      class="tag-label badge badge-success">{{
                          option.lesson.substr(0, 10)
                      }}..</span>
                    <span v-else></span>
                  </template>
                  <template slot="option" slot-scope="props">
                    <div v-if="(props.option.id !== null)" v-b-popover.hover.top="props.option.lesson">{{
                        props.option.lesson
                    }}</div>
                    <div v-else class="fixed-label" @click="handleCreateLessonFixedLabelClick">
                      {{ addNewLessonLabel.lesson }}
                    </div>
                  </template>
                </multiselect>
              </th>
            </tr>

            <tr v-if="enableEdit">
              <th style="" class="text-center text-white bg-dark"></th>
              <th style="" class="text-center text-white bg-dark"></th>
              <th v-for="(data, sessionIndex) in dateSessionsHeaderRow" :key="sessionIndex"
                class="text-center text-white bg-dark">
                <multiselect @input="changeInAttendanceAttachments(data.date, data.session_id, 'delivery_methods')"
                  placeholder="method" v-model="dateSessionsAttachments[data.date][data.session_id].delivery_methods"
                  label="name" track-by="id" :options="coursedeliverymethods" :close-on-select="false" :multiple="true"
                  :taggable="true">
                </multiselect>
              </th>
            </tr>

            <tr v-if="enableEdit">
              <th style="" class="text-center text-white bg-dark"></th>
              <th style="" class="text-center text-white bg-dark"></th>
              <th v-for="(data, sessionIndex) in dateSessionsHeaderRow" :key="sessionIndex"
                class="text-center text-white bg-dark">
                <b-form-select placeholder="Type"
                  @change="changeInAttendanceAttachments(data.date, data.session_id, 'class_type')"
                  v-model="dateSessionsAttachments[data.date][data.session_id].class_type" :options="classtypes"
                  value-field="id" text-field="name"></b-form-select>
              </th>
            </tr>

            <tr>
              <th style="min-width: 50px;" class="text-center text-white bg-dark">Roll<br>No</th>
              <th style="min-width: 130px;" class="text-center text-white bg-dark">Name</th>
              <th v-for="(data, sessionIndex) in dateSessionsHeaderRow" :key="sessionIndex"
                class="text-center text-white bg-dark">
                {{ data.date }}-{{ data.session }} <br>
                {{ AttDateMarkedBy[data.attendance_date_id].join(', ') }}
                <span v-if="enableEdit" class="text-danger btn p-0 m-0" @click="deleteAttendance.date = data.date;
                deleteAttendance.sessionName = data.session;
                deleteAttendance.session = data.session_id;
                deleteAttendance.confirmText = '';
                $bvModal.show('deleteAttendanceConfirmation')"><i class="fas fa-trash"></i></span>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(student, dataIndex) in courseStudents" :key="dataIndex">
              <td class="bg-dark  text-white">{{ student.roll_number }}</td>
              <td style="position:sticky; left:5px;" class="bg-dark  text-white">{{ student.first_name }} {{
                  student.last_name
              }}</td>
              <td v-for="(data, sessionIndex) in dateSessionsHeaderRow" :key="sessionIndex"
                v-b-popover.hover.top="studAttTypeOnDateSession(data.date, data.session_id, student.id) == null ? 'Attendance for this student has not been marked, this window can only be used for marking attendance once it has already been marked' : ''">
                <b-link class="px-2" :id="data.date + '-' + data.session_id + '-' + student.id"
                  v-if="(studAttTypeOnDateSession(data.date, data.session_id, student.id) && enableEdit)">
                  <span class="text-secondary"
                    v-if="editedStudAttTypeOnDateSession(data.date, data.session_id, student.id)">
                    {{ editedStudAttTypeOnDateSession(data.date, data.session_id, student.id) }}-></span>
                  {{ studAttTypeOnDateSession(data.date, data.session_id, student.id) }}
                  <b-popover :target="(data.date + '-' + data.session_id + '-' + student.id)" triggers="hover"
                    placement="top">
                    <b-row class="mx-1">
                      <div v-for="attType in attendanceTypes" :key="attType.id">
                        <div @click="editAttendance(data.date, data.session_id, student.id, attType.id)" :class="{
                          'att-type-box': true, 'selected-att-type-box': (studAttTypeOnDateSession(data.date, data.session_id, student.id) ==
                            attType.code), 'unselected-att-type-box': studAttTypeOnDateSession(data.date, data.session_id, student.id) !=
                              attType.code
                        }"> {{ attType.code }}</div>
                      </div>
                    </b-row>
                  </b-popover>
                </b-link>
                <span v-else-if="studAttTypeOnDateSession(data.date, data.session_id, student.id)">{{
                    studAttTypeOnDateSession(data.date,
                      data.session_id, student.id)
                }}</span>
                <span v-else>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="(viewReport === null)">Loading Schedules...</div>
      <div v-if="(viewReport === false)">Data not Available</div>
    </b-col>



    <b-modal id="deleteAttendanceConfirmation" no-close-on-backdrop no-close-on-keyboard no-close-on-esc
      hide-header-close hide-footer hide-header centered size="sm">
      <h6 style="font-size: 18px; font-weight: 600" class="text-danger">
        <span> Are you sure you want to delete attendance on {{ deleteAttendance.date }}, session
          {{ deleteAttendance.sessionName }} ? </span>
      </h6>
      <div>
        <b-form-group id="input-group-1" label-for="input-1" description="">
          <label class="" for="">Type 'DELETE' and press delete button to complete deletion:</label>
          <b-form-input id="input-1" v-model="deleteAttendance.confirmText" type="text"
            placeholder="Enter  DELETE"></b-form-input>
        </b-form-group>
      </div>
      <div class="buttons">
        <b-button :disabled="deleteAttendance.confirmText != 'DELETE'" size="sm" variant="primary"
          @click="deleteAttendanceDate()">Delete</b-button>
        <b-button size="sm" variant="danger" class="ml-2"
          @click="$bvModal.hide('deleteAttendanceConfirmation')">Cancel</b-button>
      </div>
    </b-modal>
    <b-modal id="newLessonPlanModel" no-close-on-backdrop no-close-on-keyboard no-close-on-esc hide-header-close
      hide-footer hide-header centered size="md">
      <addLessonPlanModelOnNewAttMarking @success="getCourseLessonPlans(); $bvModal.hide('newLessonPlanModel')"
        @cancel="$bvModal.hide('newLessonPlanModel')" :courseId="course_id" :siNo="lessonplans.length" />
    </b-modal>
  </div>
</template>

<script>
import addLessonPlanModelOnNewAttMarking from '../attendence/addLessonPlanModelOnNewAttMarking.vue';
import moment from "moment";
export default {
  props: ["propSessions"],
  async created() {
    await this.getMyCourses();

    // --------- for debug-----------------
    // this.course_id = 102;
    // this.getCourseAttendanceWithReport();
    // --------- for debug-----------------
  },
  components: {
    addLessonPlanModelOnNewAttMarking,
  },
  data() {
    return {
      viewReport: false,
      enableEdit: false,
      weekdayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      coursesOptions: [],
      course_id: null,

      date_upto: null,
      date_from: null,
      viewingReportDateRange: {
        date_upto: null,
        date_from: null,
      },

      sessions: {},
      attendanceTypes: {},
      courseStudents: {},
      dateSessionsStudentsAttType: {},
      editedDateSessionsStudentsAttTypeCache: [], //original att type of editted attendances will be added to this
      dateSessionsAttDateId: {},
      AttDateMarkedBy: {},
      dateSessionsAttachments: {},
      classtypes: [],
      lessonplans: [],
      addNewLessonLabel: { id: null, lesson: 'Add new Lesson plan' },
      coursedeliverymethods: [],

      dateSessionsHeaderRow: [],
      changedAttDateSessionData: [], //{date:date,session_id:sessionId,type:type}; // type [class_type,delivery_methods,lessonplans]

      deleteAttendance: {
        date: '',
        sessionName: '',
        session: '',
        confirmText: ''
      },
      enableEditAttendanceButton: false,
    };
  },
  computed: {
    numberOfDaysInDateRange() {
      this.date_from;
      this.date_upto;
      if (this.viewingReportDateRange.date_from && this.viewingReportDateRange.date_upto) {
        var start = moment(this.viewingReportDateRange.date_from);
        var end = moment(this.viewingReportDateRange.date_upto);
        if (start.format('DD/MM/YYYY') == end.format('DD/MM/YYYY')) return 1;
        return end.diff(start, "days");
      }
      return 0;
    },
  },
  methods: {
    cancelAttendanceEdit(){
      const result = window.confirm('The changes that you have made has will not been saved.');
      if (result) {
        // this.getCourseAttendanceWithReport(true); // refresh data
        this.$root.clearSubContent();
      }
    },
    async updateAttendanceDateInput() {
      let newEndDate = null;
      if (this.date_from && this.date_upto) {
        const diffInTime = new Date(this.date_upto) - new Date(this.date_from);
        const diffInDays = diffInTime / (1000 * 3600 * 24);
        if (diffInDays > 30) {
          console.log('diffInDays', diffInDays);
          newEndDate = new Date(this.date_from);
          newEndDate.setDate(newEndDate.getDate() + 29);
        }
        this.enableEditAttendanceButton = true;
      } else {
        this.enableEditAttendanceButton = false;
      }
      if (newEndDate) {
        this.$toast.error("You can only edit attendance of any 30 days at a time", {
          position: "top",
          duration: 5000,
        });
        setTimeout(() => {
          this.date_upto = newEndDate;
        }, 200);
      }
    },
    handleCreateLessonFixedLabelClick() {
      this.$bvModal.show('newLessonPlanModel');
    },
    changeInAttendanceAttachments(date, sessionId, type) {
      // console.log(date, sessionId, type);
      let data = { date: date, session_id: sessionId, type: type }; // type [class_type,delivery_methods,lessonplans]
      if (!this.changedAttDateSessionData.includes(data)) this.changedAttDateSessionData.push(data);
    },
    async saveAttendanceChanges() {
      let attTypeId = null;
      let attDateId = null;
      let newAttendances = [];
      this.editedDateSessionsStudentsAttTypeCache.forEach(cache => {
        attTypeId = this.dateSessionsStudentsAttType[cache.date][cache.session_id][cache.student_id];
        attDateId = this.dateSessionsAttDateId[cache.date][cache.session_id];
        newAttendances.push({
          attendance_date_id: attDateId,
          attendee_id: cache.student_id,
          attendance_type_id: attTypeId,
        });
      });

      let newAttDateLessonPlans = [];
      let newAttDateDeliveryMethods = [];
      let newAttDateClasstype = [];
      this.changedAttDateSessionData.forEach(changedData => {
        let attDateId = this.dateSessionsAttDateId[changedData.date][changedData.session_id];
        if (changedData.type == 'class_type') {
          newAttDateClasstype.push({
            attendance_date_id: attDateId,
            classtype_id: this.dateSessionsAttachments[changedData.date][changedData.session_id].class_type,
          });
        }
        if (changedData.type == 'delivery_methods') {
          let deliverymethods = this.dateSessionsAttachments[changedData.date][changedData.session_id].delivery_methods.map(obj => obj.id);
          newAttDateDeliveryMethods.push({
            attendance_date_id: attDateId,
            deliverymethods: deliverymethods,
          });
        }
        if (changedData.type == 'lessonplans') {
          let lessonplans = this.dateSessionsAttachments[changedData.date][changedData.session_id].lessonplans.map(obj => obj.id).filter(id => id !== null);
          newAttDateLessonPlans.push({
            attendance_date_id: attDateId,
            lessonplans: lessonplans,
          });
        }
      });

      const url = `${this.$store.getters.getAPIKey.mainAPI}/attendancedates/course/editandreport/save`;
      await this.$axios
        .post(url, {
          course: this.course_id,
          newAttendances: newAttendances,
          newAttDateClasstype: newAttDateClasstype,
          newAttDateDeliveryMethods: newAttDateDeliveryMethods,
          newAttDateLessonPlans: newAttDateLessonPlans,
        })
        .then(() => {
          this.$toast.success("Attendance data updated successfully", {
            position: "top",
            duration: 4000,
          });
        })
        .catch(() => {
          this.$toast.error("Failed to update, please try again", {
            position: "top",
            duration: 4000,
          });
        });

      // post data on success 
      // clear cache 
      this.editedDateSessionsStudentsAttTypeCache = [];
    },
    async editAttendance(date, sessionId, studentId, newAttTypeId) {
      let cacheIndex = null;
      await this.editedDateSessionsStudentsAttTypeCache.forEach((cache, index) => {
        if (cache.date == date && cache.session_id == sessionId && cache.student_id == studentId) cacheIndex = index;
      });
      if (cacheIndex === null) {
        // a new change
        let originalAttTypeId = this.dateSessionsStudentsAttType[date][sessionId][studentId];
        if (originalAttTypeId == newAttTypeId) return;
        this.editedDateSessionsStudentsAttTypeCache.push(
          { date: date, session_id: sessionId, student_id: studentId, attendance_type_id: originalAttTypeId }
        );
        this.dateSessionsStudentsAttType[date][sessionId][studentId] = newAttTypeId;
      } else {
        // already changed
        let originalAttTypeId = this.editedDateSessionsStudentsAttTypeCache[cacheIndex].attendance_type_id;
        if (newAttTypeId == originalAttTypeId) { // return to original
          this.dateSessionsStudentsAttType[date][sessionId][studentId] = newAttTypeId;
          this.editedDateSessionsStudentsAttTypeCache.splice(cacheIndex, 1);
        } else { // change
          this.dateSessionsStudentsAttType[date][sessionId][studentId] = newAttTypeId;
        }
      }
      // console.log();
      // this.$forceUpdate();
    },
    editedStudAttTypeOnDateSession(date, sessionId, studentId) {
      // date; sessionId; studentId;
      // if editted then return original to show refrence
      let attendance = null;
      this.editedDateSessionsStudentsAttTypeCache.forEach(cache => {
        if (cache.date == date && cache.session_id == sessionId && cache.student_id == studentId) {
          attendance = this.attendanceTypes[cache.attendance_type_id].code;
        }
      });
      return attendance;
    },
    studAttTypeOnDateSession(date, sessionId, studentId) {
      if (date in this.dateSessionsStudentsAttType) {
        if (sessionId in this.dateSessionsStudentsAttType[date]) {
          if (studentId in this.dateSessionsStudentsAttType[date][sessionId]) {
            let attType = this.dateSessionsStudentsAttType[date][sessionId][studentId];
            if (attType != null) {
              return this.attendanceTypes[attType].code;
            }
          }
        }
      }
      return null;
    },
    async getCourseAttendanceWithReport(viewOnly) {
      this.enableEdit = false;

      this.viewReport = null;
      this.sessions = {};
      this.attendanceTypes = {};
      this.courseStudents = {};
      this.dateSessionsStudentsAttType = {};
      this.editedDateSessionsStudentsAttTypeCache = [];
      this.dateSessionsAttDateId = {};
      this.AttateMarkedBy = {};
      this.dateSessionsAttachments = {};
      this.classtypes = [];
      this.lessonplans = [];
      this.coursedeliverymethods = [];
      this.dateSessionsHeaderRow = [];
      this.changedAttDateSessionData = [];
      if (!this.course_id) return;

      const url = `${this.$store.getters.getAPIKey.mainAPI}/attendancedates/course/editandreport`;
      var responseData = [];
      await this.$axios
        .post(url, { course: this.course_id, start_date: this.date_from, upto_date: this.date_upto })
        .then((response) => {
          responseData = response.data;
        })
        .catch((error) => {
          error;
          this.viewReport = false;
          return;
        });

      this.sessions = responseData.sessions;
      this.attendanceTypes = responseData.attendanceTypes;
      this.courseStudents = responseData.courseStudents;

      this.dateSessionsStudentsAttType = responseData.dateSessionsStudentsAttType;
      this.dateSessionsAttDateId = responseData.dateSessionsAttDateId;
      this.dateSessionsAttachments = responseData.dateSessionsAttachments;
      this.AttDateMarkedBy = responseData.AttDateMarkedBy;

      let classtypes = responseData.classtypes;
      let lessonplans = responseData.lessonplans;
      let coursedeliverymethods = responseData.coursedeliverymethods;

      coursedeliverymethods.forEach(coursedeliverymethod => {
        this.coursedeliverymethods.push({
          id: coursedeliverymethod.id, name: coursedeliverymethod.name,
        });
      });
      this.classtypes.push({
        id: null, name: 'Regular',
      });
      classtypes.forEach(classtype => {
        this.classtypes.push({
          id: classtype.id, name: classtype.name,
        });
      });

      lessonplans.forEach(lessonplan => {
        this.lessonplans.push({
          id: lessonplan.id, lesson: lessonplan.lesson,
        });
      });

      // backup for edit reference
      // this.dateSessionsStudentsAttTypeCache = responseData.dateSessionsStudentsAttType;

      this.dateSessionsHeaderRow = [];
      for (const date in this.dateSessionsAttDateId) {
        for (const sessionId in this.dateSessionsAttDateId[date]) {
          this.dateSessionsHeaderRow.push({
            date: date,
            session_id: sessionId,
            attendance_date_id: this.dateSessionsAttDateId[date][sessionId],
            session: this.sessions[sessionId].name
          });
        }
      }

      if (this.date_from && this.date_upto) {
        this.viewingReportDateRange.date_from = this.date_from;
        this.viewingReportDateRange.date_upto = this.date_upto;
      }
      this.viewReport = true;

      if (!viewOnly) this.enableAttendanceEdit();
    },
    enableAttendanceEdit() {
      if (this.numberOfDaysInDateRange > 0 && this.numberOfDaysInDateRange < 31) this.enableEdit = true;
    },

    async getMyCourses() {
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/institutionuser/courses`;
      await this.$axios
        .get(url)
        .then((response) => {
          let responseData = response.data;

          this.coursesOptions = [
            { value: null, text: "Select Course" },
          ];

          responseData.forEach((course) => {
            this.coursesOptions.push({
              value: course.id,
              text: `${course.name}(${course.usersubgroup.code}-${course.usersubgroup.usergroup.code})`,
            });
          });
        })
        .catch(() => {
          // console.log(error.response);
        });
    },


    async deleteAttendanceDate() {
      let attDateId = this.dateSessionsAttDateId[this.deleteAttendance.date][this.deleteAttendance.session];

      // reove data from these 3 so dont want to reload 
      // this.dateSessionsStudentsAttType = responseData.dateSessionsStudentsAttType;
      // this.dateSessionsAttDateId = responseData.dateSessionsAttDateId;
      // this.dateSessionsAttachments = responseData.dateSessionsAttachments;

      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/attendancedates/${attDateId}`;
      await this.$axios
        .delete(url)
        .then(() => {
          this.$toast.success("Attendance deleted", {
            position: "top",
            duration: 4000,
          });
          this.$bvModal.hide('deleteAttendanceConfirmation');
        })
        .catch(() => {
          this.$toast.error("Failed to delete attendance", {
            position: "top",
            duration: 7000,
          });
        });

      this.getCourseAttendanceWithReport(false);
    },
    lessonplansCustomLabel(id, lesson) {
      id;
      return `${lesson}`
    },
    async getCourseLessonPlans() {
      let lessonListUrl = this.$store.getters.getAPIKey.lessonList;
      const url = lessonListUrl.replace("course_id", this.course_id);
      await this.$axios
        .get(url)
        .then((response) => {
          let lessons = response.data;
          this.lessonplans = [];
          lessons.forEach(lessonplan => {
            this.lessonplans.push({
              id: lessonplan.id, lesson: lessonplan.lesson,
            });
          });
        })
        .catch(() => { });
    },
  },
};
</script>

<style  >
.tag-label {
  max-width: 80px;
  /* Adjust the width as needed */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.c-border {
  border: 2px solid;
}

.att-type-box {
  height: 20px;
  min-width: 20px;
  text-align: center;
  cursor: pointer;
  border-radius: 6px;
  padding: 2px;
  margin: 2px;
}

.selected-att-type-box {
  background-color: #10dc10;
}

.unselected-att-type-box {
  background-color: #d3d3d3;
}

.table-container {
  max-height: 90vh !important;
  /* Adjust the maximum height as needed */
  overflow-y: auto !important;
}

.black-border th,
.black-border td,
.black-border tr {
  border: 1px solid rgb(175, 175, 175);
}
</style>
