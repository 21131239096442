<template>
  <div class="container">
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div v-if="!fetchData">
      <b-row class="control-buttons">
        <b-col v-if="resourceaction == 'list'" style="text-align:left;">
          <b-button v-if="$store.getters.defaultInstitute.userRole != 'Student'" variant="success" class="text-white sm"
            size="sm" @click="createHolydayForm">Add Holiday</b-button>
        </b-col>
        <b-col v-if="resourceaction != 'list'" style="text-align:right;">
          <b-button variant="danger" class="text-white sm" size="sm" :disabled="actionButton.disabled"
            @click="resourceaction = 'list'">Cancel</b-button>
        </b-col>
      </b-row>
      <div v-if="(resourceaction == 'list')">
        <div v-if="(usersubgroup_id != null)">
          <h3 style="text-align: center; font-size:18px !important;">
            Holiday's
          </h3>
          <b-row>
            <b-col sm="12">
              <div class="table-responsive rounded">
                <table class="table table-striped table-bordered">
                  <thead class="thead-dark text-left">
                    <tr>
                      <th>
                        SI. No.
                      </th>
                      <th>
                        Date
                      </th>
                      <th>
                        Name
                      </th>
                      <th>
                        Lesson plan preparation
                      </th>
                      <!-- <th>
                      {{ subgroupCustomName }}
                    </th> -->
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(holyday, holyindex) in holydays" :key="holyindex">
                      <td>{{ holyindex + 1 }}</td>
                      <td>{{ moment(holyday.date, 'YYYY-MM-DD').format('DD/MM/YYYY') }}
                        -{{ weekdayNames[new Date(holyday.date).getDay()] }}
                      </td>
                      <td>
                        {{ holyday.name }}
                      </td>
                      <td>
                        {{ holyday.exclude_from_lessonplan?'Excluded':'' }}
                      </td>
                      <!-- <td>{{ holyday.usersubgroup.code }}-{{ holyday.usersubgroup.usergroup.code }} </td> -->
                      <td>
                        <!-- <span class="text-info btn m-0 p-0 sm" size="sm" :disabled="actionButton.disabled"
                        @click="editHolydayForm(holyindex)"><i class="fas fa-pen ml-3"></i></span> -->
                        <span class="text-info btn m-0 p-0 sm" size="sm" :disabled="actionButton.disabled"
                          @click="deleteHolyday(holyindex)"><i style="color:red;" class="fas fa-trash ml-3"></i></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-col>
          </b-row>
        </div>
        <!-- holyday list and managment for hod and admin  -->
        <div v-else>
          <table class="table table-striped table-bordered">
            <thead class="thead-dark text-left">
              <tr>
                <th>
                  SI. No.
                </th>
                <th>
                  Date
                </th>
                <th>
                  Name
                </th>
                <th>
                  {{ subgroupCustomName }}
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(holyday, holyindex) in dateHolidays" :key="holyindex">
                <td>{{ holyindex + 1 }}</td>
                <td>{{ moment(holyday.date, 'YYYY-MM-DD').format('DD/MM/YYYY') }}
                  -{{ weekdayNames[new Date(holyday.date).getDay()] }}
                </td>
                <td>
                  {{ holyday.holyday_name.join(', ') }}
                </td>
                <td>
                  <span v-for="(subgroup, subindex) in holyday.usersubgroups" :key="subindex">
                    {{ subgroup.code }}-{{ subgroup.usergroup.code }}
                    <span v-if="(holyday.usersubgroups.length > 1)" class="text-info btn m-0 p-0 sm" size="sm"
                      :disabled="actionButton.disabled" @click="removeHolydays(holyday.date, subgroup.id)"><i
                        style="color:red;" class="fas fa-minus-circle text-danger ml-1"></i></span> <br>
                  </span>
                </td>
                <td>
                  <span class="text-info btn m-0 p-0 sm" size="sm" :disabled="actionButton.disabled"
                    @click="removeHolydays(holyday.date, null)"><i style="color:red;"
                      class="fas fa-trash ml-3"></i></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="resourceaction == 'create'">
        <div>
          <h3 style="text-align: center; font-size:18px !important;">
            Add Holiday
          </h3>
          <b-row>
            <b-col sm="6">
              <b-form-group id="input-group-1" label-for="input-1" description="">
                <label class="required-label" for="">Holiday Name:</label>
                <b-form-input id="input-1" v-model="holyday.name" type="text" placeholder="Enter a Name"
                  required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="6" v-if="(holyday.date_type === null)">
              <b-form-group label="Choose Date type">
                <b-form-radio-group required v-model="holyday.date_type" name="radio-sub-component">
                  <b-form-radio value="single_date">Single day</b-form-radio>
                  <b-form-radio value="date_range">Date Range</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>
            <b-col sm="6" v-if="(holyday.date_type === 'single_date')">
              <b-form-group id="input-group-2" label-for="input-2" description="">
                <label class="required-label" for="">Date:</label>
                <b-form-datepicker id="example-datepicker111" v-model="holyday.date" class="mb-2"></b-form-datepicker>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row v-if="(holyday.date_type === 'date_range')">
            <b-col sm="6">
              <b-form-group id="input-group-2" label-for="input-2" description="">
                <label class="required-label" for="">Start Date:</label>
                <b-form-datepicker id="example-datepicker11" v-model="holyday.start_date"
                  class="mb-2"></b-form-datepicker>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group id="input-group-2" label-for="input-2" description="">
                <label class="required-label" for="">End Date:</label>
                <b-form-datepicker id="example-datepicker1" v-model="holyday.upto_date"
                  class="mb-2"></b-form-datepicker>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="6" >
              <b-form-group label="">
                <b-form-checkbox v-model="holyday.exclude_from_lessonplan">Exclude From Lesson Plan Preparation</b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row v-if="(usersubgroup_id == null)">
            <b-col>
              <b-form-group :label="'Select '+subgroupCustomName+':'" >
                <b-form-checkbox-group id="checkbox-group-2" v-model="holyday.usersubgroup_id" 
                  name="flavour-2">
                  <b-form-checkbox v-for="(subgroup, subindex) in allusersubgroups" :key="subindex" :value="subgroup.value">{{subgroup.text}}</b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="12" style="text-align:right;">
              <b-button :disabled="actionButton.disabled" variant="success" class="text-white sm" size="sm"
                @click="createHolyday">Submit</b-button>
            </b-col>
          </b-row>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  props: ["usersubgroup_id"],
  async created() {
    this.subgroupCustomName = this.$store.getters.getCustomNamings.userSubgroup.custom_name ?? "Usersubgroup";
    this.getSubgroupHolydays();
  },
  components: {},
  data() {
    return {
      fetchData: false,
      subgroupCustomName: '',
      resourceaction: 'list',
      actionButton: {
        text: "Submit",
        disabled: false,
      },
      usersubgroups: [], // subgroups in holydays list 
      holydays: [], // for a usersubgroup
      dateHolidays: [], // for multiple subgroups 
      allusersubgroups: [],
      holyday: { name: '', usersubgroup_id: [], date: null, date_type: null, start_date: null, upto_date: null, exclude_from_lessonplan:true },
      // selectedSubgroups: [],
      weekdayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
    };
  },
  methods: {
    async removeHolydays(date, subgroupId) {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usersubgroupholydays/delete`;
      await this.$axios
        .post(url, { date: date, usersubgroup: subgroupId })
        .then(() => {
          this.$toast.success("Holiday deleted successfully", {
            position: "top",
            duration: 3000,
          });
          this.getSubgroupHolydays();
        })
        .catch((error) => {
          error;
          this.$toast.error("Failed to deleted Holiday", {
            position: "top",
            duration: 3000,
          });
        });
    },
    async createHolyday() {
      this.actionButton = {
        text: "Saving...",
        disabled: true,
      };
      // this.holyday.usersubgroup_id =
      //   this.usersubgroup_id != null ? [this.usersubgroup_id] : this.selectedSubgroups.map(option => option.value)
      let url = this.$store.getters.getAPIKey.mainAPI + `/usersubgroupholydays`;
      await this.$axios.post(url, this.holyday)
        .then(() => {
          this.$toast.success("Holiday created successfully", {
            position: "top",
            duration: 3000,
          });
          this.actionButton = {
            text: "Save",
            disabled: false,
          };
          this.getSubgroupHolydays();
        })
        .catch((error) => {
          this.actionButton = {
            text: "Save",
            disabled: false,
          };
          let errortxt = "";
          if (error.response)
            if (error.response.status == 422) {
              let errormsgs = error.response.data.errors;
              for (const key in errormsgs) {
                errortxt = errormsgs[key][0];
              }
            } else {
              errortxt = error.response.data.message;
            }
          else {
            errortxt = "Failed to Create";
          }
          this.$toast.error(errortxt, {
            position: "top",
            duration: 3000,
          });
          return;
        });

    },
    async createHolydayForm() {
      if (this.allusersubgroups.length < 1 && this.usersubgroup_id == null) await this.getAllUsersubgroups();
      this.holyday = { name: '', usersubgroup_id: [], date: null, date_type: null, start_date: null, upto_date: null, exclude_from_lessonplan:true };
      if(this.usersubgroup_id != null){
        this.holyday.usersubgroup_id = [this.usersubgroup_id];
      }else{
        this.holyday.usersubgroup_id = this.allusersubgroups.map(option => option.value);
      }
      this.resourceaction = 'create';
    },
    async getSubgroupHolydays() {
      this.resourceaction = "list";
      this.holydays = [];
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usersubgroupholydays` + (this.usersubgroup_id != null ? `?usersubgroup=${this.usersubgroup_id}` : '');
      await this.$axios
        .get(url)
        .then((response) => {
          if (this.usersubgroup_id != null) {
            this.holydays = response.data;
          }
          else {
            this.dateHolidays = response.data;
          }
        })
        .catch((error) => {
          error;
        });
    },
    // async removeSubgroupHolyday(holyindex) {

    //   this.actionButton = {
    //     text: "Saving...",
    //     disabled: true,
    //   };
    //   const url =
    //     this.$store.getters.getAPIKey.mainAPI +
    //     `/usersubgroupholydays/${this.holydays[holyindex].id}`;
    //   await this.$axios
    //     .delete(url)
    //     .then(() => {
    //       this.$toast.success(this.subgroupCustomName + " removed from Holiday", {
    //         position: "top",
    //         duration: 3000,
    //       });
    //       this.holydays.splice(holyindex, 1)
    //     })
    //     .catch((error) => {
    //       error;
    //       this.$toast.error(this.subgroupCustomName + " failed to remove", {
    //         position: "top",
    //         duration: 3000,
    //       });
    //     });

    //   this.actionButton = {
    //     text: "Saving...",
    //     disabled: false,
    //   };
    // },
    async deleteHolyday(holyindex) {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usersubgroupholydays/${this.holydays[holyindex].id}`;
      await this.$axios
        .delete(url)
        .then(() => {
          this.$toast.success("Holiday deleted successfully", {
            position: "top",
            duration: 3000,
          });
          this.holydays.splice(holyindex, 1)
        })
        .catch((error) => {
          error;
          this.$toast.error("Failed to delete Holiday", {
            position: "top",
            duration: 3000,
          });
        });
    },


    async getAllUsersubgroups() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/institutionuser/usersubgroups`;
      await this.$axios
        .get(url)
        .then((response) => {
          let allusersubgroups = response.data;
          allusersubgroups.forEach(group => {
            this.allusersubgroups.push({
              text: group.code + ' ' + group.usergroup.code,
              value: group.id
            });
          });
        })
        .catch((error) => {
          error;
        });
    },
  },
};
</script>

<style >
.tag-button button {
  font-size: 9px !important;
  padding: 3px;
}
</style>
