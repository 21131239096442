var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.fetchData)?_c('div',{staticClass:"loading"},[_c('load-data')],1):_c('div',{staticClass:"createSurvey p-3"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.prepareSurveySubmission()}}},[(!_vm.survey.id)?_c('div',{staticClass:"participantsComponent"},[_c('SyncStudentList',{ref:"syncStudents",attrs:{"subgroupForStudents":_vm.subgroupForStudents,"surveyId":_vm.surveyId},on:{"cancelSyncingParticipants":function($event){return _vm.clearResponse(true)}}})],1):_vm._e(),_c('input',{attrs:{"type":"hidden"},domProps:{"value":!_vm.survey.hidden ? (_vm.survey.hidden = false) : _vm.survey.hidden}}),_c('b-form-group',[_c('label',{staticClass:"required-label",attrs:{"for":""}},[_vm._v("Survey Name:")]),_c('b-form-input',{class:{ 'form-group-error': _vm.$v.survey.name.$error },attrs:{"placeholder":"Survey Name"},model:{value:(_vm.survey.name),callback:function ($$v) {_vm.$set(_vm.survey, "name", $$v)},expression:"survey.name"}}),(!_vm.$v.survey.name.required && _vm.$v.survey.name.$dirty)?_c('span',{staticClass:"input-error-message"},[_vm._v("Survey Name is required")]):_vm._e()],1),_c('b-form-group',{attrs:{"label":"Survey Description:"}},[_c('b-form-textarea',{attrs:{"placeholder":"Enter description...","rows":"3","max-rows":"6"},model:{value:(_vm.survey.summary),callback:function ($$v) {_vm.$set(_vm.survey, "summary", $$v)},expression:"survey.summary"}})],1),_c('b-form-group',[_c('label',{staticClass:"required-label",attrs:{"for":""}},[_vm._v("Survey Type:")]),_c('b-form-select',{attrs:{"options":_vm.surveyTypeOptions,"disabled":_vm.surveyInfo.id ? true : false},on:{"change":_vm.updateSurveyType},model:{value:(_vm.survey.survey_type),callback:function ($$v) {_vm.$set(_vm.survey, "survey_type", $$v)},expression:"survey.survey_type"}})],1),_c('b-form-group',[_c('label',{staticClass:"required-label",attrs:{"for":""}},[_vm._v("Survey Mode:")]),(
          _vm.survey.survey_type == 'programme_exit' ||
          _vm.survey.survey_type == 'course_exit'
        )?_c('b-form-select',{attrs:{"disabled":_vm.surveyInfo.id ? true : false,"options":_vm.surveyOnlineOfflineOptions},on:{"change":_vm.updateSurveyType},model:{value:(_vm.survey.survey_mode),callback:function ($$v) {_vm.$set(_vm.survey, "survey_mode", $$v)},expression:"survey.survey_mode"}}):_vm._e(),(
          _vm.survey.survey_type == 'student_feedback' 
        )?_c('b-form-select',{attrs:{"disabled":_vm.surveyInfo.id ? true : false,"options":_vm.surveyOnlineOptions},on:{"change":_vm.updateSurveyType},model:{value:(_vm.survey.survey_mode),callback:function ($$v) {_vm.$set(_vm.survey, "survey_mode", $$v)},expression:"survey.survey_mode"}}):_vm._e(),(
          _vm.survey.survey_type == 'alumini' ||
          _vm.survey.survey_type == 'employer' ||
          _vm.survey.survey_type == 'parent' ||
          _vm.survey.survey_type == 'faculty'
        )?_c('b-form-select',{attrs:{"disabled":_vm.surveyInfo.id ? true : false,"options":_vm.surveyOfflineOptions},on:{"change":_vm.updateSurveyType},model:{value:(_vm.survey.survey_mode),callback:function ($$v) {_vm.$set(_vm.survey, "survey_mode", $$v)},expression:"survey.survey_mode"}}):_vm._e()],1),_c('b-form-group',[_c('label',{staticClass:"required-label",attrs:{"for":""}},[_vm._v("Select Academic Year:")]),_c('br'),_c('b-form-select',{staticClass:"w-50 text-center",class:{ 'form-group-error': _vm.$v.survey.academic_year.$error },attrs:{"options":_vm.$acedemicYears,"disabled":_vm.surveyInfo.id ? true : false,"value":!_vm.survey.academic_year
              ? (_vm.survey.academic_year = null)
              : _vm.survey.academic_year},on:{"change":function($event){return _vm.updateAcademicYear()}},model:{value:(_vm.survey.academic_year),callback:function ($$v) {_vm.$set(_vm.survey, "academic_year", $$v)},expression:"survey.academic_year"}}),_c('br'),(
            !_vm.$v.survey.academic_year.required &&
            _vm.$v.survey.academic_year.$dirty
          )?_c('span',{staticClass:"input-error-message"},[_vm._v("Academic Year is required")]):_vm._e()],1),(
          _vm.survey.survey_mode == 'online'
        )?_c('b-form-group',{class:{
          'form-group-error': _vm.$v.date_from.$error + ' ' + _vm.$v.date_upto.$error,
        },attrs:{"label":"Select Survey Start and End Date:"}},[_c('b-row',{staticStyle:{"z-index":"999 !important"}},[_c('b-col',{attrs:{"md":"6"}},[_c('label',[_vm._v("From:")]),_c('b-form-datepicker',{staticClass:"mb-2",attrs:{"id":"example-datepickerfrom"},model:{value:(_vm.date_from),callback:function ($$v) {_vm.date_from=$$v},expression:"date_from"}})],1),_c('b-col',{attrs:{"md":"6"}},[_c('label',[_vm._v("To:")]),_c('b-form-datepicker',{staticClass:"mb-2",attrs:{"id":"example-datepickerto"},model:{value:(_vm.date_upto),callback:function ($$v) {_vm.date_upto=$$v},expression:"date_upto"}})],1)],1),_c('br'),(
            (!_vm.$v.date_upto.required && _vm.$v.date_upto.$dirty) ||
            (!_vm.$v.date_from.required && _vm.$v.date_from.$dirty)
          )?_c('span',{staticClass:"input-error-message"},[_vm._v("Start and End Date is required")]):_vm._e()],1):_vm._e(),(_vm.survey.survey_type == 'course_exit')?_c('b-form-group',{class:{ 'form-group-error': _vm.$v.survey.course_id.$error },attrs:{"label":""}},[_c('label',{staticClass:"required-label",attrs:{"for":""}},[_vm._v("Select Course:")]),_c('SelectCourseForCourseExitSurvey',{ref:"courseExitSurvey",attrs:{"surveyId":_vm.surveyId,"myCoursesOptions":_vm.myCoursesOptions,"survey":_vm.survey,"disableChange":_vm.surveyInfo.id ? true : false},on:{"course_id":_vm.storeCourseId}})],1):_vm._e(),(
            !_vm.$v.survey.course_id.required &&
            _vm.$v.survey.course_id.$dirty
          )?_c('span',{staticClass:"input-error-message"},[_vm._v("Course is required")]):_vm._e(),(
          _vm.survey.survey_type == 'programme_exit' ||
          _vm.survey.survey_type == 'alumini' ||
          _vm.survey.survey_type == 'employer' ||
          _vm.survey.survey_type == 'parent' ||
          _vm.survey.survey_type == 'faculty'
        )?_c('b-form-group',{class:{ 'form-group-error': _vm.$v.survey.usersubgroup_id.$error }},[_c('label',{staticClass:"required-label",attrs:{"for":""}},[_vm._v(_vm._s('Select' + _vm.getCustomNames.userSubgroup.custom_name != null ? _vm.getCustomNames.userSubgroup.custom_name : 'Subgroup' + ':')+" ")]),_c('SelectSubgroupForProgramExitSurvey',{ref:"programExitSurvey",attrs:{"surveyId":_vm.surveyId,"subgroupForProgramExitSurvey":_vm.subgroupForProgramExitSurvey,"survey":_vm.survey,"disableChange":_vm.surveyInfo.id ? true : false},on:{"setUserSubgroupId":_vm.setUserSubgroupId}})],1):_vm._e(),(
            !_vm.$v.survey.usersubgroup_id.required &&
            _vm.$v.survey.usersubgroup_id.$dirty
          )?_c('span',{staticClass:"input-error-message"},[_vm._v(_vm._s(_vm.getCustomNames.userSubgroup.custom_name != null ? _vm.getCustomNames.userSubgroup.custom_name : 'Subgroup' + ':')+" is required")]):_vm._e(),(!_vm.survey.id && _vm.survey.survey_type == 'student_feedback')?_c('b-form-group',{attrs:{"label":"Coure Teacher Combinations:"}},[_c('SyncCourseTeachers',{ref:"syncTeachers",attrs:{"subgroupsForTeachers":_vm.subgroupsForTeachers,"surveyId":_vm.surveyId}})],1):_vm._e(),(_vm.survey.id)?_c('div',[(_vm.survey.survey_type == 'student_feedback')?_c('b-form-group',[_c('p',{staticClass:"text-info btn p-0 m-0",staticStyle:{"font-size":"14px","font-weight":"600"},on:{"click":function($event){return _vm.editCourseTeacherCombination()}}},[_vm._v(" Edit Course Instructor Combination. ")]),_c('EditCourseTeacherCombination',{ref:"editCourseTeacherCombination",attrs:{"subgroupsForTeachers":_vm.subgroupsForTeachers,"surveyId":_vm.survey.id}})],1):_vm._e(),(_vm.survey.survey_type == 'student_feedback')?_c('b-form-group',[_c('p',{staticClass:"text-info btn p-0 m-0",staticStyle:{"font-size":"14px","font-weight":"600"},on:{"click":function($event){return _vm.editParticipantDetails()}}},[_vm._v(" Edit Participants Details. ")]),_c('EditParticipantsDetails',{ref:"editParticipantsDetails",attrs:{"subgroupForStudents":_vm.subgroupForStudents,"surveyId":_vm.survey.id}})],1):_vm._e(),(_vm.survey.survey_type == 'course_exit')?_c('b-form-group',[_c('p',{staticClass:"text-info btn p-0 m-0",staticStyle:{"font-size":"14px","font-weight":"600"},on:{"click":function($event){return _vm.editParticipantDetails()}}},[_vm._v(" Edit Course Exit Participants Details. ")]),_c('EditCourseExitParticipants',{ref:"editParticipantsDetails",attrs:{"subgroupForStudents":_vm.subgroupForStudents,"surveyId":_vm.survey.id,"courseId":_vm.survey.course_id}})],1):_vm._e()],1):_vm._e(),(
          _vm.survey.survey_mode == 'online'
        )?_c('b-form-group',{attrs:{"label":"Tag:"}},[_c('b-form-input',{attrs:{"placeholder":"Enter Tag Name"},model:{value:(_vm.survey.grouping_tag),callback:function ($$v) {_vm.$set(_vm.survey, "grouping_tag", $$v)},expression:"survey.grouping_tag"}})],1):_vm._e(),(
          _vm.survey.survey_mode == 'online'
        )?_c('b-form-group',{attrs:{"label":"Comment Feedback"}},[_c('b-form-checkbox',{attrs:{"switch":"","size":"md"},model:{value:(_vm.survey.comment_feedback),callback:function ($$v) {_vm.$set(_vm.survey, "comment_feedback", $$v)},expression:"survey.comment_feedback"}})],1):_vm._e(),(
          _vm.survey.survey_mode == 'online'
        )?_c('b-form-group',{attrs:{"label":"Keep Participants Details Anonymous:"}},[_c('b-form-checkbox',{attrs:{"switch":"","size":"md"},model:{value:(_vm.survey.is_anonymous),callback:function ($$v) {_vm.$set(_vm.survey, "is_anonymous", $$v)},expression:"survey.is_anonymous"}})],1):_vm._e(),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('b-button',{attrs:{"variant":"primary","type":"submit","size":"md","disabled":_vm.surveyBtn.disabled}},[_vm._v(_vm._s(_vm.surveyBtn.text)+" ")]),_c('b-button',{staticClass:"ml-auto",attrs:{"variant":"danger","size":"md","disabled":_vm.surveyBtn.disabled},on:{"click":function($event){return _vm.cancel()}}},[_vm._v("Cancel ")])],1)])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }