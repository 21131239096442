<template>
    <div>
        <div v-if="fetchData" class="text-center">Updating...</div>
        <div class="row questions-container" v-else>
            <!-- Title  -->
            <b-col class="no-print">
                <div class="text-center font-weight-bold" style="font-size: 16px !important">
                    {{ publicexams.name }} Question
                </div>
                <div class="border m-2 p-2 text-center" style="border-radius: 6px">
                    <b-button variant="success" size="sm" @click="addNewQuestion()">Add New Question</b-button>
                    <b-button variant="danger" class="ml-2" @click="deleteAllQuestions(publicexams.id)">Delete all
                        questions</b-button>
                </div>
                <div v-for="(question, qindex) in questionObjects" :key="qindex">
                    <div class="border-secondary m-2 p-1" style="border: 2px solid; border-radius: 6px">
                        <!-- a question datas  -->
                        <!-- <question-item :question="question" :deleteQuestion="deleteQuestion" :openEditQuestion="openEditQuestion"></question-item>-->
                        <p v-if="!question.question">
                            <b>
                                {{ question.question_no }}. (Please click edit icon to edit
                                question)</b>
                            <i style="font-size: 12px !important" class="fas fa-edit text-primary text-sm btn p-0 mx-1"
                                @click="openEditQuestion(question.id)"></i>
                            <i style="font-size: 12px !important" class="fas fa-trash-alt text-danger text-sm btn p-0 mx-1"
                                @click="deleteQuestion(question.id)"></i>
                        </p>
                        <p v-else>
                            <b>
                                {{ question.question_no }}.
                                <span v-for="(qData, qdIndex) in question.question" :key="qdIndex">
                                    <span class="big-font" v-if="qData.type == 'text'"
                                        v-html="latexCodeToMathLive(qData.value)"></span>
                                    <img class="questionPaperPreviewImg" v-if="qData.type == 'image'" style=""
                                        height="100px" width="150px" :src="questionAttachments[qData.value].url" />
                                    <span v-if="qData.type == 'text' && !qData.value">(Please click edit icon to edit
                                        question)</span>
                                </span></b>
                            <i style="font-size: 12px !important" class="fas fa-edit text-primary text-sm btn p-0 mx-1"
                                @click="openEditQuestion(question.id)"></i>
                            <i style="font-size: 12px !important" class="fas fa-trash-alt text-danger text-sm btn p-0 mx-1"
                                @click="deleteQuestion(question.id)"></i>
                            <br />
                            <span v-for="(choice, cIndex) in question.choices" :key="cIndex">
                                <span 
                                :style="choice.is_right_choice == 1? 'color:green;' : 'color:red;'"
                               >
                                    {{ choice.choice_no }}. {{ choice.name }}</span>
                              
                            </span>
                        </p>
                    </div>
                </div>
            </b-col>
            <!-- questionPaperPreview  -->
        </div>
        <b-modal class="no-print" id="editQuestionModel" no-close-on-backdrop no-close-on-keyboard no-close-on-esc lazy
            size="lg" hide-header hide-footer>
            <div class="container" style="max-height: 60vh; overflow-y: auto">
                <div>
                    <b-row>
                        <b-col>
                            <b-form-group label="Question No:" label-for="input-2" description="">
                                <b-form-input id="input-2" v-model="question.question_no" type="text"
                                    placeholder="1"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group label="Score for Right answer:" label-for="input-2" description="">
                                <b-form-input id="input-2" v-model="question.maximum_mark" type="text"
                                    placeholder="10"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group label="Score for wrong answer:" label-for="input-2" description="">
                                <b-form-input id="input-2" v-model="question.wrong_answer_mark" type="text"
                                    placeholder="10"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <div class="mt-2" v-if="question.length != 0">
                        <div>Question:</div>
                        <div v-for="(qData, qdIndex) in question.question" :key="qdIndex" class="my-1">
                            <b-row>
                                <b-col>
                                    <jodit-editor v-if="qData.type == 'text'" v-model="qData.value"
                                        :extra-buttons="customButtons" :config="editorConfig"
                                        :buttons="JoditViewFullButtons" />
                                    <div v-if="qData.type == 'equation'">
                                        <math-field v-if="qData.value == ''" :id="'mathfield' + qdIndex"
                                            style="width: 100%">
                                            x=\frac{-b\pm \sqrt{b^2-4ac}}{2a}</math-field>
                                        <math-field v-else :id="'mathfield' + qdIndex" style="width: 100%">{{ qData.value
                                        }}</math-field>
                                    </div>
                                    <div v-if="qData.type == 'image'">
                                        <div>
                                            <iframe :src="cloudStorageRoot +
                                                questionAttachmentDetails(qData.value).file_path +
                                                questionAttachmentDetails(qData.value).file_name
                                                " style="width: 100%; max-height: 350px"></iframe>
                                        </div>
                                        <a :href="cloudStorageRoot +
                                            questionAttachmentDetails(qData.value).file_path +
                                            questionAttachmentDetails(qData.value).file_name
                                            " style="
                        font-size: 12px;
                        font-weight: 900;
                        word-break: break-word;
                      " class="text-decoration-none" target="_blank">
                                            {{ questionAttachmentDetails(qData.value).file_name }}
                                        </a>
                                    </div>
                                </b-col>

                                <b-col cols="auto" class="ml-0">
                                    <i style="font-size: 12px !important"
                                        class="fas fa-trash-alt text-danger text-sm btn p-0 mx-1"
                                        @click="removeDataFromQuestion(qdIndex)"></i>
                                </b-col>
                            </b-row>
                        </div>
                        <div class="border mT-2 p-2" style="border-radius: 6px" v-if="question.choices.length != 0">
                            <div>Choice:</div>
                            <div v-for="(choice, qindex) in question.choices" :key="qindex">
                                <div class="container" style="max-height: 60vh; overflow-y: auto">
                                    <div>
                                        <b-row>
                                            <b-col cols="2">
                                                <b-row>
                                                    <b-col cols="1">
                                                        <span> No: </span>
                                                    </b-col>
                                                    <b-col cols="7">
                                                        <b-input id="input-2" v-model="choice.choice_no" type="text"
                                                            placeholder="eg :1,A"></b-input>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                            <b-col cols="8">
                                                <b-row>
                                                    <b-col cols="2">
                                                        <span>choice:</span>
                                                    </b-col>
                                                    <b-col cols="9">
                                                        <b-input id="input-2" v-model="choice.name" type="text"
                                                            placeholder="Enter Choice"></b-input>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                            <b-col>
                                                <p style="display: none" v-if="choice.is_right_choice == 1">
                                                    {{ (choice.is_right_choice = "true") }}
                                                </p>
                                                <b-form-checkbox @change="
                                                    handleSelection(choice.id, choice.is_right_choice)
                                                    " v-model="choice.is_right_choice">
                                                </b-form-checkbox>
                                            </b-col>
                                            <b-col>
                                                <i style="font-size: 12px !important"
                                                    class="fas fa-trash-alt text-danger text-sm btn p-0 mx-1"
                                                    @click="deleteChoice(choice.id)"></i>
                                            </b-col>
                                        </b-row>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="m-1">
                            <b-button variant="outline-success" class="ml-1" size="sm" @click="addImageToQuestion()">Add
                                Image</b-button>
                            <b-button variant="success" class="ml-1" size="sm" @click="addChoice(question.id)">Add
                                Choice</b-button>
                        </div>
                        <b-form-file :ref="'newQuestionAttachmentInput'" style="display: none"
                            @change="uploadQuestionAttachments" class="text-center"
                            v-model="newQuestionAttAchmentFile"></b-form-file>
                        <div class="m-1 text-danger" v-if="updatingQuestion">
                            Question details update in progress, Please wait...
                        </div>
                    </div>
                    <div class="mt-3 text-right">
                        <b-button variant="danger" size="sm" @click="cancelEditQuestion">Cancel</b-button>
                        <b-button variant="success" class="ml-5" size="sm" @click="saveQuestion(true)">Save
                            Changes</b-button>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
import { JoditViewFullButtons } from "../../../../constants";
import { JoditEditor } from "jodit-vue";
export default {
    props: ["publicexams"],
    async created() {
        this.prepareQuestionsView();
        this.cloudStorageRoot = this.$store.getters.getAPIKey.userAttachments;
    },
    components: {
        JoditEditor,
    },
    data() {
        return {
            questionAttachments: {},//id:{id, url, name}
            fetchData: false,
            enableEvaluationScheme: false,
            questionObjects: {},
            enableQuestionView: true,
            question: [],
            cloudStorageRoot: "",
            updatingQuestion: false,
            newQuestionAttAchmentFile: null,
            enablehoice: false,
            customButtons: [
                {
                    name: "LaTex",
                    iconURL: "",
                    exec: function (editor) {
                        editor.selection.insertHTML("<span> $E=mc^2$ </span>");
                    },
                },
            ],
            editorConfig: {
                toolbarButtonSize: "xsmall",
                enter: "br",
            },
            JoditViewFullButtons: JoditViewFullButtons,
        };
    },
    methods: {
        latexCodeToMathLive(codeText) {
            if (codeText == null || codeText == "") return "";
            return codeText.replace(
                /\$\$(.*?)\$\$/g,
                '<math-field class="p-0" style="border: none;" readonly>$1</math-field>'
            );
        },
        async openEditQuestion(questionId) {
            this.editQuestion = this.questionObjects[questionId];
            this.question = this.editQuestion;
            if (!this.question.question) {
                this.addTextToQuestion();
            }
            this.$bvModal.show("editQuestionModel");
        },
        handleSelection(choice_id, right) {
            if (right == "true") {
                this.question.choices.forEach((choice) => {
                    if (choice.id != choice_id) {
                        choice.is_right_choice = 0;
                    }
                });
            }
        },
        async deleteAllQuestions(ExamId) {
            if (
                confirm(
                    "Are you sure you want to delete all questions? Deleting questions will also delete the choices, answer, attachment & score"
                )
            ) {
                this.fetchData = true;
                const url =
                    this.$store.getters.getAPIKey.mainAPI +
                    `/publicexamquestions/delete?exam_id=${ExamId}`;

                await this.$axios
                    .delete(url)
                    .then(() => {
                        this.prepareQuestionsView();
                    })
                    .catch((error) => {
                        error;
                    });
                this.fetchData = false;
            }
        },
        async uploadQuestionAttachments(event) {
            event;
            await this.saveQuestion(false);
            this.updatingQuestion = true;
            const fd = await new FormData();
            await fd.append("attachment", this.newQuestionAttAchmentFile);
            const url =
                this.$store.getters.getAPIKey.mainAPI +
                `/publicexamquestions/${this.question.id}/attachments`;
            await this.$axios
                .post(url, fd)
                .then(() => {
                    this.getQuestion(this.question.id);
                })
                .catch((error) => {
                    error;
                    this.$toast.error("Failed to attach, please try again");
                });

            this.updatingQuestion = false;
        },
        async getQuestion(questionId) {
            const url =
                this.$store.getters.getAPIKey.mainAPI +
                `/publicexamquestions/${this.questionId == null ? questionId : this.questionId
                }`;
            await this.$axios.get(url).then((response) => {
                let question = response.data;
                this.question = {
                    files: question.files,
                    id: question.id,
                    maximum_mark: question.maximum_mark,
                    choices: question.choices,
                    question: question.question,
                    question_no: question.question_no,
                    summary: question.summary,
                    updated_at: question.updated_at,
                };
            });
        },
        addImageToQuestion() {
            this.$refs["newQuestionAttachmentInput"].$refs.input.click();
        },
        async deleteQuestion(questionId) {
            if (
                confirm(
                    "Are you sure you want to delete this questions? Deleting questions will also delete the choices, answer, attachment, score."
                )
            ) {
                this.fetchData = true;
                const url =
                    this.$store.getters.getAPIKey.mainAPI +
                    `/publicexamquestions/delete?question_id=${questionId}`;
                await this.$axios
                    .delete(url)
                    .then(() => {
                        this.prepareQuestionsView();
                    })
                    .catch((error) => {
                        error;
                    });

                this.fetchData = false;
            }
        },
        async deleteChoice(choiceId) {
            await this.saveQuestion(false);
            if (confirm("Are you sure you want to delete this choices?")) {
                this.fetchData = true;
                const url =
                    this.$store.getters.getAPIKey.mainAPI +
                    `/publicexamchoices/${choiceId}`;
                await this.$axios
                    .delete(url)
                    .then(() => {
                        this.getQuestion(this.question.id);
                    })
                    .catch((error) => {
                        error;
                    });
                this.fetchData = false;
            }
        },
        printQuestionPaper() {
            this.enableEvaluationScheme = false;
            this.enableQuestionView = true;
            setTimeout(() => {
                window.print();
            }, 200);
            setTimeout(() => {
                this.enableEvaluationScheme = true;
                this.enableQuestionView = true;
            }, 1000);
        },
        async addChoice(quastionId) {
            this.fetchData = true;
            await this.saveQuestion(false);
            let postData = {
                question_id: quastionId,
            };
            const url = this.$store.getters.getAPIKey.mainAPI + `/publicexamchoices`;
            await this.$axios
                .post(url, postData)
                .then(() => { })
                .catch((error) => {
                    this.$toast.error(
                        "Failed to add question, Please try again. (" +
                        error.response.data.message +
                        ")"
                    );
                });
            this.getQuestion(this.question.id);
            this.fetchData = false;
        },
        async addNewQuestion() {
            this.fetchData = true;
            let postData = {
                dummy_question: true,
                public_exam_id: this.publicexams.id,
            };
            const url =
                this.$store.getters.getAPIKey.mainAPI + `/publicexamquestions`;
            await this.$axios
                .post(url, postData)
                .then(() => { })
                .catch((error) => {
                    this.$toast.error(
                        "Failed to add question, Please try again. (" +
                        error.response.data.message +
                        ")"
                    );
                });
            await this.prepareQuestionsView();
            this.fetchData = false;
        },
        async prepareQuestionsView() {
            this.questions = [];
            this.questionObjects = {};
            await Promise.all([this.getExamQuestions()]);
            this.fetchData = true;
            this.questions.forEach((question) => {
                this.questionObjects[question.id] = question;
            });
            this.fetchData = false;
            await Object.values(this.questionObjects).forEach(question => {
                question.files.forEach(file => {
                    this.questionAttachments[file.id] = {
                        id: file.id, name: file.file_name, url: this.cloudStorageRoot + file.file_path + file.file_name
                    };
                });
            });
        },
        async getExamQuestions() {
            this.fetchData = true;
            const url =
                this.$store.getters.getAPIKey.mainAPI +
                `/publicexamquestions?public_exam_id=${this.publicexams.id}`;
            await this.$axios
                .get(url)
                .then((response) => {
                    const questions = response.data;
                    this.questions = questions;
                })
                .catch((error) => {
                    error;
                });

            this.fetchData = false;
        },
        cancelEditQuestion() {
            this.$bvModal.hide("editQuestionModel");
            this.question = [];
        },
        questionAttachmentDetails(attachmentId) {
            return this.question.files.find((item) => item.id == attachmentId);
        },
        addTextToQuestion() {
            if (!this.question.question) {
                this.question.question = [];
            }
            this.question.question.push({
                type: "text",
                value: "",
            });
            this.$forceUpdate();
        },
        addEquationToQuestion() {
            if (!this.question.question) {
                this.question.question = [];
            }
            this.question.question.push({
                type: "equation",
                value: "",
            });
            this.$forceUpdate();

            // this.$forceUpdate();
        },
        removeDataFromQuestion(index) {
            this.question.question.splice(index, 1);
            this.$forceUpdate();
        },
        async finishEditQuestion() {
            this.$bvModal.hide("editQuestionModel");
        },
        async saveQuestion(finishEditing) {
            this.updatingQuestion = true;
            if (this.question.question) {
                this.question.question.forEach((qData, qIndex) => {
                    if (qData.type == "equation") {
                        const mf = document.getElementById("mathfield" + qIndex);
                        this.question.question[qIndex].value = mf.value;
                    }
                });
            }
            let examQues = await {
                dummy_question: false,
                id: this.question.id,
                public_exam_id: this.question.public_exam_id,
                maximum_mark: this.question.maximum_mark,
                wrong_answer_mark: this.question.wrong_answer_mark,
                question: this.question.question,
                question_no: this.question.question_no,
                choices: this.question.choices,
            };
            const url =
                this.$store.getters.getAPIKey.mainAPI + `/publicexamquestions`;
            await this.$axios
                .post(url, examQues)
                .then(() => {
                    if (finishEditing) this.finishEditQuestion();
                })
                .catch((error) => {
                    error;
                });
            this.prepareQuestionsView();
            this.updatingQuestion = false;
        },
    },
};
</script>

<style>
.questions-container button {
    padding: 1px !important;
}
</style>
