<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="copyUsersFromUsersubgroup" v-else>
      <b-form class="border-0 w-50" @submit.prevent="prepareSubgroupUsersForCopy">
        <b-form-group label="Select Academic Year">
          <b-form-select v-model="academicYear" :options="$acedemicYears" @change="getUsergroups"></b-form-select>
        </b-form-group>
        <b-form-group v-if="academicYear != null">
          <b-form-select @change="getUserSubgroups" :options="usergroupOptions"
            v-model="fromUsergroup"></b-form-select>
        </b-form-group>
        <b-form-group v-if="fromUsergroup != null">
          <b-form-select @change="getSubgroupStudents" :options="subgroupsOptions"
            v-model="copyUsersToSubgroup.from_subgroup" :class="{
              'form-group-error': $v.copyUsersToSubgroup.from_subgroup.$error,
            }"></b-form-select>
          <span class="input-error-message" v-if="
            !$v.copyUsersToSubgroup.from_subgroup.required &&
            $v.copyUsersToSubgroup.from_subgroup.$dirty
          ">
            Please Select a
            {{
                getCustomNames.userSubgroup.custom_name != ""
                  ? getCustomNames.userSubgroup.custom_name
                  : "subgroup"
            }}
            to copy from!
          </span>
        </b-form-group>
        <!-- <b-form-group label="Students"> -->
        <table class="table  table-striped table-bordered">
          <tr v-for="(groupedStuds, hrindex) in groupedStudents" :key="hrindex">
            <td v-for="(stud, sindex) in groupedStuds" :key="sindex">
              <b-form-checkbox :value="stud.pivot.institution_user_id" v-model="studentIds"></b-form-checkbox>
              <span
                :class="{ 'text-primary': stud.pivot.usersubgrouprole_id == 2, 'text-info': stud.pivot.usersubgrouprole_id == 1 }">{{
                    stud.roll_number
                }} <span v-if="stud.roll_number">.</span>
                {{ stud.first_name }}
                {{ stud.last_name }}({{ stud.email }})</span>
            </td>
          </tr>
        </table>
        <!-- </b-form-group> -->


        <b-form-group>
          <b-button type="submit" v-if="
            academicYear != null && copyUsersToSubgroup.from_subgroup != null && studentIds.length > 0
          " variant="primary" size="sm" :disabled="button.disabled">
            {{ button.text }}
          </b-button>
        </b-form-group>
      </b-form>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
  props: ["copyUsers", "groupInfo", "getCustomNames"],

  data() {
    return {
      fetchData: false,
      academicYear: null,
      subgroupsOptions: [],
      usergroupOptions: [],
      fromUsergroup: null,
      copyUsersToSubgroup: {
        from_subgroup: null,
        to_subgroup: this.groupInfo.id,
        studentIds: [],
      },
      studentIds: [],
      students: [],
      button: {
        text: "Complete Copying",
        disabled: false,
      },
    };
  },
  computed: {
    groupedStudents() {
      let grouped = [];
      for (let i = 0; i < this.students.length; i += 2) {
        grouped.push(this.students.slice(i, i + 2));
      }
      return grouped;
    },
  },
  validations: {
    copyUsersToSubgroup: {
      from_subgroup: {
        required,
      },
    },
  },
  methods: {
    async prepareSubgroupUsersForCopy() {
      await this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.button = {
          text: "Copying....",
          disabled: true,
        };
        let copyUsers = await this.copyUsersToCurrentSubgroup();
        if (copyUsers) {
          this.$toast.success("Users Successfully Copied", {
            position: "top",
            duration: 3000,
          });
          this.$emit("copiedSuccessfully");
        }
        if (!copyUsers) {
          this.$toast.error("Something went wrong! Please try again!", {
            position: "top",
            duration: 3000,
          });
          this.button = {
            text: "Complete Copying",
            disabled: false,
          };
        }
      }
    },
    async copyUsersToCurrentSubgroup() {
      let status = null;
      const url =
        this.$store.getters.getAPIKey.mainAPI + "/usersubgroupusers/copy";
      this.copyUsersToSubgroup.studentIds = this.studentIds;
      await this.$axios
        .post(url, this.copyUsersToSubgroup)
        .then((reponse) => {
          status = true;
          reponse;
        })
        .catch((error) => {
          status = false;
          error;
        });
      return status;
    },
    getUsergroups() {
      if (this.usergroupOptions.length > 1) return;
      this.fetchData = true;
      const url = this.$store.getters.getAPIKey.mainAPI + `/usergroups?allusergroups=1`;
      this.$axios
        .get(url)
        .then((response) => {
          this.usergroupOptions = [
            {
              value: null,
              text: 'Select Programme (Scheme)',
              disabled: true
            },
          ];
          const groups = response.data;
          groups.forEach((group) => {
            this.usergroupOptions.push({
              value: group.id,
              text: group.name + " (" + group.code + ") "+ (group.scheme ?? ''),
            });
          });
          this.fetchData = false;
        })
        .catch((error) => {
          this.fetchData = false;
          error;
        });
    },
    getUserSubgroups() {
      this.fetchData = true;
      let customName;
      if (this.getCustomNames.userSubgroup.custom_name != null) {
        customName = 'Select ' + this.getCustomNames.userSubgroup.custom_name;
      } else {
        customName = "Select Usersubgroup";
      }
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usergroups/${this.fromUsergroup}/usersubgroups`;
      this.$axios
        .get(url, {
          params: {
            academic_year: this.academicYear
          },
        })
        .then((response) => {
          this.subgroupsOptions = [
            {
              value: null,
              text: customName,
              disabled: true
            },
          ];
          const subgroups = response.data;
          subgroups.forEach((subgroup) => {
            if (subgroup.id != this.groupInfo.id) {
              this.subgroupsOptions.push({
                value: subgroup.id,
                text:
                  subgroup.name +
                  " (" +
                  subgroup.code +")",
              });
            }
          });
          this.fetchData = false;
          response;
        })
        .catch((error) => {
          this.fetchData = false;
          error;
        });
    },
    async getSubgroupStudents() {
      this.students = [];
      this.studentIds = [];
      this.fetchData = true;
      let userList = this.$store.getters.getAPIKey.getEnrollSubgroupUsersList;
      const url = userList.replace("usersubgroup_id", this.copyUsersToSubgroup.from_subgroup);
      await this.$axios
        .get(url)
        .then((response) => {
          let students = response.data;
          this.students = students;
          students.forEach(stud => {
            this.studentIds.push(stud.pivot.institution_user_id);
          });
        })
        .catch((error) => {
          error;
        });
      this.fetchData = false;
    },
  },
};
</script>
