<template>
  <div>
    <b-container fluid id="liveClassContainer">
      <div class="loading" v-if="fetchData">
        <load-data></load-data>
      </div>
      <b-row v-else no-gutters>
        <b-col md="12">
          <div class="createLiveClass" v-if="createLiveClass">
            <create-live-class
              :coursesOptions="coursesOptions"
              :liveClassUpdate="liveClassUpdate"
              @cancel="cancelLiveClass()"
              @liveClassCreated="getGoogleEvents()"
            ></create-live-class>
          </div>
          <div class="listingLiveClasses" v-else>
            <b-breadcrumb>
              <b-breadcrumb-item
                ><span
                  :class="filterEventsBtn.upcomings == 'info' ? 'text-danger' : ''"
                  style="font-size: 12px; font-weight: 600"
                  @click="getUpcomingEvents()"
                  ><i
                    class="far fa-check-circle mr-1"
                    v-if="filterEventsBtn.upcomings == 'info'"
                  ></i
                  >Today & Upcoming</span
                ></b-breadcrumb-item
              >
              <b-breadcrumb-item
                ><span
                  style="font-size: 12px; font-weight: 600"
                  @click="getPastEvents()"
                  :class="filterEventsBtn.past == 'info' ? 'text-danger' : ''"
                  ><i
                    class="far fa-check-circle mr-1"
                    v-if="filterEventsBtn.past == 'info'"
                  ></i
                  >Past Events</span
                ></b-breadcrumb-item
              >
            </b-breadcrumb>

            <div class="d-flex">
              <b-button
                variant="primary"
                size="sm"
                class="ml-1 p-1"
                @click="createLiveClass = true"
                v-if="instructorAccess"
                style="font-size: 12px"
                id="createGoogleEvent"
                :disabled="!googleAuthenticated"
                >Create Live Class Room</b-button
              >
              <div class="ml-auto">
                <link-google
                  @unlinkGoogle="getAuthenticationDetails()"
                  @linkedSuccessfully="getAuthenticationDetails()"
                  v-if="instructorAccess"
                ></link-google>
              </div>
            </div>
            <div class="notAuthenticated" v-if="!googleAuthenticated">
              <h5 class="text-danger">
                Your google meet account is not linked with ezygo.app, please link your
                google account from above.
              </h5>
            </div>
            <br />
            <div class="liveClassEvents" v-if="filteredEvents.length != 0">
              <div
                class="googleEvent p-1 border border-info"
                :style="
                  eventIndex % 2 === 0
                    ? { backgroundColor: '#F0F0F0' }
                    : { backgroundColor: '#FFFFFF' }
                "
                v-for="(event, eventIndex) in filteredEvents"
                :key="eventIndex"
              >
                <b-overlay
                  :show="loadData"
                  spinner-variant="primary"
                  spinner-type="grow"
                  spinner-small
                  rounded="md"
                >
                  <div class="course text-center">
                    <div class="name">
                      <div class="d-flex justify-content-between">
                        <p></p>
                        <p class="text-info mb-0" :id="'event' + eventIndex">
                          {{ courseCodes[event.course_id].name }}
                        </p>
                        <span
                          v-if="instructorAccess"
                          class="text-info btn m-0 p-0"
                          @click="getGoogleEvent(event.id)"
                          ><i class="far fa-edit"></i
                        ></span>
                      </div>
                      <b-popover
                        :target="'event' + eventIndex"
                        triggers="hover"
                        placement="right"
                      >
                        <p>{{ event.summary }}</p>
                      </b-popover>
                    </div>
                    <div class="code">
                      <p>{{ courseCodes[event.course_id].code }}</p>
                    </div>
                  </div>
                  <div class="date text-center">
                    <p class="mb-0">{{ event.start_datetime.format("DD/MM/YYYY") }}</p>
                  </div>
                  <div class="timing d-flex justify-content-between">
                    <div class="start">
                      <p>{{ event.start_datetime.format("hh:mm A") }}</p>
                    </div>
                    <div class="end">
                      <p>{{ event.end_datetime.format("hh:mm A") }}</p>
                    </div>
                  </div>
                  <div class="joinNow text-center">
                    <span
                      v-if="event.status == 'cancelled'"
                      class="text-danger"
                      style="font-size: 14px; font-weight: 600; text-align: center"
                      >Cancelled</span
                    >
                    <b-button
                      v-else
                      id="joinNow"
                      class="mx-auto"
                      @click="joinNow(event.id, event.event_url)"
                      >Join Now</b-button
                    >
                  </div>
                </b-overlay>
              </div>
            </div>
            <h5 class="text-danger text-center" v-else>No Events Created Yet!</h5>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import CreateLiveClasses from "../CreateLiveClasses.vue";
import LinkGoogleWithEzygo from "../LinkGoogleWithEzygo.vue";
import moment from "moment";
moment.suppressDeprecationWarnings = true;
import acl from "../../../../../authorization/acl.js";
export default {
  async created() {
    this.fetchData = true;
    const instructorLiveClass = await acl.isUserGranted("instructorLiveClass");
    this.instructorAccess = instructorLiveClass;
    const studentLiveClass = await acl.isUserGranted("studentLiveClass");
    this.studentLiveClass = studentLiveClass;
    await this.getAuthenticationDetails();
    this.getUpcomingEvents();
  },
  components: {
    createLiveClass: CreateLiveClasses,
    "link-google": LinkGoogleWithEzygo,
  },
  data() {
    return {
      fetchData: false,
      today: moment(),
      loadData: false,
      createLiveClass: false,
      courses: [],
      coursesOptions: [],
      googleEvents: [],
      courseCodes: {},
      googleAuthenticated: false,
      instructorAccess: null,
      studentLiveClass: null,
      liveClassUpdate: {},
      searchBy: "",
      filterEventsBtn: {
        upcomings: "outline-primary",
        past: "outline-primary",
        upcomingEvents: false,
        pastEvents: false,
      },
    };
  },
  computed: {
    filteredEvents() {
      if (this.filterEventsBtn.upcomingEvents && !this.filterEventsBtn.pastEvents) {
        return this.googleEvents.filter((event) => {
          const eventStartDate = event.start_datetime;
          return (
            this.today.format("YYYY/MM/DD") == eventStartDate.format("YYYY/MM/DD") ||
            this.today.format("YYYY/MM/DD") < eventStartDate.format("YYYY/MM/DD")
          );
        });
      } else if (
        this.filterEventsBtn.pastEvents &&
        !this.filterEventsBtn.upcomingEvents
      ) {
        return this.googleEvents.filter((event) => {
          const eventStartDate = event.start_datetime;
          const eventEndDate = event.end_datetime;
          return (
            this.today.format("YYYY/MM/DD") > eventStartDate.format("YYYY/MM/DD") &&
            this.today.format("YYYY/MM/DD") > eventEndDate.format("YYYY/MM/DD")
          );
        });
      } else if (this.filterEventsBtn.upcomingEvents && this.filterEventsBtn.pastEvents) {
        return this.googleEvents;
      } else {
        return this.googleEvents;
      }
    },
  },
  methods: {
    async getUpcomingEvents() {
      if (this.filterEventsBtn.upcomings == "info" && !this.filterEventsBtn.pastEvents) {
        return;
      }
      if (
        this.filterEventsBtn.upcomings == "info" &&
        this.filterEventsBtn.upcomingEvents
      ) {
        this.filterEventsBtn.upcomings = "outline-primary";
        this.filterEventsBtn.upcomingEvents = false;
        return;
      }

      this.filterEventsBtn.upcomings = "info";
      this.filterEventsBtn.upcomingEvents = true;
    },

    async getPastEvents() {
      if (this.filterEventsBtn.past == "info" && !this.filterEventsBtn.upcomingEvents) {
        return;
      }
      if (this.filterEventsBtn.past == "info" && this.filterEventsBtn.pastEvents) {
        this.filterEventsBtn.past = "outline-primary";
        this.filterEventsBtn.pastEvents = false;
        return;
      }
      this.filterEventsBtn.past = "info";
      this.filterEventsBtn.pastEvents = true;
    },
    async joinNow(eventId, eventURL) {
      this.loadData = true;
      const url = this.$store.getters.getAPIKey.mainAPI + `/googleevents/${eventId}/join`;
      await this.$axios
        .get(url)
        .then((response) => {
          window.open(eventURL, "_blank");
          response;
        })
        .catch((error) => {
          error;
        });
      this.loadData = false;
    },
    getGoogleEvents() {
      const url = this.$store.getters.getAPIKey.googleEvent;
      axios
        .get(url)
        .then((response) => {
          this.googleEvents = [];
          let events = response.data;
          //   this.googleEvents = response.data;
          events.forEach((event) => {
            this.googleEvents.push({
              id: event.id,
              summary: event.summary,
              description: event.description,
              start_datetime: moment(event.start_datetime),
              end_datetime: moment(event.end_datetime),
              course_id: event.course_id,
              event_url: event.event_url,
              status: event.status,
            });
          });
          this.fetchData = false;
          response;
        })
        .catch((error) => {
          error;
        });
    },
    async getGoogleEvent(eventId) {
      this.loadData = true;
      const url = this.$store.getters.getAPIKey.googleEvent + `/${eventId}`;
      await axios
        .get(url)
        .then((response) => {
          response;
          this.liveClassUpdate = response.data;
          this.loadData = false;
          this.createLiveClass = true;
        })
        .catch((error) => {
          this.loadData = false;
          error;
        });
    },
    cancelLiveClass() {
      this.createLiveClass = false;
      this.liveClassUpdate = {};
    },
    getCourseDetails() {
      //   this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/institutionuser/courses/withusers`;
      axios
        .get(url)
        .then((response) => {
          this.courses = [];
          this.coursesOptions = [{ value: null, text: "Select Course", disabled: true }];
          this.courses = response.data;
          this.courses.forEach((course) => {
            this.coursesOptions.push({ value: course.id, text: course.name });
            this.courseCodes[course.id] = {};
            this.courseCodes[course.id] = {
              code: `${course.code}-${course.usersubgroup.code}-${course.usersubgroup.usergroup.code}`,
              name: course.name,
            };
          });
          this.getGoogleEvents();
          response;
        })
        .catch((error) => {
          error;
        });
    },
    getAuthenticationDetails() {
      const url = this.$store.getters.getAPIKey.mainAPI + `/user/google/oauth2detailes`;
      axios
        .get(url)
        .then((response) => {
          if (response.data != "" || this.studentLiveClass) {
            this.googleAuthenticated = true;
            this.getCourseDetails();
          } else {
            this.fetchData = false;
          }
          response;
        })
        .catch((error) => {
          error;
        });
    },
  },
};
</script>

<style >
#joinNow {
  background-color: #00796b;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.25px;
  padding: 10px !important;
  border-radius: 15px;
}
.liveClassEvents .googleEvent p {
  font-weight: 600;
  font-size: 14px;
}

@media only screen and (max-width: 600px) and (orientation: portrait) {
  #joinNow {
    background-color: #00796b;
    color: red;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0.25px;
    padding: 10px !important;
    border-radius: 15px;
  }
  #createGoogleEvent {
    font-size: 14px;
  }
  #liveClassContainer {
    padding: 0px !important;
  }
}
</style>
