<template>
  <div>
    <div class="updateRollNumber text-center">
      <b-button
        variant="primary"
        size="md"
        :disabled="rollNumberBtn.disabled"
        @click="updateRollNumber()"
        >{{ rollNumberBtn.text }}</b-button
      >
      <b-button
        variant="danger"
        class="ml-3"
        size="md"
        :disabled="rollNumberBtn.disabled"
        @click="$emit('rollNumbersUpdated')"
        >Cancel</b-button
      >
    </div>

    <b-list-group>
      <b-list-group
        v-for="(student, studentIndex) in studentRollNumbers"
        :key="studentIndex"
        class="w-100"
      >
        <div class="student  d-flex justify-content-between">
          <div class="name">
            <p>{{ student.name }}</p>
          </div>
          <div class="rollNumber">
            <b-form-input
              v-model="student.roll_number"
              class="text-center"
              placeholder="Enter Roll Number"
            ></b-form-input>
          </div>
        </div>
      </b-list-group>
    </b-list-group>
  </div>
</template>

<script>
export default {
  props: ["listOfUsersEnrolled", "selectedSubgroupUsers", "groupInfo"],
  created() {
    this.prepareUsersRollnumberList();
  },
  data() {
    return {
      studentRollNumbers: [],
      rollNumberBtn: {
        text: "Submit",
        disabled: false,
      },
      responseErrors: {},
      responseSuccess: {},
    };
  },
  methods: {
    prepareUsersRollnumberList() {
      this.studentRollNumbers = [];
      this.listOfUsersEnrolled.forEach((student) => {
        const studentId = student.id;
        this.selectedSubgroupUsers.forEach((selectedId) => {
          if (studentId == selectedId) {
            this.studentRollNumbers.push({
              name: student.first_name + " " + (student.last_name || ""),
              institution_user_id: student.pivot.institution_user_id,
              roll_number:
                student.roll_number != "" || null ? student.roll_number : "",
            });
          }
        });
      });
    },
    async updateRollNumber() {
      this.rollNumberBtn.text = "Updating...";
      this.rollNumberBtn.disabled = true;
      let groupUrl = this.$store.getters.getAPIKey.enrollSubGroupUser;
      const url = groupUrl.replace("usersubgroup_id", this.groupInfo.id);
      const studentData = {
        institution_users_roll_number: [],
      };
      await this.studentRollNumbers.forEach((student) => {
        studentData.institution_users_roll_number.push({
          institution_user_id: student.institution_user_id,
          roll_number: student.roll_number,
        });
      });
      await this.$axios
        .post(url, studentData)
        .then((response) => {
          this.responseSuccess["created"] = "RollNumbers Updated!";
          this.$toast.success("Roll Numbers Updated Successfully", {
            position: "top",
            duration: 3000,
          });
          this.clearResponse();
          response;
        })
        .catch((error) => {
          this.$toast.error(
            error.response.status == 500
              ? "Something went wrong, Please try again later!"
              : "Something went wrong, Please check the input fields",
            {
              position: "top",
              duration: 3000,
            }
          );
          this.clearResponse();
        });
    },
    clearResponse() {
      this.rollNumberBtn.text = "Submit";
      setTimeout(() => {
        if (this.responseSuccess["created"]) {
          this.$emit("rollNumbersUpdated");
        }
        this.rollNumberBtn.disabled = false;
      }, 3000);
    },
  },
};
</script>
