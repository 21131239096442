var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.fetchData)?_c('div',{staticClass:"loading"},[_c('load-data')],1):_c('div',{staticClass:"applicationStatus"},[_c('b-overlay',{attrs:{"show":_vm.loadApproval,"spinner-variant":"primary","spinner-type":"grow","spinner-small":"","rounded":"md"}},[_c('h3',{staticClass:"text-info"},[_vm._v(_vm._s(_vm.eventName))]),_c('div',{staticClass:"applicationForms mb-2 d-flex flex-wrap"},_vm._l((_vm.applications),function(application,applicationIndex){return _c('b-button',{key:applicationIndex,attrs:{"size":"sm","variant":_vm.activeApplication == applicationIndex
              ? 'outline-dark'
              : 'outline-success'},on:{"click":function($event){return _vm.getApplicationStatus(application.id, applicationIndex)}}},[_c('span',[_vm._v("Form "+_vm._s(applicationIndex + 1)+" ")]),_vm._v(" "),_c('br'),_vm._v(" "+_vm._s(application.end_at != null ? _vm.convertTimeToLocal(application.end_at).format( "DD/MM/YYYY hh:mm a" ) : "")+" ")])}),1),(_vm.currentApplication != null)?_c('div',{staticClass:"application border border-info rounded p-3 d-flex justify-content-between flex-wrap bg-white"},_vm._l((_vm.approvalQuestions),function(question,questionIndex){return _c('div',{key:questionIndex,staticClass:"question w-50 mb-2"},[_c('p',{staticClass:"ques"},[_vm._v(_vm._s(question.question_no)+") "+_vm._s(question.name))]),(question.answer != null)?_c('p',{staticClass:"ans text-info ml-3"},[_vm._v(" "+_vm._s(question.answer)+" ")]):_vm._e(),_c('div',{staticClass:"d-flex flex-column"},_vm._l((question.files),function(file,fileIndex){return _c('span',{key:fileIndex,staticClass:"text-dark ml-3"},[_c('a',{staticClass:"text-decoration-none",staticStyle:{"font-size":"12px","font-weight":"600"},attrs:{"href":_vm.userAttachments + file.file_path + file.file_name,"target":"_blank"}},[_vm._v(_vm._s(file.file_name))])])}),0)])}),0):_vm._e(),_c('div',{staticClass:"approvers mt-2"},_vm._l((_vm.approversList),function(approver,approverIndex){return _c('div',{key:approverIndex,staticClass:"approver mb-2 border border-primary p-2 rounded"},[_c('div',{staticClass:"approverDetails row"},[_c('p',{staticClass:"name text-capitalize col-md-5"},[_vm._v(" Name: "+_vm._s(approver.institution_user.first_name)+_vm._s(approver.institution_user.last_name || "")+" ")]),_c('p',{staticClass:"col-md-2"},[_vm._v(" Role: "),_c('span',[_vm._v(_vm._s(approver.role))])]),_c('p',{staticClass:"col-md-5 text-right"},[_vm._v(" Status: "),(
                  _vm.applicationStatus[approver.id] &&
                    _vm.applicationStatus[approver.id][_vm.currentApplication] &&
                    _vm.applicationStatus[approver.id][_vm.currentApplication]
                      .approval_status == 'approved'
                )?_c('span',{staticClass:"text-success"},[_vm._v("Approved")]):(
                  _vm.applicationStatus[approver.id] &&
                    _vm.applicationStatus[approver.id][_vm.currentApplication] &&
                    _vm.applicationStatus[approver.id][_vm.currentApplication]
                      .approval_status == 'rejected'
                )?_c('span',{staticClass:"text-danger"},[_vm._v("Rejected")]):(
                  _vm.applicationStatus[approver.id] &&
                    _vm.applicationStatus[approver.id][_vm.currentApplication] &&
                    _vm.applicationStatus[approver.id][_vm.currentApplication]
                      .approval_status == 'indirect_approved'
                )?_c('span',{staticClass:"text-success"},[_vm._v("Indirect Approved")]):(
                  _vm.applicationStatus[approver.id] &&
                    _vm.applicationStatus[approver.id][_vm.currentApplication] &&
                    _vm.applicationStatus[approver.id][_vm.currentApplication]
                      .approval_status == 'resubmission'
                )?_c('span',{staticClass:"text-info"},[_vm._v("Resubmission")]):_c('span',{staticClass:"text-warning"},[_vm._v("Pending")])])]),_c('p',[_vm._v(" Comments: "),(
                _vm.applicationStatus[approver.id] &&
                  _vm.applicationStatus[approver.id][_vm.currentApplication] &&
                  _vm.applicationStatus[approver.id][_vm.currentApplication]
                    .comments != null
              )?_c('span',{staticClass:"text-dark"},[_vm._v(" "+_vm._s(_vm.applicationStatus[approver.id][_vm.currentApplication].comments)+" ")]):_c('span',{staticClass:"text-danger"},[_vm._v("No Comments!")])])])}),0)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }