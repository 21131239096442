<template>
  <div>
    <b-modal id="instituitionProfile" :title="selectedInstitute.name" no-close-on-backdrop no-close-on-keyboard
      no-close-on-esc hide-header-close hide-footer modal-class="instituitionProfile">
      <instituition-user-profile ref="userProfile" :userId="userId" :institutionUsersList="institutionUsersList"
        :institution_users="institution_users" @profilesFinished="profilesFinished"
        :institutionUsersObject="institutionUsersObject"></instituition-user-profile>
    </b-modal>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>

    <div class="usersListPending" v-else>
      <br />

      <div v-if="institutionUsersList.length > 0">
        <institute-invite @invitationClosed="inviteInstitutionUsers = false" v-if="inviteInstitutionUsers"
          ref="inviteUsers" :selectedInstitute="selectedInstitute"></institute-invite>
        <div class="d-flex justify-content-between align-items-center p-2 w-100"
          style="border: 2px solid #eeeeeb;border-left-style: none;border-right-style: none;">
          <div class="d-flex">
            <b-button variant="info" size="sm" @click="institutionUsers()"><i
                class="fas fa-sync-alt fa-2x"></i></b-button>
            <b-button variant="info" size="sm" class="ml-3" @click="inviteUsers()"><i class="fas fa-user-plus"></i>
              Invite users</b-button>
          </div>

          <b-form-input v-model="search" placeholder="Search by name/username/role" style="width: 300px"></b-form-input>
        </div>
        <br />
        <b-form style="border: none; padding: none; border-radius: none">
          <b-form-group>
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex">
                <b-form-checkbox size="lg" v-model="allUsersSelected" @change="toggleAll"
                  :indeterminate="indeterminate">{{
                      allUsersSelected ? "Un-select All" : "Select All"
                  }}</b-form-checkbox>

                <b-button variant="danger" class="ml-3" size="sm" @click="updateUsersEnrollStatus('blocked')"
                  :disabled="!enableEnrollStatusBtn">{{ enableEnrollStatusBtn ? 'Disable' : 'working...' }}</b-button>
                <b-button variant="success" class="ml-3" size="sm" @click="updateUsersEnrollStatus('active')"
                  :disabled="!enableEnrollStatusBtn">{{ enableEnrollStatusBtn ? 'Enable' : 'working...' }}</b-button>
                <b-button variant="info" class="ml-3" size="sm" @click="createProfile()">View Profile/Edit</b-button>
              </div>
              <p class="m-0 p-0 font-weight-bolder bg-success text-white rounded p-1">
                Users Count: <span v-if="search">{{ filteredUsers.length }}/</span>{{ institutionUsersList.length }}
              </p>
            </div>
          </b-form-group>

          <span class="text-danger" v-if="responseErrors.institution_users">{{
              responseErrors.institution_users[0]
          }}</span>
          <span class="text-success" v-if="approveSuccess">{{
              institution_users.length > 1 ? "Users Approved" : "User Approved"
          }}</span>
          <div>
            <div class="table-responsive">
              <table class="table table-striped table-bordered" id="usersPendingTable">
                <thead class="thead-dark text-left">
                  <tr>
                    <th></th>
                    <th>Name</th>

                    <th>Username</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Guardian</th>
                    <th>Role</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="" v-for="(users, userIndex) in filteredUsers" :key="userIndex">
                    <td>
                      <b-form-checkbox v-model="institution_users" :value="users.id" size="sm">
                      </b-form-checkbox>
                    </td>
                    <td>
                      <span class="btn p-0 m-0 text-info font-weight-bolder text-capitalize"
                        @click="goToUserProfile('UserProfile', users.id)">{{ users.first_name || " " }}
                        {{ users.last_name || " " }}
                      </span>
                      <b-badge v-if="users.enroll_status != 'active'" variant="danger" class="ml-2">
                        disabled
                      </b-badge>
                      <b-badge href="#" v-if="(users.user == null)" @click="resendInvitation(users.id)" variant="info"
                        class="ml-2">
                        Resend
                      </b-badge>
                    </td>

                    <td>{{ users.user ? users.user.username : '' }}
                      <b-badge v-if="(users.user == null)" variant="warning" class="ml-2">
                        unenrolled
                      </b-badge>
                    </td>
                    <td>{{ users.user ? users.user.email : users.email }}</td>
                    <td>{{ users.user ? users.user.mobile : users.mobile }}</td>
                    <td>{{ users.guardian_mobile }}</td>
                    <td>{{ users.institution_role.name in roleNames ? roleNames[users.institution_role.name] :
                        users.institution_role.name
                    }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </b-form>
        <institute-card-view v-if="viewType == 'Card'"
          :institutionUsersList="institutionUsersList"></institute-card-view>
      </div>
      <div v-else>
        <h6 class="text-center">No users enrolled yet!</h6>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import InstituteCardView from "../InstituteCardView.vue";
import InstituitionUserProfile from "./InstituitionUserProfile.vue";
import InstituteInvite from "../invite/InstituitionInvite.vue";

export default {
  async created() {
    await this.institutionUsers();
  },
  mounted() {
    this.startTimer();
  },
  components: {
    instituteCardView: InstituteCardView,
    InstituitionUserProfile: InstituitionUserProfile,
    "institute-invite": InstituteInvite,
  },
  props: ["selectedInstitute"],
  data() {
    return {
      inviteInstitutionUsers: false,
      fetchData: false,
      viewType: "List",
      roles: true,
      allUsersSelected: false,
      groups: false,
      institutionUsersList: [],
      institution_users: [],
      responseErrors: {},
      approveSuccess: false,
      approveBtn: {
        text: "Approve",
        disabled: false,
      },
      selectAll: false,
      indeterminate: false,
      profile: false,
      userId: null,
      search: null,
      institutionUsersObject: {},
      enableEnrollStatusBtn: true,
      roleNames: { admin: 'Admin', teacher: 'Faculty', student: 'Student' },
    };
  },
  computed: {
    filteredUsers() {
      if (this.search) {
        return this.institutionUsersList.filter((user) => {
          let name = user.first_name + " " + (user.last_name || "");
          let username = user.user ? user.user.username : '';
          let role = user.institution_role.name == 'teacher' ? 'faculty teacher' : user.institution_role.name;
          return this.search
            .toLowerCase()
            .split(" ")
            .every(
              (v) =>
                name.toLowerCase().includes(v) ||
                username.toLowerCase().includes(v) ||
                role.toLowerCase().includes(v)
            );
        });
      } else {
        return this.institutionUsersList;
      }
    },
  },
  methods: {

    async resendInvitation(InstitutionUserId) {
      const url = `${this.$store.getters.getAPIKey.mainAPI}/institutionusers/${InstitutionUserId}/invitation/resent`;
      this.$axios
        .get(url)
        .then(() => {
          this.$toast.success(`Enrollment code sent successfully`, {
            top: "top",
            duration: 3000,
          });
        })
        .catch(() => {
          this.$toast.error(`Failed to sent enrollment code`, {
            top: "top",
            duration: 3000,
          });
        });
    },

    goToUserProfile(path, profileId) {
      let routeData = this.$router.resolve({
        name: path,
        params: {
          profileId: btoa(profileId),
        },
      });
      window.open(routeData.href, "_blank");
    },
    async inviteUsers() {
      this.inviteInstitutionUsers = await true;
      await this.inviteInstitutionUsers;
      await this.$refs.inviteUsers.prepareInviteUsers();
    },
    startTimer() {
      // if(this.timePassed != 0) {
      this.timerInterval = setInterval(() => (this.timePassed += 1), 1000);
      // }
    },
    filterUsers() {
      if (this.search) {
        return this.institutionUsersList.filter((user) => {
          let name = user.first_name + " " + (user.last_name || "");
          return this.search
            .toLowerCase()
            .split(" ")
            .every((v) => name.toLowerCase().includes(v));
        });
      } else {
        return this.institutionUsersList;
      }
    },
    changeView(type) {
      this.viewType = type;
    },
    async institutionUsers() {
      this.fetchData = true;
      const url = this.$store.getters.getAPIKey.institutionUsers;
      await axios
        .get(url)
        .then((response) => {
          if (response) {
            this.institutionUsersList = response.data;

            this.$parent.totalUsers = this.institutionUsersList.length;
            // alert(this.institutionUsersList.length);

            const getStaff = this.institutionUsersList.filter((staff) => {
              return staff.institution_role.name == "teacher";
            });
            this.staffCount = getStaff.length;
            this.$store.state.staffCount = getStaff.length;

            const getStudents = this.institutionUsersList.filter((student) => {
              return student.institution_role.name == "student";
            });

            this.$store.state.studentCount = getStudents.length;
          }
          if (this.institutionUsersList.length != 0) {
            this.totalUsers();
          }

          this.institutionUsersList.forEach((user) => {
            this.$set(this.institutionUsersObject, user.id, user);
          });

          this.fetchData = false;

          response;
        })
        .catch((error) => {
          error;
        });
    },
    async totalUsers() {
      this.$store.state.totalInstitutionUsers = await this.institutionUsersList
        .length;
    },
    approveUser() {
      this.approveBtn.text = "Please Wait...";
      this.approveBtn.disabled = true;
      const url = this.$store.getters.getAPIKey.approveUser;
      axios
        .post(url, { institution_users: this.institution_users })
        .then((response) => {
          this.approveBtn.text = "Approve";
          this.approveBtn.disabled = false;
          this.institution_users = [];
          this.institutionUsers();
          this.approveSuccess = !this.approveSuccess;
          setTimeout(() => {
            this.approveSuccess = !this.approveSuccess;
          }, 3000);
          response;
        })
        .catch((error) => {
          this.approveBtn.text = "Approve";
          this.approveBtn.disabled = false;
          this.responseErrors = error.response.data.errors;
          error;
          setTimeout(() => {
            this.responseErrors = {};
          }, 3000);
        });
    },

    updateUsersEnrollStatus(status) {
      this.enableEnrollStatusBtn = false;
      const url = this.$store.getters.getAPIKey.mainAPI + '/institutionusers/enroll_status';
      axios
        .post(url, { institution_users: this.institution_users, enroll_status: status })
        .then(() => {
          this.$toast.success('Enroll status updated successfully');
          this.institution_users = [];
          this.institutionUsers();
        })
        .catch(() => {
          this.$toast.error('Failed to update enroll status, please try again');
        });
      this.enableEnrollStatusBtn = true;
    },
    toggleAll(checked) {
      let institutionUsers = [];
      this.institutionUsersList.forEach((user) => {
        // if (user.institution_approved == 0) {
        institutionUsers.push(user.id);
        // }
      });
      this.institution_users = checked ? institutionUsers.slice() : [];
    },
    createProfile() {
      this.$bvModal.show("instituitionProfile");
    },
    profilesFinished() {
      this.userId = null;
      this.institution_users = [];
      this.allSelected = false;
      this.$bvModal.hide("instituitionProfile");
    },
  },
};
</script>

<style >
.usersListPending i {
  font-size: 12px !important;
}
</style>
