<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="UniversityExamConfig" v-else>
      <b-col class="universityExamCreation" v-if="updateScoreComponent">
        <UniversityExamUpdateCourseCoScore
          @finishUpdateScore="(updateScoreComponent=false)"
          :boardExam="boardExam"
          :courseId="courseId"
          :multipleEvaluation="true"
        />
      </b-col>
      <b-row v-else>
        <b-col md="12">
          <div class="configHeading mb-2 text-info">University Exam Config</div>
          <br>
          <div class="" style="border: 2px solid #17a2b8; padding: 15px; border-radius: 5px;">
            <ul>
              <!-- You have two options to copy course data:
              <li>
                Copy from University Resources <span style="color: #2929aa; text-decoration: underline; cursor: pointer;"
                  @click="copy_from = 'template'; $bvModal.show('copyCoursePropertiesModel')">Click here to
                  proceed</span>
              </li> -->
              <li>Copy from previous academic year <span
                  style="color: #2929aa; text-decoration: underline; cursor: pointer;"
                  @click="copy_from = 'myinstitute'; $bvModal.show('copyCoursePropertiesModel')">Click here to
                  proceed</span>
              </li>
            </ul>
            <b-modal id="copyCoursePropertiesModel" no-close-on-backdrop no-close-on-keyboard no-close-on-esc
              hide-footer hide-header centered size="md">
              <CopyCourseProperties :copy_from_suggestion="copy_from" :course_id="courseId"
                @success="propertyCopyCompleted()" @cancel="$bvModal.hide('copyCoursePropertiesModel')" />
            </b-modal>
          </div>
          <div class="text-center m-2 text-primary"> OR </div>
          <b-form @submit.prevent="prepareUniversityExamPostData()">
            <!-- <b-form-group>
              <div class="d-flex">
                <b-form-radio v-model="examConfig.enable_grading" size="md" :value="true">Grade</b-form-radio>
                <b-form-radio v-model="examConfig.enable_grading" class="ml-4" size="md" :value="false" disabled>Marks
                </b-form-radio>
              </div>
              <span class="input-error-message" v-if="
                !$v.examConfig.enable_grading.required &&
                $v.examConfig.enable_grading.$dirty
              ">Please Select One!

                <br /></span>
            </b-form-group> -->

            <b-row>
              <b-col>
                <b-form-input v-model="examConfig.maximum_mark" placeholder="Maximum Mark" min="0" step="0.1" type="number">
                </b-form-input>
                <b-form-input v-model="examConfig.pass_mark" placeholder="Pass Mark" min="0" step="0.1" type="number">
                </b-form-input>
              </b-col>
            </b-row>
            <b-form-group v-if="examConfig.enable_grading == true">
              <b-button variant="info" size="sm" @click="addMarkField">+ Add Grade/Score</b-button>
              <div class="marks mt-2 mb-2 d-flex" v-for="(mark, markIndex) in marks" :key="markIndex">
                <b-form-input v-model="mark.grade" placeholder="Enter Grade"></b-form-input>
                <b-form-input v-model="mark.score" class="ml-2" placeholder="Enter Score"></b-form-input>
                <span class="text-danger ml-2 btn m-0 p-0" @click="marks.splice(markIndex, 1)">
                  <i class="fas fa-minus-circle"></i>
                </span>
              </div>
            </b-form-group>

            <!-- <b-form-group v-if="examConfig.enable_grading == false">
              <div class="d-flex">
                <b-form-radio v-model="examConfig.scoring_type" size="md" value="totel_score">Total Score</b-form-radio>
                <b-form-radio v-model="examConfig.scoring_type" disabled size="md" value="internal_external"
                  class="ml-4"><span title="Currently not available">
                    Internal & External
                  </span></b-form-radio>
              </div>
            </b-form-group> -->
            <b-form-group> </b-form-group>
            <!-- <div class="marks w-50 d-flex justify-content-between" v-if="
              examConfig.enable_grading == false &&
              examConfig.scoring_type &&
              examConfig.scoring_type == 'totel_score'
            ">
              <b-form-group label="Maximum Marks">
                <b-form-input placeholder="Maximum Marks" v-model="examConfig.maximum_mark" :class="{
                  'form-group-error': $v.examConfig.maximum_mark.$error,
                }"></b-form-input>
                <span class="input-error-message" v-if="
                  !$v.examConfig.maximum_mark.required &&
                  $v.examConfig.maximum_mark.$dirty
                ">Maximum Marks is required!

                  <br /></span>
                <span class="input-error-message" v-if="
                  !$v.examConfig.maximum_mark.integer &&
                  $v.examConfig.maximum_mark.$dirty
                ">Only Integers are allowed!</span>
              </b-form-group>
              <b-form-group label="Passing Marks">
                <b-form-input placeholder="Passing Marks" v-model="examConfig.pass_mark" :class="{
                  'form-group-error': $v.examConfig.pass_mark.$error,
                }"></b-form-input>
                <span class="input-error-message" v-if="
                  !$v.examConfig.pass_mark.required &&
                  $v.examConfig.pass_mark.$dirty
                ">Passing Marks is required!

                  <br /></span>
                <span class="input-error-message" v-if="
                  !$v.examConfig.pass_mark.integer &&
                  $v.examConfig.pass_mark.$dirty
                ">Only Integers are allowed!</span>
              </b-form-group>
            </div> -->
            <b-form-group class="text-center" v-if="
              examConfig.enable_grading ||
              (!examConfig.enable_grading &&
                examConfig.scoring_type == 'totel_score')
            ">
              <b-button variant="primary" size="sm" type="submit" :disabled="configBtn.disabled">
                {{ configBtn.text }}
              </b-button>
            </b-form-group>
          </b-form>

          <br> 
          <div class="border border-primary">

            <div class="table-responsive">
          <table class="table table-striped table-bordered">
            <thead class="thead-dark text-left">
              <tr>
                <th class="align-middle">University Exam</th>
                <!-- <th class="align-middle">
                  {{
                      getCustomNames.userSubgroup.custom_name != null
                        ? getCustomNames.userSubgroup.custom_name
                        : "Usersubgroup"
                  }}
                  - Academic Year
                </th> -->
                <th class="align-middle"></th>
                <!--<th class="align-middle"></th>-->
                <th class="align-middle"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="boardExams.length == 0">
                <td colspan="100" class="text-center text-danger">
                  No Exams Created Yet!
                </td>
              </tr>
              <tr v-for="(exam, examIndex) in boardExams" :key="examIndex" v-else>
                <td class="align-middle">
                  <span>{{ exam.name }} </span>
                </td>
                <td>
                  <b-link v-if="exam.mark_entry_type=='question_wise'" @click="
                    manageExamQuestions(exam)
                    ">Create question paper</b-link>
                </td>
                <!--<td>
                  <b-link @click="universityexamEvaluation(exam.id)">
                    Update Question score</b-link>
                </td>-->
                <!-- <td class="align-middle">
                  {{ exam.usersubgroup.name + " (" + exam.usersubgroup.code + ")" }}
                  - {{ exam.usersubgroup.academic_year }}
                </td> -->

                <td class="align-middle">
                  <b-button
                    variant="info"
                    class="text-uppercase"
                    @click="
                      updateBoardExamScore(examIndex)
                    "
                    >Update Marks</b-button
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
          </div>
          <br> 
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import UniversityExamUpdateCourseCoScore from "./UniversityExamUpdateCourseCoScore.vue";
import CopyCourseProperties from "../Course/CopyCourseProperties.vue"
import { required, requiredIf, decimal } from "vuelidate/lib/validators";
required;
export default {
  props: ["courseId"],
  async created() {
    this.fetchData = true;
    await this.getUniversityExamConfig();
    await this.getBoardExamsList();    
    this.fetchData = false;
  },
  components: {
    CopyCourseProperties,
    UniversityExamUpdateCourseCoScore,
  },
  data() {
    return {
      fetchData: false,
      examConfig: {},
      marksType: null,
      configBtn: {
        text: "Submit",
        disabled: false,
      },
      marks: [],
      copy_from: '',
      boardExams:[],
      boardExam:{},
      updateScoreComponent:false,
    };
  },
  computed:{
    getCustomNames() {
      return this.$store.getters.getCustomNamings;
    },
  },
  validations: {
    examConfig: {
      enable_grading: {
        required,
      },
      maximum_mark: {
        required: requiredIf(function () {
          if (
            !this.examConfig.enable_grading &&
            this.examConfig.scoring_type == "totel_score"
          ) {
            return true;
          }
        }),
        decimal,
      },
      pass_mark: {
        required: requiredIf(function () {
          if (
            !this.examConfig.enable_grading &&
            this.examConfig.scoring_type == "totel_score"
          ) {
            return true;
          }
        }),
        decimal,
      },
    },
  },
  methods: {
    universityexamEvaluation(exam_id) {
      let route = this.$router.resolve({
          path: `/university_exam/manage_exam_evaluation/${exam_id}/${this.courseId}`
      });
      window.open(route.href, '_blank');
    },
    manageExamQuestions(exam) {
      let route = this.$router.resolve({
          path: `/university_exam/manage_exam_question/${exam.id}/${this.courseId}`
      });
      window.open(route.href, '_blank');
    },
    async updateBoardExamScore(index) {
      this.boardExam = this.boardExams[index];
      if(this.boardExam.mark_entry_type=='question_wise'){
          this.universityexamEvaluation(this.boardExam.id);
      }
      else{
        this.updateScoreComponent=true; 
      }
      
    },
    async getBoardExamsList() {
      const url = this.$store.getters.getAPIKey.mainAPI + `/boardexams?course=${this.courseId}`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.boardExams = response.data;
          response;
        })
        .catch((error) => {
          error;
        });
    },
    async propertyCopyCompleted() {
      this.$bvModal.hide('copyCoursePropertiesModel')
      this.fetchData = true;
      await this.getUniversityExamConfig();
      this.fetchData = false;
    },
    addMarkField() {
      this.marks.push({
        grade: null,
        score: null,
      });
    },
    async getUniversityExamConfig() {
      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/courses/${this.courseId}/courseboardexamconfig`;
      await this.$axios
        .get(url)
        .then((response) => {
          let configResponseData = response.data;
          this.examConfig = {
            enable_grading:true,
              // configResponseData.enable_grading &&
              //   configResponseData.enable_grading == 1
              //   ? true
              //   : false,
            // scoring_type: configResponseData.scoring_type,
            maximum_mark:
              configResponseData.maximum_mark != null
                ? parseFloat(configResponseData.maximum_mark)
                : null,
            pass_mark:
              configResponseData.pass_mark != null
                ? parseFloat(configResponseData.pass_mark)
                : null,
          };
          if (configResponseData.grading_level) {
            this.marks = configResponseData.grading_level;
          }
          response;
          this.fetchData = false;
        })
        .catch((error) => {
          error;
          this.fetchData = false;
        });
    },
    async prepareUniversityExamPostData() {
      await this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.configBtn = {
          text: "Please wait....",
          disabled: true,
        };
        let postData = {
          description: null,
          enable_grading: this.examConfig.enable_grading,
          scoring_type:
            !this.examConfig.scoring_type || this.examConfig.enable_grading
              ? null
              : this.examConfig.scoring_type,
          maximum_mark: this.examConfig.maximum_mark,
          pass_mark: this.examConfig.pass_mark,
          grading_level: this.examConfig.enable_grading ? this.marks : null,
          course_id: `${this.courseId}`,
        };

        let configUniversityExam = await this.updateUniversityExamConfig(
          postData
        );
        if (configUniversityExam) {
          this.$toast.success("Configured Successfully", {
            position: "top",
            duration: 3000,
          });
          this.configBtn = {
            text: "Submit",
            disabled: false,
          };
          this.getUniversityExamConfig();
        }
        if (!configUniversityExam) {
          this.$toast.error("Something went wrong, please try again!", {
            position: "top",
            duration: 3000,
          });
          this.configBtn = {
            text: "Submit",
            disabled: false,
          };
        }
      }
    },
    async updateUniversityExamConfig(postData) {
      let status = null;
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/courseboardexamconfigs`;
      await this.$axios
        .post(url, postData)
        .then((response) => {
          response;
          status = true;
        })
        .catch((error) => {
          error;
          status = false;
        });
      return status;
    },
  },
};
</script>

<style >
.UniversityExamConfig form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.UniversityExamConfig form .marks input {
  width: 150px;
}

.UniversityExamConfig form input {
  text-align: center;
}

.configHeading {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}
</style>
