<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="approverForm" v-else>
      <b-form @submit.prevent="prepareApproversForApi()" class="border-0">
        <b-overlay :show="loadApprovers" spinner-variant="primary" spinner-type="grow" spinner-small rounded="md">
          <b-form-group v-if="!approverInfo.id">
            <multiselect v-model="selectedApprovers" :options="approversUsersOptions" :multiple="true"
              :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Select Approvers"
              label="name" track-by="name" :preselect-first="false">
              <template slot="selection"><span class="multiselect__single" v-if="selectedApprovers.length">{{
                  selectedApprovers.length
              }} approvers selected</span></template>
            </multiselect>
          </b-form-group>
          <b-form-group v-if="selectedApprovers.length != 0">
            <table class="table table-bordered table-striped">
              <thead class="thead-dark">
                <tr>
                  <th>Name</th>
                  <th>Role</th>
                  <th>Order</th>
                  <th>Special Privilege</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(approver, approverIndex) in selectedApprovers" :key="approverIndex">
                  <td class="align-middle">
                    <p style="font-size: 14px; font-weight: 600">
                      {{ approver.name }}
                    </p>
                  </td>
                  <td class="align-middle">
                    <b-form-input placeholder="Enter role" v-model="approver.role" style="height: 40px"
                      :disabled="approverBtn.disabled" :class="{
                        'form-group-error':
                          $v.selectedApprovers.$each[approverIndex].role.$error,
                      }"></b-form-input>
                    <span class="input-error-message" v-if="
                      !$v.selectedApprovers.$each[approverIndex].role
                        .required &&
                      $v.selectedApprovers.$each[approverIndex].role.$dirty
                    ">Role is required!</span>
                  </td>
                  <td class="align-middle">
                    <b-form-select style="height: 40px" :options="approvingOrderOptions"
                      :disabled="approverBtn.disabled" v-model="approver.order" :class="{
                        'form-group-error':
                          $v.selectedApprovers.$each[approverIndex].order
                            .$error,
                      }">
                    </b-form-select>
                    <br />
                    <span class="input-error-message" v-if="
                      !$v.selectedApprovers.$each[approverIndex].order
                        .required &&
                      $v.selectedApprovers.$each[approverIndex].order.$dirty
                    ">Order is required!</span>
                  </td>
                  <td class="align-middle">
                    <div class="d-flex align-items-baseline">
                      <span>
                        <b-form-checkbox style="z-index: -2"
                          v-model="approver.approve_all_same_order"></b-form-checkbox>
                      </span>

                      <span :id="'specialApproverInfo' + approverIndex" class="text-info btn p-0 m-0 ml-3"><i
                          class="fas fa-info"></i></span>
                      <b-popover :target="'specialApproverInfo' + approverIndex" triggers="hover" placement="top">
                        If any user with special privilege approves a form, all
                        the other approvers in the same order will be set to
                        auto-approved for that form.
                      </b-popover>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-form-group>

          <b-form-group v-if="selectedApprovers.length != 0">
            <div>
              <b-button variant="primary" type="submit" size="sm" :disabled="approverBtn.disabled">{{ approverBtn.text
              }}</b-button>
              <b-button variant="danger" class="ml-2" @click="cancel()" size="sm"
                :disabled="approverBtn.disabled">Cancel</b-button>
            </div>
          </b-form-group>
        </b-overlay>
      </b-form>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
  props: ["registrationId", "approvers", "approverInfo"],
  created() {
    this.getFacultiesList();
    this.checkApprovingOrder();
  },
  data() {
    return {
      fetchData: false,
      loadApprovers: false,
      selectedApprovers: [],
      approversUsersOptions: [],
      approvingOrderOptions: [],
      approverBtn: {
        text: "Submit",
        disabled: false,
      },
    };
  },
  validations: {
    selectedApprovers: {
      required,
      $each: {
        role: {
          required,
        },
        order: {
          required,
        },
      },
    },
  },
  methods: {
    async prepareApproversForApi() {
      await this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.approverBtn.text = "Please wait...";
        this.approverBtn.disabled = true;
        this.loadApprovers = true;
        for (let approver in this.selectedApprovers) {
          let selectedApprover = await this.selectedApprovers[approver];
          let approverData = await {
            institution_user_id: `${selectedApprover.value}`,
            role: selectedApprover.role,
            order: `${selectedApprover.order}`,
            approve_all_same_order:
              selectedApprover.approve_all_same_order == undefined ||
                !selectedApprover.approve_all_same_order
                ? false
                : true,
            onlineregistration_id: this.registrationId,
          };

          let approverCreation = this.approverInfo.id
            ? await this.updateApprover(approverData)
            : await this.addApprover(approverData);

          if (approverCreation) {
            if (approver == this.selectedApprovers.length - 1) {
              let msg = this.approverInfo.id
                ? "Approver Updated Successfully"
                : "Approvers Added Successfully";
              this.$toast.success(msg, {
                position: "top",
                duration: 3000,
              });
              this.clearResponse(true);
            }
          }
          if (!approverCreation) {
            this.$toast.error(`Something Went Wrong, please try again!`, {
              position: "top-right",
              duration: 3000,
            });
            return this.clearResponse(false);
          }
        }
      }
    },
    async addApprover(approverData) {
      let status = null;
      const url = this.$store.getters.getAPIKey.mainAPI + `/onlineregapprovers`;
      await this.$axios
        .post(url, approverData)
        .then((response) => {
          status = true;
          response;
        })
        .catch((error) => {
          status = false;
          error;
        });
      return status;
    },
    async updateApprover(approverData) {
      let status = null;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/onlineregapprovers/${this.approverInfo.id}`;
      await this.$axios
        .put(url, approverData)
        .then((response) => {
          status = true;
          response;
        })
        .catch((error) => {
          status = false;
          error;
        });
      return status;
    },
    checkApprovingOrder() {
      this.approvingOrderOptions = [];

      let lengthOfOrder = 8;
      this.approvingOrderOptions = [{ value: null, text: "Select Order" }];
      for (let i = 1; i <= lengthOfOrder; i++) {
        this.approvingOrderOptions.push({ value: i, text: i });
      }
    },
    clearResponse(status) {
      this.approverBtn.text = "Submit";
      setTimeout(() => {
        if (status) {
          this.cancel();
        }
        this.loadApprovers = false;
        this.approverBtn.disabled = false;
      }, 3000);
    },
    async getFacultiesList() {
      this.fetchData = true;
      const url = this.$store.getters.getAPIKey.instituitionFaculty;
      await this.$axios
        .get(url)
        .then((response) => {
          this.approversUsersOptions = [];
          const facultyUsers = response.data;
          facultyUsers.forEach((user) => {
            if (user.enroll_status == 'active') this.approversUsersOptions.push({
              value: user.id,
              name: user.first_name + " " + (user.last_name || ""),
              role: "",
              order: null,
            });
          });

          if (this.approverInfo.id) {
            this.selectedApprovers = [
              {
                value: this.approverInfo.approver.value,
                name: this.approverInfo.approver.name,
                role: this.approverInfo.approver.role,
                order: this.approverInfo.approver.order,
                approve_all_same_order: this.approverInfo.approver
                  .approve_all_same_order,
              },
            ];
          }

          this.approversUsersOptions.sort(function (a, b) {
            return b.name - a.name;
          });

          this.fetchData = false;
          response;
        })
        .catch((error) => {
          this.fetchData = false;
          error;
        });
    },
    cancel() {
      this.$emit("cancelled");
    },
  },
};
</script>

<style >
</style>
