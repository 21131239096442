<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="surveyApprovalForm" v-else>
      <b-form @submit.prevent="prepareSurveyApprovalsForApi()">
        <b-overlay
          :show="loadApproval"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="md"
        >
          <b-form-group label="Event Name">
            <b-form-input
              placeholder="Name..."
              v-model="surveyApproval.name"
              :class="{
                'form-group-error': $v.surveyApproval.name.$error,
              }"
            ></b-form-input>
            <span
              class="input-error-message"
              v-if="
                !$v.surveyApproval.name.required &&
                  $v.surveyApproval.name.$dirty
              "
              >Event Name is required</span
            >
          </b-form-group>

          <b-form-group label="Description">
            <b-form-textarea
              placeholder="Enter description..."
              rows="3"
              max-rows="6"
              v-model="surveyApproval.summary"
            ></b-form-textarea>
          </b-form-group>

          <b-form-group label="Select Academic Year:">
            <b-form-select
              class="w-50 text-center"
              :options="$acedemicYears"
              v-model="surveyApproval.academic_year"
              :class="{
                'form-group-error': $v.surveyApproval.academic_year.$error,
              }"
              @change="updateAcademicYear"
            ></b-form-select>
            <br />
            <span
              class="input-error-message"
              v-if="
                !$v.surveyApproval.academic_year.required &&
                  $v.surveyApproval.academic_year.$dirty
              "
              >Academic Date is required!</span
            >
          </b-form-group>

          <b-form-group v-if="!surveyApproval.id" label="Choose Participants">
            <b-form-select
              :options="enrollParticipantsOptions"
              v-model="surveyApproval.participants_only"
            ></b-form-select>
          </b-form-group>
          <p
            class="text-info"
            style="font-size: 12px; font-weight: 600"
            v-if="!surveyApproval.participants_only && !surveyApproval.id"
          >
            Note: Public URL will be created for this event.
          </p>
          <p
            class="text-info"
            style="font-size: 12px; font-weight: 600"
            v-if="!surveyApproval.participants_only && surveyApproval.id"
          >
            Note: Public URL is already created for this event.
          </p>
          <b-form-group
            label="Select Participants"
            v-if="!surveyApproval.id && surveyApproval.participants_only"
          >
            <UsersListForSurvey
              v-model="selectedParticipants"
              :v="$v.selectedParticipants"
              @participantsSynced="clearResponse(true)"
              ref="selectParticipants"
            />
            <span
              class="input-error-message"
              v-if="
                !$v.selectedParticipants.required &&
                  $v.selectedParticipants.$dirty
              "
              >Please Select Participants!</span
            >
          </b-form-group>

          <b-form-group
            v-if="surveyApproval.id && surveyApproval.participants_only"
          >
            <span
              class="btn m-0 p-0 text-info font-weight-bolder"
              @click="editParticipantsDetails()"
              >Edit Participants Details</span
            >
            <b-modal
              id="editParticipants"
              no-close-on-backdrop
              no-close-on-keyboard
              no-close-on-esc
              hide-footer
              hide-header
              hide-header-close
              size="lg"
              centered
            >
              <div
                class="w-100 d-flex align-items-baseline justify-content-between mb-2"
              >
                <h6 style="font-size: 20px; font-weight: 600">
                  Add/Remove Participants
                </h6>
                <span
                  class="btn p-0 m-0"
                  @click="$bvModal.hide('editParticipants')"
                  ><i class="far fa-times-circle fa-1x"></i
                ></span>
              </div>
              <hr />

              <EditParticipantsDetails
                :surveyApproval="surveyApproval"
                ref="editParticipants"
              />
            </b-modal>
          </b-form-group>

          <b-form-group label="Form Submission Start & End Time">
            <div class="d-flex">
              <div
                :class="
                  {
                    'form-group-error': $v.surveyApproval.start_at.$error,
                  } && 'startDate'
                "
              >
                <date-picker
                  v-model="surveyApproval.start_at"
                  placeholder="Select Date & Time"
                  :format="'DD/MM/YYYY hh:mm A'"
                  type="datetime"
                >
                  ></date-picker
                >
                <br />
                <span
                  class="input-error-message"
                  v-if="
                    !$v.surveyApproval.start_at.required &&
                      $v.surveyApproval.start_at.$dirty
                  "
                  >Start Date & Time is required!</span
                >
              </div>
              <div
                :class="
                  {
                    'form-group-error': $v.surveyApproval.end_at.$error,
                  } && 'endDate ml-4'
                "
              >
                <date-picker
                  v-model="surveyApproval.end_at"
                  :format="'DD/MM/YYYY hh:mm A'"
                  placeholder="Select Date & Time"
                  type="datetime"
                ></date-picker
                >
                <br />
                <span
                  class="input-error-message"
                  v-if="
                    !$v.surveyApproval.end_at.required &&
                      $v.surveyApproval.end_at.$dirty
                  "
                  >End Date & Time is required!</span
                >
              </div>
            </div>
          </b-form-group>

          <b-form-group
            label="This message will be displayed to participant after
completion of approval"
          >
            <b-form-input
              placeholder="Enter Message..."
              v-model="surveyApproval.finish_message"
            ></b-form-input>
          </b-form-group>

          <!-- <b-form-group label="Comment Feedback">
            <b-form-checkbox
              v-model="surveyApproval.comment_feedback"
              :value="true"
              switch
            ></b-form-checkbox>
          </b-form-group> -->

          <b-form-group label="Hide Event">
            <b-form-checkbox
              size="sm"
              v-model="surveyApproval.hidden"
              :value="true"
              switch
            ></b-form-checkbox>
          </b-form-group>

          <b-form-group label="Event Anonymous">
            <b-form-checkbox
              v-model="surveyApproval.is_anonymous"
              :value="true"
              switch
            ></b-form-checkbox>
          </b-form-group>

          <span v-if="surveyApproval.participants_only">
            <b-form-group
              label="Multiple Applications"
              v-if="!surveyApprovalInfo.multiple_application"
            >
              <b-form-checkbox
                v-model="surveyApproval.multiple_application"
                :value="true"
                switch
              ></b-form-checkbox>
            </b-form-group>

            <b-form-group v-else>
              <p class="text-info font-weight-bolder">
                Multiple Applications are enabled.
              </p>
            </b-form-group>
          </span>
          <b-form-group>
            <b-form-checkbox
              v-model="surveyApproval.final_approval_alert"
              size="md"
              >Notify participants by SMS after approval process is
              complete.</b-form-checkbox
            >
            <p
              style="font-size: 14px; font-weight: 600"
              :class="smsCount < 1000 ? 'text-danger' : 'text-dark'"
            >
              Balance SMS Count:
              <span>{{ smsCount }}</span>
            </p>
          </b-form-group>

          <b-form-group>
            <div class="submitBtn">
              <b-button
                variant="primary"
                :disabled="surveyBtn.disabled"
                size="md"
                type="submit"
                >{{ surveyBtn.text }}</b-button
              >
              <b-button
                variant="danger"
                :disabled="surveyBtn.disabled"
                size="md"
                class="ml-3"
                @click="cancel()"
                >Cancel</b-button
              >
            </div>
          </b-form-group>
        </b-overlay>
      </b-form>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import UsersListForSurvey from "./UsersListForSurvey.vue";
import { required, requiredIf } from "vuelidate/lib/validators";
import EditParticipantsDetails from "./EditParticipantsDetails.vue";
export default {
  props: ["surveyApprovalInfo"],
  async created() {
    this.fetchData = await true;
    this.smsCount = this.$store.getters.getSmsCount.smsBalance;
    this.$set(this.surveyApproval, "participants_only", true);
    this.$set(this.surveyApproval, "academic_year", null);
    this.$set(this.surveyApproval, "final_approval_alert", false);
    this.$set(this.surveyApproval, "multiple_application", false);
    await this.prepareAcadimicYears();
    if (this.surveyApprovalInfo.id) {
      this.surveyApproval = await this.surveyApprovalInfo;
      this.participantsOnly = await this.surveyApproval.participants_only;
    }

    await this.surveyApprovalInfo;
    this.fetchData = await false;
  },
  components: {
    DatePicker,
    UsersListForSurvey,
    EditParticipantsDetails,
  },
  validations: {
    selectedParticipants: {
      required: requiredIf(function() {
        if (!this.surveyApproval.id && this.surveyApproval.participantsOnly) {
          return true;
        }
      }),
    },
    surveyApproval: {
      name: {
        required,
      },
      start_at: {
        required,
      },
      end_at: {
        required,
      },
      academic_year: {
        required: requiredIf(function() {
          if (this.surveyApproval.academic_year == null) {
            return true;
          } else {
            return false;
          }
        }),
      },
    },
  },
  data() {
    return {
      fetchData: false,
      smsCount: null,
      loadApproval: false,
      surveyApproval: {},
      surveyBtn: {
        text: "Submit",
        disabled: false,
      },
      acadimicYears: [],
      selectedParticipants: [],
      participantsOnly: true,
      enrollParticipantsOptions: [
        { value: true, text: "Enrolled Participants" },
        { value: false, text: "Unenrolled Participants" },
      ],
    };
  },
  methods: {
    async prepareSurveyApprovalsForApi() {
      await this.$forceUpdate();
      await this.$v.$touch();
      if (this.$v.$invalid) {
        const tabHeight = document.getElementById(
          "staffListTab__BV_tab_container_"
        );
        this.$smoothScroll({
          container: tabHeight,
          scrollTo: document.querySelector(".form-group-error"),
          duration: 1000,
          offset: -120,
        });
        this.submitStatus = "ERROR";
      } else {
        this.surveyBtn.text = "Please wait...";
        this.surveyBtn.disabled = true;
        this.loadApproval = true;

        const startT = await moment(
          this.surveyApproval.start_at,
          "YYYY-MM-DD HH:mm:ss"
        )
          .utc()
          .format();
        const endT = await moment(
          this.surveyApproval.end_at,
          "YYYY-MM-DD HH:mm:ss"
        )
          .utc()
          .format();
        let surveyApprovalPostData = {
          name: this.surveyApproval.name,
          summary: this.surveyApproval.summary,
          academic_year: this.surveyApproval.academic_year,
          start_at: startT,
          end_at: endT,
          grouping_tag: this.surveyApproval.grouping_tag,
          comment_feedback: !this.surveyApproval.comment_feedback
            ? false
            : this.surveyApproval.comment_feedback,
          hidden: this.surveyApproval.hidden,
          participants_only: this.surveyApproval.participants_only,
          multiple_application: this.surveyApproval.multiple_application,
          is_anonymous: this.surveyApproval.is_anonymous,
          finish_message: this.surveyApproval.finish_message
            ? this.surveyApproval.finish_message
            : "",
          final_approval_alert: this.surveyApproval.final_approval_alert
            ? true
            : false,
        };

        const createSurveyApproval = !this.surveyApproval.id
          ? await this.createSurveyApproval(surveyApprovalPostData)
          : await this.updateSurveyApproval(surveyApprovalPostData);
        if (createSurveyApproval.status == true) {
          const msg = (await !this.surveyApproval.id)
            ? `${this.surveyApproval.name} created successfully`
            : `${this.surveyApproval.name} updated successfully`;
          this.$toast.success(`${msg}`, {
            position: "top",
            duration: 3000,
          });

          await surveyApprovalPostData.participantsOnly;
          if (
            !this.surveyApproval.id &&
            surveyApprovalPostData.participants_only == true
          ) {
            await this.$refs.selectParticipants.prepareSurveyApprovalParticipants(
              createSurveyApproval.id
            );
          } else {
            this.clearResponse(true);
          }
        }
        if (createSurveyApproval.status == false) {
          this.$toast.error(`Something went wrong, Please try again!`, {
            position: "top",
            duration: 3000,
          });
          this.clearResponse(false);
        }
      }
    },
    async createSurveyApproval(surveyApprovalPostData) {
      let approval = {
        status: null,
        id: "",
      };
      const url = this.$store.getters.getAPIKey.surveyApprovals;
      await this.$axios
        .post(url, surveyApprovalPostData)
        .then((response) => {
          approval.status = true;
          approval.id = response.data.id;
          response;
        })
        .catch((error) => {
          approval.status = false;
          approval.id = "";
          error;
        });
      return approval;
    },
    async updateSurveyApproval(surveyApprovalPostData) {
      let approval = {
        status: null,
        id: "",
      };
      const url =
        this.$store.getters.getAPIKey.surveyApprovals +
        `/${this.surveyApproval.id}`;
      await this.$axios
        .put(url, surveyApprovalPostData)
        .then((response) => {
          approval.status = true;
          response;
        })
        .catch((error) => {
          approval.status = false;
          error;
        });
      return approval;
    },
    clearResponse(status) {
      this.surveyBtn.text = "Submit";
      this.loadApproval = true;
      setTimeout(() => {
        if (status) {
          this.cancel();
        }
        this.surveyBtn.disabled = false;
      }, 3000);
    },

    cancel() {
      this.$emit("surveyApprovalCreated");
      this.surveyApprovals = {};
    },
    async editParticipantsDetails() {
      // let participantsComponent = await this.$refs.editParticipants;
      // await participantsComponent;
      this.$bvModal.show("editParticipants");
      // participantsComponent.getParticipants();
    },
    async prepareAcadimicYears() {
      this.acadimicYears = await [
        { value: null, text: "Select Year", disabled: false },
      ];
      const currentYear = (await moment().year()) - 2;
      for (let i = 1; i <= 3; i++) {
        this.acadimicYears.push({
          value:
            parseInt(currentYear + i) + "-" + parseInt(currentYear + i + 1),
          text: parseInt(currentYear + i) + "-" + parseInt(currentYear + i + 1),
        });
      }
    },
    updateAcademicYear(selected) {
      this.$forceUpdate();
      selected;
    },
  },
};
</script>
