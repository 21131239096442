<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="instituteProfileQuestions" v-else>
      <b-overlay
        :show="loadData"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="md"
      >
        <div
          class="attachQuestions mb-2 text-right"
          v-if="selectedQuestions.length != 0"
        >
          <b-button
            size="sm"
            variant="success"
            @click="prepareQuestionToBeAttached()"
            >Attach Questions</b-button
          >
        </div>
        <div class="table-responsive">
          <table class="table table-bordered table-striped">
            <thead class="thead-dark">
              <tr>
                <th></th>
                <th v-if="selectedQuestions.length != 0">Ques. No.</th>
                <th>Question</th>
                <th v-if="selectedQuestions.length != 0">Mandatory</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="institutionUserQuestions.length == 0">
                <td colspan="100" class="text-center text-danger">
                  No Questions Found!
                </td>
              </tr>

              <tr
                v-for="(question, questionIndex) in institutionUserQuestions"
                :key="questionIndex"
              >
                <td class="align-middle">
                  <b-form-checkbox
                    size="sm"
                    v-model="selectedQuestions"
                    :value="question.id"
                  >
                  </b-form-checkbox>
                </td>
                <td class="align-middle" v-if="selectedQuestions.length != 0">
                  <b-form-input
                    v-model="question.q_no"
                    style="width: 100px"
                    placeholder="Q No."
                  ></b-form-input>
                </td>
                <td class="align-middle">
                  <span
                    :id="'question' + question.id"
                    :class="
                      question.description != null ? 'text-info' : 'text-dark'
                    "
                    >{{ question.name }}</span
                  >

                  <b-popover
                    :target="'question' + question.id"
                    triggers="hover"
                    placement="top"
                    v-if="question.description != null"
                  >
                    {{ question.description }}
                  </b-popover>
                </td>
                <td
                  class="align-middle text-center"
                  v-if="selectedQuestions.length != 0"
                >
                  <b-form-checkbox
                    v-model="question.required"
                    size="sm"
                    switch
                  ></b-form-checkbox>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "instituteQuestionsStructure",
    "instituteAttachedQuestions",
    "switchRole",
  ],
  async created() {
    this.fetchData = true;
    await this.getInstituteQuestions();
    await this.prepareInstitutionProfileQuestionStructure();

    if (this.switchRole) {
      this.institutionUserQuestions = await this.institutionUserQuestions.filter(
        (ques) => {
          return ques.role == "student";
        }
      );
    } else {
      this.institutionUserQuestions = await this.institutionUserQuestions.filter(
        (ques) => {
          return ques.role == "teacher";
        }
      );
    }
    this.fetchData = false;
  },
  data() {
    return {
      fetchData: false,
      loadData: false,
      institutionUserQuestions: [],
      institutionProfileQuestions: [],
      questionsStructure: {},
      selectedQuestions: [],
    };
  },
  methods: {
    async getInstituteQuestions() {
      const url =
        this.$store.getters.getAPIKey.mainAPI + "/institutionuserquestions";
      await this.$axios
        .get(url)
        .then((response) => {
          this.institutionUserQuestions = [];
          this.institutionProfileQuestions = [];
          this.institutionProfileQuestions = response.data;
          response;
        })
        .catch((error) => {
          error;
        });
    },
    async prepareInstitutionProfileQuestionStructure() {
      let instituteUserQuestions = [];
      await this.institutionProfileQuestions.forEach((ques) => {
        instituteUserQuestions.push({
          id: ques.id,
          name: ques.name,
          description: ques.description,
          answers: ques.answers,
          role: ques.role,
          q_no: null,
          required: true,
        });
      });

      await instituteUserQuestions.forEach((ques) => {
        this.$set(this.questionsStructure, ques.id, ques);
      });

      if (this.instituteAttachedQuestions.length == 0) {
        this.institutionUserQuestions = instituteUserQuestions;
      } else {
        instituteUserQuestions.forEach((ques) => {
          if (!this.instituteQuestionsStructure[ques.id]) {
            this.institutionUserQuestions.push(ques);
          }
        });
      }
    },
    async prepareQuestionToBeAttached() {
      this.loadData = true;

      for (let questionId in this.selectedQuestions) {
        if (this.questionsStructure[this.selectedQuestions[questionId]]) {
          const quesPostData = {
            q_no: this.questionsStructure[this.selectedQuestions[questionId]]
              .q_no,
            institutionuser_question_id: this.questionsStructure[
              this.selectedQuestions[questionId]
            ].id,
            institution_id: this.$store.getters.defaultInstitute
              .defaultInstituteId,
            required: this.questionsStructure[
              this.selectedQuestions[questionId]
            ].required,
          };
          let attachQuestion = await this.attachQuestionToInstitute(
            quesPostData
          );
          if (attachQuestion) {
            if (questionId == this.selectedQuestions.length - 1) {
              this.$toast.success("Questions Attached", {
                position: "top",
                duration: 3000,
              });
              this.$emit("questionsAttached");
              this.loadData = false;
            }
          }
          if (!attachQuestion) {
            this.$toast.error("Something went wrong, Please try again!", {
              position: "top",
              duration: 3000,
            });
            this.loadData = false;
          }
        }
      }
    },

    async attachQuestionToInstitute(quesPostData) {
      let status = null;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        "/institution/institutionuserquestions/attach";
      await this.$axios
        .post(url, quesPostData)
        .then((response) => {
          status = true;
          response;
        })
        .catch((error) => {
          status = false;
          error;
        });
      return status;
    },
  },
};
</script>
