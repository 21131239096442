<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="usersubgroupUsers" v-else>
       <b-overlay
        :show="loadUsersubgroup"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="md"
      >
      <div class="reset mb-2 text-center" v-if="userSubgroupUsers.length != 0">
        <b-button variant="danger" size="sm" @click="reset()">Reset</b-button>
         <hr>
      </div>

      <div class="d-flex align-items-center">
        <div class="userSubgroups">
          <b-form-checkbox
            v-model="selectedUsersubgroups"
            v-for="({ text, value }, userSubgroupIndex) in usersubgroupsOptions"
            :key="userSubgroupIndex"
            @change="getUsersubgroup"
            :value="value"
            :disabled="userSubgroupsObj[value].disabled"
          >
            {{ value }}{{ text }}
          </b-form-checkbox>
        </div>
        <div class="users">
          <span
            class="bg-success"
            style="font-size: 12px"
            v-for="({ name }, userIndex) in userSubgroupUsers"
            :key="userIndex"
          >
            {{ name }}
            <span
              class="text-danger ml-1 btn p-0 m-0"
              @click="userSubgroupUsers.splice(userIndex, 1)"
            >
              <i class="fas fa-trash"></i>
            </span>
          </span>
        </div>
      </div>
       </b-overlay>
    </div>
  </div>
</template>

<script>
export default {
  async created() {
    this.fetchData = true;
    await this.getUsersubgroups();
    this.fetchData = false;
  },
  data() {
    return {
      fetchData: false,
      loadUsersubgroup: false,
      usersubgroupsOptions: [],
      userSubgroupsObj: {},
      selectedUsersubgroups: [],
      userSubgroupUsers: [],
    };
  },
  methods: {
    reset() {
      this.userSubgroupUsers = [];
      this.selectedUsersubgroups = [];
      for (let user in this.userSubgroupsObj) {
        this.userSubgroupsObj[user].disabled = false;
      }
    },
    async getUsersubgroups() {
      const url =
        this.$store.getters.getAPIKey.mainAPI + "/usersubgroups/withadmins";
      await this.$axios
        .get(url)
        .then((response) => {
          response.data.forEach(({ id, name, code }) => {
            this.$set(this.userSubgroupsObj, id, {
              disabled: false,
            });
            this.usersubgroupsOptions.push({
              value: id,
              text: name + " (" + code + ")",
            });
          });
          response;
        })
        .catch((error) => {
          error;
        });
    },
    async getUsersubgroup(value) {
      await this.getUserSubgroupUser(value[value.length - 1]);
    },
    async getUserSubgroupUser(userSubgroupId) {
      this.loadUsersubgroup = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usersubgroup/${userSubgroupId}/users`;
      await this.$axios.get(url).then((res) => {
        if (res.data.length != 0) {
          if (this.userSubgroupsObj[userSubgroupId]) {
            this.userSubgroupsObj[userSubgroupId].disabled = !this
              .userSubgroupsObj[userSubgroupId].disabled;
          }

          res.data.forEach(({ id, first_name, last_name }) => {
            this.userSubgroupUsers.push({
              id,
              name: first_name.concat(" ", last_name),
            });
          });
        } else {
          this.$toast.warning("No Users Found!", {
            position: "top",
            duration: 3000,
          });
        }
        res;
      });
      this.loadUsersubgroup = false;
    },
  },
};
</script>

<style >
.usersubgroupUsers .userSubgroups {
  width: 30%;
}
.usersubgroupUsers .users {
  display: flex;
  flex-wrap: wrap;
}
.usersubgroupUsers .users span {
  color: #ffffff;
  font-size: 12px;
  border-radius: 5px;
  padding: 3px;
  margin: 3px;
  display: flex;
  align-items: center;
}
.usersubgroupUsers .users span span {
  padding: 0px;
  margin: 0px;
  font-size: none;
  border-radius: none;
}
.usersubgroupUsers .users span span i {
  font-size: 15px !important;
}
</style>
