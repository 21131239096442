<template>
  <div class="container">
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <b-col v-if="!fetchData">
      <table class="table  table-striped table-bordered">
        <thead style=" position: sticky; top: 0;  background-color: gray; ">
          <tr>
            <th rowspan="2">No.</th>
            <th rowspan="2">Student</th>
            <th>Total Score</th>
          </tr>
          <tr><b-form-input style="
    border: 1px solid #52595f;" v-model="seminarMaxScore" required type="number" placeholder="Max Score">
            </b-form-input></tr>
        </thead>
        <tbody>
          <tr v-for="(student, studindex) in students" :key="studindex">
            <th class="fixed-table-column">{{ student.roll_number }}</th>
            <th class="fixed-table-column">
              {{ student.first_name }} {{ student.last_name }}
            </th>
            <td style="min-width: 60px;">
              <b-form-input v-model="
              evaluationTableData[student.institution_user_id]" :max="seminarMaxScore" type="number"
                placeholder="Enter score">
              </b-form-input>
            </td>
          </tr>
        </tbody>
      </table>

      <b-button @click="updateSeminarEvaluation()" :disabled="updateSeminarEvaluationButton != 'Save'"
        variant="primary">
        {{ updateSeminarEvaluationButton }}</b-button>
      <!-- <b-button @click="closeSeminarEvaluationModel()" variant="danger">Cancel</b-button> -->
    </b-col>

    <!-- <b-col class="m-2" v-if="courseLabs.length">
      <div class="mt-4" v-for="(courseLab, reindex) in courseLabs" :key="reindex">
        <b-card :header="
          courseLab.type == 'lab'
            ? 'Parameters for laboratory continuous evaluation'
            : courseLab.type == 'internal_exam'
              ? 'Parameters for laboratory Internal Exam'
              : 'Parameters for laboratory Viva Voce'
        " class="text-center">
          <div v-if="courseLab.type == 'lab'">
            <b-button class="m-2" v-if="enableLabAssessmentEvaluation && labExperiments.length > 0"
              @click="labEvaluationTable(courseLab)">
              Evaluation
            </b-button>
            <span class="text text-danger" v-else>Add experiments and configure Headers to enable evaluation</span>
          </div>
          <div v-if="courseLab.type == 'internal_exam'">
            <b-button class="m-2" v-if="
              enableLabInternalExamEvaluation && labExperiments.length > 0
            " @click="labEvaluationTable(courseLab)">
              Evaluation
            </b-button>
            <span class="text text-danger" v-else>Add experiments and configure Headers to enable evaluation</span>
          </div>
          <div v-if="courseLab.type == 'viva_voce'">
            <b-button class="m-2" v-if="enableLabVivaVoceEvaluation && labExperiments.length > 0"
              @click="labEvaluationTable(courseLab)">
              Evaluation
            </b-button>
            <span class="text text-danger" v-else>Add experiments and configure Headers to enable evaluation</span>
          </div>

          <b-button class="m-2" v-if="!editLabHeaders" @click="editLabHeaders = true">Edit Options</b-button>
          <b-button class="m-2" v-if="editLabHeaders" @click="updateLabEvaluationOptions(courseLab.type, courseLab.id)">
            Save
          </b-button>
          <span class="text-danger">
            Please contact Ezygo Support to add additional evaluation parameters as per the course requirement
          </span>
          <table class="table  table-striped table-bordered">
            <tr>
              <th>Enable</th>
              <th>No.</th>
              <th>
                Header
              </th>
              <th>
                Max. score
              </th>
            </tr>
            <tr v-for="(header, hrindex) in labEvaluationOptions[courseLab.type]" :key="hrindex + courseLab.type">
              <td>
                <b-form-checkbox :disabled="!editLabHeaders" v-model="header.enable"></b-form-checkbox>
              </td>
              <td>
                <span v-if="!editLabHeaders">{{ header.no }}</span>
                <b-form-input class="required" v-else v-model="header.no" type="text" placeholder="SI. No">
                </b-form-input>
              </td>
              <td>
                <span>{{ hrindex }}</span>
              </td>
              <td>
                <span v-if="!editLabHeaders">{{ header.score }}</span>
                <b-form-input class="required" v-else v-model="header.score" type="text" placeholder="Max. score">
                </b-form-input>
              </td>
            </tr>
          </table>
        </b-card>
      </div>
    </b-col> -->

    <!-- <b-col class="addLab m-2">
      <b-card class="text-center mt-2" v-if="courseLabs.length == 0 && courseLabsLoaded">
        <b-button @click="createLabForCourse">Add Lab details to the course</b-button>
      </b-card>
      <b-card class="text-center mt-2" v-if="courseLabs.length == 1 && courseLabsLoaded">
        <b-button @click="createLabForCourse">Add Lab Exam details to the course</b-button>
      </b-card>
      <b-card class="text-center mt-2" v-if="courseLabs.length == 2 && courseLabsLoaded">
        <b-button @click="createLabForCourse">Add Lab Viva Voce to the course</b-button>
      </b-card>
    </b-col> -->

    <!-- <b-card header="Experiments" class="text-center mt-2">
      <b-button @click="createLabExperiment()">Add experiment</b-button>
      <table class="table  table-striped table-bordered">
        <tr>
          <th>No.</th>
          <th>
            Name
          </th>
          <th>Description</th>
          <th>Procedure</th>
          <th></th>
        </tr>
        <tr v-for="(labExperiment, expindex) in labExperiments" :key="expindex">
          <td>{{ labExperiment.no }}</td>
          <td>{{ labExperiment.name }}</td>
          <td>{{ labExperiment.description }}</td>
          <td>{{ labExperiment.procedures }}</td>
          <td>
            <b-button @click="editLabExperiment(labExperiment)">Edit</b-button>
            <b-button class="test-danger" @click="deleteLabExperiment(labExperiment)">Delete</b-button>
          </td>
        </tr>
      </table>
    </b-card> -->

    <!-- <b-modal id="experimentModel" no-close-on-backdrop no-close-on-keyboard no-close-on-esc hide-header-close
      hide-header hide-footer centered size="md">
      <div>
        <b-col>
          <b-form-group id="input-group-1" label="No:" label-for="input-1">
            <b-form-input id="input-1" v-model="experiment.no" type="text" placeholder="Enter number">
            </b-form-input>
          </b-form-group>

          <b-form-group id="input-group-2" label="Experiment:" label-for="input-2">
            <b-form-input id="input-2" v-model="experiment.name" placeholder="Enter experiment"></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-2" label="Description:" label-for="input-2">
            <b-form-input id="input-2" v-model="experiment.description" placeholder="Enter Description">
            </b-form-input>
          </b-form-group>
          <b-form-group id="input-group-2" label="Procedure:" label-for="input-2">
            <b-form-input id="input-2" v-model="experiment.procedures" placeholder="Enter Procedure">
            </b-form-input>
          </b-form-group>

          <b-button @click="updateLabExperiment()" :disabled="updateExperimentButton != 'Save'" variant="primary">
            {{ updateExperimentButton }}</b-button>
          <b-button @click="$bvModal.hide('experimentModel')" variant="danger">Cancel</b-button>
        </b-col>
      </div>
    </b-modal> -->

    <!-- <b-modal modal-class="labEvaluation" id="labEvaluationModel" show no-close-on-backdrop no-close-on-keyboard
      no-close-on-esc hide-header-close hide-header hide-footer>
      <b-col>
        <table class="table  table-striped table-bordered">
          <thead style=" position: sticky; top: 0;  background-color: gray; ">
            <tr>
              <th rowspan="2">No.</th>
              <th rowspan="2">Student</th>
              <th v-for="(labExperiment, expindex) in labExperiments" :key="expindex"
                :colspan="enabledLabHeaders.length">
                <span v-b-tooltip.hover :title="
                  labExperiment.no +
                  '. ' +
                  labExperiment.name +
                  ': ' +
                  labExperiment.description
                ">{{ labExperiment.no }}.
                  {{ labExperiment.name.substring(0, 15) }}</span>
              </th>
            </tr>
            <tr>
              <th v-for="(header, headindex) in evaluationTableHeaderRow" :key="headindex">
                {{ header.name }}-{{ header.max_score }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(student, studindex) in students" :key="studindex">
              <th class="fixed-table-column">{{ student.roll_number }}</th>
              <th class="fixed-table-column">
                {{ student.first_name }} {{ student.last_name }}
              </th>
              <td v-for="(evaluationHeader,
              headindex) in evaluationTableHeaderRow" :key="headindex" style="min-width: 60px;">
                <b-form-input v-model="
                  evaluationTableData[student.institution_user_id][
                  evaluationHeader.experiment_id
                  ][evaluationHeader.id]
                " type="number" :max="evaluationHeader.max_score" placeholder="Enter score">
                </b-form-input>
              </td>
            </tr>
          </tbody>
        </table>

        <b-button @click="updateLabEvaluation()" :disabled="updateLabEvaluationButton != 'Save'" variant="primary">
          {{ updateLabEvaluationButton }}</b-button>
        <b-button @click="closelabEvaluationModel()" variant="danger">Cancel</b-button>
      </b-col>
    </b-modal> -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["course_id"],
  async created() {
    // await this.getCourseLabs();
    // if (this.courseLabs.length == 0) {
    //   return;
    // }
    await this.fetchCourseStudents();
    await this.students.forEach(stud => {
      this.evaluationTableData[stud.institution_user_id] = null;
    });
    this.fetchData = false;
    await this.getSeminarEvaluations();
    // await this.getLabExperiments();
  },
  // components: {
  //   createModule: createModule,
  // },
  data() {
    return {
      fetchData: false,
      // courseLabs: [],
      // courseLabsLoaded: false,
      // labExperiments: [],
      // experiment: {
      //   id: "",
      //   no: "",
      //   name: "",
      //   description: "",
      //   procedures: "",
      //   course_id: this.course_id,
      // },
      // labEvaluationOptions: {
      //   lab: {
      //     Practical: { id: "", enable: false, score: "", no: "" },
      //     Record: { id: "", enable: false, score: "", no: "" },
      //     Viva: { id: "", enable: false, score: "", no: "" },
      //     Algorithm: { id: "", enable: false, score: "", no: "" },
      //     Program: { id: "", enable: false, score: "", no: "" },
      //     Output: { id: "", enable: false, score: "", no: "" },
      //   },
      //   internal_exam: {
      //     Practical: { id: "", enable: false, score: "", no: "" },
      //     Record: { id: "", enable: false, score: "", no: "" },
      //     Algorithm: { id: "", enable: false, score: "", no: "" },
      //     Program: { id: "", enable: false, score: "", no: "" },
      //     Output: { id: "", enable: false, score: "", no: "" },
      //     Viva: { id: "", enable: false, score: "", no: "" },
      //   },
      //   viva_voce: { Viva: { id: "", enable: false, score: "", no: "" } },
      // },
      // editLabHeaders: false,
      // updateExperimentButton: "save",
      // enableLabAssessmentEvaluation: false,
      // enableLabInternalExamEvaluation: false,
      // enableLabVivaVoceEvaluation: false,
      updateSeminarEvaluationButton: "Save",
      students: [],
      seminarMaxScore: null,
      // evaluationLabActivity: null,
      // enabledLabHeaders: [],
      // evaluationTableHeaderRow: [],
      evaluationTableData: {},
    };
  },
  methods: {
    // async getCourseLabs() {
    //   this.fetchData = true;
    //   const url =
    //     this.$store.getters.getAPIKey.mainAPI +
    //     `/labactivities?course=${this.course_id}`;
    //   await axios
    //     .get(url)
    //     .then((response) => {
    //       this.courseLabs = response.data;
    //       this.fetchData = false;
    //     })
    //     .catch(() => {
    //       this.fetchData = false;
    //     });
    //   this.courseLabsLoaded = true;
    // },
    // async createLabForCourse() {
    //   this.fetchData = true;
    //   const url = this.$store.getters.getAPIKey.mainAPI + `/labactivities`;
    //   await axios
    //     .post(url, {
    //       course_id: this.course_id,
    //     })
    //     .then(() => {
    //       this.getCourseLabs();
    //       // this.fetchData = false;
    //     })
    //     .catch(() => {
    //       this.$toast.error("Failed to initiate lab, Please try again", {
    //         position: "top",
    //         duration: 3000,
    //       });
    //       this.fetchData = false;
    //     });
    // },
    // async getLabEvaluationOptions() {
    //   const url =
    //     this.$store.getters.getAPIKey.mainAPI +
    //     `/labevaluationheaders?course=${this.course_id}`;
    //   await axios.get(url).then((response) => {
    //     let labevaluationheaders = response.data;
    //     this.courseLabs.forEach((lab) => {
    //       labevaluationheaders.forEach((header) => {
    //         if (header.lab_activity_id == lab.id) {
    //           if (lab.type == "lab") {
    //             this.enableLabAssessmentEvaluation = true;
    //           } else if (lab.type == "internal_exam") {
    //             // internal_exam
    //             this.enableLabInternalExamEvaluation = true;
    //           } else {
    //             // viva_voce
    //             this.enableLabVivaVoceEvaluation = true;
    //           }
    //           this.labEvaluationOptions[lab.type][header.name].score =
    //             header.maximum_mark;
    //           this.labEvaluationOptions[lab.type][header.name].id = header.id;
    //           this.labEvaluationOptions[lab.type][header.name].no = header.no;
    //           this.labEvaluationOptions[lab.type][header.name].enable = true;
    //         }
    //       });
    //     });
    //   });
    // },
    // async updateLabEvaluationOptions(courseLabType, labId) {
    //   if (courseLabType == "lab") {
    //     this.enableLabAssessmentEvaluation = false;
    //   } else {
    //     // internal_exam
    //     this.enableLabInternalExamEvaluation = false;
    //   }
    //   const url =
    //     this.$store.getters.getAPIKey.mainAPI + `/labevaluationheaders`;
    //   var postData = [];

    //   for (var headerKey in this.labEvaluationOptions[courseLabType]) {
    //     // console.log(this.labEvaluationOptions[courseLabType][headerKey]);
    //     if (this.labEvaluationOptions[courseLabType][headerKey].enable) {
    //       if (courseLabType == "lab") {
    //         this.enableLabAssessmentEvaluation = true;
    //       } else if (courseLabType == "internal_exam") {
    //         // internal_exam
    //         this.enableLabInternalExamEvaluation = true;
    //       } else {
    //         // viva_voce
    //         this.enableLabVivaVoceEvaluation = true;
    //       }
    //       if (
    //         this.labEvaluationOptions[courseLabType][headerKey].score == "" ||
    //         this.labEvaluationOptions[courseLabType][headerKey].no == ""
    //       ) {
    //         this.$toast.error("Please fill all enabled fields", {
    //           position: "top",
    //           duration: 3000,
    //         });
    //       }

    //       postData.push({
    //         maximum_mark: this.labEvaluationOptions[courseLabType][headerKey]
    //           .score,
    //         lab_activity_id: labId,
    //         name: headerKey,
    //         no: this.labEvaluationOptions[courseLabType][headerKey].no,
    //       });
    //     }
    //   }
    //   await axios
    //     .post(url, { labHeaders: postData, lab_activity_id: labId })
    //     .then((response) => {
    //       response;
    //       // let labevaluationheaders = response.data;
    //       // this.courseLabs.forEach(lab => {
    //       //   labevaluationheaders.forEach(header => {
    //       //     if (header.lab_activity_id == lab.id) {
    //       //       this.labEvaluationOptions[lab.type][header.name].score = header.max_score;
    //       //       this.labEvaluationOptions[lab.type][header.name].enable = true;
    //       //     }
    //       //   });
    //       // });
    //     })
    //     .catch(() => {
    //       this.$toast.error(
    //         "Failed to update/ you cannot delete if Score is already added",
    //         {
    //           position: "top",
    //           duration: 5000,
    //         }
    //       );
    //       this.fetchData = false;
    //     });
    //   await this.getLabEvaluationOptions();
    //   this.editLabHeaders = false;
    // },
    // async getLabExperiments() {
    //   const url =
    //     this.$store.getters.getAPIKey.mainAPI +
    //     `/labexperiments?course=${this.course_id}`;
    //   await axios.get(url).then((response) => {
    //     this.labExperiments = response.data;
    //   });
    // },
    // createLabExperiment() {
    //   this.experiment = {
    //     id: "",
    //     no: "",
    //     name: "",
    //     description: "",
    //     procedures: "",
    //     course_id: this.course_id,
    //   };
    //   this.updateExperimentButton = "Save";
    //   this.$bvModal.show("experimentModel");
    // },
    // editLabExperiment(experiment) {
    //   this.updateExperimentButton = "Save";
    //   this.experiment = experiment;
    //   this.$bvModal.show("experimentModel");
    // },
    // async updateLabExperiment() {
    //   this.updateExperimentButton = "Saving...";
    //   if (this.experiment.id == "") {
    //     // create mode
    //     const url = this.$store.getters.getAPIKey.mainAPI + `/labexperiments`;
    //     await axios
    //       .post(url, this.experiment)
    //       .then(() => {
    //         this.getLabExperiments();
    //         // this.fetchData = false;
    //       })
    //       .catch(() => {
    //         this.$toast.error("Failed to create", {
    //           position: "top",
    //           duration: 3000,
    //         });
    //         this.fetchData = false;
    //       });
    //   } else {
    //     const url =
    //       this.$store.getters.getAPIKey.mainAPI +
    //       `/labexperiments/${this.experiment.id}`;
    //     await axios
    //       .put(url, this.experiment)
    //       .then(() => {
    //         this.getLabExperiments();
    //         // this.fetchData = false;
    //       })
    //       .catch(() => {
    //         this.$toast.error("Failed to update", {
    //           position: "top",
    //           duration: 3000,
    //         });
    //         this.fetchData = false;
    //       });
    //   }

    //   this.$bvModal.hide("experimentModel");
    // },
    // async deleteLabExperiment(experiment) {
    //   const url =
    //     this.$store.getters.getAPIKey.mainAPI +
    //     `/labexperiments/${experiment.id}`;
    //   await axios
    //     .delete(url)
    //     .then(() => {
    //       this.$toast.warning("Experiment Deleted", {
    //         position: "top",
    //         duration: 3000,
    //       });
    //       this.getLabExperiments();
    //       // this.fetchData = false;
    //     })
    //     .catch(() => {
    //       this.$toast.error(
    //         "Failed to delete/ you cannot delete if Score is already added",
    //         {
    //           position: "top",
    //           duration: 3000,
    //         }
    //       );
    //       this.fetchData = false;
    //     });
    // },


    // closeSeminarEvaluationModel() {
    //   this.$bvModal.hide("seminarEvaluationModel");
    // },
    // async labEvaluationTable(labActivity) {
    //   this.evaluationLabActivity = labActivity;
    //   this.evaluationTableHeaderRow = [];
    //   this.enabledLabHeaders = [];
    //   // for evaluation we need student list, experiment list, header list
    //   if (this.students.length == 0) await this.fetchCourseStudents();
    //   for (var labHeaderKey in this.labEvaluationOptions[labActivity.type]) {
    //     if (this.labEvaluationOptions[labActivity.type][labHeaderKey].enable) {
    //       this.enabledLabHeaders.push(
    //         this.labEvaluationOptions[labActivity.type][labHeaderKey]
    //       );
    //     }
    //   }
    //   this.labExperiments.forEach((exp) => {
    //     for (var labHeaderKey in this.labEvaluationOptions[labActivity.type]) {
    //       if (
    //         this.labEvaluationOptions[labActivity.type][labHeaderKey].enable
    //       ) {
    //         this.evaluationTableHeaderRow.push({
    //           id: this.labEvaluationOptions[labActivity.type][labHeaderKey].id,
    //           name: labHeaderKey,
    //           experiment_id: exp.id,
    //           max_score: this.labEvaluationOptions[labActivity.type][
    //             labHeaderKey
    //           ].score,
    //         });
    //       }
    //     }
    //   });

    //   this.students.forEach((student) => {
    //     this.evaluationTableData[student.institution_user_id] = {};
    //     this.evaluationTableHeaderRow.forEach((evaluationHeader) => {
    //       if (
    //         !(
    //           evaluationHeader.experiment_id in
    //           this.evaluationTableData[student.institution_user_id]
    //         )
    //       )
    //         this.evaluationTableData[student.institution_user_id][
    //           evaluationHeader.experiment_id
    //         ] = {};
    //       this.evaluationTableData[student.institution_user_id][
    //         evaluationHeader.experiment_id
    //       ][evaluationHeader.id] = null;
    //     });
    //   });
    //   // this.$forceUpdate();
    //   this.$bvModal.show("labEvaluationModel");
    //   const url =
    //     this.$store.getters.getAPIKey.mainAPI +
    //     `/labevaluations?labActivity=${labActivity.id}`;
    //   await axios.get(url).then((response) => {
    //     response;
    //     response.data.forEach((evaluation) => {
    //       // console.log(evaluation);
    //       if (evaluation.student_id in this.evaluationTableData)
    //         this.evaluationTableData[evaluation.student_id][
    //           evaluation.experiment_id
    //         ][evaluation.header_id] = parseFloat(evaluation.score);
    //     });
    //     // this.labExperiments = response.data;
    //   });
    //   this.$forceUpdate();
    // },

    async getSeminarEvaluations() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/seminarevaluations?course=${this.course_id}`;
      await axios.get(url).then((response) => {
        this.seminarMaxScore=response.data.max_score;
        response.data.seminar_evaluations.forEach((evaluation) => {
          if (evaluation.student_id in this.evaluationTableData)
            this.evaluationTableData[evaluation.student_id] = parseFloat(evaluation.score);
        });
      });
    },
    async updateSeminarEvaluation() {
      this.updateSeminarEvaluationButton = "Saving...";
      var postData = [];
      for (const studkey in this.evaluationTableData) {
        var score = this.evaluationTableData[studkey];
        if (score !== null) {
          postData.push({
            score: score,
            student_id: studkey
          });
        }
      }
      const url = this.$store.getters.getAPIKey.mainAPI + `/seminarevaluations`;
      await axios
        .post(url, { seminarEvaluations: postData, course: this.course_id, max_score: this.seminarMaxScore })
        .then(() => {
          // this.getLabExperiments();
          // this.fetchData = false;
          this.$toast.success("Updated successfully", {
            position: "top",
            duration: 3000,
          });
        })
        .catch(() => {
          this.$toast.error("Failed to create", {
            position: "top",
            duration: 3000,
          });
          this.fetchData = false;
        });

      this.updateSeminarEvaluationButton = "Saving";
    },
    async fetchCourseStudents() {
      let mainUrl = this.$store.getters.getAPIKey.createStudent;
      const url = mainUrl.replace("course_id", this.course_id);
      await this.$axios.get(url).then((response) => {
        this.students = [];
        this.students = response.data[0];
      });
    },
  },
};
</script>

<style >
.tag-button button {
  font-size: 9px !important;
  padding: 3px;
}

.labEvaluation .modal-dialog {
  max-width: 100%;
  max-height: 100vh;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  position: fixed;
  z-index: 100000;
}

.labEvaluation .modal-body {
  overflow: scroll;
}

.labEvaluation .fixed-table-column {
  position: sticky;
  left: 0;
  background-color: gray;
}

</style>
