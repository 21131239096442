<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div v-else>
      <!-- application ready for approve listing -->
      <div class="approvalListing p-3">
        <div v-if="applications.length" class="table-responsive">
          <h3 style="text-align:center;color:#b51000;">
            Data in this table is gradually generated depending on the
            interaction, please check the 'event specific applications' list to
            get all applications
          </h3>
          <table class="table table-bordered table-striped">
            <thead class="thead-dark">
              <tr>
                <th>
                  <b-form-checkbox
                    v-model="allAppSelected"
                    :indeterminate="indeterminateAppSelection"
                    aria-describedby="approvalapps"
                    aria-controls="approvalapps"
                    @change="toggleAll"
                  >
                    {{ allAppSelected ? "Un-select All" : "Select All" }}
                  </b-form-checkbox>
                </th>
                <th>Name</th>
                <th>Event</th>
                <th>Submitted</th>
                <th>View application and approve</th>
                <th>
                  <span v-if="appApproveProgress" style="color:#b51000;"
                    >Approving... please wait</span
                  >
                  <span v-else>
                    <span v-if="selectedApplicationsIndexs.length == 0">
                      Approve
                    </span>
                    <span v-else>
                      <b-button
                        variant="success"
                        size="lg"
                        @click="directSelectedAppApprove()"
                        >Approve selected apps</b-button
                      >
                    </span>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(application, appIndex) in applications"
                :key="appIndex"
              >
                <td>
                  <b-form-checkbox
                    size="lg"
                    v-model="selectedApplicationsIndexs"
                    :value="appIndex"
                    name="approvalapps"
                  >
                  </b-form-checkbox>
                </td>
                <td class="align-middle text-center">
                  {{ application.name }}
                </td>
                <td class="align-middle text-center">
                  {{ application.onlinereg }}
                </td>
                <td class="align-middle">
                  <!-- {{ application.submitted.format("DD/MM/YYYY") }} -->
                  {{ application.submitted }}
                </td>
                <td>
                  <span
                    v-for="(approverId, approverIndex) in application.approvers"
                    :key="approverIndex"
                  >
                    <b-button
                      variant="outline-primary"
                      size="sm"
                      @click="
                        openAppForApprove(
                          application.appid,
                          approverId,
                          appIndex
                        )
                      "
                      >{{ approvers[approverId].role }}({{
                        approvers[approverId].order
                      }})</b-button
                    >
                  </span>
                </td>

                <td>
                  <span
                    v-for="(approverId, approverIndex) in application.approvers"
                    :key="approverIndex"
                  >
                    <b-button
                      variant="outline-success"
                      size="sm"
                      @click="
                        directAppApprove(
                          application.appid,
                          approverId,
                          application.onlineregistration_id,
                          application.participant_id,
                          appIndex
                        )
                      "
                      >Approve as- {{ approvers[approverId].role }}({{
                        approvers[approverId].order
                      }})</b-button
                    >
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- view app for approve model -->
      <b-modal
        id="openAppForApproveModel"
        no-close-on-backdrop
        no-close-on-keyboard
        no-close-on-esc
        hide-footer
        hide-header
        centered
      >
        <div>
          <AppViewForApproveComponant
            :appId="selectAppForApprove.appId"
            :approverId="selectAppForApprove.approverId"
            v-if="selectAppForApprove.appId"
            @closeAppModelEvent="closeAppForApproveModel"
          />
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import AppViewForApproveComponant from "./applicationViewForApprove.vue";
export default {
  async created() {
    this.fetchData = true;
    await this.getUserApproversAppsForApprove();
    this.fetchData = false;
  },
  components: {
    AppViewForApproveComponant,
  },
  data() {
    return {
      fetchData: false,
      approvers: {},
      applications: [],
      selectAppForApprove: {
        appId: null,
        approverId: null,
        appindex: null,
      },
      selectedApplicationsIndexs: [],
      appApproveProgress: false,
      allAppSelected: false,
      indeterminateAppSelection: false,
    };
  },
  watch: {
    selectedApplicationsIndexs(newValue, oldValue) {
      oldValue;
      if (newValue.length === 0) {
        this.indeterminateAppSelection = false;
        this.allAppSelected = false;
      } else if (newValue.length === this.applications.length) {
        this.indeterminateAppSelection = false;
        this.allAppSelected = true;
      } else {
        this.indeterminateAppSelection = true;
        this.allAppSelected = false;
      }
    },
  },
  methods: {
    toggleAll(checked) {
      this.selectedApplicationsIndexs = [];
      if (checked) {
        for (let index = 0; index < this.applications.length; index++) {
          this.selectedApplicationsIndexs.push(index);
        }
      }
    },
    async getUserApproversAppsForApprove() {
      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/institutionuser/onlineregapprovers/applicationsforapprove`;
      let responseData = null;
      await this.$axios
        .get(url)
        .then((response) => {
          responseData = response.data;
          this.approvers = responseData.approvers;
          this.applications = responseData.readyApps;
          this.fetchData = false;
        })
        .catch(() => {
          this.fetchData = false;
        });
      // if (responseData) {
      //   this.approvers = responseData.approvers;
      //   this.applications = responseData.readyApps;
      //   // await
      //   // start_at: moment(
      //   //         moment.utc(approval.start_at, "YYYY-MM-DD HH:mm:ss").toDate()
      //   //       ),
      // }
    },
    async openAppForApprove(appId, approverId, appIndex) {
      this.selectAppForApprove.appId = appId;
      this.selectAppForApprove.approverId = approverId;
      this.selectAppForApprove.appindex = appIndex;
      // let selectApp = this.applications[appIndex];
      // remove app from index
      this.applications.splice(appIndex, 1);

      await this.$bvModal.show("openAppForApproveModel");
    },
    async closeAppForApproveModel() {
      this.selectAppForApprove.appId = null;
      this.selectAppForApprove.approverId = null;
      this.selectAppForApprove.appindex = null;

      await this.$bvModal.hide("openAppForApproveModel");
    },
    async directAppApprove(
      appid,
      approverId,
      onlineregistration_id,
      participant_id,
      appIndex
    ) {
      this.appApproveProgress = true;
      let postData = await {
        approver_id: approverId,
        comments: "",
        approval_status: "approved",
        participant_id: participant_id,
        onlineregistration_id: onlineregistration_id,
        onlineregapplication_id: appid,
      };

      const url = this.$store.getters.getAPIKey.mainAPI + `/onlineregapprovals`;
      await this.$axios
        .post(url, postData)
        .then(() => {
          this.$toast.success(`Application approved`, {
            position: "top",
            duration: 3000,
          });
          if (appIndex !== null) this.applications.splice(appIndex, 1);
          this.appApproveProgress = false;
        })
        .catch(() => {
          this.$toast.error("Something Went Wrong, please try again!", {
            position: "top",
            duration: 3000,
          });
          this.appApproveProgress = false;
        });

      // this.$emit("closeAppModelEvent");
    },

    async directSelectedAppApprove() {
      var selectedApps = [];
      let reminingApps = [];

      for (let index = 0; index < this.applications.length; index++) {
        const app = this.applications[index];
        if (this.selectedApplicationsIndexs.includes(index)) {
          app.approvers.forEach((approverId) => {
            selectedApps.push({
              appid: app.appid,
              approverId: approverId,
              onlineregistration_id: app.onlineregistration_id,
              participant_id: app.participant_id,
            });
          });
        } else {
          reminingApps.push(app);
        }
      }

      await Promise.all(
        selectedApps.map(async (selectedApp) => {
          await this.directAppApprove(
            selectedApp.appid,
            selectedApp.approverId,
            selectedApp.onlineregistration_id,
            selectedApp.participant_id,
            null
          );
        })
      );
      this.selectedApplicationsIndexs = [];
      this.applications = reminingApps;

      console.log(reminingApps);
      console.log(selectedApps);
    },
  },
};
</script>

<style >
.approvalListing .approverStatus i {
  font-size: 10px !important;
}
</style>
