<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div id="userEnrollment" v-else>
      <b-form @submit.prevent="enrollUser(groupInfo.id)">
        <b-row>
          <b-col :md="copyUsers ? '12' : '6'">
            <h6>
              {{
                  students.length == 0
                    ? "Enroll faculty to " + groupInfo.name
                    : "Enroll students to " + groupInfo.name
              }}
            </h6>
            <h6 class="alert alert-success text-center" v-if="enrollSuccess != ''">
              {{ enrollSuccess }}
            </h6>

            <div class="copyUsersToSUbgroup" v-if="groupInfo.type == 'Subgroup'">
              <b-form-group>
                <b-form-checkbox v-model="copyUsers">Copy Users From Other
                  {{
                      getCustomNames.userSubgroup.custom_name != ""
                        ? getCustomNames.userSubgroup.custom_name
                        : "Subgroup"
                  }}</b-form-checkbox>
              </b-form-group>

              <CopyUsersFromSubgroup :groupInfo="groupInfo" :getCustomNames="getCustomNames" v-if="copyUsers"
                @copiedSuccessfully="copyUsers = false" />
            </div>
            <b-form-group class="w-100" v-if="!copyUsers">
              <div>
                <multiselect class="w-50" v-model="selectedAdmins" tag-placeholder="Add this as new tag" :placeholder="
                  students.length == 0 ? 'Add users' : 'Add Students'
                " label="name" track-by="code" :options="options" :multiple="true" :taggable="true" @tag="addTag"
                  :class="{ 'is-invalid': responseErrors.institution_users }"></multiselect>
                <span class="text-danger" v-if="responseErrors.institution_users">
                  {{ responseErrors.institution_users[0] }}</span>
              </div>
            </b-form-group>
          </b-col>
          <b-col md="6" v-if="faculty.length == 0 && !copyUsers">
            <div v-if="selectedAdmins.length !== 0">
              <b-list-group v-for="(student, index) in selectedAdmins" :key="index">
                <b-list-group-item>
                  <div class="rollNo d-flex align-items-baseline justify-content-between">
                    <h6 class="studentName">{{ student.name }}:</h6>
                    <b-input placeholder="Roll No." v-model="student.rollNumber"></b-input>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </div>
            <p class="text-danger" v-else>No Users Selected yet!</p>
          </b-col>

          <b-col md="12" v-if="!copyUsers">
            <b-form-group class="float-right">
              <b-button variant="primary" type="submit" :disabled="enrollButton.disabled">{{ enrollButton.text
              }}</b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>

      <br />
    </div>
  </div>
</template>

<script>
import CopyUsersFromSubgroup from "./CopyUsersFromSubgroup.vue";
import axios from "axios";
export default {
  props: ["groupInfo", "getCustomNames"],
  async created() {
    this.fetchData = true;
    this.students = await [];
    this.faculty = await [];
    this.options = await [];
    if (this.groupInfo.type == "Subgroup") {
      await this.studentDetails();
    } else {
      this.facultyDetails();
    }
    this.fetchData = false;
  },
  components: {
    CopyUsersFromSubgroup,
  },
  data() {
    return {
      fetchData: false,
      students: [],
      selectedAdmins: [],
      options: [],
      faculty: [],
      enrollSuccess: "",
      deleteSuccess: "",
      deleteUserBtn: {
        text: "Delete",
        disabled: false,
      },
      responseErrors: {},
      enrollButton: {
        text: "Submit",
        disabled: false,
      },
      listOfUsersEnrolled: [],
      studentData: {},
      copyUsers: false,
    };
  },
  methods: {
    enrollUser(groupId) {
      if (this.selectedAdmins.length == 0) {
        setTimeout(() => {
          this.responseErrors = {};
        }, 3000);
        return (this.responseErrors.institution_users = [
          "Input cannot be empty!",
        ]);
      }
      this.enrollButton.text = "Enrolling...";
      this.enrollButton.disabled = true;
      if (this.students.length == 0) {
        let groupUrl = this.$store.getters.getAPIKey.enrollGroupUser;
        const url = groupUrl.replace("usergroup_id", groupId);
        this.enrollUsers(url);
      } else {
        let groupUrl = this.$store.getters.getAPIKey.enrollSubGroupUser;
        const url = groupUrl.replace("usersubgroup_id", groupId);
        this.enrollUsers(url);
      }
    },
    enrollUsers(url) {
      if (this.groupInfo.type == "Group") {
        this.studentData = {
          institution_users: [],
        };
        this.selectedAdmins.forEach((users) => {
          this.studentData.institution_users.push(users.id);
        });
      } else {
        this.studentData = {
          institution_users: [],
        };
        this.selectedAdmins.forEach((users) => {
          this.studentData.institution_users.push(users.id);
          if (users.rollNumber && users.rollNumber != "") {
            if (!this.studentData.institution_users_roll_number) {
              this.studentData.institution_users_roll_number = [];
              this.studentData.institution_users_roll_number.push({
                institution_user_id: users.id,
                roll_number: users.rollNumber,
              });
            } else {
              this.studentData.institution_users_roll_number.push({
                institution_user_id: users.id,
                roll_number: users.rollNumber,
              });
            }
          }
        });
      }

      axios
        .post(url, this.studentData)
        .then((response) => {
          this.enrollButton.text = "Submit";
          this.enrollButton.disabled = false;
          this.enrollSuccess = "Successfully enrolled";
          this.selectedAdmins = [];
          setTimeout(() => {
            this.enrollSuccess = "";
            this.cancel();
          }, 3000);
          response;
        })
        .catch((error) => {
          if (error.response) {
            this.responseErrors = error.response.data.errors;
            this.clearResponse();
            error;
          }
        });
    },

    async getEnrolledGroupUsers() {
      let userList = this.$store.getters.getAPIKey.getEnrollUsersList;
      const url = userList.replace("usergroup_id", this.groupInfo.id);

      await axios
        .get(url)
        .then((response) => {
          this.listOfUsersEnrolled = [];
          this.listOfUsersEnrolled = response.data;

          response;
        })
        .catch((error) => {
          error;
        });
    },

    async studentDetails() {
      const url = this.$store.getters.getAPIKey.instituitionStudents;
      await axios
        .get(url)
        .then((response) => {
          this.students = response.data;
          this.students.forEach((staff) => {
            if (staff.enroll_status == 'active') this.options.push({
              name: staff.first_name + " " + (staff.last_name || "") + `(${staff.email} - ${staff.admission_no ?? ''})`,
              id: staff.id,
              code: staff.id,
            });
          });
          this.options.sort((a, b) => a.name.localeCompare(b.name));

          response;
        })
        .catch((error) => {
          error;
        });
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
        id: newTag,
        rollNo: "",
      };
      this.options.push(tag);
      this.selectedAdmins.push(tag);
    },
    async facultyDetails() {
      const url = this.$store.getters.getAPIKey.instituitionFaculty;
      await axios
        .get(url)
        .then((response) => {
          this.faculty = response.data;
          this.faculty.forEach((staff) => {
            if (staff.enroll_status == 'active') this.options.push({
              name: staff.first_name + " " + (staff.last_name || "") + `( ${staff.email})`,
              id: staff.id,
              code: staff.id,
            });
          });
          this.options.sort((a, b) => a.name.localeCompare(b.name));

          response;
        })
        .catch((error) => {
          error;
        });
    },
    clearResponse() {
      this.enrollButton.text = "Submit";
      this.enrollButton.disabled = false;

      setTimeout(() => {
        this.responseErrors = {};
      }, 3000);
    },
    cancel() {
      this.selectedAdmins = [];
      this.userEnrollment = false;
      this.students = [];
      this.faculty = [];
      this.options = [];
      this.listOfUsersEnrolled = [];
    },
  },
};
</script>

<style >
.rollNo input {
  width: 200px;
}
</style>
