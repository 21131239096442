<template>
  <div>
    <br />
    <div class="reportSection">
      <b-container>
        <b-row>
          <b-col md="12">
            <div class="loading" v-if="fetchData">
              <load-data></load-data>
            </div>
            <div class="reportsTabSection" v-else>
              <div class="reportsOptions">
                <b-tabs content-class="mt-3" v-model="tabIndex" lazy>
                  <b-tab
                    :title="report.name"
                    v-for="(report, reportIndex) in reportComponents"
                    :key="reportIndex"
                  >
                    <component v-bind:is="report.component"></component>
                  </b-tab>
                </b-tabs>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import acl from "../../../../authorization/acl.js";
import AssessmentReports from "./assessment/AssessmentReports.vue";
import AttendenceReports from "./attendance/AttendenceReports.vue";
import SurveyReport from "./survey/SurveyReport.vue";
import LiveClass from "./live_class/LiveClass.vue";
// import CourseForm from "./course_plan/CourseForm.vue";
export default {
  props: ["assessmentReports"],
  async created() {
    this.fetchData = await true;
    const surveyReport = await acl.isUserGranted("surveyReport");
    this.surveyReport = await surveyReport;
    this.fetchData = await false;
  },
  data() {
    return {
      tabIndex: 0,
      fetchData: false,
      reportTabIndex: 0,
      reportOption: 0,
      viewType: "LiveClass",
      institutionTimetable: null,
      instructorSchedule: null,
      surveyReport: null,
      currentComponent: "LiveClass",
      activeIndex: 0,
      reportComponents: [
        { name: "Live Class", component: "LiveClass" },
        // { name: "Attendance Reports", component: "AttendenceReports" },
        { name: "Assessment Reports", component: "AssessmentReports" },

        { name: "Survey Reports", component: "SurveyReport" },
        // { name: "Course Coverage Report", component: "CourseForm" },
      ],
    };
  },
  methods: {
    getReport(reportType, index) {
      this.activeIndex = index;
      this.currentComponent = reportType;
    },
  },
  components: {
    LiveClass,
    AssessmentReports,
    AttendenceReports,
    SurveyReport,
    // CourseForm,
  },
};
</script>

<style >
.reportSection .breadcrumb .active span {
  color: #65a765 !important;
  border-bottom: 1px solid black;
  font-size: 14px !important;
}

.reportSection .breadcrumb .breadcrumb-item::before {
  content: none;
}

.reportsTabSection {
  border: 2px solid #17a2b8;
  padding: 20px;
  border-radius: 5px;
}
#reportSection__BV_tab_container_ {
  height: 600px;
  overflow: scroll;
}
.reportsTabSection .nav-tabs .nav-link {
  background-color: #077bff !important;
  color: #ffffff !important;
  border: 1px solid white;
  font-size: 14px;
}
.reportsTabSection .nav-tabs .nav-link.active {
  background-color: #ffffff !important;
  color: #077bff !important;
}

.reportsTabSection ul {
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 5px;
}
.reportsTabSection ul li {
  border: 3px solid #f8f9fa !important;
}

.reportsTabSection .tab-content {
  border: none !important;
}
</style>
