<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="attainmentCO" v-else>
      <b-row>
        <b-col md="12">
          <div class="viewOrConfig mb-3">
            <b-button size="sm" :variant="config ? 'success' : 'outline-primary'" class="font-weight-bolder"
              disabled>Choose Survey</b-button>
            <b-button size="sm" disabled :variant="!config ? 'success' : 'outline-primary'"
              class="ml-4 font-weight-bolder">View Report</b-button>
          </div>

          <div class="table-responsive" v-if="config">
            <table class="table table-bordered table-striped">
              <thead class="thead-dark text-left">
                <tr>
                  <th>
                    <b-form-checkbox @change="selectAllData" v-model="selectAll">{{
                      selectAll ? "Unselect All" : "Select All"
                      }}</b-form-checkbox>
                  </th>
                  <th>Course Exit Survey</th>
                  <th>Questions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(assignment, assignmentIndex) in assignments" :key="assignmentIndex">
                  <td class="align-middle">
                    <b-form-checkbox size="sm" @change="getAssignmentQuestions(assignment.value)"
                      v-model="assignmentQuestions[assignment.value].checked">
                    </b-form-checkbox>
                  </td>
                  <td class="align-middle">
                    {{ assignment.text }}
                  </td>
                  <td class="align-middle text-left">
                    <div class="d-flex flex-wrap">
                      <div v-for="(ques, quesIndex) in assignmentQuestions[
                        assignment.value
                      ].questions" :key="quesIndex">
                        <span :id="`ques${assignment.value}` + quesIndex"
                          :class="ques.name != null ? 'text-info' : 'text-dark'">
                          <b-form-checkbox class="ml-3" v-model="
                            assignmentQuestions[assignment.value].selectedQues
                          " :value="ques.value">
                            {{ ques.text }}
                          </b-form-checkbox>
                        </span>
                        <b-popover v-if="ques.name != null" :target="`ques${assignment.value}` + quesIndex"
                          triggers="hover" placement="top">
                          {{ ques.name }}
                        </b-popover>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="p-1 mt-3 border-secondary border-top"></div>
            <b-button variant="primary" class="mb-2" size="sm" @click="addValue">+ Add Level</b-button>
            <div class="table-responsive">
              <table class="table table-bordered table-striped">
                <thead class="thead-dark">
                  <tr>
                    <th>Value</th>
                    <th>Comparison</th>
                    <th>Compare By</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(attainment, attainmentIndex) in attainments" :key="attainmentIndex">
                    <td class="align-middle">
                      <b-form-input v-model.number="attainment.value" @keypress="handleNumber"></b-form-input>
                    </td>
                    <td class="align-middle">
                      <b-form-select :options="operators" v-model="attainment.comparisan"></b-form-select>
                    </td>
                    <td class="align-middle">
                      <b-form-input @keypress="handleNumber" v-model="attainment.compare_by"></b-form-input>
                    </td>
                    <td class="align-middle">
                      <span class="btn p-0 m-0 text-danger" @click="attainments.splice(attainmentIndex, 1)">
                        <i class="fas fa-minus-circle"></i>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div v-else>
            <div class="table-responsive">
              <table class="table table-bordered table-striped">
                <thead class="thead-dark">
                  <tr>
                    <th class="align-middle">Q. No.</th>
                    <th class="align-middle">Question</th>
                    <th class="align-middle">CO</th>
                    <th class="align-middle">Choices</th>
                    <th class="align-middle">Scores</th>
                    <th class="align-middle">Count</th>
                    <th class="align-middle">Avg</th>
                    <th class="align-middle">%</th>
                    <th class="align-middle">Attainment<br>Level</th>
                    <!-- <th
                    class="align-middle"
                    v-for="(survey, surveyIndex) in surveys.questionsWithChoices"
                    :key="surveyIndex"
                  >
                    {{ survey.choices.name }}
                  </th> -->
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="(question,
                  questionIndex) in surveys.questionsWithChoices" :key="questionIndex">
                    <td class="align-middle">
                      {{ question.question_no }}
                    </td>
                    <td class="align-middle">
                      {{ question.name }}
                    </td>
                    <td class="align-middle">
                      <span v-for="coId in question.course_outcomes" :key="coId">
                        <span>{{ surveys.course_outcomes[coId].code }}</span>
                        <br />
                      </span>
                    </td>
                    <!-- <td class="align-middle">
                    {{ question.choices.name }}({{ handleValue(question.choices.score) }})
                  </td> -->
                    <td class="align-middle">
                      <div class="d-flex flex-column">
                        <span v-for="(choice, choiceIndex) in question.choices" :key="choiceIndex">
                          {{ choice.name }}
                        </span>
                      </div>
                    </td>
                    <td class="align-middle">
                      <div class="d-flex flex-column">
                        <span v-for="(choice, choiceIndex) in question.choices" :key="choiceIndex">
                          {{ handleValue(choice.score) }}
                        </span>
                      </div>
                    </td>
                    <td class="align-middle">
                      <div class="d-flex flex-column">
                        <span v-for="(choice, cIndex) in question.choices" :key="cIndex">{{
                          max_stud= surveys.questionsWithChoices[question.id].choices[cIndex].offline_evaluation_count ?
                          surveys.questionsWithChoices[question.id].choices[cIndex].offline_evaluation_count :
                          surveys.questionAnswerSummery[question.id].choices[choice.id].length
                          }} <br></span>
                      </div>
                    </td>
                    <td class="align-middle">
                      {{
                      Number(
                      surveys.questionAnswerSummery[
                      question.id
                      ].avg_score
                      )
                      }}
                    </td>
                    <td class="align-middle">
                      {{
                      Number(
                      surveys.questionAnswerSummery[
                      question.id
                      ].persantage
                      )
                      }}
                    </td>
                    <td class="align-middle">
                      {{ surveys.questionAnswerSummery[
                        question.id
                        ].attainment_level }}
                    </td>
                  </tr>
                  <!-- <tr
                  v-for="(survey, surveyIndex) in surveys.questionsWithChoices"
                  :key="surveyIndex"
                >
                  <td class="align-middle">{{ survey.name }}</td>
                  <td
                    class="align-middle"
                    v-for="(answer, answerIndex) in surveys.questionAnswerSummery"
                    :key="answerIndex"
                  >
                    {{ handleValue(surveys.questionAnswerSummery[survey.id].avg_score) }}
                  </td>
                </tr> -->
                </tbody>
              </table>
            </div>

            <div class="table-responsive mt-5">
              <table class="table table-bordered table-striped">
                <thead class="thead-dark">
                  <th class="align-middle" v-for="(co, coIndex) in reports.course_outcomes" :key="coIndex">
                    {{ co.code }}
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <td class="align-middle" v-for="(co, coIndex) in reports.course_outcomes" :key="coIndex">
                      <span v-if="reports.CoAttainment[co.id] === null">
                        -
                      </span>
                      <span v-else>
                        {{ reports.CoAttainment[co.id]>0?reports.CoAttainment[co.id]:'' }}
                      </span>
                      
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  props: ["courseReport"],
  async created() {
    this.fetchData = true;
    await this.getAttainmentConfigration();
    this.fetchData = false;
  },

  data() {
    return {
      fetchData: false,
      max_stud: null,
      assignments: [],
      selectedAssignments: [],
      assignmentQuestions: {},
      config: true,
      surveys: [],
      reports: {},
      selectAll: true,
      attainments: [],
      operators: [
        { value: null, text: "Select Operator" },
        { value: "<", text: "<" },
        { value: ">", text: ">" },
        { value: "<=", text: "<=" },
        { value: ">=", text: ">=" },
      ],
    };
  },
  methods: {
    handleNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    addValue() {
      this.attainments.push({
        value: "",
        comparisan: null,
        compare_by: "",
      });
    },
    selectAllData(status) {
      this.selectedAssignments = [];
      if (status) {
        for (let assignment in this.assignmentQuestions) {
          this.assignmentQuestions[assignment].checked = true;
          this.assignmentQuestions[assignment].questions.forEach((ques) => {
            this.assignmentQuestions[assignment].selectedQues.push(ques.value);
          });
        }
      } else {
        for (let assignment in this.assignmentQuestions) {
          this.assignmentQuestions[assignment].checked = false;
          this.assignmentQuestions[assignment].selectedQues = [];
        }
      }
    },
    handleValue(num) {
      if (isNaN(num)) {
        return "";
      } else {
        return num % 1 == 0 ? Math.floor(num) : parseFloat(num).toFixed(2);
      }
    },
    getAssignmentQuestions(value) {
      this.assignmentQuestions[value];
      if (this.assignmentQuestions[value].questions.length == 0) {
        return this.$toast.warning(`No questions found in this Survey!`, {
          position: "top",
          duration: 3000,
        });
      }
      if (this.assignmentQuestions[value].selectedQues.length != 0) {
        this.assignmentQuestions[value].selectedQues = [];
      } else {
        this.assignmentQuestions[value].questions.forEach((ques) => {
          this.assignmentQuestions[value].selectedQues.push(ques.value);
        });
      }
    },
    async getAttainmentConfigration() {
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/coattainment/getconfig`;
      await this.$axios
        .post(url, {
          course: this.courseReport.course_id,
          step: "courseexitsurvey",
        })
        .then((response) => {
          let selected_surveys = response.data.selected_surveys;
          let selected_questions = response.data.selected_questions;
          let assignments = response.data.surveys;
          assignments.forEach((assignment) => {
            this.assignments.push({
              value: assignment.id,
              text: assignment.name,
            });

            this.$set(this.assignmentQuestions, assignment.id, {
              questions: [],
              selectedQues: [],
              checked: selected_surveys.includes(assignment.id),
            });

            assignment.questions.forEach((ques) => {
              this.assignmentQuestions[assignment.id].questions.push({
                value: ques.id,
                text: ques.question_no,
                name: ques.name == "" ? null : ques.name,
              });
              if (selected_questions.includes(ques.id))
                this.assignmentQuestions[assignment.id].selectedQues.push(ques.id);
            });
          });
          const attainments = response.data.rubrics;
          attainments.forEach((attainment) => {
            this.attainments.push({
              value: attainment.value,
              comparisan: attainment.comparisan,
              compare_by: attainment.compare_by
            });
          });
        });
    },
    async setAttainmentConfigration() {
      for (let assignment in this.assignmentQuestions) {
        await this.assignmentQuestions[assignment].selectedQues.forEach(
          (ques) => {
            this.selectedAssignments.push(ques);
          }
        );
      }
      const direct = [];
      await this.attainments.forEach((attainment) => {
        if (
          attainment.value != "" &&
          attainment.comparisan != null &&
          attainment.compare_by != ""
        ) {
          direct.push(attainment);
        }
      });
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/coattainment/setconfig`;
      await this.$axios
        .post(url, {
          rubrics: direct,
          course: this.courseReport.course_id,
          step: "courseexitsurvey",
          survey_questions: this.selectedAssignments,
        })
        .then(() => { });
    },
    async getAttainmentData() {
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/coattainment/getdata`;
      await this.$axios
        .post(url, {
          course: this.courseReport.course_id,
          step: "courseexitsurvey",
        })
        .then((response) => {
          this.surveys = response.data;
        });
    },
    async getAttainmentReport() {
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/coattainment/report`;
      await this.$axios
        .post(url, {
          course: this.courseReport.course_id,
          step: "courseexitsurvey",
        })
        .then((response) => {
          this.reports = response.data;
        });
    },
  },
};
</script>
