<template>
  <div>
    <b-row>
      <b-col md="12">
        <div class="createModule">
          <br />

          <div v-if="!moduleForm">
            <div class="" style="border: 2px solid #17a2b8; padding: 15px; border-radius: 5px;">
              <ul>
                You have three options to copy course data:
                <li>
                  Copy from University Resources <span style="color: #2929aa; text-decoration: underline; cursor: pointer;"
                    @click="copy_from = 'template'; $bvModal.show('copyCoursePropertiesModel')">Click here to
                    proceed</span>
                </li>
                <li>Copy from previous academic year <span
                    style="color: #2929aa; text-decoration: underline; cursor: pointer;"
                    @click="copy_from = 'myinstitute'; $bvModal.show('copyCoursePropertiesModel')">Click here to
                    proceed</span>
                </li>
                <li>Import data using an Excel sheet <span
                    style="color: #2929aa; text-decoration: underline; cursor: pointer;"
                    @click="$emit('goto-excel');">Click here to
                    proceed</span>
                </li>
              </ul>
              <b-modal id="copyCoursePropertiesModel" no-close-on-backdrop no-close-on-keyboard no-close-on-esc
                hide-footer hide-header centered size="md">
                <CopyCourseProperties :copy_from_suggestion="copy_from" :course_id="courseId"
                  @success="propertyCopyCompleted()" @cancel="$bvModal.hide('copyCoursePropertiesModel')" />
              </b-modal>
            </div>
            <div class="text-center m-2 text-primary"> OR </div>

            <p>Enter Manually <span style="color: #2929aa; text-decoration: underline; cursor: pointer;"
                @click="(moduleForm = true)">Click here to
                proceed</span></p>
          </div>

          <b-form v-if="moduleForm" @submit.prevent="
            currentCourseModule.id
              ? updateCourseOutcome()
              : createCourseModule()
          ">
            <h6 class="alert alert-success text-center" v-if="responseSuccess.created">
              {{ responseSuccess.created[0] }}
            </h6>
            <div v-for="(courseModule, moduleIndex) in modules" :key="moduleIndex">
              <b-form-group class="text-right" v-if="moduleIndex != 0">
                <span class="text-danger btn p-0 m-0" @click="removeModule(moduleIndex)"><i
                    class="fas fa-minus-circle fa-2x"></i></span>
              </b-form-group>
              <b-form-group label="Si. No.:">
                <b-form-input placeholder="Si. No." v-model="courseModule.si_no"></b-form-input>
              </b-form-group>

              <b-form-group label="Course Module:">
                <b-form-input placeholder="Enter Module" v-model="courseModule.module"
                  :class="{ 'is-invalid': responseErrors.module }"></b-form-input>
                <span class="text-danger" v-if="responseErrors.module">{{
                    responseErrors.module[0]
                }}</span>
              </b-form-group>

              <b-form-group label="Description:">
                <b-form-textarea placeholder="Enter Description" rows="3"
                  :class="{ 'is-invalid': responseErrors.lessons }" v-model="courseModule.lessons"></b-form-textarea>

                <span class="text-danger" v-if="responseErrors.lessons">{{
                    responseErrors.lessons[0]
                }}</span>
              </b-form-group>

              <b-form-group label="Contact Hours:">
                <b-form-input placeholder="Enter Hours" v-model="courseModule.contact_hours"
                  :class="{ 'is-invalid': responseErrors.contact_hours }"></b-form-input>
                <span class="text-danger" v-if="responseErrors.contact_hours">{{
                    responseErrors.contact_hours[0]
                }}</span>
              </b-form-group>

              <b-form-group label="Sem Exam Marks %:">
                <b-form-input placeholder="Enter Marks %" v-model="courseModule.totel_score_for_exam"
                  :class="{ 'is-invalid': responseErrors.totel_score_for_exam }"></b-form-input>
                <span class="text-danger" v-if="responseErrors.totel_score_for_exam">{{
                    responseErrors.totel_score_for_exam[0]
                }}</span>
              </b-form-group>
            </div>

            <b-form-group>
              <div class="d-flex flex-row justify-content-between">
                <b-button variant="danger" size="md" @click="cancel()" :disabled="outcomeBtn.disabled">Close</b-button>
                <div>
                  <b-button type="submit" variant="primary" size="md" :disabled="outcomeBtn.disabled">{{ outcomeBtn.text
                  }}</b-button>
                </div>
              </div>
            </b-form-group>
          </b-form>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import CopyCourseProperties from "../CopyCourseProperties.vue"
import axios from "axios";
export default {
  props: ["courseId", "currentCourseModule"],
  created() {
    if (this.currentCourseModule.id) {
      this.moduleForm = true;
      this.modules[0].si_no = this.currentCourseModule.si_no;
      this.modules[0].module = this.currentCourseModule.module;
      this.modules[0].lessons = this.currentCourseModule.lessons;
      this.modules[0].contact_hours = this.currentCourseModule.contact_hours;
      this.modules[0].totel_score_for_exam = this.currentCourseModule.totel_score_for_exam;
    }
  },
  components: {
    CopyCourseProperties,
  },

  data() {
    return {
      moduleForm: false,
      modules: [{ module: "", si_no: "", lessons: "", course_id: this.courseId }],
      outcomeCreated: "",
      outcomeBtn: {
        text: "Submit",
        disabled: false,
      },
      responseErrors: {},
      responseSuccess: {},
      copy_from: '',
    };
  },
  methods: {
    propertyCopyCompleted() {
      this.$bvModal.hide('copyCoursePropertiesModel')
      this.$emit("getCourseModules");
      this.cancel();
    },
    addMoreOutcome() {
      this.modules.push({
        si_no: "",
        module: "",
        lessons: "",
        course_id: this.courseId,
        contact_hours: "",
        totel_score_for_exam: "",
      });
    },
    removeModule(removeModule) {
      this.modules.splice(removeModule, 1);
    },
    async createCourseModule() {
      this.outcomeBtn.text = "Please wait...";
      this.outcomeBtn.disabled = true;
      const url = this.$store.getters.getAPIKey.courseModules;
      this.modules.forEach((cModule, cModuleIndex) => {
        const courseModule = {
          si_no: cModule.si_no,
          module: cModule.module,
          lessons: cModule.lessons,
          course_id: cModule.course_id,
          contact_hours: cModule.contact_hours,
          totel_score_for_exam: cModule.totel_score_for_exam,
        };

        axios
          .post(url, courseModule)
          .then(() => {
            if (this.modules.length == 1) {
              this.responseSuccess["created"] = [
                `${cModule.module} is successfully created!`,
              ];
              this.clearResponse();
            }
            if (this.modules.length > 1) {
              this.modules.splice(cModuleIndex, 1);
            }

            // console.log(response);
          })
          .catch((error) => {
            if (error.response) {
              this.responseErrors = error.response.data.errors;
              this.clearResponse();
            }

            // console.log(error.response);
          });
      });
    },
    updateCourseOutcome() {
      this.outcomeBtn.text = "Updating...";
      this.outcomeBtn.disabled = true;
      const url =
        this.$store.getters.getAPIKey.courseModules +
        `/${this.currentCourseModule.id}`;

      const courseModule = {
        si_no: this.modules[0].si_no,
        module: this.modules[0].module,
        lessons: this.modules[0].lessons,
        course_id: this.modules[0].course_id,
        contact_hours: this.modules[0].contact_hours,
        totel_score_for_exam: this.modules[0].totel_score_for_exam,
      };

      axios
        .put(url, courseModule)
        .then(() => {
          this.responseSuccess["created"] = [
            `${courseModule.module} is successfully updated!`,
          ];
          this.clearResponse();

          // console.log(response);
        })
        .catch((error) => {
          if (error.response) {
            this.responseErrors = error.response.data.errors;
            this.clearResponse();
          }
        });
    },
    clearResponse() {
      this.outcomeBtn.text = "Submit";
      setTimeout(() => {
        if (this.responseSuccess.created) {
          this.$emit("getCourseModules");
          this.cancel();
        }

        this.outcomeBtn.disabled = false;
        this.responseErrors = {};
        this.responseSuccess = {};
      }, 3000);
    },
    cancel() {
      this.modules = [
        {
          si_no: "",
          module: "",
          lessons: "",
          course_id: this.courseId,
          contact_hours: "",
          totel_score_for_exam: "",
        },
      ];
      this.$emit("cancel");
    },
  },
};
</script>

<style >
.modules form label {
  font-size: 18px;
  font-weight: bold;
}
</style>
