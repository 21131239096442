<template>
  <div>
    <div class="approvalTypes p-3 border-border-info">
      <div class="text-right videoApproval">
        <!-- <b-button
          @click="openYoutubeLinks('Approval')"
          style="backgroundColor: #FF0000; border: none"
          size="sm"
        >
          <i class="fab fa-youtube"></i>
          Video Tutorial
        </b-button> -->
      </div>
      <hr />
      <b-tabs content-class="mt-3" lazy v-if="SurveyApprovalAdmin">
        <b-tab title="Created Forms" v-if="surveyApprovalQuestions">
          <SurveyApprovalsListing
            :SurveyApprovalAdmin="SurveyApprovalAdmin"
            :SurveyApprovalStudent="SurveyApprovalStudent"
          />
        </b-tab>
        <b-tab title="Submit Forms">
          <SubmitForm />
        </b-tab>
        <b-tab>
          <template #title>
            Forms For Approval
            <span
              v-if="$store.getters.getApprovalStatus.formsApprovals.status"
              class="text-success ml-1 approvalStatus"
              ><i class="fas fa-circle text-success"></i
            ></span>
          </template>
          <div>
            <h3
              class="pl-3"
              style="cursor: pointer;"
              @click="
                ApprovalFormApplicationsSimpleView = !ApprovalFormApplicationsSimpleView
              "
            >
              <span
                style="color: #2929aa;font-size:15px !important; text-decoration: underline;"
              >
                Click here
              </span>
              <span v-if="ApprovalFormApplicationsSimpleView"
                >to view event specific applications</span
              >
              <span v-if="!ApprovalFormApplicationsSimpleView"
                >for minimal view</span
              >
            </h3>
            <ApprovalForms v-if="!ApprovalFormApplicationsSimpleView" />
            <ApprovalFormApplications
              v-if="ApprovalFormApplicationsSimpleView"
            />
          </div>
        </b-tab>
        <b-tab title="Reports" v-if="surveyApprovalReports">
          <ApprovalReport />
        </b-tab>
      </b-tabs>
      <div class="studentView" v-else>
        <SubmitForm />
      </div>
    </div>
  </div>
</template>

<script>
import SurveyApprovalsListing from "./SurveyApprovalsListing.vue";
import ApprovalForms from "./forms_for_approvals/ApprovalForms.vue";
import ApprovalFormApplications from "./forms_for_approvals/ApprovalFormApplications.vue";
import SubmitForm from "./forms_for_submit/SubmitForm.vue";
import ApprovalReport from "./approval_reports/ApprovalReport.vue";
import acl from "../../../../../authorization/acl.js";
export default {
  async created() {
    const SurveyApprovalAdmin = await acl.isUserGranted("SurveyApprovalAdmin");
    this.SurveyApprovalAdmin = SurveyApprovalAdmin;

    const SurveyApprovalStudent = await acl.isUserGranted(
      "SurveyApprovalStudent"
    );
    this.SurveyApprovalStudent = SurveyApprovalStudent;

    const surveyApprovalQuestions = await acl.isUserGranted(
      "surveyApprovalQuestions"
    );
    this.surveyApprovalQuestions = surveyApprovalQuestions;

    const surveyApprovalReports = await acl.isUserGranted(
      "surveyApprovalReports"
    );
    this.surveyApprovalReports = surveyApprovalReports;
  },
  components: {
    SurveyApprovalsListing,
    ApprovalForms,
    SubmitForm,
    ApprovalReport,
    ApprovalFormApplications,
  },
  data() {
    return {
      tabIndex: 0,
      SurveyApprovalAdmin: null,
      SurveyApprovalStudent: null,
      surveyApprovalQuestions: null,
      surveyApprovalReports: null,
      ApprovalFormApplicationsSimpleView: true,
    };
  },
};
</script>

<style >
.approvalTypes .nav-tabs {
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 5px;
}

.approvalTypes .nav-tabs .nav-item {
  border: 3px solid #f8f9fa !important;
}
.approvalTypes .tab-content {
  border: none !important;
}

.approvalTypes .approvalStatus i {
  font-size: 10px !important;
}

.approvalTypes .videoApproval i {
  font-size: 10px !important;
}
</style>
