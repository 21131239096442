<template>
  <div>
    <div class="setAcedemicYear">
      <b-form-select :options="$settingAcedemicYearsAndSemester" v-model="selectedYearSemester"
        @change="setDefaultAcedemicYearSemester((refersh = true))" style="width: 200px"></b-form-select>
    </div>
  </div>
</template>

<script>
export default {
  async created() {
    // await this.prepareAcedemicYearsChart();
    await this.getDefaultAcedemicYear();
    await this.getDefaultAcedemicSemester();
    this.selectedYearSemester = [this.acedemic_year, this.semester];
    this.$root.default_academic_year[0] = this.selectedYearSemester[0];
    this.$root.default_academic_year[1] = this.selectedYearSemester[1];
  },
  data() {
    return {
      acedemic_year: null,
      yearsChart: {},
      semester: null,
      selectedYearSemester: [],
    };
  },
  methods: {
    // async prepareAcedemicYearsChart() {
    //   await this.$acedemicYears.forEach((year) => {
    //     if (year.value != null) {
    //       this.$set(this.yearsChart, year.text, year.text);
    //     }
    //   });
    // },
    async getDefaultAcedemicYear() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        "/user/setting/default_academic_year";
      await this.$axios
        .get(url)
        .then((response) => {
          // if (
          //   this.yearsChart[response.data] &&
          //   response.data == this.yearsChart[response.data]
          // ) {
          //   this.acedemic_year = response.data;
          // } else {
          this.acedemic_year = `${response.data}`;
          // }
          // response;
        })
        .catch((error) => {
          error;
        });
    },
    // async setDefaultAcedemicYear() {
    //   const url =
    //     this.$store.getters.getAPIKey.mainAPI +
    //     "/user/setting/default_academic_year";
    //   await this.$axios
    //     .post(url, { default_academic_year: this.acedemic_year })
    //     .then((response) => {
    //       window.location.reload();
    //       response;
    //     })
    //     .catch((error) => {
    //       error;
    //     });
    // },

    async getDefaultAcedemicSemester() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        "/user/setting/default_semester";
      await this.$axios
        .get(url)
        .then((response) => {
          this.semester = `${response.data}`;
          if ((this.semester == "0" || this.acedemic_year == "0") && (this.semester != this.acedemic_year)) { // if one is zero then both should zero
            this.acedemic_year = "0"; this.semester = "0";
            this.selectedYearSemester = [this.acedemic_year, this.semester];
            this.setDefaultAcedemicYearSemester(false);
          }
        })
        .catch((error) => {
          error;
        });
    },

    async setDefaultAcedemicYearSemester(refersh) {
      const yearUrl =
        this.$store.getters.getAPIKey.mainAPI +
        "/user/setting/default_academic_year";
      await this.$axios
        .post(yearUrl, { default_academic_year: this.selectedYearSemester[0] })
        .then((response) => {
          // window.location.reload();
          response;
        })
        .catch((error) => {
          error;
          return;
        });

      const semUrl =
        this.$store.getters.getAPIKey.mainAPI +
        "/user/setting/default_semester";
      await this.$axios
        .post(semUrl, { default_semester: this.selectedYearSemester[1] })
        .then((response) => {
          if (refersh) window.location.reload();
          response;
        })
        .catch((error) => {
          error;
        });
    },
  },
};
</script>
