<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="institutionProfileData" v-else>
      <b-overlay
        :show="loadData"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="md"
      >
        <div class="studentOrTeacher mb-2 text-right">
          <div class="toggleContainer">
            <div
              id="on"
              @click="switchUserRole(true)"
              :class="{ active: switchRole }"
            >
              Student
            </div>
            <div
              id="off"
              @click="switchUserRole(false)"
              :class="{ active: !switchRole }"
            >
              Faculty
            </div>
          </div>
        </div>

        <div class="addOrEdit mb-2">
          <b-button
            size="sm"
            variant="info"
            @click="$bvModal.show('addOrEditInstituteQuestions')"
            >Add/Edit</b-button
          >
        </div>
        <b-modal
          id="addOrEditInstituteQuestions"
          no-close-on-backdrop
          no-close-on-keyboard
          no-close-on-esc
          hide-footer
          centered
          size="lg"
        >
          <InstituteProfileQuestions
            :instituteQuestionsStructure="instituteQuestionsStructure"
            :instituteAttachedQuestions="instituteAttachedQuestions"
            @questionsAttached="questionsAttached"
            :switchRole="switchRole"
          />
        </b-modal>

        <div class="table-responsive">
          <table class="table table-bordered table-striped">
            <thead class="thead-dark">
              <tr>
                <th>Question</th>
                <th>Detach</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="instituteAttachedQuestions.length == 0">
                <td colspan="100" class="text-center text-danger">
                  No Questions Attached yet!
                </td>
              </tr>

              <tr
                v-for="(attachedQues,
                attachedQuesIndex) in instituteAttachedQuestions"
                :key="attachedQuesIndex"
              >
                <td class="align-middle">
                  <span
                    :id="'question' + attachedQues.question.id"
                    :class="
                      attachedQues.question.description != null
                        ? 'text-info'
                        : 'text-dark'
                    "
                  >
                    {{ attachedQues.question.name }}
                  </span>

                  <b-popover
                    :target="'question' + attachedQues.question.id"
                    triggers="hover"
                    placement="top"
                    v-if="attachedQues.question.description != null"
                  >
                    {{ attachedQues.question.description }}
                  </b-popover>
                </td>
                <td class="align-middle text-center">
                  <span
                    class="text-danger p-0 m-0 btn"
                    @click="
                      detachQuestionFromInstitute(
                        attachedQues.question.id,
                        attachedQuesIndex
                      )
                    "
                  >
                    <i class="fas fa-trash"></i>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import InstituteProfileQuestions from "./InstituteProfileQuestions.vue";
export default {
  async created() {
    this.fetchData = true;
    await this.getAttachedQuestions();
    await this.prepareQuestionStructure();
    await this.getInstitutionProfileData();
    this.fetchData = false;
  },
  components: {
    InstituteProfileQuestions,
  },
  data() {
    return {
      fetchData: false,
      loadData: false,
      switchRole: true,
      instituteAttachedQuestions: [],
      instituteQuestionsStructure: {},
      questionOrAttached: false,
    };
  },
  methods: {
    async questionsAttached() {
      this.$bvModal.hide("addOrEditInstituteQuestions");
      this.fetchData = true;
      await this.getAttachedQuestions();
      await this.prepareQuestionStructure();
      this.fetchData = false;
    },
    async switchUserRole(status) {
      this.fetchData = true;
      this.switchRole = await status;
      await this.getAttachedQuestions();
      await this.prepareQuestionStructure();
      this.fetchData = false;
    },
    async getAttachedQuestions() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        "/institution/institutionuserquestions";
      await this.$axios
        .get(url)
        .then((response) => {
          this.instituteAttachedQuestions = response.data;
          if (this.switchRole) {
            this.instituteAttachedQuestions = this.instituteAttachedQuestions.filter(
              (ques) => {
                return ques.question.role == "student";
              }
            );
          } else {
            this.instituteAttachedQuestions = this.instituteAttachedQuestions.filter(
              (ques) => {
                return ques.question.role != "student";
              }
            );
          }
          response;
        })
        .catch((error) => {
          error;
        });
    },

    async getInstitutionProfileData() {
      const url =
        this.$store.getters.getAPIKey.mainAPI + "/institutionuserdatas";
      await this.$axios
        .get(url)
        .then((response) => {
          response;
        })
        .catch((error) => {
          error;
        });
    },
    async prepareQuestionStructure() {
      this.instituteAttachedQuestions.forEach((attachedQues) => {
        this.$set(
          this.instituteQuestionsStructure,
          attachedQues.question.id,
          attachedQues.question.id
        );
      });
    },

    async detachQuestionFromInstitute(questionId, QuestionIndex) {
      this.loadData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/institutionuserquestions/${questionId}/detach`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.instituteAttachedQuestions.splice(QuestionIndex, 1);
          delete this.instituteQuestionsStructure[questionId];
          this.$toast.success("Question Detached From Institute", {
            position: "top",
            duration: 3000,
          });
          this.loadData = false;
          response;
        })
        .catch((error) => {
          this.$toast.error("Something went wrong, Please try again!", {
            position: "top",
            duration: 3000,
          });
          this.loadData = false;
          error;
        });
    },
  },
};
</script>

<style >
.toggleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

#on,
#off {
  border-radius: 20px;
  background-color: lightgray;
  padding: 10px;
  text-align: center;
  margin: 10px -5px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

#on.active,
#off.active {
  background-color: lightgreen;
}

#on:hover,
#off:hover {
  background-color: lightcoral;
}
</style>
