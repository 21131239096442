<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div v-else>
      <b-overlay :show="postCourses" spinner-variant="primary" spinner-type="grow" spinner-small rounded="md">
        <div class="createCourse">
          <div class="existingInstructors d-flex flex-wrap align-items-baseline">
            <h5>Existing Instructors:</h5>
            <div class="instructorsList" v-for="(instructor, instructorIndex) in courseUsers" :key="instructorIndex">
              <p v-if="instructor.pivot.courserole_id == 1" class="ml-3 text-white rounded p-1">
                {{ instructor.first_name || " " }}
                {{ instructor.last_name || " " }}
                <span class="btn m-0 p-0" @click="deleteCourseUser(instructor.id, instructorIndex)"><i
                    class="fas fa-minus-circle text-danger" v-if="!courseBtn.disabled"></i></span>
              </p>
            </div>
          </div>
          <hr />

          <div class="existingStudents d-flex flex-wrap align-items-baseline">
            <h5>Existing Students:</h5>
            <div class="instructorsList" v-for="(student, studentIndex) in courseUsers" :key="studentIndex">
              <p v-if="student.pivot.courserole_id == 2" class="ml-3 text-white rounded p-1">
                {{ student.first_name || " " }} {{ student.last_name || " " }}
                <span class="btn m-0 p-0" @click="
                  deleteCourseUser(
                    student.id,
                    studentIndex,
                    student.pivot.institution_user_id
                  )
                "><i class="fas fa-minus-circle text-danger" v-if="!courseBtn.disabled"></i></span>
              </p>
              <!-- <p class="text-danger ml-3">None</p> -->
            </div>
          </div>
          <hr />
          <div ref="updateSuccessful">
            <h6 class="alert alert-success text-center" v-if="responseErrors.courseUpdated">
              {{ responseErrors.courseUpdated[0] }}
            </h6>
          </div>

          <b-form @submit.prevent="updateCourse()" class="p-2">
            <b-form-group>
              <h4 class="text-center text-info font-weight-bolder">
                Update Course
              </h4>
            </b-form-group>
          
            <b-form-group label="Si. No.">
              <p v-if="!editCourse">{{ courseDetails.si_no }}</p>
              <b-form-input v-else placeholder="Si. No." v-model="courseDetails.si_no"></b-form-input>
            </b-form-group>
            <b-form-group label="Course Name">
              <p v-if="!editCourse">{{ courseDetails.name }}</p>
              <b-form-input v-else placeholder="Course Name" v-model="courseDetails.name"
                :class="{ 'is-invalid': responseErrors.name }"></b-form-input>
              <span class="text-danger" v-if="responseErrors.name">{{
                  responseErrors.name[0]
              }}</span>
            </b-form-group>

            <b-form-group label="Course Code">
              <p v-if="!editCourse">{{ courseDetails.code }}</p>
              <b-input v-else v-model="courseDetails.code" placeholder="Course Code"
                :class="{ 'is-invalid': responseErrors.code }"></b-input>
              <span class="text-danger" v-if="responseErrors.code">{{
                  responseErrors.code[0]
              }}</span>
            </b-form-group>

            <b-form-group :label="
              getCustomNames.userSubgroup.custom_name != null
                ? 'Select ' + getCustomNames.userSubgroup.custom_name
                : 'Select Usersubgroup'
            ">
              <b-form-select v-model="courseDetails.usersubgroup_id" :options="groupDetails"
                @change="getSubgroupUsers()" :class="{ 'is-invalid': responseErrors.usersubgroup_id }"></b-form-select>
              <span class="text-danger" v-if="responseErrors.usersubgroup_id">{{
                  responseErrors.usersubgroup_id[0]
              }}</span>
            </b-form-group>

            <b-form-group label="Course Type">
              <b-form-select :options="courseCategoriesOptions" v-model="courseDetails.course_category_id"
                :class="{ 'is-invalid': responseErrors.course_category_id }"></b-form-select>
              <span class="text-danger" v-if="responseErrors.course_category_id">{{
                  responseErrors.course_category_id[0]
              }}</span>
            </b-form-group>

            <b-form-group label="Sub Type">
              <b-form-select :options="courseTypesOptions" v-model="courseDetails.course_type_id"
                :class="{ 'is-invalid': responseErrors.course_type_id }"></b-form-select>
              <span class="text-danger" v-if="responseErrors.course_type_id">{{
                  responseErrors.course_type_id[0]
              }}</span>
            </b-form-group>

            <!-- <b-form-group label="Academic Year">
              <b-form-select :options="$acedemicYears" v-model="courseDetails.academic_year"
                :class="{ 'is-invalid': responseErrors.academic_year }"></b-form-select>
              <br />
              <span class="text-danger" v-if="responseErrors.academic_year">{{
              responseErrors.academic_year[0]
              }}</span>
            </b-form-group> -->

            <b-form-group label="Batch Starting Year">
              <b-form-select :options="$batchYears" v-model="courseDetails.start_year"
                :class="{ 'is-invalid': responseErrors.start_year }"></b-form-select>
              <span class="text-danger" v-if="responseErrors.start_year">{{
                  responseErrors.start_year[0]
              }}</span>
            </b-form-group>

            <b-form-group label="Batch Ending Year">
              <b-form-select :options="$batchYears" v-model="courseDetails.end_year"
                :class="{ 'is-invalid': responseErrors.end_year }"></b-form-select>
              <span class="text-danger" v-if="responseErrors.end_year">{{
                  responseErrors.end_year[0]
              }}</span>
            </b-form-group>

            <b-form-group label="" >
              <b-form-checkbox size="lg" v-model="courseDetails.enable_laboratory">Add Laboratory To This Course</b-form-checkbox>
            </b-form-group>

            <!-- <b-form-group
              label="Choose the components to be added to this course"
            >
              <b-form-checkbox-group
                :v-model="addedComponents"
                :options="courseComponents"
                class="mb-3"
                value-field="item"
                text-field="name"
                size="sm"
              >
              </b-form-checkbox-group>
            </b-form-group> -->

            <b-form-group>
              <div class="enrollFacultyList">
                <multiselect v-model="selectedFaculty" tag-placeholder="Add this as new tag"
                  placeholder="Choose course instructors" label="name" track-by="code" :options="facultyOptions"
                  :close-on-select="false" :multiple="true" :taggable="true" @tag="addFaculty"></multiselect>
              </div>
            </b-form-group>
            <b-form-group>
              <div class="enrollStudentList">
                <multiselect v-model="selectedStudents" tag-placeholder="Add this as new tag" :placeholder="
                  StudentOptions.length == 0
                    ? 'Add Students'
                    : 'Enrolled Students List'
                " label="name" track-by="code" :options="StudentOptions" :close-on-select="false" :multiple="true"
                  :taggable="true" @tag="addStudent"></multiselect>
              </div>
            </b-form-group>
            <b-form-group>
              <b-button variant="primary" size="md" type="submit" :disabled="courseBtn.disabled">{{ courseBtn.text }}
              </b-button>
              <b-button variant="danger" size="md" class="ml-3" :disabled="courseBtn.disabled" @click="cancel()">Cancel
              </b-button>
            </b-form-group>
          </b-form>
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: [
    "courseInfo",
    "courseId",
    "courseUsers",
    "editCourse",
    "faculties",
    "getCustomNames",
    "courseTypesOptions",
    "courseCategoriesOptions",
    "subgroupType"
  ],
  async created() {
    this.courseDetails.si_no = this.courseInfo.si_no;
    this.courseDetails.name = this.courseInfo.name;
    this.courseDetails.code = this.courseInfo.code;
    this.courseDetails.usersubgroup_id = this.courseInfo.usersubgroup_id;
    this.courseDetails.enable_laboratory = this.courseInfo.enable_laboratory;
    this.$set(
      this.courseDetails,
      "start_year",
      this.courseInfo.start_year != null ? this.courseInfo.start_year : null
    );
    this.$set(
      this.courseDetails,
      "course_type_id",
      this.courseInfo.course_type_id != null
        ? this.courseInfo.course_type_id
        : null
    );
    this.$set(
      this.courseDetails,
      "course_category_id",
      this.courseInfo.course_category_id != null
        ? this.courseInfo.course_category_id
        : null
    );
    this.$set(
      this.courseDetails,
      "end_year",
      this.courseInfo.end_year != null ? this.courseInfo.end_year : null
    );
    if (this.courseInfo.academic_year != null) {
      this.$set(
        this.courseDetails,
        "academic_year",
        this.courseInfo.academic_year
      );
    } else {
      this.$set(this.courseDetails, "academic_year", null);
    }
    this.groupDetails.push({
      value: this.courseInfo.usersubgroup_id,
      text:
        this.courseInfo.usersubgroup.code +
        "(" +
        this.courseInfo.usersubgroup.code +
        ")",
      disable: true,
    });
    this.fillData();
  },

  data() {
    return {
      fetchData: false,
      postCourses: false,
      courseDetails: {},
      addedComponents: [],
      groupIds: null,
      courseComponents: [
        { item: "Class", name: "Live Class" },
        { item: "Attendance", name: "Attendance" },
        { item: "Assignment", name: "Assignment" },
        { item: "Assessment", name: "Assessment" },
      ],
      selectedFaculty: [],
      facultyOptions: [],
      selectedStudents: [],
      StudentOptions: [],
      groupDetails: [],
      groupsSubgroups: [],
      faculty: [],
      students: [],
      enrolledUsers: [],
      courseBtn: {
        text: "Submit",
        disabled: false,
      },
      responseErrors: {},
      groupList: [],
      subgroupUsers: {
        text: "Refresh Subgroup Students List",
        disabled: false,
        color: "",
      },
      studentsData: {},
      batchYearOptions: [],
    };
  },
  methods: {
    async fillData() {
      this.fetchData = await true;
      await this.getInstitutionFaculties();
      await this.getSubgroupUsers();

      this.fetchData = false;
    },

    updateCourse() {
      this.postCourses = true;
      this.courseBtn.text = "Please Wait...";
      this.courseBtn.disabled = true;
      let updateCourse = this.$store.getters.getAPIKey.updateCourse;
      const url = updateCourse.replace("course_id", this.courseId);
      axios
        .put(url, this.courseDetails)
        .then((response) => {
          let courseId = response.data.id;

          if (
            this.selectedFaculty.length == 0 &&
            this.selectedStudents.length == 0
          ) {
            this.$toast.success(
              "Course: " +
              this.courseDetails.name +
              " " +
              "successfully updated.",
              {
                position: "top",
                duration: 3000,
              }
            );
            this.$parent.getCourseDetails();
            this.clearResponse();
            this.cancel();
          }

          if (this.selectedFaculty.length !== 0) {
            this.createIntructors(courseId);
          }

          if (this.selectedStudents.length !== 0) {
            this.createStudents(courseId);
          }

          // console.log(response);
        })
        .catch((error) => {
          this.responseErrors = error.response.data.errors;
          this.clearResponse();
          // console.log(error.response);
        });
    },

    async createIntructors(courseId) {
      let mainUrl = this.$store.getters.getAPIKey.createIntructor;
      const url = mainUrl.replace("course_id", courseId);
      const instructors = {
        institution_users: [],
      };
      await this.selectedFaculty.forEach((instructor) => {
        instructors.institution_users.push(instructor.id);
      });

      await axios
        .post(url, instructors)
        .then(() => {
          this.selectedFaculty = [];
          if (this.selectedStudents.length == 0) {
            this.$toast.success(
              "Course: " +
              this.courseDetails.name +
              " " +
              "successfully updated.",
              {
                position: "top",
                duration: 3000,
              }
            );
            this.$parent.getCourseDetails();
            this.clearResponse();
            this.cancel();
          }
          // console.log(response);
        })
        .catch(() => {
          // console.log(error);
        });
    },
    async createStudents(courseId) {
      let mainUrl = this.$store.getters.getAPIKey.createStudent;
      const url = mainUrl.replace("course_id", courseId);

      const students = {
        institution_users: [],
      };

      await this.selectedStudents.forEach((student) => {
        students.institution_users.push(student.id);
      });

      await axios
        .post(url, students)
        .then(() => {
          this.selectedStudents = [];
          if (this.selectedFaculty.length == 0) {
            this.$toast.success(
              "Course: " +
              this.courseDetails.name +
              " " +
              "successfully updated.",
              {
                position: "top",
                duration: 3000,
              }
            );
            this.$parent.getCourseDetails();
            this.clearResponse();
            this.cancel();
          }
          // console.log(response);
        })
        .catch(() => {
          // console.log(error);
        });
    },
    async getSubgroupUsers() {
      this.postCourses = true;
      let mainUrl = this.$store.getters.getAPIKey.getEnrollSubgroupUsersList;
      const url = mainUrl.replace(
        "usersubgroup_id",
        this.courseInfo.usersubgroup_id
      );

      await axios
        .get(url)
        .then((response) => {
          this.enrolledUsers = [];
          this.selectedStudents = [];
          this.enrolledUsers = response.data;

          this.students = [];
          this.StudentOptions = [];
          this.students = response.data;
          this.students.forEach((staff) => {
            if (staff.pivot.usersubgrouprole_id == 2) {
              this.StudentOptions.push({
                name: staff.first_name + " " + staff.last_name,
                id: staff.pivot.institution_user_id,
                code: staff.id,
              });
              this.$set(this.studentsData, staff.pivot.institution_user_id, {
                id: staff.pivot.institution_user_id,
                data: {
                  name: staff.first_name + " " + (staff.last_name || ""),
                  id: staff.pivot.institution_user_id,
                  code: staff.id,
                },
              });
            }
          });

          if (this.courseUsers.length != 0) {
            this.courseUsers.forEach((existing) => {
              this.selectedStudents.forEach((student, studentIndex) => {
                if (existing.pivot.institution_user_id == student.id) {
                  this.selectedStudents.splice(studentIndex, 1);
                }
              });
              this.StudentOptions.forEach((option, optionIndex) => {
                if (existing.pivot.institution_user_id == option.id) {
                  this.StudentOptions.splice(optionIndex, 1);
                }
              });
            });
          }
          this.postCourses = false;
          // console.log(response);
        })
        .catch(() => {
          this.postCourses = false;
          // console.log(error.response);
        });
    },
    addFaculty(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
        id: newTag,
      };
      this.facultyOptions.push(tag);
      this.selectedFaculty.push(tag);
    },
    addStudent(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
        id: newTag,
      };
      this.StudentOptions.push(tag);
      this.selectedStudents.push(tag);
    },

    async getInstitutionFaculties() {
      this.faculty = [];
      this.faculty = this.faculties;
      if (this.courseUsers.length !== 0) {
        await this.faculty.forEach((existing, existingIndex) => {
          this.courseUsers.forEach((existingUser) => {
            if (
              existing.id == existingUser.pivot.institution_user_id &&
              existingUser.pivot.courserole_id == 1
            ) {
              this.faculty.splice(existingIndex, 1);
            }
          });
        });
      }
      await this.faculty.forEach((staff) => {
        this.facultyOptions.push({
          name: staff.first_name + " " + (staff.last_name || "") + `(${staff.email})`,
          id: staff.id,
          code: staff.id,
        });
      });

      await this.facultyOptions.sort((a, b) => a.name.localeCompare(b.name));
    },
    createCourseStudents() {
      const url = this.$store.getters.getAPIKey.instituitionStudents;
      axios
        .get(url)
        .then((response) => {
          this.students = response.data;
          if (this.courseUsers.length !== 0) {
            this.students.forEach((existing, existingIndex) => {
              this.courseUsers.forEach((existingUser) => {
                if (
                  existing.id == existingUser.pivot.institution_user_id &&
                  existingUser.pivot.courserole_id == 2
                ) {
                  this.students.splice(existingIndex, 1);
                }
              });
            });
          }
          this.students.forEach((staff) => {
            this.StudentOptions.push({
              name: staff.first_name + " " + (staff.last_name || ""),
              id: staff.id,
              code: staff.id,
            });
          });
          // console.log(response);
        })
        .catch(() => {
          // console.log(error.response);
        });
    },

    deleteCourseUser(userId, userIndex, institutionUserId) {
      this.postCourses = true;
      this.courseBtn.disabled = true;
      let userUrl = this.$store.getters.getAPIKey.deleteCourseUser;
      let url = userUrl.replace("course_user_id", userId);
      axios
        .delete(url)
        .then(() => {
          this.$parent.courseUsers.splice(userIndex, 1);
          this.courseBtn.disabled = false;
          this.postCourses = false;
          this.$toast.success("User Deleted!", {
            position: "top",
            duration: 3000,
          });
          if (this.studentsData[institutionUserId]) {
            this.StudentOptions.push(this.studentsData[institutionUserId].data);
          }
          // console.log(response);
        })
        .catch(() => {
          this.postCourses = false;
          this.$toast.error("Something went wrong, Please try again!", {
            position: "top",
            duration: 3000,
          });
          // console.log(error.reponse);
        });
    },
    clearResponse() {
      this.courseBtn.text = "Submit";
      this.postCourses = true;
      setTimeout(() => {
        this.responseErrors = {};
        this.courseBtn.disabled = false;
      }, 5000);
    },
    cancel() {
      this.$emit("updateClosed");
      this.responseErrors = {};
      this.groupIds = null;
      this.selectedFaculty = [];
      this.facultyOptions = [];
      this.selectedStudents = [];
      this.StudentOptions = [];
      this.$parent.addCourse = false;
      this.$parent.courseDetails = {};
      this.courseDetails = {};
      this.groupDetails = [];
    },
  },
};
</script>

<style >
.createCourse form p {
  font-size: 16px;
  font-weight: 600;
}

.createCourse .existingInstructors h5 {
  font-size: 16px;
  font-weight: 600;
}

.createCourse .existingInstructors h5,
.createCourse .existingStudents h5 {
  font-size: 16px;
  font-weight: 600;
}

.createCourse .existingInstructors p,
.createCourse .existingStudents p {
  font-size: 10px;
  background-color: #41b883;
}
</style>
