var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"assessmentsMobileView",staticStyle:{"background-color":"#ededed"}},[_c('b-list-group',[(_vm.exams.length == 0)?_c('b-list-group-item',[_c('p',{staticClass:"text-danger"},[_vm._v("No Exams!")])]):_vm._l((_vm.exams),function(exam,examIndex){return _c('b-list-group-item',{key:examIndex,class:examIndex == 0 ? '' : 'mt-3',staticStyle:{"boder":"1px solid #add8e6"},style:(examIndex % 2 === 0
            ? { backgroundColor: '#F0F0F0' }
            : { backgroundColor: '#FFFFFF' })},[_c('div',{staticClass:"exam"},[_c('div',{staticClass:"nameAndSettings d-flex justify-content-between"},[_c('p'),_c('p',{staticClass:"text-center",style:(exam.hidden && _vm.studentAssessmentAccess
                  ? { display: 'none' }
                  : '')},[_vm._v(" "+_vm._s(exam.name)+" "),_c('br'),_c('span',{style:(exam.hidden && _vm.studentAssessmentAccess
                    ? { display: 'none' }
                    : '')},[_vm._v(" "+_vm._s(exam.startAt1.format("DD/MM/YYYY"))+" ")])]),_c('div',[(_vm.instructorAssessmentAccess)?_c('b-dropdown',{staticStyle:{"box-shadow":"none","outline":"none"},attrs:{"variant":"link","dropleft":"","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('span',{staticClass:"text-info"},[_c('i',{staticClass:"fas fa-ellipsis-v"})])]},proxy:true}],null,true)},[_c('b-dropdown-item',[(!exam.hidden)?_c('span',{staticClass:"btn p-0 m-0 text-info",on:{"click":function($event){return _vm.$emit('examVisibility', {
                        id: exam.id,
                        status: true,
                      })}}},[_vm._v(" Show Exam for students. ")]):_c('span',{staticClass:"btn p-0 m-0 text-info",on:{"click":function($event){return _vm.$emit('examVisibility', {
                        id: exam.id,
                        status: false,
                      })}}},[_vm._v(" Hide Exam for students. ")])]),_c('b-dropdown-item',[_c('span',{staticClass:"text-info btn m-0 p-0",on:{"click":function($event){return _vm.$emit('updateExam', exam.id)}}},[_vm._v(" Edit ")])])],1):_vm._e()],1)]),_c('div',{staticClass:"codeAndCreation d-flex justify-content-between",style:(exam.hidden && _vm.studentAssessmentAccess
                ? { display: 'none' }
                : '')},[_c('p',[_vm._v(" Code: "),_c('br'),_vm._v(" "+_vm._s(exam.code)+" ")]),_c('p',[_vm._v(" Created At: "),_c('br'),_vm._v(" "+_vm._s(exam.createdAt1.format("DD/MM/YYYY"))+" ")])]),_c('div',{staticClass:"startAndEndTime d-flex justify-content-between",style:(exam.hidden && _vm.studentAssessmentAccess
                ? { display: 'none' }
                : '')},[_c('p',[_vm._v(" Start: "),_c('br'),_vm._v(" "+_vm._s(exam.startAt1.format("hh:mm A"))+" ")]),_c('p',[_vm._v(" End: "),_c('br'),_vm._v(" "+_vm._s(exam.endAt1.format("hh:mm A"))+" ")])]),_c('hr'),_c('div',{staticClass:"d-flex justify-content-between"},[(_vm.instructorAssessmentAccess)?_c('b-button',{attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.assessmentLink(("/questions/" + (exam.id)))}}},[_vm._v("Add Ques")]):_vm._e(),(
                exam.activity_type == 'tutorial' && _vm.instructorAssessmentAccess
              )?_c('b-button',{attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.linkAttendance(exam.id)}}},[_vm._v("Attendance")]):_vm._e(),(_vm.studentAssessmentAccess)?_c('b-button',{attrs:{"disabled":_vm.today <= exam.startAt1 || _vm.today >= exam.endAt1,"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.assessmentLink(("/answers/" + (exam.id)))}}},[_vm._v("Start Exam")]):_vm._e(),(_vm.instructorAssessmentAccess)?_c('b-button',{attrs:{"disabled":_vm.today < exam.endAt1,"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.assessmentLink(("/marks/" + (exam.id)))}}},[_vm._v("Evaluate")]):_vm._e(),(_vm.studentAssessmentAccess)?_c('b-button',{attrs:{"disabled":_vm.today <= exam.endAt1,"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.assessmentLink(("/evaluate/" + (exam.id)))}}},[_vm._v("View Score")]):_vm._e()],1)])])})],2)],1),_c('b-modal',{attrs:{"id":"activityAttendancesModel","no-close-on-backdrop":"","no-close-on-keyboard":"","no-close-on-esc":"","hide-footer":"","centered":"","size":"sm","lazy":""}},[_c('activity-attendances',{attrs:{"lazy":"","model_id":_vm.activityIdForAttendance}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }