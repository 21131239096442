<template>
  <div class="container">
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div v-if="!fetchData">
      <h3 style="text-align: center; font-size:18px !important;">
        Manage Activity Participants
      </h3>
      <b-row>
        <b-col sm="12">
          <table class="table  table-striped table-bordered">
            <tr>
              <th></th>
              <th>
                Roll no
              </th>
              <th>
                Name
              </th>
            </tr>
            <tr v-for="(student, attKey) in students" :key="attKey">
              <td>
                <b-spinner style="text-align: center;" v-if="loadData" label="Spinning"></b-spinner>
                <b-form-checkbox v-else @change="syncExamParticipants(student.institution_user_id)"
                  :checked="participantIds.includes(student.institution_user_id)"></b-form-checkbox>
              </td>
              <td>{{ student.roll_number }}</td>
              <td>{{ student.first_name }}{{ student.last_name }}</td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["exam_id", "course_id"],
  async created() {
    this.fetchData = true;
    await Promise.all([
      this.getParticipants(),
      this.getCourseUsers(),
    ]);
    this.fetchData = false;
  },
  data() {
    return {
      fetchData: true,
      loadData: false,
      students: [],
      participantIds: [],
    };
  },
  methods: {
    async syncExamParticipants(participant_id) {
      this.loadData = true;
      if (this.participantIds.includes(participant_id)) {
        this.participantIds.splice(this.participantIds.indexOf(participant_id), 1);
      } else {
        this.participantIds.push(participant_id)
      }
      const url = this.$store.getters.getAPIKey.mainAPI + `/exams/${this.exam_id}/examparticipants/sync`;
      await axios
        .post(url, { institution_users: this.participantIds })
        .then(() => {
          this.$toast.success("Updated successfully", {
            position: "top",
            duration: 3000,
          });
        })
        .catch(() => {
          this.$toast.error("Failed to Update/ Student already submitted answer", {
            position: "top",
            duration: 5000,
          });
          if (this.participantIds.includes(participant_id)) {
            this.participantIds.splice(this.participantIds.indexOf(participant_id), 1);
          } else {
            this.participantIds.push(participant_id)
          }
        });

      this.loadData = false;
    },

    async getParticipants() {
      const url = this.$store.getters.getAPIKey.mainAPI + `/exams/${this.exam_id}/examparticipants`;
      await axios
        .get(url)
        .then((response) => {
          let participants = response.data;
          participants.forEach((participant) => {
            this.participantIds.push(participant.id);
          });
        })
        .catch(() => {
          this.$toast.error("Failed to load", {
            position: "top",
            duration: 3000,
          });
        });
    },

    async getCourseUsers() {
      const url = this.$store.getters.getAPIKey.mainAPI + `/course/${this.course_id}/students`;
      await axios
        .get(url)
        .then((response) => {
          this.students = response.data[0];
        })
        .catch(() => {
          this.$toast.error("Failed to load", {
            position: "top",
            duration: 3000,
          });
        });
    },
  }
}
</script>

<style >
.tag-button button {
  font-size: 9px !important;
  padding: 3px;
}
</style>
