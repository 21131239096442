<template>
  <div>
    <b-sidebar id="instituteSidebar" title="Institutes" shadow>
      <div class="px-3 py-2 h-100 d-flex flex-column justify-content-between">
        <div>
          <b-list-group
            v-for="(IinstitutionUser, index) in myIinstitutionEnrollmets"
            :key="index"
          >
            <b-list-group-item
              @click="$emit('defaultInst', IinstitutionUser.id)"
              href="#"
            >
              <div class="w-100">
                <div class="d-flex justify-content-between align-items-baseline">
                  <p>{{ IinstitutionUser.institution.name }}</p>

                  <p
                    v-if="IinstitutionUser.id == defaultInstitutionUserId"
                    class="text-dark"
                  >
                    Default
                  </p>
                </div>
                <div class="d-flex align-items-center">
                  <b-badge
                    variant="info"
                    v-if="IinstitutionUser.institution.type == 'virtual'"
                    >{{ IinstitutionUser.institution.type }}</b-badge
                  >
                  <b-badge pill variant="primary" class="ml-auto">{{
                    IinstitutionUser.institution_role.name
                  }}</b-badge>
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
        </div>
        <div class="createInstitute">
          <div id="instituteStatus" class="p-0 m-0">
            <b-button
              variant="primary"
              size="sm"
              :disabled="instituteApprovalPending"
              @click="$emit('addInstitute')"
              >Create Institute</b-button
            >
            <b-popover
              target="instituteStatus"
              triggers="hover"
              placement="top"
              v-if="instituteApprovalPending"
            >
              <p>Cannot create new institute since one institute is in pending state</p>
            </b-popover>
          </div>
        </div>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
export default {
  props: [
    "myIinstitutionEnrollmets",
    "instituteApprovalPending",
    "defaultInstitutionUserId",
  ],
  data() {
    return {};
  },
};
</script>
