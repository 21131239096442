<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="attcah" v-else>
      <b-form @submit.prevent="attachUsergroupsToMaingroup()">
        <div class="d-flex justify-content-between flex-wrap">
          <b-form-group :label="'Select ' + getUsergroupName + 's'">
            <multiselect
              v-model="usergroups"
              deselect-label="Can't remove this value"
              track-by="id"
              label="name"
              :placeholder="'Select ' + getUsergroupName"
              :options="usergroupsOptions"
              :searchable="true"
              :allow-empty="true"
              :close-on-select="false"
              :multiple="true"
              style="width: 350px"
              :disabled="submitBtn.disabled"
            >
              <template slot="singleLabel" slot-scope="{ option }"
                ><strong>{{ option.name }}</strong></template
              >
            </multiselect>
          </b-form-group>
          <b-form-group :label="'Select ' + getMaingroupName">
            <multiselect
              v-model="mainGroup"
              deselect-label="Can't remove this value"
              track-by="id"
              label="name"
              :placeholder="'Select ' + getMaingroupName"
              :options="mainGroupOptions"
              :searchable="false"
              :allow-empty="true"
              :close-on-select="true"
              :multiple="false"
              style="width: 350px"
              :disabled="submitBtn.disabled"
            >
              <template slot="singleLabel" slot-scope="{ option }"
                ><strong>{{ option.name }}</strong></template
              >
            </multiselect>
          </b-form-group>
        </div>
        <b-form-group>
          <div class="d-flex justify-content-between">
            <b-button
              variant="primary"
              size="sm"
              type="submit"
              :disabled="submitBtn.disabled"
              v-if="usergroups.length != 0 && mainGroup != null"
              >{{ submitBtn.text }}</b-button
            >
            <b-button
              @click="$emit('backToGroups')"
              variant="danger"
              size="sm"
              :disabled="submitBtn.disabled"
              >Cancel</b-button
            >
          </div>
        </b-form-group>
      </b-form>
    </div>
  </div>
</template>

<script>
export default {
  props: ["getCustomNames", "mainGroups"],
  async created() {
    this.fetchData = true;
    await this.mainGroups.forEach(({ id, name, code }) => {
      this.mainGroupOptions.push({
        id,
        name: name + " (" + code + ")",
      });
    });
    await this.getUnattchedUsersubgroups();
    this.fetchData = false;
  },

  data() {
    return {
      fetchData: false,
      usergroupsOptions: [],
      usergroups: [],
      mainGroupOptions: [],
      mainGroup: null,
      submitBtn: {
        text: "Submit",
        disabled: false,
      },
    };
  },
  computed: {
    getUsergroupName() {
      if (this.getCustomNames.userGroup.custom_name != null) {
        return this.getCustomNames.userGroup.custom_name;
      } else {
        return "Usergroup";
      }
    },
    getMaingroupName() {
      if (this.getCustomNames.mainGroup.custom_name != null) {
        return this.getCustomNames.mainGroup.custom_name;
      } else {
        return "Usergroup";
      }
    },
  },
  methods: {
    async getUnattchedUsersubgroups() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/maingroups/unattached/usergroups`;
      await this.$axios.get(url).then((res) => {
        res.data.forEach(({ id, name, code }) => {
          this.usergroupsOptions.push({
            id,
            name: name + " (" + code + ")",
          });
        });
      });
    },
    async attachUsergroupsToMaingroup() {
      this.submitBtn = { text: "Please wait...", disabled: true };
      const postData = { usergroups: [] };
      await this.usergroups.forEach(({ id }) => {
        postData.usergroups.push(id);
      });
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/maingroups/${this.mainGroup.id}/attach/usergroup`;
      await this.$axios
        .post(url, postData)
        .then((res) => {
          this.$toast.success(
            `${this.getUsergroupName} attached successfully`,
            { position: "top", duration: 3000 }
          );
          this.$emit("adminsUpdated");
          res;
        })
        .catch((err) => {
          this.$toast.error(`Unable to attach ${this.getUsergroupName}`, {
            position: "top",
            duration: 3000,
          });
          err;
        });
      this.submitBtn = { text: "Submit", disabled: false };
    },
  },
};
</script>
