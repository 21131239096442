<template>
  <div class="container">
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div v-if="!fetchData">
      <b-row class="control-buttons">
        <b-col v-if="resourceaction == 'list'" style="text-align:left;">
          <b-button variant="success" class="text-white sm" size="sm" @click="createEditPo(null)">Add
            PO</b-button>
          <b-button variant="success" class="ml-2 text-white sm" size="sm" @click="downloadPdf()">Download
            PDF</b-button>
        </b-col>
        <b-col v-if="(resourceaction == 'createEdit' && po.id == null && !enableImport && !enablePoCopy)"
          style="text-align:left;">
          <b-row>
            <b-button variant="success" class="mr-2 text-white sm" size="sm" @click="(enableImport = true)">Import
              Excel</b-button>
            <b-button variant="success" class="mr-2 text-white sm" size="sm" @click="getCopyPoOptions">Copy
            From Other Programmes </b-button>
          </b-row>
        </b-col>
        <b-col v-if="resourceaction != 'list'" style="text-align:right;">
          <b-button variant="danger" class="text-white sm" size="sm" @click="resourceaction = 'list'"
            :disabled="actionButton.disabled">Cancel</b-button>
        </b-col>
      </b-row>
      <div v-if="resourceaction == 'list'">
        <h3 style="text-align: center; font-size:18px !important;">
          Programme Outcomes
        </h3>
        <b-row>
          <b-col sm="12">
            <div class="table-responsive rounded">
              <table class="table table-striped table-bordered">
                <thead class="thead-dark text-left">
                  <tr>
                    <th>
                      SI. No.
                    </th>
                    <th>
                      Code
                    </th>
                    <th>
                      Outcome
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(po, reindex) in pos" :key="reindex">
                    <td>{{ po.si_no }}</td>
                    <td>
                      {{ po.code }}
                    </td>
                    <td>{{ po.outcome }}</td>
                    <td>
                      <span class="text-info btn m-0 p-0 sm" size="sm" @click="createEditPo(reindex)"
                        :disabled="actionButton.disabled"><i class="fas fa-pen ml-3"></i></span>
                      <span class="text-info btn m-0 p-0 sm" size="sm" @click="deletePo(reindex)"
                        :disabled="actionButton.disabled"><i style="color:red;" class="fas fa-trash ml-3"></i></span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-if="resourceaction == 'createEdit'">
        <div v-if="enableImport">
          <h3 style="text-align: center; font-size:18px !important;">
            Import PO From Xcel File
          </h3>
          <img src="../../assets/images/poExcelImport.jpeg" alt="" style="width: 90%;" class="img-fluid" />
          <p>Fill xcel file with serial number(first column) then PO Name(second column) and Outcome Description(third column).</p>
          <b-form-file class="mb-2" v-model="xcelImportFile" :state="Boolean(xcelImportFile)"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .csv"
            placeholder="Choose an xcel file or drop it here..." drop-placeholder="Drop file here..."
            @change="importPoFromXcel"></b-form-file>
          <div>
            <div v-for="importPo in importPOs" :key="importPo.code" class=" border border-success m-3 p-2">
              <b-row>
                <b-col sm="6">
                  <b-form-group id="input-group-1" label-for="input-1" description="">
                    <label class="required-label" for="">Si. No.:</label>
                    <b-form-input id="input-1" v-model="importPo.si_no" type="text"
                      placeholder="Enter a Number"></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group id="input-group-2" label-for="input-2" description="">
                    <label class="required-label" for="">PO Name:</label>
                    <b-form-input id="input-2" v-model="importPo.code" type="text"
                      placeholder="PO 1"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group id="input-group-1" label-for="input-1" description="">
                    <label class="required-label" for="">PO Description:</label>
                    <b-form-textarea v-model="importPo.outcome" placeholder="Description" rows="3"
                      max-rows="6"></b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-row> 
            </div>

            <b-row v-if="(importPOs.length > 0)">
              <b-col sm="12" style="text-align:right;">
                <b-button variant="success" class="text-white sm" size="sm" @click="createImportedPo"
                  :disabled="actionButton.disabled">{{ actionButton.text }}</b-button>
              </b-col>
            </b-row>
          </div>
        </div>
        <div v-else-if="enablePoCopy">
          <h3 style="text-align: center; font-size:18px !important;">
            Copy PO from other Programme
          </h3>
          <b-form-group>
            <label class="required-label" for="">Choose Programme:</label>
            <b-form-select @change="getUsergroupPosForPoCopy" :options="copyPoUsergroups" v-model="copyPoUsergroup_id">
            </b-form-select>
            <span v-if="(copyPoUsergroups.length < 1)" class="text-danger">Loading...</span>
          </b-form-group>
          <ul v-if="copyPoUsergroup_id">
            <span class="text-danger" v-if="(copyFromPoOptions.length < 1)">PO list is empty on selected
              programme</span>
            <li v-for="poOption in copyFromPoOptions" :key="poOption.id">
              <b-form-checkbox v-model="copyFromPoIds" name="checkbox-pocopyoption" :value="poOption.id">
                {{ poOption.code }}-{{ poOption.outcome }}
              </b-form-checkbox>
            </li>

          </ul>
          <b-row>
            <b-col sm="12" style="text-align:right;">
              <b-button variant="success" class="text-white sm" size="sm" @click="submitCopyPo"
                :disabled="(copyFromPoIds.length < 1)">{{ actionButton.text }}</b-button>
            </b-col>
          </b-row>
        </div>
        <div v-else>
          <h3 style="text-align: center; font-size:18px !important;">
            {{ po.id ? 'Edit PO' : 'Add new PO' }}
          </h3>
          <b-row>
            <b-col sm="6">
              <b-form-group id="input-group-1" label-for="input-1" description="">
                <label class="required-label" for="">Si. No.:</label>
                <b-form-input id="input-1" v-model="po.si_no" type="text" placeholder="Enter a Number"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group id="input-group-2" label-for="input-2" description="">
                <label class="required-label" for="">PO Name:</label>
                <b-form-input id="input-2" v-model="po.code" type="text"
                  placeholder="PO 1"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group id="input-group-1" label-for="input-1" description="">
                <label class="required-label" for="">PO Description:</label>
                <b-form-textarea v-model="po.outcome" placeholder="Description" rows="3"
                  max-rows="6"></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" style="text-align:right;">
              <b-button variant="success" class="text-white sm" size="sm" @click="createUpdatePo"
                :disabled="actionButton.disabled">{{ actionButton.text }}</b-button>
            </b-col>
          </b-row>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import readXlsxFile from "read-excel-file";
import axios from "axios";
export default {
  props: ['usergroup_id'],
  async created() {
    this.getPos();
  },
  components: {},
  data() {
    return {
      fetchData: false,
      resourceaction: "list", // list,createEdit
      pos: [],
      po: {
        id: null,
        si_no: '',
        name: "",
        code: '',
        outcome: "",
        usergroup_id: '',
      },
      actionButton: {
        text: "Save",
        disabled: false,
      },

      importPOs: [],
      enableImport: false,
      xcelImportFile: null,

      enablePoCopy: false,
      copyPoUsergroups: [],
      copyPoUsergroup_id: null,
      copyFromPoOptions: [],
      copyFromPoIds: [],
    };
  },
  methods: {

    async submitCopyPo() {
      this.fetchData = true;
      this.actionButton = {
        text: "Saving...",
        disabled: true,
      };
      let url = this.$store.getters.getAPIKey.mainAPI + `/programmeoutcomes/copy`;

      await axios.post(url, { programme_outcomes: this.copyFromPoIds, copy_to_usergroup: this.usergroup_id })
        .then(() => {
          this.$toast.success("PO copied successfully", {
            position: "top",
            duration: 3000,
          });
          this.getPos();
        })
        .catch(() => {
          let errortxt = "Failed to Copy PO";
          this.$toast.error(errortxt, {
            position: "top",
            duration: 7000,
          });
          return;
        });

      this.fetchData = false;
      this.actionButton = {
        text: "Save",
        disabled: false,
      };
    },
    async getCopyPoOptions() {
      this.copyFromPoOptions = [];
      this.copyFromPoIds = [];
      this.copyPoUsergroups = [];
      this.copyPoUsergroup_id = null;
      this.enablePoCopy = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usergroups?allusergroups=1`;
      await this.$axios
        .get(url)
        .then((response) => {
          response.data.forEach(group => {
            this.copyPoUsergroups.push({
              value: group.id, text: `${group.code}-${group.name}-${group.scheme}-${group.affiliated_university}`
            });
          });
        })
        .catch((error) => {
          error;
        });

      this.actionButton.text = 'Start Copy';
    },

    async getUsergroupPosForPoCopy() {
      this.fetchData = true;
      this.copyFromPoOptions = [];
      this.copyFromPoIds = [];
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/programmeoutcomes?usergroup=${this.copyPoUsergroup_id}`;
      await this.$axios
        .get(url)
        .then((response) => {
          response.data.forEach(po => {
            this.copyFromPoOptions.push(po);
            this.copyFromPoIds.push(po.id);
          });
        })
        .catch((error) => {
          error;
        });
      this.fetchData = false;
    },

    async createImportedPo() {
      this.fetchData = true;
      this.actionButton = {
        text: "Saving...",
        disabled: true,
      };
      let url = this.$store.getters.getAPIKey.mainAPI + `/programmeoutcomes`;
      let failedImportPOs = [];
      for (let index = 0; index < this.importPOs.length; index++) {
        const po = this.importPOs[index];
        await axios.post(url, po)
          .then(() => {
            this.$toast.success(po.code + " created successfully", {
              position: "top",
              duration: 3000,
            });
          })
          .catch((error) => {
            failedImportPOs.push(po);
            let errortxt = "";
            if (error.response)
              if (error.response.status == 422) {
                let errormsgs = error.response.data.errors;
                for (const key in errormsgs) {
                  errortxt = errormsgs[key][0];
                }
              } else {
                errortxt = error.response.data.message;
              }
            else {
              errortxt = "Failed to Update/Create";
            }
            this.$toast.error(errortxt, {
              position: "top",
              duration: 7000,
            });
            return;
          });
      }

      this.fetchData = false;
      this.actionButton = {
        text: "Save",
        disabled: false,
      };
      if (failedImportPOs.length < 1) this.getPos();
      this.importPOs = failedImportPOs;
    },
    async importPoFromXcel(e) {
      if (e.target.files[0] == null) return;

      let error = false;
      let xcelSheet = null;
      await readXlsxFile(e.target.files[0]).then((sheet) => {
        xcelSheet = sheet;
      }).catch(() => {
        this.$toast.error('Failed to process the input xcel file', {
          position: "top",
          duration: 6000,
        });
        error = true;
      });
      if (xcelSheet.length < 1) {
        this.$toast.error('Excel has empty, please fill it.', {
          position: "top",
          duration: 6000,
        });
        error = true;
      }
      if (error) return;
      console.log();
      await xcelSheet.forEach((sheetRow, rowIndex) => {
        if (sheetRow.length != 3) {
          this.$toast.error('Number of column in each row should be 3. Row ' + (rowIndex + 1) + ' has more or less column used', {
            position: "top",
            duration: 6000,
          });
          error = true;
        } else {
          this.importPOs.push({
            si_no: sheetRow[0],
            code: sheetRow[1],
            outcome: sheetRow[2],
            usergroup_id: this.usergroup_id,
          });
        }
      });
      if (this.importPOs.length > 0) this.$toast.warning(this.importPOs.length + ' POs are imported from excel, please verify and submit', {
        position: "top",
        duration: 6000,
      });
      this.xcelImportFile = null;
    },
    async createEditPo(index) {
      this.enableImport = false;
      this.enablePoCopy = false;
      this.importPOs = [];

      this.po = {
        id: null,
        si_no: this.pos.length + 1,
        name: "",
        code: '',
        outcome: "",
        usergroup_id: this.usergroup_id,
      };
      if (index !== null) this.po = this.pos[index];

      this.resourceaction = 'createEdit';
    },
    async getPos() {
      this.fetchData = true;
      this.resourceaction = 'list';
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/programmeoutcomes?usergroup=${this.usergroup_id}`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.pos = response.data;
        })
        .catch((error) => {
          error;
        });
      this.fetchData = false;
    },
    async createUpdatePo() {
      this.fetchData = true;
      this.actionButton = {
        text: "Saving...",
        disabled: true,
      };
      let url = this.$store.getters.getAPIKey.mainAPI + `/programmeoutcomes`;
      let action = 'post';
      if (this.po.id) {
        url += `/${this.po.id}`;
        action = 'put';
      }

      await axios[action](url, this.po)
        .then(() => {
          this.$toast.success("PO created successfully", {
            position: "top",
            duration: 3000,
          });
          this.getPos();
        })
        .catch((error) => {
          let errortxt = "";
          if (error.response)
            if (error.response.status == 422) {
              let errormsgs = error.response.data.errors;
              for (const key in errormsgs) {
                errortxt = errormsgs[key][0];
              }
            } else {
              errortxt = error.response.data.message;
            }
          else {
            errortxt = "Failed to Update/Create";
          }
          this.$toast.error(errortxt, {
            position: "top",
            duration: 7000,
          });
          return;
        });

      this.fetchData = false;
      this.actionButton = {
        text: "Save",
        disabled: false,
      };
    },

    async deletePo(index) {
      var result = confirm("Deleting PO will remove the PO from Programme exist survey questions and CO-PO Mapping. Are you sure you want to proceed ? ");
      if (result) {
        this.fetchData = true;
        this.actionButton = {
          text: "Saving...",
          disabled: true,
        };

        let url = this.$store.getters.getAPIKey.mainAPI + `/programmeoutcomes/${this.pos[index].id}`;

        await axios.delete(url)
          .then(() => {
            this.$toast.success("Deleted successfully", {
              position: "top",
              duration: 3000,
            });
            this.pos.splice(index, 1);
          })
          .catch(() => {
            let errortxt = "Failed to Delete";
            this.$toast.error(errortxt, {
              position: "top",
              duration: 3000,
            });
          });

        this.actionButton = {
          text: "Save",
          disabled: false,
        };
        this.fetchData = false;
      }
    },

    async downloadPdf() {
      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/programmeoutcomes/report/pdfexport`;
      await this.$axios
        .post(url, { usergroup: this.usergroup_id }, { responseType: "blob" })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "ProgrammeOutcome.pdf");
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch((error) => {
          let errorText = "Failed to Download, please try later";
          if (error.response != undefined)
            errorText = error.response.message;
          this.$toast.error(errorText, {
            position: "top",
            duration: 6000,
          });
        })
      this.fetchData = false;
    },
  },
};
</script>

<style >
.tag-button button {
  font-size: 9px !important;
  padding: 3px;
}
</style>
