<template>
  <div>
    <div class="assessmentsMobileView" style="background-color: #ededed">
      <b-list-group>
        <b-list-group-item v-if="exams.length == 0">
          <p class="text-danger">No Exams!</p>
        </b-list-group-item>
        <b-list-group-item
          v-else
          v-for="(exam, examIndex) in exams"
          :key="examIndex"
          :style="
            examIndex % 2 === 0
              ? { backgroundColor: '#F0F0F0' }
              : { backgroundColor: '#FFFFFF' }
          "
          style="boder: 1px solid #add8e6"
          :class="examIndex == 0 ? '' : 'mt-3'"
        >
          <div class="exam">
            <div class="nameAndSettings d-flex justify-content-between">
              <p></p>
              <p
                :style="
                  exam.hidden && studentAssessmentAccess
                    ? { display: 'none' }
                    : ''
                "
                class="text-center"
              >
                {{ exam.name }} <br />
                <span
                  :style="
                    exam.hidden && studentAssessmentAccess
                      ? { display: 'none' }
                      : ''
                  "
                >
                  {{ exam.startAt1.format("DD/MM/YYYY") }}
                </span>
              </p>
              <div>
                <b-dropdown
                  v-if="instructorAssessmentAccess"
                  variant="link"
                  dropleft
                  toggle-class="text-decoration-none"
                  no-caret
                  style="box-shadow: none; outline: none"
                >
                  <template #button-content>
                    <span class="text-info"
                      ><i class="fas fa-ellipsis-v"></i>
                    </span>
                  </template>
                  <b-dropdown-item>
                    <span
                      v-if="!exam.hidden"
                      @click="
                        $emit('examVisibility', {
                          id: exam.id,
                          status: true,
                        })
                      "
                      class="btn p-0 m-0 text-info"
                    >
                      Show Exam for students.
                    </span>
                    <span
                      @click="
                        $emit('examVisibility', {
                          id: exam.id,
                          status: false,
                        })
                      "
                      class="btn p-0 m-0 text-info"
                      v-else
                    >
                      Hide Exam for students.
                      <!-- <i class="fas fa-eye-slash"></i> -->
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item>
                    <span
                      class="text-info btn m-0 p-0"
                      @click="$emit('updateExam', exam.id)"
                    >
                      <!-- <i class="far fa-edit"></i> -->
                      Edit
                    </span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>

            <div
              class="codeAndCreation d-flex justify-content-between"
              :style="
                exam.hidden && studentAssessmentAccess
                  ? { display: 'none' }
                  : ''
              "
            >
              <p>
                Code: <br />
                {{ exam.code }}
              </p>
              <p>
                Created At: <br />
                {{ exam.createdAt1.format("DD/MM/YYYY") }}
              </p>
            </div>
            <div
              class="startAndEndTime d-flex justify-content-between"
              :style="
                exam.hidden && studentAssessmentAccess
                  ? { display: 'none' }
                  : ''
              "
            >
              <p>
                Start: <br />
                {{ exam.startAt1.format("hh:mm A") }}
              </p>
              <p>
                End: <br />
                {{ exam.endAt1.format("hh:mm A") }}
              </p>
            </div>
            <hr />
            <div class="d-flex justify-content-between">
              <b-button
                v-if="instructorAssessmentAccess"
                variant="primary"
                size="sm"
                @click="assessmentLink(`/questions/${exam.id}`)"
                >Add Ques</b-button
              >
              <b-button
                variant="primary"
                size="sm"
                @click="linkAttendance(exam.id)"
                v-if="
                  exam.activity_type == 'tutorial' && instructorAssessmentAccess
                "
                >Attendance</b-button
              >
              <b-button
                :disabled="today <= exam.startAt1 || today >= exam.endAt1"
                v-if="studentAssessmentAccess"
                variant="primary"
                size="sm"
                @click="assessmentLink(`/answers/${exam.id}`)"
                >Start Exam</b-button
              >
              <b-button
                :disabled="today < exam.endAt1"
                v-if="instructorAssessmentAccess"
                variant="primary"
                size="sm"
                @click="assessmentLink(`/marks/${exam.id}`)"
                >Evaluate</b-button
              >
              <b-button
                :disabled="today <= exam.endAt1"
                v-if="studentAssessmentAccess"
                variant="primary"
                size="sm"
                @click="assessmentLink(`/evaluate/${exam.id}`)"
                >View Score</b-button
              >
            </div>
          </div>
        </b-list-group-item>
      </b-list-group>
    </div>

    <b-modal
      id="activityAttendancesModel"
      no-close-on-backdrop
      no-close-on-keyboard
      no-close-on-esc
      hide-footer
      centered
      size="sm"
      lazy
    >
      <activity-attendances
        lazy
        :model_id="activityIdForAttendance"
      ></activity-attendances>
    </b-modal>
  </div>
</template>

<script>
// import axios from "axios";
export default {
  props: [
    "exams",
    "instructorAssessmentAccess",
    "studentAssessmentAccess",
    "today",
  ],
  data() {
    return {
      activityIdForAttendance: null,
    };
  },
  methods: {
    assessmentLink(link) {
      this.$router.push({ path: link });
    },
    linkAttendance(activityId) {
      // openModel
      this.activityIdForAttendance = activityId;
      this.$bvModal.show("activityAttendancesModel");
    },
  },
};
</script>

<style >
.assessmentsMobileView .exam p {
  font-size: 14px;
  font-weight: 600;
}

.assessmentsMobileView .dropdown button {
  border: none;
}
</style>
