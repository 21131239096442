<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="institutionMobileView" v-else>
      <b-tabs content-class="mt-3" v-model="tabIndex" justified lazy>
        <b-tab title="Staff, Student List" v-if="usersList"
          >This tab is only available in web view.</b-tab
        >
        <b-tab title="My Schedule" v-if="mySchedule"
          >This tab is only available in web view.</b-tab
        >
        <b-tab title="My Courses" v-if="myCourses"
          >This tab is only available in web view.</b-tab
        >
        <b-tab title="Activity" v-if="assessmentTab">
          <list-exams></list-exams>
        </b-tab>
        <b-tab title="Attendance" v-if="attendenceTab">
          <attendence-marking></attendence-marking>
        </b-tab>
        <b-tab title="Live Class" v-if="liveClassTab">
          <live-class></live-class>
        </b-tab>
        <b-tab title="Groups/Subgroups" v-if="groupList"
          >This tab is only available in web view.</b-tab
        >
        <b-tab title="Courses" v-if="viewCoursesTab"
          >This tab is only available in web view.</b-tab
        >
        <b-tab title="TimeTable" v-if="institutionTimetable"
          >This tab is only available in web view.</b-tab
        >
        <b-tab title="Attendance" v-if="studentTab"
          >This tab is only available in web view.</b-tab
        >
        <b-tab title="Instructor Schedule" v-if="instructorSchedule"
          >This tab is only available in web view.</b-tab
        >
        <b-tab title="Survey" v-if="surveyTab">
          <ListingSurveys />
        </b-tab>
        <b-tab
          :title="applicationForm ? 'Application Forms' : 'Approvals'"
          id="instructorAccess"
          v-if="viewSurveyApprovaTab"
        >
          <EventStudent v-if="applicationForm" />
          <EventTeacher v-else />
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import acl from "../../../../authorization/acl.js";
import LiveClass from "../live_class/mobile/LiveClass.vue";
import AttendenceMarking from "../attendence/AttendenceMarking.vue";
import ExamsListing from "../examination/ExamsListing.vue";
import EventTeacher from "../survey/survey_approvals/mobile_view/EventlTeacher.vue";
import EventStudent from "../survey/survey_approvals/mobile_view/EventStudent.vue";
import ListingSurveys from "../survey/ListingSurveys.vue";

export default {
  async created() {
    this.fetchData = true;
    const instituitionUsersList = await acl.isUserGranted(
      "viewInstitutionUsers"
    );
    this.usersList = instituitionUsersList;
    const groups = await acl.isUserGranted("viewGroupsSubgroupsTab");
    this.groupList = groups;
    const viewCoursesTab = await acl.isUserGranted("viewCoursesTab");
    this.viewCoursesTab = viewCoursesTab;
    const myCourses = await acl.isUserGranted("myCourses");
    this.myCourses = myCourses;
    const institutionTimetable = await acl.isUserGranted(
      "ViewInstitutionTimeTableTab"
    );
    this.institutionTimetable = institutionTimetable;
    const attendenceTab = await acl.isUserGranted("viewManageAttendanceTab");
    this.attendenceTab = attendenceTab;
    const studentTab = await acl.isUserGranted("viewStudentTab");
    this.studentTab = studentTab;
    const mySchedule = await acl.isUserGranted("mySchedule");
    this.mySchedule = mySchedule;
    const instructorSchedule = await acl.isUserGranted(
      "ViewInstructorTimeTableTab"
    );
    this.instructorSchedule = instructorSchedule;
    const assessmentTab = await acl.isUserGranted("viewAssessmentTab");
    this.assessmentTab = assessmentTab;
    const viewLiveClassTab = await acl.isUserGranted("viewLiveClassTab");
    this.liveClassTab = viewLiveClassTab;
    const viewSurveyApprovaTab = await acl.isUserGranted(
      "viewSurveyApprovalTab"
    );
    this.viewSurveyApprovaTab = viewSurveyApprovaTab;
    const viewSurveyTab = await acl.isUserGranted("viewSurveyTab");
    this.surveyTab = viewSurveyTab;
    const applicationForm = await acl.isUserGranted("SurveyApprovalStudent");
    this.applicationForm = applicationForm;
    this.fetchData = false;
  },
  props: ["selectedInstitute"],

  components: {
    liveClass: LiveClass,
    attendenceMarking: AttendenceMarking,
    listExams: ExamsListing,
    EventTeacher,
    EventStudent,
    ListingSurveys,
  },
  data() {
    return {
      fetchData: false,
      tabIndex: 0,
      usersList: null,
      groupList: null,
      viewCoursesTab: null,
      myCourses: null,
      institutionTimetable: null,
      attendenceTab: null,
      studentTab: null,
      mySchedule: null,
      instructorSchedule: null,
      assessmentTab: null,
      liveClassTab: null,
      viewSurveyApprovaTab: null,
      applicationForm: null,
      surveyTab: null,
    };
  },
};
</script>

<style >
.institutionMobileView button {
  border-bottom: 3px solid white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
}
.institutionMobileView .nav-tabs {
  flex-wrap: nowrap;
  white-space: nowrap;
  width: 100vw !important;
  height: 40px;
  overflow: auto;
  box-sizing: border-box;
}
.institutionMobileView .nav-tabs li a {
  font-size: 12px !important;
  background-color: #077bff;
  color: white;
  border: 1px solid white;
}
</style>
