<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div
      class="participantApplication p-3 border border-info rounded mt-2 bg-white"
      v-if="!fetchData"
    >
      <div class="border-0">
        <!-- <div class="print mb-2 text-right">
          <b-button variant="info" size="sm" @click="printPdf()"
            >Print</b-button
          >
        </div> -->
        <div>
          <p
            style="font-size: 16px; font-weight: 600; text-align: center"
            class="text-center bg-success text-white rounded p-1"
          >
            Name:
            {{ application.participant.first_name
            }}{{ application.participant.last_name || "" }}
          </p>
        </div>

        <br />
        <div class="d-flex justify-content-between flex-wrap">
          <div
            class="application mb-3 w-50"
            style="font-size: 14px; font-weight: 600"
            v-for="(question, questionIndex) in approvalQuestions"
            :key="questionIndex"
          >
            <div class="question mb-2">
              {{ question.question_no }}) {{ question.name }}
            </div>
            <div class="answer mb-2">
              <span v-if="answers[question.id].answer">{{
                answers[question.id].answer
              }}</span>
              <span class="text-danger" v-else>No Text Answer!</span>
            </div>
            <div
              class="attachments mb-2"
              v-if="answers[question.id].files.length > 0"
            >
              <h6 style="font-size: 14px; font-weight: 600">
                Attachments:
              </h6>
              <span
                class="text-dark ml-3"
                v-for="(file, fileIndex) in answers[question.id].files"
                :key="fileIndex"
              >
                <a
                  :href="
                    userAttachmentPrefixUrl + file.file_path + file.file_name
                  "
                  target="_blank"
                  class="text-decoration-none"
                  style="font-size: 12px; font-weight: 600"
                  >{{ file.file_name }}</a
                >
              </span>
            </div>
          </div>
        </div>

        <div>
          <div class="comments mb-2">
            <b-form-input
              placeholder="Enter Comments"
              v-model="approvalComment"
            ></b-form-input>
          </div>
          <div class="approveOrReject d-flex justify-content-between">
            <b-button
              variant="success"
              size="sm"
              @click="
                approvalType = 'approved';
                applicationtakeAction();
              "
              >Approve</b-button
            >

            <b-button
              variant="warning"
              size="sm"
              @click="
                approvalType = 'resubmission';
                applicationtakeAction();
              "
              class="text-white"
              >Ask to Resubmit</b-button
            >
            <b-button variant="primary" size="sm" @click="closeAppModel"
              >Close</b-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["appId", "approverId"],
  async created() {
    this.fetchData = true;
    this.userAttachmentPrefixUrl = await this.$store.getters.getAPIKey
      .userAttachments;
    await this.getApplicationDetails();
    await this.getapprovalQuestions();

    // preapre w answers

    await this.approvalQuestions.forEach((ques) => {
      this.answers[ques.id] = {
        answer: "",
        files: [], // file.file_path, file.file_name
      };
    });
    await this.application.answers.forEach((ans) => {
      this.answers[ans.question_id].answer = ans.answer;
      this.answers[ans.question_id].files =
        ans.files.length > 0 ? ans.files : [];
    });
    this.fetchData = false;
  },
  components: {},
  data() {
    return {
      fetchData: false,
      userAttachmentPrefixUrl: "",
      approvalQuestions: [],
      application: null,
      answers: {}, // qId:answer:files
      approvalComment: "",
      approvalType: null, // [approved, resubmission]
    };
  },
  methods: {
    closeAppModel() {
      this.$emit("closeAppModelEvent");
    },
    async getApplicationDetails() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/onlineregapplications/${this.appId}`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.application = response.data;
        })
        .catch((error) => {
          error;
        });
    },

    async applicationtakeAction() {
      if (this.approvalType == "resubmission") {
        if (confirm(`You are going to request resubmission`) != true) return;
      }

      let postData = await {
        approver_id: this.approverId,
        comments: this.approvalComment,
        approval_status: this.approvalType,
        participant_id: this.application.participant_id
          ? this.application.participant_id
          : this.application.public_participant_id,
        onlineregistration_id: this.application.onlineregistration_id,
        onlineregapplication_id: this.application.id,
      };

      let status = null;
      const url = this.$store.getters.getAPIKey.mainAPI + `/onlineregapprovals`;
      await this.$axios
        .post(url, postData)
        .then((response) => {
          status = true;
          response;
        })
        .catch((error) => {
          status = false;
          error;
        });
      if (status) {
        this.$toast.success(`Application ${this.approvalType}`, {
          position: "top",
          duration: 3000,
        });
      }
      if (!status) {
        this.$toast.error("Something Went Wrong, please try again!", {
          position: "top",
          duration: 3000,
        });
      }

      this.$emit("closeAppModelEvent");
    },

    async getapprovalQuestions() {
      // const approvalId = this.$route.params.approval_id;
      const url =
        this.$store.getters.getAPIKey.surveyApprovals +
        `/${this.application.onlineregistration_id}/onlineregquestions`;
      await this.$axios.get(url).then((response) => {
        this.approvalQuestions = response.data;
      });
    },
  },
};
</script>

<style >
.pendingForms {
  min-height: 100vh;
}
.pendingForms .col-md-3:not(:first-child),
.pendingForms .col-md-9 {
  height: 600px;
  overflow-y: scroll;
}
.pendingForms .col-md-3 .card .card-header {
  font-size: 18px;
  font-weight: 600;
}
.pendingForms i {
  font-size: 10px !important;
}
.pendingForms .closeBtn i {
  font-size: 20px !important;
}
.pendingForms .applicationForms button {
  width: 150px;
  margin-left: 10px;
}

.pendingForms .applicationForms button:not(:first-child) {
  margin-left: 10px;
}
</style>
