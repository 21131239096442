<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="smsTransactionHistory" v-else>
      <div class="overflow-auto">
        <div class="d-flex justify-content-between">
          <b-form-input
            type="number"
            style="width: 200px"
            v-model="perPage"
            placeholder="Data Per Page"
          ></b-form-input>
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="sms-transactions"
          ></b-pagination>
          <p></p>
        </div>

        <table class="table table-bordered table-striped" id="sms-transactions">
          <thead class="thead-dark">
            <tr>
              <th>Date & Time</th>
              <th>Module</th>
              <th>SMS</th>
              <th>Sent By</th>
              <th>SMS Count</th>
              <th>Recipients</th>
              <th>Status</th>
              <th>Remarks</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(sms, smsIndex) in smsHistoryWithPagination"
              :key="smsIndex"
            >
              <td class="align-middle">
                {{ sms.created_at.format("DD/MM/YYYY") }} <br />
                {{ sms.created_at.format("hh:mm a") }}
              </td>
              <td class="align-middle">
                {{ sms.notification_type }}
              </td>

              <td class="align-middle">
                <p style="width: 300px; word-wrap: break-word">
                  {{ sms.data != null ? sms.data.message : "" }}
                </p>
              </td>
              <td class="align-middle">
                {{
                  sms.institution_user.id
                    ? sms.institution_user.first_name +
                      " " +
                      sms.institution_user.last_name
                    : ""
                }}
              </td>
              <td class="align-middle">
                {{ sms.sms_count }}
              </td>
              <td class="align-middle">
                <span
                  :id="'smsHistory' + smsIndex"
                  :class="
                    sms.recipients != null
                      ? 'text-info btn p-0. m-0'
                      : 'text-dark'
                  "
                >
                  {{ sms.recipients != null ? sms.recipients.length : "0" }}
                  Users
                </span>
                <b-popover
                  :target="'smsHistory' + smsIndex"
                  triggers="hover"
                  placement="top"
                  v-if="sms.recipients != null"
                >
                  <b-list-group
                    style="
                      height: 200px;
                      overflow-y: scroll;
                      font-size: 14px;
                      font-weight: 600;
                      padding: 10px;
                    "
                  >
                    <b-list-group-item
                      v-for="(recipient, recipientIndex) in sms.recipients"
                      :key="recipientIndex"
                      class="border border-info mt-1 rounded"
                    >
                      {{ recipient.mobile }}
                    </b-list-group-item>
                  </b-list-group>
                </b-popover>
              </td>
              <td class="align-middle">
                <span
                  class="bg-danger rounded p-1 text-white"
                  v-if="sms.status != 'success'"
                  >{{ sms.status }}</span
                >
                <span v-else>
                  <span
                    class="bg-warning rounded p-1 text-white"
                    v-if="sms.comments"
                    >{{ sms.status }}</span
                  >
                  <span class="bg-success rounded p-1 text-white" v-else>{{
                    sms.status
                  }}</span>
                </span>
              </td>

              <td class="align-middle">
                {{ sms.comments }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  async created() {
    this.fetchData = true;
    await this.getSmsTransactionHistory();
    this.fetchData = false;
  },
  data() {
    return {
      fetchData: false,
      smsHistory: [],
      perPage: 10,
      currentPage: 1,
    };
  },
  computed: {
    rows() {
      return this.smsHistory.length;
    },
    smsHistoryWithPagination() {
      return this.smsHistory.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
  },
  methods: {
    async getSmsTransactionHistory() {
      const url = this.$store.getters.getAPIKey.mainAPI + "/smstransactions";
      await this.$axios
        .get(url)
        .then((response) => {
          let history = response.data;
          history.forEach((sms) => {
            this.smsHistory.push({
              data: sms.data,
              recipients: sms.recipients,
              sms_count: sms.sms_count,
              status: sms.status,
              notification_type: sms.notification_type,
              created_at: moment(sms.created_at),
              institution_user: sms.institution_user,
              comments: sms.comments,
            });
          });

          response;
        })
        .catch((error) => {
          error;
        });
    },
  },
};
</script>
