<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="custom-report" v-else>
      <b-overlay
        :show="loadData"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="md"
      >
        <div class="addCustomRow" v-if="editState">
          <AddCustomRow
            :reportsOptions="reportsOptions"
            :templatesOptions="templatesOptions"
            :templatesObject="templatesObject"
            @cancelCustomReport="cancelCustomReport"
          />
        </div>
        <div v-else>
          <b-button
            size="sm"
            variant="primary"
            class="mb-2"
            @click="editState = !editState"
            >+ Add/Edit</b-button
          >

          <div class="table responsive">
            <table class="table table-bordered table-striped">
              <thead class="thead-dark">
                <tr class="text-left">
                  <th>Report</th>
                  <th>Template</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(report, reportIndex) in customReports" :key="reportIndex">
                  <td class="align-middle">
                    <p>
                      {{ report.report }}
                    </p>
                  </td>
                  <td class="align-middle">
                    <p>
                      {{ report.template }}
                    </p>
                  </td>
                  <td class="align-middle">
                    <span
                      class="text-danger btn p-0 m-0"
                      @click="deleteCustomReport(report.id, reportIndex)"
                    >
                      <i class="fas fa-trash"></i>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import AddCustomRow from "./AddCustomRow.vue";
export default {
  async created() {
    this.fetchData = true;
    await this.getCustomReportsOptions();
    await this.getCustomReports();
    this.fetchData = false;
  },
  components: {
    AddCustomRow,
  },
  data() {
    return {
      fetchData: false,
      loadData: false,
      customReports: [],
      existingCustomReports: [],
      customReportsOptions: [],
      reportsOptions: [],
      templatesOptions: [],
      templatesObject: {},
      editState: false,

      tempo:
        '<table class="table table-bordered table-striped"><thead class="thead-dark"><tr><th colspan="2"><img src="data:image/jpeg;base64,{{ base64_encode(@file_get_contents(url("https://picsum.photos/id/237/200/300"))) }}" alt="Italian Trulli"><strong style="font-size: 18px;">variable_instituteName</strong> </th></tr><tr><th colspan="2">variable_reportName</th></tr></thead></table>',
    };
  },
  methods: {
    cancelCustomReport(status) {
      if (status) {
        this.editState = !this.editState;
        this.getCustomReports();
      } else {
        this.editState = !this.editState;
      }
    },
    async getCustomReports() {
      this.fetchData = true;
      const url = this.$store.getters.getAPIKey.mainAPI + `/customreports`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.customReports = [];
          this.editState = false;
          const existingCustomReports = response.data;
          existingCustomReports.forEach((report) => {
            this.customReports.push({
              id: report.id,
              report: report.report,
              template: report.template,
              // variables: report.variables,
            });
          });

          response;
        })
        .catch((error) => {
          error;
        });
      this.fetchData = false;
    },
    async deleteCustomReport(reportId, reportIndex) {
      this.loadData = true;
      const url = this.$store.getters.getAPIKey.mainAPI + `/customreports/${reportId}`;
      await this.$axios
        .delete(url)
        .then((response) => {
          this.customReports.splice(reportIndex, 1);
          this.$toast.success("Removed Successfully", {
            position: "top",
            duration: 3000,
          });
          response;
        })
        .catch((error) => {
          error;
        });
      this.loadData = false;
    },
    async getCustomReportsOptions() {
      const url = this.$store.getters.getAPIKey.mainAPI + `/customreports/options`;
      await this.$axios
        .get(url)
        .then((response) => {
          const reports = response.data.reports;
          const templates = response.data.templates;
          this.reportsOptions = [{ value: null, text: "Select Report" }];
          this.templatesOptions = [{ value: null, text: "Select Template" }];
          reports.forEach((report) => {
            this.reportsOptions.push({
              value: report.key,
              text: report.key,
            });
          });
          for (let template in templates) {
            this.templatesOptions.push({
              value: template,
              text: template,
            });
            const templateObject = {};
            templates[template].variables.forEach((temp) => {
              this.$set(templateObject, temp.key, {
                value: null,
                attributes: temp.attributes.length != 0 ? temp.attributes[0] : "",
              });
            });
            this.$set(this.templatesObject, template, templateObject);
          }

          response;
        })
        .catch((error) => {
          error;
        });
    },
  },
};
</script>

<style >
.custom-report i {
  font-size: 12px !important;
}
</style>
