<template>
  <div>
    <div class="loading" v-if="loadCourses">
      <load-data></load-data>
    </div>
    <div v-else>
      <b-modal id="courseReports" :title="courseReport.name" no-close-on-backdrop no-close-on-keyboard no-close-on-esc
        hide-footer centered size="lg">
        <CourseReports :getCustomNames="getCustomNames" :courseReport="courseReport" />
      </b-modal>

      <b-modal id="courseAdmins" :title="courseAdminModal.name + ': ' + 'Course Instructors'" no-close-on-backdrop
        no-close-on-keyboard no-close-on-esc hide-footer centered size="lg" @close="checkAdminStatus">
        <CourseAdmins @adminRemoved="adminRemoved" @newCourseAdminsAdded="getCourseDetails()"
          :courseAdminModal="courseAdminModal" :faculties="faculties" :getCustomNames="getCustomNames" />
      </b-modal>

      <b-modal id="courseStudents" :title="courseStudentModal.name + ': ' + 'Students'" no-close-on-backdrop
        no-close-on-keyboard no-close-on-esc hide-footer centered size="lg"
        @close="courseStudentModal.deletion ? getCourseDetails() : ''">
        <CourseStudents :courseStudentModal="courseStudentModal" @studentsRemoved="courseStudentModal.deletion = true"
          @newStudentAdded="getCourseDetails()" :getCustomNames="getCustomNames" />
      </b-modal>
      <div class="coursesBtns p-2 d-flex justify-content-between" style="
          border: 2px solid #eeeeeb;
          border-left-style: none;
          border-right-style: none;
        " v-if="!addCourse && editCourse">
        <div>
          <b-button variant="info" size="sm" class="ml-3" v-if="!addCourse && !showUpdateCourse"
            @click="getCourseDetails()" siz>
            <i class="fas fa-sync-alt text-white"></i>
            Refresh
          </b-button>
        </div>
        <!-- <b-button
          @click="openYoutubeLinks('Courses')"
          style="background-color: #ff0000; border: none"
          size="sm"
        >
          <i class="fab fa-youtube"></i>
          Video Tutorial
        </b-button> -->
      </div>
      <create-course v-if="showCreateCourse" ref="createCourse" @courseCreated="showCreateCourse = false"
        :faculties="faculties" :getCustomNames="getCustomNames" :courseTypesOptions="courseTypesOptions"
        :courseCategoriesOptions="courseCategoriesOptions">
      </create-course>
      <update-course class="updateCourse" ref="updateCourse" :courseInfo="courseDetails" :courseId="courseId"
        :courseUsers="courseUsers" :editCourse="editCourse" v-if="showUpdateCourse" :getCustomNames="getCustomNames"
        @updateClosed="showUpdateCourse = false" :faculties="faculties" :courseTypesOptions="courseTypesOptions"
        :courseCategoriesOptions="courseCategoriesOptions">
      </update-course>

      <div v-if="!showUpdateCourse && !showCreateCourse">
        <br />
        <div class="noCoursesFound" v-if="courses.length == 0">
          <h6 class="text-center text-danger">No courses created yet!</h6>
        </div>
        <div class="couresDetails" v-else>
          <!-- <div class="sortByDropdown mb-2 w-50">

          </div> -->

          <div class="p-3">
            <div class="table-responsive rounded">
              <table class="table table-striped table-bordered">
                <thead class="thead-dark text-left">
                  <tr>
                    <th>Si No</th>
                    <th>Course</th>
                    <th>Code</th>
                    <th>Course Type</th>
                    <th>Sub Type</th>
                    <th>Edit</th>
                    <th>Instructor Details</th>
                    <th>Enrolled Students</th>
                    <th>Course Reports</th>
                    <th>Academic Year</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="filteredCourses.length == 0">
                    <td colspan="100" class="text-danger text-center">
                      No Course Found!
                    </td>
                  </tr>
                  <tr v-else v-for="(course, index) in filteredCourses" :key="index" class="text-left">
                    <td class="align-middle">
                      {{ course.si_no }}
                    </td>
                    <td class="align-middle">
                      <p v-if="course.roleBy == 'instructor'" class="btn p-0 m-0 font-weight-bolder "
                        @click="$bvModal.show('coursesOptions' + course.id)">
                        <span v-if="courseTypesObj[course.course_type_id] == 'Non Curricular'" class="text-success ">{{
                            course.name
                        }}</span>
                        <span v-else class="text-info ">{{ course.name }}</span>
                      </p>
                      <p v-else class=" p-0 m-0 font-weight-bolder ">
                        {{ course.name }}
                      </p>
                    </td>

                    <td class="align-middle">
                      {{ course.code }}-{{ course.usersubgroup.code }}-{{
                          course.usersubgroup.usergroup.code
                      }}
                    </td>
                    <td class="align-middle">
                      {{
                            course.course_category_id != null
                              ? courseCategoriesObj[course.course_category_id]
                              : "Not Applicable"
                        }}
                    </td>
                    <td class="align-middle">
                      {{
                          course.course_type_id != null
                            ? courseTypesObj[course.course_type_id]
                            : "-"
                      }}
                    </td>
                    <td class="align-middle">
                      <span v-if="course.roleBy == 'instructor'" class="text-info btn m-0 p-0"
                        @click="updateCourse(course.id)"><i class="fas fa-pen ml-3"></i></span>
                    </td>
                    <td class="align-middle">
                      <b-popover :target="'disabled-courseAdmin' + index" triggers="hover" placement="top"
                        v-if="instructorsList[course.id].length != 0">
                        <b-list-group style="height: 200px; overflow-y: scroll">
                          <b-list-group-item v-for="({ first_name, last_name },
                          adminIndex) in instructorsList[course.id]" :key="adminIndex">
                            {{ first_name.concat(" ", last_name) }}
                          </b-list-group-item>
                        </b-list-group>
                      </b-popover>
                      <span :id="'courseAdmin' + index" @click="
                        course.roleBy == 'instructor' ? getCourseAdmins(
                          course.id,
                          course.name,
                          instructorsList[course.id]
                        ) : accessDenied()
                      ">
                        <span v-if="instructorsList[course.id].length != 0" class="text-info btn p-0 m-0">
                          {{ instructorsList[course.id][0].first_name }} {{ instructorsList[course.id][0].last_name }}
                          <span v-if="instructorsList[course.id].length > 1" :id="'courseAdmin' + index">
                            &
                            {{ instructorsList[course.id].length - 1 + " other" }}
                          </span>
                        </span>
                        <span v-else class="text-danger btn">
                          No Instructors Assigned!
                        </span>
                      </span>
                    </td>

                    <td class="align-middle">
                      <span @click="course.roleBy == 'instructor' ?
                        getCourseStudentsFromModal(
                          course.id,
                          course.name,
                          studentsList[course.id],
                          course.usersubgroup_id
                        ) : accessDenied()
                      ">

                        <span class="text-info btn p-0 m-0" v-if="studentsList[course.id].length != 0">
                          {{ studentsList[course.id].length }}
                          {{
                              studentsList[course.id].length > 1
                                ? "Students"
                                : "Student"
                          }}
                        </span>
                        <span v-else class="text-danger btn">
                          No Students Enrolled
                        </span>
                      </span>
                    </td>
                    <td class="text-center align-middle">
                      <span class="btn p-0 m-0 text-info" @click="course.roleBy == 'instructor' ?
                        dataRequiredForCourseReportsAPI(
                          course.id,
                          course.usersubgroup.usergroup.id,
                          course.usersubgroup_id,
                          course.name
                        ) : accessDenied()
                      "><i class="fas fa-file-alt"></i></span>
                    </td>
                    <td class="align-middle">
                      {{ course.academic_year }}
                    </td>
                    <td class="text-center align-middle">
                      <b-modal :id="'coursesOptions' + course.id" :title="course.name + ' Configuration'"
                        no-close-on-backdrop no-close-on-keyboard no-close-on-esc hide-footer
                        modal-class="coursesOptions">
                        <CoursesOptions :courseInfo="course" :course_id="course.id" :course_type="course.course_type_id != null
                        ? courseTypesSlugs[course.course_type_id]
                        : '-'" :courseInstructor="courseInstructor" :courseId="course.id"
                          :usergroupId="course.usersubgroup.usergroup.id" :editCourseComponents="editCourseComponents"
                          @CourseUpdated="CourseUpdated" :getCustomNames="getCustomNames" />
                      </b-modal>

                      <span v-if="course.roleBy == 'instructor'" class="btn p-0 m-0 text-info"
                        @click="$bvModal.show('coursesOptions' + course.id)">
                        <i class="fas fa-bars"></i>
                      </span>

                      <b-modal :id="'courseProjectDashboard' + course.id" :title="course.name + ' Configuration'"
                        no-close-on-backdrop no-close-on-keyboard no-close-on-esc hide-footer
                        modal-class="coursesOptions">
                        <ProjectDashboard :course_id="course.id" />
                      </b-modal>
                      <span v-if="course.roleBy == 'projectCoordinator' || course.roleBy == 'projectSupervisor'"
                        class="btn p-0 m-0 text-success" @click="$bvModal.show('courseProjectDashboard' + course.id)">
                        <i class="fas fa-bars"></i>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CreateCourse from "../Course/CreateCourse.vue";
import UpdateCourse from "../Course/UpdateCourse.vue";
import axios from "axios";
import acl from "../../../../authorization/acl.js";

import CourseReports from "./CourseReports.vue";
import CoursesOptions from "./CoursesOptions.vue";
import CourseAdmins from "./CourseAdmins.vue";
import CourseStudents from "./CourseStudents.vue";
import ProjectDashboard from "../../../courseProject/ProjectDashboard.vue";

export default {
  props: ["editCourseComponents"],
  async created() {
    this.loadCourses = true;
    const canEditCourse = await acl.isUserGranted("editCourse");
    this.editCourse = canEditCourse;
    const courseInstructor = await acl.isUserGranted("courseInstructor");
    this.courseInstructor = courseInstructor;
    await this.getInstitutionFaculties();
    // await this.getUserSubgroups();
    await this.getCourseTypes();
    await this.getCourseCategories();
    await this.getCourseDetails();
    this.loadCourses = false;
  },
  components: {
    CreateCourse: CreateCourse,
    UpdateCourse: UpdateCourse,
    CourseReports,
    CoursesOptions,
    CourseAdmins,
    CourseStudents,
    ProjectDashboard,
  },
  data() {
    return {
      courseReport: {
        course_id: null,
        usergroup_id: null,
        usersubgroup_id: null,
        name: null,
      },
      courseInstructor: null,
      loadLessons: false,
      deleteLesson: false,
      loadCourses: false,
      showUpdateCourse: false,
      fetchData: false,
      lessonCreation: false,
      updateLessonDetails: {
        id: null,
        lesson: {},
      },
      lessonView: {},
      editCourse: null,
      addCourse: false,
      courses: [],
      coursesWithUsers: [],
      instructorsList: [],
      studentsList: [],
      courseDetails: {},
      courseId: "",
      courseUsers: [],
      lessonList: {},
      courseStudents: {},
      search: null,
      reportBtn: {
        text: "Download PDF",
        disabled: false,
      },
      showCreateCourse: false,
      courseAdminModal: {},
      courseStudentModal: {},
      faculties: [],
      subgroups: [],
      sortBySubgroup: null,
      selectedSubgroup: null,
      subgroupOptions: [],
      courseTypesOptions: [],
      courseCategoriesOptions: [],
      courseCategoriesObj: {}, 
      courseTypesSlugs: {},
      courseTypesObj: {}
    };
  },
  computed: {
    getCustomNames() {
      return this.$store.getters.getCustomNamings;
    },
    filteredCourses() {
      if (this.search) {
        return this.courses.filter((course) => {
          let cName = course.name;
          let cCode = course.code;
          let cGroupCode = course.usersubgroup.usergroup.code;
          let cSubgroupCode = course.usersubgroup.code;

          return this.search
            .toLowerCase()
            .split(" ")
            .every(
              (v) =>
                cName.toLowerCase().includes(v) ||
                cCode.toLowerCase().includes(v) ||
                cGroupCode.toLowerCase().includes(v) ||
                cSubgroupCode.toLowerCase().includes(v)
            );
        });
      } else {
        return this.courses;
      }
    },
  },
  methods: {
    accessDenied() {
      alert("You don't have enough permission to access this resource");
    },
    async getCourseCategories() {
      const url = this.$store.getters.getAPIKey.mainAPI + `/coursetypes/categories`;
      await this.$axios.get(url).then((res) => {
        this.courseCategoriesOptions = [
          {
            value: null,
            text: "Not Applicable",
          },
        ];
        res.data.forEach(({ id, name }) => {
          this.$set(this.courseCategoriesObj, id, name);
          this.courseCategoriesOptions.push({
            value: id,
            text: name,
          });
        });
      });
    },
    async CourseUpdated() {
      await this.getCourseDetails();
    },
    getCourseAdmins(courseId, courseName, courseAdmins) {
      this.courseAdminModal = {};
      this.$set(this.courseAdminModal, "id", courseId);
      this.$set(this.courseAdminModal, "name", courseName);
      this.$set(this.courseAdminModal, "admins", courseAdmins);
      this.$set(
        this.courseAdminModal,
        "totalAdmins",
        Number(courseAdmins.length)
      );
      this.$set(
        this.courseAdminModal,
        "remainingAdmins",
        Number(courseAdmins.length)
      );
      this.$bvModal.show("courseAdmins");
    },

    getCourseStudentsFromModal(
      courseId,
      courseName,
      courseStudents,
      usersubgroupId
    ) {
      this.courseAdminModal = {};
      this.$set(this.courseStudentModal, "id", courseId);
      this.$set(this.courseStudentModal, "name", courseName);
      this.$set(this.courseStudentModal, "students", courseStudents);
      this.$set(this.courseStudentModal, "deletion", false);

      this.$set(this.courseStudentModal, "usersubgroup_id", usersubgroupId);

      this.$bvModal.show("courseStudents");
    },
    adminRemoved(remainingAdmins) {
      this.courseAdminModal.remainingAdmins = remainingAdmins;
    },
    checkAdminStatus() {
      if (
        Number(this.courseAdminModal.admins.length) !=
        Number(this.courseAdminModal.remainingAdmins)
      ) {
        this.getCourseDetails();
      }
    },
    async getInstitutionFaculties() {
      //get institutionfaculties
      const url = this.$store.getters.getAPIKey.instituitionFaculty;
      await axios
        .get(url)
        .then((response) => {
          this.faculties = [];
          this.faculties = response.data;
        })
        .catch(() => {
          // console.log(error.response);
        });
    },
    dataRequiredForCourseReportsAPI(courseId, groupId, subgroupId, courseName) {
      this.courseReport.course_id = courseId;
      this.courseReport.usergroup_id = groupId;
      this.courseReport.usersubgroup_id = subgroupId;
      this.courseReport.name = courseName;
      this.$bvModal.show("courseReports");
    },
    checkStudents(courseId) {
      this.lessonView[courseId] = false;
      this.lessonView[courseId] = true;
      if (!this.courseStudents[courseId]) {
        this.getCourseStudents(courseId);
      }
    },
    checkInstructors(courseId) {
      this.lessonView[courseId] = false;
      this.lessonView[courseId] = true;
    },
    async getUserSubgroups() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        "/institutionuser/usersubgroups";
      await axios
        .get(url)
        .then((response) => {
          this.subgroups = response.data;
          this.subgroups.forEach((subgroup) => {
            this.subgroupOptions.push({
              value: subgroup.id,
              name: subgroup.usergroup.code + " - " + subgroup.code,
            });
          });
          if (this.subgroups.length != 0) {
            this.selectedSubgroup = this.subgroupOptions[0];
          }
          response;
        })
        .catch((error) => {
          error;
        });
    },
    async getSubgroupUsers() {
      let mainUrl = this.$store.getters.getAPIKey.getEnrollSubgroupUsersList;
      const url = mainUrl.replace(
        "usersubgroup_id",
        this.courseInfo.usersubgroup_id
      );
      // this.StudentOptions = [];
      // await this.getInstitutionStudents();
      await axios
        .get(url)
        .then((response) => {
          this.enrolledUsers = [];
          this.selectedStudents = [];
          this.enrolledUsers = response.data;
          this.students = [];
          this.StudentOptions = [];
          this.students = response.data;

          this.students.forEach((staff) => {
            this.StudentOptions.push({
              name: staff.first_name + " " + (staff.last_name || ""),
              id: staff.pivot.institution_user_id,
              code: staff.id,
            });
          });
          this.enrolledUsers.forEach((student) => {
            if (student.pivot.usersubgrouprole_id == 2) {
              this.selectedStudents.push({
                name: student.first_name + " " + (student.last_name || ""),
                id: student.pivot.institution_user_id,
                code: student.id,
              });
            }
          });
          if (this.courseUsers.length != 0) {
            this.courseUsers.forEach((existing) => {
              this.selectedStudents.forEach((student, studentIndex) => {
                if (existing.pivot.institution_user_id == student.id) {
                  this.selectedStudents.splice(studentIndex, 1);
                }
              });
            });
            this.courseUsers.forEach((existing) => {
              this.StudentOptions.forEach((student, studentIndex) => {
                if (existing.pivot.institution_user_id == student.id) {
                  this.StudentOptions.splice(studentIndex, 1);
                }
              });
            });
          }
          // console.log(response);
        })
        .catch(() => {
          // console.log(error.response);
        });
    },
    async getCourseDetails() {
      this.loadCourses = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/institutionuser/courses/withusers`;
      await axios
        .get(url)
        .then((response) => {
          this.courses = response.data;
          this.coursesWithUsers = response.data;
          this.instructorsList = {};
          this.studentsList = {};
          this.courses.forEach((courseUsers) => {
            this.$set(this.lessonView, courseUsers.id, false);
            this.instructorsList[courseUsers.id] = [];
            this.studentsList[courseUsers.id] = [];
            courseUsers.institution_users.forEach((user) => {
              if (user.pivot.courserole_id == 1) {
                this.instructorsList[courseUsers.id].push(user);
                // this.instructorsList[courseUsers.id] = user;
              }
              if (user.pivot.courserole_id == 2) {
                this.studentsList[courseUsers.id].push(user);
              }
            });
          });
          this.loadCourses = false;
          // console.log(response);
        })
        .catch(() => {
          // console.log(error.response);
        });
    },
    async getCourseStudents(course_id) {
      this.fetchData = true;
      let mainUrl = this.$store.getters.getAPIKey.createStudent;
      const url = mainUrl.replace("course_id", course_id);
      await axios
        .get(url)
        .then((response) => {
          const users = response.data[0];
          this.courseStudents[course_id] = [];
          users.forEach((user) => {
            this.courseStudents[course_id].push(user);
          });
          this.sortedArray(course_id);
          this.fetchData = false;
          // console.log(response);
        })
        .catch(() => {
          // console.log(error.response);
        });
    },
    sortedArray(course_id) {
      // this.courseStudents[course_id].sort(function(a, b) {
      //   return (
      //     a.roll_number - b.roll_number ||
      //     a.first_name.localeCompare(b.first_name)
      //   );
      // });
      return course_id;
    },

    createCourse() {
      this.addCourse = true;
    },
    async updateCourse(course_id) {
      this.loadCourses = true;
      this.courseDetails = {};
      this.courseId = "";
      this.courseUsers = [];
      let updateUrl = this.$store.getters.getAPIKey.updateCourse;
      const url = updateUrl.replace("course_id", course_id);
      let updateUrlWIthUsers = this.$store.getters.getAPIKey.getCourseWithUsers;
      const usersList = updateUrlWIthUsers.replace("course_id", course_id);
      await axios
        .get(url)
        .then((response) => {
          this.courseId = response.data.id;
          this.courseDetails.si_no = response.data.si_no;
          this.courseDetails.name = response.data.name;
          this.courseDetails.code = response.data.code;
          this.courseDetails.academic_year = response.data.academic_year;
          this.courseDetails.usersubgroup_id = response.data.usersubgroup_id;
          this.courseDetails.enable_laboratory = response.data.enable_laboratory;
          if (
            response.data.start_year != null &&
            response.data.end_year != null
          ) {
            this.courseDetails.start_year = response.data.start_year;
            this.courseDetails.end_year = response.data.end_year;
          }
          this.courseDetails.usersubgroup = response.data.usersubgroup;
          this.courseDetails.usergroup = response.data.usergroup;
          this.courseDetails.course_type_id = response.data.course_type_id;
          this.courseDetails.course_category_id = response.data.course_category_id;
          this.courseId = response.data.id;
          axios.get(usersList).then((response) => {
            this.courseUsers = response.data;
            // console.log(response);
          });
          this.showUpdateCourse = true;
          // this.addCourse = true;
          // if(this.$refs.updateCourse) {
          //   this.$refs.updateCourse.fillData();
          // }

          this.loadCourses = false;
          // console.log(response);
        })
        .catch(() => {
          // console.log(error.response);
        });
    },
    async getCourseTypes() {
      const url = this.$store.getters.getAPIKey.mainAPI + `/coursetypes `;
      await this.$axios.get(url).then((res) => {
        this.courseTypesOptions = [
          {
            value: null,
            text: "Select Course Type",
          },
        ];
        res.data.forEach(({ id, name }) => {
          this.$set(this.courseTypesObj, id, name);
          this.courseTypesOptions.push({
            value: id,
            text: name,
          });
        });

        res.data.forEach(({ id, slug }) => {
          this.$set(this.courseTypesSlugs, id, slug);
        });
      });
    },
  },
};
</script>

<style >
.coursesBtns i {
  font-size: 12px !important;
  margin-right: 4px;
}

.coursesOptions .modal-dialog {
  max-width: 100%;
  max-height: 100vh;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  position: fixed;
  z-index: 100000;
}

.coursesOptions .modal-body {
  overflow: scroll;
}
</style>
