<template>
  <div>
    <div>
      <!-- this option is to choose copy from , but option is prop from parant -->
      <!-- <div class="col-md-12">
        <h2 class="text-center font-weight-bolder text-info">
          Copy class/semester with courses and course details from
          <span v-if="copySubgroupFrom == 'template'">University Resources</span>
          <span v-if="copySubgroupFrom == 'myinstitute'">
             previous academic year</span>
        </h2>
        <b-form-group>
          <label class="required-label" for="">Choose Resources</label>
          <b-form-select @change="changeCopyFrom()"
            :options="[{ value: '', text: 'Select' }, { value: 'template', text: 'KTU Resources' }, { value: 'myinstitute', text: 'My Institute' }]"
            v-model="copySubgroupFrom"></b-form-select>
        </b-form-group>
        <div class="row" v-if="copySubgroupFrom == ''">
          <b-form-group style="text-align:right;" class="col">
            <b-button variant="danger" @click="$emit('Cancel')" :disabled="startCopybutton.disabled">Cancel</b-button>
          </b-form-group>
        </div>
        <br />
      </div> -->
      <b-form v-if="copySubgroupFrom == 'template'" @submit.prevent="CopySubgroupFromTemplate()">

        <b-form-group>
          <label class="required-label" for="">Choose destination programme:</label>
          <b-form-select @change="getTemplateUserGroups" :options="userGroups" v-model="userGroupTo">
          </b-form-select>
        </b-form-group>

        <!-- <b-form-group>
          <label class="required-label" for="">Choose Resources</label>
          <b-form-select @change="getTemplateUserGroups()" :options="templateInstitutes"
            v-model="templateInstitute"></b-form-select>
          <span class="text-danger" v-if="(templateInstitutes.length == 1)">Please wait...</span>
        </b-form-group> -->
        <div class="col-md-12">

          <b-form-group v-if="userGroupTo">
            <label class="required-label" for="">Choose Resources Programme</label>
            <b-form-select @change="getSubgroups()" :options="templateUserGroups" v-model="userGroup">
            </b-form-select>
          </b-form-group>

          <b-form-group v-if="userGroup">
            <label class="required-label" for="">Select the {{ usersubgroupCustomname }}s to be copied :</label>
            <div class="row">
              <div class="col-sm-6 col-md-4 col-lg-3" v-for="(option, index) in subgroupOptions" :key="index">
                <b-form-checkbox v-model="fromSubgroups" :value="option"
                  @change="updateIn_FromSubgroups(option.value)">{{ option.text }}
                </b-form-checkbox>
              </div>
              <!-- <multiselect v-model="fromSubgroups" tag-placeholder="Add this as new tag" placeholder="Choose multiple"
                label="text" track-by="value" :options="subgroupOptions" :close-on-select="false" :multiple="true"
                :taggable="true" @select="updateIn_FromSubgroups">
              </multiselect> -->
            </div>
          </b-form-group>

          <b-form-group v-if="fromSubgroups.length">
            <label class="required-label" for="">Choose Courses of selected {{ usersubgroupCustomname }}s:</label>
            <div class="">

              <table v-if="fromSubgroups.length" class="table  table-striped table-bordered">
                <thead>
                  <tr style="background-color: #a8a8a8;">
                    <th v-for="(subgroupId, subgindex) in fromSubgroups.map(option => option.value)" :key="subgindex">
                      <b-form-checkbox @change="selectAllCoursesInSubgroup($event, subgroupId)" size="md">
                        {{ subgroupsCoursesOptions[subgroupId].name }}-{{ subgroupsCoursesOptions[subgroupId].code }}
                      </b-form-checkbox>

                    </th>
                  </tr>
                </thead>
                <tbody style="font-weight: 300;">
                  <tr v-for="n in maxCoursesInProgrammeSubgroups" :key="n">
                    <td v-for="(subgroupId, subgindex) in fromSubgroups.map(option => option.value)" :key="subgindex">
                      <span v-if="subgroupsCoursesOptions[subgroupId].courses[n - 1]">
                        <b-form-checkbox v-model="selectedFromSubgroupsCourses"
                          :value="subgroupsCoursesOptions[subgroupId].courses[n - 1].id" size="md">
                          {{ subgroupsCoursesOptions[subgroupId].courses[n - 1].code }}-{{
                              subgroupsCoursesOptions[subgroupId].courses[n - 1].name
                          }}
                        </b-form-checkbox>

                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-form-group>


          <div v-if="selectedFromSubgroupsCourses.length">

            <ul>
              Data to be copied along with the selected Courses:
              <li>Courses Modules</li>
              <li>Lesson Plan</li>
              <li>Lab Experiments</li>
              <li>Course Outcomes</li>
              <li>Courses Properties (Text Books, Reference Docs,
                Prerequisites, Industrial Relevance, Content Beyond Syllabus,
                Details of Learning Materials, LTP Credits, Aims, Objectives)</li>
            </ul>
          </div>


          <div class="text-danger">
            The {{ usersubgroupCustomname }} will be copied to:
            {{ $root.default_academic_year[0] }}-{{ $root.default_academic_year[1] }}, <span
              v-for="(group, gindex) in userGroups" :key="gindex"><span v-if="(userGroupTo == group.value)">{{
                  group.text
              }}</span></span>
          </div>
          <br> <br>

          <div class="row">
            <b-form-group style="text-align:left;" class="col-6">
              <b-button v-if="(fromSubgroups.length && userGroupTo)" type="submit" variant="primary"
                :disabled="startCopybutton.disabled">{{
                    startCopybutton.text
                }}</b-button>
            </b-form-group>
            <b-form-group style="text-align:right;" class="col-6">
              <b-button variant="danger" @click="$emit('Cancel')" :disabled="startCopybutton.disabled">Cancel</b-button>
            </b-form-group>
          </div>

        </div>
      </b-form>

      <!-- copy from own institiute  -->
      <b-form v-if="copySubgroupFrom == 'myinstitute'" @submit.prevent="CopySubgroup()">
        <div class="col-md-12">

          <b-form-group>
            <label class="required-label" for="">Choose Academic Year to be copied from:</label>
            <b-form-select :options="$acedemicYears" v-model="academic_year">
            </b-form-select>
          </b-form-group>

          <b-form-group v-if="academic_year">
            <label class="required-label" for="">Choose Programme</label>
            <b-form-select @change="getSubgroups()" :options="userGroups" v-model="userGroup">
            </b-form-select>
          </b-form-group>

          <!-- <b-form-group v-if="academic_year && userGroup">
            <label class="required-label" for="">{{ 'Select the ' + usersubgroupCustomname + ' to be copied :'
            }}</label>
            <b-form-select @change="checkInterDepartmentCourseGroupStatus" :options="subgroupOptions"
              v-model="fromSubgroup" :disabled="false"></b-form-select>
          </b-form-group> -->
          <b-form-group v-if="(academic_year && userGroup)">
            <label class="required-label" for="">Select the {{ usersubgroupCustomname }}s to be copied :</label>
            <div class="row">
              <div class="col-sm-6 col-md-4 col-lg-3" v-for="(option, index) in subgroupOptions" :key="index">
                <b-form-checkbox v-model="fromSubgroups" :value="option" @change="getSubgroupCourses(option)">{{
                    option.text
                }}
                </b-form-checkbox>
              </div>
            </div>
          </b-form-group>

          <b-form-group v-if="fromSubgroups.length">
            <label class="required-label" for="">Choose Courses of selected {{ usersubgroupCustomname }}s:</label>
            <div class="">

              <table v-if="fromSubgroups.length" class="table  table-striped table-bordered">
                <thead>
                  <tr style="background-color: #a8a8a8;">
                    <th v-for="(subgroup, subgindex) in fromSubgroups" :key="subgindex">
                      {{ subgroup.text }} <br>
                      <b-link
                        @click="selectedFromSubgroupsCourses = [...selectedFromSubgroupsCourses, ...subgroupsCoursesOptions[subgroup.value].courses.map(option => option.id)]">Select
                        All</b-link>
                    </th>
                  </tr>
                </thead>
                <tbody style="font-weight: 300;">
                  <tr>
                    <td v-for="(subgroupId, subgindex) in fromSubgroups.map(option => option.value)" :key="subgindex">
                      <span v-for="(course, courseindex) in subgroupsCoursesOptions[subgroupId].courses"
                        :key="courseindex">
                        <b-form-checkbox v-model="selectedFromSubgroupsCourses" :value="course.id" size="md">
                          {{ course.code }}-{{ course.name }}
                        </b-form-checkbox>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-form-group>
          <br />

          <div class="text-danger">
            The {{ usersubgroupCustomname }} will be copied to:
            {{ $root.default_academic_year[0] }}-{{ $root.default_academic_year[1] }}, <span
              v-for="(group, gindex) in userGroups" :key="gindex"><span v-if="(userGroupTo == group.value)">{{
                  group.text
              }}</span></span>
          </div>
          <br>

          <b-form-group v-if="userGroup">
            <label class="required-label" for="">Choose destination programme :</label>
            <b-form-select @change="checkInterDepartmentCourseGroupStatus" :options="userGroups" v-model="userGroupTo">
            </b-form-select>
          </b-form-group>

          <br />

          <div v-if="(selectedFromSubgroupsCourses.length > 0)">
            <!-- <div class="row">
              <h4 class="col">
                <b>
                  Choose the data to be copied along with
                  {{ usersubgroupCustomname }}:
                </b>
              </h4>
            </div>
            <div class="row">
              <b-form-group class="col">
                <b-form-checkbox id="courses" v-model="
                  dupliacateSubgroupoptions.usersubgroup_properties.courses
                " name="checkbox-1">
                  {{ usersubgroupCustomname + " courses" }}
                </b-form-checkbox>
              </b-form-group>
            </div> -->
            <div>
              <div class="row">
                <h4 class="col">
                  <b>Choose the data to be copied along with all courses:</b>
                </h4>
              </div>
              <div class="row">
                <b-form-group class="col">
                  <b-form-checkbox id="co" v-model="dupliacateSubgroupoptions.courses_properties.co"
                    name="checkbox-1">Course Outcomes
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group class="col">
                  <b-form-checkbox id="courses_properties" v-model="
                    dupliacateSubgroupoptions.courses_properties
                      .course_instructors
                  " name="checkbox-1">Courses Instructors
                  </b-form-checkbox>
                </b-form-group>
                <!-- <b-form-group class="col">
                  <b-form-checkbox id="course_scedules" v-model="
                    dupliacateSubgroupoptions.courses_properties
                      .course_scedules
                  " name="checkbox-1">Courses Schedules (Timetable)
                  </b-form-checkbox>
                </b-form-group> -->
              </div>
              <div class="row">
                <b-form-group class="col">
                  <b-form-checkbox id="boardexam_config" v-model="
                    dupliacateSubgroupoptions.courses_properties
                      .boardexam_config
                  " name="checkbox-1">University Exam Configs
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group class="col">
                  <b-form-checkbox id="modules" v-model="
                    dupliacateSubgroupoptions.courses_properties.modules
                  " name="checkbox-1">Courses Modules
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group class="col">
                  <b-form-checkbox id="lessonplan" @change="lessonPlanEnabled" v-model="
                    dupliacateSubgroupoptions.courses_properties.lessonplan
                  " name="checkbox-1">Courses Lesson Plans
                  </b-form-checkbox>
                </b-form-group>
              </div>

              <div class="row">
                <b-form-group class="col">
                  <b-form-checkbox :disabled="(userGroupTo != userGroup)" id="co_po_pso_mapping" v-model="
                    dupliacateSubgroupoptions.courses_properties
                      .co_po_pso_mapping
                  " name="checkbox-1">CO-PO & PSO Mapping
                    {{ (userGroupTo != userGroup) ? '(Not available if copying to different programs) ' : '' }}
                  </b-form-checkbox>
                </b-form-group>
              </div>

              <div class="row">
                <b-form-group class="col">
                  <b-form-checkbox id="course_properties" v-model="
                    dupliacateSubgroupoptions.courses_properties
                      .course_properties
                  " name="checkbox-1">Courses Properties (Text books, Reference docs,
                    Prerequisites, Industrial relevence ,Content beyond
                    syllabus, Details of learning materials, LTP credits, Aims, Objectives)
                  </b-form-checkbox>
                </b-form-group>
              </div>
            </div>
          </div>
          <div class="row">
            <b-form-group style="text-align:left;" class="col-6">
              <b-button v-if="fromSubgroups.length" type="submit" variant="primary"
                :disabled="startCopybutton.disabled">{{
                    startCopybutton.text
                }}</b-button>
            </b-form-group>
            <b-form-group style="text-align:right;" class="col-6">
              <b-button variant="danger" @click="$emit('Cancel')" :disabled="startCopybutton.disabled">Cancel</b-button>
            </b-form-group>
          </div>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
export default {
  props: ["copy_from_suggestion"],
  async created() {
    this.usersubgroupCustomname = this.$store.getters.getCustomNamings.userSubgroup.custom_name ?? "Usersubgroup";
    this.copySubgroupFrom = this.copy_from_suggestion;
    this.changeCopyFrom();
  },
  data() {
    return {
      userGroupTo: null,
      // fromSubgroup: null,
      usergeoupCustomname: null,
      new_usersubgroup: true,
      dupliacateSubgroupoptions: {
        // usersubgroup_properties: { courses: true },
        courses_properties: {
          co: true,
          lab_experiments: true,
          course_instructors: true,
          course_scedules: true,
          boardexam_config: true,
          modules: true,
          lessonplan: true,
          co_po_pso_mapping: true,
          course_properties: true, //text_books, reference_docs, pre_requisites, industrial_relevence ,content_beyond_syllabus, details_of_lerning_matials, ltp_credits, aims, objectives
        },
      },
      // fromSubgroup: { id: null, name: "", code: "" },
      subgroupOptions: [],
      subgroupObjects: {},
      startCopybutton: {
        text: "Start Copying",
        disabled: false,
      },
      academic_year: null,
      templateUserGroups: [],
      userGroups: [],
      userGroupObjects: {},
      userGroup: null,

      fromSubgroups: [],
      selectedFromSubgroupsCourses: [],
      subgroupsCoursesOptions: {},
      copySubgroupFrom: '',//'template'/'myinstitute'
      templateGroupSubgroupCourses: {},
      course_properties: {
        co: true,
        boardexam_config: true,
        lab_experiments: true,
        modules: true,
        lessonplan: true,
        co_po_pso_mapping: true,
        course_properties: true, //text_books, reference_docs, pre_requisites, industrial_relevence ,content_beyond_syllabus, details_of_lerning_matials, ltp_credits
      },
      maxCoursesInProgrammeSubgroups: 0, // table row count purpouse
      // templateInstitutes: [{ value: '', text: 'Select' },],
      // templateInstitute: '',
    };
  },
  methods: {
    async getSubgroupCourses(subgroup) {
      this.subgroupsCoursesOptions[subgroup.value].courses = [];
      if (this.fromSubgroups.includes(subgroup)) {
        const url =
          this.$store.getters.getAPIKey.mainAPI +
          `/usersubgroups/${subgroup.value}/courses`;
        await this.$axios
          .get(url)
          .then((response) => {
            response.data.forEach((course) => {
              this.subgroupsCoursesOptions[subgroup.value].courses.push(course);
            });
          })
          .catch((error) => {
            error;
          });

        this.subgroupsCoursesOptions[subgroup.value].courses.forEach(course => {
          if (course.type) if (['core', 'laboratory', 'project', 'seminar'].includes(course.type.slug)) {
            if (!this.selectedFromSubgroupsCourses.includes(course.id)) this.selectedFromSubgroupsCourses.push(course.id);
          }
        });

        this.$forceUpdate();
      }
      this.checkInterDepartmentCourseGroupStatus();
    },
    checkInterDepartmentCourseGroupStatus() {
      if (this.copySubgroupFrom == 'myinstitute') {
        // this.dupliacateSubgroupoptions.courses_properties
        //   .co_po_pso_mapping = (this.userGroupTo != this.userGroup) ? false : true;
        if (this.userGroupTo == null || this.fromSubgroups.length < 1) return;
        if (this.userGroupObjects[this.userGroupTo].has_interdepartment_courses_group) {
          let interDCGCount = 0;
          this.fromSubgroups.forEach(fromSubGr => {
            if (this.subgroupObjects[fromSubGr.value].type == 'interdepartment_courses_group') interDCGCount++;
          });
          if (interDCGCount > 1 || (this.userGroupObjects[this.userGroupTo].has_interdepartment_courses_group && interDCGCount > 0)) {
            this.fromSubgroups = []; this.userGroupTo = null;
            this.selectedFromSubgroupsCourses = [];
            this.$toast.error(
              `The selected destination programme already has a ${this.usersubgroupCustomname} that contain interdepartmental courses, you cannot add more than one`,
              { position: "top", duration: 6000 }
            );
          }
        }

      }
      if (this.copySubgroupFrom == 'template') {
        if (this.userGroupTo == null || this.fromSubgroups.length < 1) return;
        let interDCGCount = 0;
        this.fromSubgroups.forEach(fromSubGr => {
          if (this.subgroupObjects[fromSubGr.value].type == 'interdepartment_courses_group') interDCGCount++;
        });
        if (interDCGCount > 1 || (this.userGroupObjects[this.userGroupTo].has_interdepartment_courses_group && interDCGCount > 0)) {
          this.fromSubgroup = null; this.fromSubgroups = []; this.userGroupTo = null;
          this.selectedFromSubgroupsCourses = [];
          this.$toast.error(
            `The selected destination programme already has a ${this.usersubgroupCustomname} that contain interdepartmental courses, you cannot add more than one`,
            { position: "top", duration: 6000 }
          );
        }
      }
    },
    lessonPlanEnabled() {
      if (this.course_properties.lessonplan) {
        this.course_properties.co = true;
        this.course_properties.modules = true;
      }
      if (this.dupliacateSubgroupoptions.course_properties.lessonplan) {
        this.dupliacateSubgroupoptions.course_properties.co = true;
        this.dupliacateSubgroupoptions.course_properties.modules = true;
      }
    },
    updateIn_FromSubgroups(subgroupId) {
      // console.log(selectedOption);
      // add some type course ids to selectedFromSubgroupsCourses
      // let subgroupId = selectedOption.value;
      this.subgroupsCoursesOptions[subgroupId].courses.forEach(course => {
        if (course.type) if (['core', 'laboratory', 'project', 'seminar'].includes(course.type.slug)) {
          if (!this.selectedFromSubgroupsCourses.includes(course.id)) this.selectedFromSubgroupsCourses.push(course.id);
        }
      });
      this.checkInterDepartmentCourseGroupStatus();
    },
    selectAllCoursesInSubgroup(event, subgroupId) {
      this.subgroupsCoursesOptions[subgroupId].courses.forEach(course => {
        if (event) {
          if (!this.selectedFromSubgroupsCourses.includes(course.id)) {
            this.selectedFromSubgroupsCourses.push(course.id);
          }
        } else {
          const index = this.selectedFromSubgroupsCourses.indexOf(course.id);
          if (index > -1) {
            this.selectedFromSubgroupsCourses.splice(index, 1);
          }
        }
      });
    },
    changeCopyFrom() {

      this.subgroupOptions = [];
      this.academic_year = null;
      this.userGroups = [];
      this.templateUserGroups = [];
      this.userGroup = null;

      this.fromSubgroups = [];
      this.subgroupsCoursesOptions = {};
      this.templateGroupSubgroupCourses = {};
      this.maxCoursesInProgrammeSubgroups = 0;

      this.getUserGroups(); // get list to select or where to copy
      // if (this.copySubgroupFrom == 'template') {
      //   // this.getTemplateInstitutes();
      // }
    },
    async CopySubgroupFromTemplate() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usergroup/${this.groupId}/usersubgroups`;
      this.startCopybutton = {
        text: "Copiying...",
        disabled: true,
      };
      // console.log({
      //   'usergroup': this.userGroupTo, 'usersubgroup_templates': this.fromSubgroups.map(option => option.value)
      //   , courses: this.selectedFromSubgroupsCourses, course_properties: this.course_properties
      // });
      await this.$axios.post(url, {
        'usergroup': this.userGroupTo, 'usersubgroup_templates': this.fromSubgroups.map(option => option.value)
        , courses: this.selectedFromSubgroupsCourses, course_properties: this.course_properties
      })
        .then((response) => {
          this.$toast.success(`${response.data.name} is created successfully`, {
            position: "top-right",
            duration: 6000,
          });
          this.$emit("success");
        })
        .catch((error) => {
          error;
        });
      this.startCopybutton = {
        text: "Start Copying",
        disabled: false,
      };
    },
    getCustomNames() {
      return this.$store.getters.getCustomNamings;
    },
    async CopySubgroup() {
      // console.log(this.dupliacateSubgroupoptions);
      this.startCopybutton = {
        text: "Copiying...",
        disabled: true,
      };
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/usersubgroup/duplicate`;
      var postData = this.dupliacateSubgroupoptions;
      postData["to_usergroup"] = this.userGroupTo;
      postData["from_usersubgroups"] = this.fromSubgroups.map(option => option.value);
      postData["from_usersubgroups_courses"] = this.selectedFromSubgroupsCourses;
      postData["new_usersubgroup"] = this.new_usersubgroup;
      // console.log(postData);
      await this.$axios
        .post(url, postData)
        .then((response) => {
          // console.log(response);
          // response;
          this.$toast.success(`${response.data.name} is created successfully`, {
            position: "top-right",
            duration: 6000,
          });
          this.$emit("success");
        })
        .catch((error) => {
          error;
        });
      this.startCopybutton = {
        text: "Start Copying",
        disabled: false,
      };

      // console.log(postData);
    },
    async getSubgroups() {
      // this.fromSubgroup = null;
      this.subgroupOptions = [];
      this.fromSubgroups = [];
      let coursesLength = 0;
      this.subgroupsCoursesOptions = {};
      if (this.copySubgroupFrom == 'template') {
        this.templateGroupSubgroupCourses.forEach((group) => {
          if (group.id == this.userGroup) {
            group.usersubgroups.forEach((subgroup) => {
              this.subgroupObjects[subgroup.id] = subgroup;
              this.subgroupOptions.push({
                value: subgroup.id,
                text: `${subgroup.name} (${subgroup.code})`,
              });
              this.$set(this.subgroupsCoursesOptions, subgroup.id, subgroup);
              // this.subgroupsCoursesOptions[subgroup.id] = subgroup;
              coursesLength = Math.max(coursesLength, subgroup.courses.length);
            });
          }
        });
        this.maxCoursesInProgrammeSubgroups = coursesLength;
      }
      else {

        this.userGroupTo = this.userGroup; // same institutte can copy to same programme
        const url =
          this.$store.getters.getAPIKey.mainAPI +
          `/usergroups/${this.userGroup}/usersubgroups?academic_year=${this.academic_year}`;
        await this.$axios
          .get(url)
          .then((response) => {
            response.data.forEach((subgroup) => {
              this.subgroupsCoursesOptions[subgroup.id] = {};
              this.subgroupsCoursesOptions[subgroup.id].courses = [];
              this.subgroupObjects[subgroup.id] = subgroup;
              this.subgroupOptions.push({
                value: subgroup.id,
                text: `${subgroup.name} (${subgroup.code})`,
              });
            });
          })
          .catch((error) => {
            error;
          });
      }
    },

    async getUserGroups() {
      this.userGroups = [];
      this.userGroups.push({
        value: null,
        text: `Choose destination programme`,
      });
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usergroups/allusergroups?subgroup_types_status=1`;
      await this.$axios
        .get(url)
        .then((response) => {
          response.data.forEach((group) => {
            this.userGroupObjects[group.id] = group;
            this.userGroups.push({
              value: group.id,
              text: `${group.name} (${group.code}` + (group.scheme ? `-${group.scheme} Scheme)` : ')')
              // has_interdepartment_courses_group:group.has_interdepartment_courses_group,
            });
          });
        })
        .catch((error) => {
          error;
        });
    },

    async getTemplateUserGroups() {
      this.templateUserGroups = [];
      this.userGroup = null;
      this.subgroupOptions = [];
      this.fromSubgroups = [];
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usergroups/templates?usergroup_to=${this.userGroupTo}`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.templateGroupSubgroupCourses = response.data;
          response.data.forEach((group) => {
            this.templateUserGroups.push({
              value: group.id,
              text: `${group.name} (${group.code}` + (group.scheme ? `-${group.scheme} Scheme)` : ')'),
            });
          });
        })
        .catch((error) => {
          error;
        });
    },
    // async getTemplateInstitutes() {
    //   this.templateInstitutes = [{ value: '', text: 'Select' },];
    //   const url =
    //     this.$store.getters.getAPIKey.mainAPI +
    //     `/institutions/templates`;
    //   await this.$axios
    //     .get(url)
    //     .then((response) => {
    //       response.data.forEach((institute) => {
    //         this.templateInstitutes.push({
    //           value: institute.id,
    //           text: `${institute.name}`,
    //         });
    //       });
    //     })
    //     .catch((error) => {
    //       error;
    //     });
    // },
  },
};
</script>
