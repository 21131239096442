<template>
  <div>
    <div class="lessonPlanListing">
      <div class="deleteLessons mb-2" v-if="deleteLessonPlanIds.length != 0 && !deleteLesson">
        <b-button variant="danger" @click="$bvModal.show('deleteLesson')" size="sm">{{
            deleteLessonPlanIds.length > 1
              ? "Remove Lesson Plans"
              : "Remove Lesson Plan"
        }}</b-button>
      </div>
      <div class="lessonPlans">
        <b-modal id="deleteLesson" no-close-on-backdrop no-close-on-keyboard no-close-on-esc hide-footer hide-header
          centered size="md">
          <h6 style="font-size: 18px; font-weight: 600" class="text-danger">
            <span>
              Deleting Lesson Plan will remove the Topic mapped with attendance.
              Are you Sure you want to proceed?
            </span>
          </h6>
          <div class="buttons">
            <b-button size="sm" variant="primary" @click="deleteLessonPlan()">Proceed</b-button>
            <b-button size="sm" variant="danger" class="ml-2"
              @click="deleteLessonPlanIds = []; $bvModal.hide('deleteLesson')">Cancel</b-button>
          </div>
        </b-modal>
        <div v-if="!lessonCreation">
          <div class="p-2 mb-2 d-flex justify-content-between"
            style="border: 2px solid #eeeeeb; border-left-style: none; border-right-style: none;"
            v-if="$store.getters.defaultInstitute.userRole != 'Student'">
            <div class="d-flex align-items-center">
              <b-button variant="info" class="text-white" size="sm" @click="lessonCreation = !lessonCreation"><i
                  class="fas fa-plus-square text-white mr-1"></i>Add Lesson Plan</b-button>

              <b-form-checkbox v-model="selectAllLessons" v-if="editCourseComponents"
                @change="selectAllLessonsForDeletion()" size="md" class="ml-3">{{
                    selectAllLessons ? "Unselect All" : "Select All"
                }}</b-form-checkbox>

              <!-- <b-button variant="info" size="sm" class="text-white ml-3" @click="goToExcel('CourseLessonsCSV')">Import
                    Excel Sheet<i class="fas fa-file-excel ml-2"></i></b-button> -->
              <span class="text-info btn p-0 mr-0 ml-3" @click="getLessonPlans(course_id)">
                <i class="fas fa-sync-alt fa-2x"></i>
              </span>
              <b-button :disabled="(lessons.length == 0)" variant="success" class="text-white  ml-3" size="sm"
                @click="prepareLessonPlanModelView()">Auto-generate Planned Dates</b-button>
            </div>
            <b-button :disabled="reportBtn.disabled" @click="downloadLessonPlanReportPDF()" variant="danger" size="sm">
              {{ reportBtn.text }}</b-button>
          </div>
        </div>

        <div class="loading" v-if="loadLessons">
          <load-data></load-data>
        </div>
        <div class="table-responsive" style="font-size: 14px; font-weight: 600" v-if="!lessonCreation && !loadLessons">
          <b-overlay :show="deleteLesson" spinner-variant="primary" spinner-type="grow" spinner-small rounded="md">
            <!-- <div v-if="inventoryData.length > 0" class=" shadow  mx-1 my-2">
                          <b-alert show variant="info">
                            <h4 class="alert-heading alert-link" style="width: fit-content; font-size: 1.5rem !important;">
                              Automatically add
                              Lesson Plans from ezygo suggestion!</h4>
                            <ul>
                              <li>{{ inventoryData[0].lesson }} ... </li>
                              <li v-if="inventoryData.length > 1"> {{ inventoryData[1].lesson }} ...
                              </li>
                              <li v-else> ...</li>
                            </ul>
                            <hr>
                            <p class="mb-0">
                              Click to automatically add LessonPlans <b-button @click="importLessonPlansInventory()"
                                class="shadow ml-3" pill size="sm" variant="success">
                                Proceed
                              </b-button>
                            </p>
                          </b-alert>
                        </div> -->
            <br>
            <div v-if="coWarning || moduleWarning" class="alert alert-danger" role="alert">
              <li v-if="coWarning">Please add all <strong>COURSE OUTCOMES</strong> (with serial number) first !</li>
              <li v-if="moduleWarning">Please add all <strong>COURSE MODULES</strong> (with serial number) first !</li>
            </div>
            <br>
            <div class="mb-2" v-if="!editCourseComponents">This data can only be edited by course instructor</div>
            <div class="mb-2 text-danger">The order of topics in the lesson plan is arranged according to their serial
              numbers. To add a new topic in between, simply assign the desired serial number to the new topic, and the
              rest of the serial numbers will automatically adjust.</div>
            <table class="table table-striped table-bordered">
              <thead class="thead-dark text-left">
                <tr>
                  <th v-if="editCourseComponents"></th>
                  <th>Si No</th>
                  <th>Lesson/Topic</th>
                  <th>Planned Date
                    <b-button v-if="editLessonColumn == 'date'" variant="warning" @click="closeEdit()" size="sm"
                      v-b-tooltip.hover title="Close edit box"><i class="fas fa-times"></i></b-button>
                  </th>
                  <th>No. of hours required</th>
                  <th>Course Outcome <br>
                    <b-button v-if="editLessonColumn == 'cos'" variant="success" @click="updateLiveLessonPlan()"
                      size="sm" v-b-tooltip.hover title="Save changes and close edit box"><i
                        class="fas fa-save"></i></b-button>&nbsp;
                    <b-button v-if="editLessonColumn == 'cos'" variant="warning" @click="closeEdit()" size="sm"
                      v-b-tooltip.hover title="Close edit & discard changes"><i class="fas fa-times"></i></b-button>
                  </th>
                  <th>Course Module</th>
                  <th>Cognitive levels</th>
                  <th v-if="editCourseComponents"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="lessons.length == 0">
                  <td colspan="100">
                    <p class="text-danger font-weight-bolder text-center">
                      No Lesson plans created yet!
                    </p>
                  </td>
                </tr>

                <tr v-else v-for="(lesson, lessonIndex) in lessons" :key="lessonIndex">
                  <td class="align-middle" v-if="editCourseComponents">
                    <b-form-checkbox size="sm" v-model="deleteLessonPlanIds" :value="lesson.id">
                    </b-form-checkbox>
                  </td>
                  <td class="align-middle">
                    {{ lesson.si_no }}
                  </td>
                  <td class="align-middle">
                    {{ lesson.lesson }}
                  </td>
                  <td class="align-middle">
                    <div v-if="(editLessonId == lesson.id && editLessonColumn == 'date')">
                      <b-form-input v-if="(!editLessonLoading)" @blur="updateLiveLessonPlan()"
                        placeholder="14/02/2023,15/02/2023,..." v-model="editLessonDate"></b-form-input>
                      <!-- <date-picker style="width:110px;" v-if="(!editLessonLoading)" v-model="editLessonDate"
                        format="DD/MM/YYYY" placeholder="Select Date" @pick="updateLiveLessonPlan()">
                      </date-picker> -->
                      <b-spinner v-else variant="primary"></b-spinner>
                    </div>
                    <span style="color: #2929aa; text-decoration: underline; cursor: pointer;"
                      @click="lessonPlanDateEditing(lesson.id, lesson.planned_dates)" v-else>
                      {{ lesson.planned_dates ?
                          lesson.planned_dates : 'Date not updated'
                      }}
                      <i class="fas fa-pen"></i></span>
                  </td>
                  <td class="align-middle text-center">
                    {{ lesson.required_hours }}
                  </td>
                  <td class="text-center align-middle">

                    <div v-if="(editLessonId == lesson.id && editLessonColumn == 'cos')">
                      <div v-if="(!editLessonLoading)">
                        <multiselect v-model="editLessonCOs" :options="courseOutcomeOptions" :multiple="true"
                          :close-on-select="false" :clear-on-select="false" placeholder="Select CO's" label="text"
                          track-by="value" :preselect-first="false">
                        </multiselect>
                      </div>
                      <b-spinner v-else variant="primary"></b-spinner>
                    </div>
                    <span style="color: #2929aa; text-decoration: underline; cursor: pointer;"
                      @click="lessonPlanCOEditing(lesson.id, lesson.course_outcomes)" v-else>
                      <span v-if="lesson.course_outcomes.length == 0">-</span>
                      <span v-else>
                        <span v-for="(outcome, outcomeIndex) in lesson.course_outcomes" :key="outcomeIndex"
                          :id="'co' + outcomeIndex">
                          {{ outcome.code }}
                          <b-tooltip :target="'co' + outcomeIndex" triggers="hover">
                            {{ outcome.outcome }}
                          </b-tooltip>
                        </span>
                      </span>
                      <i class="fas fa-pen"></i>
                    </span>



                    <!-- <div>
                      <div v-if="lesson.course_outcomes.length == 0">
                        <span>-</span>
                      </div>
                      <div class="d-flex flex-column" v-else>
                        <span v-for="(outcome,
                        outcomeIndex) in lesson.course_outcomes" :key="outcomeIndex"
                          :class="outcome.outcome != '' ? 'text-info text-capitalize' : 'text-dark text-capitalize'"
                          :id="'co' + outcomeIndex">
                          {{ outcome.code }}
                          <b-tooltip v-if="outcome.outcome != ''" :target="'co' + outcomeIndex" triggers="hover">
                            {{ outcome.outcome }}
                          </b-tooltip>
                        </span>
                      </div>
                    </div> -->
                  </td>
                  <td class="align-middle">
                    <div v-if="lesson.course_module">
                      <span
                        :class="lesson.course_module.lessons != '' ? 'text-info text-capitalize' : 'text-capitalize'"
                        :id="'cm' + lessonIndex">{{ lesson.course_module.module == "" ? "-"
                            : lesson.course_module.module
                        }}
                      </span>
                      <b-tooltip :target="'cm' + lessonIndex" triggers="hover">
                        {{ lesson.course_module.lessons }}
                      </b-tooltip>
                    </div>
                  </td>
                  <td> <span v-for="(level, ClIndex) in lesson.cognitive_levels" :key="ClIndex"> {{ level }},</span>
                  </td>
                  <td class="align-middle" v-if="editCourseComponents">
                    <span class="btn m-0 p-0 text-info col-md-0" @click="
                      updateLessonPlan(
                        lesson.id,
                        lesson.si_no,
                        lesson.lesson,
                        lesson.planned_dates,
                        lesson.required_hours,
                        lesson.course_id,
                        lesson.course_outcomes,
                        lesson.course_module_id,
                        lesson.cognitive_levels
                      )
                    "><i class="far fa-edit"></i></span>

                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <span class="text-danger" @click="deleteLessonPlanIds = [lesson.id]; $bvModal.show('deleteLesson')"
                      size="sm">
                      <i class="fas fa-trash"></i></span>
                  </td>
                </tr>
                <tr v-if="excelImportAlert">
                  <td colspan="8">
                    <b-alert variant="info" class="text-center text-danger" show>Please refresh/change tab after excel
                      import</b-alert>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-overlay>
        </div>
      </div>
      <div class="createLessonPlan" v-if="lessonCreation">
        <LessonPlans v-on:goto-excel="goToExcel('CourseLessonsCSV')" :courseId="course_id"
          :updateLessonDetails="updateLessonDetails" @cancel="lessonCreation = !lessonCreation"
          @courseCreated="updateList" @cancelUpdate="cancelUpdate()" />
      </div>
    </div>

    <!-- model  -->

    <b-modal id="prepareLessonPlanModel" no-close-on-backdrop no-close-on-keyboard no-close-on-esc hide-footer
      hide-header centered size="md">
      <b-container fluid>
        <h3>Holidays are already factored into auto date generation for lessonplan.</h3>
        <b-form-group label="Start date (Optional):" v-slot="{ ariaDescribedby }" class="m-1 ">

          <b-form-datepicker :aria-describedby="ariaDescribedby" placeholder="Select Date"
            v-model="lessonPlanStartDate">
          </b-form-datepicker>
        </b-form-group>
        <b-form-group label="End date (Optional):" v-slot="{ ariaDescribedby }" class="m-1 mb-3 ">

          <b-form-datepicker :aria-describedby="ariaDescribedby" placeholder="Select Date" v-model="lessonPlanEndDate">
          </b-form-datepicker>
        </b-form-group>


        <b-form-group label="Enter date to exclude (YYYY-MM-DD) separate by comma" v-slot="{ ariaDescribedby }"
          class="m-1">

          <b-form-tags :aria-describedby="ariaDescribedby" input-id="tags-pills" v-model="lessonPlanExcludedDates"
            tag-variant="success" tag-pills size="sm" separator=" ,;" placeholder="2022-03-27,"></b-form-tags>
          <span class="text-info">These dates will be neglected when generate planned dates</span>
        </b-form-group>
        <b-row>
          <b-col>
            <b-form-checkbox id="checkbox-1" v-model="lessonPlanExcludeSuspendClass" name="checkbox-1" value="accepted"
              unchecked-value="not_accepted">
              Exclude Class Suspended Date/Sessions
            </b-form-checkbox></b-col>
        </b-row>

        <b-form-group>
          <div class="d-flex flex-row justify-content-between mt-3">
            <b-button variant="danger" size="md" @click="$bvModal.hide('prepareLessonPlanModel')">Cancel</b-button>
            <div>
              <b-button variant="primary" size="md" @click="prepareLessonPlan()"
                :disabled="prepareLessonPlanButton.disabled">
                {{ prepareLessonPlanButton.text }}</b-button>
            </div>
          </div>
        </b-form-group>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
// import DatePicker from "vue2-datepicker";
import LessonPlans from "./LessonPlans.vue";
// import moment from "moment";
export default {
  props: ["course_id", "courseInstructor", "editCourseComponents", "courseInfo"],
  async created() {
    await Promise.all(
      await this.getLessonPlans(),
      //  co and module added or not
      await this.getLessonPlanWarning(),
    );
  },
  components: {
    LessonPlans,
    // DatePicker,
  },
  data() {
    return {
      loadLessons: false,
      deleteLesson: false,
      lessonCreation: false,
      deleteLessonPlanIds: [],
      selectAllLessons: false,
      lessons: [],
      updateLessonDetails: {
        id: null,
        lesson: {},
      },
      lessonList: {},
      reportBtn: {
        text: "Download PDF",
        disabled: false,
      },
      excelImportAlert: false,
      // inventoryData: [],
      coWarning: false, moduleWarning: false,
      prepareLessonPlanButton: {
        text: "Proceed",
        disabled: false,
      },
      lessonPlanStartDate: null,
      lessonPlanEndDate: null,
      lessonPlanExcludedDates: [],
      lessonPlanExcludeSuspendClass: false,

      // live editting
      editLessonDate: null,
      editLessonId: null,
      editLessonLoading: false,
      editLessonColumn: '', // date, cos
      editLessonCOs: [],
      courseOutcomeOptions: [],
    };
  },
  methods: {
    closeEdit() {
      this.editLessonId = null;
      this.editLessonDate = null;
      this.editLessonLoading = false;
      this.editLessonColumn = '';
      this.editLessonCOs = [];
    },
    async lessonPlanCOEditing(id, cos) {
      if (this.editLessonColumn == 'cos' && this.editLessonId) await this.updateLiveLessonPlan();
      this.editLessonColumn = 'cos';
      this.editLessonId = id;
      this.editLessonCOs = [];
      this.editLessonLoading = true;
      if (this.courseOutcomeOptions.length == 0) await this.getCourseOutcomes();
      this.editLessonCOs = cos.map((co) => {
        return {
          value: co.id, text: co.code,
          outcome: co.outcome,
        };
      });
      this.editLessonLoading = false;
    },
    async updateLiveLessonPlan() {
      this.editLessonLoading = true; let postData = {};
      if (this.editLessonColumn == 'date') {
        // let date = moment(this.editLessonDate).format("YYYY/MM/DD");
        postData = { planned_dates: this.editLessonDate }
      }
      if (this.editLessonColumn == 'cos') {
        let course_outcomes = this.editLessonCOs.map((co) => {
          return co.value;
        });
        postData = { course_outcomes: course_outcomes }
      }
      const url =
        this.$store.getters.getAPIKey.lessonplans +
        `/${this.editLessonId}`;
      await axios
        .put(url, postData)
        .then(() => {
          if (this.editLessonColumn == 'date') {
            this.lessons.forEach(lesson => {
              if (lesson.id == this.editLessonId) lesson.planned_dates = this.editLessonDate;
            });
          }
          if (this.editLessonColumn == 'cos') {
            this.lessons.forEach(lesson => {
              if (lesson.id == this.editLessonId) lesson.course_outcomes = this.editLessonCOs.map((co) => {
                return { id: co.value, code: co.text, outcome: co.outcome }
              });
            });
          }
          this.$toast.success("Lesson Plans updated successfully.", {
            position: "top",
            duration: 2000,
          });
        })
        .catch(() => {
          this.$toast.error("Failed to update lesson plan, please try again", {
            position: "top",
            duration: 5000,
          });
        });

      this.editLessonId = null;
      this.editLessonDate = null;
      this.editLessonLoading = false;
      this.editLessonColumn = '';
      this.editLessonCOs = [];
    },
    lessonPlanDateEditing(id, date) {
      this.editLessonColumn = 'date';
      this.editLessonId = id;
      this.editLessonDate = date;
      // new Date(
      //   this.updateLessonDetails.lesson.planned_date
      // );
    },
    async prepareLessonPlanModelView() {
      this.prepareLessonPlanButton.text = "Please wait...";
      this.prepareLessonPlanButton.disabled = true;
      this.$bvModal.show('prepareLessonPlanModel');
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/usersubgroups/${this.courseInfo.usersubgroup_id}`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.lessonPlanStartDate = response.data.start_date;
          this.lessonPlanEndDate = response.data.end_date;
        });
      this.lessonPlanExcludedDates = [];

      this.prepareLessonPlanButton.text = "Proceed";
      this.prepareLessonPlanButton.disabled = false;
    },
    async prepareLessonPlan() {
      this.prepareLessonPlanButton.text = "Generating...";
      this.prepareLessonPlanButton.disabled = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/lessonplans/prepare/plannedhours`;
      await this.$axios
        .post(url, {
          start_date: this.lessonPlanStartDate, end_date: this.lessonPlanEndDate,
          date_excludes: this.lessonPlanExcludedDates, course: this.course_id,
          exclude_suspend_class: this.lessonPlanExcludeSuspendClass
        })
        .then(() => {
          this.$bvModal.hide('prepareLessonPlanModel');
          this.$toast.success("Successfully generated", {
            position: "top",
            duration: 3000,
          });

          this.getLessonPlans();
        }).catch(() => {
          this.$toast.error("Failed to generated", {
            position: "top",
            duration: 3000,
          });
        });

      this.prepareLessonPlanButton.text = "Proceed";
      this.prepareLessonPlanButton.disabled = false;
    },
    goToExcel(path) {
      this.$toast.info("Please refresh after excel import", {
        position: "top",
        duration: 3000,
      });
      this.excelImportAlert = true;
      let routeData = this.$router.resolve({
        name: path,
        params: {
          course_id: this.course_id,
        },
      });
      setTimeout(function () {
        window.open(routeData.href, "_blank");
      }, 1500)
    },
    async selectAllLessonsForDeletion() {
      if (this.selectAllLessons) {
        this.lessons.forEach((lesson) => {
          this.deleteLessonPlanIds.push(lesson.id);
        });
      } else {
        this.deleteLessonPlanIds = [];
      }
    },
    async downloadLessonPlanReportPDF() {
      this.reportBtn = {
        text: "Downloading....",
        disabled: true,
      };

      const url =
        this.$store.getters.getAPIKey.mainAPI + `/lessonplans/report/pdfexport`;
      await this.$axios
        .post(url, { course: this.course_id }, { responseType: "blob" })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "lessonPlanReport.pdf");
          document.body.appendChild(link);
          link.click();
          link.remove();
          // this.loadReport = false;
          this.reportBtn = {
            text: "Download PDF",
            disabled: false,
          };
          // console.log(response);
        })
        .catch(() => {
          this.reportBtn = {
            text: "Download PDF",
            disabled: false,
          };
          // this.loadReport = false;
          //handle 422------------------------------------------------------------------------------------------------------------------
          // console.log(error.response);
        });
    },
    async getLessonPlanWarning() {
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/lessonplans/warnings?course=${this.course_id}`;
      await axios
        .get(url)
        .then((response) => {
          this.coWarning = response.data.coWarning;
          this.moduleWarning = response.data.moduleWarning;
        });
    },
    async getLessonPlans() {
      this.loadLessons = true;
      let lessonListUrl = this.$store.getters.getAPIKey.lessonList;
      const url = lessonListUrl.replace("course_id", this.course_id);
      await axios
        .get(url)
        .then((response) => {
          this.lessons = response.data;
          // this.lessons.forEach((lesson) => {
          //   const plannedDate = lesson.planned_date;
          //   lesson.planned_date = plannedDate == '0000-00-00 00:00:00' || plannedDate == null ? null : moment(plannedDate);
          // });
          this.loadLessons = false;
          // if (this.lessons.length < 4) this.getLessonPlansInventorySuggestions();
          // console.log(response);
        })
        .catch(() => {
          this.loadLessons = false;
          // console.log(error.response);
        });
    },
    // getLessonPlansInventorySuggestions() {
    //   const url =
    //     this.$store.getters.getAPIKey.mainAPI + `/inventory/courses/${this.course_id}/suggestions?inventorytype=lessonplans`;
    //   axios
    //     .get(url)
    //     .then((response) => {
    //       this.inventoryData = response.data.lessonplans;
    //     });
    // },
    // importLessonPlansInventory() {
    //   this.loadLessons = true;
    //   const url =
    //     this.$store.getters.getAPIKey.mainAPI + `/inventory/courses/${this.course_id}/import?inventorytype=lessonplans`;
    //   axios
    //     .get(url)
    //     .then(() => {
    //       this.getLessonPlans();
    //     });
    // },
    async deleteLessonPlan() {
      this.$bvModal.hide("deleteLesson");
      this.deleteLesson = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/lessonplans/deletemany`;
      await this.$axios
        .post(url, { lessonplans: this.deleteLessonPlanIds })
        .then(() => {
          this.$toast.success(
            this.deleteLessonPlanIds.length > 1
              ? "Lessons Deleted"
              : "Lesson Deleted",
            {
              position: "top",
              duration: 3000,
            }
          );
          this.deleteLessonPlanIds = [];
          this.getLessonPlans();
          this.deleteLesson = false;
        })
        .catch(() => {
          this.$toast.error("Something went wrong", {
            position: "top",
            duration: 3000,
          });
          this.deleteLesson = false;
          // console.log(error);
        });
    },
    updateList(value) {
      this.getLessonPlans(value);
    },
    cancelUpdate() {
      this.updateLessonDetails.id = null;
      this.updateLessonDetails.lesson = {};
    },
    updateLessonPlan(id, si_no, name, date, hours, course_id, outcomeId, moduleId, cognitive_levels) {
      this.updateLessonDetails.id = id;
      this.updateLessonDetails.lesson.si_no = si_no;
      this.updateLessonDetails.lesson.lesson = name;
      this.updateLessonDetails.lesson.required_hours = hours;
      this.updateLessonDetails.lesson.planned_dates = date;
      this.updateLessonDetails.lesson.course_id = course_id;
      this.updateLessonDetails.lesson.course_outcomes = outcomeId;
      this.updateLessonDetails.lesson.course_module_id = moduleId;
      this.updateLessonDetails.lesson.cognitive_levels = cognitive_levels;
      this.lessonCreation = !this.lessonCreation;
    },
    async getCourseOutcomes() {
      let courseOutcomeUrl = this.$store.getters.getAPIKey.getCourseOutcomes;
      const url = courseOutcomeUrl.replace("course_id", this.course_id);
      await axios
        .get(url)
        .then((response) => {
          response.data.forEach((cOutcome) => {
            this.courseOutcomeOptions.push({
              value: cOutcome.id,
              text: cOutcome.code,
              outcome: cOutcome.outcome,
            });
          });
        })
        .catch(() => {
        });
    },
  },
};
</script>

<style >
.lessonPlans i {
  font-size: 15px !important;
}

.mx-input-wrapper.mx-icon-clear {
  display: none !important;
}
</style>
