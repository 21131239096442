<template>
  <div>
    <b-overlay
      :show="loadData"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="md"
    >
      <div class="p-3">
        <b-form
          @submit.prevent="prepareAssessmentReports()"
          class="w-50 border-0"
        >
          <b-form-group label="Select Date">
            <b-row>
              <b-col md="6">
                <label for="example-datepickerfrom">From:</label>
                <b-form-datepicker
                  id="example-datepickerfrom"
                  v-model="reports.from"
                  class="mb-2"
                ></b-form-datepicker>
              </b-col>
              <b-col md="6">
                <label for="example-datepickerto">To:</label>
                <b-form-datepicker
                  id="example-datepickerto"
                  v-model="reports.to"
                  class="mb-2"
                ></b-form-datepicker>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group>
            <b-button
              v-if="showReport"
              type="Submit"
              variant="primary"
              size="md"
              >Go</b-button
            >
          </b-form-group>
        </b-form>

        <br /><br />
        <b-modal
          id="assessmentReport"
          no-close-on-backdrop
          no-close-on-keyboard
          no-close-on-esc
          hide-footer
          title="Assessment Report"
          modal-class="assessmentReport"
        >
          <div class="closeButton">
            <b-button
              class="ml-2 mb-3"
              variant="primary"
              size="sm"
              v-if="reportExamId == null"
              :disabled="exportCsvBtn.disabled || exams.length == 0"
              @click="exportToExcel('xlsx')"
              >{{ exportCsvBtn.text }}</b-button
            >
            <br />
            <b-breadcrumb>
              <b-breadcrumb-item
                :active="activeReport.assessmentReport"
                @click="closeDetailedReport()"
                >Assessment Report</b-breadcrumb-item
              >
              <b-breadcrumb-item
                v-if="exams.length != 0"
                :active="activeReport.detailedReport"
                @click="
                  activeDetailedReport.id == null
                    ? getDetailedReportOf(exams[0].id, exams[0].name)
                    : getDetailedReportOf(
                        activeDetailedReport.id,
                        activeDetailedReport.name
                      )
                "
                >{{
                  activeDetailedReport.name == null
                    ? exams[0].name
                    : activeDetailedReport.name
                }}</b-breadcrumb-item
              >
            </b-breadcrumb>
          </div>
          <br />
          <div v-if="reportExamId != null">
            <detailed-assessment-report
              v-bind:exam-id="reportExamId"
            ></detailed-assessment-report>
          </div>
          <div v-else>
            <div
              class="table-responsive"
              v-if="showReport && exams.length != 0"
            >
              <table
                class="table table-bordered table-striped"
                ref="assessmentReport"
              >
                <thead
                  class="thead-dark"
                  style="font-size: 14px; font-weight: 600; text-align: center"
                >
                  <tr>
                    <th>No.</th>
                    <th>Name</th>
                    <th>Course</th>
                    <th>
                      {{
                        getCustomNames.userSubgroup.custom_name != null
                          ? getCustomNames.userSubgroup.custom_name
                          : "Usersubgroup"
                      }}
                    </th>
                    <th>
                      {{
                        getCustomNames.userGroup.custom_name != null
                          ? getCustomNames.userGroup.custom_name
                          : "Usergroup"
                      }}
                    </th>
                    <th>Start</th>
                    <th>End</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(exam, examIndex) in exams"
                    :key="examIndex"
                    style="font-size: 14px; font-weight: 600; text-align: center"
                  >
                    <td>{{ examIndex + 1 }}</td>
                    <td
                      class="text-info btn p-0 m-0"
                      @click="getDetailedReportOf(exam.id, exam.name)"
                    >
                      {{ exam.name }}
                    </td>
                    <td>
                      {{
                        exam.course.length != 0 &&
                        courseSubgroupGroupArray[exam.course[0].id]
                          ? courseSubgroupGroupArray[exam.course[0].id].course
                              .code
                          : "-"
                      }}
                    </td>
                    <td>
                      {{
                        exam.course.length != 0 &&
                        courseSubgroupGroupArray[exam.course[0].id]
                          ? courseSubgroupGroupArray[exam.course[0].id]
                              .usersubgroup.code
                          : "-"
                      }}
                    </td>
                    <td>
                      {{
                        exam.course.length != 0 &&
                        courseSubgroupGroupArray[exam.course[0].id]
                          ? courseSubgroupGroupArray[exam.course[0].id]
                              .usergroup.code
                          : "-"
                      }}
                    </td>
                    <td class="text-center">
                      {{ exam.starts_at.format("DD/MM/YYYY") }} <br />
                      {{ exam.starts_at.format("hh:mm A") }}
                    </td>
                    <td class="text-center">
                      {{ exam.end_at.format("DD/MM/YYYY") }} <br />
                      {{ exam.end_at.format("hh:mm A") }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p v-else class="text-danger">No Data Found!</p>
          </div>
        </b-modal>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import axios from "axios";
// import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
import XLSX from "xlsx";
import DetailedAssessmentReport from "./DetailedAssessmentReports";
import acl from "../../../../../authorization/acl.js";
export default {
  async created() {
    const groupAdmin = await acl.isUserGranted("groupAdminAssessmentReport");
    this.groupAdmin = groupAdmin;
    const subgroupAdmin = await acl.isUserGranted(
      "subgroupAdminAssessmentReport"
    );
    this.subgroupAdmin = subgroupAdmin;
    const instructor = await acl.isUserGranted("instructorAssessmentReport");
    this.instructor = instructor;
  },
  components: {
    // DatePicker,
    DetailedAssessmentReport,
  },
  computed: {
    getCustomNames() {
      return this.$store.getters.getCustomNamings;
    },
  },
  data() {
    return {
      groupAdmin: null,
      subgroupAdmin: null,
      instructor: null,
      loadData: false,
      //-----------------------------------------Export To CSV-------------------------------------------------
      exportCsvBtn: {
        text: "Export to Excel",
        disabled: false,
      },
      activeReport: {
        assessmentReport: true,
        detailedReport: false,
      },
      activeDetailedReport: {
        id: null,
        name: null,
      },
      assessmentReportOptions: [
        {
          text: "Home",
          active: false,
        },
        {
          text: "Assessment Report",
          active: true,
        },
        {
          text: "Detailed Report",
          active: false,
        },
      ],
      today: moment(),
      reportDate: "",
      reports: {
        from: null,
        to: null,
      },
      showReport: true,
      courses: [],
      exams: [],

      courseSubgroupGroupArray: {},

      reportExamId: null,
    };
  },
  methods: {
    getDetailedReportOf(examId, examName) {
      this.activeDetailedReport.id = examId;
      this.activeDetailedReport.name = examName;
      this.reportExamId = examId;
      this.activeReport.assessmentReport = false;
      this.activeReport.detailedReport = true;
    },
    closeDetailedReport() {
      this.reportExamId = null;
      this.activeReport.assessmentReport = true;
      this.activeReport.detailedReport = false;
    },
    async exportToExcel(type, fn, dl) {
      this.exportCsvBtn.text = "Please wait...";
      this.exportCsvBtn.disabled = true;
      var elt = this.$refs.assessmentReport;
      var wb = await XLSX.utils.table_to_book(elt, { sheet: "Sheet JS" });
      this.exportCsvBtn.text = "Export to Excel";
      this.exportCsvBtn.disabled = false;
      return dl
        ? XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" })
        : XLSX.writeFile(wb, fn || "assessments." + (type || "xlsx"));
    },
    // prepareReportDates() {
    //   const separateDate = this.reportDate;
    //   const fromDate = moment(separateDate[0]).format("YYYY/MM/DD");
    //   const toDate = moment(separateDate[1]).format("YYYY/MM/DD");
    //   this.reports.from = fromDate;
    //   this.reports.to = toDate;
    // },
    closeReport() {
      this.$bvModal.hide("assessmentReport");
      this.activeDetailedReport.id = null;
      this.activeDetailedReport.name = null;
      this.activeReport.assessmentReport = true;
      this.activeReport.detailedReport = false;
      this.reportExamId = null;
      this.exams = [];

      // this.$emit('closeReport');
    },
    async prepareAssessmentReports() {
      this.loadData = true;
      this.showReport = false;
      // if (this.reportDate != "") {
      //   await this.prepareReportDates();
      // }
      let reportsData = await this.getExamsReports();

      let courses = (this.courses = reportsData.courses);
      this.exams = reportsData.exams;
      await this.exams.forEach((examDate) => {
        const start = moment(
          moment.utc(examDate.starts_at, "YYYY-MM-DD HH:mm:ss").toDate()
        );
        const end = moment(
          moment.utc(examDate.end_at, "YYYY-MM-DD HH:mm:ss").toDate()
        );

        examDate.starts_at = start;
        examDate.end_at = end;
      });

      await courses.forEach((course) => {
        this.courseSubgroupGroupArray[course.id] = {
          course: {
            name: course.name,
            code: course.code,
          },
          usersubgroup: {
            name: course.usersubgroup.name,
            code: course.usersubgroup.code,
          },
          usergroup: {
            name: course.usersubgroup.usergroup.name,
            code: course.usersubgroup.usergroup.code,
          },
        };
      });

      this.showReport = await true;
      this.loadData = await false;
      await this.$bvModal.show("assessmentReport");
      this.$forceUpdate();
    },

    async getExamsReports() {
      let postData = {
        start_date: this.reports.from,
        upto_date: this.reports.to,
      };

      const url = this.$store.getters.getAPIKey.mainAPI + `/exams/report`;
      let reports = [];
      await axios
        .post(url, postData)
        .then((response) => {
          reports = response.data;
          reports;
        })
        .catch((error) => {
          //handle 422------------------------------------------------------------------------------------------------------------------
          error;
        });

      return reports;
    },
  },
};
</script>

<style >
.assessmentReport .modal-dialog {
  max-width: 100%;
  max-height: 100vh;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  position: fixed;
  z-index: 100000;
}

.assessmentReport .modal-body {
  overflow: scroll;
}
</style>
