<template>
  <div>
    <div id="cardView">
      <b-list-group
        style="
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
        "
      >
        <b-list-group-item
          v-for="users in institutionUsersList"
          :key="users.id"
        >
          <div>
            <b-card>
              <b-card-body class="p-0 m-0">
                <div class="title">
                  <h4>
                    {{
                      users.first_name != null && users.last_name != null
                        ? users.first_name + " " + (users.last_name || "")
                        : "FullName"
                    }}
                  </h4>
                  <h5>{{ users.user.username }}</h5>
                </div>
                <div class="userDetails p-3">
                  <div class="userIcons d-flex justify-content-between">
                    <div>
                      <span
                        ><i
                          class="fas fa-user-circle fa-3x text-white"
                          style="background-color: #7b7b7b"
                        ></i
                      ></span>
                      <p
                        v-if="!users.user_approved"
                        class="text-center mt-2 text-dark font-weight-bolder"
                        style="background-color: #f06060; border-radius: 15px"
                      >
                        Pending
                      </p>
                      <p
                        v-else
                        class="text-center mt-2 text-white font-weight-bolder bg-success p-1"
                        style="border-radius: 15px"
                      >
                        Approved
                      </p>
                    </div>
                    <span
                      ><i class="fas fa-certificate fa-3x text-white"></i>
                    </span>
                    <span
                      ><i class="fas fa-external-link-alt fa-3x text-white"></i
                    ></span>
                  </div>
                  <div class="usersInfo">
                    <b-list-group>
                      <b-list-group-item
                        class="d-flex justify-content-baseline"
                      >
                        <span><i class="fas fa-user-tie fa-2x ml-1"></i></span>
                        <p class="ml-3">{{ users.institution_role.name }}</p>
                      </b-list-group-item>
                      <b-list-group-item
                        class="d-flex justify-content-baseline"
                      >
                        <span><i class="fas fa-users fa-2x ml-1"></i></span>
                        <p class="ml-3">Group Code</p>
                      </b-list-group-item>
                      <b-list-group-item
                        class="d-flex justify-content-baseline"
                      >
                        <span><i class="fas fa-at fa-2x ml-1"></i></span>
                        <p class="ml-3">{{ users.user.email }}</p>
                      </b-list-group-item>
                      <b-list-group-item
                        class="d-flex justify-content-baseline"
                      >
                        <span
                          ><i class="fas fa-mobile-alt fa-2x ml-1"></i
                        ></span>
                        <p class="ml-3">{{ users.user.mobile }}</p>
                      </b-list-group-item>
                    </b-list-group>
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </div>
        </b-list-group-item>
      </b-list-group>
    </div>
  </div>
</template>

<script>
export default {
  props: ["institutionUsersList"],
};
</script>

<style >
#cardView .card {
  width: 100px;
  background-color: #6666ae;
  border-radius: 5px;
}

#cardView .list-group .list-group-item {
  border-style: none;
}

#cardView .card .userDetails {
  background-color: white;
}

#cardView .card .userDetails .userIcons i {
  padding: 15px;
  border-radius: 50px;
  background-color: #df6793;
}
</style>
