<template>
  <div>
    <div class="textBook">
      <br>
      <div v-if="!edit && $store.getters.defaultInstitute.userRole != 'Student'" class=""
        style="border: 2px solid #17a2b8; padding: 15px; border-radius: 5px;">
        <ul>
          You have two options to copy course data:
          <li>
            Copy from University Resources <span style="color: #2929aa; text-decoration: underline; cursor: pointer;"
              @click="copy_from = 'template'; $bvModal.show('copyCoursePropertiesModel')">Click here to proceed</span>
          </li>
          <li>Copy from previous academic year <span style="color: #2929aa; text-decoration: underline; cursor: pointer;"
              @click="copy_from = 'myinstitute'; $bvModal.show('copyCoursePropertiesModel')">Click here to
              proceed</span>
          </li>
        </ul>
        <b-modal id="copyCoursePropertiesModel" no-close-on-backdrop no-close-on-keyboard no-close-on-esc hide-footer
          hide-header centered size="md">
          <CopyCourseProperties :copy_from_suggestion="copy_from" :course_id="courseInfo.id"
            @success="propertyCopyCompleted()" @cancel="$bvModal.hide('copyCoursePropertiesModel')" />
        </b-modal>
      </div>
      <div v-if="!edit && $store.getters.defaultInstitute.userRole != 'Student'" class="text-center m-2 text-primary">
        OR </div>
      <b-form @submit.prevent="updateCourse">
        <b-form-group class="text-right">
          <b-button size="sm" variant="info" v-if="
            !edit && $store.getters.defaultInstitute.userRole != 'Student'
          " @click="edit = !edit">Edit</b-button>
        </b-form-group>


        <b-form-group label="Aims:">
          <b-form-textarea rows="3" max-rows="6" :disabled="submitBtn.disabled" v-if="edit"
            v-model="course.aims"></b-form-textarea>
          <pre style="white-space: pre-line; magrin: 0px" v-else> {{ course.aims == null ? "-" : course.aims }}
          </pre>
        </b-form-group>

        <b-form-group label="Objectives:">
          <b-form-textarea rows="3" max-rows="6" :disabled="submitBtn.disabled" v-if="edit"
            v-model="course.objectives"></b-form-textarea>
          <pre style="white-space: pre-line; magrin: 0px" v-else> {{ course.objectives == null ? "-" : course.objectives }}
          </pre>
        </b-form-group>

        <b-form-group label="Preamble:">
          <b-form-textarea rows="3" max-rows="6" :disabled="submitBtn.disabled" v-if="edit"
            v-model="course.preamble"></b-form-textarea>
          <pre style="white-space: pre-line; magrin: 0px" v-else> {{ course.preamble == null ? "-" : course.preamble }}
          </pre>
        </b-form-group>

        <b-form-group label="Pre-requisites:">
          <b-form-textarea rows="3" max-rows="6" :disabled="submitBtn.disabled" v-if="edit"
            v-model="course.pre_requisites"></b-form-textarea>
          <pre style="white-space: pre-line; magrin: 0px" v-else>
            {{ course.pre_requisites == null ? "-" : course.pre_requisites }}
          </pre>
        </b-form-group>

        <b-form-group label="Text-Books:">
          <b-form-textarea v-model="course.text_books" rows="3" max-rows="6" v-if="edit" :disabled="submitBtn.disabled">
          </b-form-textarea>
          <pre style="white-space: pre-line; magrin: 0px" v-else>
            {{ course.text_books == null ? "-" : course.text_books }}
          </pre>
        </b-form-group>

        <b-form-group label="References:">
          <b-form-textarea v-model="course.reference_docs" rows="3" max-rows="6" v-if="edit"
            :disabled="submitBtn.disabled"></b-form-textarea>
          <pre style="white-space: pre-line; magrin: 0px" v-else>
            {{ course.reference_docs == null ? "-" : course.reference_docs }}
          </pre>
        </b-form-group>
        <b-form-group label="LTP Credit">
          <div class="ltp_credit" v-if="edit">
            <b-form-input v-model="course.ltp_credits" placeholder="LTP Credit" @input="handleNumber"></b-form-input>
          </div>
          <div class="ltp_credit" v-else>
            <span>{{
                course.ltp_credits == "" ? "-" : course.ltp_credits
            }}</span>
          </div>
        </b-form-group>
        <b-form-group v-if="edit">
          <b-button variant="primary" size="sm" type="submit" :disabled="submitBtn.disabled">{{ submitBtn.text }}
          </b-button>
        </b-form-group>
      </b-form>
    </div>
  </div>
</template>

<script>
import CopyCourseProperties from "./CopyCourseProperties.vue"
export default {
  props: ["courseInfo"],
  async created() {
    this.$set(this.course, "name", this.courseInfo.name);
    this.$set(this.course, "code", this.courseInfo.code);
    this.$set(
      this.course,
      "pre_requisites",
      this.courseInfo.property.pre_requisites
    );
    this.$set(this.course, "text_books", this.courseInfo.property.text_books);
    this.$set(this.course, "preamble", this.courseInfo.property.preamble);
    this.$set(this.course, "aims", this.courseInfo.property.aims);
    this.$set(this.course, "objectives", this.courseInfo.property.objectives);
    this.$set(
      this.course,
      "reference_docs",
      this.courseInfo.property.reference_docs
    );
    this.$set(this.course, "ltp_credits", this.courseInfo.property.ltp_credits);
  },
  components: {
    CopyCourseProperties,
  },
  data() {
    return {
      course: {},
      edit: false,
      ltp_credit: {
        l: "",
        t: "",
        p: "",
        credit: "",
      },
      submitBtn: {
        text: "Submit",
        disabled: false,
      },
      copy_from: '',
    };
  },
  methods: {
    propertyCopyCompleted() {
      this.$bvModal.hide('copyCoursePropertiesModel');
      this.$emit("CourseUpdated");
    },
    handleNumber() {
      if (
        isNaN(this.ltp_credit.l) ||
        isNaN(this.ltp_credit.t) ||
        isNaN(this.ltp_credit.p) ||
        isNaN(this.ltp_credit.credit)
      ) {
        return;
      }
    },
    async updateCourse() {
      this.submitBtn.text = "Please Wait...";
      this.submitBtn.disabled = true;

      const url =
        this.$store.getters.getAPIKey.updateCourse.replace(
          "course_id",
          this.courseInfo.id
        ) + `?course_property=1`;
      await this.$axios
        .put(url, this.course)
        .then((response) => {
          response;
          this.edit = !this.edit;
          this.$emit("CourseUpdated");
        })
        .catch((error) => {
          error;
        });
      this.submitBtn.text = "Submit";
      this.submitBtn.disabled = false;
    },
  },
};
</script>

<style >
.textBook .ltp_credit {
  width: 25%;
  display: flex;
  justify-content: space-between;
}

.textBook .ltp_credit input {
  width: 150px;
  text-align: center;
}
</style>
