<template>
  <div>
    <div class="attendence border border-info rounded p-2">
      <div class="">
        <div class="">
          <b-form-checkbox class="ml-2" v-model="attendence.checkAttendence">
            <p class="btn p-0 m-0 text-info">
              Attendance
            </p>
          </b-form-checkbox>
        </div>
        <br />
        <div class="col" v-if="attendence.checkAttendence">
          <div class="row score">
            <div class="col-3">
              <label for="scoreForAttendence">Score for attendance:</label>
              <b-form-input id="scoreForAttendence" v-model="attendence.scoreForAttendence" placeholder="Enter Score"
                :class="{
                  'form-group-error': $v.attendence.scoreForAttendence.$error,
                }"></b-form-input>
              <span class="input-error-message" v-if="
                !$v.attendence.scoreForAttendence.required &&
                $v.attendence.scoreForAttendence.$dirty
              ">Score is mandatory!</span>
            </div>

            <div class="col">
              <div class="row">
                <div class="col">
                  <span style="color:gray;">Note: By default the entire attendance for course will be
                    considered for report, use date picker only if attendance
                    has to be filtered according to date.</span>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label for="example-datepickerfrom">Date From:</label>
                  <b-form-datepicker id="example-datepickerfrom" v-model="attendence.date_from"
                    class="mb-2"></b-form-datepicker>
                  <br />
                </div>

                <div class="col">
                  <label for="example-datepickerto">Date Upto:</label>
                  <b-form-datepicker id="example-datepickerto" v-model="attendence.date_upto"
                    class="mb-2"></b-form-datepicker>
                  <br />
                </div>

                <div class="col-2" v-if="attendence.date_from||attendence.date_upto">
                  <b-link @click="attendence.date_from=null;attendence.date_upto=null;"><i class=" text-info fas fa-times-circle"></i></b-link>
                </div>
              </div>
            </div>
          </div>

          <div class="row score">
            <div class="col">
              <b-form-group label="Attendance score round :" v-slot="{ attendancescoreroundingradio }">
                <b-form-radio v-model="attendence.roundAttendenceScoreTo"
                  :aria-describedby="attendancescoreroundingradio" name="some-radios" value="up">Up</b-form-radio>
                <b-form-radio v-model="attendence.roundAttendenceScoreTo"
                  :aria-describedby="attendancescoreroundingradio" name="some-radios" value="down">Down</b-form-radio>
                <b-form-radio v-model="attendence.roundAttendenceScoreTo"
                  :aria-describedby="attendancescoreroundingradio" name="some-radios" value="decimals">To
                  decimals</b-form-radio>
                <b-form-radio v-model="attendence.roundAttendenceScoreTo"
                  :aria-describedby="attendancescoreroundingradio" name="some-radios" value="mapping">To Custom
                  mapping</b-form-radio>
              </b-form-group>
            </div>
            <div class="col">
              <div v-if="attendence.roundAttendenceScoreTo == 'decimals'">
                <label for="roundAttendenceScoreDecimals">Number of decimals:</label>
                <b-form-input v-model="attendence.roundAttendenceScoreDecimals" id="roundAttendenceScoreDecimals"
                  type="number"></b-form-input>
              </div>
            </div>
          </div>

          <b-container fluid v-if="attendence.roundAttendenceScoreTo == 'mapping'">
            <h3>Attendance percentage - Score mapping</h3>
            <p>
              (Conditions are compare with student attendance percentage one by
              one, if condition is true, the score is selected for that student
              )
            </p>
            <b-row style="text-align:left;" class="my-1" v-for="(mapping,
            mapkey) in attendence.roundAttendenceScoreCustomMapping" :key="mapkey">
              <b-col sm="1">
                <label>{{ mapkey + 1 }}</label>
              </b-col>
              <b-col sm="3">
                <label :for="`1type-${mapkey}`">Percentage:</label>
              </b-col>
              <b-col sm="2">
                <b-form-input :id="`1type-${mapkey}`" type="number" min="0" max="100" step=".1" placeholder="persantage"
                  v-model="mapping.persantage"></b-form-input>
              </b-col>
              <b-col sm="3">
                <label :for="`type-${mapkey}`">Score for <code>{{ mapping.persantage }}%</code> and
                  above:</label>
              </b-col>
              <b-col sm="2">
                <b-form-input :id="`type-${mapkey}`" v-model="mapping.score" type="number" min="0"
                  step=".1"></b-form-input>
              </b-col>
              <b-col sm="1">
                <b-button @click="removescoremapping(mapkey)" variant="danger">-</b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-button @click="addscoremapping()" variant="success">Add new row</b-button>
            </b-row>
          </b-container>

          <div>
            <b-form-group label="Class Types Selection (Optional):" >
              <b-form-checkbox-group id="checkbox-group-5" v-model="selectedClassTypes" 
                name="flavour-2">
                <b-form-checkbox :value="0" disabled>Regular</b-form-checkbox>
                <b-form-checkbox v-for="(classType, ctIndex) in classTypes" :key="ctIndex" :value="classType.id">{{ classType.name }}</b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, requiredIf } from "vuelidate/lib/validators";
// import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
required;
export default {
  props: ["saved_configs", "courseReport", "classTypes", "selectedClassTypesProp"],
  components: {
    // DatePicker,
  },
  created(){
    this.selectedClassTypes=this.selectedClassTypesProp;
    let savedAttConfig = this.saved_configs.activity_catagory_configs.attendance;
    // savedAttConfig= {'enable' => false,
    //                 'start_date' => null,
    //                 'upto_date' => null,
    //                 'score' => '',
    //                 'round_to' => '', // [up,down,decimal,mapping]
    //                 'decimal_count' => '',
    //                 'roundAttendenceScoreCustomMapping' => [
    //                     //['persantage'=>'','score'=>[]], ...
    //                 ]
    //               }
    // console.log(savedAttConfig);
    this.attendence.checkAttendence=savedAttConfig.enable;
    this.attendence.scoreForAttendence=savedAttConfig.score;
    this.attendence.date_from=savedAttConfig.start_date?savedAttConfig.start_date.replace(/\//g, '-'):null;
    this.attendence.date_upto=savedAttConfig.upto_date? savedAttConfig.upto_date.replace(/\//g, '-'):null;
    this.attendence.roundAttendenceScoreTo=savedAttConfig.round_to;
    this.attendence.roundAttendenceScoreDecimals=savedAttConfig.decimal_count;
    this.attendence.roundAttendenceScoreCustomMapping=savedAttConfig.roundAttendenceScoreCustomMapping;
    
  },
  watch: {
    selectedClassTypesProp(newVal) {
      // Update childData when childProp changes
      if(this.selectedClassTypes.length==0)this.selectedClassTypes = newVal; // For example, transforming the value
    }
  },
  data() {
    return {
      selectedClassTypes:[],
      attendence: {
        checkAttendence: true,
        scoreForAttendence: 10,
        // attendenceDate: null,
        date_from: null,
        date_upto: null,
        roundAttendenceScoreTo: "",
        roundAttendenceScoreDecimals: "1",
        roundAttendenceScoreCustomMapping: [
          // {persanatge:score}
          { persantage: "90", score: "10" },
          { persantage: "85", score: "9" },
          { persantage: "80", score: "8" },
          { persantage: "75", score: "7" },
          { persantage: "70", score: "6" },
          { persantage: "0", score: "5" },
        ],
      },
    };
  },
  methods: {
    removescoremapping(mapkey) {
      this.attendence.roundAttendenceScoreCustomMapping.splice(mapkey, 1);
    },
    addscoremapping() {
      this.attendence.roundAttendenceScoreCustomMapping.push({
        persantage: "0",
        score: "5",
      });
      // this.attendence.roundAttendenceScoreCustomMapping = [
      //   {
      //     persantage: "0",
      //     score: "5",
      //   },
      //   ...this.attendence.roundAttendenceScoreCustomMapping,
      // ];
    },
    async checkValidation() {
      await this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      }
    },
  },
  validations: {
    attendence: {
      scoreForAttendence: {
        required: requiredIf(function () {
          if (this.attendence.checkAttendence) {
            return true;
          }
        }),
      },
    },
  },
};
</script>

<style >
.attendence p {
  font-size: 16px;
  font-weight: 600;
}

.attendence p small {
  font-size: 10px;
  font-weight: 600;
}
</style>
