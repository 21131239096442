<template>
  <div class="container">
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <b-container>
      <b-row>
        <b-col></b-col>
        <b-col></b-col>
        <b-col></b-col>
      </b-row>
    </b-container>
    <div v-if="!fetchData">
      <b-row class="control-buttons">
        <b-col v-if="resourceaction == 'list'" style="text-align:left;">
          <b-button v-if="$store.getters.defaultInstitute.userRole != 'Student'" variant="success" class="text-white sm"
            size="sm" @click="createHostelForm">Add Hostel</b-button>
        </b-col>
        <b-col v-if="resourceaction != 'list'" style="text-align:right;">
          <b-button variant="danger" class="text-white sm" size="sm" @click="resourceaction = 'list'"
            :disabled="actionButton.disabled">Cancel</b-button>
        </b-col>
      </b-row>
      <div v-if="resourceaction == 'list'">
        <!-- <h3 style="text-align: center; font-size:18px !important;">
          Hostel's
        </h3> -->
        <b-row>
          <b-col sm="12">
            <div class="table-responsive rounded">
              <table class="table table-striped table-bordered">
                <thead class="thead-dark text-left">
                  <tr>
                    <th>
                      SI. No.
                    </th>
                    <th>
                      Name
                    </th>
                    <th>
                      Description
                    </th>
                    <th>
                      Students Count
                    </th>
                    <th>
                      Wardens
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(hostel, reindex) in hostels" :key="reindex">
                    <td>{{ reindex + 1 }}</td>
                    <td>
                      {{ hostel.name }}
                    </td>
                    <td>{{ hostel.description }}</td>
                    <td>
                      <i style="color:blue; font-size: 14px !important;" class="fas fa-users"></i>
                      {{ hostel.students_count }}
                      <br>
                      <i style="color:green; font-size: 14px !important;" class="fas fa-sign-in-alt"></i> {{
                          hostel.history_in
                      }}
                      <br>
                      <i style="color:red; font-size: 14px !important;" class="fas fa-sign-out-alt"></i> {{
                          hostel.history_out
                      }}
                    </td>
                    <td>
                      <span v-for="(warden, warindex) in hostel.wardens" :key="warindex">
                        {{ warden.username }}({{ warden.email }}) <br>
                      </span>
                      <span style="color: #2929aa; text-decoration: underline; cursor: pointer;"
                        @click="editHostelWarden(reindex)">Add/Remove warden</span>
                    </td>
                    <td>
                      <span class="text-info btn m-0 p-0 sm" size="sm" @click="editHostelForm(hostel)"
                        :disabled="actionButton.disabled"><i class="fas fa-pen ml-3"></i></span>
                      <span class="text-info btn m-0 p-0 sm" size="sm" @click="deleteHostel(hostel.id)"
                        :disabled="actionButton.disabled"><i style="color:red;" class="fas fa-trash ml-3"></i></span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-if="resourceaction == 'create' || resourceaction == 'edit'">
        <div>
          <h3 style="text-align: center; font-size:18px !important;">
            Add new Hostel
          </h3>
          <b-row>
            <b-col sm="6">
              <b-form-group id="input-group-1" label-for="input-1" description="">
                <label class="required-label" for="">Hostel Name:</label>
                <b-form-input id="input-1" v-model="hostel.name" type="text" placeholder="Enter a Name"
                  required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group id="input-group-2" label-for="input-2" description="">
                <label class="required-label" for="">Description:</label>
                <b-form-input id="input-2" v-model="hostel.description" type="text"
                  placeholder="Enter a Description"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row><b-col>Selected Students:</b-col></b-row>
          <b-row class="px-3">
            <b-col class="p-1" sm="6" md="4" lg="3" @click="removeHostelStudent(guser)"
              v-for="(guser, reindex) in hostelUsers" :key="reindex">
              <b-button class="py-1" pill variant="outline-success" style="text-align: left;">
                <span style="font-size: 10px !important;">{{ guser.text }}</span>
              </b-button>
            </b-col>
          </b-row>


          <br> <br>
          <b-row><b-col>Choose students to be added to hostel:</b-col></b-row>
          <b-row>
            <b-col>
              <b-form-group>
                <label for="">Academic Year:</label>
                <b-form-select :options="$acedemicYears" v-model="academic_year"></b-form-select>
                <span v-if="!academic_year" class="text-danger">Select academic year </span>
              </b-form-group>
              <b-form-group v-if="academic_year">
                <label class="required-label" for="">Choose Programme(Scheme):</label>
                <b-form-select @change="getSubgroups()" :options="usergroups" v-model="usergroup_id">
                  <span v-if="!usergroup_id" class="text-danger">Select Programme </span>
                </b-form-select>
              </b-form-group>
              <b-form-group v-if="usergroup_id">
                <label class="required-label" for="">{{ 'Choose ' + subgroupCustomName + ':'
                }}</label>
                <b-form-select @change="getSubgroupStudents()" :options="usersubgroups" v-model="usersubgroup_id"
                  :disabled="false"></b-form-select>
                <span v-if="!academic_year" class="text-danger">Select {{ subgroupCustomName }} </span>
              </b-form-group>

              <b-form-group v-if="(usersubgroup_id && institutionUsers.length)">
                <span @click="selectAllSubgroupStudents"
                  style="color: #2929aa; text-decoration: underline; cursor: pointer;">
                  {{ 'Select all / Invert' }}
                </span>
              </b-form-group>
            </b-col>
            <b-col sm="1" md="1" lg="1">OR</b-col>
            <b-col>
              <b-form-group>
                <label for="">Search/Select Students:</label>
                <multiselect v-model="hostelUsers" tag-placeholder="Add this as new tag" placeholder="Search student"
                  label="text" track-by="value" :options="institutionUsersSearch" :close-on-select="false"
                  :multiple="true" :searchable="true" :loading="isSearchLoading" @search-change="searchStudents"
                  :internal-search="false" :taggable="false">
                </multiselect>
              </b-form-group>
            </b-col>
          </b-row>


          <b-row>
            <span v-if="(usersubgroup_id && institutionUsers.length < 1)" class="text-danger p-1"> Student list is empty,
              please choose another {{ subgroupCustomName }} </span>
            <b-col class="p-1" sm="6" md="4" lg="3" @click="selectUser(guser)"
              v-for="(guser, reindex) in institutionUsers" :key="reindex">
              <b-button pill :variant="
            hostelUsers.includes(guser)
              ? 'success' : 'outline-secondary'" style="text-align: left;">
                <span>{{ guser.text }}</span>
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" style="text-align:right;">
              <b-button variant="success" class="text-white sm" size="sm" @click="createHostel"
                :disabled="actionButton.disabled">{{ actionButton.text }}</b-button>
            </b-col>
          </b-row>
        </div>
      </div>

    </div>

    <b-modal id="editHostelWardenModel" no-close-on-backdrop no-close-on-keyboard no-close-on-esc hide-footer
      hide-header centered size="md">
      <h3>Manage Hostel wardens:</h3>

      <b-col v-for="(guser, reindex) in hostel.wardens" :key="reindex">
        <span class="py-1" style="text-align: left; ">
          {{ guser.username }}({{ guser.email }}, {{ guser.mobile }})
          <span class="text-info btn m-0 p-0 sm" size="sm" @click="updateHostelWarden(reindex)"
            :disabled="actionButton.disabled"><i style="color:red; font-size: 14px !important;"
              class="fas fa-trash ml-1"></i></span>
        </span> <br>
      </b-col>
      <br><br>
      <b-col>
        <b-form-group id="input-group-1" label-for="input-1" description="">
          <label for="">Add Warden (Enter the Ezygo registered username/email/ 91+10 digit mobile number of the warden,
            and click on 'Save'. Please note that the user must have already created an account on Ezygo.app to be added
            as hostel warden):</label>
          <b-form-input id="input-1" v-model="newWarden" type="text"
            placeholder="Enter username/email/ 91+10 digit mobile number"></b-form-input>
        </b-form-group>
      </b-col>
      <!-- <b-col>
        <b-button variant="success" class="text-white sm" size="sm" @click="attachHostelWarden"
          :disabled="actionButton.disabled">{{ actionButton.text }}</b-button>
      </b-col> -->
      <br>
      <b-row class="p-2">
        <b-col style="text-align:left;">
          <b-button variant="danger" class="text-white sm" size="sm"
            @click="$bvModal.hide('editHostelWardenModel');">Close</b-button>
        </b-col>
        <b-col style="text-align:right;">
          <b-button variant="success" class="text-white sm" size="sm" @click="attachHostelWarden"
            :disabled="actionButton.disabled">{{ actionButton.text }}</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: [],
  async created() {
    this.subgroupCustomName = this.$store.getters.getCustomNamings.userSubgroup.custom_name ?? "Usersubgroup";
    this.getHostels();
  },
  components: {},
  data() {
    return {
      fetchData: false, subgroupCustomName: '',
      resourceaction: "list", // list,create,edit
      hostels: [],
      hostel: {
        id: null,
        name: "",
        description: "",
      },
      // create_with_user_ids: [],
      hostelUsers: [],
      institutionUsersSearch: [], // available users to add
      institutionUsers: [], // available users to add
      actionButton: {
        text: "Save",
        disabled: false,
      },
      academic_year: null,
      academic_semester: null,
      usergroups: [{
        value: null,
        text: 'Select an option'
      }],
      usergroup_id: null, usersubgroups: [], usersubgroup_id: null,

      isSearchLoading: false,
      searchQuery: '',
      // hostelWardens:[],
      // ezygoUsersSearch: [],
      newWarden: "",
    };
  },
  methods: {
    async editHostelWarden(index) {
      this.hostel = this.hostels[index];
      this.newWarden = "";
      this.$bvModal.show('editHostelWardenModel');
    },
    async updateHostelWarden(currentHostelWardenIndex) { // remove warden
      this.hostel.wardens.splice(currentHostelWardenIndex, 1);
      await this.syncHostelUsers(true); // warden sync
      this.$bvModal.hide('editHostelWardenModel');
      this.getHostels();
    },
    async attachHostelWarden() { // attach new warden
      await this.syncHostelUsers(true); // warden sync
      this.$bvModal.hide('editHostelWardenModel');
      this.getHostels();
    },

    async searchStudents(searchQuery) {
      this.isSearchLoading = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/institutionusers/students/search?q=${searchQuery}`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.institutionUsersSearch = [];
          response.data.forEach((student) => {
            this.institutionUsersSearch.push({
              value: student.id,
              text: `${student.first_name} ${student.last_name}-${student.email}`,
            });
          });
          this.isSearchLoading = false;
        })
        .catch((error) => {
          error;
          this.isSearchLoading = false;
        });
    },
    // async searchWardens(searchQuery) {
    //   this.isSearchLoading = true;
    //   const url =
    //     this.$store.getters.getAPIKey.mainAPI +
    //     `/users/search?q=${searchQuery}`;
    //   await this.$axios
    //     .get(url)
    //     .then((response) => {
    //       this.ezygoUsersSearch = [];
    //       response.data.forEach((user) => {
    //         this.ezygoUsersSearch.push({
    //           value: user.id,
    //           text: `${user.username}`,
    //         });
    //       });
    //       this.isSearchLoading = false;
    //     })
    //     .catch((error) => {
    //       error;
    //       this.isSearchLoading = false;
    //     });
    // },
    async getUserGroups() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usergroups?allusergroups=1`;
      await this.$axios
        .get(url)
        .then((response) => {
          response.data.forEach((group) => {
            this.usergroups.push({
              value: group.id,
              text: `${group.name} (${group.code}) ${group.scheme ?? ''}`,
            });
          });
        })
        .catch((error) => {
          error;
        });
    },
    async getSubgroups() {
      this.usersubgroups = [{
        value: null,
        text: 'Select an option'
      }];
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usergroups/${this.usergroup_id}/usersubgroups?academic_year=${this.academic_year}`;
      await this.$axios
        .get(url)
        .then((response) => {
          response.data.forEach((subgroup) => {
            this.usersubgroups.push({
              value: subgroup.id,
              text: `${subgroup.name} (${subgroup.code})`,
            });
          });
        })
        .catch((error) => {
          error;
        });
    },
    async getSubgroupStudents() {
      this.institutionUsers = [];
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usersubgroup/${this.usersubgroup_id}/users`;
      await this.$axios
        .get(url)
        .then((response) => {
          response.data.forEach((student) => {
            if (student.pivot.usersubgrouprole_id == 2) this.institutionUsers.push({
              value: student.pivot.institution_user_id,
              text: (student.roll_number ? student.roll_number + "-" :
                "") + `${student.first_name} ${student.last_name}`,
            });
          });
        })
        .catch((error) => {
          error;
        });
    },
    selectAllSubgroupStudents() {
      this.institutionUsers.forEach(student => {
        if (this.hostelUsers.includes(student)) {
          this.hostelUsers.splice(this.hostelUsers.indexOf(student), 1);
        } else {
          this.hostelUsers.push(student);
        }
      });
    },
    removeHostelStudent(guser) {
      this.hostelUsers.splice(this.hostelUsers.indexOf(guser), 1);
    },
    selectUser(guser) {
      if (this.hostelUsers.includes(guser)) {
        this.hostelUsers.splice(this.hostelUsers.indexOf(guser), 1);
      } else {
        this.hostelUsers.push(guser);
      }
    },
    async getHostels() {
      this.resourceaction = "list";
      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/hostels?with_usercount=1&with_wardens=1&student_history_summary=1`;
      await axios
        .get(url)
        .then((response) => {
          this.hostels = response.data;
          this.fetchData = false;
        })
        .catch(() => {
          this.$toast.error("Failed to get List", {
            position: "top",
            duration: 3000,
          });
          this.fetchData = false;
        });
    },
    deleteHostel(id) {
      this.actionButton = {
        text: "Save",
        disabled: true,
      };
      const url = this.$store.getters.getAPIKey.mainAPI + `/hostels/${id}`;
      this.$axios
        .delete(url)
        .then(() => {
          this.getHostels();
          this.$toast.success("Deleted successfully", {
            position: "top",
            duration: 3000,
          });
          this.actionButton = {
            text: "Save",
            disabled: false,
          };
        })
        .catch(() => {
          this.$toast.error("Failed to delete", {
            position: "top",
            duration: 3000,
          });
          this.actionButton = {
            text: "Save",
            disabled: false,
          };
        });
    },

    async createHostelForm() {
      this.hostelUsers = [];
      this.hostel = {
        id: null,
        name: "",
        description: "",
      };
      this.resourceaction = "create";
      if (this.usergroups.length < 2) await this.getUserGroups();
      // await this.getInstitutionUsers();
    },
    async createHostel() {
      this.actionButton = {
        text: "Saving...",
        disabled: true,
      };
      const url = this.$store.getters.getAPIKey.mainAPI + `/hostels`;
      let responseError = false;
      await axios
        .post(url, this.hostel)
        .then((response) => {
          this.hostel = response.data;
          this.$toast.success(this.hostel.name + " created successfully", {
            position: "top",
            duration: 3000,
          });
          this.actionButton = {
            text: "Save",
            disabled: false,
          };
        })
        .catch((error) => {
          this.actionButton = {
            text: "Save",
            disabled: false,
          };
          responseError = true;
          let errortxt = "";
          if (error.response)
            if (error.response.status == 422) {
              let errormsgs = error.response.data.errors;
              for (const key in errormsgs) {
                errortxt = errormsgs[key][0];
              }
            } else {
              errortxt = error.response.data.message;
            }
          else {
            errortxt = "Failed to Create";
          }
          this.$toast.error(errortxt, {
            position: "top",
            duration: 3000,
          });
          return;
        });

      if (!responseError) {
        if (this.hostelUsers.length || this.hostel.id) {
          await this.syncHostelUsers();
        }
        await this.getHostels();
      }
      return;
    },
    async editHostelForm(hostel) {
      this.hostel = hostel;
      this.hostelUsers = [];
      //fill existing users and set available users status
      // await this.getHostels(hostel.id);
      this.resourceaction = "edit";
      if (this.usergroups.length < 2) await this.getUserGroups();
      await this.getHostelUsers();
    },

    async getHostelUsers() {
      // this.resourceaction = "list";
      this.fetchData = true;
      // const url = this.$store.getters.getAPIKey.mainAPI + `/courseResources/${ResourceId}`;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/hostelusers?hostel=${this.hostel.id}`;
      await axios
        .get(url)
        .then((response) => {
          response.data.forEach((user) => {
            this.hostelUsers.push({
              value: user.id,
              text: `${user.first_name} ${user.last_name}`,
            });
          });
          this.fetchData = false;
        })
        .catch(() => {
          this.$toast.error("Failed to get students List", {
            position: "top",
            duration: 3000,
          });
          this.fetchData = false;
        });
    },
    async syncHostelUsers(syncWardens = false) {
      this.actionButton = {
        text: "Saving...",
        disabled: true,
      };
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/hostelusers`;
      let postData = {};
      if (syncWardens) {
        postData = {
          new_warden: this.newWarden,
          users: this.hostel.wardens.map(option => option.id),
          hostel: this.hostel.id, role: 'warden'
        };
      } else {
        postData = {
          institution_users: this.hostelUsers.map(option => option.value),
          hostel: this.hostel.id, role: 'student'
        };
      }
      await axios
        .post(url, postData)
        .then(() => {
          this.$toast.success("Users updated successfully", {
            position: "top",
            duration: 3000,
          });
          this.actionButton = {
            text: "Save",
            disabled: false,
          };
        })
        .catch((error) => {
          let errortxt = "";
          if (error.response) {
            errortxt = error.response.data.message;
          } else {
            errortxt = "Failed to Update Users";
          }
          this.$toast.error(errortxt, {
            position: "top",
            duration: 7000,
          });
          this.actionButton = {
            text: "Save",
            disabled: false,
          };
        });
    },
  },
};
</script>

<style >
.tag-button button {
  font-size: 9px !important;
  padding: 3px;
}
</style>
