<template>
  <div>
    <CopySubgroup v-if="(copySubgroupView === true)" @Cancel="(copySubgroupView = !copySubgroupView)"
      @success="handleResponse(true)" :copy_from_suggestion="copy_from" />
    <div class="createModule">
      <b-form @submit.prevent="prepareGroupCreation()" v-if="(copySubgroupView === false)">
        <b-form-group>
          <h3 class="text-center font-weight-bolder text-info">
            {{ groupInfo.group ? "Edit" : "Create" }}
            {{
                getCustomNames.userSubgroup.custom_name != null
                  ? getCustomNames.userSubgroup.custom_name
                  : "Usersubgroup"
            }}
          </h3>
        </b-form-group>
        <b-form-group v-if="groupInfo.group">
          <DisplayExistingAdmins @adminDeleted="updateAdminStatus" :groupInfo="groupInfo" />
        </b-form-group>
        <b-form-group>
          <b-form-select :options="groupOptions" v-model="groupId" :disabled="groupInfo.group ? true : false">
          </b-form-select>
        </b-form-group>
        <div v-if="groupId != null">

          <b-form-group v-if="!groupInfo.group">

            <!-- <b-form-checkbox :disabled="usergroups[groupId].has_interdepartment_courses_group"
              id="interdepartment_courses_groupcheckbox-1" v-model="interdepartment_courses_group"
              name="interdepartment_courses_groupcheckbox-1" :value="true">
              <span> Use this {{ getCustomNames.userSubgroup.custom_name != null
                  ? getCustomNames.userSubgroup.custom_name
                  : 'Usersubgroup'
              }} for and only interdepartmental courses (eg: minor course, open elective)</span>

            </b-form-checkbox> -->

            <b-form-radio :disabled="usergroups[groupId].has_interdepartment_courses_group"
              v-model="interdepartment_courses_group" name="some1-radios" :value="false">Create a new {{
                  getCustomNames.userSubgroup.custom_name != null
                    ? getCustomNames.userSubgroup.custom_name
                    : 'Usersubgroup'
              }}</b-form-radio>
            <b-form-radio :disabled="usergroups[groupId].has_interdepartment_courses_group"
              v-model="interdepartment_courses_group" name="some1-radios" :value="true">Create an interdepartmental
              course group for and only interdepartmental courses (eg: minor
              course, open elective)</b-form-radio>

            <span class="text-info" v-if="usergroups[groupId].has_interdepartment_courses_group"> Group for
              interdepartmental courses is
              already added to selected Programme</span>
          </b-form-group>
          <b-form-group>
            <label class="required-label" for="">Si.No:</label>
            <b-form-input placeholder="Serial Number" v-model="group.si_no"></b-form-input>
          </b-form-group>
          <b-form-group>
            <label class="required-label" for="">Name:</label>
            <b-form-input :placeholder="interdepartment_courses_group ? 'Name for interdepartmental course group' :
              (getCustomNames.userSubgroup.custom_name != null
                ? getCustomNames.userSubgroup.custom_name + ' Name'
                : 'Usersubgroup Name')
            " v-model="group.name" required></b-form-input>
          </b-form-group>
          <b-form-group>
            <label class="required-label" for="">Code:</label>
            <b-form-input :placeholder="interdepartment_courses_group ? 'Code for interdepartmental course group' :
              (getCustomNames.userSubgroup.custom_name != null
                ? getCustomNames.userSubgroup.custom_name + ' Code'
                : 'Usersubgroup Code')
            " v-model="group.code" required></b-form-input>
          </b-form-group>
          <span class="text-info  my-2" v-if="group.type == 'interdepartment_courses_group'">
            This {{ getCustomNames.userSubgroup.custom_name != null
                ? getCustomNames.userSubgroup.custom_name
                : 'Usersubgroup'
            }} is for interdepartmental courses only (eg: minor course, open elective)
          </span>

          <b-form-group>
            <label v-if="interdepartment_courses_group || group.type == 'interdepartment_courses_group'" for="">Add
              additional faculties for managing this interdepartmental course group:</label>
            <multiselect v-model="group.institution_users" tag-placeholder="Add this as new tag" :placeholder="
              interdepartment_courses_group || group.type == 'interdepartment_courses_group' ? 'Select Faculties' : (getCustomNames.userSubgroup.custom_name != null
                ? 'Add ' + getCustomNames.userSubGroupAdmin.custom_name
                : 'Add Usersubgroup Admin')
            " label="name" track-by="code" :options="adminsOptions" :multiple="true" :taggable="true"
              :close-on-select="false" @tag="addTag"></multiselect>
            <span v-if="interdepartment_courses_group || group.type == 'interdepartment_courses_group'"
              class="text-info">
              <!-- Usually {{
                  getCustomNames.userSubgroup.custom_name != null
                    ? getCustomNames.userSubGroupAdmin.custom_name
                    : 'Usersubgroup Admin'
              }}'s are not required, instead {{ getCustomNames.userGroupAdmin.custom_name != null ?
    getCustomNames.userGroupAdmin.custom_name : "Usergroup Admin"
}}
              directly manage interdepartmental courses
              (eg: minor course, open elective) -->

              Programme Coordinator will have provision to directly manage interdepartmental course group. Additional
              faculties need not be necessarily added.
            </span>
          </b-form-group>
          <b-col v-if="!(interdepartment_courses_group || group.type == 'interdepartment_courses_group')">
            <b-row>
              {{ getCustomNames.userSubgroup.custom_name != null
                  ? getCustomNames.userSubgroup.custom_name + ' Name'
                  : 'Usersubgroup Name'
              }} start and end date (This information might be used for lesson planning, timetable creation, and other
              related purposes)
            </b-row>
            <b-row>
              <b-form-group label="Start date (YYYY-MM-DD)" v-slot="{ ariaDescribedby }" class="m-1">

                <b-form-datepicker :aria-describedby="ariaDescribedby" placeholder="Select Date"
                  v-model="group.start_date"></b-form-datepicker>
              </b-form-group>
              <b-form-group label="End date (YYYY-MM-DD)" v-slot="{ ariaDescribedby }" class="m-1 mb-3">

                <b-form-datepicker :aria-describedby="ariaDescribedby" placeholder="Select Date"
                  v-model="group.end_date"></b-form-datepicker>
              </b-form-group>
            </b-row>
          </b-col>
          <b-form-group>
            <label class="required-label" for="">Academic Year:</label>
            <b-form-select :disabled="groupInfo.group" :options="$acedemicYears" v-model="group.academic_year" required></b-form-select>
          </b-form-group>
          <b-form-group>
            <label class="required-label" for="">Academic Semester:</label>
            <b-form-select :disabled="groupInfo.group" :options="$acedemicSemesters" v-model="group.academic_semester" required></b-form-select>
          </b-form-group>
          <b-form-group v-if="!(interdepartment_courses_group || group.type == 'interdepartment_courses_group')">
            <label class="required-label" for="">Batch Starting Year:</label>
            <b-form-select :options="$batchYears" v-model="group.start_year" required></b-form-select>
          </b-form-group>

          <b-form-group v-if="!(interdepartment_courses_group || group.type == 'interdepartment_courses_group')">
            <label class="required-label" for="">Batch Ending Year:</label>
            <b-form-select :options="$batchYears" v-model="group.end_year" required></b-form-select>
          </b-form-group>
        </div>
        <b-form-group>
          <div class="d-flex justify-content-between">
            <b-button type="submit" variant="primary" :disabled="groupButton.disabled" v-if="groupId != null">{{
                groupButton.text
            }}</b-button>
            <b-button @click="$emit('GroupCreated', false)" variant="danger" :disabled="groupButton.disabled">Cancel
            </b-button>
          </div>
        </b-form-group>
      </b-form>
    </div>

    <div v-if="(copySubgroupView === null)">

      <div class="" style="border: 1px solid #17a2b8; padding: 10px; border-radius: 5px;">
        <div v-if="$root.default_academic_year[0] == '0'">
          Please select correct academic year to enable copy option
        </div>
        <div v-else>

          <span class="text ">
            <h5 style="font-size: 12pt !important;">Now, there are two pick and choose options available to add a class
              or semester.</h5>
            <ul>
              <li>
                The first option is to directly pick from University Resources,
                which will automatically update all the courses and course details of the class.
                <span style="color: #2929aa; text-decoration: underline; cursor: pointer;"
                  @click="copy_from = 'template'; (copySubgroupView = true)">Click here to proceed</span>

              </li>
              <li>
                The second option is to duplicate a class/semester from the previous or current academic year,
                which will include all the data already entered, such as courses, course configurations, COs, POs,
                lesson
                plans, and more.
                <span style="color: #2929aa; text-decoration: underline; cursor: pointer;"
                  @click="copy_from = 'myinstitute'; (copySubgroupView = true)">Click here to proceed</span>

              </li>
            </ul>
          </span>
        </div>
      </div>
      <div class=" m-2 ">
        <ul>
          To proceed with manually creating a new class/semester/interdepartmental course group
          <span style="color: #2929aa; text-decoration: underline; cursor: pointer;"
            @click="(copySubgroupView = false)">Create new</span>
          <br>Recommended only for first time users who
          <li>
            do not find their University Resources in Ezygo.app
          </li>
          <li>has not updated details in previous academic year</li>
        </ul>
      </div>
      <div class="row">
        <b-form-group style="text-align:right;" class="col">
          <b-button variant="danger" @click="$emit('GroupCreated', false)">Cancel</b-button>
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script>
import DisplayExistingAdmins from "./DisplayExistingAdmins";
import CopySubgroup from "./CopySubgroup";
export default {
  props: ["adminList", "groupInfo", "groupOptions", "getCustomNames", "usergroups"],
  created() {

    if (this.groupInfo.group) {
      this.group = this.groupInfo.group;
      this.groupId = this.groupInfo.group.usergroup_id;
      this.$set(this.group, "institution_users", []);
      this.copySubgroupView = false;
    } else {
      this.$set(this.group, "institution_users", []);
      this.$set(this.group, "academic_year", null);
      this.$set(this.group, "academic_semester", null);
      this.$set(this.group, "start_year", null);
      this.$set(this.group, "end_year", null);
    }
    if (this.groupInfo.group && this.groupInfo.admins.length != 0) {
      this.groupInfo.admins.forEach((admin) => {
        this.$set(this.existingAdmins, admin.pivot.institution_user_id, true);
      });
    }
    this.getFaculties();
  },
  components: {
    DisplayExistingAdmins,
    CopySubgroup,
  },
  data() {
    return {
      group: {},
      interdepartment_courses_group: false,
      adminsOptions: [],
      groupButton: {
        text: "Submit",
        disabled: false,
      },
      adminsObject: {},
      groupId: null,
      existingAdmins: {},
      copySubgroupView: null,
      copy_from: "",
    };
  },
  methods: {
    async prepareGroupCreation() {
      this.groupButton = {
        text: "Please wait...",
        disabled: true,
      };

      const groupData = {
        si_no: this.group.si_no,
        name: this.group.name,
        code: this.group.code,
        start_year:
          this.group.start_year == null
            ? null
            : this.group.start_year.toString(),
        end_year:
          this.group.end_year == null ? null : this.group.end_year.toString(),

        start_date:
          this.group.start_date,
        end_date:
          this.group.end_date,
        academic_year: this.group.academic_year,
        academic_semester: this.group.academic_semester,
        usergroup_id: this.groupInfo.group
          ? this.groupInfo.group.usergroup_id
          : this.groupId,
        type: this.interdepartment_courses_group ? 'interdepartment_courses_group' : null,
      };
      const admins = {
        institution_users: [],
      };

      if (this.group.institution_users.length != 0)
        await this.group.institution_users.forEach((admin) =>
          admins.institution_users.push(admin.id)
        );

      const createGroup = await this.createUsergroup(groupData);
      if (createGroup.status) {
        if (admins.institution_users.length != 0) {
          this.$toast.info("Assigning admins....", {
            position: "top-right",
            duration: 3000,
          });
          await this.assignUsergroupAdmins(admins, createGroup.id);
        }
        this.$toast.success(`${this.group.name} is successfully created.`, {
          position: "top",
          duration: 3000,
        });
        this.handleResponse(true);
      }
      if (!createGroup.status) {
        this.handleResponse(false);
      }
    },
    async createUsergroup(postData) {
      let url;
      let method;
      if (this.groupInfo.group) {
        method = "put";
        url =
          this.$store.getters.getAPIKey.mainAPI +
          `/usersubgroups/${this.groupInfo.group.id}`;
      } else {
        method = "post";
        url =
          this.$store.getters.getAPIKey.mainAPI +
          `/usergroup/${this.groupId}/usersubgroups`;
      }

      const createGroup = await this.$axios[method](url, postData)
        .then((res) => {
          return {
            status: true,
            id: res.data.id,
          };
        })
        .catch((err) => {
          return {
            status: false,
            err: err.response.message,
          };
        });
      return createGroup;
    },
    async assignUsergroupAdmins(postData, groupId) {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usersubgroup/${groupId}/admins`;
      await this.$axios.post(url, postData);
    },
    handleResponse(status) {
      this.groupButton = {
        text: status ? "Created" : "Submit",
        disabled: status ? true : false,
      };
      if (status) {
        this.$emit("GroupCreated", true);
      }
    },
    updateAdminStatus(institution_user_id) {
      this.$emit("updateAdminStatus");
      this.existingAdmins[institution_user_id] = false;
      this.getFaculties();
    },
    async getFaculties() {
      await this.adminList.forEach((admin) => {
        if (
          !this.existingAdmins[admin.id] ||
          this.existingAdmins[admin.id] == undefined
        ) {
          if (admin.enroll_status == 'active') this.adminsOptions.push({
            name: admin.first_name.concat(" ", admin.last_name) + `(${admin.email})`,
            id: admin.id,
            code: admin.id,
          });
        }
      });
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
        id: newTag,
      };
      this.adminsOptions.push(tag);
      this.group.institution_users.push(tag);
    },
  },
};
</script>
