<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div v-else>
      <br />

      <h6 style="font-size: 24px; font-weight: 600; text-align: center">
        Live Class Report For:
        <span class="text-info">{{ liveClass.subgroup.name }}</span>
      </h6>
      <table class="table table-striped table-bordered">
        <thead class="thead-dark">
          <tr></tr>
          <tr>
            <th>Course Name</th>
            <th>Code</th>
            <th>Date</th>
            <th>Start Time</th>
            <th>End Time</th>
            <th>Attendees Lists</th>
          </tr>
        </thead>

        <tbody>
          <tr v-if="liveClassReport.length == 0">
            <td colspan="100" class="align-middle text-danger text-center">
              No Data Found!
            </td>
          </tr>
          <tr
            v-for="(report, reportIndex) in liveClassReport"
            :key="reportIndex"
          >
            <td class="align-middle">
              {{ report.course.name }}
            </td>
            <td class="align-middle">
              {{
                report.course.code +
                  "-" +
                  report.course.usersubgroup.usergroup.code +
                  "-" +
                  report.course.usersubgroup.code
              }}
            </td>
            <td class="align-middle">
              {{ report.start_datetime.format("DD/MM/YYYY") }}
            </td>
            <td class="align-middle">
              {{ report.start_datetime.format("HH:mm A") }}
            </td>
            <td class="align-middle">
              {{ report.end_datetime.format("hh:mm A") }}
            </td>
            <td class="align-middle text-center">
              <b-button
                size="sm"
                variant="primary"
                @click="
                  getLiveClassParticipants(
                    report.id,
                    'participants' + reportIndex
                  )
                "
                >View Attendees List</b-button
              >
              <b-modal
                :id="'participants' + reportIndex"
                no-close-on-backdrop
                no-close-on-keyboard
                no-close-on-esc
                hide-footer
                centered
                size="lg"
              >
                <div class="loading" v-if="loadStudents">
                  <load-data></load-data>
                </div>
                <table class="table table-bordered table-striped" v-else>
                  <thead class="thead-dark">
                    <th>Roll No.</th>
                    <th>Name</th>
                    <th>Start Time</th>
                  </thead>
                  <tbody>
                    <tr v-if="liveClassParticipants.length == 0">
                      <td
                        colspan="100"
                        class="align-middle text-danger text-center"
                      >
                        No Data Found!
                      </td>
                    </tr>
                    <tr
                      v-for="(student, studentIndex) in liveClassParticipants"
                      :key="studentIndex"
                      v-else
                    >
                      <td class="text-center align-middle">
                        {{ student.roll_number }}
                      </td>
                      <td class="text-center align-middle text-capitalize">
                        {{ student.first_name || " " }}
                        {{ student.last_name || " " }}
                      </td>
                      <td class="text-center align-middle">
                        {{ student.start_datetime.format("DD/MM/YYYY") }}
                        <br />
                        {{ student.start_datetime.format("hh:mm a") }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-modal>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: ["liveClassReport", "liveClass"],
  data() {
    return {
      fetchData: false,
      liveClassParticipants: [],
      loadStudents: false,
    };
  },
  methods: {
    getLiveClassParticipants(eventId, modalName) {
      this.loadStudents = true;
      this.$bvModal.show(`${modalName}`);
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/googleevents/${eventId}/reports`;
      this.$axios
        .get(url)
        .then((response) => {
          this.liveClassParticipants = response.data.participants;
          this.liveClassParticipants.forEach((participant) => {
            let startT = moment(participant.start_datetime);
            participant.start_datetime = startT;
          });
          this.loadStudents = false;
          response;
        })
        .catch((error) => {
          this.loadStudents = false;
          error;
        });
    },
  },
};
</script>
