<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="createSurvey p-3" v-else>
      <b-form @submit.prevent="prepareSurveySubmission()">
        <div class="participantsComponent" v-if="!survey.id">
          <!--Sync Participants-->
          <SyncStudentList
            @cancelSyncingParticipants="clearResponse(true)"
            :subgroupForStudents="subgroupForStudents"
            :surveyId="surveyId"
            ref="syncStudents"
          />
        </div>
        <input
          type="hidden"
          :value="!survey.hidden ? (survey.hidden = false) : survey.hidden"
        />

        <b-form-group>
         <label class="required-label" for="">Survey Name:</label> 
          <b-form-input
            :class="{ 'form-group-error': $v.survey.name.$error }"
            placeholder="Survey Name"
            v-model="survey.name"
          ></b-form-input>
          <span
            class="input-error-message"
            v-if="!$v.survey.name.required && $v.survey.name.$dirty"
            >Survey Name is required</span
          >
        </b-form-group>
        <b-form-group label="Survey Description:">
          <b-form-textarea
            v-model="survey.summary"
            placeholder="Enter description..."
            rows="3"
            max-rows="6"
          >
          </b-form-textarea>
        </b-form-group>
        <b-form-group>
          <label class="required-label" for="">Survey Type:</label>
          <b-form-select
            :options="surveyTypeOptions"
            v-model="survey.survey_type"
            @change="updateSurveyType"
            :disabled="surveyInfo.id ? true : false"
          ></b-form-select>
        </b-form-group>
        <b-form-group
        >
        <label class="required-label" for="">Survey Mode:</label>
          <b-form-select    v-if="
            survey.survey_type == 'programme_exit' ||
            survey.survey_type == 'course_exit'
          "
            v-model="survey.survey_mode"
            @change="updateSurveyType"
            :disabled="surveyInfo.id ? true : false"
            :options="surveyOnlineOfflineOptions"
          ></b-form-select>
        
       
          <b-form-select v-if="
            survey.survey_type == 'student_feedback' 
          "
            v-model="survey.survey_mode"
            @change="updateSurveyType"
            :disabled="surveyInfo.id ? true : false"
            :options="surveyOnlineOptions"
          ></b-form-select>
      
      
          <b-form-select  v-if="
            survey.survey_type == 'alumini' ||
            survey.survey_type == 'employer' ||
            survey.survey_type == 'parent' ||
            survey.survey_type == 'faculty'
          "
            v-model="survey.survey_mode"
            @change="updateSurveyType"
            :disabled="surveyInfo.id ? true : false"
            :options="surveyOfflineOptions"
          ></b-form-select>
        </b-form-group>
        <b-form-group>
         <label class="required-label" for="">Select Academic Year:</label><br>
          <b-form-select
            class="w-50 text-center"
            :options="$acedemicYears"
            :disabled="surveyInfo.id ? true : false"
            v-model="survey.academic_year"
            :class="{ 'form-group-error': $v.survey.academic_year.$error }"
            :value="
              !survey.academic_year
                ? (survey.academic_year = null)
                : survey.academic_year
            "
            @change="updateAcademicYear()"
          ></b-form-select>
          <br />
          <span
            class="input-error-message"
            v-if="
              !$v.survey.academic_year.required &&
              $v.survey.academic_year.$dirty
            "
            >Academic Year is required</span
          >
        </b-form-group>
        <b-form-group
          v-if="
            survey.survey_mode == 'online'
          "
          label="Select Survey Start and End Date:"
          :class="{
            'form-group-error': $v.date_from.$error + ' ' + $v.date_upto.$error,
          }"
        >
          <b-row style="z-index: 999 !important">
            <b-col md="6">
              <label>From:</label>
              <b-form-datepicker
                id="example-datepickerfrom"
                v-model="date_from"
                class="mb-2"
              ></b-form-datepicker>
            </b-col>
            <b-col md="6">
              <label>To:</label>
              <b-form-datepicker
                id="example-datepickerto"
                v-model="date_upto"
                class="mb-2"
              ></b-form-datepicker>
            </b-col>
          </b-row>
          <br />
          <span
            class="input-error-message"
            v-if="
              (!$v.date_upto.required && $v.date_upto.$dirty) ||
              (!$v.date_from.required && $v.date_from.$dirty)
            "
            >Start and End Date is required</span
          >
        </b-form-group>

        <b-form-group
          label=""
          v-if="survey.survey_type == 'course_exit'"
          :class="{ 'form-group-error': $v.survey.course_id.$error }"
           
        >
        <label class="required-label" for="">Select Course:</label>
          <SelectCourseForCourseExitSurvey
            :surveyId="surveyId"
            :myCoursesOptions="myCoursesOptions"
            :survey="survey"
            ref="courseExitSurvey"
            @course_id="storeCourseId"
            :disableChange="surveyInfo.id ? true : false"
            
          />
        </b-form-group>
       
          <span
            class="input-error-message"
            v-if="
              !$v.survey.course_id.required &&
              $v.survey.course_id.$dirty
            "
            >Course is required</span
          >
          
        <b-form-group 
          v-if="
            survey.survey_type == 'programme_exit' ||
            survey.survey_type == 'alumini' ||
            survey.survey_type == 'employer' ||
            survey.survey_type == 'parent' ||
            survey.survey_type == 'faculty'
          "
          
          :class="{ 'form-group-error': $v.survey.usersubgroup_id.$error }"
           
        >
        <label class="required-label" for="">{{'Select' + getCustomNames.userSubgroup.custom_name != null
              ? getCustomNames.userSubgroup.custom_name
              : 'Subgroup' + ':'  }}
      </label>
          <SelectSubgroupForProgramExitSurvey
            :surveyId="surveyId"
            :subgroupForProgramExitSurvey="subgroupForProgramExitSurvey"
            @setUserSubgroupId="setUserSubgroupId"
            :survey="survey"
            ref="programExitSurvey"
            :disableChange="surveyInfo.id ? true : false"
          />
        
        </b-form-group>
          <span
            class="input-error-message"
            v-if="
              !$v.survey.usersubgroup_id.required &&
              $v.survey.usersubgroup_id.$dirty
            "
            >{{getCustomNames.userSubgroup.custom_name != null
              ? getCustomNames.userSubgroup.custom_name
              : 'Subgroup' + ':'  }} is required</span
          >
        <b-form-group
          label="Coure Teacher Combinations:"
          v-if="!survey.id && survey.survey_type == 'student_feedback'"
        >
          <!--Attach Course Teacher Combination-->
          <SyncCourseTeachers
            :subgroupsForTeachers="subgroupsForTeachers"
            :surveyId="surveyId"
            ref="syncTeachers"
          />
        </b-form-group>
        <div v-if="survey.id">
          <!--Edit Course Teacher Combination-->
          <b-form-group v-if="survey.survey_type == 'student_feedback'">
            <p
              style="font-size: 14px; font-weight: 600"
              class="text-info btn p-0 m-0"
              @click="editCourseTeacherCombination()"
            >
              Edit Course Instructor Combination.
            </p>
            <EditCourseTeacherCombination
              :subgroupsForTeachers="subgroupsForTeachers"
              :surveyId="survey.id"
              ref="editCourseTeacherCombination"
            />
          </b-form-group>

          <b-form-group v-if="survey.survey_type == 'student_feedback'">
            <!--Edit Participants Details-->
            <p
              style="font-size: 14px; font-weight: 600"
              class="text-info btn p-0 m-0"
              @click="editParticipantDetails()"
            >
              Edit Participants Details.
            </p>
            <EditParticipantsDetails
              :subgroupForStudents="subgroupForStudents"
              :surveyId="survey.id"
              ref="editParticipantsDetails"
            />
          </b-form-group>

          <b-form-group v-if="survey.survey_type == 'course_exit'">
            <p
              style="font-size: 14px; font-weight: 600"
              class="text-info btn p-0 m-0"
              @click="editParticipantDetails()"
            >
              Edit Course Exit Participants Details.
            </p>
            <EditCourseExitParticipants
              :subgroupForStudents="subgroupForStudents"
              :surveyId="survey.id"
              :courseId="survey.course_id"
              ref="editParticipantsDetails"
            />
          </b-form-group>

          <!-- <b-form-group v-if="survey.survey_type == 'programme_exit'">

            <p
              style="font-size: 14px; font-weight: 600"
              class="text-info btn p-0 m-0"
              @click="editParticipantDetails()"
            >
              Edit Participants Details.
            </p>
            <EditParticipantsDetails
              :subgroupForStudents="subgroupForStudents"
              :surveyId="survey.id"
              ref="editParticipantsDetails"
            />
          </b-form-group> -->
        </div>

        <b-form-group
          label="Tag:"
          v-if="
            survey.survey_mode == 'online'
          "
        >
          <b-form-input
            placeholder="Enter Tag Name"
            v-model="survey.grouping_tag"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Comment Feedback"
          v-if="
            survey.survey_mode == 'online'
          "
        >
          <b-form-checkbox
            v-model="survey.comment_feedback"
            switch
            size="md"
          ></b-form-checkbox>
        </b-form-group>
        <b-form-group
          label="Keep Participants Details Anonymous:"
          v-if="
            survey.survey_mode == 'online'
          "
        >
          <b-form-checkbox
            v-model="survey.is_anonymous"
            switch
            size="md"
          ></b-form-checkbox>
        </b-form-group>
        <b-form-group>
          <div class="d-flex justify-content-between">
            <b-button
              variant="primary"
              type="submit"
              size="md"
              :disabled="surveyBtn.disabled"
              >{{ surveyBtn.text }}
            </b-button>
            <b-button
              class="ml-auto"
              variant="danger"
              size="md"
              :disabled="surveyBtn.disabled"
              @click="cancel()"
              >Cancel
            </b-button>
          </div>
        </b-form-group>
      </b-form>
    </div>
  </div>
</template>

<script>
// import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import SyncStudentList from "./SyncStudentList.vue";
import SyncCourseTeachers from "./SyncCourseTeachers.vue";
import EditCourseTeacherCombination from "./EditCourseTeacherCombination.vue";
import EditParticipantsDetails from "./EditParticipantsDetails.vue";
import EditCourseExitParticipants from "./EditCourseExitParticipants.vue";
import SelectCourseForCourseExitSurvey from "./SelectCourseForCourseExitSurvey.vue";
import SelectSubgroupForProgramExitSurvey from "./SelectSubgroupForProgramExitSurvey.vue";
import acl from "../../../../authorization/acl.js";

import { required, requiredIf } from "vuelidate/lib/validators";

export default {
  props: ["surveyInfo"],
  async created() {
    this.fetchData = true;
    // this.$set(this.survey, "acedemic_year", null);
    const surveyInstituteAdmin = await acl.isUserGranted(
      "surveyInstituteAdmin"
    );
    this.surveyInstituteAdmin = surveyInstituteAdmin;

    const surveyGroupAdmin = await acl.isUserGranted("surveyGroupAdmin");
    this.surveyGroupAdmin = surveyGroupAdmin;

    const surveyInstructorAdmin = await acl.isUserGranted(
      "surveyInstructorAdmin"
    );
    this.surveyInstructorAdmin = surveyInstructorAdmin;

    await this.getSurveyTypesBasedOnPermissions();

    if (this.surveyInfo.id) {
      this.survey = await this.surveyInfo;
      const surveyDate = this.survey.date;
      this.date_upto = await new Date(surveyDate[1]);
      this.date_from = await new Date(surveyDate[0]);
      this.$forceUpdate();
    }
    await this.getMyCourses();
    await this.getSubgroups();
  },
  components: {
    // DatePicker,
    SyncStudentList,
    SyncCourseTeachers,
    EditCourseTeacherCombination,
    EditParticipantsDetails,
    EditCourseExitParticipants,
    SelectCourseForCourseExitSurvey,
    SelectSubgroupForProgramExitSurvey,
  },
  data() {
    return {
      fetchData: false,
      survey: { academic_year: null },
      date_from: null,
      date_upto: null,
      surveyTypeOptions: [],
      surveyOnlineOfflineOptions: [
        { text: "Online", value: "online" },
        { text: "Offline", value: "offline" },
      ],
      surveyOfflineOptions: [{ text: "Offline", value: "offline" }],
      surveyOnlineOptions: [ { text: "Online", value: "online" }],
      surveyBtn: {
        text: "Submit",
        disabled: false,
      },
      subgroupTeachersValidations: [],
      subgroupsForTeachers: [],
      subgroupForStudents: [],
      // acadimicYears: [],
      responseErrors: {},
      surveyId: "",
      surveyInstituteAdmin: null,
      surveyGroupAdmin: null,
      surveyInstructorAdmin: null,
      myCoursesOptions: [],
      subgroupForProgramExitSurvey: [],
    };
  },
  validations: {
    // subgroupTeachersValidations: {
    //   required,
    //   minLength: minLength(1),
    // },

    date_from: {
      required: requiredIf(function () {
        if (
          this.survey.survey_mode == "online"
        ) {
          return true;
        }
      }),
    },
    date_upto: {
      required: requiredIf(function () {
        if (
          this.survey.survey_mode == "online"
        ) {
          return true;
        }
      }),
    },
    survey: {
      name: {
        required,
      },
      academic_year: {
        required,
      },
      course_id: {
      required: requiredIf(function () {
        if (
          this.survey.survey_type == "course_exit"
        ) {
          return true;
        }
      }),
    },
    usersubgroup_id: {
      required: requiredIf(function () {
        if (
          this.survey.survey_type == 'programme_exit' ||
          this.survey.survey_type == 'alumini' ||
          this.survey.survey_type == 'employer' ||
          this.survey.survey_type == 'parent' ||
          this.survey.survey_type == 'faculty'
        ) {
          return true;
        }
      }),
    },
      // date: {
      //   required,
      // },
    },
  },
  watch: {
    "survey.survey_type": function () {
      if(!this.surveyInfo.id){
        if (
        this.survey.survey_type == "student_feedback" ||
        this.survey.survey_type == "programme_exit" ||
        this.survey.survey_type == "course_exit"
      ) {
        this.survey.survey_mode = "online";
      } else {
        this.survey.survey_mode = "offline";
      }
      }
      
    },
  },
  computed: {
    getCustomNames() {
      return this.$store.getters.getCustomNamings;
    },
  },
  methods: {
    updateSurveyType(selected) {
      delete this.survey["course_id"];
      delete this.survey["usersubgroup_id"];
      this.$forceUpdate();
      selected;
    },
    updateAcademicYear() {
      // this.$forceUpdate();
      // selected;
    },
    storeCourseId(courseId) {
      this.$set(this.survey, "course_id", `${courseId}`);
    },
    setUserSubgroupId(subgroupId) {
      this.$set(this.survey, "usersubgroup_id", `${subgroupId}`);
    },
    //***********Preparing survey submission for API**************//
    async prepareSurveySubmission() {
      await this.$v.$touch();
      if (this.$v.$invalid) {
        const tabHeight = document.getElementById(
          "staffListTab__BV_tab_container_"
        );
        this.$smoothScroll({
          container: tabHeight,
          scrollTo: document.querySelector(".form-group-error"),
          duration: 1000,
          offset: -120,
        });

        this.submitStatus = "ERROR";
      } else {
        let syncTeachers;
        let syncCourseExitSurveyUsers;
        let syncProgramExitSurveyUsers;
        //Checking Teachers Array in child component.
        if (!this.survey.id) {
          //Student Feedback Survey
          if (this.survey.survey_type == "student_feedback") {
            syncTeachers = await this.$refs.syncTeachers
              .selectedSubgroupsTeachers;
            if (syncTeachers.length == 0) {
              return this.$toast.error(
                `Atleast one course teacher combination is required`,
                {
                  position: "top",
                  duration: 3000,
                }
              );
            }
          }

          //Course Exit Survey
          if (this.survey.survey_type == "course_exit") {
            syncCourseExitSurveyUsers = await this.$refs.courseExitSurvey
              .courseUsers;
            if (syncCourseExitSurveyUsers.length == 0) {
              return this.$toast.error(
                `Atleast one course with users should be selected!`,
                {
                  position: "top",
                  duration: 3000,
                }
              );
            }
          }

          //Program Exit Survey
          if (this.survey.survey_type == "programme_exit") {
            syncProgramExitSurveyUsers = await this.$refs.programExitSurvey
              .subgroupUsers;
            if (syncProgramExitSurveyUsers.length == 0) {
              return this.$toast.error(
                `Atleast one subgroup with users should be selected!`,
                {
                  position: "top",
                  duration: 3000,
                }
              );
            }
          }
        }
        // const prepareDate = this.survey.date;
        //Preparing Survey POST OR PUT Data
        const surveyPostData = await {
          name: this.survey.name,
          summary: this.survey.summary,
          academic_year: this.survey.academic_year,
          start_at: this.date_from,
          end_at: this.date_upto,
          time_required: null,
          course_id: this.survey.course_id,
          usersubgroup_id: this.survey.usersubgroup_id,
          grouping_tag: !this.survey.grouping_tag
            ? (this.survey.grouping_tag = "")
            : this.survey.grouping_tag,
          survey_type: this.survey.survey_type,
          survey_mode: this.survey.survey_mode
            ? this.survey.survey_mode
            : "online",
          comment_feedback: !this.survey.comment_feedback
            ? (this.survey.comment_feedback = false)
            : this.survey.comment_feedback,
          is_anonymous: !this.survey.is_anonymous
            ? (this.survey.is_anonymous = false)
            : this.survey.is_anonymous,
          hidden: this.survey.hidden,
        };

        if (!this.survey.id) {
          this.surveyBtn.text = "Please wait...";
        } else {
          this.surveyBtn.text = "Updating...";
        }
        this.surveyBtn.disabled = true;
        let createSurvey;
        let updateSurvey;
        //********Calling CREATE SURVEY API*************/
        if (!this.survey.id) {
          createSurvey = await this.createSurvey(surveyPostData);
          if (createSurvey) {
            const surveyName = this.survey.name + " is sucessfully created.";
            this.$toast.success(`${surveyName}`, {
              position: "top",
              duration: 3000,
            });
            //***************Syncing Teachers ****************
            if (this.survey.survey_type == "student_feedback") {
              if (syncTeachers.length != 0) {
                await this.syncTeachers();
              }
            } else {
              this.clearResponse(true);
            }
            //***************Syncing Courses ****************
            // if (this.survey.survey_type == "course_exit") {
            //   if (syncCourseExitSurveyUsers.length != 0) {
            //     this.syncCourseExitSurveyUsers();
            //   }
            // }

            // if (this.survey.survey_type == "programme_exit") {
            //   if (syncProgramExitSurveyUsers.length != 0) {
            //     this.syncProgramExitSurveyUsers();
            //   }
            // }
          }

          if (!createSurvey) {
            if (Object.keys(this.responseErrors).length > 0) {
              for (const err in this.responseErrors) {
                await this.$toast.error(`${this.responseErrors[err][0]}`, {
                  position: "top",
                  duration: 3000,
                });
              }
            } else {
              this.$toast.error(
                "Something went wrong, Please try again later!",
                {
                  position: "top",
                  duration: 3000,
                }
              );
            }
            return this.clearResponse(false);
          }
        }
        //********Caliing UPDATE SURVEY API*************/
        else {
          updateSurvey = await this.updateSurvey(surveyPostData);
          if (updateSurvey) {
            const surveyName = this.survey.name + " is sucessfully updated.";
            this.$toast.success(`${surveyName}`, {
              position: "top",
              duration: 3000,
            });
            this.clearResponse(true);
          }
          if (!updateSurvey) {
            if (Object.keys(this.responseErrors).length > 0) {
              for (const err in this.responseErrors) {
                await this.$toast.error(`${this.responseErrors[err][0]}`, {
                  position: "top",
                  duration: 3000,
                });
              }
            } else {
              this.$toast.error(
                "Something went wrong, Please try again later!",
                {
                  position: "top",
                  duration: 3000,
                }
              );
            }
            return this.clearResponse(false);
          }
        }
      }
    },
    //***********Creating Survey with API**************//
    async createSurvey(surveyPostData) {
      const url = this.$store.getters.getAPIKey.studentFeedbackSurvey;
      let surveyStatus = null;
      await this.$axios
        .post(url, surveyPostData)
        .then((response) => {
          this.surveyId = response.data.id;
          surveyStatus = true;
          response;
        })
        .catch((error) => {
          if (error.response.data.errors) {
            this.responseErrors = error.response.data.errors;
          }
          surveyStatus = false;
          error;
        });
      return surveyStatus;
    },
    //***********Updating Survey with API**************//
    async updateSurvey(surveyPostData) {
      const url =
        this.$store.getters.getAPIKey.studentFeedbackSurvey +
        `/${this.survey.id}`;
      let status = null;
      await this.$axios
        .put(url, surveyPostData)
        .then((response) => {
          this.surveyId = response.data;
          status = true;
          response;
        })
        .catch((error) => {
          if (error.response.data.errors) {
            this.responseErrors = error.response.data.errors;
          }
          status = false;
          error;
        });
      return status;
    },

    getSurveyTypesBasedOnPermissions() {
      if (this.surveyInstituteAdmin) {
        this.surveyTypeOptions.push({
          value: "student_feedback",
          text: "Instructor Feedback Survey",
        });
        this.$set(this.survey, "survey_type", "student_feedback");
      }

      if (this.surveyGroupAdmin) {
        this.surveyTypeOptions.push({
          value: "programme_exit",
          text: "Program Exit Survey",
        });
        this.$set(this.survey, "survey_type", "programme_exit");
      }

      if (this.surveyInstructorAdmin) {
        this.surveyTypeOptions.push({
          value: "course_exit",
          text: "Course Exit Survey",
        });
        this.$set(this.survey, "survey_type", "course_exit");
      }
      if (this.surveyGroupAdmin) {
        this.surveyTypeOptions.push({
          value: "alumini",
          text: "Alumini Survey",
        });
        this.$set(this.survey, "survey_type", "alumini");
      }
      if (this.surveyGroupAdmin) {
        this.surveyTypeOptions.push({
          value: "employer",
          text: "Employer Survey",
        });
        this.$set(this.survey, "survey_type", "employer");
      }
      if (this.surveyGroupAdmin) {
        this.surveyTypeOptions.push({
          value: "parent",
          text: "Parent Survey",
        });
        this.$set(this.survey, "survey_type", "parent");
      }
      if (this.surveyGroupAdmin) {
        this.surveyTypeOptions.push({
          value: "faculty",
          text: "Faculty Survey",
        });
        this.$set(this.survey, "survey_type", "faculty");
      }
    },
    //********** Edit Course Teacher Combination **********/
    editCourseTeacherCombination() {
      this.$refs.editCourseTeacherCombination.checkCourseTeacherCombination();
    },
    editParticipantDetails() {
      this.$refs.editParticipantsDetails.checkParticipants();
    },
    //*********Sync Teachers *********/
    async syncTeachers() {
      await this.$toast.info(`Linking course Teachers, Please wait`, {
        position: "top-right",
        duration: 3000,
      });
      await this.$refs.syncTeachers.prepareSyncTeachersPostData();
      await this.$refs.syncStudents.checkParticipants();
    },
    //*********Sync Course Exit Survey Users *********/
    async syncCourseExitSurveyUsers() {
      await this.$toast.info(`Linking course, Please wait`, {
        position: "top-right",
        duration: 3000,
      });
      await this.$refs.courseExitSurvey.prepareSyncTeachersPostData();
      await this.$refs.syncStudents.checkParticipants();
    },

    //*********Sync Course Exit Survey Users *********/
    async syncProgramExitSurveyUsers() {
      await this.$toast.info(`Linking subgroup, Please wait`, {
        position: "top-right",
        duration: 3000,
      });
      await this.$refs.programExitSurvey.prepareSyncTeachersPostData();
      await this.$refs.syncStudents.checkParticipants();
    },
    //*********Get Subgroups Info *********/
    async getSubgroups() {
      this.fetchData = true;
      this.subgroups = [];
      const url =
        this.$store.getters.getAPIKey.mainAPI + "/usergroups/withsubgroups";
      await this.$axios
        .get(url)
        .then((response) => {
          this.subgroupsOptions = [];
          this.subgroupForProgramExitSurvey = [];
          const subgroups = response.data;
          subgroups.forEach((group) => {
            group.usersubgroups.forEach((subgroup) => {
              this.subgroupsForTeachers.push({
                value: subgroup.id,
                text: `${subgroup.name}-${group.name}`,
                disabled: false,
              });

              this.subgroupForStudents.push({
                value: subgroup.id,
                text: `${subgroup.name}-${group.name}`,
                disabled: false,
              });
            });
          });
          this.subgroupForProgramExitSurvey = [
            {
              value: null,
              text:
                subgroups.length == 0
                  ? "No Subgroups Found!"
                  : "Select Subgroup",
            },
          ];
          subgroups.forEach((group) => {
            group.usersubgroups.forEach((subgroup) => {
              this.subgroupForProgramExitSurvey.push({
                value: subgroup.id,
                text: `${subgroup.name}`,
                disabled: false,
              });
            });
          });

          this.fetchData = false;
          response;
        })
        .catch((error) => {
          error;
          this.fetchData = false;
        });
    },
    getMyCourses() {
      this.loadData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/institutionuser/courses/withusers`;
      this.$axios
        .get(url)
        .then((response) => {
          let courses = response.data;
          this.myCoursesOptions = [{ value: null, text: "Select Course" }];
          courses.forEach((course) => {
            this.myCoursesOptions.push({ value: course.id, text: course.name });
          });
          response;
          this.loadData = false;
        })
        .catch((error) => {
          error;
          this.loadData = false;
        });
    },
    clearResponse(status) {
      this.surveyBtn.text = "Submit";
      setTimeout(() => {
        if (status) {
          this.survey = {};
          this.surveyBtn.disabled = false;
          this.cancel();
        } else {
          this.surveyBtn.disabled = false;
          this.responseErrors == {};
        }
      }, 4000);
    },
    cancel() {
      this.$emit("surveyCancelled");
    },
  },
};
</script>

<style>
.createSurvey form input:focus,
.createSurvey form textarea:focus,
.createSurvey form select:focus {
  border: 2px solid #5cb85c;
}
</style>
