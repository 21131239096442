<template>
  <div>
    <b-overlay :show="loadData" spinner-variant="primary" spinner-type="grow" spinner-small rounded="md">
      <!-- <div class="loading" v-if="fetchData">
        <load-data></load-data>
      </div> -->
      <div class="studentAttendence">
        <div class="table-responsive">
          <table class="table table-striped table-bordered" v-if="!loadData">
            <thead>
              <tr>
                <th colspan="100" class="text-center">Attendance Report</th>
              </tr>
              <tr>
                <th></th>
                <th v-for="(session, sessionIndex) in sessions" :key="sessionIndex" class="text-capitalize">
                  {{ session.name }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(attDate, attIndex) in attendanceDates" :key="attIndex">
                <td>{{ attDate.date }}-{{ attDate.day }}</td>
                <td v-for="(session, sessionIndex) in sessions" :key="sessionIndex" class="text-center">
                  <span v-if="studentAttendanceData[attDate.key][session.id].attendance" style="">
                    <span class="attType">
                      {{
                          attendanceTypes[
                            studentAttendanceData[attDate.key][session.id].attendance
                          ].code
                      }}</span><br />
                    <span class="course">
                      {{
    courses[studentAttendanceData[attDate.key][session.id].course]
      .name
                      }}</span>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
// import acl from "../../../../../authorization/acl.js";

export default {
  async created() {
    this.getStudentAttendanceDetailedReport();
  },
  data() {
    return {
      loadData: true,
      courses: {},
      sessions: {},
      attendanceTypes: {},
      studentAttendanceData: {},
      attendanceDates: {},
    };
  },
  methods: {
    getStudentAttendanceDetailedReport() {
      this.loadData = true;
      let mainUrl = this.$store.getters.getAPIKey.mainAPI;
      const url = mainUrl + "/attendancereports/student/detailed";
      this.$axios
        .post(url)
        .then((response) => {
          let responseData = response.data;
          this.courses = responseData.courses;
          this.sessions = responseData.sessions;
          this.attendanceTypes = responseData.attendanceTypes;
          this.studentAttendanceData = responseData.studentAttendanceData;
          this.attendanceDates = responseData.attendanceDatesArray;
          this.loadData = false;
        })
        .catch((error) => {
          error;
          this.loadData = false;
        });
    },
  },
};
</script>

<style >
.attType {
  font-size: 12px !important;
}

.course {
  font-size: 10px !important;
}

.selectReportType .list-group .list-group-item {
  font-size: 14px;
  font-weight: 600;
  padding: 5px;
}
</style>
