<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div v-else>
      <div class="d-flex justify-content-between">
        <b-button
          class="ml-2 mb-3"
          variant="primary"
          size="sm"
          :disabled="
            exportCsvBtn.disabled ||
              !exam.participants ||
              exam.participants.length == 0
          "
          @click="exportToExcel('xlsx')"
          >{{ exportCsvBtn.text }}</b-button
        >
        <h4 class="text-center font-weight-bolder">
          Paricipants for: <span class="text-info">{{ exam.name }}</span>
        </h4>
        <p></p>
      </div>

      <div class="table-responsive">
        <table
          class="table table-borderd table-strped"
          id="assessmentDetailedReport"
        >
          <thead
            class="thead-dark"
            style="font-size: 14px; font-weight: 600; text-align: center"
          >
            <tr>
              <th>Name</th>
              <th>Started At</th>
              <th>Ended At</th>
              <th>No. of questions attempted</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!exam.participants || exam.participants.length == 0">
              <td colspan="100">
                <p class="text-danger text-center font-weight-bolder">
                  No Participants Found!
                </p>
              </td>
            </tr>
            <tr
              v-for="(participant, participantsIndex) in exam.participants"
              :key="participantsIndex"
              style="font-size: 14px; font-weight: 600; text-align: center"
              v-else
            >
              <td>{{ participant.first_name }}</td>
              <td>
                {{
                  participant.pivot.starts_at == null
                    ? "-"
                    : participant.pivot.starts_at.format("DD/MM/YYYY") +
                      " " +
                      participant.pivot.starts_at.format("hh:mm A")
                }}
                <!-- <br>
                {{
                  participant.pivot.start_at == null ? "-" : participant.pivot.start_at.format("hh:mm A")
                }} -->
              </td>
              <td>
                {{
                  participant.pivot.end_at == null
                    ? "-"
                    : participant.pivot.end_at.format("DD/MM/YYYY") +
                      " " +
                      participant.pivot.end_at.format("hh:mm A")
                }}
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import XLSX from "xlsx";
export default {
  props: ["examId"],
  async created() {
    this.fetchData = await true;
    await this.getDetailedReport();
    await this.prepareStudentQuestionScores();
  },
  data() {
    return {
      fetchData: false,
      exam: {},
      questionsChoices: [],
      answers: [],
      participantsQuestionScores: null,
      exportCsvBtn: {
        text: "Export to Excel",
        disabled: false,
      },
    };
  },
  methods: {
    async exportToExcel(type, fn, dl) {
      this.exportCsvBtn.text = "Please wait...";
      this.exportCsvBtn.disabled = true;
      var elt = document.getElementById("assessmentDetailedReport");
      var wb = await XLSX.utils.table_to_book(elt, { sheet: "Sheet JS" });
      this.exportCsvBtn.text = "Export to Excel";
      this.exportCsvBtn.disabled = false;
      return dl
        ? XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" })
        : XLSX.writeFile(wb, fn || "assessmentDetails." + (type || "xlsx"));
    },
    async getDetailedReport() {
      let examId = this.examId;
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/exams/${examId}/report`;
      let examreport = null;
      await this.$axios.get(url).then((response) => {
        examreport = response.data;
        this.exam = examreport.exam;
        this.questionsChoices = examreport.questionsChoices;
        this.answers = examreport.answers;
        if (this.exam.participants) {
          this.exam.participants.forEach((participant) => {
            participant.pivot.starts_at =
              participant.pivot.starts_at != null
                ? moment(
                    moment
                      .utc(participant.pivot.starts_at, "YYYY-MM-DD HH:mm:ss")
                      .toDate()
                  )
                : null;
            participant.pivot.end_at =
              participant.pivot.end_at != null
                ? moment(
                    moment
                      .utc(participant.pivot.end_at, "YYYY-MM-DD HH:mm:ss")
                      .toDate()
                  )
                : null;
          });
        }
      });
      return examreport;
    },

    async prepareStudentQuestionScores() {
      let answersObjects = {};
      await this.answers.forEach((answer) => {
        if (!answersObjects[answer.student_id])
          answersObjects[answer.student_id] = {};
        answersObjects[answer.student_id][answer.examquestion_id] = answer;
      });
      this.fetchData = await false;
      // this.answersObjects = answersObjects;

      let participantsQuestionScores = {};
      let participants = this.exam.participants;
      await participants.forEach((participant) => {
        participantsQuestionScores[participant.id] = {};
        this.questionsChoices.forEach((question) => {
          participantsQuestionScores[participant.id][question.id] = {
            score: "",
            answer: "",
          };
          if (answersObjects[participant.id]) {
            if (answersObjects[participant.id][question.id])
              participantsQuestionScores[participant.id][question.id] =
                answersObjects[participant.id][question.id];
          }
        });
      });

      this.participantsQuestionScores = participantsQuestionScores;
    },
  },
};
</script>

<style ></style>
