<template>
  <div>
    <div class="editInstitution">
      <b-overlay :show="loadInstitute" spinner-variant="primary" spinner-type="grow" spinner-small rounded="md">
        <ViewInstitute :selectedInstitute="selectedInstitute" v-if="!editInstitute"
          @editInstitute="editInstitute = true" />
        <b-form @submit.prevent="prepareInstituteUpdation()" class="border-0" v-else>
          <b-form-group label="Institute Name">
            <b-form-input placeholder="Institute Name" v-model="institute.name" :class="{
              'form-group-error': $v.institute.name.$error || responseErrors.name,
            }"></b-form-input>
            <span class="input-error-message" v-if="!$v.institute.name.required && $v.institute.name.$dirty">Institute
              Name is required!</span>
            <span class="input-error-message" v-if="responseErrors.name">{{
            responseErrors.name[0]
            }}</span>
          </b-form-group>
          <b-form-group label="Institute Code">
            <b-form-input placeholder="Institute Code" v-model="institute.code" :class="{
              'form-group-error': $v.institute.code.$error || responseErrors.code,
            }"></b-form-input>
            <span class="input-error-message" v-if="responseErrors.code">{{
            responseErrors.code[0]
            }}</span>
            <span class="input-error-message" v-if="!$v.institute.code.required && $v.institute.code.$dirty">Institute
              Code is required! <br /></span>
            <span class="input-error-message" v-if="!$v.institute.code.maxLength && $v.institute.code.$dirty">Institute
              code cannot be greater than 8 letters!</span>
          </b-form-group>

          <b-form-group label="University (for course suggestions)">
            <b-form-select v-model="institute.university" :options="university"></b-form-select>
          </b-form-group>

          <b-form-group label="Line 1">
            <b-form-input placeholder="Line 1" v-model="institute.name_2"></b-form-input>
          </b-form-group>
          <b-form-group label="Line 2">
            <b-form-input placeholder="Line 2" v-model="institute.name_3"> </b-form-input>
          </b-form-group>
          <b-form-group label="Address Line 1">
            <b-form-input v-model="institute.address" :class="{
              'form-group-error': $v.institute.address.$error || responseErrors.address,
            }"></b-form-input>
            <span class="input-error-message" v-if="responseErrors.address">{{
            responseErrors.address[0]
            }}</span>
            <span class="input-error-message"
              v-if="!$v.institute.address.required && $v.institute.address.$dirty">Institute Address is required!
              <br /></span>
          </b-form-group>
          <b-form-group label="Address Line 2">
            <b-form-input v-model="institute.address2"></b-form-input>
          </b-form-group>
          <b-form-group label="Country" :class="{ 'form-group-error': $v.institute.country.$error }">
            <b-form-select v-model="institute.country" :options="country"></b-form-select>

            <span class="input-error-message"
              v-if="!$v.institute.country.required && $v.institute.country.$dirty">Country is required! <br /></span>
          </b-form-group>
          <b-form-group label="State">
            <b-form-select v-model="institute.state" :options="state"
              :class="{ 'form-group-error': $v.institute.state.$error }"></b-form-select>
            <span class="input-error-message" v-if="!$v.institute.state.required && $v.institute.state.$dirty">State is
              required! <br /></span>
          </b-form-group>
          <b-form-group label="Pincode">
            <b-form-input v-model="institute.pincode" :class="{ 'form-group-error': $v.institute.pincode.$error }">
            </b-form-input>
            <span class="input-error-message"
              v-if="!$v.institute.pincode.required && $v.institute.pincode.$dirty">Pincode is required! <br /></span>
          </b-form-group>
          <b-form-group>
            <div class="d-flex justify-content-between">
              <b-button variant="primary" type="submit" size="sm" :disabled="instituteBtn.disabled">{{ instituteBtn.text
              }}</b-button>
              <b-button variant="danger" size="sm" @click="editInstitute = false" :disabled="instituteBtn.disabled">
                Cancel</b-button>
            </div>
          </b-form-group>
        </b-form>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import { required, maxLength, numeric } from "vuelidate/lib/validators";
import ViewInstitute from "./ViewInstitute.vue";
export default {
  created() {
    this.$set(this.institute, "name", this.selectedInstitute.name);
    this.$set(this.institute, "code", this.selectedInstitute.code);
    this.$set(this.institute, "name_2", this.selectedInstitute.name_2);
    this.$set(this.institute, "name_3", this.selectedInstitute.name_3);
    this.$set(this.institute, "address", this.selectedInstitute.address);
    this.$set(this.institute, "address2", this.selectedInstitute.address2);
    this.$set(this.institute, "state", this.selectedInstitute.state);
    this.$set(this.institute, "country", this.selectedInstitute.country);
    this.$set(this.institute, "pincode", this.selectedInstitute.pincode);
    this.$set(this.institute, "university", this.selectedInstitute.university);
  },
  computed: {
    selectedInstitute() {
      return this.$store.getters.getCurrentInstitution;
    },
  },
  components: {
    ViewInstitute,
  },
  data() {
    return {
      editInstitute: false,
      institute: {},
      loadInstitute: false,
      instituteBtn: {
        text: "Submit",
        disabled: false,
      },
      instituteResponse: {},
      address: {},
      country: [
        { value: null, text: "Select Country", disabled: true },
        { value: "India", text: "India" },
      ],
      responseErrors: {},
      state: [
        "Andhra Pradesh",
        "Arunachal Pradesh",
        "Assam",
        "Bihar",
        "Chhattisgarh",
        "Goa",
        "Gujarat",
        "Haryana",
        "Himachal Pradesh",
        "Jammu and Kashmir",
        "Jharkhand",
        "Karnataka",
        "Kerala",
        "Madhya Pradesh",
        "Maharashtra",
        "Manipur",
        "Meghalaya",
        "Mizoram",
        "Nagaland",
        "Odisha",
        "Punjab",
        "Rajasthan",
        "Sikkim",
        "Tamil Nadu",
        "Telangana",
        "Tripura",
        "Uttarakhand",
        "Uttar Pradesh",
        "West Bengal",
        "Andaman and Nicobar Islands",
        "Chandigarh",
        "Dadra and Nagar Haveli",
        "Daman and Diu",
        "Delhi",
        "Lakshadweep",
        "Puducherry",
      ],
      university: [
        "University of Kerala",
        "University of Calicut",
        "Cochin University of Science and Technology",
        "Mahatma Gandhi University",
        "	Kannur University",
        "Sree Narayana Guru Open University",
        "Kerala University of Health Sciences",
        "Kerala Veterinary and Animal Sciences University",
        "Kerala Agricultural University",
        "Kerala University of Fisheries and Ocean Studies",
        "National University of Advanced Legal Studies",
        "Sree Sankaracharya University of Sanskrit",
        "Thunchath Ezhuthachan Malayalam University",
        "Kerala University of Digital Sciences, Innovation and Technology",
        "A P J Abdul Kalam Technological University",
        "K. R. Narayanan National Institute of Visual Science and Arts",
        "Department of Technical Education Kerala",
        "Anna University",
        "Chinmaya Vishwa Vidyapeeth",
        "St. Peters University"
      ],
    };
  },
  validations: {
    institute: {
      name: {
        required,
      },
      code: {
        required,
        maxLength: maxLength(8),
      },
      address: {
        required,
      },
      state: {
        required,
      },
      country: {
        required,
      },
      pincode: {
        required,
        numeric,
      },
    },
    // address: {

    // },
  },
  methods: {
    async prepareInstituteUpdation() {
      await this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.instituteBtn.text = "Please wait...";
        this.instituteBtn.disabled = true;
        this.loadInstitute = true;

        const updateInstitute = await this.updateInstituteInfo();
        if (updateInstitute) {
          this.$toast.success("Institute Updated", {
            position: "top",
            duration: 3000,
          });

          this.clearResponse(true);
        }
        if (!updateInstitute) {
          // this.$toast.error("Something went wrong, please try again!", {
          //   position: "top",
          //   duration: 3000,
          // });
          return this.clearResponse(false);
        }
      }
    },
    async updateInstituteInfo() {
      let status = null;
      let preparePostData = {};
      if (this.institute.name != this.selectedInstitute.name)
        preparePostData["name"] = await this.institute.name;

      if (this.institute.code != this.selectedInstitute.code)
        preparePostData["code"] = await this.institute.code;

      if (this.institute.name_2 != null)
        preparePostData["name_2"] = await this.institute.name_2;
      if (this.institute.name_3 != null)
        preparePostData["name_3"] = await this.institute.name_3;

      preparePostData["address"] = await this.institute.address;
      preparePostData["address2"] = await this.institute.address2;
      preparePostData["country"] = await this.institute.country;
      preparePostData["state"] = await this.institute.state;
      preparePostData["pincode"] = await this.institute.pincode;
      preparePostData["university"] = await this.institute.university;

      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/institutions/${this.selectedInstitute.id}`;
      await this.$axios
        .put(url, preparePostData)
        .then((response) => {
          this.instituteResponse = response.data;
          status = true;
        })
        .catch((error) => {
          this.responseErrors = error.response.data.errors;
          status = false;
        });
      return status;
    },

    clearResponse(status) {
      this.instituteBtn.text = "Submit";
      this.loadInstitute = false;
      setTimeout(() => {
        if (status) {
          this.$store.commit('getCurrentInstitution', this.instituteResponse)
          this.loadInstitute = false;
          this.instituteBtn.disabled = false;
          this.editInstitute = false;
        }
        this.responseErrors = {};

        this.instituteBtn.disabled = false;
      }, 3000);
    },
  },
};
</script>
