var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.fetchData)?_c('div',{staticClass:"loading"},[_c('load-data')],1):_c('div',{staticClass:"surveyReport"},[_c('b-overlay',{attrs:{"show":_vm.loadSurveyReport,"spinner-variant":"primary","spinner-type":"grow","spinner-small":"","rounded":"md"}},[_c('b-form',{staticClass:"border-0 p-3",on:{"submit":function($event){$event.preventDefault();return _vm.getTeacherFeedbackReport()}}},[_c('b-form-group',{attrs:{"label":"Select Survey Type"}},[_c('b-form-select',{staticClass:"w-50",attrs:{"options":_vm.surveyTypesOptions},on:{"change":function($event){return _vm.getSurveys()}},model:{value:(_vm.selectedSurveyType),callback:function ($$v) {_vm.selectedSurveyType=$$v},expression:"selectedSurveyType"}})],1),(_vm.selectedSurveyType == 'course_exit')?_c('b-form-group',{attrs:{"label":"Select Course"}},[_c('b-form-select',{staticClass:"w-50",attrs:{"options":_vm.coursesOptions},on:{"change":function($event){return _vm.getSurveys()}},model:{value:(_vm.selectedCourse),callback:function ($$v) {_vm.selectedCourse=$$v},expression:"selectedCourse"}})],1):_vm._e(),(_vm.selectedSurveyType == 'programme_exit')?_c('b-form-group',{attrs:{"label":_vm.getCustomNames.userGroup.custom_name != null
              ? 'Select ' + _vm.getCustomNames.userGroup.custom_name
              : 'Select Usergroup'}},[_c('b-form-select',{staticClass:"w-50",attrs:{"options":_vm.usergroupOptions},on:{"change":function($event){return _vm.getSurveys()}},model:{value:(_vm.selectedGroupSubgroup),callback:function ($$v) {_vm.selectedGroupSubgroup=$$v},expression:"selectedGroupSubgroup"}})],1):_vm._e(),(_vm.selectedSurveyType != null)?_c('b-form-group',[(
              _vm.selectedSurveyType != 'programme_exit' &&
                _vm.selectedSurveyType != 'course_exit'
            )?_c('div',{staticClass:"table-responsive rounded"},[_c('table',{staticClass:"table bordered table-bordered table-striped"},[_c('thead',{staticClass:"thead-dark"},[_c('tr',{staticStyle:{"font-size":"14px","font-weight":"600","text-align":"center"}},[_c('th',[_vm._v("Name")]),(
                      _vm.selectedSurveyType == 'course_exit' ||
                        _vm.selectedSurveyType == 'programme_exit'
                    )?_c('th',[_vm._v(" Created For ")]):_vm._e(),_c('th',[_vm._v("Created Date")]),_c('th',[_vm._v("Start Date")]),_c('th',[_vm._v("End Date")]),_c('th')])]),_c('tbody',[(_vm.filterSurveys.length == 0)?_c('tr',[_c('td',{staticClass:"text-danger text-center align-middle",attrs:{"colspan":"100"}},[_vm._v(" No Surveys Found! ")])]):_vm._l((_vm.filterSurveys),function(survey,surveyIndex){return _c('tr',{key:surveyIndex,staticClass:"text-center",staticStyle:{"font-size":"14px","font-weight":"600"}},[_c('td',{staticClass:"align-middle"},[_c('div',{},[_c('span',{attrs:{"id":'survey' + surveyIndex}},[_vm._v(_vm._s(survey.name)+" ")])]),_c('br'),_c('b-tooltip',{attrs:{"target":'survey' + surveyIndex,"triggers":"hover","placement":"topright"}},[_c('p',{staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(survey.summary))])])],1),(
                      _vm.selectedSurveyType == 'course_exit' ||
                        _vm.selectedSurveyType == 'programme_exit'
                    )?_c('td',{staticClass:"align-middle text-center"},[(_vm.selectedSurveyType == 'course_exit')?_c('span',[_vm._v(" "+_vm._s(survey.course && survey.course.name ? survey.course.code + "-" + survey.course.usersubgroup.usergroup.code + "-" + survey.course.usersubgroup.code : "")+" ")]):_vm._e(),(_vm.selectedSurveyType == 'programme_exit')?_c('span',[_vm._v(" "+_vm._s(survey.usersubgroup != null && survey.usersubgroup.name ? survey.usersubgroup.usergroup.code + " - " + survey.usersubgroup.code : "")+" ")]):_vm._e()]):_vm._e(),_c('td',{staticClass:"align-middle"},[_vm._v(_vm._s(survey.created_at))]),_c('td',{staticClass:"align-middle"},[_vm._v(" "+_vm._s(survey.start_at.format("DD/MM/YYYY"))+" ")]),_c('td',{staticClass:"align-middle"},[_vm._v(" "+_vm._s(survey.end_at.format("DD/MM/YYYY"))+" ")]),_c('td',{staticClass:"align-middle text-left"},[(
                        (_vm.institutionAdmin || _vm.groupAdmin || _vm.subgroupAdmin) &&
                          _vm.selectedSurveyType == 'student_feedback'
                      )?_c('b-button',{attrs:{"size":"sm","disable":_vm.selectedSurvey == null ? true : false,"variant":"primary"},on:{"click":function($event){return _vm.getTeacherFeedbackReport(survey.id)}}},[_vm._v("Survey Report")]):_vm._e(),(
                        (_vm.institutionAdmin || _vm.groupAdmin) &&
                          _vm.selectedSurveyType == 'programme_exit'
                      )?_c('b-button',{attrs:{"size":"sm","disable":_vm.selectedSurvey == null ? true : false,"variant":"danger"},on:{"click":function($event){return _vm.generateReport(survey.id)}}},[_vm._v("Download Survey Report")]):_vm._e(),(
                        (_vm.institutionAdmin || _vm.groupAdmin) &&
                          _vm.selectedSurveyType == 'course_exit'
                      )?_c('b-button',{attrs:{"size":"sm","disable":_vm.selectedSurvey == null ? true : false,"variant":"danger"},on:{"click":function($event){return _vm.generateReport(survey.id)}}},[_vm._v("Download Survey Report")]):_vm._e(),_c('br'),_c('br'),(_vm.surveyParticipantsReport)?_c('b-button',{attrs:{"size":"sm","disable":_vm.selectedSurvey == null ? true : false,"variant":"primary"},on:{"click":function($event){return _vm.getParticipantsReport(
                          survey.id,
                          survey.course,
                          survey.usersubgroup
                        )}}},[_vm._v("Participants Report")]):_vm._e()],1)])})],2)])]):_vm._e(),(
              (_vm.selectedGroupSubgroup != null &&
                _vm.selectedSurveyType == 'programme_exit') ||
                (_vm.selectedCourse != null &&
                  _vm.selectedSurveyType == 'course_exit')
            )?_c('div',{staticClass:"table-responsive rounded"},[_c('table',{staticClass:"table bordered table-bordered table-striped"},[_c('thead',{staticClass:"thead-dark"},[_c('tr',{staticStyle:{"font-size":"14px","font-weight":"600","text-align":"center"}},[_c('th',[_vm._v("Name")]),(
                      _vm.selectedSurveyType == 'course_exit' ||
                        _vm.selectedSurveyType == 'programme_exit'
                    )?_c('th',[_vm._v(" Created For ")]):_vm._e(),_c('th',[_vm._v("Created Date")]),_c('th',[_vm._v("Start Date")]),_c('th',[_vm._v("End Date")]),_c('th')])]),_c('tbody',[(_vm.filterSurveys.length == 0)?_c('tr',[_c('td',{staticClass:"text-danger text-center align-middle",attrs:{"colspan":"100"}},[_vm._v(" No Surveys Found! ")])]):_vm._l((_vm.filterSurveys),function(survey,surveyIndex){return _c('tr',{key:surveyIndex,staticClass:"text-center",staticStyle:{"font-size":"14px","font-weight":"600"}},[_c('td',{staticClass:"align-middle"},[_c('div',{},[_c('span',{attrs:{"id":'survey' + surveyIndex}},[_vm._v(_vm._s(survey.name)+" ")])]),_c('br'),_c('b-tooltip',{attrs:{"target":'survey' + surveyIndex,"triggers":"hover","placement":"topright"}},[_c('p',{staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(survey.summary))])])],1),(
                      _vm.selectedSurveyType == 'course_exit' ||
                        _vm.selectedSurveyType == 'programme_exit'
                    )?_c('td',{staticClass:"align-middle text-center"},[(_vm.selectedSurveyType == 'course_exit')?_c('span',[_vm._v(" "+_vm._s(survey.course && survey.course.name ? survey.course.code + "-" + survey.course.usersubgroup.usergroup.code + "-" + survey.course.usersubgroup.code : "")+" ")]):_vm._e(),(_vm.selectedSurveyType == 'programme_exit')?_c('span',[_vm._v(" "+_vm._s(survey.usersubgroup != null && survey.usersubgroup.name ? survey.usersubgroup.usergroup.code + " - " + survey.usersubgroup.code : "")+" ")]):_vm._e()]):_vm._e(),_c('td',{staticClass:"align-middle"},[_vm._v(_vm._s(survey.created_at))]),_c('td',{staticClass:"align-middle"},[_vm._v(" "+_vm._s(survey.start_at.format("DD/MM/YYYY"))+" ")]),_c('td',{staticClass:"align-middle"},[_vm._v(" "+_vm._s(survey.end_at.format("DD/MM/YYYY"))+" ")]),_c('td',{staticClass:"align-middle text-left"},[(
                        (_vm.institutionAdmin || _vm.groupAdmin || _vm.subgroupAdmin) &&
                          _vm.selectedSurveyType == 'student_feedback'
                      )?_c('b-button',{attrs:{"size":"sm","disable":_vm.selectedSurvey == null ? true : false,"variant":"primary"},on:{"click":function($event){return _vm.checkReportTypeForSurvey(survey.id)}}},[_vm._v("Survey Report")]):_vm._e(),(
                        (_vm.institutionAdmin || _vm.groupAdmin) &&
                          _vm.selectedSurveyType == 'programme_exit'
                      )?_c('b-button',{attrs:{"size":"sm","disable":_vm.selectedSurvey == null ? true : false,"variant":"danger"},on:{"click":function($event){return _vm.generateReport(survey.id)}}},[_vm._v("Download Survey Report")]):_vm._e(),(
                        (_vm.institutionAdmin || _vm.groupAdmin) &&
                          _vm.selectedSurveyType == 'course_exit'
                      )?_c('b-button',{attrs:{"size":"sm","disable":_vm.selectedSurvey == null ? true : false,"variant":"danger"},on:{"click":function($event){return _vm.generateReport(survey.id)}}},[_vm._v("Download Survey Report")]):_vm._e(),_c('br'),_c('br'),(_vm.surveyParticipantsReport)?_c('b-button',{attrs:{"size":"sm","disable":_vm.selectedSurvey == null ? true : false,"variant":"primary"},on:{"click":function($event){return _vm.getParticipantsReport(
                          survey.id,
                          survey.course,
                          survey.usersubgroup
                        )}}},[_vm._v("Participants Report")]):_vm._e()],1)])})],2)])]):_vm._e()]):_vm._e()],1)],1),_c('b-modal',{attrs:{"id":"surveyCourses","no-close-on-backdrop":"","no-close-on-keyboard":"","no-close-on-esc":"","hide-footer":"","modal-class":"courseReport"}},[_c('SurveyCourseReport',{attrs:{"survey_id":_vm.survey_id,"groupsWithSubgroupsOptions":_vm.groupsWithSubgroupsOptions,"selectedSurveyType":_vm.selectedSurveyType,"coursesOptions":_vm.coursesOptions,"getCustomNames":_vm.getCustomNames}})],1),_c('b-modal',{attrs:{"id":"surveyParticipants","no-close-on-backdrop":"","no-close-on-keyboard":"","no-close-on-esc":"","hide-footer":"","title":_vm.participantsReportTitle,"modal-class":"courseReport"}},[_c('SurveyParticipantReport',{attrs:{"students":_vm.students,"groupsWithSubgroupsOptions":_vm.groupsWithSubgroupsOptions,"survey_id":_vm.survey_id,"selectedSurveyType":_vm.selectedSurveyType,"coursesOptions":_vm.coursesOptions,"getCustomNames":_vm.getCustomNames}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }