<template>
  <div>
    <div class="surveyAdmin" v-if="surveyAdmin">
      <p>This tab is only availabe in web view.</p>
    </div>
    <div class="studentOrAdminView">
      <p class="text-danger" v-if="surveys.length == 0">
        No Forms Found!
      </p>
      <div class="studentEvent" v-else>
        <div
          class="event mt-2 p-3"
          v-for="(survey, surveyIndex) in surveys"
          :key="surveyIndex"
          :style="
            surveyIndex % 2 === 0
              ? {
                  backgroundColor: '#F0F0F0',
                  fontSize: 14 + 'px',
                  fontWeight: 600,
                }
              : {
                  backgroundColor: '#FFFFFF',
                  fontSize: 14 + 'px',
                  fontWeight: 600,
                }
          "
        >
          <div class="eventName text-center mb-2">
            <span
              :class="survey.summary != null ? 'text-info' : 'text-dark'"
              :id="'approvalSummary' + surveyIndex"
              >{{ survey.name }}</span
            >
            <b-tooltip
              :target="'approvalSummary' + surveyIndex"
              triggers="hover"
              v-if="survey.summary != null"
            >
              {{ survey.summary }}
            </b-tooltip>
          </div>
          <div class="date mb-2 d-flex justify-content-between">
            <div class="startDate">
              {{ survey.start_at.format("DD/MM/YYYY") }} <br />
              {{ survey.start_at.format("hh:mm a") }}
            </div>
            <div class="endDate">
              {{ survey.end_at.format("DD/MM/YYYY") }} <br />
              {{ survey.end_at.format("hh:mm a") }}
            </div>
          </div>
          <div class="submitBtn d-flex justify-content-center">
            <b-button
              variant="primary"
              v-if="today >= survey.start_at && today <= survey.end_at"
              size="sm"
              squared
              @click="surveyLinks('Answers', survey.id, survey.survey_type)"
              >Take Survey</b-button
            >
            <span v-else-if="today <= survey.start_at" class="text-success"
              >Upcoming Survey</span
            >
            <span v-else class="text-danger">Last Date exceeded</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["surveyAdmin", "surveyStudent", "surveys", "today"],
  methods: {
    surveyLinks(Link, surveyId, type) {
      if (Link == "Questions") {
        this.$router.push({ path: `/survey_questions/${surveyId}` });
      }
      if (Link == "Answers") {
        if (type == "student_feedback") {
          this.$router.push({ path: `/survey_answers/${surveyId}` });
        }
        if (type == "course_exit") {
          this.$router.push({
            path: `/survey_answers_course_exit/${surveyId}`,
          });
        }
        if (type == "programme_exit") {
          this.$router.push({
            path: `/survey_answers_programme_exit/${surveyId}`,
          });
        }
      }
      if (Link == "Excel") {
        this.$router.push({ path: `/survey_questions_excel/${surveyId}` });
      }
    },
  },
};
</script>
