<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="coursesOptions" v-else>

      <!-- this option is to choose copy from , but option is prop from parant -->
      <!-- <div class="col-md-12">
        <b-form-group>
          <label class="required-label" for="">Choose Resources</label>
          <b-form-select @change="changeCopyFrom()"
            :options="[{ value: '', text: 'Select' }, { value: 'template', text: 'KTU Resources' }, { value: 'myinstitute', text: 'My Institute' }]"
            v-model="copyCourseFrom"></b-form-select>
        </b-form-group>
      </div> -->
      <div class="col-md-12" v-if="copyCourseFrom == 'template'">



        <!-- <b-form-group>
          <label class="required-label" for="">Choose Resources</label>
          <b-form-select @change="getTemplateUsergroups()" :options="templateInstitutes"
            v-model="templateInstitute"></b-form-select>
          <span class="text-danger" v-if="(templateInstitutes.length == 1)">Please wait...</span>
        </b-form-group> -->
        <h2 class="text-center font-weight-bolder text-info">
          Duplicate the course from University Resources
        </h2>
        <b-form-group>
          <label class="required-label" for="">Choose Resources Programme</label>
          <b-form-select @change="getSubgroups()" :options="usergroups" v-model="usergroup_id">
          </b-form-select>
        </b-form-group>

        <b-form-group v-if="usergroup_id">
          <label class="required-label" for="">{{ 'Choose ' + subgroupCustomName + ':'
          }}</label>
          <b-form-select @change="getCourses()" :options="usersubgroups" v-model="usersubgroup_id"
            :disabled="false"></b-form-select>
        </b-form-group>

        <b-form-group v-if="usergroup_id && usersubgroup_id">
          <label class="required-label" for="">Choose Courses to be copied</label>
          <div class="row">
            <div class="col-sm-6 col-md-4 col-lg-3" v-for="(option, index) in courses" :key="index">
              <b-form-checkbox v-model="fromCourses" :value="option">{{ option.text }}
              </b-form-checkbox>
            </div>
          </div>

          <!-- <multiselect v-model="fromCourses" tag-placeholder="Add this as new tag" placeholder="Choose multiple"
            label="text" track-by="value" :options="courses" :close-on-select="false" :multiple="true" :taggable="true">
          </multiselect> -->


        </b-form-group>


        <div v-if="fromCourses.length">
          <ul>
            Data to be copied along with the selected Courses:
            <li>Courses Modules</li>
            <li>Lesson Plan</li>
            <li>Lab Experiments</li>
            <li>Course Outcomes</li>
            <li>Courses Properties (Text Books, Reference Docs,
              Prerequisites, Industrial Relevance, Content Beyond Syllabus,
              Details of Learning Materials, LTP Credits, Aims, Objectives)</li>
          </ul>
        </div>



        <div>
          The Courses will be copied to: {{ to_subgroup.name }}-{{ to_subgroup.code }} ({{ to_subgroup.academic_year }}
          {{ to_subgroup.academic_semester }})
        </div>
        <br><br>

      </div>



      <div class="col-md-12" v-if="copyCourseFrom == 'myinstitute'">
        <h2 class="text-center font-weight-bolder text-info">
          Duplicate the course from previous/same academic year
        </h2>


        <b-form-group>
          <label class="required-label" for="">Choose Academic Year to be copied from:</label>
          <b-form-select :options="$acedemicYears" v-model="academic_year">
          </b-form-select>
        </b-form-group>

        <b-form-group v-if="academic_year">
          <label class="required-label" for="">Choose Programme:</label>
          <b-form-select @change="getSubgroups()" :options="usergroups" v-model="usergroup_id">
          </b-form-select>
        </b-form-group>

        <b-form-group v-if="(academic_year && usergroup_id)">
          <label class="required-label" for="">{{ 'Choose ' + subgroupCustomName + ':'
          }}</label>
          <b-form-select @change="getCourses()" :options="usersubgroups" v-model="usersubgroup_id"
            :disabled="false"></b-form-select>
        </b-form-group>

        <b-form-group v-if="(academic_year && usergroup_id && usersubgroup_id)">
          <label class="required-label" for="">Choose Course to be copied:</label>
          <b-form-select :options="courses" v-model="course_id">
          </b-form-select>
        </b-form-group>

        <div>
          The Course will be copied to: {{ to_subgroup.name }}-{{ to_subgroup.code }} ({{ to_subgroup.academic_year }}
          {{ to_subgroup.academic_semester }})
        </div>


        <div v-if="course_id">
          <ul>
            Data to be copied along with the selected Courses:
            <li>Courses Modules</li>
            <li>Lesson Plan</li>
            <li>Lab Experiments</li>
            <li>Course Outcomes</li>
            <li>Courses Properties (Text Books, Reference Docs,
              Prerequisites, Industrial Relevance, Content Beyond Syllabus,
              Details of Learning Materials, LTP Credits, Aims, Objectives)</li>
          </ul>
        </div>
      </div>
      <div class="col-md-12">
        <!-- submission buttons  -->
        <div class="row">
          <b-form-group class="col-6 ">
            <b-button class="float-left" @click="cancel()" variant="danger" :disabled="startCopybutton.disabled">
              Cancel</b-button>
          </b-form-group>
          <b-form-group class="col-6 float-right">
            <b-button class="float-right" v-if="(course_id || fromCourses.length)" @click="copyCourse" variant="primary"
              :disabled="startCopybutton.disabled">
              {{ startCopybutton.text }}
            </b-button>
          </b-form-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["to_subgroup_id", "to_subgroup_name", "copy_from_suggestion"],
  async created() {
    this.subgroupCustomName = this.$store.getters.getCustomNamings.userSubgroup.custom_name ?? "Usersubgroup";
    this.fetchData = true;
    this.getSubgroup();
    this.copyCourseFrom = this.copy_from_suggestion;
    this.changeCopyFrom();
    // await this.getUserGroups();
    this.fetchData = false;
  },
  data() {
    return {
      startCopybutton: {
        text: "Start Copying",
        disabled: false,
      },
      to_subgroup: { name: null, code: null, academic_year: null, academic_semester: null, },
      fetchData: false,
      courses: [],
      course_id: null,
      usersubgroups: [],
      usersubgroupObjects: {},
      usersubgroup_id: null,
      usergroups: [],
      usergroup_id: null, academic_year: null,
      templateGroupSubgroupCourses: [],
      fromCourses: [],

      course_properties: {
        co: true,
        course_instructors: true,
        lab_experiments: true,
        course_scedules: true,
        boardexam_config: true,
        modules: true,
        lessonplan: true,
        co_po_pso_mapping: true,
        course_properties: true, //text_books, reference_docs, pre_requisites, industrial_relevence ,content_beyond_syllabus, details_of_lerning_matials, ltp_credits, aims, objectives
      },
      programmeCustomName: 'Programme',
      subgroupCustomName: 'Subgroup',
      copyCourseFrom: '',//'template'/'myinstitute'
      // templateInstitutes: [],
      // templateInstitute: '',
    };
  },
  methods: {
    lessonPlanEnabled() {
      if (this.course_properties.lessonplan) {
        this.course_properties.co = true;
        this.course_properties.modules = true;
      }
    },
    async changeCopyFrom() {
      this.course_id = null,
        this.usersubgroups = [];
      this.usersubgroup_id = null;
      this.usergroups = [];
      this.usergroup_id = null;

      if (this.copyCourseFrom == 'template') {
        this.getTemplateUsergroups();
        // this.templateInstitutes = [{ value: '', text: 'Select' },];
        // const url =
        //   this.$store.getters.getAPIKey.mainAPI +
        //   `/institutions/templates`;
        // await this.$axios
        //   .get(url)
        //   .then((response) => {
        //     response.data.forEach((institute) => {
        //       this.templateInstitutes.push({
        //         value: institute.id,
        //         text: `${institute.name}`,
        //       });
        //     });
        //   })
        //   .catch((error) => {
        //     error;
        //   });
      }
      if (this.copyCourseFrom == 'myinstitute') {
        this.getUserGroups();
      }
    },
    async getTemplateUsergroups() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usergroups/templates?usersubgroup_to=${this.to_subgroup_id}`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.templateGroupSubgroupCourses = response.data;
          response.data.forEach((group) => {
            this.usergroups.push({
              value: group.id,
              text: `${group.name} (${group.code}` + (group.scheme ? `-${group.scheme} Scheme)` : ')'),
            });
          });
        })
        .catch((error) => {
          error;
        });
    },
    copyCourse() {
      this.startCopybutton = {
        text: "Copying...",
        disabled: true,
      };
      const url = this.$store.getters.getAPIKey.mainAPI + `/course/duplicate`;
      var postData = {};
      postData["copy_from"] = this.copyCourseFrom;
      postData["from_course"] = this.course_id;
      postData["from_courses"] = this.fromCourses.map(option => option.value);
      postData["create_new_course"] = true;
      postData["to_usersubgroup"] = this.to_subgroup_id;
      // postData["new_usersubgroup"] = new_usersubgroup;
      postData["course_properties"] = this.course_properties;
      this.$axios
        .post(url, postData)
        .then((response) => {
          // console.log(response);
          // response;
          this.$toast.success(`${response.data.name} is created successfully`, {
            position: "top-right",
            duration: 6000,
          });
          this.startCopybutton = {
            text: "Start Copying",
            disabled: false,
          };

          this.$emit("success");
          // this.$emit("GroupCreated", true);
        })
        .catch((error) => {
          // console.log(error);
          this.$toast.error(`Course is failed to copy, `, {
            position: "top-right",
            duration: 6000,
          });
          this.startCopybutton = {
            text: "Start Copying",
            disabled: false,
          };
          this.$toast.error(error.response.data.message, {
            position: "top-right",
            duration: 6000,
          });
        });
    },


    async getCourses() {
      this.courses = [];

      let fromSubgroupType = '';
      if (this.usersubgroup_id)
        fromSubgroupType = this.usersubgroupObjects[this.usersubgroup_id].type;
      // 
      if ( this.to_subgroup.type != 'interdepartment_courses_group' && this.to_subgroup.type != fromSubgroupType) {
        // restricted
        let errorText = fromSubgroupType == '' ?
          `Courses from this ${this.subgroupCustomName} can only be copied to a non 'interdepartmental Course group'. this will helps to easily manage 'Minor', 'Open elective' courses` :
          `Courses from this ${this.subgroupCustomName} can only be copied to an 'interdepartmental Course group'. this will helps to easily manage 'Minor', 'Open elective' courses`;
        this.$toast.error(
          errorText,
          { position: "top", duration: 6000 }
        );
        this.usersubgroup_id = null;
        this.usergroup_id = null;
        return;
      }
      if (this.copyCourseFrom == 'myinstitute') {
        const url =
          this.$store.getters.getAPIKey.mainAPI +
          `/usersubgroups/${this.usersubgroup_id}/courses`;
        await this.$axios
          .get(url)
          .then((response) => {
            response.data.forEach((course) => {
              this.courses.push({
                value: course.id,
                text: `${course.name} (${course.code})`,
              });
            });
          })
          .catch((error) => {
            error;
          });
      }
      else {
        this.templateGroupSubgroupCourses.forEach((group) => {
          if (group.id == this.usergroup_id) {
            group.usersubgroups.forEach((subgroup) => {
              if (subgroup.id == this.usersubgroup_id) {
                subgroup.courses.forEach((course) => {
                  this.courses.push({
                    value: course.id,
                    text: `${course.name} (${course.code})`,
                  });

                });
              }
            });
          }
        });
      }
    },

    async getSubgroups() {
      this.usersubgroups = [];
      // console.log('dfvdfvd');
      if (this.copyCourseFrom == 'myinstitute') {
        // console.log('dfvdfvdmyinstitute');
        const url =
          this.$store.getters.getAPIKey.mainAPI +
          `/usergroups/${this.usergroup_id}/usersubgroups`;
        await this.$axios
          .get(url)
          .then((response) => {
            response.data.forEach((subgroup) => {
              this.usersubgroupObjects[subgroup.id] = subgroup;
              this.usersubgroups.push({
                value: subgroup.id,
                text: `${subgroup.name} (${subgroup.code})`,
              });
            });
          })
          .catch((error) => {
            error;
          });
      } else {
        this.templateGroupSubgroupCourses.forEach((group) => {
          if (group.id == this.usergroup_id) {
            group.usersubgroups.forEach((subgroup) => {
              console.log(subgroup);
              this.usersubgroupObjects[subgroup.id] = subgroup;
              this.usersubgroups.push({
                value: subgroup.id,
                text: `${subgroup.name} (${subgroup.code})`,
              });
            });
          }
        });
      }
    },

    async getSubgroup() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usersubgroups/${this.to_subgroup_id}`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.to_subgroup = response.data;
        })
        .catch((error) => {
          error;
        });
    },

    async getUserGroups() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usergroups?allusergroups=1`;
      await this.$axios
        .get(url)
        .then((response) => {
          response.data.forEach((group) => {
            this.usergroups.push({
              value: group.id,
              text: `${group.name} (${group.code}` + (group.scheme ? `-${group.scheme} Scheme)` : ')'),
            });
          });
        })
        .catch((error) => {
          error;
        });
    },
    cancel() {
      this.$emit("cancel");
    },

  },
};
</script>

<style >
.coursesOptions .list-group .list-group-item {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
  cursor: pointer;
  background-color: #007bff;
  color: #ffffff;
  border-radius: 10px;
}

.coursesOptions .list-group .list-group-item.active {
  color: #007bff;
  background-color: #ffffff;
}
</style>
