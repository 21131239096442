<template>
  <div>
    <div class="courseOutcome">
      <div class="deleteLessons mb-2" v-if="deleteOutcomesIds.length != 0 && !deleteOutcome">
        <b-button variant="danger" @click="$bvModal.show('courseOutcome')" size="sm">{{
            deleteOutcomesIds.length > 1
              ? "Remove Course Outcomes"
              : "Remove Course Outcome"
        }}</b-button>
      </div>
      <b-modal id="courseOutcome" no-close-on-backdrop no-close-on-keyboard no-close-on-esc hide-footer hide-header
        centered size="md">
        <h6 style="font-size: 18px; font-weight: 600" class="text-danger">
          Deleting Course Outcome will remove the CO from Lesson Plan &
          CO-PO/PSO. Are you Sure you want to proceed?
        </h6>
        <div class="buttons">
          <b-button size="sm" variant="primary" @click="prepareOutcomesDeletion()">Proceed</b-button>
          <b-button size="sm" variant="danger" class="ml-2"
            @click="deleteOutcomesIds = []; $bvModal.hide('courseOutcome')">Cancel</b-button>
        </div>
      </b-modal>
      <div v-if="!outcomeCreation">
        <div class="p-2 mb-2 d-flex justify-content-between" style="
            border: 2px solid #eeeeeb;
            border-left-style: none;
            border-right-style: none;
          " v-if="$store.getters.defaultInstitute.userRole != 'Student'">
          <div class="d-flex align-items-center">
            <b-button variant="info" class="text-white" size="sm" @click="outcomeCreation = !outcomeCreation"><i
                class="fas fa-plus-square text-white mr-1"></i>Add Course Outcome</b-button>

            <b-form-checkbox size="md" v-model="selectAllOutcomes" v-if="editCourseComponents" class="ml-3"
              @change="selectAllOutcomesForDeletion()">{{
                  selectAllOutcomes ? "Unselect All" : "Select All"
              }}</b-form-checkbox>

            <!-- <b-button variant="info" size="sm" class="text-white ml-3" @click="goToExcel('CourseOutcomeCSV')">Import
              Excel Sheet<i class="fas fa-file-excel ml-2"></i></b-button> -->
            <span class="text-info btn p-0 m-0 ml-3" @click="getCourseOutcomes()">
              <i class="fas fa-sync-alt fa-2x"></i>
            </span>
          </div>
          <div class="download">
            <b-button variant="danger" :disabled="reportBtn.disabled" size="sm" @click="downloadCOReportPDF()">{{
                reportBtn.text
            }}</b-button>
          </div>
        </div>
      </div>
      <div class="loading" v-if="fetchData">
        <load-data></load-data>
      </div>
      <div v-else>
        <div class="table-responsive" v-if="!outcomeCreation">
          <b-overlay :show="deleteOutcome" spinner-variant="primary" spinner-type="grow" spinner-small rounded="md">
            <!-- <div v-if="inventoryData.length > 0 && $store.getters.defaultInstitute.userRole != 'Student'"
              class=" shadow  mx-1 my-2">
              <b-alert show variant="info">
                <h4 class="alert-heading alert-link" style="width: fit-content; font-size: 1.5rem !important;">
                  Automatically add
                  Course Outcomes from ezygo suggestion!</h4>
                <ul>
                  <li> {{ inventoryData[0].code }}-{{ inventoryData[0].outcome }} ... </li>
                  <li v-if="inventoryData.length > 1"> {{ inventoryData[1].code }}-{{ inventoryData[1].outcome }} ...
                  </li>
                  <li v-else> ...</li>
                </ul>
                <hr>
                <p class="mb-0">
                  Click to automatically add Course Outcomes <b-button @click="importCourseOutcomesInventory()"
                    class="shadow ml-3" pill size="sm" variant="success">
                    Proceed
                  </b-button>
                </p>
              </b-alert>
            </div> -->

            <div class="mb-2" v-if="!editCourseComponents">This data can only be edited by course instructor</div>
            <table class="table table-striped table-bordered" style="font-size: 14px; font-weight: 600">
              <thead class="thead-dark text-left">
                <tr>
                  <th v-if="editCourseComponents"></th>
                  <th>Si No</th>
                  <th>Course Outcome</th>
                  <th>Description</th>
                  <th>Cognitive levels</th>
                  <th v-if="editCourseComponents"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="outcomes && outcomes[courseId].length == 0">
                  <td colspan="100" class="text-danger text-center">
                    <span>No Course Outcomes Created Yet!</span>
                  </td>
                </tr>
                <tr v-else v-for="(cOutcome, outcomeIndex) in outcomes[courseId]" :key="outcomeIndex">
                  <td v-if="editCourseComponents">
                    <b-form-checkbox v-model="deleteOutcomesIds" :value="cOutcome.id"></b-form-checkbox>
                  </td>
                  <td>
                    {{ cOutcome.si_no }}
                  </td>
                  <td>
                    {{ cOutcome.code }}
                  </td>
                  <td>{{ cOutcome.outcome }}</td>
                  <td> <span v-for="level in cOutcome.cognitive_levels" :key="level">{{ level }},</span> </td>
                  <td v-if="editCourseComponents">
                    <span class="btn m-0 p-0 text-info col-md-0" @click="
                      getCourseOutcome(
                        cOutcome.id,
                        cOutcome.si_no,
                        cOutcome.code,
                        cOutcome.outcome,
                        cOutcome.cognitive_levels,
                      )
                    "><i class="far fa-edit"></i></span> &nbsp; &nbsp;&nbsp;&nbsp;
                    <span class="text-danger" @click="deleteOutcomesIds = [cOutcome.id]; $bvModal.show('courseOutcome')"
                      size="sm">
                      <i class="fas fa-trash"></i></span>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-overlay>
        </div>
      </div>
    </div>
    <div class="createCourseOutcome" v-if="outcomeCreation">
      <createOutcome :courseId="courseId" :currentCourseOutcome="currentCourseOutcome" @cancel="cancel()"
        @getCourseOutcomes="getCourseOutcomes()" v-on:goto-excel="goToExcel('CourseOutcomeCSV')"></createOutcome>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import OutcomeCreation from "./OutcomeCreation.vue";
export default {
  props: ["courseId", "editCourseComponents"],
  async created() {
    await this.getCourseOutcomes();
  },
  components: {
    createOutcome: OutcomeCreation,
  },
  data() {
    return {
      fetchData: false,
      outcomes: {},
      deleteOutcomesIds: [],
      selectAllOutcomes: false,
      outcomeCreation: false,
      currentCourseOutcome: {},
      deleteOutcome: false,
      reportBtn: {
        text: "Download PDF",
        disabled: false,
      },
      // inventoryData: [],
    };
  },
  methods: {
    goToExcel(path) {
      this.outcomeCreation = false;
      let routeData = this.$router.resolve({
        name: path,
        params: {
          course_id: this.courseId,
        },
      });
      window.open(routeData.href, "_blank");
    },
    async selectAllOutcomesForDeletion() {
      if (this.selectAllOutcomes) {
        this.outcomes[this.courseId].forEach((outcome) => {
          this.deleteOutcomesIds.push(outcome.id);
        });
      } else {
        this.deleteOutcomesIds = [];
      }
    },
    async downloadCOReportPDF() {
      this.reportBtn = {
        text: "Downloading....",
        disabled: true,
      };

      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/courseoutcomes/report/pdfexport`;
      await this.$axios
        .post(url, { course: this.courseId }, { responseType: "blob" })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "courseOutcomeReport.pdf");
          document.body.appendChild(link);
          link.click();
          link.remove();

          this.reportBtn = {
            text: "Download PDF",
            disabled: false,
          };
          // console.log(response);
        })
        .catch(() => {
          this.reportBtn = {
            text: "Download PDF",
            disabled: false,
          };

          // console.log(error.response);
        });
    },
    async getCourseOutcomes() {
      this.fetchData = true;
      let courseOutcomeUrl = this.$store.getters.getAPIKey.getCourseOutcomes;
      const url = courseOutcomeUrl.replace("course_id", this.courseId);
      await axios
        .get(url)
        .then((response) => {
          this.outcomes = {};
          this.outcomes[this.courseId] = response.data;
          this.fetchData = false;
          // if (response.data.length < 4 && this.$store.getters.defaultInstitute.userRole != 'Student') this.getCourseOutcomesInventorySuggestions();
          // console.log(response);
        })
        .catch(() => {
          // console.log(error.response);
        });
    },

    // getCourseOutcomesInventorySuggestions() {
    //   const url =
    //     this.$store.getters.getAPIKey.mainAPI + `/inventory/courses/${this.courseId}/suggestions?inventorytype=courseoutcomes`;
    //   axios
    //     .get(url)
    //     .then((response) => {
    //       this.inventoryData = response.data.courseoutcomes;
    //     });
    // },
    // importCourseOutcomesInventory() {
    //   this.fetchData = true;
    //   const url =
    //     this.$store.getters.getAPIKey.mainAPI + `/inventory/courses/${this.courseId}/import?inventorytype=courseoutcomes`;
    //   axios
    //     .get(url)
    //     .then(() => {
    //       this.getCourseOutcomes();
    //     });
    // },
    getCourseOutcome(id, si_no, name, description, cognitive_levels) {
      (this.currentCourseOutcome.id = id),
        (this.currentCourseOutcome.code = name),
        (this.currentCourseOutcome.si_no = si_no),
        (this.currentCourseOutcome.outcome = description);
      (this.currentCourseOutcome.cognitive_levels = cognitive_levels);
      this.outcomeCreation = true;
    },
    cancel() {
      this.currentCourseOutcome = {};
      this.outcomeCreation = false;
    },
    async prepareOutcomesDeletion() {
      this.$bvModal.hide("courseOutcome");
      this.deleteOutcome = true;
      for (let outcome in this.deleteOutcomesIds) {
        let outcomeDeleted = await this.deleteProgramOutcome(
          this.deleteOutcomesIds[outcome]
        );
        if (outcomeDeleted) {
          if (outcome == this.deleteOutcomesIds.length - 1) {
            this.$toast.success("Deleted Successfully", {
              position: "top",
              duration: 3000,
            });
            this.deleteOutcome = false;
            this.deleteOutcomesIds = [];
            this.getCourseOutcomes();
          }
        }
        if (!outcomeDeleted) {
          this.$toast.error("Something went wrong", {
            position: "top",
            duration: 3000,
          });
          return (this.deleteOutcome = false);
        }
      }
    },
    async deleteProgramOutcome(outcomeId) {
      let status = null;
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/courseoutcomes/${outcomeId}`;
      await this.$axios
        .delete(url)
        .then(() => {
          status = true;
        })
        .catch(() => {
          status = false;
          // console.log(error);
        });
      return status;
    },
  },
};
</script>
<style >
.blink {
  animation: blink 2s infinite;
}

@keyframes blink {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1.03);
  }

  50% {
    transform: scale(1.02);
  }

  75% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}
</style>