<template>
  <div>
    <div class="approversListing">
      <div class="loading" v-if="fetchData">
        <load-data></load-data>
      </div>
      <b-container v-else>
        <b-row>
          <b-col md="12">
            <b-button
              class="mb-2"
              :variant="approverForm ? 'danger' : 'primary'"
              size="sm"
              @click="approverForm = !approverForm"
              >{{ approverForm ? "Close" : "Add Approver" }}</b-button
            >
            <div class="approverForm" v-if="approverForm">
              <CreateApprovers
                @cancelled="emitResponseFromApproverCreation"
                :approverInfo="approverInfo"
                :registrationId="registrationId"
                :approvers="approvers"
              />
            </div>
            <div class="table-responsive">
              <b-overlay
                :show="loadApprovers"
                spinner-variant="primary"
                spinner-type="grow"
                spinner-small
                rounded="md"
              >
                <table class="table table-bordered table-striped">
                  <thead class="thead-dark">
                    <tr>
                      <th>Sr No.</th>
                      <th>Name</th>
                      <th>Role</th>
                      <th>Order</th>
                      <th>Edit</th>
                      <th>Remove</th>
                      <th>Special Privilege</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="approvers.length == 0">
                      <td class="align-middle text-center text-danger" colspan="100">
                        No Approvers Set!
                      </td>
                    </tr>
                    <tr
                      v-else
                      v-for="(approver, approverIndex) in approvers"
                      :key="approverIndex"
                    >
                      <td class="align-middle">{{ approverIndex + 1 }}</td>

                      <td class="align-middle">
                        {{ approver.name }}
                      </td>
                      <td class="align-middle">{{ approver.role }}</td>
                      <td class="align-middle">{{ approver.order }}</td>
                      <td class="align-middle">
                        <span
                          v-if="!approverForm"
                          class="text-info btn m-0 p-0"
                          @click="
                            getApprover(
                              approver.id,
                              approver.name,
                              approver.institution_user_id,
                              approver.role,
                              approver.order,
                              approver.approve_all_same_order
                            )
                          "
                          ><i class="far fa-edit"></i
                        ></span>
                      </td>
                      <td class="align-middle">
                        <span
                          v-if="!approverForm"
                          class="btn p-0 m-0 text-danger"
                          @click="removeApprover(approver.id, approverIndex)"
                        >
                          <i class="fas fa-trash"></i>
                        </span>
                      </td>
                      <td class="align-middle">
                        <b-form-checkbox
                          v-if="!approverForm"
                          @change="
                            updateSpecialPrivilege(
                              approver.id,
                              approver.approve_all_same_order,
                              approver.institution_user_id,
                              approver.role
                            )
                          "
                          v-model="approver.approve_all_same_order"
                        ></b-form-checkbox>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-overlay>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import CreateApprovers from "./CreateApprovers.vue";
export default {
  props: ["registrationId"],
  async created() {
    await this.getApprovers();
  },
  components: {
    CreateApprovers,
  },
  data() {
    return {
      fetchData: false,
      approvers: [],
      approverForm: false,
      loadApprovers: false,
      approverInfo: {},
    };
  },
  methods: {
    async getApprovers() {
      await this.registrationId;
      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.surveyApprovals +
        `/${this.registrationId}/onlineregapprovers`;
      this.$axios
        .get(url)
        .then((response) => {
          this.approvers = [];
          let approvers = response.data;
          approvers.forEach((approver) => {
            this.approvers.push({
              id: approver.id,
              role: approver.role,
              order: approver.order,
              approve_all_same_order: approver.approve_all_same_order == 1 ? true : false,
              name:
                approver.institution_user.first_name +
                " " +
                approver.institution_user.last_name,
              institution_user_id: approver.institution_user.id,
            });
          });
          this.approvers.sort(function (a, b) {
            return a.order - b.order || a.name.localeCompare(b.name);
          });
          this.approverForm = false;
          this.fetchData = false;
        })
        .catch(() => {
          this.fetchData = false;
        });
    },
    async getApprover(
      id,
      name,
      institution_user_id,
      role,
      order,
      approve_all_same_order
    ) {
      this.approverInfo.id = await id;
      this.approverInfo.approver = await {
        name: name,
        value: institution_user_id,
        role: role,
        order: order,
        approve_all_same_order: approve_all_same_order == 1 ? true : false,
      };

      this.approverForm = true;
    },
    async removeApprover(approverId, approverIndex) {
      this.loadApprovers = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/onlineregapprovers/${approverId}`;
      this.$axios
        .delete(url)
        .then(() => {
          this.$toast.success(`Approver Removed`, {
            position: "top",
            duration: 3000,
          });
          this.approvers.splice(approverIndex, 1);
          this.loadApprovers = false;
          this.getApprovers();
        })
        .catch(() => {
          this.$toast.error(`Something went wrong!`, {
            position: "top",
            duration: 3000,
          });
          this.loadApprovers = false;
        });
    },
    async updateSpecialPrivilege(id, status, institution_user_id, role) {
      // this.loadApprovers = true;
      // console.log(status);
      // let privelege = status == true ? false : true;
      // console.log(privelege);
      let postData = await {
        approve_all_same_order: status,
        institution_user_id: institution_user_id,
        role: role,
      };
      const url = this.$store.getters.getAPIKey.mainAPI + `/onlineregapprovers/${id}`;
      await this.$axios
        .put(url, postData)
        .then((response) => {
          this.getApprovers();
          response;
        })
        .catch((error) => {
          error;
        });
      this.loadApprovers = false;
    },
    emitResponseFromApproverCreation() {
      this.getApprovers();
      this.approverInfo = {};
    },
  },
};
</script>
