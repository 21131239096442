<template>
  <div>
    <b-container>
      <div class="loading" v-if="fetchData">
        <load-data></load-data>
      </div>
      <b-row v-else>
        <b-col md="12">
          <div class="createLiveClass" v-if="createLiveClass">
            <create-live-class
              :coursesOptions="coursesOptions"
              :liveClassUpdate="liveClassUpdate"
              :coursesObject="coursesObject"
              v-if="googleAuthenticated && instructorAccess"
              @cancel="cancelLiveClass()"
              @liveClassCreated="getGoogleEvents()"
            ></create-live-class>
          </div>
          <div class="listingLiveClasses" v-else>
            <div class="upcomingsOrPast">
              <b-button
                :variant="filterEventsBtn.upcomings"
                @click="getUpcomingEvents()"
                size="sm"
                pill
                ><i
                  class="far fa-check-circle"
                  v-if="filterEventsBtn.upcomings == 'info'"
                ></i
                >Today & Upcoming</b-button
              >
              <b-button
                :variant="filterEventsBtn.past"
                @click="getPastEvents()"
                size="sm"
                pill
                class="ml-4"
                ><i
                  class="far fa-check-circle"
                  v-if="filterEventsBtn.past == 'info'"
                ></i
                >Past</b-button
              >
            </div>
            <br />

            <div
              class="p-2 d-flex justify-content-between"
              style="
                border: 2px solid #eeeeeb;
                border-left-style: none;
                border-right-style: none;
              "
            >
              <div>
                <b-button
                  variant="info"
                  size="sm"
                  @click="createLiveClass = true"
                  v-if="instructorAccess"
                  :disabled="!googleAuthenticated"
                  style="font-size: 12px"
                  ><i class="fas fa-plus-square"></i>Add</b-button
                >
                <b-button
                  variant="info"
                  class="ml-3"
                  size="sm"
                  @click="getGoogleEvents()"
                >
                  <i class="fas fa-sync-alt text-white"></i>Refresh
                </b-button>
              </div>
              <div class="d-flex">
                <link-google
                  @unlinkGoogle="getAuthenticationDetails()"
                  @linkedSuccessfully="getAuthenticationDetails()"
                  v-if="instructorAccess"
                ></link-google>
                <!-- <b-button
                  class="ml-3"
                  @click="openYoutubeLinks('LiveClass')"
                  style="background-color: #ff0000; border: none"
                  size="sm"
                  v-if="$store.getters.defaultInstitute.userRole != 'Student'"
                >
                  <i class="fab fa-youtube"></i>
                  Video Tutorial
                </b-button> -->
              </div>
            </div>
            <div class="notAuthenticated" v-if="!googleAuthenticated">
              <h5 class="text-danger">
                Your google meet account is not linked with ezygo.app, please
                link your google account from above.
              </h5>
            </div>

            <div class="mt-4 p-3" v-if="googleEvents.length != 0">
              <b-overlay
                :show="loadData"
                spinner-variant="primary"
                spinner-type="grow"
                spinner-small
                rounded="md"
              >
                <div class="table-responsive rounded">
                  <table
                    class="table table-striped table-bordered text-center"
                    style="font-size: 14px; font-weight: 600"
                  >
                    <thead class="thead-dark">
                      <tr>
                        <th>Course Name</th>
                        <th>Course Code</th>
                        <th>Date</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                        <th>Join Link</th>
                        <th v-if="googleAuthenticated && instructorAccess"></th>
                        <th v-if="instructorAccess">Report</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="filteredEvents.length == 0">
                        <td
                          colspan="100"
                          class="text-danger font-weight-bolder"
                        >
                          No Upcoming Events!
                        </td>
                      </tr>
                      <tr
                        v-for="(event, eventIndex) in filteredEvents"
                        :key="eventIndex"
                        v-else
                      >
                        <td class="align-middle">
                          <span class="text-info" :id="'event' + eventIndex">
                            {{ courseCodes[event.course_id].name }}
                          </span>
                          <b-popover
                            :target="'event' + eventIndex"
                            triggers="hover"
                            placement="right"
                          >
                            <p>{{ event.summary }}</p>
                          </b-popover>
                        </td>

                        <td class="align-middle">
                          {{ courseCodes[event.course_id].code }}
                        </td>
                        <td class="align-middle">
                          {{ event.start_datetime.format("DD/MM/YYYY") }}
                        </td>
                        <td class="align-middle">
                          {{ event.start_datetime.format("hh:mm A") }}
                        </td>
                        <td class="align-middle">
                          {{ event.end_datetime.format("hh:mm A") }}
                        </td>
                        <td class="align-middle">
                          <span
                            v-if="event.status == 'cancelled'"
                            class="text-danger"
                            style="font-size: 14px; font-weight: 600; text-align: center"
                            >Cancelled</span
                          >
                          <b-button
                            v-else
                            id="joinNow"
                            @click="joinNow(event.id, event.event_url)"
                            >Join Now</b-button
                          >
                        </td>
                        <td
                          class="align-middle"
                          v-if="googleAuthenticated && instructorAccess"
                        >
                          <span
                            class="text-info btn m-0 p-0"
                            @click="getGoogleEvent(event.id)"
                            v-if="event.status != 'cancelled'"
                            ><i class="far fa-edit"></i
                          ></span>
                        </td>
                        <td class="align-middle" v-if="instructorAccess">
                          <span
                            class="btn p-0 m-0 text-info"
                            @click="
                              getLiveClassParticipants(
                                event.id,
                                'liveClassParticipants' + eventIndex
                              )
                            "
                            ><i class="fas fa-file-alt"></i
                          ></span>
                          <b-modal
                            :id="'liveClassParticipants' + eventIndex"
                            no-close-on-backdrop
                            no-close-on-keyboard
                            no-close-on-esc
                            hide-footer
                            centered
                            size="lg"
                          >
                            <div class="loading" v-if="loadStudents">
                              <load-data></load-data>
                            </div>
                            <table
                              class="table table-bordered table-striped"
                              v-else
                            >
                              <thead class="thead-dark">
                                <th>Roll No.</th>
                                <th>Name</th>
                                <th>Start Time</th>
                              </thead>
                              <tbody>
                                <tr v-if="liveClassParticipants.length == 0">
                                  <td
                                    colspan="100"
                                    class="align-middle text-danger text-center"
                                  >
                                    No Data Found!
                                  </td>
                                </tr>
                                <tr
                                  v-for="(student,
                                  studentIndex) in liveClassParticipants"
                                  :key="studentIndex"
                                  v-else
                                >
                                  <td class="text-center align-middle">
                                    {{ student.roll_number }}
                                  </td>
                                  <td
                                    class="text-center align-middle text-capitalize"
                                  >
                                    {{ student.first_name }}
                                    {{ student.last_name || "" }}
                                  </td>
                                  <td class="text-center align-middle">
                                    {{
                                      student.start_datetime.format(
                                        "DD/MM/YYYY"
                                      )
                                    }}
                                    <br />
                                    {{
                                      student.start_datetime.format("HH:mm a")
                                    }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </b-modal>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-overlay>
            </div>
            <h5 class="text-danger text-center" v-else>
              No Events Created Yet!
            </h5>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import CreateLiveClasses from "./CreateLiveClasses.vue";
import LinkGoogleWithEzygo from "./LinkGoogleWithEzygo.vue";
import moment from "moment";
moment.suppressDeprecationWarnings = true;
import acl from "../../../../authorization/acl.js";
export default {
  async created() {
    this.fetchData = true;
    const instructorLiveClass = await acl.isUserGranted("instructorLiveClass");
    this.instructorAccess = instructorLiveClass;
    const studentLiveClass = await acl.isUserGranted("studentLiveClass");
    this.studentLiveClass = studentLiveClass;
    await this.getAuthenticationDetails();
  },
  components: {
    createLiveClass: CreateLiveClasses,
    "link-google": LinkGoogleWithEzygo,
  },
  data() {
    return {
      fetchData: false,
      loadStudents: false,
      loadData: false,
      today: moment(),
      createLiveClass: false,
      courses: [],
      coursesOptions: [],
      googleEvents: [],
      courseCodes: {},
      googleAuthenticated: false,
      instructorAccess: null,
      studentLiveClass: null,
      searchBy: "",
      filterEventsBtn: {
        upcomings: "outline-primary",
        past: "outline-primary",
        upcomingEvents: false,
        pastEvents: false,
      },
      liveClassUpdate: {},
      liveClassParticipants: [],
      coursesObject: {},
    };
  },
  computed: {
    filteredEvents() {
      if (
        this.filterEventsBtn.upcomingEvents &&
        !this.filterEventsBtn.pastEvents
      ) {
        return this.googleEvents.filter((event) => {
          const eventStartDate = event.start_datetime;
          return (
            this.today.format("YYYY/MM/DD") ==
              eventStartDate.format("YYYY/MM/DD") ||
            this.today.format("YYYY/MM/DD") <
              eventStartDate.format("YYYY/MM/DD")
          );
        });
      } else if (
        this.filterEventsBtn.pastEvents &&
        !this.filterEventsBtn.upcomingEvents
      ) {
        return this.googleEvents.filter((event) => {
          const eventStartDate = event.start_datetime;
          const eventEndDate = event.end_datetime;
          return (
            this.today.format("YYYY/MM/DD") >
              eventStartDate.format("YYYY/MM/DD") &&
            this.today.format("YYYY/MM/DD") > eventEndDate.format("YYYY/MM/DD")
          );
        });
      } else if (
        this.filterEventsBtn.upcomingEvents &&
        this.filterEventsBtn.pastEvents
      ) {
        return this.googleEvents;
      } else {
        return this.googleEvents;
      }
    },
  },
  methods: {
    getLiveClassParticipants(eventId, modalName) {
      this.loadStudents = true;
      this.$bvModal.show(`${modalName}`);
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/googleevents/${eventId}/reports`;
      this.$axios
        .get(url)
        .then((response) => {
          this.liveClassParticipants = response.data.participants;
          this.liveClassParticipants.forEach((participant) => {
            let startT = moment(participant.start_datetime);
            participant.start_datetime = startT;
          });
          this.loadStudents = false;
          response;
        })
        .catch((error) => {
          this.loadStudents = false;
          error;
        });
    },

    cancelLiveClass() {
      this.createLiveClass = false;
      this.liveClassUpdate = {};
    },
    async joinNow(eventId, eventURL) {
      this.loadData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/googleevents/${eventId}/join`;
      await this.$axios
        .get(url)
        .then((response) => {
          window.open(eventURL, "_blank");
          response;
        })
        .catch((error) => {
          error;
        });
      this.loadData = false;
    },
    async getUpcomingEvents() {
      if (
        this.filterEventsBtn.upcomings == "info" &&
        !this.filterEventsBtn.pastEvents
      ) {
        return;
      }
      if (
        this.filterEventsBtn.upcomings == "info" &&
        this.filterEventsBtn.upcomingEvents
      ) {
        this.filterEventsBtn.upcomings = "outline-primary";
        this.filterEventsBtn.upcomingEvents = false;
        return;
      }

      this.filterEventsBtn.upcomings = "info";
      this.filterEventsBtn.upcomingEvents = true;
    },

    async getPastEvents() {
      if (
        this.filterEventsBtn.past == "info" &&
        !this.filterEventsBtn.upcomingEvents
      ) {
        return;
      }
      if (
        this.filterEventsBtn.past == "info" &&
        this.filterEventsBtn.pastEvents
      ) {
        this.filterEventsBtn.past = "outline-primary";
        this.filterEventsBtn.pastEvents = false;
        return;
      }
      this.filterEventsBtn.past = "info";
      this.filterEventsBtn.pastEvents = true;
    },
    getGoogleEvents() {
      this.fetchData = true;
      const url = this.$store.getters.getAPIKey.googleEvent;
      axios
        .get(url)
        .then((response) => {
          this.googleEvents = [];
          let events = response.data;

          events.forEach((event) => {
            this.googleEvents.push({
              id: event.id,
              summary: event.summary,
              description: event.description,
              start_datetime: moment(new Date(event.start_datetime)),
              end_datetime: moment(new Date(event.end_datetime)),
              course_id: event.course_id,
              event_url: event.event_url,
              status: event.status,
            });
          });
          this.getUpcomingEvents();
          this.fetchData = false;
          response;
        })
        .catch((error) => {
          error;
        });
    },
    async getGoogleEvent(eventId) {
      this.loadData = true;
      const url = this.$store.getters.getAPIKey.googleEvent + `/${eventId}`;
      await axios
        .get(url)
        .then((response) => {
          response;
          this.liveClassUpdate = response.data;
          this.loadData = false;
          this.createLiveClass = true;
        })
        .catch((error) => {
          this.loadData = false;
          error;
        });
    },
    getCourseDetails() {
      //   this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/institutionuser/courses/withusers`;
      axios
        .get(url)
        .then((response) => {
          this.courses = [];
          this.coursesOptions = [
            { value: null, text: "Select Course", disabled: true },
          ];
          this.courses = response.data;
          this.courses.forEach((course) => {
            this.$set(this.coursesObject, course.id, {
              value: course.id,
              text:
                course.name +
                " (" +
                course.usersubgroup.usergroup.name +
                " - " +
                course.usersubgroup.name +
                ")",
            });
            this.coursesOptions.push({
              value: course.id,
              text:
                course.name +
                " (" +
                course.usersubgroup.usergroup.name +
                " - " +
                course.usersubgroup.name +
                ")",
            });
            this.courseCodes[course.id] = {};
            this.courseCodes[course.id] = {
              code: `${course.code}-${course.usersubgroup.code}-${course.usersubgroup.usergroup.code}`,
              name: course.name,
            };
          });
          this.getGoogleEvents();

          response;
        })
        .catch((error) => {
          error;
        });
    },

    getAuthenticationDetails() {
      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/user/google/oauth2detailes`;
      axios
        .get(url)
        .then((response) => {
          if (response.data != "" || this.studentLiveClass) {
            this.googleAuthenticated = true;
          } else {
            this.googleAuthenticated = false;
          }
          this.getCourseDetails();
          response;
        })
        .catch((error) => {
          error;
        });
    },
  },
};
</script>

<style >
.listingLiveClasses i {
  font-size: 12px !important;
  margin-right: 4px;
}
#joinNow {
  background-color: #00796b;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.25px;
  padding: 7px !important;
  border-radius: 15px;
}
</style>
