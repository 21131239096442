<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="surveyUsers border border-info rounded p-2" v-else>
      <div class="d-flex align-items-baseline justify-content-center">
        <b-button class="ml-2" variant="danger" size="sm" v-if="selectedUsers.length != 0" @click="clearAll()">Clear
          All</b-button>
      </div>
      <br />

      <div>
        <div class="row">
          <div class="col" style="height: 200px; overflow-y: scroll">
            <div>
              <p class="font-weight-bolder text-dark">Institution Users</p>
              <b-form-checkbox size="sm" v-model="allUsers.selected" :disabled="allUsers.disabled"
                @change="selectAllUsers()">All Institution Users</b-form-checkbox>
            </div>
            <br />
            <div>
              <p class="font-weight-bolder text-dark">
                Departments
              </p>

              <b-form-checkbox-group size="sm" v-model="selectedMainGroup" :options="groupOptions" stacked
                @change="getEnrolledGroupUsers()"></b-form-checkbox-group>
            </div>
            <br />
            <div>
              <p class="font-weight-bolder text-dark">
                {{ getSubgroup }}({{ getSubgroup }} Name - {{ getGroup }} Name -
                {{ getSubgroup }} Code)
              </p>
              <b-form-checkbox-group size="sm" v-model="selectedSubgroup" :options="subgroupOptions" stacked
                @change="getEnrolledSubgroupUsers()"></b-form-checkbox-group>
            </div>
          </div>
          <div class="col" style="height: 200px; overflow-y: scroll" v-if="selectedUsers.length != 0">
            <div class="selectedUsers d-flex flex-wrap">
              <p class="bg-success p-1 ml-1 text-white border-0 rounded" style="font-size: 12px"
                v-for="(user, userIndex) in selectedUsers" :key="userIndex">
                {{ user.name }}
                <span class="text-white btn p-0 m-0 ml-1" @click="selectedUsers.splice(userIndex, 1)"><i
                    class="fas fa-times-circle"></i>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  async created() {
    this.fetchData = await true;
    await this.getInstitutionUsers(); // with groups and subgroups
    await this.getGroupList();
    // this.fetchData = await false;
  },
  props: {
    value: {
      type: Array,
    },
    v: {
      type: Object,
      required: true,
    },
  },
  computed: {
    selectedUsers: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    getCustomNames() {
      return this.$store.getters.getCustomNamings;
    },
    getGroup() {
      if (this.getCustomNames.userGroup.custom_name != null) {
        return this.getCustomNames.userGroup.custom_name;
      } else {
        return "Usergroup";
      }
    },
    getSubgroup() {
      if (this.getCustomNames.userSubgroup.custom_name != null) {
        return this.getCustomNames.userSubgroup.custom_name;
      } else {
        return "Usersubgroup";
      }
    },
  },
  data() {
    return {
      fetchData: false,
      allUsers: {
        selected: false,
        disabled: false,
      },
      institutionUsers: [],
      groupUsers: {},
      subgroupUsers: {},
      groupOptions: [],
      subgroupOptions: [],
      selectedMainGroup: [],
      selectedSubgroup: [],
      selectedUsersObject: {},
      selected_users: [],
    };
  },
  methods: {
    async prepareSurveyApprovalParticipants(surveyApprovalId) {
      this.$toast.info("Syncing participants Please wait...", {
        position: "top-right",
        duration: 3000,
      });
      const participants = {
        institution_users: [],
      };
      await this.selectedUsers.forEach((user) => {
        participants.institution_users.push(user.id);
      });
      let syncParticipants = await this.syncSurveyApprovalParticipants(
        participants,
        surveyApprovalId
      );

      if (syncParticipants) {
        this.$toast.success("Participants synced successfully ", {
          position: "top",
          duration: 3000,
        });
        this.$emit("participantsSynced");
      }
      if (!syncParticipants) {
        this.$toast.error("Participants syncing failed, Please try again! ", {
          position: "top",
          duration: 3000,
        });
        this.$emit("participantsSynced");
      }
    },
    async syncSurveyApprovalParticipants(participants, surveyApprovalId) {
      let syncParticipants = null;
      const url =
        this.$store.getters.getAPIKey.surveyApprovals +
        `/${surveyApprovalId}/participants/sync`;
      await this.$axios
        .post(url, participants)
        .then((response) => {
          syncParticipants = true;
          response;
        })
        .catch((error) => {
          syncParticipants = false;
          error;
        });
      return syncParticipants;
    },
    async selectAllUsers() {
      this.allUsers.disabled = true;
      if (this.selectedUsers.length != 0) {
        this.selectedUsers = await [];
      }
      this.selectedSubgroup = await [];
      this.selectedMainGroup = await [];
      await this.groupOptions.forEach((gUser) => {
        gUser.disabled = true;
      });
      this.selectedUsersObject = await {};
      this.selected_users = [];
      await this.subgroupOptions.forEach((sUser) => {
        sUser.disabled = true;
      });

      this.selectedUsers = await this.institutionUsers;
    },
    async clearAll() {
      this.selectedUsers = [];
      this.selected_users = [];
      this.selectedUsersObject = {};
      this.allUsers.selected = false;
      this.allUsers.disabled = false;
      this.groupUsers = {};
      this.subgroupUsers = {};
      this.selectedMainGroup = [];
      this.selectedSubgroup = [];
      await this.getGroupList();
    },
    async getGroupList() {
      this.fetchData = true;


      let url = this.$store.getters.getAPIKey.mainAPI + `/maingroups`;
      await this.$axios
        .get(url)
        .then((response) => {
          let groupList = response.data;
          this.groupUsers = {};
          this.subgroupUsers = {};
          this.groupOptions = [];
          this.subgroupOptions = [];
          groupList.forEach((gUser) => {
            this.groupUsers[gUser.id] = {
              name: gUser.name,
              code: gUser.code,
              users: [],
              disabled: false,
            };
            this.groupOptions.push({
              value: gUser.id,
              text: `${gUser.name + "-" + gUser.code + "-Staff List"}`,
              disabled: false,
            });
          });
          response;
        })
        .catch((error) => {
          error;
        });

      url = this.$store.getters.getAPIKey.mainAPI + `/usersubgroups`;
      await this.$axios
        .get(url)
        .then((response) => {
          let groupList = response.data;
          groupList.forEach((gUser) => {
            this.subgroupUsers[gUser.id] = {
              name: gUser.name,
              code: gUser.code,
              users: [],
              disabled: false,
            };
            this.subgroupOptions.push({
              value: gUser.id,
              text: `${gUser.name +
                "-" +
                gUser.name +
                "-" +
                gUser.code +
                "-" +
                gUser.usergroup.code +
                "-Student List"}`,
              disabled: false,
            });
          });
          this.fetchData = false;
          response;
        })
        .catch((error) => {
          this.fetchData = false;
          error;
        });
    },
    async getEnrolledGroupUsers() {
      // await this.selectedMainGroup.reverse();
      this.fetchData = true;
      const url = this.$store.getters.getAPIKey.mainAPI + `/maingroups/${this.selectedMainGroup[this.selectedMainGroup.length - 1]}/users`;
      await this.selectedMainGroup;
      await this.$axios
        .get(url)
        .then((response) => {
          let listOfUsersEnrolled = response.data;

          listOfUsersEnrolled.forEach((user) => {
            this.$set(
              this.selectedUsersObject,
              user.institution_user_id,
              {}
            );
            this.$set(
              this.selectedUsersObject[user.institution_user_id],
              "name",
              `${user.first_name + " " + (user.last_name || "")}`
            );
            this.$set(
              this.selectedUsersObject[user.institution_user_id],
              "id",
              user.institution_user_id
            );
            this.groupUsers[
              this.selectedMainGroup[this.selectedMainGroup.length - 1]
            ].users.push({
              id: user.institution_user_id,
              name: `${user.first_name + " " + (user.last_name || "")}`,
            });
          });

          if (
            this.groupUsers[this.selectedMainGroup[this.selectedMainGroup.length - 1]]
              .users.length != 0
          ) {
            this.selected_users = [];
            for (const user in this.selectedUsersObject) {
              this.selected_users.push({
                id: this.selectedUsersObject[user].id,
                name: this.selectedUsersObject[user].name,
              });
            }
            this.selectedUsers = this.selected_users;

            this.groupOptions.forEach((group) => {
              if (
                group.value == this.selectedMainGroup[this.selectedMainGroup.length - 1]
              ) {
                group.disabled = true;
              }
            });
          }
          if (
            this.groupUsers[this.selectedMainGroup[this.selectedMainGroup.length - 1]]
              .users.length == 0
          ) {
            this.$toast.warning("This usergroup has no users yet!", {
              position: "top",
              duration: 3000,
            });
            this.selectedMainGroup.splice(-1, 1);
          }
          this.fetchData = false;
          response;
        })
        .catch((error) => {
          this.fetchData = false;
          error;
        });
    },

    async getEnrolledSubgroupUsers() {
      this.fetchData = true;
      let userList = this.$store.getters.getAPIKey.getEnrollSubgroupUsersList;
      const url = userList.replace(
        "usersubgroup_id",
        this.selectedSubgroup[this.selectedSubgroup.length - 1]
      );

      await this.$axios
        .get(url)
        .then((response) => {
          let listOfUsersEnrolled = response.data;
          listOfUsersEnrolled = listOfUsersEnrolled.filter((user) => {
            return user.pivot.usersubgrouprole_id == 2;
          });
          listOfUsersEnrolled.forEach((user) => {
            this.$set(
              this.selectedUsersObject,
              user.pivot.institution_user_id,
              {}
            );
            this.$set(
              this.selectedUsersObject[user.pivot.institution_user_id],
              "name",
              `${user.first_name + " " + (user.last_name || "")}`
            );
            this.$set(
              this.selectedUsersObject[user.pivot.institution_user_id],
              "id",
              user.pivot.institution_user_id
            );
            this.subgroupUsers[
              this.selectedSubgroup[this.selectedSubgroup.length - 1]
            ].users.push({
              id: user.pivot.institution_user_id,
              name: `${user.first_name + " " + (user.last_name || "")}`,
            });
          });
          if (
            this.subgroupUsers[
              this.selectedSubgroup[this.selectedSubgroup.length - 1]
            ].users.length != 0
          ) {
            this.selected_users = [];
            for (const user in this.selectedUsersObject) {
              this.selected_users.push({
                id: this.selectedUsersObject[user].id,
                name: this.selectedUsersObject[user].name,
              });
            }
            this.selectedUsers = this.selected_users;
            this.subgroupOptions.forEach((group) => {
              if (
                group.value ==
                this.selectedSubgroup[this.selectedSubgroup.length - 1]
              ) {
                group.disabled = true;
              }
            });
          }
          if (
            this.subgroupUsers[
              this.selectedSubgroup[this.selectedSubgroup.length - 1]
            ].users.length == 0
          ) {
            this.$toast.warning("This usersubgroup has no users yet!", {
              position: "top",
              duration: 3000,
            });
            this.selectedSubgroup.splice(-1, 1);
          }
          this.fetchData = false;
          response;
        })
        .catch((error) => {
          this.fetchData = false;
          error;
        });
    },
    async getInstitutionUsers() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        "/institutionusers/withgroupsandsubgroups";
      await this.$axios
        .get(url)
        .then((response) => {
          var usersWithgroupSubgroup = response.data;
          usersWithgroupSubgroup.forEach((user) => {
            this.institutionUsers.push({
              id: user.id,
              name: `${user.first_name + " " + (user.last_name || "")}`,
            });
          });
          response;
        })
        .catch((error) => {
          error;
        });
    },
  },
};
</script>
