<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="attainmentCO" v-else>
      <b-row>
        <b-col md="12">
          <div class="viewOrConfig mb-3">
            <b-button size="sm" :variant="config ? 'success' : 'outline-primary'" class="font-weight-bolder"
              disabled>Configured</b-button>
            <b-button size="sm" disabled :variant="!config ? 'success' : 'outline-primary'"
              class="ml-4 font-weight-bolder">View Data</b-button>
          </div>

          <div class=" " v-if="config">
            <table class="table table-bordered table-striped">
              <thead class="thead-dark text-left">
                <tr>
                  <th>
                    <!-- <b-form-checkbox @change="selectAllData" v-model="selectAll">{{
                        selectAll ? "Unselect All" : "Select All"
                    }}</b-form-checkbox> -->
                    Si. No.
                  </th>
                  <th>Experiment</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(experiment, assignmentIndex) in experiments" :key="assignmentIndex">
                  <td class="align-middle">
                    <b-form-checkbox size="sm" v-model="selectedExperiments" :value="experiment.id">
                    </b-form-checkbox>
                  </td>
                  <td class="align-middle">
                    {{ experiment.name }}
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- <div class="p-1 mt-3 border-secondary border-top"></div> -->
            <!-- <div class="targetPercentage mb-2">
              <span class="font-weight-bolder">Target Percentage:</span>
              <div class="d-flex align-items-center">
                <b-form-input v-model="target_percentage" style="width: 100px; text-align: center"></b-form-input>
                %
              </div>
            </div> -->
            <!-- <div class="p-1 mt-3 border-secondary border-top"></div>
            <b-button variant="primary" class="mb-2" size="sm" @click="addValue">+ Add Level</b-button> -->
            <!-- <div class=" ">
              <table class="table table-bordered table-striped">
                <thead class="thead-dark">
                  <tr>
                    <th>Value</th>
                    <th>Comparison</th>
                    <th>Compare By</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(rubric, attainmentIndex) in rubrics" :key="attainmentIndex">
                    <td class="align-middle">
                      <b-form-input v-model.number="rubric.value" @keypress="handleNumber"></b-form-input>
                    </td>
                    <td class="align-middle">
                      <b-form-select :options="operators" v-model="rubric.comparisan"></b-form-select>
                    </td>
                    <td class="align-middle">
                      <b-form-input @keypress="handleNumber" v-model="rubric.compare_by"></b-form-input>
                    </td>
                    <td class="align-middle">
                      <span class="btn p-0 m-0 text-danger" @click="rubric.splice(attainmentIndex, 1)">
                        <i class="fas fa-minus-circle"></i>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div> -->
          </div>

          <div v-else>
            <h4>Experiments</h4>
            <div class="mt-5">
              <table class="table table-bordered table-striped">
                <thead class="thead-dark">
                  <th>Code</th>
                  <th>Experiment</th>
                  <th>COs</th>
                </thead>
                <tbody>
                  <tr v-for="(experiment, coIndex) in experiments" :key="coIndex">
                    <td class="align-middle">Exp. {{ (coIndex + 1) }}</td>
                    <td class="align-middle">{{ experiment.name }}</td>
                    <td>
                      <span v-for="(co_id, coexIndex) in experiment.co_ids" :key="coexIndex">
                        {{course_outcomes[co_id].code}},
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br>

            <h4>Continues Evaluation Scores </h4>
            <div class=" ">
              <table class="table table-bordered table-striped">
                <thead class="thead-dark">
                  <tr>
                    <th class="align-middle">Q. No.</th>
                    <th class="align-middle">Student</th>
                    <th class="align-middle" v-for="(experiment, coIndex) in experiments" :key="coIndex">Exp.
                      {{ (coIndex + 1) }}<br>
                      <span v-for="(co_id, coexIndex) in experiment.co_ids" :key="coexIndex">
                        {{course_outcomes[co_id].code}},
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(student,
                  questionIndex) in course_students" :key="questionIndex">
                    <td>{{ (questionIndex + 1) }}</td>
                    <td class="align-middle">
                      {{ student.first_name }}{{ student.last_name }}
                    </td>
                    <td class="align-middle" v-for="(experiment, coIndex) in experiments" :key="coIndex">
                      {{ continues_evaluation_scores[student.id][experiment.id]['score'] }}/
                      {{ continues_evaluation_scores[student.id][experiment.id]['max'] }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br>

            <h4>Continues Evaluation CO Scores </h4>
            <div class=" ">
              <table class="table table-bordered table-striped">
                <thead class="thead-dark">
                  <tr>
                    <th class="align-middle">Q. No.</th>
                    <th class="align-middle">Student</th>
                    <th class="align-middle" v-for="(co, coIndex) in course_outcomes" :key="coIndex">
                      {{ co.code }}<br> Score(%) <br>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(student,
                  questionIndex) in course_students" :key="questionIndex">
                    <td>{{ (questionIndex + 1) }}</td>
                    <td class="align-middle">
                      {{ student.first_name }}{{ student.last_name }}
                    </td>
                    <td class="align-middle" v-for="(co, cosIndex) in course_outcomes" :key="cosIndex">
                      {{ continues_evaluation_co_scores[student.id][co.id]['score'] }}/
                      {{ continues_evaluation_co_scores[student.id][co.id]['max'] }}
                      ({{ handleValue(continues_evaluation_co_scores[student.id][co.id]['percentage']) }})
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br>

            <h4>Internal Exam Scores </h4>
            <div class=" ">
              <table class="table table-bordered table-striped">
                <thead class="thead-dark">
                  <tr>
                    <th class="align-middle">Q. No.</th>
                    <th class="align-middle">Student</th>
                    <th class="align-middle">Score</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(student,
                  questionIndex) in course_students" :key="questionIndex">
                    <td>{{ (questionIndex + 1) }}</td>
                    <td class="align-middle">
                      {{ student.first_name }}{{ student.last_name }}
                    </td>
                    <td class="align-middle">
                      {{ internal_exam_scores[student.id]['score'] }}/
                      {{ internal_exam_scores[student.id]['max'] }}
                      ({{ handleValue(internal_exam_scores[student.id]['percentage']) }})
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br>


            <h4>Laboratory Continues Evaluation Attainment</h4>
            <div class=" ">
              <table class="table table-bordered table-striped">
                <thead class="thead-dark">
                  <tr>
                    <th>CO</th>
                    <th>Total Students</th>
                    <th>Target %</th>
                    <th>Students achieved target</th>
                    <th>% of students achieved target</th>
                    <th>Attainment level</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(co,
                  questionIndex) in course_outcomes" :key="questionIndex">
                    <td class="align-middle">
                      {{ co.code }}
                    </td>
                    <td>{{ lab_computed_co_data[co.id]['max_student_count'] }}</td>
                    <td>{{ co_target_percentages[co.id] }}</td>
                    <td>{{ lab_computed_co_data[co.id]['target_achived_students'] }}</td>
                    <td>{{ lab_computed_co_data[co.id]['target_achived_percentage'] }}</td>
                    <td>{{ lab_computed_co_data[co.id]['attainment_level'] }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br>

            <h4>Laboratory Internal Exam Attainment</h4>
            <div class=" ">
              <table class="table table-bordered table-striped">
                <thead class="thead-dark">
                  <tr>
                    <th>CO</th>
                    <th>Total Students</th>
                    <th>Target %</th>
                    <th>Students achieved target</th>
                    <th>% of students achieved target</th>
                    <th>Attainment level</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(co,
                  questionIndex) in course_outcomes" :key="questionIndex">
                    <td class="align-middle">
                      {{ co.code }}
                    </td>
                    <td>{{ exam_computed_co_data[co.id]['max_student_count'] }}</td>
                    <td>{{ co_target_percentages[co.id] }}</td>
                    <td>{{ exam_computed_co_data[co.id]['target_achived_students'] }}</td>
                    <td>{{ exam_computed_co_data[co.id]['target_achived_percentage'] }}</td>
                    <td>{{ exam_computed_co_data[co.id]['attainment_level'] }}</td>
                  </tr>
                </tbody>
              </table>
            </div>


            <h4>Laboratory Attainment</h4>
            <div class=" ">
              <table class="table table-bordered table-striped">
                <thead class="thead-dark">
                  <tr>
                    <th v-for="(co,
                    questionIndex) in course_outcomes" :key="questionIndex">{{ co.code }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td v-for="(co,
                    questionIndex) in course_outcomes" :key="questionIndex">
                      {{ avg_attainment_level[co.id] }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>


          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  props: ["courseReport"],
  async created() {
    this.fetchData = true;
    await this.getAttainmentConfigration();
    this.fetchData = false;
  },

  data() {
    return {
      fetchData: false,
      config: true,
      experiments: [],
      selectedExperiments: [],
      selectAll: true,
      // rubrics: [],
      // target_percentage: 50,
      co_target_percentages:{},

      continues_evaluation_scores: {},
      internal_exam_scores: {},
      course_outcomes: {},
      course_students: [],
      continues_evaluation_co_scores: {},
      lab_computed_co_data: {},
      exam_computed_co_data: {},
      avg_attainment_level: {},
      // co_experiment_ids: {},


      // operators: [
      //   { value: null, text: "Select Operator" },
      //   { value: "<", text: "<" },
      //   { value: ">", text: ">" },
      //   { value: "<=", text: "<=" },
      //   { value: ">=", text: ">=" },
      // ],
    };
  },
  methods: {
    handleValue(num) {
      if (isNaN(num)) {
        return "";
      } else {
        return num % 1 == 0 ? Math.floor(num) : parseFloat(num).toFixed(2);
      }
    },
    async getAttainmentConfigration() {
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/coattainment/getconfig`;
      await this.$axios
        .post(url, {
          course: this.courseReport.course_id,
          step: "laboratory",
        })
        .then((response) => {
          let selected_experiments = response.data.selected_experiments;
          this.experiments = response.data.experiments;
          this.experiments.forEach((experiment) => {
            if(selected_experiments.includes(experiment.id))this.selectedExperiments.push(experiment.id);
          });
          // const rubrics = response.data.rubrics;
          // rubrics.forEach((rubric) => {
          //   this.rubrics.push({
          //     value: rubric.value,
          //     comparisan: rubric.comparisan,
          //     compare_by: rubric.compare_by
          //   });
          // });
        });
    },
    async setAttainmentConfigration() {
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/coattainment/setconfig`;
      await this.$axios
        .post(url, {
          course: this.courseReport.course_id,
          step: "laboratory",
          experiments: this.selectedExperiments,
        })
        .then(() => { });
    },
    async getAttainmentData() {
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/coattainment/getdata`;
      await this.$axios
        .post(url, {
          course: this.courseReport.course_id,
          step: "laboratory",
        })
        .then((response) => {
          // this.surveys = response.data;
          this.continues_evaluation_scores = response.data.continues_evaluation_scores;
          this.internal_exam_scores = response.data.internal_exam_scores;
          this.experiments = response.data.experiments;
          this.course_outcomes = response.data.course_outcomes;
          this.course_students = response.data.course_students;
        });
    },
    async getAttainmentReport() {
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/coattainment/report`;
      await this.$axios
        .post(url, {
          course: this.courseReport.course_id,
          step: "laboratory",
        })
        .then((response) => {
          // this.reports = response.data;
          this.continues_evaluation_co_scores = response.data.continues_evaluation_co_scores;
          this.lab_computed_co_data = response.data.lab_computed_co_data;
          this.exam_computed_co_data = response.data.exam_computed_co_data;
          this.avg_attainment_level = response.data.avg_attainment_level;
          this.co_target_percentages = response.data.co_target_percentages;
          // this.co_experiment_ids = response.data.co_experiment_ids;
          // this.experiments = response.data.experiments;
          // this.course_outcomes = response.data.course_outcomes;
          // this.course_students = response.data.course_students;
        });
    },
    handleNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    addValue() {
      this.attainments.push({
        value: "",
        comparisan: null,
        compare_by: "",
      });
    },
  },
};
</script>
