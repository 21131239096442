<template>
  <div>
    <div class="examsWebView">
      <ExamsListing />
    </div>
  </div>
</template>

<script>
import ExamsListing from "./ExamsListing.vue";
export default {
  components: {
    ExamsListing,
  },
  data() {
    return {};
  },
};
</script>
