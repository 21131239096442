<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="submitForms" v-else>
      <div class="approvalListing p-3">
        <b-overlay
          :show="loadApproval"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="xl"
        >
          <b-modal
            id="getDetailedApprovalReportFromApprovalForms"
            no-close-on-backdrop
            no-close-on-keyboard
            no-close-on-esc
            hide-footer
            scrollable
            size="xl"
          >
            <DetailedApprovalReport :detailedReport="detailedReport" />
          </b-modal>
          <div class="table-responsive">
            <table class="table table-bordered table-striped">
              <thead class="thead-dark">
                <tr>
                  <th>Name</th>
                  <th>Start</th>
                  <th>End</th>
                  <th></th>
                  <th>Academic Year</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="surveyApprovals.length == 0">
                  <td colspan="100" class="vertical-align text-danger text-center">
                    No Forms Found!
                  </td>
                </tr>
                <tr
                  v-else
                  v-for="(approval, approvalIndex) in surveyApprovals"
                  :key="approvalIndex"
                >
                  <td class="align-middle text-center">
                    <span
                      :class="approval.summary != null ? 'text-info' : 'text-dark'"
                      :id="'approvalSummary' + approvalIndex"
                      >{{ approval.name }}</span
                    >
                    <span
                      class="text-success ml-1 approverStatus"
                      v-if="approval.approverStatus"
                      ><i class="fas fa-circle"></i
                    ></span>
                    <br />

                    <b-button
                      variant="primary"
                      size="sm"
                      @click="goTo('ApprovalForms', approval.id)"
                      >Approve Forms</b-button
                    >
                    <b-tooltip
                      :target="'approvalSummary' + approvalIndex"
                      triggers="hover"
                      v-if="approval.summary != null"
                    >
                      {{ approval.summary }}
                    </b-tooltip>
                  </td>
                  <td class="align-middle">
                    {{ approval.start_at.format("DD/MM/YYYY") }} <br />
                    {{ approval.start_at.format("hh:mm a") }}
                  </td>
                  <td class="align-middle">
                    {{ approval.end_at.format("DD/MM/YYYY") }} <br />
                    {{ approval.end_at.format("hh:mm a") }}
                  </td>

                  <td class="align-middle text-center">
                    <span
                      class="btn p-0 m-0 text-info"
                      @click="getDetailedReport(approval.id)"
                      ><i class="fas fa-file-alt"></i
                    ></span>
                  </td>
                  <td class="align-middle">
                    {{ approval.academic_year }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import DetailedApprovalReport from "../approval_reports/DetailedApprovalReport.vue";
import moment from "moment";
export default {
  async created() {
    this.fetchData = true;
    await this.getFormsForApproversOnly();
    this.fetchData = false;
  },
  components: {
    DetailedApprovalReport,
  },
  data() {
    return {
      fetchData: false,
      loadApproval: false,
      surveyApprovals: [],
      detailedReport: {},
    };
  },
  methods: {
    getDetailedReport(registrationId) {
      let routeData = this.$router.resolve({
        name: "DetailedApprovalReport",
        params: {
          approval_id: registrationId,
        },
      });
      window.open(routeData.href, "_blank");
    },
    async downloadApprovalReport(eventId, eventName) {
      this.loadApproval = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/onlineregistrations/${eventId}/report/pdfexport`;
      await this.$axios
        .get(url, { responseType: "blob" })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", eventName + ".pdf");
          document.body.appendChild(link);
          link.click();
          link.remove();

          this.loadApproval = false;
          response;
        })
        .catch((error) => {
          this.loadApproval = false;
          error;
        });
    },
    async downloadApprovalAttachments(eventId, eventName) {
      this.loadApproval = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/onlineregistrations/${eventId}/onlinereganswers/attachments/zipexport`;
      await this.$axios
        .post(url, { force_download: false }, { responseType: "blob" })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", eventName + ".zip");
          document.body.appendChild(link);
          link.click();
          link.remove();

          this.loadApproval = false;
          response;
        })
        .catch((error) => {
          this.$toast.error(
            "Something went wrong with this event, please try again later!",
            {
              position: "top",
              duration: 3000,
            }
          );
          this.loadApproval = false;
          error;
        });
    },
    async getFormsForApproversOnly() {
      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/onlineregapprover/onlineregistrations`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.surveyApprovals = [];
          const approvals = response.data;
          approvals.forEach((approval) => {
            this.surveyApprovals.push({
              id: approval.id,
              name: approval.name,
              summary: approval.summary,
              academic_year: approval.academic_year,
              start_at: moment(
                moment.utc(approval.start_at, "YYYY-MM-DD HH:mm:ss").toDate()
              ),
              end_at: moment(moment.utc(approval.end_at, "YYYY-MM-DD HH:mm:ss").toDate()),
              grouping_tag: approval.grouping_tag,
              comment_feedback: approval.comment_feedback,
              hidden: approval.hidden,
              participants_only: approval.participants_only,
              is_anonymous: approval.is_anonymous,
              approverStatus: approval.approverStatus == 1 ? true : false,
            });
          });

          this.fetchData = false;
        })
        .catch(() => {
          this.fetchData = false;
        });
    },
    goTo(pathName, approvalId) {
      let routeData = this.$router.resolve({
        name: pathName,
        params: {
          approval_id: approvalId,
        },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style >
.approvalListing .approverStatus i {
  font-size: 10px !important;
}
</style>
