<template>
  <div>
    <!-- handle usergroup creation or copy usergroup and sub component's from template institute  -->
    <!-- ask to copy or create  -->
    <!-- for copy usergroup : 
    list all programs from template institution to select one
    when choose list all usersubgroup with tick box and course list(view only)
    for interdepartmental usersubgroup show tickable courses
    Post selected usergroupId, subgroupIds, courseIds of interdep. subgroups 
    Done -->
    <div class="createModule">
      <b-form @submit.prevent="prepareGroupCreation()">

        <b-form-group>
          <h3 class="text-center font-weight-bolder text-info">
            {{ groupInfo.mainGroup ? "Edit" : "Create" }}
            {{
                getCustomNames.userGroup.custom_name != null
                  ? getCustomNames.userGroup.custom_name
                  : "Usergroup"
            }}
          </h3>
        </b-form-group>
        <b-form-group>
          <DisplayExistingAdmins v-if="groupInfo.mainGroup" @updateAdminsList="updateAdminsList"
            :groupInfo="groupInfo" />
        </b-form-group>
        <b-form-group>
          <b-form-input :placeholder="
            getCustomNames.userGroup.custom_name != null
              ? getCustomNames.userGroup.custom_name + ' Name'
              : 'Usergroup Name'
          " v-model="group.name" required></b-form-input>
        </b-form-group>
        <b-form-group>
          <b-form-input :placeholder="
            getCustomNames.userGroup.custom_name != null
              ? getCustomNames.userGroup.custom_name + ' Code'
              : 'Usergroup Code'
          " v-model="group.code" required></b-form-input>
        </b-form-group>
        <b-form-group>
          <multiselect v-model="group.institution_users" tag-placeholder="Add this as new tag" :placeholder="
            getCustomNames.userGroupAdmin.custom_name != null
              ? 'Add ' + getCustomNames.userGroupAdmin.custom_name
              : 'Add Usergroup Admin'
          " label="name" track-by="code" :options="adminsOptions" :multiple="true" :taggable="true"
            :close-on-select="false" @tag="addTag"></multiselect>
        </b-form-group>
        <b-form-group>
          <b-form-select :options="userGroupsTypesOptions" v-model="group.programme_type_id" required></b-form-select>
        </b-form-group>

        <b-form-group label="Affiliated University:">
          <b-form-select v-model="group.affiliated_university" :options="universities" placeholder="Select Affiliated University" required></b-form-select>
        </b-form-group>

        <b-form-group label="Scheme :">
          <b-form-select v-model="group.scheme" :options="['2014','2015','2016','2017','2018','2019','2020','2021','2022','2023','2024','2025','2026']" placeholder="Select Scheme"></b-form-select>
        </b-form-group>
        <b-form-group>
          <div class="d-flex justify-content-between">
            <b-button type="submit" variant="primary" :disabled="groupButton.disabled">{{ groupButton.text }}</b-button>
            <b-button @click="$emit('GroupCreated', false)" variant="danger"
              :disabled="groupButton.disabled">Cancel</b-button>
          </div>
        </b-form-group>
      </b-form>
    </div>
  </div>
</template>

<script>
import DisplayExistingAdmins from "./DisplayExistingAdmins";
export default {
  props: [
    "adminList",
    "groupInfo",
    "mainGroupId",
    "getCustomNames",
    "userGroupsTypesOptions",
  ],
  created() {
    this.$set(this.group, "programme_type_id", null);
    if (this.groupInfo.mainGroup) {
      this.group = this.groupInfo.mainGroup;
    }
    if (this.groupInfo.mainGroup && this.groupInfo.admins.length != 0) {
      this.groupInfo.admins.forEach((admin) => {
        this.$set(this.existingAdmins, admin.pivot.institution_user_id, true);
      });
    }
    this.$set(this.group, "institution_users", []);
    this.getFaculties();
  },
  components: {
    DisplayExistingAdmins,
  },
  data() {
    return {
      group: {},
      adminsOptions: [],
      groupButton: {
        text: "Submit",
        disabled: false,
      },
      adminsObject: {},
      existingAdminsObj: {},
      existingAdmins: {},
      universities: [
        " University of Kerala",
        "University of Calicut",
        "Cochin University of Science and Technology",
        "Mahatma Gandhi University",
        "	Kannur University",
        "Sree Narayana Guru Open University",
        "Kerala University of Health Sciences",
        "Kerala Veterinary and Animal Sciences University",
        "Kerala Agricultural University",
        "Kerala University of Fisheries and Ocean Studies",
        "National University of Advanced Legal Studies",
        "Sree Sankaracharya University of Sanskrit",
        "Thunchath Ezhuthachan Malayalam University",
        "Kerala University of Digital Sciences, Innovation and Technology",
        "A P J Abdul Kalam Technological University",
        "K. R. Narayanan National Institute of Visual Science and Arts",
        "Department of Technical Education Kerala",
        "Anna University",
        "Chinmaya Vishwa Vidyapeeth",
        "St. Peters University"
      ],
    };
  },
  methods: {
    async prepareGroupCreation() {
      this.groupButton = {
        text: "Please wait...",
        disabled: true,
      };

      const groupData = {
        name: this.group.name,
        code: this.group.code,
        maingroup_id: this.mainGroupId,
        programme_type_id: this.group.programme_type_id,
        affiliated_university: this.group.affiliated_university,
        scheme: this.group.scheme
      };
      const admins = {
        institution_users: [],
      };


      if (this.group.institution_users.length != 0)
        await this.group.institution_users.forEach((admin) =>
          admins.institution_users.push(admin.id)
        );

      const createGroup = await this.createUsergroup(groupData);
      if (createGroup.status) {
        if (admins.institution_users.length != 0) {
          this.$toast.info("Assigning admins....", {
            position: "top-right",
            duration: 3000,
          });
          await this.assignUsergroupAdmins(admins, createGroup.id);
        }
        this.$toast.success(`${this.group.name} is successfully created.`, {
          position: "top",
          duration: 3000,
        });
        this.handleResponse(true);
      }
      if (!createGroup.status) {
        this.handleResponse(false);
      }
    },
    async createUsergroup(postData) {
      let url;
      let method;
      if (this.groupInfo.mainGroup) {
        method = "put";
        url =
          this.$store.getters.getAPIKey.createGroup +
          `/${this.groupInfo.mainGroup.id}`;
      } else {
        method = "post";
        url = this.$store.getters.getAPIKey.createGroup;
      }

      const createGroup = await this.$axios[method](url, postData)
        .then((res) => {
          return {
            status: true,
            id: res.data.id,
          };
        })
        .catch((err) => {
          return {
            status: false,
            err: err.response.message,
          };
        });
      return createGroup;
    },
    async assignUsergroupAdmins(postData, groupId) {
      const url = this.$store.getters.getAPIKey.createAdmin.replace(
        "usergroup_id",
        groupId
      );
      await this.$axios.post(url, postData);
    },
    handleResponse(status) {
      this.groupButton = {
        text: status ? "Created" : "Submit",
        disabled: status ? true : false,
      };
      if (status) {
        this.$emit("GroupCreated", true);
      }
    },
    updateAdminsList(institution_user_id) {
      this.existingAdmins[institution_user_id] = false;
      this.getFaculties();
    },
    async getFaculties() {
      await this.adminList.forEach((admin) => {
        if (
          !this.existingAdmins[admin.id] ||
          this.existingAdmins[admin.id] == undefined
        ) {
          if (admin.enroll_status == 'active') this.adminsOptions.push({
            name: admin.first_name.concat(" ", admin.last_name) + `(${admin.email})`,
            id: admin.id,
            code: admin.id,
          });
        }
      });
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
        id: newTag,
      };
      this.adminsOptions.push(tag);
      this.group.institution_users.push(tag);
    },
  },
};
</script>
