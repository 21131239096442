<template>
  <div>
    <div class="usersubgroupCourses">
      <div class="table-responsive rounded">
        <table class="table table-striped table-bordered">
          <thead class="thead-dark text-left">
            <tr>
              <th>Course</th>
              <th>Code</th>
              <th>
                {{
                  getCustomNames.userSubgroup.custom_name != null
                    ? getCustomNames.userSubgroup.custom_name
                    : "Usersubgroup"
                }}
                Code -
                {{
                  getCustomNames.userGroup.custom_name != null
                    ? getCustomNames.userGroup.custom_name
                    : "Usergroup"
                }}
                Code
              </th>
              <th>Academic Year</th>
              <th>Attendance Total (Present/Total)</th>
              <th>Attendance Percenage</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="filteredCourses.length == 0">
              <td colspan="100" class="text-danger text-center">
                No Course Found!
              </td>
            </tr>
            <tr
              v-else
              v-for="(course, index) in filteredCourses"
              :key="index"
              class="text-left"
            >
              <td class="align-middle">
                <b-modal
                  :id="'coursesOptions' + course.id"
                  :title="course.name + ' Configuration'"
                  no-close-on-backdrop
                  no-close-on-keyboard
                  no-close-on-esc
                  hide-footer
                  modal-class="coursesOptions"
                  ><CoursesOptions
                    :courseInfo="course"
                    :course_id="course.id"
                    :courseId="course.id"
                    :usergroupId="course.usersubgroup.usergroup.id"
                    :getCustomNames="getCustomNames"
                  />
                </b-modal>
                <p
                  class="text-info btn p-0 m-0 font-weight-bolder text-capitalize"
                  @click="$bvModal.show('coursesOptions' + course.id)"
                >
                  {{ course.name }}
                </p>
              </td>

              <td class="align-middle">
                {{ course.code }}
              </td>
              <td class="align-middle">
                {{ course.usersubgroup.code }} -
                {{ course.usersubgroup.usergroup.code }}
              </td>

              <td class="align-middle">
                {{ course.academic_year }}
              </td>
              <td class="align-middle">
                <b-spinner
                  small
                  v-if="!coursesSummary[course.id]"
                  variant="primary"
                  type="grow"
                  label="Spinning"
                ></b-spinner>
                <span v-else>
                  {{
                    coursesSummary[course.id].present +
                      "/" +
                      coursesSummary[course.id].totel
                  }}
                </span>
              </td>
              <td class="align-middle">
                <b-spinner
                  small
                  v-if="!coursesSummary[course.id]"
                  variant="primary"
                  type="grow"
                  label="Spinning"
                ></b-spinner>
                <span v-else>
                  {{ coursesSummary[course.id].persantage }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import CoursesOptions from "../Course/CoursesOptions";
export default {
  props: ["usersubgroupId", "courses", "getCustomNames"],
  async created() {
    this.filteredCourses = await this.courses.filter(({ usersubgroup_id }) => {
      return usersubgroup_id == this.usersubgroupId;
    });
    if (this.filteredCourses.length != 0) await this.getAttendenceDetails();

    this.$toast.success(
      "<a href='https://play.google.com/store/apps/details?id=in.chipsnbytes.ezygo.ezygo_app1&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img height='50' style='margin: -19px;'  alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>  ",
      {
        // position: "top",
        duration: 7000,
      }
    );
  },
  components: {
    CoursesOptions,
  },
  data() {
    return {
      filteredCourses: [],
      coursesSummary: {},
    };
  },
  methods: {
    async getAttendenceDetails() {
      await this.filteredCourses.forEach(({ id }) => {
        const url =
          this.$store.getters.getAPIKey.mainAPI +
          `/attendancereports/institutionuser/courses/${id}/summery`;
        this.$axios.get(url).then((res) => {
          this.$set(this.coursesSummary, id, res.data);
        });
      });
    },
  },
};
</script>
