<template>
  <div>
    <b-container>
      <br />
      <div>
        <fill-profile
          ref="enterProfileDetails"
          @profileCreated="profileCreated()"
        ></fill-profile>
        <p class="text-info">Create an account for my institute</p>
        <p class="ml-2">
          Create an account for your physical institute that you would like to
          manage using Ezygo or simply create a virtual institute and manage it
          here
        </p>
        <p class="text-info">Create an account for</p>

        <div>
          <b-form @submit.prevent="createInstituteName()">
            <b-form-group>
              <b-form-radio-group
                v-model="institute.type"
                :class="{ 'is-invalid': responserErrors.type }"
              >
                <b-form-radio value="physical">Physical Institute</b-form-radio>
                <b-form-radio value="virtual">Virtual Institute</b-form-radio>
              </b-form-radio-group>
              <span v-if="responserErrors.type" class="text-danger">{{
                responserErrors.type[0]
              }}</span>
            </b-form-group>
            <div v-if="institute.type && institute.type != ''">
              <b-form-group>
                <label>Institute Name <span class="text-danger">*</span></label>
                <b-form-input
                  v-model="institute.name"
                  :class="{
                    'is-invalid': responserErrors.name,
                    'form-group-error': $v.institute.name.$error,
                  }"
                ></b-form-input>
                <span
                  class="input-error-message"
                  v-if="!$v.institute.name.required && $v.institute.name.$dirty"
                  >Institute Name is required! <br
                /></span>

                <span v-if="responserErrors.name" class="text-danger">{{
                  responserErrors.name[0]
                }}</span>
              </b-form-group>

              <b-form-group>
                <label>Institute Code <span class="text-danger">*</span></label>
                <b-form-input
                  v-model="institute.code"
                  :class="{
                    'is-invalid': responserErrors.code,
                    'form-group-error': $v.institute.code.$error,
                  }"
                ></b-form-input>
                <span
                  class="input-error-message"
                  v-if="!$v.institute.code.required && $v.institute.code.$dirty"
                  >Institute Code is required! <br
                /></span>
                <span
                  class="input-error-message"
                  v-if="
                    !$v.institute.code.maxLength && $v.institute.code.$dirty
                  "
                  >Institute code cannot be greater than 8 letters. <br
                /></span>

                <span v-if="responserErrors.code" class="text-danger">{{
                  responserErrors.code[0]
                }}</span>
              </b-form-group>

              <div v-if="institute.type == 'physical'">
                <b-form-group>
                  <label
                    >Official Phone Number
                    <span class="text-danger">*</span></label
                  >
                  <b-form-input
                    v-model="institute.phone"
                    placeholder="Enter Phone Number"
                    :class="{
                      'is-invalid': responserErrors.phone_no,
                      'form-group-error': $v.institute.phone.$error,
                    }"
                  ></b-form-input>
                  <span
                    class="input-error-message"
                    v-if="
                      !$v.institute.phone.required && $v.institute.phone.$dirty
                    "
                    >Institute Phone Number is required! <br
                  /></span>

                  <span v-if="responserErrors.phone_no" class="text-danger">{{
                    responserErrors.phone_no[0]
                  }}</span>
                </b-form-group>

                <b-form-group>
                  <label
                    >Official Email Address
                    <span class="text-danger">*</span></label
                  >
                  <b-form-input
                    :class="{
                      'is-invalid': responserErrors.email,
                      'form-group-error': $v.institute.email.$error,
                    }"
                    type="email"
                    v-model="institute.email"
                    placeholder="institute_xyz@example.com"
                  ></b-form-input>
                  <span
                    class="input-error-message"
                    v-if="
                      !$v.institute.email.required && $v.institute.email.$dirty
                    "
                    >Institute Email is required! <br
                  /></span>

                  <span v-if="responserErrors.email" class="text-danger">{{
                    responserErrors.email[0]
                  }}</span>
                </b-form-group>

                <b-form-group>
                  <label>Official Website </label>
                  <b-form-input
                    v-model="institute.website"
                    :class="{
                      'is-invalid': responserErrors.website_url,
                    }"
                    placeholder="https://www.example.com"
                  ></b-form-input>
                  <span
                    v-if="responserErrors.website_url"
                    class="text-danger"
                    >{{ responserErrors.website_url[0] }}</span
                  >
                </b-form-group>
              </div>
            </div>

            <b-form-group class="text-center" v-if="institute.type">
              <br />
              <b-button
                variant="info"
                type="submit"
                class="text-white"
                :disabled="create.disabled"
                >{{ create.text }}</b-button
              >
              <b-button
                variant="danger"
                @click="$emit('institutionCreationCancelled')"
                class="text-white ml-2"
                :disabled="create.disabled"
                >Cancel</b-button
              >
            </b-form-group>
            <h6 class="alert alert-success" v-if="instituteCreated">
              {{ instituteName }} created
            </h6>
          </b-form>
          <br />
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import { required, requiredIf, maxLength } from "vuelidate/lib/validators";
export default {
  async created() {
    await this.listInstitutes();
    this.createInstitute = this.$store.getters.defaultInstitute.createInstitute;
  },

  data() {
    return {
      canInviteUsers: null,
      createInstitute: null,
      fetchData: false,
      institute: {},
      instituteType: "",
      instituteName: "",
      instituteCode: "",
      email: "",
      phone: "",
      website: "",
      create: {
        text: "Create",
        disabled: false,
      },
      responserErrors: {},
      instituteCreated: false,
      noInstitutesCreated: false,
      profileError: false,
    };
  },
  validations: {
    institute: {
      name: {
        required,
      },
      code: {
        required,
        maxLength: maxLength(8),
      },
      email: {
        required: requiredIf(function() {
          if (this.institute.type == "physical") {
            return true;
          }
        }),
      },
      phone: {
        required: requiredIf(function() {
          if (this.institute.type == "physical") {
            return true;
          }
        }),
      },
    },
  },
  methods: {
    profileCreated() {
      if (this.instituteType != "" && this.instituteName != "") {
        this.createInstituteName();
      }
    },
    async createInstituteName() {
      await this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        let data;
        if (this.institute.type == "physical") {
          data = {
            name: this.institute.name,
            code: this.institute.code,
            type: this.institute.type,
            email: this.institute.email,
            phone_no: this.institute.phone,
            website_url:
              this.institute.website == "" ? null : this.institute.website,
          };
        } else {
          data = {
            name: this.institute.name,
            code: this.institute.code,
            type: this.institute.type,
          };
        }
        this.create.text = "Creating...";
        this.create.disabled = true;
        const url = this.$store.getters.getAPIKey.createInstituteName;
        await axios
          .post(url, data)
          .then((response) => {
            this.$toast.success(
              `${this.institute.name} is successfully created`,
              {
                position: "top",
                duration: 3000,
              }
            );
            setTimeout(() => {
              this.$store.dispatch("setUpInstitutes");
            }, 3000);

            this.profileError = false;

            this.clearResponse();
            response;
          })
          .catch((error) => {
            if (error.response.status == 423) {
              // this.profileError = true;
              this.$refs.enterProfileDetails.checkProfile();
            } else if (error.response.status == 422) {
              this.$toast.error(error.response.data.message, {
                position: "top",
                duration: 5000,
              });
              this.responserErrors = error.response.data.errors;
            } else {
              this.responserErrors = error.response.data.errors;
            }
            this.clearResponse();

            error;
          });
      }
    },
    async listInstitutes() {
      const url = this.$store.getters.getAPIKey.createInstituteName;
      await axios
        .get(url)
        .then((response) => {
          if (response.data.length == 0) {
            this.noInstitutesCreated = !this.noInstitutesCreated;
          } else {
            this.noInstitutesCreated = false;
          }
          response;
        })
        .catch((error) => {
          error;
        });
    },

    clearResponse() {
      this.create.text = "Create";

      setTimeout(() => {
        if (this.instituteCreated == true) {
          this.instituteType = "";
          this.instituteName = "";
          this.instituteCreated = false;
        }
        this.responserErrors = {};
        this.create.disabled = false;
      }, 5000);
    },
  },
};
</script>

<style ></style>
