<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="smstransaction" v-else>
      <b-modal
        id="smsTransactionHistory"
        hide-footer
        no-close-on-backdrop
        no-close-on-keyboard
        no-close-on-esc
        modal-class="smsTransactionHistory"
      >
        <SmsTransactionHistory />
      </b-modal>
      <div class="d-flex justify-content-between align-items-center">
        <p
          style="font-size: 14px; font-weight: 600"
          :class="smsCount < 1000 ? 'text-danger' : 'text-dark'"
        >
          Balance SMS Count:
          <span>{{ smsCount }}</span>
        </p>
        <span
          class="btn p-0 m-0 bg-info text-white p-1"
          @click="$bvModal.show('smsTransactionHistory')"
          ><i class="fas fa-inbox mr-2"></i>Outbox</span
        >
      </div>

      <table class="table table-bordered table-striped mt-2">
        <thead class="thead-dark">
          <tr>
            <th class="align-middle">Date & Time.</th>
            <!-- <th class="align-middle">Transaction Type</th> -->
            <th class="align-middle">Credit/Debit</th>
            <th>Description</th>
            <th class="align-middle">Balance Count</th>
            <th class="align-middle">Price</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="smsReport.length == 0">
            <td colspan="100" class="align-middle text-danger text-center">
              No Data Found!
            </td>
          </tr>
          <tr v-else v-for="(sms, smsIndex) in smsReport" :key="smsIndex">
            <td class="align-middle">
              <span v-if="sms.created_at != null">
                {{ sms.created_at.format("DD/MM/YYYY") }} <br />
                {{ sms.created_at.format("hh:mm a") }}
              </span>
              <span v-else> </span>
            </td>
            <!-- <td class="align-middle text-capitalize">{{ sms.transaction_type }}</td> -->
            <td class="align-middle">{{ sms.transaction_type }}</td>
            <td class="align-middle">
              {{ sms.comments && sms.comments != null ? sms.comments : "-" }}
            </td>
            <td class="align-middle text-center">
              <span class="bg-info text-white rounded m-1 p-1">{{
                sms.balence_count
              }}</span>
            </td>
            <td class="align-middle">{{ sms.sms_price }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import SmsTransactionHistory from "./SmsTransactionHistory";
import moment from "moment";

export default {
  async created() {
    this.fetchData = true;

    this.smsCount = this.$store.getters.getSmsCount.smsBalance;

    await this.getSmsTransactionReport();
    this.fetchData = false;
  },
  components: {
    SmsTransactionHistory,
  },
  data() {
    return {
      smsCount: null,
      fetchData: false,
      smsReport: [],
    };
  },
  methods: {
    async getSmsTransactionReport() {
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/smscredittransactions`;
      await this.$axios
        .get(url)
        .then((resposne) => {
          this.smsReport = [];
          let smsReport = resposne.data;
          smsReport.forEach((sms) => {
            this.smsReport.push({
              id: sms.id,
              balence_count: sms.balence_count,
              transaction_type: sms.transaction_type,
              status: sms.status,
              comments: sms.comments,
              created_at:
                sms.created_at != null ? moment(sms.created_at) : null,
              sms_price: sms.sms_price,
              description: sms.description,
            });
          });

          resposne;
        })
        .catch((error) => {
          error;
        });
    },
  },
};
</script>

<style >
.smsTransactionHistory .modal-dialog {
  max-width: 100%;
  max-height: 100vh;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  position: fixed;
  z-index: 100000;
}

.smsTransactionHistory .modal-body {
  overflow: scroll;
}
</style>
