<template>
  <div>
    <b-skeleton-table
      v-if="fetchData"
      :rows="3"
      :columns="2"
      :table-props="{ bordered: true, striped: true }"
    ></b-skeleton-table>
    <div class="maingroupReport" v-else>
      <b-overlay
        :show="loadReport"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="md"
      >
        <table class="table-bordered table table-striped">
          <thead class="thead-dark">
            <tr>
              <th>Report Type</th>
              <th>Download</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="align-middle">Timetable For Faculty</td>
              <td class="align-middle text-center">
                <!-- <div
                  class="selectReportType mb-2 d-flex justify-content-center"
                >
                  <b-form-radio v-model="reportType" :value="0"
                    >Oridinary Report</b-form-radio
                  >
                  <b-form-radio v-model="reportType" :value="1" class="ml-3"
                    >ISO Report</b-form-radio
                  >
                </div>
                <b-button
                  variant="danger"
                  @click="downloadStudentTimetable()"
                  size="sm"
                  >Download</b-button
                > -->
                <b-modal
                  id="facultiesTimetable"
                  no-close-on-backdrop
                  no-close-on-keyboard
                  no-close-on-esc
                  hide-footer
                  size="xl"
                >
                  <FacultiesTimetable :maingroupReportId="maingroupReport.id" />
                </b-modal>

                <span
                  class="btn p-0 m-0 text-info font-weight-bolder"
                  @click="$bvModal.show('facultiesTimetable')"
                  >Configure & Download</span
                >
              </td>
            </tr>
          </tbody>
        </table>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import FacultiesTimetable from "./facultiesTimetableDownload";
export default {
  props: ["maingroupReport"],

  data() {
    return {
      fetchData: false,
      loadReport: false,
      selectedExams: [],
      selectExam: false,
      exams: [],
      courses: {},
      reportType: 0,
    };
  },
  components: {
    FacultiesTimetable,
  },
  methods: {},
};
</script>
