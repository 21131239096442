<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="submitForms" v-else>
      <div class="approvalListing p-3">
        <b-modal
          id="applicationStatus"
          no-close-on-backdrop
          no-close-on-keyboard
          no-close-on-esc
          hide-footer
          size="lg"
          modal-class="applicationStatus"
          :title="eventName"
        >
          <ApplicationStatus
            :eventId="eventId"
            :applications="applications"
            :eventName="eventName"
          />
        </b-modal>
        <b-overlay
          :show="loadApproval"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="md"
        >
          <div class="table-responsive">
            <table class="table table-bordered table-striped">
              <thead class="thead-dark">
                <tr>
                  <th>Name</th>
                  <th>Start</th>
                  <th>End</th>
                  <th>Status</th>
                  <th>Academic Year</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="surveyApprovals.length == 0">
                  <td
                    colspan="100"
                    class="vertical-align text-danger text-center"
                  >
                    No Forms Found!
                  </td>
                </tr>
                <tr
                  v-else
                  v-for="(approval, approvalIndex) in surveyApprovals"
                  :key="approvalIndex"
                >
                  <td class="align-middle text-center">
                    <span
                      :class="
                        approval.summary != null ? 'text-info' : 'text-dark'
                      "
                      :id="'approvalSummary' + approvalIndex"
                      >{{ approval.name }}</span
                    >
                    <br />
                    <b-modal
                      :id="'studentApplications' + approvalIndex"
                      no-close-on-backdrop
                      no-close-on-keyboard
                      no-close-on-esc
                      hide-footer
                      size="lg"
                      :title="
                        studentApplications.approvalName
                          ? studentApplications.approvalName
                          : ''
                      "
                    >
                      <StudentApplications
                        :studentApplications="studentApplications"
                      />
                    </b-modal>
                    <b-button
                      variant="primary"
                      size="sm"
                      @click="
                        goTo(
                          approval.name,
                          'ApprovalAns',
                          approval.id,
                          approval.multiple_application,
                          approval.application,
                          approvalIndex
                        )
                      "
                      >Submit Form</b-button
                    >
                    <b-tooltip
                      :target="'approvalSummary' + approvalIndex"
                      triggers="hover"
                      v-if="approval.summary != null"
                    >
                      {{ approval.summary }}
                    </b-tooltip>
                  </td>
                  <td class="align-middle">
                    {{ approval.start_at.format("DD/MM/YYYY") }} <br />
                    {{ approval.start_at.format("hh:mm a") }}
                  </td>
                  <td class="align-middle">
                    {{ approval.end_at.format("DD/MM/YYYY") }} <br />
                    {{ approval.end_at.format("hh:mm a") }}
                  </td>
                  <td class="align-middle">
                    <b-button
                      variant="primary"
                      size="sm"
                      v-if="approval.application.length != 0"
                      @click="
                        getApplicationStatus(
                          approval.id,
                          approval.name,
                          approval.application
                        )
                      "
                      >Check Status</b-button
                    >
                    <p
                      v-else
                      class="text-danger"
                      style="font-size: 12px; font-weight: 600"
                    >
                      Application not submitted!
                    </p>
                  </td>
                  <td class="align-middle">
                    {{ approval.academic_year }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import ApplicationStatus from "./ApplicationStatus.vue";
import StudentApplications from "./StudentApplications.vue";
import moment from "moment";
export default {
  created() {
    this.getFormsForSubmit();
  },
  components: {
    ApplicationStatus,
    StudentApplications,
  },
  data() {
    return {
      fetchData: false,
      loadApproval: false,
      surveyApprovals: [],
      applications: null,
      eventName: null,
      eventId: null,
      studentApplications: {},
    };
  },
  methods: {
    async getApplicationStatus(eventId, eventName, applications) {
      this.eventId = eventId;
      this.applications = await applications;
      this.eventName = eventName;
      this.$bvModal.show("applicationStatus");
    },
    getFormsForSubmit() {
      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/onlineregparticipant/onlineregistrations`;
      this.$axios
        .get(url)
        .then((response) => {
          this.surveyApprovals = [];
          const approvals = response.data;
          approvals.forEach((approval) => {
            if (approval.hidden == true || approval.hidden == 1) {
              ("DON'T PUSH DATA");
            } else {
              this.surveyApprovals.push({
                id: approval.id,
                name: approval.name,
                summary: approval.summary,
                academic_year: approval.academic_year,
                start_at: moment(
                  moment.utc(approval.start_at, "YYYY-MM-DD HH:mm:ss").toDate()
                ),
                end_at: moment(
                  moment.utc(approval.end_at, "YYYY-MM-DD HH:mm:ss").toDate()
                ),
                grouping_tag: approval.grouping_tag,
                comment_feedback: approval.comment_feedback,
                hidden: approval.hidden,
                participants_only: approval.participants_only,
                is_anonymous: approval.is_anonymous,
                application: approval.applications,
                multiple_application:
                  approval.multiple_application == 1 ? true : false,
              });
            }
          });

          this.fetchData = false;
        })
        .catch(() => {
          this.fetchData = false;
        });
    },
    goTo(
      approvalName,
      pathName,
      approvalId,
      multipleApplications,
      applications,
      approvalIndex
    ) {
      this.studentApplications = {
        approvalName,
        pathName,
        approvalId,
        multipleApplications,
        applications,
      };

      this.$bvModal.show("studentApplications" + approvalIndex);
    },
  },
};
</script>

<style >
.applicationStatus .modal-body {
  background-color: #eeeeeb;
}
</style>
