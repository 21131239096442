<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="submitForms" v-else>
      <div class="approvalListing p-3">
        <b-overlay
          :show="loadApproval"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="md"
        >
          <b-modal
            id="getDetailedApprovalReport"
            no-close-on-backdrop
            no-close-on-keyboard
            no-close-on-esc
            hide-footer
            scrollable
            size="xl"
          >
            <DetailedApprovalReport :detailedReport="detailedReport" />
          </b-modal>
          <div class="table-responsive">
            <table class="table table-bordered table-striped">
              <thead class="thead-dark">
                <tr>
                  <th>Name</th>
                  <th>Start</th>
                  <th>End</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="surveyApprovals.length == 0">
                  <td colspan="100" class="vertical-align text-danger text-center">
                    No Forms Found!
                  </td>
                </tr>
                <tr
                  v-else
                  v-for="(approval, approvalIndex) in surveyApprovals"
                  :key="approvalIndex"
                >
                  <td class="align-middle">
                    <span
                      :class="approval.summary != null ? 'text-info' : 'text-dark'"
                      :id="'approvalSummary' + approvalIndex"
                      >{{ approval.name }}</span
                    >

                    <b-tooltip
                      :target="'approvalSummary' + approvalIndex"
                      triggers="hover"
                      v-if="approval.summary != null"
                    >
                      {{ approval.summary }}
                    </b-tooltip>
                  </td>
                  <td class="align-middle">
                    {{ approval.start_at.format("DD/MM/YYYY") }} <br />
                    {{ approval.start_at.format("hh:mm a") }}
                  </td>
                  <td class="align-middle">
                    {{ approval.end_at.format("DD/MM/YYYY") }} <br />
                    {{ approval.end_at.format("hh:mm a") }}
                  </td>
                  <td class="align-middle text-center">
                    <span
                      class="btn p-0 m-0 text-info"
                      @click="getDetailedReport(approval.id)"
                      ><i class="fas fa-file-alt"></i
                    ></span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import DetailedApprovalReport from "./DetailedApprovalReport.vue";
export default {
  created() {
    this.getFormsForSubmit();
    this.report();
  },
  components: {
    DetailedApprovalReport,
  },
  data() {
    return {
      fetchData: false,
      loadApproval: false,
      surveyApprovals: [],
      detailedReport: {},
    };
  },
  methods: {
    report() {
      const url = this.$store.getters.getAPIKey.surveyApprovals + `/report`;
      this.$axios
        .post(url, { start_date: "2021/07/01", upto_date: "2021/07/28" })
        .then((response) => {
          response;
        })
        .catch((error) => {
          error;
        });
    },
    getDetailedReport(registrationId) {
      let routeData = this.$router.resolve({
        name: "DetailedApprovalReport",
        params: {
          approval_id: registrationId,
        },
      });
      window.open(routeData.href, "_blank");

      // let route = this.$router.resolve({
      //   name: "DetailedApprovalReport",
      //   params: {
      //     approval_id: registrationId,
      //   },
      // });
      // console.log(route);
      // let domain = this.$router.resolve({
      //   name: "login",
      // });
      // console.log(domain);
      // window.open(route, "_blank");
      // this.loadApproval = true;
      // const url =
      //   this.$store.getters.getAPIKey.surveyApprovals + `/${registrationId}/report`;
      // this.$axios
      //   .get(url)
      //   .then((response) => {
      //     this.$set(this.detailedReport, "approvals", response.data.approvals);
      //     this.$set(this.detailedReport, "approvers", response.data.approvers);
      //     this.$set(
      //       this.detailedReport,
      //       "onlineregistration",
      //       response.data.onlineregistration
      //     );
      //     this.$set(
      //       this.detailedReport,
      //       "participants_application_answers",
      //       response.data.participants_application_answers
      //     );
      //     this.$set(this.detailedReport, "questions", response.data.questions);
      //     this.loadApproval = false;
      //     this.$bvModal.show("getDetailedApprovalReport");
      //     response;
      //   })
      //   .catch((error) => {
      //     this.loadApproval = false;
      //     error;
      //   });
    },
    getFormsForSubmit() {
      this.fetchData = true;
      const url = this.$store.getters.getAPIKey.surveyApprovals;
      this.$axios
        .get(url)
        .then((response) => {
          this.surveyApprovals = [];
          const approvals = response.data;
          approvals.forEach((approval) => {
            if (approval.hidden == true || approval.hidden == 1) {
              ("DON'T PUSH DATA");
            } else {
              this.surveyApprovals.push({
                id: approval.id,
                name: approval.name,
                summary: approval.summary,
                academic_year: approval.academic_year,
                start_at: moment(
                  moment.utc(approval.start_at, "YYYY-MM-DD HH:mm:ss").toDate()
                ),
                end_at: moment(
                  moment.utc(approval.end_at, "YYYY-MM-DD HH:mm:ss").toDate()
                ),
                grouping_tag: approval.grouping_tag,
                comment_feedback: approval.comment_feedback,
                hidden: approval.hidden,
                participants_only: approval.participants_only,
                is_anonymous: approval.is_anonymous,
                application: approval.applications,
              });
            }
          });

          this.fetchData = false;
          response;
        })
        .catch((error) => {
          this.fetchData = false;
          error;
        });
    },
  },
};
</script>
