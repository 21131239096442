<template>
  <div>
    <b-skeleton-table v-if="fetchData" :rows="4" :columns="6"
      :table-props="{ bordered: true, striped: true }"></b-skeleton-table>
    <div class="usergroup" v-else>
      <div class="groupSubBtns p-2 mb-3 d-flex justify-content-between" style="
            border: 2px solid #eeeeeb;
            border-left-style: none;
            border-right-style: none;
          " v-if="editGroup && !enrollAndInvite && currentComponent != 'MainGroups'">
        <b-row>
          <b-button variant="info" v-if="mainGroupAdmin" @click="currentComponent = 'MainGroups'"><i
              class="fas fa-plus-square text-white"></i>Add Department</b-button>
          <div class="text-danger " style="width: 60%;">
            (<span style="font-size: 13px !important; font-weight: bold;">When a change in the scheme occurs, a new
              programme must be created </span>and the classes following the new scheme should be added to the newly
            created programme)
          </div>
        </b-row>
        <!-- <b-button
          @click="openYoutubeLinks('Groups')"
          style="backgroundColor: #FF0000; border: none"
          size="sm"
        >
          <i class="fab fa-youtube"></i>
          Video Tutorial
        </b-button> -->
      </div>

      <component :selectedInstitute="selectedInstitute" :groupId="groupId" :groups="groups" :adminList="adminList"
        :groupInfo="groupInfo" :mainGroups="mainGroups" :getCustomNames="getCustomNames"
        :userGroupsTypesOptions="userGroupsTypesOptions" :usergroupFromMain="groups" @goToPSO="goToPSO"
        @backToGroups="currentComponent = 'ViewMainGroups'" @GroupCreated="GroupCreated" @getGroup="getGroup"
        @updateAdminStatus="groupInfo.status = true" @adminsUpdated="$emit('adminsUpdated')"
        @checkMaingroupAdmin="loadMainGroups()" @mainGroupCreated="mainGroupCreated"
        @addFacultyStatus="loadMainGroups()" v-bind:is="currentComponent"></component>
    </div>
  </div>
</template>

<script>
import ViewGroups from "./ViewGroups";
import ProgramSpecificOutcome from "../Institute/settings/SettingsSpecificOutcome";
import ViewMainGroups from "./ViewMainGroups";
import MainGroups from "./MainGroups";
import AttachUsergroupToMaingroup from "./AttachUsergroupToMaingroup.vue";
export default {
  props: [
    "instituteAdmin",
    "mainGroupAdmin",
    "userGroupAdmin",
    "groups",
    "selectedInstitute",
    "getCustomNames",
    "adminList",
    "userGroupsTypesOptions",
  ],
  async created() {
    await this.loadGroups();
  },
  components: {
    ViewGroups,
    ProgramSpecificOutcome,
    ViewMainGroups,
    MainGroups,
    AttachUsergroupToMaingroup,
  },
  data() {
    return {
      fetchData: false,
      editGroup: true,
      enrollAndInvite: false,
      groupId: null,
      mainGroups: [],
      currentComponent: "ViewMainGroups",
      groupInfo: {},
      adminDataChanged: false,
    };
  },
  computed: {
    getComponentBasedOnAdminRole() {
      if (!this.instituteAdmin && !this.mainGroupAdmin && this.userGroupAdmin) {
        return "ViewGroups";
      } else {
        return "ViewMainGroups";
      }
    },
  },
  methods: {
    mainGroupCreated(status) {
      status || this.groupInfo.status
        ? this.loadGroups()
        : (this.currentComponent = ViewMainGroups);
      this.groupInfo = {};
    },
    async loadGroups() {
      this.fetchData = true;
      this.currentComponent = "ViewMainGroups";

      await this.loadMainGroups();

      this.fetchData = false;
    },
    switchComponent(componentName) {
      this.currentComponent = componentName;
    },
    goToPSO(groupId) {
      this.groupId = groupId;
      this.switchComponent("ProgramSpecificOutcome");
    },
    async GroupCreated(status) {
      this.currentComponent = "ViewMainGroups";
      this.groupInfo = {};
      status || this.groupInfo.status
        ? await this.loadGroups()
        : (this.currentComponent = "ViewMainGroups");
    },
    getGroup(groupData) {
      this.groupInfo = groupData;
      this.currentComponent = "MainGroups";
    },
    async loadMainGroups() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/maingroups?with_admins=1&with_po_count=1&with_users_count=1`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.mainGroups = response.data;
          response;
        })
        .catch((error) => {
          error;
        });
    },
    async getUsergroups() {
      const url = this.$store.getters.getAPIKey.getUserGroupList;
      await this.$axios
        .get(url)
        .then((response) => {
          this.groups = response.data;
          response;
        })
        .catch((error) => {
          error;
        });
    },
  },
};
</script>

<style >
.groupSubBtns i {
  font-size: 14px !important;
  margin-right: 4px;
}

.groupSubgroups ul {
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 5px;
}

.groupSubgroups ul li {
  border: 3px solid #f8f9fa !important;
}

.groupSubgroups .tab-content {
  border: none !important;
}
</style>
