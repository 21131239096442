<template>
  <div class="container">
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div v-if="!fetchData">
      <b-row class="control-buttons">
        <b-col v-if="resourceaction == 'list'" style="text-align:left;">
          <b-button
            v-if="$store.getters.defaultInstitute.userRole != 'Student'"
            variant="info"
            class="text-white sm"
            size="sm"
            @click="createCourseResourceform"
            ><i class="fas fa-plus-square text-white mr-1"></i>Add Resource Link</b-button
          >
        </b-col>
        <b-col v-if="resourceaction != 'list'" style="text-align:right;">
          <b-button
            variant="danger"
            class="text-white sm"
            size="sm"
            @click="getCourseresources"
            :disabled="actionButton.disabled"
            >Cancel</b-button
          >
        </b-col>
      </b-row>
      <div v-if="resourceaction == 'list'">
        <b-row>
          <b-col sm="12">
            <table class="table  table-striped table-bordered">
              <tr>
                <th>
                  SI. No.
                </th>
                <th>
                  Name
                </th>
                <th>
                  Description
                </th>
                <th>
                  Module
                </th>
                <th></th>
                <th
                  v-if="$store.getters.defaultInstitute.userRole != 'Student'"
                ></th>
              </tr>
              <tr
                v-for="(cresource, reindex) in courseresources"
                :key="reindex"
              >
                <td>{{ reindex + 1 }}</td>
                <td>
                  <a target="_blank" :href="cresource.href_link">
                    {{ cresource.name }}
                  </a>
                </td>
                <td>{{ cresource.description }}</td>
                <td>
                  <span v-if="cresource.module">{{
                    cresource.module.module
                  }}</span>
                </td>
                <td>
                  <a target="_blank" :href="cresource.href_link">{{
                    cresource.href_link
                  }}</a>
                </td>
                <td
                  v-if="$store.getters.defaultInstitute.userRole != 'Student'"
                >
                  <span
                    class="text-info btn m-0 p-0 sm"
                    size="sm"
                    @click="editCourseResourceform(cresource)"
                    :disabled="actionButton.disabled"
                    ><i class="fas fa-pen ml-3"></i
                  ></span>
                  <span
                    class="text-info btn m-0 p-0 sm"
                    size="sm"
                    @click="deleteCourseresources(cresource.id)"
                    :disabled="actionButton.disabled"
                    ><i style="color:red;" class="fas fa-trash ml-3"></i
                  ></span>
                </td>
              </tr></table
          ></b-col>
        </b-row>
      </div>
      <div v-if="resourceaction == 'create'">
        <div>
          <h3 style="text-align: center; font-size:18px !important;">
            Add new Course Resource link
          </h3>
          <b-row>
            <b-col sm="6">
              <b-form-group
                id="input-group-1"
                label="Title:"
                label-for="input-1"
                description=""
              >
                <b-form-input
                  id="input-1"
                  v-model="selectedcourseresource.name"
                  type="text"
                  placeholder="Enter a Title"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                id="input-group-2"
                label="Description:"
                label-for="input-2"
                description=""
              >
                <b-form-input
                  id="input-2"
                  v-model="selectedcourseresource.description"
                  type="text"
                  placeholder="Enter a Description"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="6">
              <b-form-group
                id="input-group-3"
                label="Link:"
                label-for="input-3"
                description=""
              >
                <b-form-input
                  id="input-3"
                  v-model="selectedcourseresource.href_link"
                  type="text"
                  placeholder="http:// ..."
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col sm="6">
              <div>
                <label for="moduleselect">Select course Module</label>
                <b-form-select
                  id="moduleselect"
                  v-model="selectedcourseresource.course_module_id"
                  :options="modules"
                ></b-form-select></div
            ></b-col>
          </b-row>
          <b-row>
            <b-col sm="12" style="text-align:right;">
              <b-button
                variant="success"
                class="text-white sm"
                size="sm"
                @click="createCourseResource"
                :disabled="actionButton.disabled"
                >{{ actionButton.text }}</b-button
              >
            </b-col>
          </b-row>
        </div>
      </div>
      <div v-if="resourceaction == 'edit'">
        <div>
          <h3 style="text-align: center; font-size:18px !important;">
            Edit Course Resource link
          </h3>
          <b-row>
            <b-col sm="6">
              <b-form-group
                id="input-group-1"
                label="Title:"
                label-for="input-1"
                description=""
              >
                <b-form-input
                  id="input-1"
                  v-model="selectedcourseresource.name"
                  type="text"
                  placeholder="Enter a Title"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                id="input-group-2"
                label="Description:"
                label-for="input-2"
                description=""
              >
                <b-form-input
                  id="input-2"
                  v-model="selectedcourseresource.description"
                  type="text"
                  placeholder="Enter a Description"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="6">
              <b-form-group
                id="input-group-3"
                label="Link:"
                label-for="input-3"
                description=""
              >
                <b-form-input
                  id="input-3"
                  v-model="selectedcourseresource.href_link"
                  type="text"
                  placeholder="http:// ..."
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col sm="6">
              <div>
                <label for="moduleselect">Select course Module</label>
                <b-form-select
                  id="moduleselect"
                  v-model="selectedcourseresource.course_module_id"
                  :options="modules"
                ></b-form-select></div
            ></b-col>
          </b-row>
          <b-row>
            <b-col sm="12" style="text-align:right;">
              <b-button
                variant="success"
                class="text-white sm"
                size="sm"
                @click="updateCourseResource"
                :disabled="actionButton.disabled"
                >{{ actionButton.text }}</b-button
              >
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import createModule from "./ModuleCreation";
export default {
  props: ["courseId"],
  created() {
    this.getCourseresources();
  },
  // components: {
  //   createModule: createModule,
  // },
  data() {
    return {
      fetchData: false,
      resourceaction: "list", // list,create,edit
      courseresources: [],
      selectedcourseresource: {
        id: "",
        course_id: this.courseId,
        course_module_id: "",
        name: "",
        description: "",
        href_link: "",
      },
      modules: [],
      // moduleCreation: false,
      // currentCourseModule: {},
      // deleteModulesIds: [],
      // selectAllModules: false,
      // modules: {},
      // deleteModule: false,
      actionButton: {
        text: "Save",
        disabled: false,
      },
    };
  },
  methods: {
    getCourseresources() {
      this.resourceaction = "list";
      this.fetchData = true;
      // const url = this.$store.getters.getAPIKey.mainAPI + `/courseResources/${ResourceId}`;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/courses/${this.courseId}/courseresourcelinks`;
      axios
        .get(url)
        .then((response) => {
          this.courseresources = response.data;
          this.fetchData = false;
          // console.log(response);
        })
        .catch(() => {
          this.$toast.error("Failed to get List", {
            position: "top",
            duration: 3000,
          });
          this.fetchData = false;
          // console.log(error.response);
        });
    },
    getCourseModules() {
      this.fetchData = true;
      let courseModuleUrl = this.$store.getters.getAPIKey.getCourseModules;
      const url = courseModuleUrl.replace("course_id", this.courseId);
      axios
        .get(url)
        .then((response) => {
          // this.modules = response.data;
          response.data.forEach((element) => {
            this.modules.push({
              text: element.module,
              value: element.id,
            });
          });
          this.fetchData = false;
          // console.log(response);
        })
        .catch(() => {
          this.$toast.error("Failed to fetch course modules", {
            position: "top",
            duration: 3000,
          });
          // console.log(error.response);
        });
    },
    deleteCourseresources(id) {
      this.actionButton = {
        text: "Save",
        disabled: true,
      };
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/courseresourcelinks/${id}`;
      this.$axios
        .delete(url)
        .then(() => {
          this.getCourseresources();
          this.$toast.success("Deleted successfully", {
            position: "top",
            duration: 3000,
          });
          this.actionButton = {
            text: "Save",
            disabled: false,
          };
          // console.log(response);
        })
        .catch(() => {
          this.$toast.error("Failed to delete", {
            position: "top",
            duration: 3000,
          });
          this.actionButton = {
            text: "Save",
            disabled: false,
          };
          // console.log(error);
        });
    },

    async createCourseResourceform() {
      this.selectedcourseresource = {
        id: "",
        course_id: this.courseId,
        course_module_id: "",
        name: "",
        description: "",
        href_link: "",
      };
      this.resourceaction = "create";
      this.getCourseModules();
    },
    async createCourseResource() {
      // this.ResourceBtn.text = "Please wait...";
      // this.ResourceBtn.disabled = true;
      this.actionButton = {
        text: "Saving...",
        disabled: true,
      };
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/courseresourcelinks`;
      axios
        .post(url, this.selectedcourseresource)
        .then(() => {
          // console.log(response);
          this.$toast.success("Created successfully", {
            position: "top",
            duration: 3000,
          });
          this.actionButton = {
            text: "Save",
            disabled: false,
          };
          this.getCourseresources();
        })
        .catch((error) => {
          this.$toast.error("Failed to Create", {
            position: "top",
            duration: 3000,
          });
          this.actionButton = {
            text: "Save",
            disabled: false,
          };
          let errormsgs = error.response.data.errors;
          for (const key in errormsgs) {
            // console.log(element);
            // error.response.data.errors.forEach((element) => {
            this.$toast.error(errormsgs[key][0], {
              position: "top",
              duration: 3000,
            });
          }
          // console.log(error.response);
        });
    },
    async editCourseResourceform(cresource) {
      this.selectedcourseresource = cresource;
      this.resourceaction = "edit";
      this.getCourseModules();
    },
    async updateCourseResource() {
      this.actionButton = {
        text: "Saving...",
        disabled: true,
      };
      // this.ResourceBtn.text = "Please wait...";
      // this.ResourceBtn.disabled = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/courseresourcelinks/${this.selectedcourseresource.id}`;
      axios
        .put(url, this.selectedcourseresource)
        .then(() => {
          // console.log(response);
          this.$toast.success("Updated successfully", {
            position: "top",
            duration: 3000,
          });
          this.actionButton = {
            text: "Save",
            disabled: false,
          };
          this.getCourseresources();
        })
        .catch(() => {
          this.actionButton = {
            text: "Save",
            disabled: false,
          };
          this.$toast.error("Failed to Update", {
            position: "top",
            duration: 3000,
          });
          // console.log(error.response);
        });
    },
  },
};
</script>

<style >
</style>
