<template>
  <div>
    <b-modal id="inviteUsersToInstitute" title="Invite Users" hide-footer no-close-on-backdrop no-close-on-keyboard
      no-close-on-esc size="lg" centered ref="modal">
      <div class="loading" v-if="fetchData">
        <load-data></load-data>
      </div>
      <b-form @submit.prevent="sendInvitations()" v-else>
        <h6 class="alert alert-success text-center" v-if="invitationSuccess">
          Invitations Sent
        </h6>
        <b-alert v-if="smsCount < 1000" variant="danger" class="text-center" show>Invitations cannot be sent via SMS if
          the SMS count is low</b-alert>
        <b-form-group>
          <b-form-select :options="institutionRoles" size="sm" class="mt-3" v-model="invitation.role_id"
            :class="{ 'is-invalid': responseErrors.role_id }"></b-form-select>
          <span class="text-danger" v-if="responseErrors.role_id">{{
              responseErrors.role_id[0]
          }}</span>
        </b-form-group>

        <div v-if="invitation.role_id">
          <!-- <b-form-group class="ml-4">
            <b-form-group label="Choose how invitation will sent:" v-slot="{ ariaDescribedby }">
              <b-form-radio v-model="invitationType" :aria-describedby="ariaDescribedby" name="some-radios"
                value="normal">Normal</b-form-radio>
              <b-form-radio v-model="invitationType" :aria-describedby="ariaDescribedby" name="some-radios"
                value="advanced">Advanced</b-form-radio>
            </b-form-group>
            <span v-if="invitationType == 'advanced'" class="text-danger">
              With Ezygo's latest invitation option, faculty profiles will be automatically created prior to their
              enrollment at Ezygo. This means that admins can complete class-faculty mapping, course-faculty mapping,
              update the timetable, and more, even before a faculty member officially signs up and enrolls at the
              institution in Ezygo. This feature provides a seamless experience for admins and faculty, ensuring that
              everyone is ready to hit the ground running from day one.
            </span>
            <span v-if="invitationType == 'normal'" class="text-danger">
              Users can sign up and enroll in our institution using an invitation code.
              However, please note that faculty members can only add users to programmes, courses,
              and perform other related tasks after users have officially enrolled in our institution.
            </span>
          </b-form-group> -->

          <b-form-group class="ml-4">
            <b-form-tags v-if="invitationType == 'normal'" input-id="tags-remove-on-delete"
              :input-attrs="{ 'aria-describedby': 'tags-remove-on-delete-help' }" v-model="invitation.invitee_array"
              separator="'
             ,;'" placeholder="Enter email/91 + 10 digit mobile number separated by commas" remove-on-delete
              no-add-on-enter tag-pills tag-variant="primary" :class="{ 'is-invalid': responseErrors.invitee }"
              v-b-popover.hover.bottom="
                'john8@gmail.com,918765******,test@gmail.com, test2@gmail.com'
              " @blur="checkTags"></b-form-tags>
            <div v-if="invitationType == 'advanced'">
              <b-form-group class="ml-4">
                <b-form-group class="mb-0">
                  <label for="" class="required-label">Enter mobile number(91+10 digit number) or email separated by
                    comma.
                    You may also copy and paste an entire excel column that contains email or mobile number:</label>
                  <b-form-textarea v-model="emailMobileInputData" id="textarea-formatter" rows="3"
                    placeholder="user@gmail.com,918876543210,..."></b-form-textarea>
                </b-form-group>
                <br>
                <b-form-group class="mb-0">
                  <label for="" class="required-label">Please enter the names of the students in the same order as the
                    corresponding email/mobile number entered in the first field, and separate each name with a comma.
                    This
                    will ensure that the correct names are associated with the correct contact information. Instead, you
                    may
                    copy and paste an entire Excel column containing student names:</label>
                  <b-form-textarea v-model="studentsNameInputData" id="textarea-formatter" rows="3"
                    placeholder="User 1,User 2, User 3"></b-form-textarea>
                </b-form-group>
                <b-button variant="success" size="md" @click="updateTableFromInputData()">Proceed</b-button>
              </b-form-group>

              <b-form-group class="ml-4">
                <div v-if="(inviteesDatas.length > 0)">
                  <table class="table  table-striped table-bordered">
                    <thead class="thead-dark">
                      <tr>
                        <th>#</th>
                        <th>Email/Mobile</th>
                        <th>Student First Name</th>
                        <th>Last Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(inviteeData, reindex) in inviteesDatas" :key="reindex">
                        <td>{{ (reindex + 1) }}</td>
                        <td>{{ inviteeData.invitee }}</td>
                        <td>{{ inviteeData.first_name }}</td>
                        <td>{{ inviteeData.last_name }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <b-button v-if="(inviteesDatas.length > 0)" variant="primary" type="submit" class="font-weight-bolder text-white"
                  :disabled="(sendInvites.disabled || inviteesDatas.length < 1)">{{ sendInvites.text }}</b-button>
              </b-form-group>

            </div>

            <span class="text-danger" v-if="failedinvitation != null">Invitation already accepted by {{ failedinvitation
            }}<br />
            </span>
            <span class="text-danger" v-if="responseErrors.invitee">{{
                responseErrors.invitee[0]
            }}</span>
          </b-form-group>

          <b-form-group class="float-right" v-if="invitation.invitee_array.length != 0">
            <b-button variant="white" type="submit" class="ml-5 font-weight-bolder text-primary"
              :disabled="sendInvites.disabled">{{ sendInvites.text }}</b-button>
          </b-form-group>
        </div>


        <b-form-group>
          <p style="font-size: 14px; font-weight: 600" :class="smsCount < 1000 ? 'text-danger' : 'text-dark'">
            Balance SMS Count:
            <span>{{ smsCount }}</span>
          </p>
          <div class="text-right" @click="pendingUsers.length == 0 ? checkInvitees() : ''">
            <p class="text-primary btn p-0 m-0" @click="$bvModal.show('pendingUsersList')">
              View list of unenrolled admin/faculties
            </p>
          </div>
        </b-form-group>

        <b-modal size="xl" id="pendingUsersList" title="List of invitees yet to join" class="text-center" centered
          hide-footer hide-header>
          <div class="d-flex align-items-baseline justify-content-between">
            <h5 class="title">List of invitees yet to join</h5>
            <p class="btn p-0 m-0" @click="$bvModal.hide('pendingUsersList')">
              <i class="far fa-times-circle fa-lg"></i>
            </p>
          </div>
          <hr />
          <div v-for="(pending, userIndex) in pendingUsers" :key="pending.id">
            <b-list-group v-if="
              pendingUsers.length > 0 &&
              pending.invite_to_id == parseInt(selectedInstitute.id)
            ">
              <b-list-group-item class="d-flex justify-content-between flex-wrap">
                <div>
                  <p class="p-0 m-0">{{ pending.invitee }}</p>
                  <p class="p-0 m-0">Role: {{ pending.institution_role.name }}</p>
                </div>
                <div>
                  <span class="text-danger">
                    {{ pending.notification_status }}
                  </span>
                  <b-button v-if="!pending.accepted_by" variant="info" size="md"
                    @click="resentEnrolmentCode(pending.id)">Resend</b-button>
                  <b-button v-if="!pending.accepted_by" variant="danger" size="md"
                    @click="deleteInvitation(userIndex)">Delete</b-button>
                </div>
              </b-list-group-item>
            </b-list-group>
            <h5 v-if="!pending.invite_to_id">No pending users</h5>
          </div>
        </b-modal>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["selectedInstitute"],
  created() {
    this.smsCount = this.$store.getters.getSmsCount.smsBalance;
  },

  data() {
    return {
      studentsNameInputData: '',
      inviteesDatas: [],
      emailMobileInputData: '',
      invitationType: 'advanced', // 'normal', 'advanced'
      smsCount: null,
      fetchData: false,
      newTag: "",
      invitation: {
        invitee_array: [],
        invitee: "",
        invite_to: "institution",
        role_id: null,
      },
      institutionRoles: [],
      pendingUsers: [],
      sendInvites: {
        text: "Send Enrollment Code",
        disabled: false,
      },
      responseErrors: {},
      invitationSuccess: false,
      failedinvitation: null,
    };
  },
  methods: {
    async updateTableFromInputData() {
      this.inviteesDatas = [];
      let splitDataEmailMobile = await this.emailMobileInputData.replaceAll(' ', '').split(/[\n,]+/);
      let splitDataStudentsName = await this.studentsNameInputData.split(/[\n,]+/);
      if (this.emailMobileInputData == '' || this.studentsNameInputData == '' || splitDataEmailMobile.length != splitDataStudentsName.length) {
        this.$toast.error(`Please fill the columns /Number of Email/Mobile not matches number of Student name.`, {
          top: "top",
          duration: 3000,
        });
        return;
      }
      for (let index = 0; index < splitDataStudentsName.length; index++) {
        let names = splitDataStudentsName[index].trim().split(' ');
        let first_name = names[0];
        let last_name = splitDataStudentsName[index].substring(first_name.length + 1);
        this.inviteesDatas.push({
          invitee: splitDataEmailMobile[index], name: splitDataStudentsName[index],
          first_name: first_name, last_name: last_name,
        });
      }

      this.sendInvites.text = "Send Enrolment Code";
      this.sendInvites.disabled = false;

    },

    checkTags($event) {
      console.log($event);
    },
    async dataFromNotepad($event) {
      await $event.target;
      let dataToProcess = await $event.target.value;

      this.newTag = "";
      let splitData = await dataToProcess.split(",");
      await splitData.forEach((data) => {
        this.invitation.invitee_array.push(data);
      });
    },
    avoidComma($event) {
      if ($event.charCode == 44) {
        return $event.preventDefault();
      }
    },
    resetInputValue() {
      this.newTag = "";
    },
    setTag(text) {
      this.newTag = text;
    },
    test(value) {
      console.log(value);
    },
    clearFields() {
      this.sendInvites.text = "Send Enrolment Code";

      setTimeout(() => {
        this.sendInvites.disabled = false;
        this.failedinvitation = null;
        this.responseErrors = {};
      }, 5000);
    },
    async prepareInviteUsers() {
      this.$bvModal.show("inviteUsersToInstitute");
      await this.userRoles(this.selectedInstitute.id);
    },

    async sendInvitations() {
      this.sendInvites.text = "Sending...";
      this.sendInvites.disabled = true;
      this.invitation.invite_to = "institution";
      let postData = {
        invite_to: "institution",
        role_id: this.invitation.role_id,
      }
      if (this.invitationType == 'normal') {
        postData.invitee_array = this.invitation.invitee_array;
        postData.invitee = this.invitation.invitee;
      } else {
        postData.invite_with_profiles = this.inviteesDatas;
      }

      const url = this.$store.getters.getAPIKey.userInvitations;
      axios
        .post(url, postData)
        .then((response) => {
          response;
          this.studentsNameInputData = '';
          this.inviteesDatas = [];
          this.emailMobileInputData = '';
          this.invitation.invitee_array = [];
          this.invitation.invite_to = "";
          this.invitation.role_id = null;
          this.$toast.success(`Invitations Sent Successfully.`, {
            top: "top",
            duration: 3000,
          });
          setTimeout(() => {
            this.$bvModal.hide("inviteUsersToInstitute");
          }, 4000);
          this.checkInvitees();
          this.clearFields();
        })
        .catch((error) => {
          this.responseErrors = error.response.data.errors;
          // this.responseErrors =
          this.failedinvitation = error.response.data.failed_invitee;
          // if (failedInvitee) {
          //   this.$toast.error(`Invitation already accepted by ${failedInvitee}.`, {
          //     top: "top",
          //     duration: 4000,
          //   });
          // }
          this.clearFields();
          error;
        });
    },
    async checkInvitees() {
      const url = this.$store.getters.getAPIKey.listInvitees;
      axios
        .get(url)
        .then((response) => {
          this.pendingUsers = response.data;
          response;
        })
        .catch((error) => {
          error;
        });
    },
    async resentEnrolmentCode(id) {
      const url = `${this.$store.getters.getAPIKey.mainAPI}/userinvitations/${id}/resent`;
      axios
        .get(url)
        .then(() => {
          this.$toast.success(`Enrollment code sent successfully`, {
            top: "top",
            duration: 3000,
          });
        })
        .catch(() => {
          this.$toast.error(`Failed to sent enrollment code`, {
            top: "top",
            duration: 3000,
          });
        });
    },

    async deleteInvitation(index) {
      if (
        confirm(
          `WARNING: Invitation will be deleted only if invitation not accepted by a user. Automatically created Student/Faculty will not delete along with invitation, if the profile is included in any activity like attendance, schedules, ...`
        ) == false
      ) return
      let invitation = this.pendingUsers[index];
      const url = `${this.$store.getters.getAPIKey.mainAPI}/userinvitations/${invitation.id}`;
      axios
        .delete(url)
        .then(() => {
          this.pendingUsers.splice(index, 1);
          this.$toast.success(`Invitation deleted successfully`, {
            top: "top",
            duration: 3000,
          });
        })
        .catch(() => {
          this.$toast.error(`Failed to delete invitation`, {
            top: "top",
            duration: 3000,
          });
        });
    },
    async userRoles(id) {
      this.fetchData = true;
      let roleNames = {
        admin: 'Admin', teacher: 'Faculty',
        // student:'Student'
      };
      const url = this.$store.getters.getAPIKey.mainAPI + `/institution/${id}/roles`;
      await axios
        .get(url)
        .then((response) => {
          this.institutionRoles = [];
          this.institutionRoles = [
            { value: null, text: "Please Select a role", disabled: true },
          ];
          const institutionRoles = response.data;
          institutionRoles.forEach((role) => {
            // let roleName = roleNames[role.name] ?? role.name;
            let roleName = roleNames[role.name] ?? null;
            if (roleName) this.institutionRoles.push({ value: role.id, text: roleName });
          });
          this.fetchData = false;
        })
        .catch((error) => {
          error;
        });
    },
    async closeInstitutionInvites() {
      await this.$bvModal.hide("inviteUsersToInstitute");
      await this.$emit("invitationClosed");
    },
  },
};
</script>
