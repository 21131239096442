<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div v-else>
      <b-overlay :show="postCourses" spinner-variant="primary" spinner-type="grow" spinner-small rounded="md">
        <CoursesCloning @cancel="cancel()" @success="copyCompleted()" :to_subgroup_id="subgroupPreSelection"
          v-if="(copyCourseView === true)" :copy_from_suggestion="copy_from" />
        <div v-if="(copyCourseView === false)">
          <div class="createCourse">
            <b-form @submit.prevent="prepareCoursePostData()">
              <h6 class="alert alert-success text-center" v-if="responseErrors.courseCreated">
                {{ responseErrors.courseCreated[0] }}
              </h6>
              <b-form-group>
                <h4 class="text-center text-info font-weight-bolder">
                  Create Course
                </h4>
              </b-form-group>
              <b-form-group label="Si. No.">
                <b-form-input placeholder="Si. No." v-model="courseDetails.si_no"></b-form-input>
              </b-form-group>
              <b-form-group label="Course Name">
                <b-form-input placeholder="Course Name" v-model="courseDetails.name"
                  :class="{ 'is-invalid': responseErrors.name }"></b-form-input>
                <span class="text-danger" v-if="responseErrors.name">{{
                  responseErrors.name[0]
                  }}</span>
              </b-form-group>

              <b-form-group label="Course Code">
                <b-input v-model="courseDetails.code" placeholder="Course Code"
                  :class="{ 'is-invalid': responseErrors.code }"></b-input>
                <span class="text-danger" v-if="responseErrors.code">{{
                  responseErrors.code[0]
                  }}</span>
              </b-form-group>

              <b-form-group label="Course Type">
                <b-form-select :options="courseCategoriesOptions" v-model="courseDetails.course_category_id"
                  ></b-form-select>
              </b-form-group>

              <b-form-group label="Sub Type">
                <b-form-select @change="courseTypeChange()" :options="courseTypesOptions"
                  v-model="courseDetails.course_type_id" :class="{ 'is-invalid': responseErrors.course_type_id }">
                </b-form-select>
                <span class="text-danger" v-if="responseErrors.course_type_id">{{ responseErrors.course_type_id[0]
                  }}</span>
                <span
                  v-if="subgroupType != 'interdepartment_courses_group' && (['Minor', 'Open Elective'].includes(courseTypeObjects[courseDetails.course_type_id]))"
                  class="mt-1 text-danger">Inter-departmental courses (eg:minor, open elective) can be added to
                  {{ getCustomNames.userSubgroup.custom_name != null
                  ? getCustomNames.userSubgroup.custom_name
                  : "Usersubgroup"
                  }}. created by
                  {{ getCustomNames.userGroupAdmin.custom_name != null
                  ? getCustomNames.userGroupAdmin.custom_name
                  : "Usergroup Admin"
                  }}
                  for Inter-departmental courses only.
                </span>

                <span
                  v-if="subgroupType == 'interdepartment_courses_group' && (!['Minor', 'Open Elective'].includes(courseTypeObjects[courseDetails.course_type_id]))"
                  class="mt-1 text-danger">This course type only supports options for interdepartmental courses
                  (eg:minor, open elective). Please refrain from choosing other options.</span>
              </b-form-group>



              <b-form-group v-if="!subgroupPreSelection" :label="getCustomNames.userSubgroup.custom_name != null
                  ? 'Select ' + getCustomNames.userSubgroup.custom_name
                  : 'Select Usersubgroup'
                ">
                <b-form-select v-model="courseDetails.usersubgroup_id" :options="groupDetails"
                  @change="getSubgroupUsers()" :class="{ 'is-invalid': responseErrors.usersubgroup_id }">
                </b-form-select>
                <span class="text-danger" v-if="responseErrors.usersubgroup_id">
                  {{ responseErrors.usersubgroup_id[0] }}
                </span>
              </b-form-group>

              <!-- <b-form-group label="Academic Year">
                <b-form-select :options="$acedemicYears" v-model="courseDetails.academic_year"
                  :class="{ 'is-invalid': responseErrors.academic_year }"></b-form-select>
                <br />
                <span class="text-danger" v-if="responseErrors.academic_year">{{
                responseErrors.academic_year[0]
                }}</span>
              </b-form-group> -->

              <b-form-group label="Batch Starting Year">
                <b-form-select :options="$batchYears" v-model="courseDetails.start_year"
                  :class="{ 'is-invalid': responseErrors.start_year }"></b-form-select>
                <span class="text-danger" v-if="responseErrors.start_year">{{
                  responseErrors.start_year[0]
                  }}</span>
              </b-form-group>

              <b-form-group label="Batch Ending Year">
                <b-form-select :options="$batchYears" v-model="courseDetails.end_year"
                  :class="{ 'is-invalid': responseErrors.end_year }"></b-form-select>
                <span class="text-danger" v-if="responseErrors.end_year">{{
                  responseErrors.end_year[0]
                  }}</span>
              </b-form-group>

              <b-form-group label="" v-if="courseTypeObjects[courseDetails.course_type_id]!='LAB'">
                <b-form-checkbox size="lg" v-model="courseDetails.enable_laboratory">Add Laboratory To This
                  Course</b-form-checkbox>
              </b-form-group>
              <!-- <b-form-group
                label="Choose the components to be added to this course"
              >
                <b-form-checkbox-group
                  :v-model="addedComponents"
                  :options="courseComponents"
                  class="mb-3"
                  value-field="item"
                  text-field="name"
                  size="sm"
                >
                </b-form-checkbox-group>
              </b-form-group> -->

              <b-form-group>
                <div class="enrollFacultyList">
                  <multiselect v-model="selectedFaculty" tag-placeholder="Add this as new tag"
                    placeholder="Choose course instructors" label="name" track-by="code" :options="facultyOptions"
                    :close-on-select="false" :multiple="true" :taggable="true" @tag="addFaculty"></multiselect>
                </div>
              </b-form-group>
              <b-form-group>
                <div class="enrollStudentList">
                  <multiselect v-model="selectedStudents" tag-placeholder="Add this as new tag" :placeholder="StudentOptions.length == 0
                      ? 'Add Students'
                      : 'Enrolled Students List'
                    " label="name" track-by="code" :options="StudentOptions" :close-on-select="false" :multiple="true"
                    :taggable="true" @tag="addStudent"></multiselect>
                </div>
              </b-form-group>
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <span v-if="subgroupType == 'interdepartment_courses_group'">
                    <span v-if="!['Minor', 'Open Elective'].includes(courseTypeObjects[courseDetails.course_type_id])"
                      class="text-danger">Current config. not available</span>
                    <b-button v-else variant="primary" size="md" type="submit"
                      :disabled="courseId == null ? courseBtn.disabled : true">{{ courseBtn.text }}</b-button>
                  </span>
                  <span v-else>
                    <span v-if="['Minor', 'Open Elective'].includes(courseTypeObjects[courseDetails.course_type_id])"
                      class="text-danger">Current config. not available</span>
                    <b-button v-else variant="primary" size="md" type="submit"
                      :disabled="courseId == null ? courseBtn.disabled : true">{{ courseBtn.text }}</b-button>
                  </span>
                  <b-button variant="danger" size="md" @click="cancel()"
                    :disabled="courseId == null ? courseBtn.disabled : true">Cancel</b-button>
                </div>
              </b-form-group>
            </b-form>
          </div>
        </div>
      </b-overlay>

      <div v-if="(copyCourseView === null)">

        <div class="" style="border: 1px solid #17a2b8; padding: 10px; border-radius: 5px;">


          <span class="text ">
            <h5 style="font-size: 12pt !important;">Now, there are two pick and choose options available to add courses
              to
              a semester/class. </h5>
            <ul>
              <li>
                The first option is to directly pick courses from University Resources, which will automatically update
                all the course details of the chosen courses.
                <span style="color: #2929aa; text-decoration: underline; cursor: pointer;"
                  @click="copy_from = 'template'; (copyCourseView = true)">Click here to proceed</span>

              </li>
              <li>
                The second option is to duplicate a course from the previous or current academic year, which will
                include
                all the data already entered, such as course configurations, COs, POs, lesson plans, and more.
                <span style="color: #2929aa; text-decoration: underline; cursor: pointer;"
                  @click="copy_from = 'myinstitute'; (copyCourseView = true)">Click here to proceed</span>

              </li>
            </ul>
          </span>
        </div>

        <div class=" m-2 ">
          <ul>
            To proceed with manually creating a new course
            <span style="color: #2929aa; text-decoration: underline; cursor: pointer;"
              @click="(copyCourseView = false)">Create new</span>
            <br>Recommended only for first time users who
            <li>
              do not find their University Resources in Ezygo.app
            </li>
            <li>has not updated details in previous academic year</li>
          </ul>

        </div>

        <div class="row">
          <b-form-group style="text-align:right;" class="col">
            <b-button variant="danger" @click="cancel()">Cancel</b-button>
          </b-form-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import CoursesCloning from "./CoursesCloning.vue";

export default {
  props: [
    "faculties",
    "getCustomNames",
    "courseTypesOptions",
    "courseCategoriesOptions",
    "subgroupPreSelection",
    "subgroupType"
  ],
  components: { CoursesCloning },
  created() {
    this.$set(this.courseDetails, "si_no", null);
    this.$set(this.courseDetails, "academic_year", null);
    this.$set(this.courseDetails, "usersubgroup_id", null);
    if (this.subgroupPreSelection)
      this.$set(
        this.courseDetails,
        "usersubgroup_id",
        this.subgroupPreSelection
      );
    this.$set(this.courseDetails, "start_year", null);
    this.$set(this.courseDetails, "end_year", null);
    this.$set(this.courseDetails, "course_type_id", null);
    this.$set(this.courseDetails, "course_category_id", null);
    this.$set(this.courseDetails, "enable_laboratory", null);
    this.fillData();
    this.courseTypesOptions.forEach(option => {
      this.courseTypeObjects[option.value] = option.text;
    });
  },
  data() {
    return {
      fetchData: false,
      postCourses: false,
      tabIndex: 0,
      checked: true,
      courseId: null,
      courseDetails: {},
      addedComponents: [],

      courseComponents: [
        { item: "Class", name: "Live Class" },
        { item: "Attendance", name: "Attendance" },
        { item: "Assignment", name: "Assignment" },
        { item: "Assessment", name: "Assessment" },
      ],
      selectedFaculty: [],
      facultyOptions: [],
      selectedStudents: [],
      StudentOptions: [],
      groupDetails: [{ value: null, text: "Subgroup Name(Group Id)" }],
      groupsSubgroups: [],
      faculty: [],
      students: [],
      enrolledUsers: [],
      courseBtn: {
        text: "Submit",
        disabled: false,
      },
      responseErrors: {},
      batchYearOptions: [],
      courseTypeObjects: {},
      courseCategoryObjects: {},
      copyCourseView: null,
      copy_from: '',
    };
  },

  methods: {
    courseTypeChange() {
      if (this.courseTypeObjects[this.courseDetails.course_type_id] == 'Minor' || this.courseTypeObjects[this.courseDetails.course_type_id] == 'Open Elective') {
        this.selectedStudents = [];
      } else {
        if (this.selectedStudents.length < 1) {
          this.enrolledUsers.forEach((student) => {
            if (student.pivot.usersubgrouprole_id == 2) {
              this.selectedStudents.push({
                name: student.first_name + " " + student.last_name,
                id: student.pivot.institution_user_id,
                code: student.id,
              });
            }
          });
        }
      }

    },
    async fillData() {
      this.fetchData = true;
      await this.getInstitutionFaculties();
      if (this.subgroupPreSelection) {
        this.getSubgroupUsers();
        this.groupDetails.push({
          value: `${this.subgroupPreSelection}`,
          text: "Selected",
        });
      } else {
        await this.getGroupList();
      }
      this.getSubgroupUsers();

      // await this.getInstitutionStudents();
      // await this.getCourseTypes();
      this.fetchData = false;
    },

    async prepareCoursePostData() {
      this.courseBtn.text = "Please Wait...";
      this.courseBtn.disabled = true;

      const createCourse = await this.createCourse();
      if (createCourse) {
        this.$toast.success(
          "Course: " + this.courseDetails.name + " " + "successfully created.",
          {
            position: "top",
            duration: 3000,
          }
        );
        if (
          this.selectedFaculty.length == 0 &&
          this.selectedStudents.length == 0
        ) {
          this.$parent.getCourseDetails();
          this.clearResponse();
          this.cancel();
        }

        if (this.selectedFaculty.length !== 0) {
          await this.createIntructors(this.courseId);
        }

        if (this.selectedStudents.length !== 0) {
          await this.createStudents(this.courseId);
        }

        this.clearResponse();
        this.cancel();
        this.$parent.getCourseDetails();
      }
      if (!createCourse) {
        this.clearResponse();
      }
    },
    async createCourse() {
      const url = this.$store.getters.getAPIKey.createCourse;
      let status = null;
      await axios
        .post(url, this.courseDetails)
        .then((response) => {
          let courseId = response.data.id;
          courseId;
          this.courseId = response.data.id;
          status = true;
          // console.log(response);
        })
        .catch((error) => {
          status = false;
          this.responseErrors = error.response.data.errors;

          // console.log(error.response);
        });
      return status;
    },

    async createIntructors(courseId) {
      let mainUrl = this.$store.getters.getAPIKey.createIntructor;
      const url = mainUrl.replace("course_id", courseId);
      const instructors = {
        institution_users: [],
      };
      await this.selectedFaculty.forEach((instructor) => {
        instructors.institution_users.push(instructor.id);
      });

      await axios
        .post(url, instructors)
        .then(() => {
          // console.log(response);
        })
        .catch(() => {
          // console.log(error);
        });
    },
    async createStudents(courseId) {
      let mainUrl = this.$store.getters.getAPIKey.createStudent;
      const url = mainUrl.replace("course_id", courseId);

      const students = {
        institution_users: [],
      };

      await this.selectedStudents.forEach((student) => {
        students.institution_users.push(student.id);
      });

      await axios
        .post(url, students)
        .then(() => {
          // console.log(response);
        })
        .catch(() => {
          // console.log(error);
        });
    },
    addFaculty(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
        id: newTag,
      };
      this.facultyOptions.push(tag);
      this.selectedFaculty.push(tag);
    },
    addStudent(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
        id: newTag,
      };
      this.StudentOptions.push(tag);
      this.selectedStudents.push(tag);
    },
    async getGroupList() {
      let customName;
      if (
        this.getCustomNames.userSubgroup.custom_name != null &&
        this.getCustomNames.userGroup.custom_name == null
      ) {
        customName =
          this.getCustomNames.userSubgroup.custom_name +
          " (" +
          "Usergroup Code" +
          ")";
      } else if (
        this.getCustomNames.userSubgroup.custom_name == null &&
        this.getCustomNames.userGroup.custom_name != null
      ) {
        customName =
          "Usersubgroup" +
          " (" +
          this.getCustomNames.userGroup.custom_name +
          " Code)";
      } else if (
        this.getCustomNames.userSubgroup.custom_name != null &&
        this.getCustomNames.userGroup.custom_name != null
      ) {
        customName =
          this.getCustomNames.userSubgroup.custom_name +
          " (" +
          this.getCustomNames.userGroup.custom_name +
          " Code)";
      } else {
        customName = "Usersubgroup Name(Group Code)";
      }
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/institutionuser/usersubgroups`;
      await axios
        .get(url)
        .then((response) => {
          // console.log(response);

          this.groupDetails = [
            { value: null, text: `${customName}`, disabled: true },
          ];
          this.groupList = response.data;
          let subgroups = response.data;
          subgroups.forEach((subgroup) => {
            this.groupDetails.push({
              value: `${subgroup.id}`,
              text: subgroup.name + "(" + subgroup.usergroup.code + ")",
            });
          });
        })
        .catch(() => {
          // console.log(error);
        });
    },
    async getSubgroupUsers() {
      this.postCourses = true;
      let mainUrl = this.$store.getters.getAPIKey.getEnrollSubgroupUsersList;
      const url = mainUrl.replace(
        "usersubgroup_id",
        this.courseDetails.usersubgroup_id
      );
      // this.StudentOptions = [];
      // await this.getInstitutionStudents();
      await axios
        .get(url)
        .then((response) => {
          this.enrolledUsers = [];
          this.selectedStudents = [];
          this.enrolledUsers = response.data;

          this.students = [];
          this.StudentOptions = [];
          this.students = response.data;

          this.students.forEach((staff) => {
            if (staff.pivot.usersubgrouprole_id == 2) {
              this.StudentOptions.push({
                name: staff.first_name + " " + (staff.last_name || ""),
                id: staff.pivot.institution_user_id,
                code: staff.id,
              });
            }
          });

          this.enrolledUsers.forEach((student) => {
            if (student.pivot.usersubgrouprole_id == 2) {
              this.selectedStudents.push({
                name: student.first_name + " " + student.last_name,
                id: student.pivot.institution_user_id,
                code: student.id,
              });
            }
          });
          this.postCourses = false;
          // console.log(response);
        })
        .catch(() => {
          this.postCourses = false;
          // console.log(error.response);
        });
    },

    async getInstitutionFaculties() {
      this.faculty = [];
      this.faculty = this.faculties;
      await this.faculty.forEach((staff) => {
        this.facultyOptions.push({
          name: staff.first_name + " " + (staff.last_name || "") + `(${staff.email})`,
          id: staff.id,
          code: staff.id,
        });
      });
      await this.facultyOptions.sort((a, b) => a.name.localeCompare(b.name));
    },
    async getInstitutionStudents() {
      const url = this.$store.getters.getAPIKey.instituitionStudents;
      await axios
        .get(url)
        .then((response) => {
          this.students = [];
          this.StudentOptions = [];
          this.students = response.data;

          this.students.forEach((staff) => {
            this.StudentOptions.push({
              name: staff.first_name + " " + (staff.last_name || ""),
              id: staff.id,
              code: staff.id,
            });
          });
          // console.log(response);
        })
        .catch(() => {
          // console.log(error.response);
        });
    },

    clearResponse() {
      this.courseBtn.text = "Submit";
      this.postCourses = false;
      setTimeout(() => {
        this.responseErrors = {};
        this.courseBtn.disabled = false;
      }, 3000);
    },
    cancel() {
      // this.courseDetails = {};
      this.courseId = null;
      this.groupIds = null;
      this.showCreateCourse = false;
      this.selectedFaculty = [];
      this.facultyOptions = [];
      this.selectedStudents = [];
      this.StudentOptions = [];
      this.$parent.addCourse = false;
      this.responseErrors = {};
      this.$emit("courseCreated");
    },
    copyCompleted() {

      this.cancel();
      this.$parent.getCourseDetails();
    },
  },
};
</script>

<style >
@media only screen and (max-width: 600px) and (orientation: portrait) {

  .createCourse form input,
  .createCourse form select {
    height: auto;
  }

  .createCourse form .month-year-display,
  .createCourse form .multiselect__tags {
    height: auto;
  }

  .createCourse form .enrollStudentList .multiselect__tags {
    height: auto;
  }
}
</style>
