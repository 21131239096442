<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="subgroupUsersProfileUpdate p-2 mt-2  border border-primary rounded " v-else>
      <div>
        <b-col class="m-1">
          <h5 style="font-size:1.7rem !important">How To Update!!</h5>
          <p style="font-size:1.2rem !important">
            Follow these steps to update data in a column (e.g., roll number or admission number of all students):
          </p>
          <ol>
            <li>
              <strong style="font-size:1.1rem !important">
                Select the column to update.</strong>
            </li>
            <li>
              <strong style="font-size:1.1rem !important">Optionally, copy and paste the existing data to an Excel
              </strong> file. To do this, copy all text from the data
              input field in Ezygo.app and paste it into a column in an Excel table.
            </li>

            <li>
              <strong style="font-size:1.1rem !important">After making modifications in the Excel table </strong>, copy
              the entire column. To do this, select all cells in the
              desired column, then copy (Ctrl+C).
            </li>
            <li>
              In Ezygo.app, <strong style="font-size:1.1rem !important">paste the Excel column data into the
                data input field</strong>, then click the <strong style="font-size:1.1rem !important">'Update Table'
                button</strong>.
              This will automatically update the data in the Ezygo table.
            </li>
            <li>
              You can now verify the existing data and new data for each student in the Ezygo table, and make any
              changes for specific students if needed.
            </li>
            <li>
              Click the <strong style="font-size:1.1rem !important">'Merge' button to temporarily update</strong> the
              data in the Ezygo table.
            </li>

            <li>
              Repeat the process to update another column (e.g., email address, phone number and so on).
            </li>

            <li>
              Finally, click the <strong style="font-size:1.1rem !important">'Save Changes Permanently'</strong> button
              to finish the process.
            </li>
          </ol>
          <p>If you're still unsure how to update data in a column, please contact Ezygo.app Support(+91 8330869500,
            support@ezygo.app) for assistance.</p>
        </b-col>

        <div class="border border-primary m-4"></div>
        <b-col class="m-1">
          <b-form-group label="Choose the column to be updated" v-slot="{ ariaDescribedby }">
            <b-form-radio-group id="radio-group-1" v-model="selectedColumn" @change="changeDataColumn()"
              :options="columnOptions" :aria-describedby="ariaDescribedby" name="radio-options"></b-form-radio-group>
          </b-form-group>
        </b-col>
        <div class="border border-secondary m-4"></div>
        <b-col class="m-1" v-if="selectedColumn != 'name'">
          <b-row>
            <b-col sm="6">
              <b-form-group label="Paste excel column data here (Data input field):" label-for="textarea-formatter"
                class="mb-0">
                <b-form-textarea :disabled="selectedColumnDatasArray.length > 0" v-model="selectedColumnDatasString"
                  id="textarea-formatter" rows="4" placeholder="Enter your text"></b-form-textarea>
              </b-form-group>
              <span v-if="selectedColumnDatasArray.length > 0" class="text text-danger">Merge changes or change column
                selection to enable this field
                again</span>
              <b-button :disabled="selectedColumnDatasArray.length > 0" variant="success" size="md"
                @click="updateTableFromInputData()">Update Table</b-button>
            </b-col>
          </b-row>
        </b-col>
        <div class="border border-secondary m-4"></div>
        <b-col>
          <table class="table  table-striped table-bordered">
            <thead class="thead-dark">
              <tr>
                <th>#</th>
                <th colspan="2">Name</th>
                <th colspan="2">
                  Gender <br>(m,f,M,F, <br> Male,<br> Female)
                  <span v-if="
                    selectedColumn == 'gender' &&
                    selectedColumnDatasArray.length > 0
                  ">
                    <b-button variant="success" size="md" @click="mergeTableFromInputData()">Merge</b-button>
                  </span>
                </th>
                <th colspan="2">
                  DOB (YYYY-MM-DD)
                  <span v-if="
                    selectedColumn == 'birth_date' &&
                    selectedColumnDatasArray.length > 0
                  ">
                    <b-button variant="success" size="md" @click="mergeTableFromInputData()">Merge</b-button>
                  </span>
                </th>
                <th colspan="2">
                  Email
                  <span v-if="
                    selectedColumn == 'email' &&
                    selectedColumnDatasArray.length > 0
                  ">
                    <b-button variant="success" size="md" @click="mergeTableFromInputData()">Merge</b-button>
                  </span>
                </th>
                <th colspan="2">
                  Phone No.
                  <span v-if="
                    selectedColumn == 'mobile' &&
                    selectedColumnDatasArray.length > 0
                  ">
                    <b-button variant="success" size="md" @click="mergeTableFromInputData()">Merge</b-button>
                  </span>
                </th>
                <th colspan="2">
                  Guardian Email
                  <span v-if="
                    selectedColumn == 'guardian_email' &&
                    selectedColumnDatasArray.length > 0
                  ">
                    <b-button variant="success" size="md" @click="mergeTableFromInputData()">Merge</b-button>
                  </span>
                </th>
                <th colspan="2">
                  Guardian Phone No.
                  <span v-if="
                    selectedColumn == 'guardian_mobile' &&
                    selectedColumnDatasArray.length > 0
                  ">
                    <b-button variant="success" size="md" @click="mergeTableFromInputData()">Merge</b-button>
                  </span>
                </th>
                <th colspan="2">
                  Roll No.
                  <span v-if="
                    selectedColumn == 'roll_number' &&
                    selectedColumnDatasArray.length > 0
                  ">
                    <b-button variant="success" size="md" @click="mergeTableFromInputData()">Merge</b-button>
                  </span>
                </th>
                <th colspan="2">
                  Admission No.
                  <span v-if="
                    selectedColumn == 'admission_no' &&
                    selectedColumnDatasArray.length > 0
                  ">
                    <b-button variant="success" size="md" @click="mergeTableFromInputData()">Merge</b-button>
                  </span>
                </th>
                <th colspan="2">
                  Registration No.
                  <span v-if="
                    selectedColumn == 'register_no' &&
                    selectedColumnDatasArray.length > 0
                  ">
                    <b-button variant="success" size="md" @click="mergeTableFromInputData()">Merge</b-button>
                  </span>
                </th>
                <th colspan="2">
                  Institution Last Attended(HSE)
                  <span v-if="
                    selectedColumn == 'last_institution' &&
                    selectedColumnDatasArray.length > 0
                  ">
                    <b-button variant="success" size="md" @click="mergeTableFromInputData()">Merge</b-button>
                  </span>
                </th>
                <th colspan="2">
                  Address for Communication
                  <span v-if="
                    selectedColumn == 'contact_address' &&
                    selectedColumnDatasArray.length > 0
                  ">
                    <b-button variant="success" size="md" @click="mergeTableFromInputData()">Merge</b-button>
                  </span>
                </th>

                <th colspan="2">
                  District of institution last attended
                  <span v-if="
                    selectedColumn == 'district_of_institution_last_attended' &&
                    selectedColumnDatasArray.length > 0
                  ">
                    <b-button variant="success" size="md" @click="mergeTableFromInputData()">Merge</b-button>
                  </span>
                </th>


                <th colspan="2">
                  Code of Institution last attended
                  <span v-if="
                    selectedColumn == 'code_of_institution_last_attended' &&
                    selectedColumnDatasArray.length > 0
                  ">
                    <b-button variant="success" size="md" @click="mergeTableFromInputData()">Merge</b-button>
                  </span>
                </th>

                <th colspan="2">
                  AAdhaar card no
                  <span v-if="
                    selectedColumn == 'aadhaar_card_no' &&
                    selectedColumnDatasArray.length > 0
                  ">
                    <b-button variant="success" size="md" @click="mergeTableFromInputData()">Merge</b-button>
                  </span>
                </th>

                
              </tr>
            </thead>
            <tbody class="profile-data-table">
              <tr v-for="(profile, reindex) in institutionUsersProfiles" :key="reindex">
                <td>{{ reindex + 1 }}</td>
                <td>
                  <b-form-input :style="{
                    width: sLength(profile.first_name) * 7 + 'px',
                    'min-width': '50px', 'border-color': 'gray',
                  }" v-if="selectedColumn == 'name'" :placeholder="selectedColumn"
                    v-model="profile.first_name"></b-form-input>
                  <span v-else>{{ profile.first_name }} </span>
                </td>
                <td>
                  <b-form-input :style="{
                    width: sLength(profile.first_name) * 7 + 'px',
                    'min-width': '50px', 'border-color': 'gray',
                  }" v-if="selectedColumn == 'name'" :placeholder="selectedColumn"
                    v-model="profile.last_name"></b-form-input>
                  <span v-else>{{ profile.last_name }} </span>
                </td>
                <td>
                  <span :style="
                    selectedColumn == 'gender' ? 'white-space: nowrap;' : ''
                  ">
                    {{ profile.gender }}
                  </span>
                </td>
                <td>
                  <b-form-input :style="{
                    width:
                      sLength(selectedColumnDatasArray[reindex]) * 7 + 'px',
                    'min-width': '50px',
                    'border-color':
                      selectedColumnDatasArray[reindex] != profile.gender
                        ? '#ffa300 !important'
                        : 'gray',
                  }"
                    v-if="selectedColumn == 'gender' && selectedColumnDatasArray.length >= reindex && selectedColumnDatasArray.length > 0"
                    :placeholder="selectedColumn" v-model="selectedColumnDatasArray[reindex]"></b-form-input>
                </td>
                <td>
                  <span :style="
                    selectedColumn == 'birth_date'
                      ? 'white-space: nowrap;'
                      : ''
                  ">
                    {{ profile.birth_date }}
                  </span>
                </td>
                <td>
                  <b-form-input :style="{
                    width:
                      sLength(selectedColumnDatasArray[reindex]) * 7 + 'px',
                    'min-width': '50px',
                    'border-color':
                      selectedColumnDatasArray[reindex] != profile.birth_date
                        ? '#ffa300 !important'
                        : 'gray',
                  }"
                    v-if="selectedColumn == 'birth_date' && selectedColumnDatasArray.length >= reindex && selectedColumnDatasArray.length > 0"
                    :placeholder="selectedColumn" v-model="selectedColumnDatasArray[reindex]"></b-form-input>
                </td>
                <td>
                  <span :style="
                    selectedColumn == 'email' ? 'white-space: nowrap;' : ''
                  ">
                    {{ profile.email }}
                  </span>
                </td>
                <td>
                  <b-form-input v-if="
                  selectedColumn == 'email' &&
                  selectedColumnDatasArray.length >= reindex &&
                  selectedColumnDatasArray.length > 0" :style="{
    width: sLength(selectedColumnDatasArray[reindex]) * 7 + 'px', 'min-width': '50px', 'border-color':
      selectedColumnDatasArray[reindex] != profile.email
        ? '#ffa300 !important'
        : 'gray'
  }" :placeholder="selectedColumn" v-model="selectedColumnDatasArray[reindex]"></b-form-input>
                </td>
                <td>
                  <span :style="
                    selectedColumn == 'mobile' ? 'white-space: nowrap;' : ''
                  ">
                    {{ profile.mobile }}
                  </span>
                </td>
                <td>
                  <b-form-input :style="{
                    width:
                      sLength(selectedColumnDatasArray[reindex]) * 7 + 'px',
                    'min-width': '50px',
                    'border-color':
                      selectedColumnDatasArray[reindex] != profile.mobile
                        ? '#ffa300 !important'
                        : 'gray',
                  }" v-if="
selectedColumn == 'mobile' &&
selectedColumnDatasArray.length >= reindex &&
selectedColumnDatasArray.length > 0" :placeholder="selectedColumn"
                    v-model="selectedColumnDatasArray[reindex]"></b-form-input>
                </td>
                <td>
                  <span :style="
                    selectedColumn == 'guardian_email'
                      ? 'white-space: nowrap;'
                      : ''
                  ">
                    {{ profile.guardian_email }}
                  </span>
                </td>
                <td>
                  <b-form-input :style="{
                    width:
                      sLength(selectedColumnDatasArray[reindex]) * 7 + 'px',
                    'min-width': '50px',
                    'border-color':
                      selectedColumnDatasArray[reindex] !=
                        profile.guardian_email
                        ? '#ffa300 !important'
                        : 'gray',
                  }" v-if="
selectedColumn == 'guardian_email' &&
selectedColumnDatasArray.length >= reindex &&
selectedColumnDatasArray.length > 0" :placeholder="selectedColumn"
                    v-model="selectedColumnDatasArray[reindex]"></b-form-input>
                </td>
                <td>
                  <span :style="
                    selectedColumn == 'guardian_mobile'
                      ? 'white-space: nowrap;'
                      : ''
                  ">
                    {{ profile.guardian_mobile }}
                  </span>
                </td>
                <td>
                  <b-form-input :style="{
                    width:
                      sLength(selectedColumnDatasArray[reindex]) * 7 + 'px',
                    'min-width': '50px',
                    'border-color':
                      selectedColumnDatasArray[reindex] !=
                        profile.guardian_mobile
                        ? '#ffa300 !important'
                        : 'gray',
                  }" v-if="
  selectedColumn == 'guardian_mobile' &&
  selectedColumnDatasArray.length >= reindex &&
  selectedColumnDatasArray.length > 0
" :placeholder="selectedColumn" v-model="selectedColumnDatasArray[reindex]"></b-form-input>
                </td>
                <td>
                  <span :style="
                    selectedColumn == 'roll_number'
                      ? 'white-space: nowrap;'
                      : ''
                  ">
                    {{ profile.roll_number }}
                  </span>
                </td>
                <td>
                  <b-form-input :style="{
                    width:
                      sLength(selectedColumnDatasArray[reindex]) * 7 + 'px',
                    'min-width': '50px',
                    'border-color':
                      selectedColumnDatasArray[reindex] != profile.roll_number
                        ? '#ffa300 !important'
                        : 'gray',
                  }" v-if="
  selectedColumn == 'roll_number' &&
  selectedColumnDatasArray.length >= reindex &&
  selectedColumnDatasArray.length > 0
" :placeholder="selectedColumn" v-model="selectedColumnDatasArray[reindex]"></b-form-input>
                </td>
                <td>
                  <span :style="
                    selectedColumn == 'admission_no'
                      ? 'white-space: nowrap;'
                      : ''
                  ">
                    {{ profile.admission_no }}
                  </span>
                </td>
                <td>
                  <b-form-input :style="{
                    width:
                      sLength(selectedColumnDatasArray[reindex]) * 7 + 'px',
                    'min-width': '50px',
                    'border-color':
                      selectedColumnDatasArray[reindex] !=
                        profile.admission_no
                        ? '#ffa300 !important'
                        : 'gray',
                  }" v-if="
  selectedColumn == 'admission_no' &&
  selectedColumnDatasArray.length >= reindex &&
  selectedColumnDatasArray.length > 0
" :placeholder="selectedColumn" v-model="selectedColumnDatasArray[reindex]"></b-form-input>
                </td>
                <td>
                  <span :style="
                    selectedColumn == 'register_no'
                      ? 'white-space: nowrap;'
                      : ''
                  ">
                    {{ profile.register_no }}
                  </span>
                </td>
                <td>
                  <b-form-input :style="{
                    width:
                      sLength(selectedColumnDatasArray[reindex]) * 7 + 'px',
                    'min-width': '50px',
                    'border-color':
                      selectedColumnDatasArray[reindex] != profile.register_no
                        ? '#ffa300 !important'
                        : 'gray',
                  }" v-if="
  selectedColumn == 'register_no' &&
  selectedColumnDatasArray.length >= reindex &&
  selectedColumnDatasArray.length > 0
" :placeholder="selectedColumn" v-model="selectedColumnDatasArray[reindex]"></b-form-input>
                </td>
                <td>
                  <span :style="
                    selectedColumn == 'last_institution'
                      ? 'white-space: nowrap;'
                      : ''
                  ">
                    {{ profile.last_institution }}
                  </span>
                </td>
                <td>
                  <b-form-input :style="{
                    width:
                      sLength(selectedColumnDatasArray[reindex]) * 7 + 'px',
                    'min-width': '50px',
                    'border-color':
                      selectedColumnDatasArray[reindex] !=
                        profile.last_institution
                        ? '#ffa300 !important'
                        : 'gray',
                  }" v-if="
  selectedColumn == 'last_institution' &&
  selectedColumnDatasArray.length >= reindex &&
  selectedColumnDatasArray.length > 0
" :placeholder="selectedColumn" v-model="selectedColumnDatasArray[reindex]"></b-form-input>
                </td>
                <td>
                  <span :style="
                    selectedColumn == 'contact_address'
                      ? 'white-space: nowrap;'
                      : ''
                  ">
                    {{ profile.contact_address }}
                  </span>
                </td>
                <td>
                  <b-form-input :style="{
                    width:
                      sLength(selectedColumnDatasArray[reindex]) * 7 + 'px',
                    'min-width': '50px',
                    'border-color':
                      selectedColumnDatasArray[reindex] !=
                        profile.contact_address
                        ? '#ffa300 !important'
                        : 'gray',
                  }" v-if="
  selectedColumn == 'contact_address' &&
  selectedColumnDatasArray.length >= reindex &&
  selectedColumnDatasArray.length > 0
" :placeholder="selectedColumn" v-model="selectedColumnDatasArray[reindex]"></b-form-input>
                </td>

                <td>
                  <span :style="
                    selectedColumn == 'district_of_institution_last_attended'
                      ? 'white-space: nowrap;'
                      : ''
                  ">
                    {{ profile.district_of_institution_last_attended }}
                  </span>
                </td>
                <td>
                  <b-form-input :style="{
                    width:
                      sLength(selectedColumnDatasArray[reindex]) * 7 + 'px',
                    'min-width': '50px',
                    'border-color':
                      selectedColumnDatasArray[reindex] !=
                        profile.district_of_institution_last_attended
                        ? '#ffa300 !important'
                        : 'gray',
                  }" v-if="
  selectedColumn == 'district_of_institution_last_attended' &&
  selectedColumnDatasArray.length >= reindex &&
  selectedColumnDatasArray.length > 0
" :placeholder="selectedColumn" v-model="selectedColumnDatasArray[reindex]"></b-form-input>
                </td>

                <td>
                  <span :style="
                    selectedColumn == 'code_of_institution_last_attended'
                      ? 'white-space: nowrap;'
                      : ''
                  ">
                    {{ profile.code_of_institution_last_attended }}
                  </span>
                </td>
                <td>
                  <b-form-input :style="{
                    width:
                      sLength(selectedColumnDatasArray[reindex]) * 7 + 'px',
                    'min-width': '50px',
                    'border-color':
                      selectedColumnDatasArray[reindex] !=
                        profile.code_of_institution_last_attended
                        ? '#ffa300 !important'
                        : 'gray',
                  }" v-if="
  selectedColumn == 'code_of_institution_last_attended' &&
  selectedColumnDatasArray.length >= reindex &&
  selectedColumnDatasArray.length > 0
" :placeholder="selectedColumn" v-model="selectedColumnDatasArray[reindex]"></b-form-input>
                </td>

                <td>
                  <span :style="
                    selectedColumn == 'aadhaar_card_no'
                      ? 'white-space: nowrap;'
                      : ''
                  ">
                    {{ profile.aadhaar_card_no }}
                  </span>
                </td>
                <td>
                  <b-form-input :style="{
                    width:
                      sLength(selectedColumnDatasArray[reindex]) * 7 + 'px',
                    'min-width': '50px',
                    'border-color':
                      selectedColumnDatasArray[reindex] !=
                        profile.aadhaar_card_no
                        ? '#ffa300 !important'
                        : 'gray',
                  }" v-if="
  selectedColumn == 'aadhaar_card_no' &&
  selectedColumnDatasArray.length >= reindex &&
  selectedColumnDatasArray.length > 0
" :placeholder="selectedColumn" v-model="selectedColumnDatasArray[reindex]"></b-form-input>
                </td>

              </tr>
            </tbody>
          </table>
        </b-col>
        <b-col class="my-3">
          <div class="editOptions d-flex justify-content-between">
            <span v-if="selectedColumnDatasArray.length > 0" class="text text-danger">Click 'merge' button before final
              submission to take effect the
              changes</span>
            <b-button variant="primary" size="md" :disabled="editProfile.disabled" @click="updatePorfiles()">{{
                editProfile.text
            }}</b-button>
            <b-button variant="danger" size="md" :disabled="editProfile.disabled" @click="$emit('cancelProfile')">Cancel
            </b-button>
          </div>
        </b-col>
      </div>
    </div>
  </div>
</template>

<script>
// import UserProfileQuesAns from "../../../institution_users/UserProfileQuesAns.vue"
// UserProfileQuesAns

export default {
  props: ["groupInfo", "institutionUserIds", "sortByOrder"],
  async created() {
    this.fetchData = await true;
    await this.getUserProfiles();
  },
  components: {
    // UserProfileQuesAns
  },
  data() {
    return {
      fetchData: false,
      institutionUsersProfiles: [],
      editProfile: {
        text: "Save changes permanently",
        disabled: false,
      },
      columnOptions: [
        { text: "Name", value: "name" },
        { text: "Gender", value: "gender" },
        { text: "DOB (YYYY-MM-DD)", value: "birth_date" },
        { text: "Email", value: "email" },
        { text: "Phone No", value: "mobile" },
        { text: "Guardian Email", value: "guardian_email" },
        { text: "Guardian Phone No", value: "guardian_mobile" },
        { text: "Roll No", value: "roll_number" },
        { text: "Admission No", value: "admission_no" },
        { text: "Registration No", value: "register_no" },
        { text: "Institution Last Attended(HSE)", value: "last_institution" },
        { text: "Address for Communication", value: "contact_address" },
        { text: "District of institution last attended", value: "district_of_institution_last_attended" },
        { text: "Code of Institution last attended", value: "code_of_institution_last_attended" },
        { text: "AAdhaar card no", value: "aadhaar_card_no" },
      ],
      selectedColumn: null,
      selectedColumnDatasString: "",
      selectedColumnDatasArray: [],
      // responseErrors: {},
      // responseSuccess: {},
      // genderOptions: [
      //   { value: "male", text: "Male" },
      //   { value: "female", text: "Female" },
      //   { value: "other", text: "Other" },
      // ],
      // genderSelection: {},
    };
  },
  methods: {
    sLength(string) { return string ? string.length : 1; },
    changeDataColumn() {
      this.selectedColumnDatasString = "";
      this.selectedColumnDatasArray = [];
      this.institutionUsersProfiles.forEach((profile) => {
        this.selectedColumnDatasString = `${this.selectedColumnDatasString
          }${profile[this.selectedColumn] ?? ""}\n`;
      });
    },
    async updateTableFromInputData() {
      let splitData = await this.selectedColumnDatasString.split(/\r?\n/);
      await splitData.forEach((data) => {
        this.selectedColumnDatasArray.push(data);
      });
    },
    mergeTableFromInputData() {
      for (
        let index = 0;
        index < this.institutionUsersProfiles.length;
        index++
      ) {
        if (this.selectedColumnDatasArray.length >= index)
          if (this.selectedColumn == 'gender') {
            if (['male', 'm', 'M', 'Male'].includes(this.selectedColumnDatasArray[index])) this.institutionUsersProfiles[index][
              this.selectedColumn
            ] = 'male';
            if (['female', 'f', 'F', 'Female'].includes(this.selectedColumnDatasArray[index])) this.institutionUsersProfiles[index][
              this.selectedColumn
            ] = 'female';
          } else {
            this.institutionUsersProfiles[index][
              this.selectedColumn
            ] = this.selectedColumnDatasArray[index];
          }
      }

      this.selectedColumn = null;
      this.selectedColumnDatasString = "";
      this.selectedColumnDatasArray = [];
    },
    async updatePorfiles() {
      this.editProfile.text = "Please Wait...";
      this.editProfile.disabled = true;
      let postData = [];

      for (let i in this.institutionUsersProfiles) {
        postData.push({
          id: this.institutionUsersProfiles[i].id,
          first_name: this.institutionUsersProfiles[i].first_name,
          last_name: this.institutionUsersProfiles[i].last_name,
          email: this.institutionUsersProfiles[i].email,
          gender: this.institutionUsersProfiles[i].gender,
          mobile: this.institutionUsersProfiles[i].mobile,
          birth_date: this.institutionUsersProfiles[i].birth_date,
          guardian_mobile: this.institutionUsersProfiles[i].guardian_mobile,
          guardian_email: this.institutionUsersProfiles[i].guardian_email,
          admission_no: this.institutionUsersProfiles[i].admission_no,
          contact_address: this.institutionUsersProfiles[i].contact_address,
          last_institution: this.institutionUsersProfiles[i].last_institution,
          roll_number: this.institutionUsersProfiles[i].roll_number,
          register_no: this.institutionUsersProfiles[i].register_no,
          district_of_institution_last_attended: this.institutionUsersProfiles[i].district_of_institution_last_attended,
          code_of_institution_last_attended: this.institutionUsersProfiles[i].code_of_institution_last_attended,
          aadhaar_card_no: this.institutionUsersProfiles[i].aadhaar_card_no,
        });
      }

      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usersubgroups/${this.groupInfo.id}/users/update`;
      await this.$axios
        .post(url, { usersubgroupusers: postData })
        .then(() => {
          this.$toast.success("Students data updated successfully", {
            position: "top",
            duration: 3000,
          });
        })
        .catch(() => {
          this.$toast.error(
            "Failed to update students data, please try again.",
            {
              position: "top",
              duration: 3000,
            }
          );
        });

      this.editProfile.disabled = false;
      this.editProfile.text = "Save changes permanently";
    },

    async getUserProfiles() {
      this.fetchData = true;
      let userList = this.$store.getters.getAPIKey.getEnrollSubgroupUsersList;
      let url = userList.replace("usersubgroup_id", this.groupInfo.id);
      url = url + `?allProfileFields=1&`;
      if (this.sortByOrder != "") url = url + `sortby=${this.sortByOrder}&`;
      await this.$axios
        .get(url)
        .then((response) => {
          let allSubgroupUsers = response.data;
          this.institutionUsersProfiles = allSubgroupUsers.filter(
            (students) => {
              return (
                students.pivot.usersubgrouprole_id == 2 &&
                this.institutionUserIds.includes(
                  students.pivot.institution_user_id
                )
              );
            }
          );
        })
        .catch(() => {
          this.$toast.error("Failed to load students data, please try again.", {
            position: "top",
            duration: 3000,
          });
          this.fetchData = false;
        });
      this.fetchData = false;
    },
    // clearResponse() {
    //   this.editProfile.text = "Save changes permanently";
    //   setTimeout(() => {
    //     if (this.responseSuccess["created"]) {
    //       this.$emit("cancelProfile");
    //       this.responseSuccess = {};
    //     }
    //     this.responseErrors = {};
    //     this.editProfile.disabled = false;
    //   }, 3000);
    // },
  },
};
</script>

<style >
.profile-data-table input {
  padding: 0px;
  height: auto;
}

.data-change {
  border-color: "#ffa300" !important;
}
</style>
