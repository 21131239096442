<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="sessions" v-else>
      <h5>Workdays</h5>
      <div class="mt-2" ref="workdayForm"></div>
      <b-form class="mt-4" v-if="addWorkday" @submit.prevent="addWorkdays()">
        <b-form-group>
          <span
            class="alert alert-success text-center"
            v-if="responseErrors.workdayCreated"
            >{{ responseErrors.workdayCreated[0] }}</span
          >
        </b-form-group>
        <b-form-group>
          <div>
            <div>
              <b-form-select
                class="ml-4"
                v-model="workdays.view_order"
                :value="
                  !workdays.view_order
                    ? (workdays.view_order = null)
                    : workdays.view_order
                "
                :options="viewOrderList"
                :class="{ 'is-invalid': responseErrors.view_order }"
              >
              </b-form-select>
              <span class="text-danger" v-if="responseErrors.view_order">{{
                responseErrors.view_order[0]
              }}</span>
            </div>
          </div>
        </b-form-group>

        <b-form-group>
          <b-button
            type="submit"
            variant="primary"
            size="md"
            :disabled="workdayBtn.disabled"
            >{{ workdayBtn.text }}</b-button
          >
          <b-button
            variant="danger"
            size="md"
            class="ml-3"
            :disabled="workdayBtn.disabled"
            @click="cancel()"
            >Cancel</b-button
          >
        </b-form-group>
      </b-form>
      <div v-if="workdaysList.length != undefined && !addWorkday">
        <b-list-group id="workdaysList">
          <b-list-group-item
            v-for="(workday, index) in workdaysList"
            :key="index"
            class="d-flex align-items-baseline"
            ><p>{{ workday.name }}</p>
            <span
              @click="getSpecificWorkday(workday.id)"
              class="btn m-0 p-0 ml-auto"
              ><i class="far fa-edit ml-2"></i
            ></span>
            <span
              class="btn m-0 p-0"
              @click="getSpecificWorkday(workday.id, { hide: true })"
              ><i
                :class="
                  workday.view_order == '0'
                    ? 'far fa-eye-slash ml-3'
                    : 'far fa-eye ml-2'
                "
              ></i
            ></span>
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  created() {
    this.getWorkdays();
  },
  data() {
    return {
      fetchData: false,
      workdayId: "",
      workdays: {},
      viewOrderList: [],
      addWorkday: false,
      workdaysList: [],
      workdayBtn: {
        text: "Submit",
        disabled: false,
      },
      responseErrors: {},
    };
  },
  methods: {
    addWorkdays() {
      this.workdayBtn.text = "Please wait...";
      this.workdayBtn.disabled = true;
      if (this.workdayId != "") {
        this.updateWorkday();
      } else {
        const url = this.$store.getters.getAPIKey.createworkdays;
        axios
          .post(url, this.workdays)
          .then((response) => {
            this.responseErrors = {
              workdayCreated: [
                this.workdays.name + " is successfully created.",
              ],
            };
            this.getWorkdays();
            this.clearResponse();
            response;
          })
          .catch((error) => {
            this.responseErrors = error.response.data.errors;
            this.clearResponse();
            error;
          });
      }
    },
    getWorkdays() {
      this.fetchData = true;
      const url = this.$store.getters.getAPIKey.createworkdays;
      axios
        .get(url)
        .then((response) => {
          this.workdaysList = [];
          this.workdaysList = response.data;
          this.viewOrderList = [{ value: null, text: "Select Order" }];

          if (this.workdaysList.length == 0) {
            this.viewOrderList.push({ value: "1", text: 1 });
          } else {
            for (let i = 1; i < this.workdaysList.length; i++) {
              this.viewOrderList.push({ value: i.toString(), text: i });
            }
          }

          this.fetchData = false;
          response;
        })
        .catch((error) => {
          error;
        });
    },
    getSpecificWorkday(workday_id, hide) {
      if (hide) {
        const url =
          this.$store.getters.getAPIKey.createworkdays + `/${workday_id}`;
        axios
          .get(url)
          .then((response) => {
            this.addWorkday = true;
            this.workdayId = response.data.id;
            this.workdays.name = response.data.name;
            this.workdays.view_order = "0";
            this.updateWorkday();
            response;
          })
          .catch((error) => {
            error;
          });
      } else {
        this.$smoothScroll({
          scrollTo: this.$refs.workdayForm,
          duration: 1000,
          offset: -50,
        });
        const url =
          this.$store.getters.getAPIKey.createworkdays + `/${workday_id}`;
        axios
          .get(url)
          .then((response) => {
            this.addWorkday = true;
            this.workdayId = response.data.id;
            this.workdays.name = response.data.name;
            response;
          })
          .catch((error) => {
            error;
          });
      }
    },
    updateWorkday() {
      this.workdayBtn.text = "Updating...";
      this.workdayBtn.disabled = true;
      const url =
        this.$store.getters.getAPIKey.createworkdays + `/${this.workdayId}`;

      axios
        .put(url, this.workdays)
        .then((response) => {
          this.responseErrors = {
            workdayCreated: [this.workdays.name + " is successfully updated."],
          };
          this.workdayId = "";
          this.getWorkdays();
          this.clearResponse();
          response;
        })
        .catch((error) => {
          this.responseErrors = error.response.data.errors;
          this.clearResponse();
          error;
        });
    },

    cancel() {
      this.addWorkday = false;
      this.workdays.name = "";
      this.workdays.time_from = "";
      this.workdays.time_to = "";
      this.workdays.view_order = null;
    },
    clearResponse() {
      setTimeout(() => {
        if (this.responseErrors.workdayCreated) {
          this.addWorkday = false;
          this.workdays.name = "";
          this.workdays.time_from = "";
          this.workdays.time_to = "";
          this.workdays.view_order = null;
        }
        this.responseErrors = {};
        this.workdayBtn.text = "Submit";
        this.workdayBtn.disabled = false;
      }, 3000);
    },
  },
};
</script>

<style >
#workdaysList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

#workdaysList .list-group-item {
  width: 250px;
}
@media only screen and (max-width: 600px) and (orientation: portrait) {
  #workdaysList .list-group-item {
    width: 100%;
  }
}
</style>
