<template>
  <div>
    <div class="institutionMobileView w-100 text-center">
      <b-button-group class="w-100" vertical>
        <b-button
          variant="primary"
          v-b-toggle.sessions
          size="md"
          block
          class="w-100"
          >Sessions<i class="fas fa-caret-down"></i
        ></b-button>
        <b-collapse id="sessions" class="mt-2">
          <institution-sessions
            :selectedInstitute="selectedInstitute"
          ></institution-sessions>
        </b-collapse>

        <b-button
          variant="primary"
          v-b-toggle.workdays
          size="md"
          block
          class="w-100"
          >Wordays<i class="fas fa-caret-down"></i
        ></b-button>
        <b-collapse id="workdays" class="mt-2">
          <institution-workdays
            :selectedInstitute="selectedInstitute"
          ></institution-workdays>
        </b-collapse>

        <b-button
          variant="primary"
          v-b-toggle.attendence
          size="md"
          block
          class="w-100"
          >Attendance<i class="fas fa-caret-down"></i
        ></b-button>
        <b-collapse id="attendence" class="mt-2">
          <institution-attendence
            :selectedInstitute="selectedInstitute"
          ></institution-attendence>
        </b-collapse>

        <b-button
          variant="primary"
          v-b-toggle.delivery
          size="md"
          block
          class="w-100"
          >Delivery<i class="fas fa-caret-down"></i
        ></b-button>
        <b-collapse id="delivery" class="mt-2">
          <institution-delivery
            :selectedInstitute="selectedInstitute"
          ></institution-delivery>
        </b-collapse>
      </b-button-group>
    </div>
  </div>
</template>

<script>
import InstitutionSettingsAttendence from "./InstitutionSettingsAttendence.vue";
import InstitutionSettingsWorkdays from "./InstitutionSettingsWorkdays.vue";
import InstitutionSettingsSessions from "./InstitutionSettingsSessions.vue";
import InstitutionSettingsDelivery from "./InstitutionSettingsDelivery.vue";
export default {
  props: ["selectedInstitute"],
  components: {
    institutionAttendence: InstitutionSettingsAttendence,
    institutionWorkdays: InstitutionSettingsWorkdays,
    institutionSessions: InstitutionSettingsSessions,
    institutionDelivery: InstitutionSettingsDelivery,
  },
};
</script>

<style ></style>
