<template>
  <div>
    <b-overlay
      :show="loadTeachers"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="md"
    >
      <div class="d-flex" style="height: 200px">
        <div class="courseNames w-50">
          <b-form-select
            :options="subgroupForProgramExitSurvey"
            v-model="subgroupId"
            @change="getsubgroupStudents()"
            :disabled="disableChange"
          ></b-form-select>
        </div>
        <div
          class="courseUsers w-50"
          v-if="subgroupUsers.length != 0"
          style="overflow-y: scroll"
        >
          <div class="d-flex flex-wrap">
            <p
              v-for="(student, studentIndex) in subgroupUsers"
              :key="studentIndex"
              class="bg-success p-1 text-white rounded ml-2"
              style="font-size: 12px; font-weight: 600"
            >
              {{ student.first_name || " " }} {{ student.last_name || " " }}
            </p>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
export default {
  props: [
    "subgroupForProgramExitSurvey",
    "surveyId",
    "survey",
    "disableChange",
  ],
  async created() {
    if (this.survey.id && this.survey.usersubgroup_id) {
      this.subgroupId = await this.survey.usersubgroup_id;
      await this.getsubgroupStudents();
    }
  },
  data() {
    return {
      loadTeachers: false,
      subgroupId: null,
      subgroupUsers: [],
    };
  },
  methods: {
    async getsubgroupStudents() {
      this.loadTeachers = true;
      await this.subgroupId;
      this.subgroupUsers = [];
      await this.$emit("setUserSubgroupId", this.subgroupId);
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usersubgroup/${this.subgroupId}/users`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.subgroupUsers = response.data;
          this.subgroupUsers = this.subgroupUsers.filter((subgroup) => {
            return subgroup.pivot.usersubgrouprole_id == 2;
          });
          this.loadTeachers = false;
          response;
        })
        .catch((error) => {
          this.loadTeachers = false;
          error;
        });
    },
  },
};
</script>
