<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="attainmentCO" v-else>
      <b-row>
        <b-col md="12">
          <div class="viewOrConfig mb-3">
            <b-button size="sm" :variant="config ? 'success' : 'outline-primary'" class="font-weight-bolder"
              disabled>Configured</b-button>
            <!-- <b-button
              size="sm"
              disabled
              :variant="!config ? 'success' : 'outline-primary'"
              class="ml-4 font-weight-bolder"
              >View Data</b-button
            > -->
          </div>

          <div class="table-responsive" v-if="config">
            <table class="table table-bordered table-striped">
              <thead class="thead-dark">
                <tr>
                  <th>
                    <b-form-checkbox @change="selectAllData" v-model="selectAll">{{
                        selectAll ? "Unselect All" : "Select All"
                    }}</b-form-checkbox>
                  </th>
                  <th>Outcome</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(co, coIndex) in coOptions" :key="coIndex">
                  <td class="align-middle">
                    <b-form-checkbox v-model="selectedCos" :value="co.id"></b-form-checkbox>
                  </td>
                  <td class="align-middle">
                    {{ co.code }}
                  </td>
                  <td class="align-middle">
                    {{ co.description }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- <div class="table-responsive" v-else>
            <table class="table table-bordered table-striped">
              <thead class="thead-dark">
                <tr>
                  <th>Outcome</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(co, coIndex) in cosObject" :key="coIndex">
                  <td class="align-middle">{{ co.code }}</td>
                  <td class="align-middle">{{ co.outcome }}</td>
                </tr>
              </tbody>
            </table>
          </div> -->
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  props: ["courseReport"],
  async created() {
    this.fetchData = true;
    await this.getAttainmentConfigration();
    this.fetchData = false;
  },

  data() {
    return {
      fetchData: false,
      coOptions: [],
      selectedCos: [],
      cosObject: {},
      config: true,
      selectAll: true,
    };
  },
  methods: {
    selectAllData(status) {
      this.selectedCos = [];
      if (status) {
        this.coOptions.forEach((co) => {
          this.selectedCos.push(co.id);
        });
      } else {
        this.selectedCos = [];
      }
    },
    // async getAttainmentData() {
    //   const url = this.$store.getters.getAPIKey.mainAPI + `/coattainment/getdata`;
    //   await this.$axios
    //     .post(url, {
    //       course: this.courseReport.course_id,
    //       step: "courseoutcome",
    //     })
    //     .then((response) => {
    //       this.cosObject = response.data;
    //     });
    // },
    async getAttainmentConfigration() {
      const url = this.$store.getters.getAPIKey.mainAPI + `/coattainment/getconfig`;
      await this.$axios
        .post(url, {
          course: this.courseReport.course_id,
          step: "courseoutcome",
        })
        .then((response) => {
          this.coOptions = [];
          let outcomes = response.data.course_outcomes;
          outcomes.forEach((co) => {
            this.coOptions.push({
              id: co.id,
              code: co.code,
              description: co.outcome,
            });
          });
          this.selectedCos = response.data.selected_cos;
        });
    },
    async setAttainmentConfigration() {
      const url = this.$store.getters.getAPIKey.mainAPI + `/coattainment/setconfig`;
      await this.$axios
        .post(url, {
          course: this.courseReport.course_id,
          step: "courseoutcome",
          course_outcomes: this.selectedCos,
        })
        .then(() => { });
    },
    // async getAttainmentCourseOutcomes() {
    //   const url = this.$store.getters.getAPIKey.mainAPI + `/coattainment/getdata`;
    //   await this.$axios
    //     .post(url, {
    //       course: this.courseReport.course_id,
    //       step: "courseoutcome",
    //     })
    //     .then(() => {});
    // },
  },
};
</script>
