<template>
  <div>
    <div class="mainGroups" @click="$root.$emit('bv::hide::popover')">
      <b-overlay :show="groupLoading" spinner-variant="primary" spinner-type="grow" spinner-small rounded="md">
        <b-modal :title="
          getCustomNames.maingroupAdmin.custom_name != null
            ? getCustomNames.maingroupAdmin.custom_name + ' List'
            : 'Maingroup Admin List'
        " size="lg" id="mainGroupAdmin" hide-footer centered @close="checkMaingroupAdmin">
          <ListOfAdmins :editInfo="editInfo" :adminList="adminList" :getCustomNames="getCustomNames" />
        </b-modal>
        <b-modal size="lg" id="usergroup" hide-footer centered no-close-on-backdrop no-close-on-esc
          @close="groupInfo = {}">
          <Creation @GroupCreated="GroupCreated" :groupInfo="groupInfo" :adminList="adminList"
            :mainGroupId="mainGroupId" :getCustomNames="getCustomNames"
            :userGroupsTypesOptions="userGroupsTypesOptions" />
        </b-modal>
        <b-modal :title="'Add/View Users to/from ' + editInfo.name" size="lg" id="mainGroupUsers" hide-footer centered
          @close="editInfo.status ? addFacultyStatus() : (editInfo = {})">
          <GroupUser @addFacultyStatus="editInfo.status = true" :editInfo="editInfo" />
        </b-modal>
        <!-- <b-modal
          size="lg"
          id="addPo"
          hide-footer
          centered
          no-close-on-backdrop
          no-close-on-esc
          @close="poInfo.status ? $emit('adminsUpdated') : (poInfo = {})"
        >
          <ProgramOutcome
            :getCustomNames="getCustomNames"
            :poInfo="poInfo"
            @updatePOStatusToMain="poInfo.status = true"
          />
        </b-modal> -->
        <b-modal size="lg" id="maingroupReport" hide-footer centered no-close-on-backdrop no-close-on-esc
          :title="maingroupReport.name" @close="maingroupReport = {}">
          <MaingroupReport :maingroupReport="maingroupReport" />
        </b-modal>
        <div class="" v-if="mainGroups.length != 0">
          <table class="table table-bordered table-striped">
            <thead class="thead-dark">
              <tr>
                <th class="align-middle">
                  {{
                      getCustomNames.mainGroup.custom_name != null
                        ? getCustomNames.mainGroup.custom_name
                        : "Maingroup"
                  }}
                </th>
                <th class="align-middle">
                  {{
                      getCustomNames.maingroupAdmin.custom_name != null
                        ? getCustomNames.maingroupAdmin.custom_name + " List"
                        : "Maingroup Admin List"
                  }}
                </th>
                <th class="align-middle">
                  Faculties
                </th>
                <th class="align-middle">
                  Report
                </th>
                <th class="align-middle">
                  {{
                      getCustomNames.userGroup.custom_name != null
                        ? getCustomNames.userGroup.custom_name
                        : "Usergroup"
                  }}
                </th>
                <!-- <th class="align-middle">
                  PO
                </th> -->
              </tr>
            </thead>
            <tbody v-for="({
            id,
            name,
            code,
            institution_users,
            institution_users_count,
          },
            mainIndex) in mainGroups" :key="mainIndex">
              <tr v-if="showMaingroupRow(id)">
                <td class="align-middle">
                  <div>
                    {{ name + " (" + code + ")" }}
                    <span class="text-info btn p-0 m-0 ml-2" @click="
                      getMainGroup({
                        id,
                        admins: institution_users,
                        status: false,
                        group: undefined,
                      })
                    "><i class="fas fa-pen"></i></span>
                  </div>
                </td>
                <td class="align-middle">
                  <b-popover :target="'disabled-maingroupAdmin' + mainIndex" triggers="hover" placement="top"
                    v-if="institution_users.length != 0">
                    <b-list-group style="height: 200px; overflow-y: scroll">
                      <b-list-group-item v-for="({ first_name, last_name },
                      adminIndex) in institution_users" :key="adminIndex">
                        {{ first_name.concat(" ", last_name) }}
                      </b-list-group-item>
                    </b-list-group>
                  </b-popover>
                  <span v-if="institution_users.length == 1" :id="'maingroupAdmin' + mainIndex"
                    class="btn p-0 m-0 text-info font-weight-bolder" @click="
                      openMainAdminsModal({
                        id,
                        name,
                        group: undefined,
                        dataChanged: false,
                      })
                    ">
                    {{
                        institution_users[0].first_name.concat(
                          " ",
                          institution_users[0].last_name
                        )
                    }}
                  </span>
                  <span v-else-if="institution_users.length > 1" :id="'maingroupAdmin' + mainIndex"
                    class="btn p-0 m-0 text-info font-weight-bolder" @click="
                      openMainAdminsModal({
                        id,
                        name,
                        group: undefined,
                        dataChanged: false,
                      })
                    ">
                    {{
                        institution_users[0].first_name.concat(
                          " ",
                          institution_users[0].last_name
                        )
                    }}
                    & {{ institution_users.length - 1 }} more
                  </span>
                  <span v-else class="btn m-0 p-0 text-danger font-weight-bolder" @click="
                    openMainAdminsModal({
                      id,
                      name,
                      group: undefined,
                      dataChanged: false,
                    })
                  ">
                    No Admins Assigned Yet!
                  </span>
                </td>
                <td class="align-middle">
                  <b-popover :target="'faculty' + mainIndex" triggers="click" placement="bottom">
                    <b-alert variant="warning" show="" v-if="loadFaculty">Please wait...</b-alert>
                    <b-list-group style="height: 200px; overflow-y: scroll" v-else>
                      <b-list-group-item v-if="faculties.length == 0">
                        <span class="text-danger font-weight-bolder text-center">
                          No Falculties Found!
                        </span>
                      </b-list-group-item>
                      <b-list-group-item v-else v-for="({ first_name, last_name },
                      userIndex) in faculties" :key="userIndex">
                        {{ first_name.concat(" ", last_name) }}
                      </b-list-group-item>
                    </b-list-group>
                    <!-- <span v-else>{{ pos }}</span> -->
                  </b-popover>
                  <div class="d-flex justify-content-between align-items-baseline">
                    <span @click="
                      openMaingroupUsersModal({ id, name, status: false })
                    " class="btn p-0 m-0 text-info font-weight-bolder">
                      {{ institution_users_count }} Faculties
                    </span>
                    <span @click="displayFaculties(id)" :id="'faculty' + mainIndex" class="btn m-0 p-0 text-info">
                      <i class="fas fa-bars"></i>
                    </span>
                  </div>
                </td>
                <td class="align-middle">
                  <span class="btn p-0 m-0 text-info" @click="getMaingroupReport(id, name)">
                    <i class="fas fa-file-alt"></i>
                  </span>
                </td>

                <td class="align-middle text-center">
                  <div class="d-flex justify-content-between">
                    <span class="text-info btn m-0 p-0 font-weight-bolder"
                      :class="mainGroupObjects[id].show ? null : 'collapsed'" :aria-expanded="
                        mainGroupObjects[id].show ? 'true' : 'false'
                      " :aria-controls="'mainGroup' + id" @click="getUsergroupsAssociatedWith(id, false)"><i
                        v-if="(!mainGroupObjects[id].show)" class="fas fa-plus text-success"></i> <i
                        v-if="(mainGroupObjects[id].show)" class="fas fa-minus text-danger"></i>
                      {{
                          !mainGroupObjects[id].show
                            ? "Show " + getUsergroups
                            : "Hide " + getUsergroups
                      }}
                    </span>
                  </div>
                </td>
              </tr>
              <tr :style="{ display: mainGroupObjects[id].show ? '' : 'none' }">
                <td colspan="100">
                  <b-row>
                    <b-col>
                      <b-button class="m-2" variant="info" size="sm" @click="createUsergroup(id)">
                      <i class="fas fa-plus-square"></i> Add Programme
                    </b-button>
                    </b-col>
                  </b-row>
                  <b-collapse accordion="true" :id="'mainGroup' + id" v-model="mainGroupObjects[id].show">
                    <ViewGroups :usergroupFromMain="mainGroupObjects[id].data" :id="id" :getCustomNames="getCustomNames"
                      :adminList="adminList" @goToPSO="goToPSO" @getGroup="getGroup"
                      @adminsUpdated="checkAdminsStatus" />
                  </b-collapse>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <b-alert variant="danger" class="text-danger text-center font-weight-bolder" show v-else>
          No
          {{
    getCustomNames.mainGroup.custom_name != null
      ? getCustomNames.mainGroup.custom_name
      : "Maingroup"
          }}
          Found!
        </b-alert>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import Creation from "./Creation";
import ViewGroups from "./ViewGroups";
import ListOfAdmins from "./ListOfAdmins";
import MaingroupReport from "./MaingroupReport";
import GroupUser from "./GroupUser";

export default {
  props: [
    "selectedInstitute",
    "adminList",
    "groups",
    "mainGroups",
    "getCustomNames",
    "userGroupsTypesOptions",
  ],
  created() {
    this.mainGroups.forEach((group) => {
      this.$set(this.mainGroupObjects, group.id, {
        show: false,
        data: [],
      });
    });
  },
  data() {
    return {
      editInfo: {},
      groupLoading: false,
      groupInfo: {},
      mainGroupId: null,
      mainGroupObjects: {},
      maingroupReport: {},
      faculties: [],
      loadFaculty: false,
    };
  },
  computed: {
    getUsergroups() {
      if (this.getCustomNames.userGroup.custom_name != null) {
        return this.getCustomNames.userGroup.custom_name;
      } else {
        return "Usergroups";
      }
    },
  },
  components: {
    Creation,
    ViewGroups,
    ListOfAdmins,
    MaingroupReport,
    GroupUser,
  },
  methods: {
    showMaingroupRow(id) {
      let expanded = false;
      for (const key in this.mainGroupObjects) {
        const maingroup = this.mainGroupObjects[key];
        if (maingroup.show) {
          expanded = true;
          break;
        }
      }
      return expanded ? this.mainGroupObjects[id].show : true;
    },
    addFacultyStatus() {
      this.$emit("addFacultyStatus");
      this.editInfo = {};
    },
    getMaingroupReport(id, name) {
      this.maingroupReport = { id, name };
      this.$bvModal.show("maingroupReport");
    },
    openMaingroupUsersModal(data) {
      this.editInfo = data;
      this.$bvModal.show("mainGroupUsers");
    },
    async displayFaculties(id) {
      this.faculfacultiesies = [];
      this.loadFaculty = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/maingroups/${id}/users`;
      await this.$axios.get(url).then((res) => {
        this.faculties = res.data;
      });
      this.loadFaculty = false;
    },
    openMainAdminsModal(info) {
      this.editInfo = info;
      this.$bvModal.show("mainGroupAdmin");
    },
    createUsergroup(id) {
      this.mainGroupId = id;
      this.$bvModal.show("usergroup");
    },
    goToPSO(id) {
      this.$emit("goToPSO", id);
    },
    async GroupCreated(status) {
      this.groupLoading = true;
      this.$bvModal.hide("usergroup");
      status
        ? await this.getUsergroupsAssociatedWith(this.mainGroupId, true)
        : "";
      this.groupInfo = {};
      this.groupLoading = false;
    },
    async getUsergroupsAssociatedWith(id, status) {
      this.mainGroupId = id;
      if (!status) {
        if (this.mainGroupObjects[id].show) {
          this.mainGroupObjects[id].show = !this.mainGroupObjects[id].show;
          return;
        }
      }
      this.groupLoading = true;

      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/maingroups/${id}/usergroups?with_admins=1&with_po_count=1&with_pso_count=1&with_type=1`;
      await this.$axios.get(url).then((res) => {
        this.mainGroupObjects[id].data = res.data;
      });
      if (!status) {
        this.mainGroupObjects[id].show = !this.mainGroupObjects[id].show;
      }
      this.groupLoading = false;
    },
    async getGroup(data) {
      this.groupInfo = data;
      this.$bvModal.show("usergroup");
    },
    async getMainGroup(info) {
      this.groupLoading = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/maingroups/${info.id}?with_admins=1`;
      let mainGroup = await this.$axios.get(url).then((res) => {
        return res.data;
      });
      const data = {
        ...info,
        mainGroup,
      };
      this.$emit("getGroup", data);
      this.groupLoading = false;
    },
    // mainGroupCreated(status) {
    //   this.$bvModal.hide("mainGroups");
    //   status ? this.$emit("mainGroupCreated") : "";
    // },

    openGroupUsersModal(info) {
      this.editInfo = info;
      this.$bvModal.show("groupUsers");
    },
    updateInstitutionUserCount(status) {
      this.editInfo.dataChanged = status;
    },
    async checkAdminsStatus() {
      this.getUsergroupsAssociatedWith(this.mainGroupId, true);
      // this.$emit("adminsUpdated");
    },
    checkMaingroupAdmin() {
      this.$emit("checkMaingroupAdmin");
    },
  },
};
</script>

<style >
.mainGroups i {
  font-size: 20px !important;
}
</style>
