<template>
  <div>
    <div class="courseOutcome">
      <div class="deleteLessons mb-2" v-if="deleteModulesIds.length != 0 && !deleteModule">
        <b-button variant="danger" @click="$bvModal.show('courseModule')" size="sm">{{
            deleteModulesIds.length > 1
              ? "Remove Course Modules"
              : "Remove Course Module"
        }}</b-button>
      </div>
      <b-modal id="courseModule" no-close-on-backdrop no-close-on-keyboard no-close-on-esc hide-footer hide-header
        centered size="md">
        <h6 style="font-size: 18px; font-weight: 600" class="text-danger">
          Deleting Course Module will remove the CM from Lesson Plan. Are you
          Sure you want to proceed?
        </h6>
        <div class="buttons">
          <b-button size="sm" variant="primary" @click="prepareModulesDeletion()">Proceed</b-button>
          <b-button size="sm" variant="danger" class="ml-2"
            @click="deleteModulesIds = []; $bvModal.hide('courseModule')">Cancel</b-button>
        </div>
      </b-modal>
      <div v-if="!moduleCreation">
        <div class="p-2 mb-2 d-flex justify-content-between" style="
                      border: 2px solid #eeeeeb;
                      border-left-style: none;
                      border-right-style: none;
                    " v-if="$store.getters.defaultInstitute.userRole != 'Student'">
          <div class="d-flex align-items-center">
            <b-button variant="info" class="text-white" size="sm" @click="moduleCreation = !moduleCreation"><i
                class="fas fa-plus-square text-white mr-1"></i>Add Module</b-button>

            <b-form-checkbox size="md" class="ml-3" v-model="selectAllModules" v-if="editCourseComponents"
              @change="selectAllModulesForDeletion()">{{
                  selectAllModules ? "Unselect All" : "Select All"
              }}</b-form-checkbox>

            <!-- <b-button size="sm" variant="info" class="text-white ml-3" @click="goToExcel('CourseModuleCSV')">Import
                  Excel Sheet<i class="fas fa-file-excel ml-2"></i></b-button> -->
            <span class="text-info btn p-0 m-0 ml-3" @click="getCourseModules()">
              <i class="fas fa-sync-alt fa-2x"></i>
            </span>
          </div>
          <div class="download">
            <b-button variant="danger" :disabled="reportBtn.disabled" size="sm" @click="downloadCMReportPDF()">{{
                reportBtn.text
            }}</b-button>
          </div>
        </div>
      </div>
      <div class="loading" v-if="fetchData">
        <load-data></load-data>
      </div>
      <div v-else>
        <div class="table-responsive" v-if="!moduleCreation">
          <b-overlay :show="deleteModule" spinner-variant="primary" spinner-type="grow" spinner-small rounded="md">
            <!-- <div v-if="inventoryData.length > 0 && $store.getters.defaultInstitute.userRole != 'Student'"
                        class=" shadow  mx-1 my-2">
                        <b-alert show variant="info">
                          <h4 class=" alert-heading alert-link" style="width: fit-content; font-size: 1.5rem !important;">
                            Automatically add
                            Course Modules/Syllabus from ezygo suggestion!</h4>
                          <ul>
                            <li> {{ inventoryData[0].module }}-{{ inventoryData[0].lessons }} ... </li>
                            <li v-if="inventoryData.length > 1"> {{ inventoryData[1].module }}-{{ inventoryData[1].lessons }} ...
                            </li>
                            <li v-else> ...</li>
                          </ul>
                          <hr>
                          <p class="mb-0">
                            Click to automatically add Course Modules<b-button @click="importCourseModulesInventory()"
                              class="shadow ml-3" pill size="sm" variant="success">
                              Proceed
                            </b-button>
                          </p>
                        </b-alert>
                      </div> -->
            <div class="mb-2" v-if="!editCourseComponents">This data can only be edited by course instructor</div>
            <table class="table table-bordered table-striped" style="font-size: 14px; font-weight: 600">
              <thead class="thead-dark text-left">
                <tr>
                  <th v-if="editCourseComponents"></th>
                  <th>Si No.</th>
                  <th>Course Module</th>
                  <th>Description</th>
                  <th>Contact Hours</th>
                  <th>Sem Exam Marks %</th>

                  <th v-if="editCourseComponents"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="modules[courseId] && modules[courseId].length == 0" class="text-danger">
                  <td colspan="100" class="text-center">
                    No course modules created yet!
                  </td>
                </tr>
                <tr v-else v-for="(cModule, cModuleIndex) in modules[courseId]" :key="cModuleIndex">
                  <td v-if="editCourseComponents">
                    <b-form-checkbox v-model="deleteModulesIds" :value="cModule.id"></b-form-checkbox>
                  </td>
                  <td>{{ cModule.si_no }}</td>
                  <td>
                    {{ cModule.module }}
                  </td>
                  <td>{{ cModule.lessons }}</td>
                  <td>{{ cModule.contact_hours }}</td>
                  <td>{{ cModule.totel_score_for_exam }}</td>
                  <td v-if="editCourseComponents">
                    <span class="btn m-0 p-0 text-info" @click="
                      getCourseModule(
                        cModule.id,
                        cModule.si_no,
                        cModule.module,
                        cModule.lessons,
                        cModule.required_houres,
                        cModule.totel_score_for_exam
                      )
                    "><i class="far fa-edit"></i></span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <span class="text-danger" @click="deleteModulesIds = [cModule.id]; $bvModal.show('courseModule')"
                      size="sm">
                      <i class="fas fa-trash"></i></span>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-overlay>
        </div>
      </div>
    </div>
    <div class="createCourseOutcome" v-if="moduleCreation">
      <createModule :courseId="courseId" @getCourseModules="getCourseModules()"
        :currentCourseModule="currentCourseModule" @cancel="cancel()" v-on:goto-excel="goToExcel('CourseModuleCSV')">
      </createModule>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import createModule from "./ModuleCreation";
export default {
  props: ["courseId", "courseInstructor", "editCourseComponents"],
  async created() {
    await this.getCourseModules();
  },
  components: {
    createModule: createModule,
  },
  data() {
    return {
      fetchData: false,
      moduleCreation: false,
      currentCourseModule: {},
      deleteModulesIds: [],
      selectAllModules: false,
      modules: {},
      deleteModule: false,
      reportBtn: {
        text: "Download PDF",
        disabled: false,
      },
      // inventoryData: [],
    };
  },
  methods: {
    goToExcel(path) {
      let routeData = this.$router.resolve({
        name: path,
        params: {
          course_id: this.courseId,
        },
      });
      window.open(routeData.href, "_blank");
    },
    async selectAllModulesForDeletion() {
      if (this.selectAllModules) {
        this.modules[this.courseId].forEach((cModule) => {
          this.deleteModulesIds.push(cModule.id);
        });
      } else {
        this.deleteModulesIds = [];
      }
    },
    async downloadCMReportPDF() {
      this.reportBtn = {
        text: "Downloading....",
        disabled: true,
      };

      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/coursemodules/report/pdfexport`;
      await this.$axios
        .post(url, { course: this.courseId }, { responseType: "blob" })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "courseModulesReport.pdf");
          document.body.appendChild(link);
          link.click();
          link.remove();

          this.reportBtn = {
            text: "Download PDF",
            disabled: false,
          };
          // console.log(response);
        })
        .catch(() => {
          this.reportBtn = {
            text: "Download PDF",
            disabled: false,
          };

          // console.log(error.response);
        });
    },
    async getCourseModules() {
      this.fetchData = true;
      let courseModuleUrl = this.$store.getters.getAPIKey.getCourseModules;
      const url = courseModuleUrl.replace("course_id", this.courseId);
      await axios
        .get(url)
        .then((response) => {
          this.modules = {};
          this.modules[this.courseId] = response.data;
          this.fetchData = false;
          // if (response.data.length < 4 && this.$store.getters.defaultInstitute.userRole != 'Student') this.getCourseModulesInventorySuggestions();
          // console.log(response);
        })
        .catch(() => {
          // console.log(error.response);
        });
    },

    // getCourseModulesInventorySuggestions() {
    //   const url =
    //     this.$store.getters.getAPIKey.mainAPI + `/inventory/courses/${this.courseId}/suggestions?inventorytype=coursemodules`;
    //   axios
    //     .get(url)
    //     .then((response) => {
    //       this.inventoryData = response.data.coursemodules;
    //     });
    // },
    // importCourseModulesInventory() {
    //   this.fetchData = true;
    //   const url =
    //     this.$store.getters.getAPIKey.mainAPI + `/inventory/courses/${this.courseId}/import?inventorytype=coursemodules`;
    //   axios
    //     .get(url)
    //     .then(() => {
    //       this.getCourseModules();
    //     });
    // },
    getCourseModule(
      moduleId,
      moduleSiNo,
      moduleModule,
      moduleLesson,
      contact_hours,
      totel_score_for_exam
    ) {
      this.currentCourseModule.id = moduleId;
      this.currentCourseModule.si_no = moduleSiNo;
      this.currentCourseModule.module = moduleModule;
      this.currentCourseModule.lessons = moduleLesson;
      this.currentCourseModule.contact_hours = contact_hours;
      this.currentCourseModule.totel_score_for_exam = totel_score_for_exam;
      this.moduleCreation = true;
    },
    cancel() {
      this.currentCourseModule = {};
      this.moduleCreation = false;
    },
    async prepareModulesDeletion() {
      this.$bvModal.hide("courseModule");
      this.deleteModule = true;
      for (let cModule in this.deleteModulesIds) {
        let moduleDeleted = await this.deleteCourseModule(
          this.deleteModulesIds[cModule]
        );
        if (moduleDeleted) {
          if (cModule == this.deleteModulesIds.length - 1) {
            this.$toast.success("Deleted Successfully", {
              position: "top",
              duration: 3000,
            });
            this.deleteModule = false;
            this.deleteModulesIds = [];
            this.getCourseModules();
          }
        }
        if (!moduleDeleted) {
          this.$toast.error("Something went wrong", {
            position: "top",
            duration: 3000,
          });
          return (this.deleteModule = false);
        }
      }
    },
    async deleteCourseModule(moduleId) {
      let status = null;
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/coursemodules/${moduleId}`;
      await this.$axios
        .delete(url)
        .then(() => {
          status = true;
          // console.log(response);
        })
        .catch(() => {
          status = false;
          // console.log(error);
        });
      return status;
    },
  },
};
</script>

<style >
.courseOutcome i {
  font-size: 15px !important;
}

.blink {
  animation: blink 2s infinite;
}

@keyframes blink {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1.03);
  }

  50% {
    transform: scale(1.02);
  }

  75% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}
</style>
