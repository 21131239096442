<template>
  <div>
    <b-container>
      <b-row>
        <b-col md="12">
          <fill-profile ref="enterProfileDetails" @profileCreated="profileCreated"></fill-profile>
          <div class="closeBtn mb-2 text-right">
            <b-button v-if="
              myIinstitutionEnrollmets.length != 0 && selectedInstitute.approved != 0
            " variant="danger" size="sm" @click="$emit('closeInstituteCreation')">Close</b-button>
          </div>
          <div class="instituteCreated text-center font-weight-bolder"
            v-if="selectedInstitute.id && selectedInstitute.approved == 0">
            <h6>
              Your institute has been created successfully, usually it takes 24 hours
              window to get approved. <br />
              Please check back later to check the status.
              <b-badge variant="warning" class="text-white p-1">{{
                  selectedInstitute.approval_status
              }}</b-badge>
            </h6>
          </div>
          <div v-else-if="!instituteApprovalStatus">
            <b-alert variant="danger" class="text-center font-weight-bolder" show>
              Cannot create institute/enroll to one, since one of the institute is in pending state.
            </b-alert>
          </div>
          <div v-else>
            <div class="createInstitute">
              <h6 class="alert alert-danger text-center" v-if="!selectedInstitute.id">
                You do not have an active enrolment in any institution at the moment. Please enrol in an institution to access its resources and features. <br>
                If you believe your account has been mistakenly disabled by the institution, please contact them for assistance.
              </h6>
              <h6 class="alert alert-success text-center" v-else>
                Create or enroll to institute
              </h6>
              <div class="createInstituteForm" v-if="!createInstitute">
                <p>Click below to create institute.</p>
                <b-button variant="primary" size="md" @click="createInstitute = !createInstitute">Create
                  Institute</b-button>
                <br />
              </div>
              <create-institute @institutionCreationCancelled="createInstitute = !createInstitute"
                v-if="createInstitute"></create-institute>
            </div>

            <div class="enrollment" v-if="!createInstitute">
              <h6 class="text-center">Or</h6>
              <h6>Enter Enrollment Code</h6>
              <h6 class="alert alert-success text-center" v-if="enrolled">
                Successfully Enrolled
              </h6>
              <p class="text-info" style="font-size: 14px; font-weight: 600">
                Contact Institute Admin Or Group Faculty for Enrollment Code
              </p>
              <b-form @submit.prevent="enrollUser()">
                <b-form-group>
                  <b-input placeholder="Enrolment Code" v-model="enrollmentCode.invitation_code"
                    :class="{ 'is-invalid': responseErrors.invitation_code }"></b-input>
                  <span class="text-danger" v-if="responseErrors.invitation_code">{{
                      responseErrors.invitation_code[0]
                  }}</span>
                </b-form-group>

                <b-form-group>
                  <b-button variant="primary" size="md" type="submit" :disabled="
                    enrollBtn.disabled || enrollmentCode.invitation_code.length < 4
                  ">{{ enrollBtn.text }}</b-button>
                </b-form-group>
              </b-form>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import InstituteDropdown from "./InstituteDropdown.vue";
import axios from "axios";
export default {
  props: ["selectedInstitute", "myIinstitutionEnrollmets", "instituteApprovalStatus"],
  components: {
    "create-institute": InstituteDropdown,
  },
  data() {
    return {
      enrollBtn: {
        text: "Submit",
        disabled: false,
      },
      enrolled: false,
      enrollmentCode: { invitation_code: "" },
      responseErrors: {},
      createInstitute: false,
    };
  },
  methods: {
    profileCreated() {
      if (this.enrollmentCode.invitation_code != "") {
        this.enrollBtn.text = "Profile Created Successfully.";
        this.enrollBtn.disabled = true;
        setTimeout(() => {
          this.enrollUser();
        }, 3000);
      }
    },
    enrollUser() {
      this.enrollBtn.text = "Please wait...";
      this.enrollBtn.disabled = true;
      const url = this.$store.getters.getAPIKey.acceptInvitation;
      axios
        .post(url, this.enrollmentCode)
        .then((response) => {
          this.enrollBtn.text = "Submit";
          this.enrolled = true;
          setTimeout(() => {
            this.$store.dispatch("setUpInstitutes");
            this.enrollBtn.disabled = false;
            this.enrolled = false;
          }, 3000);
          response;
        })
        .catch((error) => {
          if (error.response.status == 423) {
            this.$refs.enterProfileDetails.checkProfile();
            this.responseErrors = error.response.data.errors;
            this.enrollBtn.text = "Submit";
            setTimeout(() => {
              this.enrollBtn.disabled = false;
              this.responseErrors = {};
            }, 3000);
          } else {
            this.responseErrors = error.response.data.errors;
            this.enrollBtn.text = "Submit";
            setTimeout(() => {
              this.enrollBtn.disabled = false;
              this.responseErrors = {};
            }, 3000);
          }

          error;
        });
    },
  },
};
</script>
