var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.surveyAdmin)?_c('div',{staticClass:"surveyAdmin"},[_c('p',[_vm._v("This tab is only availabe in web view.")])]):_vm._e(),_c('div',{staticClass:"studentOrAdminView"},[(_vm.surveys.length == 0)?_c('p',{staticClass:"text-danger"},[_vm._v(" No Forms Found! ")]):_c('div',{staticClass:"studentEvent"},_vm._l((_vm.surveys),function(survey,surveyIndex){return _c('div',{key:surveyIndex,staticClass:"event mt-2 p-3",style:(surveyIndex % 2 === 0
            ? {
                backgroundColor: '#F0F0F0',
                fontSize: 14 + 'px',
                fontWeight: 600,
              }
            : {
                backgroundColor: '#FFFFFF',
                fontSize: 14 + 'px',
                fontWeight: 600,
              })},[_c('div',{staticClass:"eventName text-center mb-2"},[_c('span',{class:survey.summary != null ? 'text-info' : 'text-dark',attrs:{"id":'approvalSummary' + surveyIndex}},[_vm._v(_vm._s(survey.name))]),(survey.summary != null)?_c('b-tooltip',{attrs:{"target":'approvalSummary' + surveyIndex,"triggers":"hover"}},[_vm._v(" "+_vm._s(survey.summary)+" ")]):_vm._e()],1),_c('div',{staticClass:"date mb-2 d-flex justify-content-between"},[_c('div',{staticClass:"startDate"},[_vm._v(" "+_vm._s(survey.start_at.format("DD/MM/YYYY"))+" "),_c('br'),_vm._v(" "+_vm._s(survey.start_at.format("hh:mm a"))+" ")]),_c('div',{staticClass:"endDate"},[_vm._v(" "+_vm._s(survey.end_at.format("DD/MM/YYYY"))+" "),_c('br'),_vm._v(" "+_vm._s(survey.end_at.format("hh:mm a"))+" ")])]),_c('div',{staticClass:"submitBtn d-flex justify-content-center"},[(_vm.today >= survey.start_at && _vm.today <= survey.end_at)?_c('b-button',{attrs:{"variant":"primary","size":"sm","squared":""},on:{"click":function($event){return _vm.surveyLinks('Answers', survey.id, survey.survey_type)}}},[_vm._v("Take Survey")]):(_vm.today <= survey.start_at)?_c('span',{staticClass:"text-success"},[_vm._v("Upcoming Survey")]):_c('span',{staticClass:"text-danger"},[_vm._v("Last Date exceeded")])],1)])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }