<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <b-container v-else>
      <div class="text-right"><b-button @click="courseCoverageMonitoringPdfDownload" variant="danger" size="sm" class="ml-4">Download PDF</b-button></div>
      <div v-for="(usergroup, ugIndex) in usergroups" :key="ugIndex" class="mb-5">
        <div style="font-size: 16px !important; font-weight: 600" class="text-center">{{ usergroup.name }}   </div>
        <table class="table table-bordered table-striped">
          <tbody v-for="(subgroup, sgIndex) in usergroupSubgroups(usergroup.id)" :key="sgIndex">
            <tr class="table-header" style="background-color: gray;">
              <th >{{ subgroup.name }}</th>
              <th>Remedial</th>
              <th>Other</th>
              <th :colspan="maximumModulesInSubgroup(subgroup.id)" class="text-center">Modules</th>
            </tr>
            <tr v-for="(course, cIndex) in subgroupCourses(subgroup.id)" :key="cIndex">
              <td>{{ course.name }}</td>
              <td>{{ coursesRemedialAttCounts[course.id] }}</td>
              <td>{{ coursesOtherAttCounts[course.id] }}</td>
              <td v-for="(moduleData, moduleKey) in course.module_coverage" :key="moduleKey">
                <span class="text-secondary">{{moduleKey}}</span> <br>
                  {{ moduleData.attendance_count }}/{{
                      moduleData.planned_attendance_count
                  }}
                  <span
                    :class="{ 'text-danger': moduleData.attendance_count / moduleData.planned_attendance_count < .9, 'text-success': moduleData.attendance_count / moduleData.planned_attendance_count >= 1 }"
                    v-if="(moduleData.planned_attendance_count != 0)">
                    <span class="text-primary"> | </span>
                    {{ Math.round((moduleData.attendance_count /
                        moduleData.planned_attendance_count
                        * 100))
                    }}%
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  props: [],
  components: {
  },
  async created() {
    this.fetchData = true;
    await this.getCourseCoverage();
    this.fetchData = false;
  },

  data() {
    return {
      fetchData: false,
      coursesWithModule: [],
      usergroups: [],
      subgroups: [],
      coursesOtherAttCounts: {},
      coursesRemedialAttCounts: {},
    };
  },
  methods: {
    maximumModulesInSubgroup(subgroupId){
      let courses = this.subgroupCourses(subgroupId);
      let moduleCount = 0;
      courses.forEach(course => {
        moduleCount = Object.keys(course.module_coverage).length < moduleCount?moduleCount:Object.keys(course.module_coverage).length;
      });
      return moduleCount;
    },
    subgroupCourses(subgroupId) {
      return this.coursesWithModule.filter(
        (course) => {
          return course.usersubgroup_id == subgroupId;
        }
      );
    },
    usergroupSubgroups(usergroupId) {
      return this.subgroups.filter(
        (subgroup) => {
          return subgroup.usergroup_id == usergroupId;
        }
      );
    },
    subgroupModuleKeysLength(subgroupId) {
      // console.log(subgroupId, this.subgroupModuleKeys[subgroupId], this.subgroupModuleKeys[subgroupId].length);
      return this.subgroupModuleKeys[subgroupId].length;
    },
    async getCourseCoverage() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usergroups/coursecoverage/monitoring`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.coursesWithModule = response.data.coursesWithModule;
          this.usergroups = response.data.usergroups;
          this.subgroups = response.data.subgroups;
          this.coursesOtherAttCounts = response.data.coursesOtherAttCounts;
          this.coursesRemedialAttCounts = response.data.coursesRemedialAttCounts;
        })
        .catch((error) => {
          error;
        });
    },
    async courseCoverageMonitoringPdfDownload() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usergroups/coursecoverage/monitoring/report/pdfexport`;
      await this.$axios
        .post(
          url,
          {},
          { responseType: "blob" }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download","courseCoverageMonitoringPDF.pdf");
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch(() => {
          let errorText = "Failed to Download, please try again";
          this.$toast.error(errorText, {
            position: "top",
            duration: 3000,
          });
        });
    },
  },
  watch: {
  },
};
</script>

<style  scoped>
.table-header {
  background-color: gray !important;
}
</style>
