<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="universityExam p-3" v-else>
      <div class="universityExamCreation" v-if="updateScoreComponent">
        <UniversityExamUpdateScore @finishUpdateScore="(updateScoreComponent = false)" :boardExam="boardExam" />
      </div>
      <div class="universityExamListing" v-else>
        <div class="p-2 d-flex justify-content-between" style="
            border: 2px solid #eeeeeb;
            border-left-style: none;
            border-right-style: none;
          ">
          <b-button variant="info" @click="addEditBoardExam(null)" size="sm"><i
              class="fas fa-plus-square text-white"></i>Add University Exam To {{
                      getCustomNames.userSubgroup.custom_name != null
                        ? getCustomNames.userSubgroup.custom_name
                        : "Usersubgroup"
                  }}</b-button>
          <!-- <b-button
            @click="openYoutubeLinks('UniversityExam')"
            style="backgroundColor: #FF0000; border: none"
            size="sm"
          >
            <i class="fab fa-youtube"></i>
            Video Tutorial
          </b-button> -->
        </div>
        <br />
        <div class="table-responsive">
          <table class="table table-striped table-bordered">
            <thead class="thead-dark text-left">
              <tr>
                <th>Si.No.</th>
                <th class="align-middle">University Exam</th>
                <th>Type</th>
                <th class="align-middle">
                  {{
                      getCustomNames.userSubgroup.custom_name != null
                        ? getCustomNames.userSubgroup.custom_name
                        : "Usersubgroup"
                  }}
                  - Academic Year
                </th>

                <th class="align-middle"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="boardExams.length == 0">
                <td colspan="100" class="text-center text-danger">
                  No Exams Created Yet!
                </td>
              </tr>
              <tr v-for="(exam, examIndex) in boardExams" :key="examIndex" v-else>
                <td class="align-middle">
                  <span>{{ exam.si_no }} </span>
                </td>
                <td class="align-middle">
                  <span>{{ exam.name }} </span>
                </td>
                <td class="align-middle">
                  <span>{{ exam.type == 'regular' ?
                      'Regular' : exam.type == 'supple_improve' ?
                        'Supplementary/Improvement' : 'Not updated'
                  }} </span>
                </td>
                <td class="align-middle">
                  {{ exam.usersubgroup.name + " (" + exam.usersubgroup.code + ")" }}
                  - {{ exam.usersubgroup.academic_year }}-{{ exam.usersubgroup.usergroup.code }}
                </td>

                <td class="align-middle">
                  <span class="text-info btn m-0 p-0 sm" size="sm" @click="addEditBoardExam(examIndex)"><i
                      class="fas fa-pen ml-3"></i></span>
                  <b-button variant="info" class="text-uppercase ml-2" @click="
                    updateBoardExamScore(examIndex)
                  ">Update Marks</b-button>

                  <span class="text-info btn  ml-2 p-0 sm" size="sm" @click="deleteBoardExam(exam.id)">
                    <i style="color:red;" class="fas fa-trash ml-3"></i>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>


    <b-modal id="addEditBoardexamModel" no-close-on-backdrop no-close-on-keyboard no-close-on-esc hide-footer
      hide-header centered size="md">
      <h3 class="text-center">Add/Update University Exam</h3>
      <b-form-group id="input-group-1" label-for="input-1" description="">
        <label class="required-label" for="">Si No:</label>
        <b-form-input id="input-1" v-model="boardExam.si_no" type="text"
          placeholder="Enter Serial Number"></b-form-input>
      </b-form-group>
      <b-form-group id="input-group-1" label-for="input-1" description="">
        <label class="required-label" for="">Name:</label>
        <b-form-input id="input-1" v-model="boardExam.name" type="text" placeholder="Enter Name"></b-form-input>
      </b-form-group>
      <b-form-group id="input-group-1" label-for="input-1" description="">
        <label class="required-label" for="">Type:</label>
        <b-form-select v-model="boardExam.type" class="mb-3">
          <b-form-select-option :value="null">Please Choose</b-form-select-option>
          <b-form-select-option :value="'regular'">Regular</b-form-select-option>
          <b-form-select-option :value="'supple_improve'">Supplementary/Improvement</b-form-select-option>
        </b-form-select>
      </b-form-group>
      <b-form-group id="input-group-1" label-for="input-1" description="" :disabled="boardExam.id ? true : false">
        <label class="required-label" for="">Mark Entry Type:</label>
        <b-form-select v-model="boardExam.mark_entry_type" class="mb-3" :disabled="boardExam.id ? true : false">
          <b-form-select-option :value="'subject_wise'">Subject wise total mark</b-form-select-option>
          <b-form-select-option :value="'question_wise'">Question wise mark entry (For Autonomous Institute)</b-form-select-option>
          <b-form-select-option :value="'co_wise'">CO wise mark entry (For Autonomous Institute)</b-form-select-option>
        </b-form-select>
      </b-form-group>
      <b-form-group id="input-group-1" label-for="input-1" description="">
        <label class="" for="">Description:</label>
        <b-form-input id="input-1" v-model="boardExam.summary" type="text"
          placeholder="Enter a description"></b-form-input>
      </b-form-group>
      <b-form-group v-if="(boardExam.id == null)" id="input-group-1" label-for="input-1" description="">
        <label class="required-label" for="">{{
            getCustomNames.userSubgroup.custom_name != null
              ? getCustomNames.userSubgroup.custom_name
              : "Usersubgroup"
        }}:</label>
        <b-form-select v-model="boardExam.usersubgroup_id" :options="usersubgroups" class="mb-3"></b-form-select>
      </b-form-group>
      <b-row class="p-2">
        <b-col style="text-align:left;">
          <b-button variant="danger" class="text-white sm" size="sm"
            @click="$bvModal.hide('addEditBoardexamModel');">Close</b-button>
        </b-col>
        <b-col style="text-align:right;">
          <b-button variant="success" class="text-white sm" size="sm" @click="createBoardExam">{{ boardExam.id ==
              null ? 'Create' : 'Update'
          }}</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import UniversityExamUpdateScore from "./UniversityExamUpdateScore.vue";
export default {
  async created() {
    this.fetchData = true;
    await this.getBoardExamsList();
    // await document.querySelector(".universityExam");
    // if (document.querySelector(".universityExam") != null) {
    //   const theHeight = document.querySelector(".universityExam").offsetHeight;
    //   this.$emit("totalComponentSize", theHeight);
    // }
    this.fetchData = false;
  },
  components: {
    UniversityExamUpdateScore,
  },
  data() {
    return {
      fetchData: false,
      // universityExamCreation: false,
      boardExams: [],
      usersubgroups: [],
      // board exam adding / editting  or updating score
      boardExam: { id: null, si_no: '', type: 'regular', mark_entry_type: 'subject_wise', name: '', summary: '', usersubgroup_id: null }, // new or edit 
      // boardExamsWithUsersubgroups: [],
      updateScoreComponent: false,
    };
  },
  computed: {
    getCustomNames() {
      return this.$store.getters.getCustomNamings;
    },
  },
  methods: {

    async deleteBoardExam(id) {
      if (
        confirm(
          `WARNING: Are you sure you want to proceed? Please note that this action will result in the deletion of the exam, along with all the scores entered for students in various courses associated with this exam.`
        ) == true
      ) {
        const url = this.$store.getters.getAPIKey.mainAPI + `/boardexams/${id}`;
        await this.$axios
          .delete(url)
          .then(() => {
            this.$toast.success('Exam Deleted successfully', {
              position: "top",
              duration: 4000,
            });
            this.getBoardExamsList();
          })
          .catch(() => {
            this.$toast.error('Failed to Deleted exam', {
              position: "top",
              duration: 4000,
            });
          });
      }
    },
    async updateBoardExamScore(index) {
      this.boardExam = this.boardExams[index];
      this.updateScoreComponent = true;
    },
    async addEditBoardExam(index) {
      if (this.usersubgroups.length < 1) this.getUsersubgroups();
      this.boardExam = { id: null, si_no: '', type: 'regular', mark_entry_type:'subject_wise', name: '', summary: '', usersubgroup_id: null };
      if (index !== null) this.boardExam = this.boardExams[index];
      this.$bvModal.show('addEditBoardexamModel');
    },
    async createBoardExam() {
      let error = null;
      if (this.boardExam.id == null) {
        const url = this.$store.getters.getAPIKey.mainAPI + `/boardexams`;
        await this.$axios
          .post(url, this.boardExam)
          .then(() => {
            this.$toast.success('Exam added successfully', {
              position: "top",
              duration: 4000,
            });
          })
          .catch((errordata) => {
            error = errordata;
          });
      } else {
        const url = this.$store.getters.getAPIKey.mainAPI + `/boardexams/${this.boardExam.id}`;
        await this.$axios
          .put(url, this.boardExam)
          .then(() => {
            this.$toast.success('Exam updated successfully', {
              position: "top",
              duration: 4000,
            });
          })
          .catch((errordata) => {
            error = errordata;
          });
      }

      if (error) {
        let errortxt = "";
        if (error.response)
          if (error.response.status == 422) {
            let errormsgs = error.response.data.errors;
            for (const key in errormsgs) {
              errortxt = errormsgs[key][0];
            }
          } else {
            errortxt = error.response.data.message;
          }
        else {
          errortxt = "Failed to Create";
        }
        this.$toast.error(errortxt, {
          position: "top",
          duration: 7000,
        });
      } else {
        this.boardExam = { id: null, si_no: '', type: 'regular', mark_entry_type: 'subject_wise', name: '', summary: '', usersubgroup_id: null };
        this.$bvModal.hide('addEditBoardexamModel');
        this.getBoardExamsList();
      }
    },
    // updateBoardExam(usersubgroup_id, board_id, academic_year) {
    //   this.updateExam.usersubgroup_id = usersubgroup_id;
    //   this.updateExam.board_id = board_id;
    //   this.updateExam.academic_year = academic_year;
    //   this.universityExamCreation = true;
    // },
    // async universityExamCancelled(status) {
    //   this.universityExamCreation = false;
    //   this.updateExam = {};
    //   if (status) {
    //     this.fetchData = true;
    //     await this.getBoardExamsList();
    //     this.fetchData = false;
    //   }
    // },
    async getBoardExamsList() {
      const url = this.$store.getters.getAPIKey.mainAPI + `/boardexams`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.boardExams = response.data;
          // this.boardExams.forEach((exam) => {
          //   if (exam.usersubgroups.length != 0) {
          //     exam.usersubgroups.forEach((usersubgroup) => {
          //       this.boardExamsWithUsersubgroups.push({
          //         board_id: exam.id,
          //         board_name: exam.name,
          //         board_academic_year: exam.academic_year,
          //         board_summary: exam.summary,
          //         subgroup_id: usersubgroup.id,
          //         subgroup_name: usersubgroup.name,
          //         subgroup_code: usersubgroup.code,
          //         subgroup_academic_year: usersubgroup.academic_year,
          //       });
          //     });
          //   }
          // });
          response;
        })
        .catch((error) => {
          error;
        });
    },

    async getUsersubgroups() {
      const url = this.$store.getters.getAPIKey.mainAPI + `/usersubgroups`;
      await this.$axios
        .get(url)
        .then((response) => {
          let usersubgroups = response.data;
          usersubgroups.forEach(subgroup => {
            this.usersubgroups.push({
              value: subgroup.id,
              text: `${subgroup.name}(${subgroup.code}) - ${subgroup.usergroup.code}`,
            });
          });
        })
        .catch((error) => {
          error;
        });
    },
  },
};
</script>

<style >
.universityExamListing i {
  font-size: 12px !important;
  margin-right: 4px;
}
</style>
