<template>
  <div>
    <b-overlay
      :show="loadParticipants"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="md"
    >
      <div class="printReport mb-2 text-right">
        <b-button variant="primary" size="sm" @click="printReport()"
          >Print</b-button
        >
      </div>

      <div class="participantReport p-4">
        <div
          class="selectGroup mb-2 d-flex justify-content-between"
          v-if="
            students.length != 0 && selectedSurveyType == 'student_feedback'
          "
        >
          <p style="font-size: 14px; font-weight: 600">
            Select
            {{
              getCustomNames.userGroup.custom_name != null
                ? getCustomNames.userGroup.custom_name
                : "Usergroup"
            }}
            -
            {{
              getCustomNames.userSubgroup.custom_name != null
                ? getCustomNames.userSubgroup.custom_name
                : "userSubgroup"
            }}
          </p>
          <b-form-select
            :options="groupsWithSubgroupsOptions"
            v-model="selectedGroupSubgroup"
            @change="getSubgroupParticipants"
            class="w-50"
          ></b-form-select>
          <b-button
            variant="danger"
            size="sm"
            v-if="selectedGroupSubgroup != null"
            @click="clearSubgroupSelection()"
            >Clear</b-button
          >
        </div>
        <br />
        <div id="printParticipantReport">
          <div class="instituteDetails mb-3 text-center">
            <h5 style="font-size: 24px; font-weight: 600">
              {{ selectedInstitute.name }}
            </h5>
            <h6
              v-if="selectedInstitute.name_2 != null"
              style="font-size: 20px; font-weight: 600"
            >
              {{ selectedInstitute.name_2 }}
            </h6>
            <h6
              v-if="selectedInstitute.name_3 != null"
              style="font-size: 20px; font-weight: 600"
            >
              {{ selectedInstitute.name_3 }}
            </h6>
          </div>
          <br />
          <h5 style="font-size: 20px; font-weight: 600">
            ~ Participation Report
            <span v-if="selectedGroupSubgroup != null"
              >For:<span class="text-info">
                {{ selectedGroupSubgroup.name }}</span
              ></span
            >
          </h5>

          <table class="table table-striped table-bordered">
            <thead class="thead-dark">
              <tr>
                <th v-if="rollNumberAvailable" class="align-middle">
                  Roll Number
                </th>
                <th class="w-50 align-middle">Name</th>
                <th class="w-25 align-middle">Start</th>
                <th class="w-25 align-middle">End</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="participants.length == 0">
                <td colspan="100" class="align-middle text-center text-danger">
                  No Data Found!
                </td>
              </tr>
              <tr
                v-for="(student, studentIndex) in participants"
                :key="studentIndex"
                v-else
              >
                <td v-if="rollNumberAvailable" class="text-center align-middle">
                  {{ student.roll_number }}
                </td>
                <td class="text-center align-middle">
                  {{ student.first_name || " " }} {{ student.last_name || " " }}
                </td>
                <td class="align-middle">{{ student.pivot.starts_at }}</td>
                <td class="align-middle">{{ student.pivot.end_at }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
export default {
  props: [
    "students",
    "groupsWithSubgroupsOptions",
    "survey_id",
    "selectedSurveyType",
    "getCustomNames",
  ],
  async created() {
    await this.getParticipants();
    this.selectedInstitute = this.$store.getters.getCurrentInstitution;
    if (this.groupsWithSubgroupsOptions.length > 1) {
      this.selectedGroupSubgroup = await this.groupsWithSubgroupsOptions[1]
        .value;
      await this.getSubgroupParticipants();
    }
  },
  data() {
    return {
      participants: [],
      loadParticipants: false,
      selectedGroupSubgroup: null,
      selectedInstitute: {},
      rollNumberAvailable: false,
    };
  },
  methods: {
    getParticipants() {
      this.rollNumberAvailable = false;
      this.participants = this.students;
    },
    getSubgroupParticipants() {
      this.loadParticipants = true;
      // let subgroupId;
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/studfbsurveystudents/report`;
      this.$axios
        .post(url, {
          survey: this.survey_id,
          usersubgroup: this.selectedGroupSubgroup,
        })
        .then((response) => {
          this.participants = response.data;
          this.rollNumberAvailable = true;

          this.sortedArray();
          this.loadParticipants = false;
          response;
        })
        .catch((error) => {
          this.loadParticipants = false;
          error;
        });
    },
    sortedArray() {
      // this.participants.sort(function(a, b) {
      //   return (
      //     a.roll_number - b.roll_number ||
      //     a.first_name.localeCompare(b.first_name)
      //   );
      // });
    },
    clearSubgroupSelection() {
      this.selectedGroupSubgroup = null;

      this.getParticipants();
    },
    async printReport() {
      this.$htmlToPaper("printParticipantReport");
    },
  },
};
</script>
