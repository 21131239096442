<template>
  <div>
    <b-skeleton-table
      v-if="fetchData"
      :rows="4"
      :columns="6"
      :table-props="{ bordered: true, striped: true }"
    ></b-skeleton-table>
    <div class="studentSemester" v-else>
      <div class="table-responsive">
        <table class="table table-bordered table-striped">
          <thead class="thead-dark">
            <tr>
              <th class="align-middle">Name</th>
              <th class="align-middle">
                {{
                  getCustomNames.userGroup.custom_name != null
                    ? getCustomNames.userGroup.custom_name
                    : "Usergroup"
                }}
                Code -
                {{
                  getCustomNames.userSubgroup.custom_name != null
                    ? getCustomNames.userSubgroup.custom_name
                    : "Usersubgroup"
                }}
                Code
              </th>
              <th class="align-middle">Academic Year</th>
              <th class="align-middle">Courses</th>
            </tr>
          </thead>
          <tbody
            v-for="({ id, name, academic_year, code, usergroup },
            usergroupCode) in usersubgroups"
            :key="usergroupCode"
          >
            <tr>
              <td class="align-middle">{{ name }}</td>
              <td class="align-middle">{{ code }} - {{ usergroup.code }}</td>
              <td class="align-middle">{{ academic_year }}</td>
              <td class="align-middle">
                <span
                  v-b-toggle.collapse-1
                  class="btn p-0 m-0 font-weight-bolder text-info"
                  @click="showCourses(id)"
                >
                  {{
                    usersubgroupObj[id].show ? "Hide Courses" : "Show Courses"
                  }}
                </span>
              </td>
            </tr>

            <tr :style="{ display: usersubgroupObj[id].show ? '' : 'none' }">
              <td colspan="100">
                <b-collapse
                  accordion="true"
                  :id="'usersubgoup' + id"
                  v-model="usersubgroupObj[id].show"
                >
                  <Courses
                    :getCustomNames="getCustomNames"
                    :courses="courses"
                    :usersubgroupId="id"
                  />
                </b-collapse>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Courses from "./Courses";
export default {
  props: ["getCustomNames"],
  async created() {
    this.fetchData = true;
    await this.getUserSubgroups();
    await this.getCourses();
    this.fetchData = false;
  },
  components: {
    Courses,
  },
  data() {
    return {
      fetchData: false,
      usersubgroups: [],
      usersubgroupObj: {},
      courses: [],
    };
  },
  methods: {
    showCourses(usersubgroupId) {
      this.usersubgroupObj[usersubgroupId].show = !this.usersubgroupObj[
        usersubgroupId
      ].show;
    },
    async getUserSubgroups() {
      const url = this.$store.getters.getAPIKey.mainAPI + `/usersubgroups`;
      await this.$axios.get(url).then((res) => {
        res.data.forEach((subgroup) => {
          this.$set(this.usersubgroupObj, subgroup.id, { show: false });
          this.usersubgroups.push(subgroup);
        });
      });
    },
    async getCourses() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/institutionuser/courses/withusers`;
      await this.$axios.get(url).then((res) => {
        this.courses = res.data;
      });
    },
  },
};
</script>
