<template>
  <div>
    <!-- subgroup students attendance report -->
    <div v-if="courseId">
      <div class="closeButton">
        <b-button
          class="ml-2"
          variant="primary"
          size="md"
          :disabled="exportCsvBtn.disabled"
          @click="exportToExcel('xlsx')"
          >{{ exportCsvBtn.text }}</b-button
        >
      </div>

      <br />
      <table
        class="table table-striped table-bordered"
        v-if="redyToViewSubgroupReport && students.length != 0"
        ref="subgroupReport"
      >
        <thead>
          <tr>
            <th colspan="100" class="text-center">{{ reports.tableName }}</th>
          </tr>
          <tr>
            <th></th>
            <th>Students</th>
            <th
              :colspan="attendanceTypes.length"
              v-for="(course, coursesIndex) in courses"
              :key="coursesIndex"
              class="text-capitalize"
            >
              {{ course.name }}
            </th>
            <th>Total</th>
            <th>%</th>
          </tr>
          <tr>
            <th></th>
            <th></th>
            <th
              v-for="(attendanceType,
              attendanceTypeIndex) in courseAttandanceTypesHeaderArray"
              :key="attendanceTypeIndex"
            >
              {{ attendanceType }}
              <span v-if="attendanceType.limit">
                -{{ attendanceType.limit }}</span
              >
            </th>

            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(student, studentIndex) in students" :key="studentIndex">
            <td>
              {{
                student.pivot.roll_number == null
                  ? "RN"
                  : student.pivot.roll_number
              }}
            </td>
            <td>
              {{ student.first_name || " " }} {{ student.last_name || " " }}
            </td>

            <td
              v-for="(courseAttTypeCount,
              countIndex) in typeCountOfCourceInStudArray[student.id]"
              :key="countIndex"
              class="text-center"
              :style="
                courseAttTypeCount.percentage &&
                courseAttTypeCount.percentage > courseAttTypeCount.limit
                  ? 'color:red;'
                  : ''
              "
            >
              {{ courseAttTypeCount.count }}
              <span v-if="courseAttTypeCount.percentage > 0"
                >- {{ courseAttTypeCount.percentage }}
              </span>
            </td>

            <td class="text-center">
              {{ studentAttendanceTotel[student.id].totelAttendance }}/{{
                studentAttendanceTotel[student.id].maximumAttendance
              }}
            </td>
            <td>
              {{
                Number(
                  (
                    (studentAttendanceTotel[student.id].totelAttendance * 100) /
                    studentAttendanceTotel[student.id].maximumAttendance
                  ).toFixed(1)
                )
              }}
            </td>
          </tr>
        </tbody>
      </table>

      <p class="text-danger" v-else>No Data Found!</p>
    </div>
    <b-modal
      id="subgroupReport"
      no-close-on-backdrop
      no-close-on-keyboard
      no-close-on-esc
      hide-footer
      modal-class="subgroupReport"
    >
      <div class="loading" v-if="fetchData">
        <load-data></load-data>
      </div>
      <!-- subgroup students attendance report -->
      <div v-else>
        <div v-if="!courseId">
          <div class="closeButton">
            <b-button variant="danger" size="md" @click="closeReport()"
              >Close</b-button
            >
            <b-button
              class="ml-2"
              variant="primary"
              size="md"
              :disabled="exportCsvBtn.disabled"
              @click="exportToExcel('xlsx')"
              >{{ exportCsvBtn.text }}</b-button
            >
          </div>

          <br />
          <table
            class="table table-striped table-bordered"
            v-if="redyToViewSubgroupReport && students.length != 0"
            ref="subgroupReport"
          >
            <thead>
              <tr>
                <th colspan="100" class="text-center">
                  {{ reports.tableName }}
                </th>
              </tr>
              <tr>
                <th></th>
                <th>Students</th>
                <th
                  :colspan="attendanceTypes.length"
                  v-for="(course, coursesIndex) in courses"
                  :key="coursesIndex"
                  class="text-capitalize"
                >
                  {{ course.name }}
                </th>
                <th>Total</th>
                <th>%</th>
              </tr>
              <tr>
                <th></th>
                <th></th>
                <th
                  v-for="(attendanceType,
                  attendanceTypeIndex) in courseAttandanceTypesHeaderArray"
                  :key="attendanceTypeIndex"
                >
                  {{ attendanceType }}
                </th>

                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(student, studentIndex) in students"
                :key="studentIndex"
              >
                <td>
                  {{
                    student.pivot.roll_number == null
                      ? "RN"
                      : student.pivot.roll_number
                  }}
                </td>
                <td>
                  {{ student.first_name || " " }} {{ student.last_name || " " }}
                </td>

                <td
                  v-for="(courseAttTypeCount,
                  countIndex) in typeCountOfCourceInStudArray[student.id]"
                  :key="countIndex"
                  class="text-center"
                >
                  {{ courseAttTypeCount.count }}
                </td>
                <!-- </span> -->
                <td class="text-center">
                  {{ studentAttendanceTotel[student.id].totelAttendance }}/{{
                    studentAttendanceTotel[student.id].maximumAttendance
                  }}
                </td>
                <td>
                  {{
                    Number(
                      (
                        (studentAttendanceTotel[student.id].totelAttendance *
                          100) /
                        studentAttendanceTotel[student.id].maximumAttendance
                      ).toFixed(1)
                    )
                  }}
                </td>
              </tr>
            </tbody>
          </table>

          <p class="text-danger" v-else>No Data Found!</p>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import XLSX from "xlsx";
export default {
  async created() {
    if (this.courseId) this.prepareSubgroupReport();
  },
  props: ["reports", "courseId"],
  data() {
    return {
      fetchData: false,
      //-----------------------------------------Export To CSV-------------------------------------------------
      exportCsvBtn: {
        text: "Export to Excel",
        disabled: false,
      },
      //---------------------------------------------type 2-----------------------------------------------------
      redyToViewSubgroupReport: false,
      courses: [],
      coursesObjects: {}, //access with id
      subgrpStudCourseAttTypeCount: {}, //subgroupStudentsCourseViseAttendancetypeCountArray
      typeCountOfCourceInStudArray: {}, // {student: [1st type count of cource 1, 2n dtypecount ,..], ..} for tabl eview
      studentAttendanceTotel: {}, // totel attendanmce of each student
      courseAttandanceTypesHeaderArray: [],
      //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxtype 2xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

      students: [],
      studentsObjects: {}, //access with id
      attendanceTypes: [],
      attendanceTypesObjects: {}, //access with id

      report: {
        from: "",
        to: "",
        status: null,
      },
      statusOptions: [],
      reportBtn: {
        text: "Search",
        disabled: false,
      },
    };
  },
  methods: {
    async exportToExcel(type, fn, dl) {
      this.exportCsvBtn.text = "Please wait...";
      this.exportCsvBtn.disabled = true;
      var elt = this.$refs.subgroupReport;
      var wb = await XLSX.utils.table_to_book(elt, { sheet: "Sheet JS" });
      this.exportCsvBtn.text = "Export to Excel";
      this.exportCsvBtn.disabled = false;
      return dl
        ? XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" })
        : XLSX.writeFile(
            wb,
            fn ||
              (this.reports.name + "." || "studentsList.") + (type || "xlsx")
          );
    },
    closeReport() {
      this.$bvModal.hide("assessmentReport");
      this.$emit("closeReport");
    },
    //---------------------------------------------type 2-----------------------------------------------------
    async prepareSubgroupReport() {
      this.$bvModal.show("subgroupReport");
      this.fetchData = true;
      this.redyToViewSubgroupReport = false;
      await this.getReportStatusOptions();
      await this.getAttendenceTypes();
      // await this.getSessions();

      let report = await this.getSubgroupReport();

      let students = (this.students = report.students);

      let attTypesLimit = report.attTypesLimit;
      await this.attendanceTypes.forEach((attType) => {
        if (attTypesLimit) {
          this.attendanceTypesObjects[attType.id]["limit"] = null;
          if (attType.id in attTypesLimit) {
            attType["limit"] = parseInt(attTypesLimit[attType.id]);
            this.attendanceTypesObjects[attType.id]["limit"] = parseInt(
              attTypesLimit[attType.id]
            );
          }
        }
      });

      let courses = (this.courses = report.courses);

      let attendanceTypes = this.attendanceTypes;
      let dataArray = {}; //subgroupStudentsCourseViseAttendancetypeCountArray
      await students.forEach((student) => {
        dataArray[student.id] = {};
        courses.forEach((course) => {
          dataArray[student.id][course.id] = {};
          attendanceTypes.forEach((attendanceType) => {
            dataArray[student.id][course.id][attendanceType.id] = 0;
          });
        });
      });

      //this will combine attendance date session course student attendavcetype etc
      let dateSessionCourseStudentAttendancetype = await this.prepareAttendanceAndDates(
        report.attendanceDates,
        report.attendances
      );

      // count from dateSessionCourseStudentAttendancetype array and make dataArray
      await dateSessionCourseStudentAttendancetype.forEach((attendance) => {
        dataArray[attendance.student][attendance.course][
          attendance.attendanceType
        ] += 1;
      });

      this.studentAttendanceTotel = await this.prepareStudentsTotelAttendance(
        dateSessionCourseStudentAttendancetype
      );
      this.subgrpStudCourseAttTypeCount = dataArray;

      //prepare table data columns
      await students.forEach((student) => {
        this.typeCountOfCourceInStudArray[student.id] = [];
        courses.forEach((course) => {
          attendanceTypes.forEach((type) => {
            let studAttTypePercentage = null;
            if (
              this.studentAttendanceTotel[student.id].maximumAttendance > 0 &&
              type.limit
            )
              studAttTypePercentage =
                Math.round(
                  ((dataArray[student.id][course.id][type.id] * 100) /
                    this.studentAttendanceTotel[student.id].maximumAttendance) *
                    10
                ) / 10;
            this.typeCountOfCourceInStudArray[student.id].push({
              count: dataArray[student.id][course.id][type.id],
              percentage: studAttTypePercentage,
              limit: type.limit,
            }); // for each student an array with attendance type counts with respect to attendamnce types order again withrespect to course order
          });
        });
      });

      // console.log(this.typeCountOfCourceInStudArray);

      //prepare header
      await courses.forEach(() => {
        attendanceTypes.forEach((type) => {
          let headername = type.code;
          if (type.limit) {
            headername += `-Limit: ${type.limit}%`;
          }
          this.courseAttandanceTypesHeaderArray.push(headername);
        });
      });

      await this.sortedArray();
      this.redyToViewSubgroupReport = true;
      this.fetchData = false;
    },

    async sortedArray() {
      //  await this.students.sort(function (a, b) {
      //     return (
      //       a.pivot.roll_number - b.pivot.roll_number ||
      //       a.first_name.localeCompare(b.first_name)
      //     );
      //   });
      return;
    },

    async getSubgroupReport() {
      let postData = {
        start_date: this.reports.from,
        upto_date: this.reports.to,
        usersubgroup: this.reports.id,
        course: this.courseId,
      };
      let url =
        this.$store.getters.getAPIKey.mainAPI +
        `/attendancereports/usersubgroup`;
      if (this.courseId)
        url =
          this.$store.getters.getAPIKey.mainAPI + `/attendancereports/course`;
      let reports = [];
      await axios.post(url, postData).then((response) => {
        reports = response.data;
      });

      return reports;
    },

    async getReportStatusOptions() {
      this.statusOptions = await [
        { value: null, text: "Select Status", disabled: true },
      ];
    },

    async prepareStudentsTotelAttendance(
      dateSessionCourseStudentAttendancetype
    ) {
      //calculate totel attendamce
      let studentAttendanceTotel = {}; // maximum and totel attendance of each students

      // await dateSessionCourseStudentAttendancetype.forEach((attendance) => { // ----backup this couse issue when course list empty
      await this.students.forEach((student) => {
        studentAttendanceTotel[student.id] = {
          totelAttendance: 0,
          maximumAttendance: 0,
        };
      });

      await dateSessionCourseStudentAttendancetype.forEach((attendance) => {
        if (
          this.attendanceTypesObjects[attendance.attendanceType]
            .positive_report_value == 1
        ) {
          studentAttendanceTotel[attendance.student].totelAttendance += 1;
        }
        studentAttendanceTotel[attendance.student].maximumAttendance += 1;
      });

      return studentAttendanceTotel;
    },

    async prepareAttendanceAndDates(attendanceDates, attendances) {
      //this will combine attendance date session course student attendavcetype etc
      let attendanceDateArray = {};
      await attendanceDates.forEach((attendanceDate) => {
        attendanceDateArray[attendanceDate.id] = attendanceDate;
      });

      let attendanceArray = [];
      await attendances.forEach((attendance) => {
        attendanceArray.push({
          course: attendanceDateArray[attendance.attendance_date_id].course_id,
          date: attendanceDateArray[attendance.attendance_date_id].date_time,
          session:
            attendanceDateArray[attendance.attendance_date_id].session_id,
          student: attendance.attendee_id,
          attendanceType: attendance.attendance_type_id,
        });
      });

      return attendanceArray;
    },

    async getAttendenceTypes() {
      // this.fetchData = true;
      const url = this.$store.getters.getAPIKey.attendenceType;
      // let attendanceTypes = [];
      await axios.get(url).then((response) => {
        let attendanceTypes = response.data;
        attendanceTypes.forEach((attendenceType) => {
          this.$set(
            this.attendanceTypesObjects,
            [attendenceType.id],
            attendenceType
          );
          // this.attendanceTypesObjects[attendenceType.id] = attendenceType;
        });

        this.attendanceTypes = attendanceTypes;
        // this.fetchData = false;
      });
      // return attendanceTypes;
    },

    async getSessions() {
      const url = this.$store.getters.getAPIKey.createSessions;
      await axios.get(url).then((response) => {
        let sessions = response.data;
        sessions.forEach((session) => {
          this.sessionsObjects[session.id] = session;
        });

        this.sessions = sessions;
      });
    },
  },
};
</script>

<style >
.subgroupReport .modal-dialog {
  max-width: 100%;
  max-height: 100vh;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  position: fixed;
  z-index: 100000;
}
.subgroupReport .modal-body {
  overflow: scroll;
}
</style>
