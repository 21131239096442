<template>
  <div>
    <b-skeleton-table v-if="fetchData" :rows="3" :columns="2" :table-props="{ bordered: true, striped: true }">
    </b-skeleton-table>
    <b-container class="m-1" v-else>
      <b-row>
        <h4>All reports are listed below. Select the reports to add them to course file</h4>
      </b-row> <br>
      <b-row>
        <b-col v-if="(reportOptions.length < 1)" class="text-info"> All reports are selected</b-col>
        <b-col @click="selectAllReports()" v-if="(reportOptions.length > 1)" sm="4" md="3" lg="2"
          class="glow-button btn border-warning p-1 m-1 shadow cursor-move rounded-lg">Select all reports
        </b-col>
        <b-col sm="4" md="3" lg="2"
          v-b-popover.hover.top="report.config === false ? 'This file has not been configured. Complete configuration and try downloading again.' : ''"
          :class="{ 'glow-button btn  p-1 m-1 shadow cursor-move rounded-lg': true, 'border-danger': report.config === false }"
          v-for="(report, index) in reportOptions" :key="index" @click="selectReport(index)">
          {{ report.name }}
        </b-col>
      </b-row>
      <br>
      <b-row>
        <h4>Selected reports are listed below. Grab and move to reorder the reports as required in course file. The
          index page will display list of reports in the same order</h4>
      </b-row> <br>

      <b-container>
        <b-col v-if="(selectedReports.length < 1)" sm="8" md="8" lg="8" class="text-danger shadow rounded-lg">
          Please choose reports from above
        </b-col>
        <draggable :list="selectedReports">
          <b-col style="cursor: grab;" sm="8" md="8" lg="8"
            class="shadow cursor-move rounded-lg p-2 my-2 d-flex justify-content-between"
            v-for="(report, index) in selectedReports" :key="index">
            <i class="fas fa-bars" aria-hidden="true"></i>
            <span class="mr-1">{{ (index + 1) }} </span>
            {{ report.name }}
            <i role="button" @click="deSelectReport(index)" class=" text-danger fa fa-times-circle-o"
              aria-hidden="true"></i>
          </b-col>
        </draggable>
      </b-container>

      <br> <br>

      <b-row>
      </b-row>
      <b-row v-if="loadData" class="text-danger"> Please wait....</b-row>
      <b-row v-else>
        <b-col sm="2"><b-button class="mt-2" variant="danger" @click="$emit('cancel')">Back</b-button></b-col>
        <b-col><b-button class="mt-2" variant="danger" @click="SaveDownloadCourseDiaryReports(true,false)">
            Save & Download Report</b-button>
            <b-button style="margin-left: 1px" class="mt-2" variant="primary" @click="SaveDownloadCourseDiaryReports(true,true)">
            Save & View Report</b-button> </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  props: ["courseReport"],
  async created() {
    this.fetchData = true;
    await this.getCourseDiaryReportsDetails();
    this.fetchData = false;
    // this.$forceUpdate();
  },
  data() {
    return {
      fetchData: false,
      loadData: false,
      reportOptions: [],
      selectedReports: [],
      save_config: false,
    };
  },
  methods: {
    selectReport(index) {
      if (this.reportOptions[index].config === false) {
        this.$toast.error('This file has not been configured. Complete configuration and try downloading again.', {
          position: "top",
          duration: 6000,
        });
        return;
      }
      this.selectedReports.push(this.reportOptions[index]);
      this.reportOptions.splice(index, 1);
    },
    deSelectReport(index) {
      this.reportOptions.push(this.selectedReports[index]);
      this.selectedReports.splice(index, 1);
    },
    async selectAllReports() {
      // Move objects with disabled property set to false from array1 to array2
      for (let i = this.reportOptions.length - 1; i >= 0; i--) {
        if (this.reportOptions[i].config != false) {
          this.selectedReports.push(this.reportOptions[i]);
          this.reportOptions.splice(i, 1);
        }
      }
    },
    async SaveDownloadCourseDiaryReports(save_config,viewReport) {
      this.loadData = true;
      let postData = {
        selected_reports: this.selectedReports,
        save_config: save_config,
      };
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/courses/${this.courseReport.course_id}/coursediaryreport/pdfexport`;
      await this.$axios
        .post(url, postData, { responseType: "blob" })
        .then((response) => {
         const pdfBlob = new Blob([response.data], {
            type: "application/pdf",
          });
          const pdfUrl = window.URL.createObjectURL(pdfBlob);
          let newTab = null;
          if (viewReport) {
            newTab = window.open(pdfUrl, "_blank");
          }
          if (!newTab) {
            if (viewReport)
              this.$toast.error(
                "Failed to show pdf file, insted the file will be downloaded",
                {
                  position: "top",
                  duration: 3000,
                }
              );
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "CourseDiaryList.pdf");
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
        })
        .catch((error) => {
          let errorText = "Failed to Download, please try later";
          if (error.response != undefined && error.response.data.error) {
              errorText = error.response.data.error;
              if(error.response.data.error_reports && error.response.data.error_reports.length >0){
                errorText = 'Failed to genarate the reports ';
                error.response.data.error_reports.forEach(report => {
                  errorText+=report.report+', '
                });
              }
          }

          this.$toast.error(errorText, {
              position: "top",
              duration: 6000,
          });

        })
      this.loadData = false;
    },
    addedToAvailableStudents() {
      // console.log(this.availableStudents);
      // console.log(this.selectedStudents);
    },
    addedSelectedStudents() {
      // console.log(this.availableStudents);
      // console.log(this.selectedStudents);
    },
    async getCourseDiaryReportsDetails() {
      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/courses/${this.courseReport.course_id}/coursediaryreport/options`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.reportOptions = response.data.course_reports_data;
          // pre selection if already saved
          let selected_reports_keys = response.data.selected_reports_keys ?? [];

          selected_reports_keys.forEach(selectedKey => {
            const repo = this.reportOptions.find(obj => obj.key == selectedKey);
            if (repo && repo.config != false) {
              this.selectedReports.push(repo);
              this.reportOptions.splice(this.reportOptions.indexOf(repo), 1);
            }
          });
        })
        .catch((error) => {
          error;
        });
      this.fetchData = false;
    },

  },
};
</script>

<style >
.glow-button {
  transition: all 0.2s ease-in-out;
}

.glow-button:hover {
  box-shadow: 0 5px 15px rgba(145, 92, 182, .4) !important;
}
</style>
