<template>
  <div>
    <div class="additionalInfo">
      <br>
      <div v-if="$store.getters.defaultInstitute.userRole != 'Student'" class=""
        style="border: 2px solid #17a2b8; padding: 15px; border-radius: 5px;">
        <ul>
          You have two options to copy course data:
          <li>
            Copy from University Resources <span style="color: #2929aa; text-decoration: underline; cursor: pointer;"
              @click="copy_from = 'template'; $bvModal.show('copyCoursePropertiesModel')">Click here to proceed</span>
          </li>
          <li>Copy from previous academic year <span style="color: #2929aa; text-decoration: underline; cursor: pointer;"
              @click="copy_from = 'myinstitute'; $bvModal.show('copyCoursePropertiesModel')">Click here to
              proceed</span>
          </li>
        </ul>
        
        <b-modal id="copyCoursePropertiesModel" no-close-on-backdrop no-close-on-keyboard no-close-on-esc hide-footer
          hide-header centered size="md">
          <CopyCourseProperties :copy_from_suggestion="copy_from" :course_id="courseInfo.id" @success="propertyCopyCompleted()"
            @cancel="$bvModal.hide('copyCoursePropertiesModel')" />
        </b-modal>
      </div>
      <div v-if="$store.getters.defaultInstitute.userRole != 'Student'" class="text-center m-2 text-primary"> OR <br>Enter Manually Below </div>
      <b-form @submit.prevent="updateCourse()">
        <div class="mb-2 text-right">
          <b-button v-if="view && $store.getters.defaultInstitute.userRole != 'Student'" variant="info"
            @click="view = !view">Edit</b-button>
        </div>
        <b-form-group label="Learning Materials Provided">
          <b-form-textarea rows="3" max-rows="6" v-model="course.details_of_lerning_matials" v-if="!view"
            placeholder="Learning Materials Provided"></b-form-textarea>

          <pre style="white-space: pre-line; magrin: 0px" v-else>
            {{
                course.details_of_lerning_matials
                  ? course.details_of_lerning_matials
                  : "-"
            }}
          </pre>
        </b-form-group>
        <b-form-group label="Content Beyond The Syllabus">
          <b-form-textarea rows="3" max-rows="6" v-model="course.content_beyond_syllabus" v-if="!view"
            placeholder="Content Beyond The Syllabus"></b-form-textarea>

          <pre style="white-space: pre-line; magrin: 0px" class="m-0" v-else>
            {{
                course.content_beyond_syllabus
                  ? course.content_beyond_syllabus
                  : "-"
            }}
          </pre>
        </b-form-group>
        <b-form-group label="Industrial Relevance Of Course">
          <b-form-textarea rows="3" max-rows="6" v-if="!view" v-model="course.industrial_relevence"
            placeholder="Industrial Relevance Of Course"></b-form-textarea>

          <pre style="white-space: pre-line; magrin: 0px" v-else>
            {{
                course.industrial_relevence ? course.industrial_relevence : "-"
            }}
          </pre>
        </b-form-group>
        <b-form-group v-if="!view">
          <b-button type="submit" variant="primary" size="sm" :disabled="additionalBtn.disabled">{{ additionalBtn.text
          }}</b-button>
        </b-form-group>
      </b-form>
    </div>
  </div>
</template>

<script>
import CopyCourseProperties from "./CopyCourseProperties.vue"
export default {
  props: ["courseInfo"],
  created() {
    this.$set(this.course, "name", this.courseInfo.name);
    this.$set(this.course, "code", this.courseInfo.code);
    this.$set(
      this.course,
      "details_of_lerning_matials",
      this.courseInfo.property.details_of_lerning_matials
    );
    this.$set(
      this.course,
      "content_beyond_syllabus",
      this.courseInfo.property.content_beyond_syllabus
    );
    this.$set(
      this.course,
      "industrial_relevence",
      this.courseInfo.property.industrial_relevence
    );
  },
  components: {
    CopyCourseProperties,
  },
  data() {
    return {
      course: {},
      view: true,
      additionalBtn: {
        text: "Submit",
        disabled: false,
      },
      copy_from:'',
    };
  },
  methods: {
    propertyCopyCompleted() {
      this.$bvModal.hide('copyCoursePropertiesModel');
      this.$emit("CourseUpdated");
    },
    async updateCourse() {
      this.additionalBtn.text = "Please Wait...";
      this.additionalBtn.disabled = true;
      const url =
        this.$store.getters.getAPIKey.updateCourse.replace(
          "course_id",
          this.courseInfo.id
        ) + `?course_property=1`;

      await this.$axios
        .put(url, this.course)
        .then((response) => {
          response;
          this.view = !this.view;
          this.$emit("CourseUpdated");
        })
        .catch((error) => {
          error;
        });
      this.additionalBtn.text = "Submit";
      this.additionalBtn.disabled = false;
    },
  },
};
</script>
