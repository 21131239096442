<template>
  <div>
    <div class="viewInstitute">
      <div class="edit p-2 d-flex justify-content-end">
        <b-button size="sm" variant="info" @click="$emit('editInstitute')">Edit</b-button>
      </div>
      <div class="name text-center">
        <p class="p-0 m-0">{{ selectedInstitute.name }}</p>
        <p class="p-0 m-0 text-info">Institute Code: {{ selectedInstitute.code }}</p>
      </div>
      <p class="text-center p-0 m-0">{{ selectedInstitute.name_2 }}</p>
      <p class="text-center p-0 m-0">
        {{ selectedInstitute.name_3 }}
      </p>
      <p class="text-center p-0 m-0">
        {{ selectedInstitute.university }}
      </p>
      <br />
      <div class="address">
        <span class="font-weight-bolder">Address: </span> <br />
        <span>{{ selectedInstitute.address }}</span> <br />
        <span v-if="selectedInstitute.address2 != null">{{
        selectedInstitute.address2
        }}</span>
        <br />
        <span>{{ selectedInstitute.state }}</span> <br />
        <span>{{ selectedInstitute.country }}</span> <br />
        <span>{{ selectedInstitute.pincode }}</span> <br />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["selectedInstitute"],
};
</script>

<style >
.viewInstitute {
  border: 2px solid #17a2b8;
  padding: 20px;
  border-radius: 5px;
  font-weight: 600;
}

.viewInstitute .name p {
  font-size: 16px !important;
}

.viewInstitute .name p:nth-child(2) {
  font-size: 12px !important;
}
</style>
