<template>
  <div>
    <b-skeleton-table
      v-if="fetchData"
      :rows="4"
      :columns="6"
      :table-props="{ bordered: true, striped: true }"
    ></b-skeleton-table>
    <div class="mainGroupOrUsergroup" v-else>
      <b-tabs
        content-class="mt-3"
        fill
        justified
        lazy
        v-if="mainGroupAdmin && userGroupAdmin"
      >
        <b-tab
          :title="
            getCustomNames.maingroupAdmin.custom_name != null
              ? 'For ' + getCustomNames.maingroupAdmin.custom_name
              : 'Maingroup'
          "
        >
          <Usergroup
            :instituteAdmin="instituteAdmin"
            :mainGroupAdmin="mainGroupAdmin"
            :userGroupAdmin="userGroupAdmin"
            :groups="groups"
            :getCustomNames="getCustomNames"
            :userGroupsTypesOptions="userGroupsTypesOptions"
            :adminList="adminList"
            @adminsUpdated="getUsergroups()"

          />
        </b-tab>
        <b-tab
          :title="
            getCustomNames.userGroupAdmin.custom_name != null
              ? 'For ' + getCustomNames.userGroupAdmin.custom_name
              : 'Usergroup'
          "
        >
          <b-modal
            size="lg"
            id="usergroup"
            hide-footer
            centered
            no-close-on-backdrop
            no-close-on-esc
            @close="groupInfo = {}"
          >
            <Creation
              @GroupCreated="GroupCreated"
              :groupInfo="groupInfo"
              :adminList="adminList"
              :mainGroupId="mainGroupId"
              :getCustomNames="getCustomNames"
              :userGroupsTypesOptions="userGroupsTypesOptions"
            />
          </b-modal>
          <ProgramSpecificOutcome
            :groupId="groupId"
            @backToGroups="backToGroups"
            v-if="pso"
          />
          <ViewGroups
            v-else
            :usergroupFromMain="groups"
            :getCustomNames="getCustomNames"
            :adminList="adminList"
            @goToPSO="goToPSO"
            @getGroup="getGroup"
            @adminsUpdated="getUsergroups()"
          />
        </b-tab>
      </b-tabs>
      <div v-else-if="userGroupAdmin && !mainGroupAdmin">
        <b-modal
          size="lg"
          id="usergroup"
          hide-footer
          centered
          no-close-on-backdrop
          no-close-on-esc
          @close="groupInfo = {}"
        >
          <Creation
            @GroupCreated="GroupCreated"
            :groupInfo="groupInfo"
            :adminList="adminList"
            :mainGroupId="mainGroupId"
            :getCustomNames="getCustomNames"
            :userGroupsTypesOptions="userGroupsTypesOptions"
            @mainGroupCreated="mainGroupCreated"
          />
        </b-modal>
        <ProgramSpecificOutcome
          :groupId="groupId"
          @backToGroups="backToGroups"
          v-if="pso"
        />
        <ViewGroups
          v-else
          :usergroupFromMain="groups"
          :getCustomNames="getCustomNames"
          @goToPSO="goToPSO"
          @getGroup="getGroup"
          :adminList="adminList"
          @adminsUpdated="getUsergroups()"
        />
      </div>
      <Usergroup
        v-else
        :instituteAdmin="instituteAdmin"
        :mainGroupAdmin="mainGroupAdmin"
        :userGroupAdmin="userGroupAdmin"
        :groups="groups"
        :getCustomNames="getCustomNames"
        :userGroupsTypesOptions="userGroupsTypesOptions"
        :adminList="adminList"
        @adminsUpdated="getUsergroups()"

      />
    </div>
  </div>
</template>

<script>
import Usergroup from "./Usergroup";
import ViewGroups from "./ViewGroups";
import ProgramSpecificOutcome from "../Institute/settings/SettingsSpecificOutcome";
import acl from "../../../../authorization/acl";
import Creation from "./Creation";

export default {
  props: ["getCustomNames"],
  async created() {
    this.fetchData = true;
    this.instituteAdmin = await acl.isUserGranted("institutionAdmin");
    this.mainGroupAdmin = await acl.isUserGranted("mainGroupAdmin");
    this.userGroupAdmin = await acl.isUserGranted("groupAdmin");
    await this.loadData();
    this.fetchData = false;
  },
  components: {
    Usergroup,
    ProgramSpecificOutcome,
    ViewGroups,
    Creation,
  },
  data() {
    return {
      fetchData: false,
      instituteAdmin: null,
      mainGroupAdmin: null,
      userGroupAdmin: null,
      groups: [],
      pso: false,
      groupId: null,
      groupInfo: {},
      mainGroupId: null,
      userGroupsTypesOptions: [],
      adminList: [],
    };
  },
  methods: {
    async loadData() {
      this.fetchData = true;
      await this.getFacultyDetails();
      await this.getUsergroups();
      await this.getUsergroupsTypes();
      this.fetchData = false;
    },
    goToPSO(groupId) {
      this.groupId = groupId;
      this.pso = !this.pso;
    },
    backToGroups() {
      this.pso = false;
      this.groupId = null;
    },
    async getGroup(data) {
      this.groupInfo = data;
      this.$bvModal.show("usergroup");
    },
    async GroupCreated(status) {
      this.$bvModal.hide("usergroup");
      status ? await this.getUsergroups() : "";
      this.groupInfo = {};
    },
    async getUsergroups() {

      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usergroups/allusergroups?with_admins=1`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.groups = response.data;

          response;
        })
        .catch((error) => {
          error;
        });
    },
    async getFacultyDetails() {
      const url = this.$store.getters.getAPIKey.instituitionFaculty;
      await this.$axios
        .get(url)
        .then((response) => {
          this.adminList = [];
          this.adminList = response.data;
        })
        .catch((error) => {
          error;
        });
    },
    async getUsergroupsTypes() {
      let customName;
      if (this.getCustomNames.userGroup.custom_name != null) {
        customName =
          "Select " + this.getCustomNames.userGroup.custom_name + "Type";
      } else {
        customName = "Select Usergroup Type";
      }
      const url = this.$store.getters.getAPIKey.mainAPI + `/programmetypes`;
      await this.$axios.get(url).then((res) => {
        this.userGroupsTypesOptions = [
          {
            value: null,
            text: customName,
          },
        ];
        res.data.forEach(({ id, name }) => {
          this.userGroupsTypesOptions.push({
            value: id,
            text: name,
          });
        });
      });
    },
  },
};
</script>

<style >
.mainGroupOrUsergroup .nav-tabs .nav-link {
  background-color: #077bff !important;
  color: #ffffff !important;
  border: 1px solid white;
  font-size: 14px;
}
.mainGroupOrUsergroup .nav-tabs .nav-link.active {
  background-color: #ffffff !important;
  color: #077bff !important;
}

.mainGroupOrUsergroup ul {
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 5px;
}
.mainGroupOrUsergroup ul li {
  border: 3px solid #f8f9fa !important;
}

.mainGroupOrUsergroup .tab-content {
  border: none !important;
}
</style>
