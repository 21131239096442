var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.fetchData)?_c('div',{staticClass:"loading"},[_c('load-data')],1):_c('div',{staticClass:"submitForms"},[_c('div',{staticClass:"approvalListing"},[_c('b-overlay',{attrs:{"show":_vm.loadApproval,"spinner-variant":"primary","spinner-type":"grow","spinner-small":"","rounded":"md"}},[(_vm.surveyApprovals.length == 0)?_c('p',{staticClass:"text-danger"},[_vm._v("No Forms Found!")]):_c('div',{staticClass:"studentEvent"},_vm._l((_vm.surveyApprovals),function(approval,approvalIndex){return _c('div',{key:approvalIndex,staticClass:"event mt-2 p-3 border border-info rounded",style:(approvalIndex % 2 === 0
                ? {
                    backgroundColor: '#F0F0F0',
                    fontSize: 14 + 'px',
                    fontWeight: 600,
                  }
                : {
                    backgroundColor: '#FFFFFF',
                    fontSize: 14 + 'px',
                    fontWeight: 600,
                  })},[_c('div',{staticClass:"eventName text-center mb-2"},[_c('span',{class:approval.summary != null ? 'text-info' : 'text-dark',attrs:{"id":'approvalSummary' + approvalIndex}},[_vm._v(_vm._s(approval.name))]),(approval.summary != null)?_c('b-tooltip',{attrs:{"target":'approvalSummary' + approvalIndex,"triggers":"hover"}},[_vm._v(" "+_vm._s(approval.summary)+" ")]):_vm._e()],1),_c('div',{staticClass:"date mb-2 d-flex justify-content-between"},[_c('div',{staticClass:"startDate"},[_vm._v(" "+_vm._s(approval.start_at.format("DD/MM/YYYY"))+" "),_c('br'),_vm._v(" "+_vm._s(approval.start_at.format("hh:mm a"))+" ")]),_c('div',{staticClass:"endDate"},[_vm._v(" "+_vm._s(approval.end_at.format("DD/MM/YYYY"))+" "),_c('br'),_vm._v(" "+_vm._s(approval.end_at.format("hh:mm a"))+" ")])]),_c('div',{staticClass:"submitBtn"},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('application' + approvalIndex),expression:"'application' + approvalIndex"}],staticStyle:{"font-size":"14px","height":"30px","text-align":"center"},attrs:{"variant":"primary","size":"sm","block":""}},[_vm._v("Submit Form")]),_c('b-collapse',{staticClass:"mt-3",attrs:{"id":'application' + approvalIndex}},[(
                    approval.multiple_application || approval.applications.length == 0
                  )?_c('b-button',{staticClass:"mb-1",staticStyle:{"height":"30px","font-size":"12px"},attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.goToApplication(null, 1, approval.id)}}},[_vm._v("New Application")]):_vm._e(),_c('div',{staticClass:"d-flex flex-column"},_vm._l((approval.applications),function(application,applicationIndex){return _c('span',{key:applicationIndex,staticClass:"mb-2"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('span',[_vm._v(_vm._s(applicationIndex + 1)+") "+_vm._s(application.created_at != null ? _vm.convertTimeToLocal(application.created_at).format( "DD/MM/YYYY hh:mm a" ) : ""))]),(application.end_at != null)?_c('p',{staticClass:"text-success"},[_vm._v(" Submitted ")]):_c('b-button',{staticStyle:{"height":"30px","font-size":"12px"},attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.goToApplication(
                            application.id,
                            application.end_at,
                            approval.id
                          )}}},[_vm._v(" Continue ")])],1)])}),0)],1)],1)])}),0)])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }