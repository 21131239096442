<template>
<div>
    <div class="loading" v-if="fetchData">
        <load-data></load-data>
    </div>
    <div class="examsListing" v-else>
        <b-overlay :show="loadExams" spinner-variant="primary" spinner-type="grow" spinner-small rounded="md">

            <div class="examinationBtn p-2 d-flex justify-content-between" style="border: 2px solid #eeeeeb;border-left-style: none; border-right-style: none;" v-if="!addExam">
                <b-button v-if="instructorAssessmentAccess" variant="info" @click="addExam = true"><i class="fas fa-plus-square text-white"></i>Add Activity</b-button>
                <div>
                    <span>Filter by: </span>
                    <b-button :variant="
              sortVia == 'assessment' ? 'success' : 'outline-secondary'
            " size="sm" pill @click="setSortViaAssesment()">
                        <!-- <i
                class="far fa-check-circle mr-1"
                v-if="sortVia == 'assessment'"
              ></i> -->
                        Assessment
                    </b-button>

                    <b-button :variant="
              sortVia == 'assignment' ? 'success' : 'outline-secondary'
            " @click="setSortViaAssignment()" size="sm" class="ml-4" pill>
                        <!-- <i
                class="far fa-check-circle mr-1"
                v-if="sortVia == 'assignment'"
              ></i> -->
                        Assignment
                    </b-button>
                    <b-button :variant="
              sortVia == 'tutorial' ? 'success' : 'outline-secondary'
            " @click="setSortViaTutorial()" size="sm" class="ml-4" pill>
                        Tutorial
                    </b-button>

                    <b-button v-if="(filterCourse != 0 || sortVia != '')" variant="warning" @click="filterCourse = 0; sortVia = '';" size="sm" class="ml-4" pill>
                        Clear
                    </b-button>
                </div>
                <div>
                    <b-form-select v-model="filterCourse" :options="filterCourseOptions" @change="courseFilterRefreshKey++" size="sm" class="mt-3"></b-form-select>
                </div>
            </div>

            <ExamCreation @examFormCancelled="examFormReset()" @getExams="getExams()" :examToBeUpdated="examToBeUpdated" :publicexamToBeUpdated="publicexamToBeUpdated" v-if="addExam" />

            <div class="examList" v-if="exams.length != 0 && !addExam">
                <ListExamsMobileView v-if="$screenSize <= 600" :exams="exams" :instructorAssessmentAccess="instructorAssessmentAccess" :studentAssessmentAccess="studentAssessmentAccess" :today="today" @updateExam="updateExam" @examVisibility="examVisibility" />
                <div class="p-3" v-else>
                    <div class="table-responsive rounded">
                        <table class="table table-striped table-bordered" v-if="exams.length != 0 || publicexams.length != 0">
                            <thead style="font-size: 14px; font-weight: 600; text-align: center" class="thead-dark">
                                <tr>
                                    <th>Activity Name</th>
                                    <th v-if="instructorAssessmentAccess"></th>
                                    <th v-if="instructorAssessmentAccess"></th>
                                    <th>Type</th>
                                    <th>Course Code</th>
                                    <th>Created Date</th>
                                    <!-- <th>Scheduled On</th> -->
                                    <th>Start</th>
                                    <th>End</th>
                                    <th v-if="instructorAssessmentAccess"></th>
                                    <th v-if="instructorAssessmentAccess"></th>
                                </tr>
                            </thead>
                            <!--********Public Exam Table *****************-->
                                <tbody>
                                    <tr style="font-size: 14px; font-weight: 600; text-align: center" v-for="(exam, examIndex) in publicexams" :key="examIndex">
                                        <td class="text-left">
                                            <b-tooltip :target="'exam' + examIndex" triggers="hover" placement="topright">
                                                <p style="font-size: 14px">{{ exam.summary }}</p>
                                            </b-tooltip>
                                            <span :class="[exam.deleted_at ? 'text-danger' : '']" :id="'exam' + examIndex">{{
                                                exam.name
                                            }}</span>
                                            <br>
                                            <div v-if="(exam.deleted_at != null)">
                                                <vue-countdown :time="timeToDeleteIn(exam.deleted_at)" v-slot="{ hours, minutes, seconds }">
                                                    <b-badge variant="danger" style="white-space: nowrap; margin-right: 2px;">Delete within {{
                                                        hours
                                                    }}:{{ minutes }}:{{ seconds }}
                                                    </b-badge>
                                                    <b-link @click="CancelDeletion(exam.id)">
                                                        <b-badge variant="success">Cancel</b-badge>
                                                    </b-link>
                                                </vue-countdown>
                                            </div>
                                            <b-button v-if="studentAssessmentAccess && today >= exam.startAt1 && today < exam.endAt1" variant="primary" size="sm" @click="startExam(exam.id)">
                                                Start Exam</b-button>
                                            &nbsp; <b-button variant="primary" v-if="studentAssessmentAccess && today >exam.endAt1" size="sm" @click="viewStudentScore(exam.id)">View Score</b-button>
                                            
                                        </td>
                                        <td v-if="instructorAssessmentAccess">
                                            <b-link @click="
                                                managePublicExamQuestions(exam)
                                                ">Create question paper</b-link>
                                        </td>
                                        <td v-if="instructorAssessmentAccess" class="align-middle text-center">
                                            <b-button variant="primary" size="sm" squared @click="summaryReport(exam.id)">
                                                Summary</b-button>
                                                 <b-button style="margin-top: 2px" variant="primary" size="sm" squared @click="viewScore(exam.id)">View Score</b-button>
                                        </td>                      
                                        <td class="align-middle">
                                            <span class="bg-info p-1 rounded text-white m-0">
                                                Online
                                            </span>
                                        </td>
                                        <td class="align-middle">
                                            <span class="bg-info p-1 rounded text-white">
                                                Non Course
                                            </span>
                                        </td>
                                        <td class="align-middle">
                                            <span v-if="exam.createdAt1.isValid()">
                                                {{ exam.createdAt1.format("DD/MM/YYYY") }}</span>
                                            <span v-else>Date not updated</span>
                                        </td>
                                        <td class="align-middle">
                                            <span v-if="exam.startAt1.isValid()">
                                                {{ exam.startAt1.format("DD/MM/YYYY") }}
                                                <br />
                                                {{ exam.startAt1.format("hh:mm a") }}
                                            </span>
                                            <span v-else>Date not updated</span>
                                        </td>
                                        <td class="align-middle">
                                            <span v-if="exam.endAt1.isValid()">
                                                {{ exam.endAt1.format("DD/MM/YYYY") }}
                                                <br />
                                                {{ exam.endAt1.format("hh:mm a") }}

                                            </span>
                                            <span v-else>Date not updated</span>
                                        </td>
                                        <td class="align-middle" v-if="instructorAssessmentAccess">
                                            <!--   <div :id="'hideExam' + examIndex">
                                            <b-popover :target="'hideExam' + examIndex" triggers="hover">
                                                {{
                                                        !exam.hidden
                                                            ? "This exam is visible to students. Click to make it invisible."
                                                            : "This exam is invisible to students. Click to make it visible."
                                                    }}
                                            </b-popover>
                                            <span v-if="!exam.hidden" @click="hideExam(exam.id, true)" class="btn p-0 m-0 text-info"><i class="far fa-eye"></i></span>
                                            <span @click="hideExam(exam.id, false)" class="btn p-0 m-0 text-info" v-else><i class="fas fa-eye-slash"></i></span>
                                        </div>-->
                                        </td>
                                        <td class="align-middle" v-if="instructorAssessmentAccess">
                                            <span class="text-info btn m-1 p-0" @click="getPublicExam(exam.id)"><i style="font-size: 16px !important;" class="far fa-edit"></i></span>
                                            <span class="text-danger btn m-1 p-0" @click="deletePublicExam(exam.id)">
                                                <i class="fas fa-trash" style="font-size: 16px !important;"></i>
                                            </span>
                                            <b-link class="m-1" @click="managePublicExamParticipants(exam)">Participants</b-link>
                                        </td>
                                    </tr>
                                </tbody>
                                <!--************end public Exam Table**************-->
                            <tbody>
                                <tr v-if="filteredExams.length == 0">
                                    <td colspan="100" class="text-danger text-center font-weight-bolder">
                                        No Activities!
                                    </td>
                                </tr>
                                <tr style="font-size: 14px; font-weight: 600; text-align: center" v-for="(exam, examIndex) in filteredExams" :key="examIndex" v-else>
                                    <td class="text-left">
                                        <b-tooltip :target="'exam' + examIndex" triggers="hover" placement="topright">
                                            <p style="font-size: 14px">{{ exam.summary }}</p>
                                        </b-tooltip>
                                        <span :class="[exam.deleted_at ? 'text-danger' : '']" :id="'exam' + examIndex">{{
                          exam.name
                      }}</span>
                                        <br>
                                        <div v-if="(exam.deleted_at != null)">
                                            <vue-countdown :time="timeToDeleteIn(exam.deleted_at)" v-slot="{ hours, minutes, seconds }">
                                                <b-badge variant="danger" style="white-space: nowrap; margin-right: 2px;">Delete within {{
                              hours
                          }}:{{ minutes }}:{{ seconds }}
                                                </b-badge>
                                                <b-link @click="CancelDeletion(exam.id)">
                                                    <b-badge variant="success">Cancel</b-badge>
                                                </b-link>
                                            </vue-countdown>
                                        </div>
                                        <br />

                                        <div v-if="studentAssessmentAccess" class="d-flex justify-content-between">
                                            <b-button v-if="canViewExamScore(exam)" variant="primary" size="sm" @click="
                          assessmentLink(
                            exam.activity_type,
                            exam.id,
                            'ExamScore'
                          )
                        ">View Score</b-button>
                                            <b-button v-else variant="primary" size="sm" disabled>View Score</b-button>

                                            <div v-if="(exam.offline_activity == 0)">
                                                <p v-if="exam.startAt1.isValid() && today < exam.startAt1" class="text-success">Upcoming
                                                    Activity</p>
                                                <b-button v-if="(exam.activity_type == 'assignment' && (today < exam.endAt1 || exam.respond_after_close))" variant="primary" size="sm" @click="assessmentLink(
                              exam.activity_type,
                              exam.id,
                              'ExamAnswers'
                            )">Submit Assignment</b-button>
                                                <div v-if="(exam.activity_type == 'assessment')">
                                                    <b-button v-if="canStartExam(exam)" variant="primary" size="sm" @click="assessmentLink(
                              exam.activity_type,
                              exam.id,
                              'ExamAnswers'
                            )">
                                                        Start Exam</b-button>
                                                    <p class="text-danger" v-if="today >= exam.endAt1">
                                                        Exam Over
                                                    </p>
                                                    <span v-if="(today < exam.endAt1 && exam.publish_result && exam.participant != null)">
                                                        <p v-if="exam.participant.end_at != null" class="text-danger">
                                                            Exam Over
                                                        </p>
                                                    </span>
                                                </div>
                                            </div>

                                        </div>

                                        <div v-if="instructorAssessmentAccess" class="d-flex justify-content-between">
                                            <!-- <b-button variant="primary" size="sm" @click="
                          assessmentLink(
                            exam.activity_type,
                            exam.id,
                            'ExamQues'
                          )
                        ">Add Ques</b-button> -->

                                            <b-button variant="primary" size="sm" @click="linkAttendance(exam.id, exam.name)" v-if="
                        exam.activity_type == 'tutorial'">Link attendance</b-button>

                                        </div>
                                    </td>
                                    <td v-if="instructorAssessmentAccess">
                                        <b-link @click="
                        manageExamQuestions(exam)
                      ">Create question paper</b-link>
                                    </td>
                                    <td v-if="instructorAssessmentAccess">
                                        <b-link @click="activityEvaluationTabOpen(exam.id)">
                                            Update mark</b-link>
                                    </td>
                                    <td>
                                        {{
                          exam.activity_type == "assessment"
                            ? "Assessment"
                            : exam.activity_type == "assignment"
                              ? "Assignment"
                              : "Tutorial"
                      }}
                                        <br>
                                        <span v-if="(exam.offline_activity == 1)" class="bg-info p-1 rounded text-white m-0">
                                            Offline
                                        </span>
                                    </td>
                                    <td class="align-middle">
                                        <span :class="
                        exam.courseCode != '-'
                          ? 'bg-info p-1 rounded text-white'
                          : ''
                      ">
                                            {{ exam.courseCode }}
                                        </span>
                                    </td>
                                    <td class="align-middle">
                                        <span v-if="exam.createdAt1.isValid()">
                                            {{ exam.createdAt1.format("DD/MM/YYYY") }}</span>
                                        <span v-else>Date not updated</span>
                                    </td>
                                    <!-- <td>
                    {{ exam.startAt1.format("DD/MM/YYYY") }}
                    </td> -->
                                    <td class="align-middle">
                                        <div v-if="(exam.activity_type == 'assessment' && exam.startAt1.isValid())">
                                            {{ exam.startAt1.format("DD/MM/YYYY") }}
                                            <span v-if="!exam.offline_activity">
                                                <br />
                                                {{ exam.startAt1.format("hh:mm a") }}
                                            </span>
                                        </div>
                                        <span v-else-if="exam.activity_type != 'assessment'">NA</span>
                                        <span v-else>Date not updated</span>
                                    </td>
                                    <td class="align-middle">
                                        <!-- <span v-if="!exam.offline_activity"> -->
                                        <span v-if="exam.endAt1.isValid()">
                                            {{ exam.endAt1.format("DD/MM/YYYY") }} <br />
                                            <span v-if="exam.activity_type == 'assessment'">{{
                            exam.endAt1.format("hh:mm a")
                        }}</span>
                                        </span>
                                        <span v-else>Date not updated</span>
                                        <!-- </span> -->
                                        <!-- <span v-else>N/A</span> -->
                                    </td>
                                    <td class="align-middle" v-if="instructorAssessmentAccess">
                                        <div :id="'hideExam' + examIndex">
                                            <b-popover :target="'hideExam' + examIndex" triggers="hover">
                                                {{
                              !exam.hidden
                                ? "This exam is visible to students. Click to make it invisible."
                                : "This exam is invisible to students. Click to make it visible."
                          }}
                                            </b-popover>
                                            <span v-if="!exam.hidden" @click="hideExam(exam.id, true)" class="btn p-0 m-0 text-info"><i class="far fa-eye"></i></span>
                                            <span @click="hideExam(exam.id, false)" class="btn p-0 m-0 text-info" v-else><i class="fas fa-eye-slash"></i></span>
                                        </div>
                                    </td>
                                    <td class="align-middle" v-if="instructorAssessmentAccess">
                                        <span class="text-info btn m-1 p-0" @click="getExam(exam.id)"><i style="font-size: 16px !important;" class="far fa-edit"></i></span>

                                        <span v-b-popover.hover="'Download Activity Reports'"
                                            class="text-info btn m-1 p-0" @click="reportDownload.exam = exam.id;
                                            reportDownload.enable_scrutiny_form = exam.enable_scrutiny_form;
                                            reportDownload.scrutiny_form_comment = exam.scrutiny_form_comment;
                                            reportDownload.exam = exam.id;
                                                $bvModal.show('ExamReportsModel');"><i class="fas fa-file-alt"
                                                style="font-size: 16px !important;"></i>
                                        </span>

                                        <span v-if="(exam.deleted_at == null)" class="text-danger btn m-1 p-0" @click="deleteExamModel.id = exam.id; deleteExamModel.name = exam.name; $bvModal.show('deleteExamConfirmationModel');"><i class="fas fa-trash" style="font-size: 16px !important;"></i>
                                        </span>

                                        <b-link v-if="exam.course_id != '-'" class="m-1" @click="manageParticipants(exam.id, exam.course_id)">Participants</b-link>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div v-else>
                            <p class="text-danger" v-if="exams.length == 0">
                                No Assessments Created!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </b-overlay>
    </div>
    <b-modal id="ManagePublicExamQuestionsModel" no-close-on-backdrop no-close-on-keyboard no-close-on-esc hide-footer size="lg">
        <ManagePublicExamQuestions :publicexams="examdetails" />
    </b-modal>
    <b-modal id="activityAttendancesModel" no-close-on-backdrop no-close-on-keyboard no-close-on-esc hide-footer centered size="sm" lazy>
        <activity-attendances lazy :activityIdForAttendance="activityIdForAttendance" :activityName="activityNameForAttendance">
        </activity-attendances>
    </b-modal>

    <b-modal id="manageParticipantsModel" no-close-on-backdrop no-close-on-keyboard no-close-on-esc hide-footer centered size="md" lazy>
        <ExamParticipants lazy :exam_id="manageParticipantsId" :course_id="manageParticipantsCourseId" />
    </b-modal>

    <b-modal id="managePublicExamParticipantsModel" no-close-on-backdrop no-close-on-keyboard no-close-on-esc hide-footer centered size="lg" lazy>
        <PublicExamParticipants lazy :publicexam="PublicExamArray" />
    </b-modal>

    <b-modal id="deleteExamConfirmationModel" hide-header-close hide-footer hide-header centered size="sm">
        <h6 style="font-size: 18px; font-weight: 600" class="text-danger">
            Are you sure you want to delete {{ deleteExamModel.name }}
        </h6>
        <div>
            <b-form-group id="input-group-1" label-for="input-1">
                <label class="required-label" for="">Type 'DELETE' and press delete button to complete deletion:</label>
                <b-form-input id="input-1" v-model="deleteExamModel.confirmText" type="text" placeholder="Enter  DELETE"></b-form-input>
            </b-form-group>
        </div>
        <div class="buttons">
            <b-button :disabled="deleteExamModel.confirmText != 'DELETE'" size="sm" variant="primary" @click="deleteExam()">Delete</b-button>
            <b-button size="sm" variant="danger" class="ml-2" @click="$bvModal.hide('deleteExamConfirmationModel')">Cancel</b-button>
        </div>
    </b-modal>

    <b-modal id="ExamReportsModel" hide-footer centered size="md" :title="'Reports for ' + (reportDownload.exam ? exams.find(obj => obj.id == reportDownload.exam).name : '')">
        <table class="table table-striped table-bordered" style="width:100%;">
            <tbody>
                <tr>
                    <th class="p-2">Question wise mark with CO mapping</th>
                    <td class="p-2">
                        <b-button variant="danger" @click="ExamEvaluationFormPdf(exams.find(obj => obj.id == reportDownload.exam).courseCode)">Download PDF</b-button>
                    </td>
                </tr>
                <tr>
                    <th class="p-2">Scrutiny Form</th>
                    <td class="p-2">
                        <span class="btn p-0 m-0 text-info font-weight-bolder"
                        @click="getScrutinyFormQuestions(reportDownload.exam);">Configure & Download</span>
                    </td>
                </tr>
                <tr>
                    <th class="p-2">Result Analysis</th>
                    <td class="p-2">
                        <b-form-group>
                            <label class="required-label" for="">Pass Mark:</label>
                            <b-form-input id="input-1" v-model="reportDownload.passMark" type="number" placeholder=""></b-form-input>
                        </b-form-group>
                        <b-button variant="danger" @click="ResultAnalysisPdf(exams.find(obj => obj.id == reportDownload.exam).courseCode)">Download PDF</b-button>
                    </td>
                </tr>
            </tbody>
        </table>
    </b-modal>
    <!--Scrutiny Questions Model-->
    <b-modal id="ScrutinyFormModel" hide-footer centered size="xl">
            <table class="table table-striped table-bordered">
                <thead class="thead-dark text-left">
                    <tr>
                        <th></th>
                        <th>Question</th>
                        <th>Option</th>
                        <th>Comment</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(scrutiny, qindex) in scrutinyFormQuestionOptions" :key="qindex">
                        <td>
                            <b-form-checkbox value="1" v-model="scrutiny.enable"></b-form-checkbox>
                        </td>
                        <td>
                            {{ scrutiny.question }}
                        </td>
                        <td>
                            <b-form-radio :value="1" class="ml-3" v-model="scrutiny.option">Yes</b-form-radio>
                            <b-form-radio class="ml-3" :value="0" v-model="scrutiny.option">No</b-form-radio>
                        </td>
                        <td>
                            <b-form-input id="input-1" v-model="scrutiny.comments"
                                placeholder="comment if any"></b-form-input>
                        </td>
                    </tr>
                </tbody>
            </table>
            <b-row class="mx-2 my-1">
                <span class="mr-1" style="align-self: center;"> Additional comments if any: </span>
                <b-form-input class="mr-4" v-model="reportDownload.scrutiny_form_comment"
                    style="width: 700x;"></b-form-input>
                <span class="mr-1" style="align-self: center;"> <b-form-checkbox :value="1"
                        v-model="reportDownload.enable_scrutiny_form"></b-form-checkbox> I hereby certify that: <br>
                    I have scrutinized the question paper and scheme of evaluation, and have recommended the required
                    corrections, if any, as mentioned above.</span>
            </b-row>
            <b-button :disabled="!reportDownload.enable_scrutiny_form ||reportDownload.enable_scrutiny_form==0" variant="primary" @click="ExamScrutinyFormPdf(true)">Save &amp; View</b-button>
            <b-button :disabled="!reportDownload.enable_scrutiny_form ||reportDownload.enable_scrutiny_form==0" variant="danger" @click="ExamScrutinyFormPdf(false)">Save &amp; Download</b-button>
        </b-modal>

    <!-- <b-modal id="manageExamQuestionsModel" no-close-on-backdrop no-close-on-keyboard no-close-on-esc hide-footer  centered
     lazy size="xl">
      <ManageExamQuestions lazy :exam="manageQuestionOfExam" />
    </b-modal> -->
</div>
</template>

<script>
import ExamCreation from "./ExamCreation.vue";
import moment from "moment";
import ListExamsMobileView from "./mobile/ListExamsMobileView.vue";
import acl from "../../../../authorization/acl.js";
import ActivityAttendances from "./activityAttendances.vue";
import ExamParticipants from "./examParticipants.vue";
import PublicExamParticipants from "../public_exam/publicexamParticipants.vue";
import ManagePublicExamQuestions from "../public_exam/managePublicExamQuestions.vue";

import VueCountdown from "@chenfengyuan/vue-countdown";
export default {
    async created() {
        // this.getCourseDetails();
        this.fetchData = true;

        const instructorAssessmentAccess = await acl.isUserGranted(
            "instructorAssessmentAccess"
        );
        this.instructorAssessmentAccess = instructorAssessmentAccess;
        const studentAssessmentAccess = await acl.isUserGranted(
            "studentAssessmentAccess"
        );
        this.studentAssessmentAccess = studentAssessmentAccess;
        // await this.getCourseDetails();
        await this.getExams();
        await this.getPublicExams();
    },
    components: {
        ExamCreation,
        ListExamsMobileView,
        ActivityAttendances,
        ExamParticipants,
        PublicExamParticipants,
        VueCountdown,
        ManagePublicExamQuestions,
    },
    data() {
        return {
            today: moment(),
            examdetails: '',
            fetchData: false,
            addExam: false,
            loadExams: false,
            exams: [],
            publicexams: [],
            // courseCodes: {},
            // courses: [],
            publicexamToBeUpdated: {},
            examToBeUpdated: {
                id: "",
                exam: {},
            },
            sortVia: "",
            studentAssessmentAccess: null,
            instructorAssessmentAccess: null,
            searchBy: "",
            // filterEventsBtn: {
            //   upcomings: "info",
            //   past: "info",
            //   upcomingEvents: true,
            //   pastEvents: true,
            // },
            activityIdForAttendance: null,
            activityNameForAttendance: "",
            filterCourse: 0,
            filterCourseOptions: [],
            courseFilterRefreshKey: 0,
            manageParticipantsCourseId: null,
            manageParticipantsId: null,
            managePublicExamParticipantsId: null,
            PublicExamArray: [],
            deleteExamModel: {
                id: null,
                name: '',
                confirmText: ''
            },
            // manageQuestionOfExam:null,
            scrutinyFormQuestionOptions : [],
            selectedScrutinyFormQuestionOptions : [],
            reportDownload: {
                exam: null,
                passMark: '50',
                enable_scrutiny_form: 0,
                scrutiny_form_comment: null
            },
        };
    },

    computed: {
        filteredExams() {
            this.filterCourse;
            this.sortVia;
            return this.exams.filter((exam) => {
                let cFilter = this.filterCourse != 0 ? exam.course_id == this.filterCourse : true
                let aFilter = this.sortVia == '' ? true : exam.activity_type == this.sortVia;
                return cFilter && aFilter;
            });
        },
    },
    methods: {
        async getScrutinyFormQuestions(exam_id) {
            await this.$axios
                .get(this.$store.getters.getAPIKey.examination + `/${exam_id}`)
                .then((response) => {
                    this.reportDownload.enable_scrutiny_form = response.data.enable_scrutiny_form;
                    this.reportDownload.scrutiny_form_comment = response.data.scrutiny_form_comment;
                })
                .catch((error) => {
                    error;
                });
            const url = this.$store.getters.getAPIKey.mainAPI + `/exams/${exam_id}/scrutinyformquestion`;
            await this.$axios
                .get(url)
                .then((response) => {
                    this.scrutinyFormQuestionOptions = response.data;
                    this.$bvModal.show('ScrutinyFormModel');
                })
                .catch((error) => {
                    error;
                });
        },
        async deletePublicExam(publicExamId) {
            if (
                confirm(
                    "Are you sure you want to delete this Exam?"
                )
            ) {
                this.fetchData = true;
                const url =
                    this.$store.getters.getAPIKey.mainAPI +
                    `/publicexams/${publicExamId}`;
                await this.$axios
                    .delete(url)
                    .then(() => {
                        this.examFormReset();
                    })
                    .catch((error) => {
                        error;
                    });
                this.fetchData = false;
            }
        },
        summaryReport(ExamId) {
            let route = this.$router.resolve({
                path: `/summary_report/${ExamId}`
            });
            window.open(route.href, '_blank');
        },
        viewStudentScore(activityId) {
            let type = 1;
            let route = this.$router.resolve({
                path: `/studentScore/${activityId}/${type}`
            });
            window.open(route.href, '_blank');
        },
        viewScore(ExamId) {
            let route = this.$router.resolve({
                path: `/facultyScoreCard/${ExamId}`
            });
            window.open(route.href, '_blank');
        },
        startExam(activityId) {
            let type = 1;
            let route = this.$router.resolve({
                path: `/publicexamanswers/${activityId}/${type}`
            });
            window.open(route.href, '_blank');
        },
        async ExamEvaluationFormPdf(courseCode) {
            const url =
                this.$store.getters.getAPIKey.mainAPI + `/exams/detailes/evaluationreport/pdfexport`;
            await this.$axios
                .post(
                    url, {
                        exam: this.reportDownload.exam,
                    }, {
                        responseType: "blob"
                    }
                )
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download",`Question wise mark with CO mapping-${courseCode}.pdf` )
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch(() => {
                    let errorText = "Failed to download, Please try again";
                    this.$toast.error(errorText, {
                        position: "top",
                        duration: 3000,
                    });
                });

        },

        async ResultAnalysisPdf(courseCode) {
            const url =
                this.$store.getters.getAPIKey.mainAPI + `/exams/resultanalysis/report/pdfexport`;
            await this.$axios
                .post(
                    url, {
                        exam: this.reportDownload.exam,
                        pass_mark: this.reportDownload.passMark,
                    }, {
                        responseType: "blob"
                    }
                )
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download",`Result Analysis-${courseCode}.pdf` )
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch(() => {
                    let errorText = "Failed to download, Please try again";
                    this.$toast.error(errorText, {
                        position: "top",
                        duration: 3000,
                    });
                });

        },
        async ExamScrutinyFormPdf(viewReport) {
            this.scrutinyFormQuestionOptions.forEach(question => {
                if(question.enable){
                    this.selectedScrutinyFormQuestionOptions.push(question);
                }  
            });
            const url =
                this.$store.getters.getAPIKey.mainAPI + `/exams/scrutinyform/report/pdfexport`;
            await this.$axios
                .post(
                    url, {
                    exam: this.reportDownload.exam,
                    enable_scrutiny_form: this.reportDownload.enable_scrutiny_form,
                    scrutiny_form_comment: this.reportDownload.scrutiny_form_comment,
                    selectedScrutinyFormQuestionOptions: this.selectedScrutinyFormQuestionOptions,
                }, {
                    responseType: "blob"
                }
                )
                .then((response) => {
                    const pdfBlob = new Blob([response.data], {
                        type: "application/pdf",
                    });
                    const pdfUrl = window.URL.createObjectURL(pdfBlob);
                    let newTab = null;
                    if (viewReport) {
                        newTab = window.open(pdfUrl, "_blank");
                    }
                    if (!newTab) {
                        if (viewReport)
                            this.$toast.error(
                                "Failed to show pdf file, insted the file will be downloaded",
                                {
                                    position: "top",
                                    duration: 3000,
                                }
                            );
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", "ExamScrutinyForm.pdf");
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                    }
                    this.selectedScrutinyFormQuestionOptions = [];
                    this.downloadData = false;                  
                })
                .catch(() => {
                    let errorText = "Failed to download, Please try again";
                    this.$toast.error(errorText, {
                        position: "top",
                        duration: 3000,
                    });
                });
        },
        timeToDeleteIn(deleted_at) {
            // Step 1: Add 24 hours to the API response time
            const futureTime = moment.utc(deleted_at, 'YYYY-MM-DD HH:mm:ss').add(24, 'hours');
            // Step 2: Get the current UTC time using moment.js
            const currentUTCTime = moment.utc();
            // Step 3: Calculate the difference in seconds
            const timeDifferenceInSeconds = futureTime.diff(currentUTCTime, 'seconds');
            return timeDifferenceInSeconds * 1000;
        },
        canStartExam(exam) {
            if (this.today < exam.startAt1 || this.today > exam.endAt1) return false;
            if (exam.participant != null)
                if (exam.participant.end_at != null && exam.publish_result)
                    return false;
            return true;
        },
        canViewExamScore(exam) {
            if (exam.offline_activity == 1) return true;
            if (exam.offline_activity == 0 && this.today > exam.endAt1) return true;
            if (exam.offline_activity == 0 && exam.participant != null)
                if (exam.participant.end_at != null && exam.publish_result)
                    return true;
            return false;
        },
        manageExamQuestions(exam) {
            // this.manageQuestionOfExam = exam;
            // this.$bvModal.show('manageExamQuestionsModel');

            this.$root.subContent.subContentComponent = "ManageExamQuestions";
            this.$root.subContent.subContentData = {
                exam: exam
            };
            this.$root.subContent.enableMainContent = false;
        },

        managePublicExamQuestions(exam) {
            this.examdetails = exam;
            this.$bvModal.show('ManagePublicExamQuestionsModel');

            //   this.$root.subContent.subContentComponent = "managePublicExamQuestions";
            //  this.$root.subContent.subContentData = {
            //      exam: exam
            //  };
            //  this.$root.subContent.enableMainContent = false;

            //   this.$router.push({ path: `/public_exam/questions/${exam.id}/${exam.name}` });
            //this.$root.subContent.subContentComponent = "ManagePublicExamQuestions";
            // this.$root.subContent.subContentData = {
            //     exam: exam
            // };
            //this.$root.subContent.enableMainContent = false;

        },
        manageParticipants(exam_id, course_id) {
            this.manageParticipantsId = exam_id;
            this.manageParticipantsCourseId = course_id;
            this.$bvModal.show('manageParticipantsModel');
        },
        managePublicExamParticipants(exam) {
            this.PublicExamArray = exam;
            this.$bvModal.show('managePublicExamParticipantsModel');
        },
        activityEvaluationTabOpen(exam_id) {
            this.$root.subContent.subContentComponent = "ActivityEvaluation";
            this.$root.subContent.subContentData = {
                exam_id: exam_id
            };
            this.$root.subContent.enableMainContent = false;
        },
        setSortViaAssesment() {
            if (this.sortVia == "assessment") {
                return;
            } else {
                this.sortVia = "assessment";
            }
        },
        setSortViaAssignment() {
            if (this.sortVia == "assignment") {
                return;
            } else {
                this.sortVia = "assignment";
            }
        },
        setSortViaTutorial() {
            if (this.sortVia == "tutorial") {
                return;
            } else {
                this.sortVia = "tutorial";
            }
        },
        updateExam(value) {
            this.getExam(value);
        },
        examVisibility(value) {
            this.hideExam(value.id, value.status);
        },
        getPublicExams() {
            this.fetchData = true;
            const url = this.$store.getters.getAPIKey.mainAPI +
                `/publicexams`;
            this.$axios
                .get(url)
                .then((response) => {
                    let publicexamsList = response.data;
                    publicexamsList.forEach((publicexam) => {
                        this.publicexams.push({
                            id: publicexam.id,
                            name: publicexam.name,
                            description: publicexam.summary,
                            startAt1: moment(
                                moment.utc(publicexam.starts_at, "YYYY-MM-DD HH:mm:ss").toDate()
                            ),
                            endAt1: moment(
                                moment.utc(publicexam.end_at, "YYYY-MM-DD HH:mm:ss").toDate()
                            ),
                            createdAt1: moment(new Date(publicexam.created_at)),
                            maximum_mark: publicexam.maximum_mark,
                            hidden: publicexam.hidden,
                        });
                    });
                    this.fetchData = false;
                    response;
                })
                .catch((error) => {
                    error;
                });
        },
        getExams() {
            this.fetchData = true;
            const url = this.$store.getters.getAPIKey.examination;
            this.$axios
                .get(url)
                .then((response) => {
                    // this.filterCourseOptions = {};
                    this.filterCourseOptions = [{
                        value: 0,
                        text: 'Select Course'
                    }];
                    this.exams = [];
                    let examsList = response.data;
                    // if (this.courses.length != 0) {
                    examsList.forEach((exam) => {
                        const examHidden = exam.hidden == 1 ? true : false;
                        if (this.studentAssessmentAccess && examHidden) {
                            ("DO Nothing");
                        } else {
                            let participant = exam.participants != null ? exam.participants.length > 0 ? exam.participants[0] : null : null
                            this.exams.push({
                                id: exam.id,
                                name: exam.name?exam.name:exam.activity_name.activity_name,
                                description: exam.summary,
                                startAt1: moment(
                                    moment.utc(exam.starts_at, "YYYY-MM-DD HH:mm:ss").toDate()
                                ),
                                endAt1: moment(
                                    moment.utc(exam.end_at, "YYYY-MM-DD HH:mm:ss").toDate()
                                ),
                                createdAt1: moment(new Date(exam.created_at)),
                                maximum_mark: exam.maximum_mark,
                                offline_activity: exam.offline_activity,
                                hidden: exam.hidden,
                                course_id: exam.course.length == 0 ? "-" : exam.course[0].id,
                                courseCode: exam.course.length == 0 ?
                                    "-" : `${exam.course[0].code}-${exam.course[0].usersubgroup.code}-${exam.course[0].usersubgroup.usergroup.code}`,
                                summary: exam.summary,
                                activity_type: exam.activity_type,
                                respond_after_close: exam.respond_after_close == 1 ? true : false,
                                auto_evaluation: exam.auto_evaluation == 1 ? true : false,
                                publish_result: exam.publish_result == 1 ? true : false,
                                is_objective_only: exam.is_objective_only == 1 ? true : false,
                                participant: participant,
                                deleted_at: exam.deleted_at,
                            });

                            let filteroption = {
                                value: exam.course[0].id,
                                text: `${exam.course[0].code}-${exam.course[0].usersubgroup.code}-${exam.course[0].usersubgroup.usergroup.code}`
                            };
                            this.filterCourseOptions.push(filteroption);
                            // var valObj = this.filterCourseOptions.filter(function (elem) {
                            //   if (elem.value == filteroption.value) return elem.Value;
                            // });
                            // if (valObj.length < 1) {
                            //   console.log(filteroption);
                            //   this.filterCourseOptions.push(filteroption);
                            // }

                        }
                    });
                    // }
                    this.sortExamsByDate();
                    // this.getUpcomingEvents();
                    // this.setSortViaAssesment();

                    this.fetchData = false;
                    response;
                })
                .catch((error) => {
                    error;
                });
        },

        sortExamsByDate() {
            return this.exams.sort((a, b) => new Date(a.endAt1) - new Date(b.endAt1));
        },
        async getPublicExam(publicexamId) {
            this.loadExams = true;
            const url =
                this.$store.getters.getAPIKey.mainAPI +
                `/publicexams/${publicexamId}`;
            await this.$axios
                .get(url)
                .then((response) => {
                    this.publicexamToBeUpdated = response.data;
                    this.examToBeUpdated.id = response.data.id;
                    (this.examToBeUpdated.exam.course_id = 0),
                        (this.addExam = true);
                    this.loadExams = false;
                    response;
                })
                .catch((error) => {
                    this.loadExams = false;
                    error;
                });
        },
        async getExam(assessmentId) {
            this.loadExams = true;
            const url =
                this.$store.getters.getAPIKey.examination + `/${assessmentId}`;
            await this.$axios
                .get(url)
                .then((response) => {
                    this.examToBeUpdated.id = response.data.id;
                    (this.examToBeUpdated.exam.name = response.data.name),
                    (this.examToBeUpdated.exam.activity_name_id = response.data.activity_name_id),
                    (this.examToBeUpdated.exam.summary = response.data.summary),
                    (this.examToBeUpdated.exam.category_id = response.data.category_id),
                    (this.examToBeUpdated.exam.offline_activity = response.data.offline_activity),
                    (this.examToBeUpdated.exam.course_id =
                        response.data.course.length != 0 ?
                        response.data.course[0].id :
                        ""),
                    // (this.examToBeUpdated.exam.category_id = `${response.data.category_id}`),
                    (this.examToBeUpdated.exam.hidden =
                        response.data.hidden == 1 ? true : false),
                    (this.examToBeUpdated.exam.co_score_evaluation =
                        response.data.co_score_evaluation == 1 ? true : false),
                    (this.examToBeUpdated.exam.respond_after_close =
                        response.data.respond_after_close == 1 ? true : false),
                    (this.examToBeUpdated.exam.starts_at = response.data.starts_at),
                    (this.examToBeUpdated.exam.end_at = response.data.end_at),
                    (this.examToBeUpdated.exam.return_at = response.data.return_at),
                    (this.examToBeUpdated.exam.limitted_time_seconds =
                        response.data.limitted_time_seconds),
                    (this.examToBeUpdated.exam.positive_mark =
                        response.data.positive_mark),
                    (this.examToBeUpdated.exam.negative_mark =
                        response.data.negative_mark),
                    (this.examToBeUpdated.exam.maximum_mark =
                        response.data.maximum_mark),
                    (this.examToBeUpdated.exam.activity_type =
                        response.data.activity_type);
                    this.examToBeUpdated.exam.exclude_from_report =
                        response.data.exclude_from_report == 1 ? true : false;
                    (this.examToBeUpdated.exam.negative_mark =
                        response.data.negative_mark),
                    (this.examToBeUpdated.exam.is_objective_only =
                        response.data.is_objective_only == 1 ? true : false),
                    (this.examToBeUpdated.exam.shuffle_questions =
                        response.data.shuffle_questions == 1 ? true : false),
                    (this.examToBeUpdated.exam.shuffle_choices =
                        response.data.shuffle_choices == 1 ? true : false),
                    (this.examToBeUpdated.exam.exclude_from_report =
                        response.data.exclude_from_report == 1 ? true : false),
                    (this.examToBeUpdated.exam.ordered_choice_list =
                        response.data.ordered_choice_list == 1 ? true : false),
                    (this.examToBeUpdated.exam.auto_evaluation =
                        response.data.auto_evaluation == 1 ? true : false),
                    (this.examToBeUpdated.exam.publish_result =
                        response.data.publish_result == 1 ? true : false),
                    (this.addExam = true);
                    this.loadExams = false;

                    response;
                })
                .catch((error) => {
                    this.loadExams = false;
                    error;
                });
        },
        async hideExam(examId, hideStatus) {
            this.fetchData = true;
            let url = this.$store.getters.getAPIKey.examination + `/${examId}`;
            await this.$axios
                .put(url, {
                    hidden: hideStatus
                })
                .then((response) => {
                    const msg = hideStatus ?
                        "Exam Hidden for Students" :
                        "Exam Visible for Students";
                    this.$toast.success(msg, {
                        position: "top",
                        duration: 3000,
                    });
                    this.getExams();
                    response;
                })
                .catch((error) => {
                    this.fetchData = false;

                    error;
                });
        },

        async CancelDeletion(id) {
            this.loadExams = true;
            const url =
                this.$store.getters.getAPIKey.examination + `/${id}/canceldeletion`;
            this.$axios
                .get(url)
                .then(() => {
                    this.getExams();
                    this.loadExams = false;
                });
        },
        async deleteExam() {
            let assessmentId = this.deleteExamModel.id;
            let assessmentName = this.deleteExamModel.name;
            if (
                confirm(
                    `WARNING: You are going to delete activity: ${assessmentName}. 
          This action will also delete Activity questions, answers, choices, student answers and uploaded files, student scores, question CO mapping, CO scores. 
          and you cannot use these data in any reports including continuous evaluation report, activity reports, Attainment reports`
                ) == true
            ) {
                this.loadExams = true;
                const url =
                    this.$store.getters.getAPIKey.examination + `/${assessmentId}`;
                await this.$axios
                    .delete(url)
                    .then(() => {
                        this.$bvModal.hide('deleteExamConfirmationModel');
                        this.$toast.success("Activity deletion initiated successfully", {
                            position: "top",
                            duration: 6000,
                        });
                        this.getExams();
                        this.loadExams = false;
                    })
                    .catch(() => {
                        this.loadExams = false;
                        this.$toast.error("Activity not fount/ already deleted", {
                            position: "top",
                            duration: 6000,
                        });
                    });
            }
        },
        examFormReset() {
            this.addExam = false;
            this.examToBeUpdated.id = "";
            this.examToBeUpdated.exam = {};
            this.publicexams = [];
            this.getPublicExams();
        },
        // async getCourseDetails() {
        //   this.fetchData = true;
        //   const url =
        //     this.$store.getters.getAPIKey.mainAPI +
        //     `/institutionuser/courses/withusers`;
        //   await this.$axios
        //     .get(url)
        //     .then((response) => {
        //       this.courses = [];
        //       this.courseCodes = {};
        //       this.courses = response.data;

        //       this.courses.forEach((course) => {
        //         this.$set(this.courseCodes, course.id, {
        //           code: `${course.code}-${course.usersubgroup.code}-${course.usersubgroup.usergroup.code}`,
        //         });
        //       });
        //       // this.getExams();
        //       response;
        //     })
        //     .catch((error) => {
        //       error;
        //     });
        // },
        assessmentLink(activityType, activityId, activityPath) {
            if (
                activityPath == "ExamQues" ||
                activityPath == "ExamEvaluation" ||
                activityPath == "ExamScore"
            ) {
                let routeData = this.$router.resolve({
                    name: activityPath,
                    params: {
                        exam_id: activityId,
                    },
                });
                window.open(routeData.href, "_blank");
            } else {
                this.$router.push({
                    name: activityPath,
                    params: {
                        exam_id: activityId,
                    },
                });
            }
        },

        linkAttendance(activityId, activityName) {
            // openModel
            this.activityIdForAttendance = activityId;
            this.activityNameForAttendance = activityName;

            this.$bvModal.show("activityAttendancesModel");
        },
    },
};
</script>

<style>
.examinationBtn i {
    font-size: 12px !important;
    margin-right: 4px;
}

.examsListing .upcomingsOrPast i {
    font-size: 12px !important;
}

.examsListing .youtube button i {
    font-size: 10px !important;
}
</style>
