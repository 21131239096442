<template>
  <div>
    <div class="courseAdmins">
      <b-overlay :show="loadData" spinner-variant="primary" spinner-type="grow" spinner-small rounded="md">
        <b-button variant="primary" class="mb-2" size="sm" pill v-if="!createAdmin"
          @click="createAdmin = !createAdmin">Add Course Instructor</b-button>
        <div v-if="createAdmin">
          <AddAdmin :courseAdminModal="courseAdminModal" :faculties="faculties" :courseAdminList="courseAdminList"
            @addAdminStatus="addAdminStatus" />
        </div>

        <table class="table table-striped table-bordered" v-else>
          <thead class="thead-dark text-left">
            <tr>
              <th>Sr. No.</th>
              <th>Name</th>
              <th>Remove</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(admin, adminIndex) in courseAdminList" :key="adminIndex">
              <td class="align-middle">
                {{ adminIndex + 1 }}
              </td>
              <td :class="{ 'align-middle': true, 'text-danger': admin.enroll_status != 'active' }">
                {{ admin.first_name || " " }} {{ admin.last_name || " " }}
              </td>
              <td class="align-middle">
                <span class="text-danger btn m-0 p-0" @click="deleteCourseUser(admin.id, adminIndex)">
                  <i class="fas fa-trash"></i>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import AddAdmin from "./AddAdmin.vue";
export default {
  props: ["courseAdminModal", "faculties"],
  created() {
    this.courseAdminList = [];
    this.courseAdminList = [...this.courseAdminModal.admins];
    this.courseAdminList.sort(function (a, b) {
      return (
        a.first_name - b.first_name || a.first_name.localeCompare(b.first_name)
      );
    });
  },
  components: {
    AddAdmin,
  },
  data() {
    return {
      courseAdminList: [],

      loadData: false,
      createAdmin: false,
    };
  },
  methods: {
    addAdminStatus(status) {
      if (status) {
        this.$emit("newCourseAdminsAdded");
      } else {
        this.createAdmin = !this.createAdmin;
      }
    },
    deleteCourseUser(userId, userIndex) {
      this.loadData = true;
      let userUrl = this.$store.getters.getAPIKey.deleteCourseUser;
      let url = userUrl.replace("course_user_id", userId);
      this.$axios
        .delete(url)
        .then(() => {
          this.$toast.success("User Deleted!", {
            position: "top",
            duration: 3000,
          });
          this.courseAdminList.splice(userIndex, 1);

          this.$emit("adminRemoved", Number(this.courseAdminList.length));
          this.loadData = false;
        })
        .catch(() => {
          this.postCourses = false;
          this.$toast.error("Something went wrong, Please try again!", {
            position: "top",
            duration: 3000,
          });
          this.loadData = false;
          // console.log(error.reponse);
        });
    },
  },
};
</script>
