<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="programOutcome" v-else>
      <b-overlay :show="loadpso" spinner-variant="primary" spinner-type="grow" spinner-small rounded="md">
        <div class="d-flex justify-content-between" style="font-size: 12px" v-if="!outcomeProgram">
          <div class="d-flex align-items-baseline" style="font-size: 12px">
            <b-button variant="info"  size="sm" class="ml-4 mt-2" @click="outcomeProgram = true">Add PSO</b-button>
            <b-button variant="danger" size="sm" class="ml-3" @click="downloadPSOPDF()" >Download PDF</b-button>
          </div>
          <b-button variant="danger"  size="sm" class="ml-4 mt-2" @click="$emit('backToGroups')">Close</b-button>
        </div>

        <b-form @submit.prevent="
          currentspecificOutcome.id
            ? updateProgramSpecificOutcome()
            : addProgramSpecificOutcome()
        " class="mt-4" v-if="outcomeProgram">
          <b-form-group label="Si. No">
            <b-form-input v-model="specificOutcome.si_no" :value="
              currentspecificOutcome.si_no
                ? (specificOutcome.si_no = currentspecificOutcome.si_no)
                : specificOutcome.si_no
            "></b-form-input>
          </b-form-group>
          <b-form-group label="PSO Name">
            <b-form-input v-model="specificOutcome.code" :value="
              currentspecificOutcome.code
                ? (specificOutcome.code = currentspecificOutcome.code)
                : specificOutcome.code
            " :class="{ 'is-invalid': responseErrors.code }"></b-form-input>
            <span class="text-danger" v-if="responseErrors.code">{{
            responseErrors.code[0]
            }}</span>
          </b-form-group>
          <b-form-group label="PSO description">
            <b-form-textarea :class="{ 'is-invalid': responseErrors.outcome }" id="textarea"
              placeholder="Enter description..." rows="3" max-rows="6" v-model="specificOutcome.outcome" :value="
                currentspecificOutcome.outcome
                  ? (specificOutcome.outcome = currentspecificOutcome.outcome)
                  : specificOutcome.outcome
              "></b-form-textarea>
            <span class="text-danger" v-if="responseErrors.outcome">{{
            responseErrors.outcome[0]
            }}</span>
          </b-form-group>

          <b-form-group>
            <b-button variant="danger" size="md" @click="cancel()" :disabled="outcomeBtn.disabled">Cancel</b-button>
            <b-button variant="primary" type="submit" size="md" class="ml-3" :disabled="outcomeBtn.disabled">{{
            outcomeBtn.text }}</b-button>
          </b-form-group>
        </b-form>

        <br />

        <div class="table-responsive" v-if="!outcomeProgram">
          <table class="table table-bordered table-striped" v-if="specificOutcomes.length != 0">
            <thead class="thead-dark">
              <tr>
                <th>Si. No.</th>
                <th>Outcome</th>
                <th>Description</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(pOutcome, pOIndex) in specificOutcomes" :key="pOIndex">
                <td>{{ pOutcome.si_no }}</td>
                <td>{{ pOutcome.code }}</td>
                <td>{{ pOutcome.outcome }}</td>
                <td>
                  <span class="text-info btn p-0 m-0" @click="
                    getProgramSpecificOutcome(
                      pOutcome.id,
                      pOutcome.code,
                      pOutcome.outcome,
                      pOutcome.usergroup_id,
                      pOutcome.si_no
                    )
                  "><i class="far fa-edit"></i></span>
                </td>
                <td>
                  <span class="btn text-danger" @click="$bvModal.show('deletepso' + pOIndex)"><i
                      class="fas fa-trash"></i></span>
                  <b-modal :id="'deletepso' + pOIndex" no-close-on-backdrop no-close-on-keyboard no-close-on-esc
                    hide-footer hide-header centered size="md">
                    <h6 style="font-size: 18px; font-weight: 600" class="text-danger">
                      Deleting this PSO will remove the PSO from CO-PO/PSO
                      mapping and PSO's attached to program exit survey
                      questions. Are you sure you want to proceed?
                    </h6>
                    <div class="buttons">
                      <b-button size="sm" variant="primary" @click="deletePSO(pOutcome.id, pOIndex)">Proceed</b-button>
                      <b-button size="sm" variant="danger" class="ml-2" @click="$bvModal.hide('deletepso' + pOIndex)">
                        Cancel</b-button>
                    </div>
                  </b-modal>
                </td>
              </tr>
            </tbody>
          </table>
          <p class="text-danger font-weight-bolder" style="font-size: 16px" v-else>
            No Program Specific Outcomes Created Yet!
          </p>
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["groupId"],
  async created() {
    await this.getProgramSpecificOutcomes();
  },
  data() {
    return {
      fetchData: false,
      loadpso: false,
      specificOutcome: {},
      currentspecificOutcome: {},
      specificOutcomes: [],
      groups: [],
      groupOptions: [],
      outcomeProgram: false,
      outcomeBtn: {
        text: "Submit",
        disabled: false,
      },
      responseErrors: {},
      responseSuccess: {},
    };
  },
  methods: {
    async downloadPSOPDF() {
      this.loadpso = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/programmespecificoutcomes/report/pdfexport`;
      await this.$axios
        .post(url, { usergroup: this.groupId }, { responseType: "blob" })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "programmeSpecificOutcomeReport.pdf");
          document.body.appendChild(link);
          link.click();
          link.remove();
          this.loadpso = false;

          response;
        })
        .catch((error) => {
          this.loadpso = false;
          error;
        });
    },
    async addProgramSpecificOutcome() {
      this.outcomeBtn.text = "Please wait...";
      this.outcomeBtn.disabled = true;
      this.specificOutcome.usergroup_id = await this.groupId;
      const url = this.$store.getters.getAPIKey.programSpecificOutcome;

      axios
        .post(url, this.specificOutcome)
        .then((response) => {
          this.$toast.success(
            this.specificOutcome.code + " is successfully created",
            {
              position: "top",
              duration: 3000,
            }
          );
          this.responseSuccess["created"] = [`Successfully created!`];
          this.clearResponse();

          response;
        })
        .catch((error) => {
          this.responseErrors = error.response.data.errors;
          this.clearResponse();
          error;
        });
    },
    getProgramSpecificOutcomes() {
      this.fetchData = true;
      const url = this.$store.getters.getAPIKey.programSpecificOutcome;
      axios
        .get(url)
        .then((response) => {
          this.specificOutcomes = response.data;
          this.specificOutcomes = this.specificOutcomes.filter((outcome) => {
            return outcome.usergroup_id == this.groupId;
          });
          this.fetchData = false;
          console.log(response);
          response;
        })
        .catch((error) => {
          // this.responseErrors = error.response.data.errors;
          this.fetchData = false;
          error;
        });
    },
    async deletePSO(psoId, poIndex) {
      this.$bvModal.hide("deletepso" + poIndex);
      this.loadpso = true;
      const url =
        this.$store.getters.getAPIKey.programSpecificOutcome + `/${psoId}`;
      await this.$axios
        .delete(url)
        .then((response) => {
          this.$toast.success("PSO Deleted", {
            position: "top",
            duration: 3000,
          });
          this.specificOutcomes.splice(poIndex, 1);
          this.loadpso = false;
          response;
        })
        .catch((error) => {
          this.loadpso = false;
          error;
        });
    },
    getProgramSpecificOutcome(
      pOutcomeId,
      pOutcomeCode,
      pOutcomeDescription,
      pOutcomeGroup,
      pOutcomeSiNo
    ) {
      this.currentspecificOutcome.id = pOutcomeId;
      this.currentspecificOutcome.code = pOutcomeCode;
      this.currentspecificOutcome.outcome = pOutcomeDescription;
      this.currentspecificOutcome.usergroup_id = pOutcomeGroup;
      this.currentspecificOutcome.si_no = pOutcomeSiNo;
      this.outcomeProgram = true;
    },
    updateProgramSpecificOutcome() {
      this.outcomeBtn.text = "Updating...";
      this.outcomeBtn.disabled = true;
      const url =
        this.$store.getters.getAPIKey.programSpecificOutcome +
        `/${this.currentspecificOutcome.id}`;

      axios
        .put(url, this.specificOutcome)
        .then((response) => {
          this.$toast.success(
            this.specificOutcome.code + " is successfully updated",
            {
              position: "top",
              duration: 3000,
            }
          );
          this.responseSuccess["created"] = [`Successfully updated!`];

          this.clearResponse();
          response;
        })
        .catch((error) => {
          // this.responseErrors = error.response.data.errors;
          this.clearResponse();
          error;
        });
    },
    clearResponse() {
      this.outcomeBtn.text = "Submit";
      setTimeout(() => {
        if (this.responseSuccess.created) {
          this.getProgramSpecificOutcomes();
          this.responseSuccess = {};
          this.cancel();
        }

        this.outcomeBtn.disabled = false;
        this.responseErrors = {};
      }, 3000);
    },
    cancel() {
      this.specificOutcome = {};
      this.currentspecificOutcome = {};
      this.outcomeProgram = false;
    },
  },
};
</script>

<style >
.programOutcome table {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}
</style>
