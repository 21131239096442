<template>
  <div>
    <b-skeleton-table v-if="fetchData" :rows="4" :columns="6"
      :table-props="{ bordered: true, striped: true }"></b-skeleton-table>
    <div class="usergroup" v-else>
      <div class="groupSubBtns p-2 mb-3 d-flex justify-content-between" style="
            border: 2px solid #eeeeeb;
            border-left-style: none;
            border-right-style: none;
          " v-if="currentComponent != 'Creation'">
        <b-row>
          <b-button variant="info" v-if="mainGroupAdmin || userGroupAdmin" @click="currentComponent = 'Creation'"
            class="d-flex align-items-center"><i class="fas fa-plus-square text-white"></i>ADD
            Semester/Interdepartmental
            Course Group</b-button>
          <div class="text-danger " style="width: 60%;">
            (<span style="font-size: 13px !important; font-weight: bold;">When a change in the scheme occurs, a new
              programme must be created </span>and the classes following the new scheme should be added to the newly
            created programme)
          </div>
        </b-row>
        <!-- <b-button
          variant="info"
          v-if="mainGroupAdmin || userGroupAdmin"
          @click="currentComponent = 'CopySubgroup'"
          class="d-flex align-items-center"
          ><i class="fas fa-plus-square text-white"></i>Copy</b-button
        > -->
        <!-- <b-button
          @click="openYoutubeLinks('Groups')"
          style="backgroundColor: #FF0000; border: none"
          size="sm"
          class="d-flex align-items-center"
        >
          <i class="fab fa-youtube"></i>
          Video Tutorial
        </b-button> -->
      </div>
      <b-row>
        <b-col><b-form-select class="mb-3 text-center" :options="usersubgroupTypes" v-model="selectedSubgroup"
            @change="getUsergroups" v-if="currentComponent == 'ViewGroups'"></b-form-select></b-col>
        <b-col v-if="(groupOptions.length > 0)"><b-form-select class="mb-3 text-center" :options="groupOptions"
            v-model="selectedFilterUsergroup" @change="getUsergroups"
            v-if="currentComponent == 'ViewGroups'"></b-form-select></b-col>
      </b-row>


      <component :usergroups="usergroups" :selectedInstitute="selectedInstitute" :groupId="groupId" :groups="groups"
        :adminList="adminList" :groupInfo="groupInfo" :groupOptions="groupOptions" :getCustomNames="getCustomNames"
        :mainGroupAdmin="mainGroupAdmin" :userGroupAdmin="userGroupAdmin" :subgroupAdmin="subgroupAdmin"
        @goToPSO="goToPSO" @backToGroups="currentComponent = 'ViewGroups'" @GroupCreated="GroupCreated"
        @getGroup="getGroup" @updateAdminStatus="groupInfo.status = true" @adminsUpdated="loadGroups"
        @inviteEnroll="inviteEnroll" @closeEnroll="currentComponent = 'ViewGroups'" v-bind:is="currentComponent">
      </component>
    </div>
  </div>
</template>

<script>
import ViewGroups from "./ViewGroups";
import Creation from "./Creation";
import InviteEnroll from "../Institute/usergroups/usersubgroups/InviteAndEnroll";
import acl from "../../../../authorization/acl";
export default {
  props: ["selectedInstitute", "getCustomNames"],
  async created() {
    this.fetchData = true;
    this.mainGroupAdmin = await acl.isUserGranted("mainGroupAdmin");
    this.userGroupAdmin = await acl.isUserGranted("groupAdmin");
    this.subgroupAdmin = await acl.isUserGranted("subgroupAdmin");
    const customName1 =
      this.getCustomNames.userSubgroup.custom_name != null
        ? "All " + this.getCustomNames.userSubgroup.custom_name
        : "All " + "Usersubgroups";
    const customName2 =
      this.getCustomNames.userSubgroup.custom_name != null
        ? "My " + this.getCustomNames.userSubgroup.custom_name
        : "My " + "Usersubgroups";
    if (this.mainGroupAdmin || this.userGroupAdmin) {
      this.selectedSubgroup = 1;
      this.usersubgroupTypes.push(
        { value: 1, text: customName1 },
        { value: 2, text: customName2 }
      );
    } else {
      this.selectedSubgroup = 2;
      this.usersubgroupTypes.push({ value: 2, text: customName2 });
    }
    await this.getGroups();
    await this.loadGroups();
  },
  components: {
    ViewGroups,
    Creation,
    // CopySubgroup,
    InviteEnroll,
  },
  data() {
    return {
      mainGroupAdmin: null,
      userGroupAdmin: null,
      subgroupAdmin: null,
      fetchData: false,
      editGroup: true,
      enrollAndInvite: false,
      groupId: null,
      adminList: [],
      groups: [],
      currentComponent: "ViewGroups",
      groupInfo: {},
      groupOptions: [],
      selectedFilterUsergroup: null,
      usersubgroupTypes: [],
      selectedSubgroup: 1,
      usergroups: {},
    };
  },
  methods: {
    async loadGroups() {
      this.fetchData = true;
      await this.getFacultyDetails();
      await this.getUsergroups();
      this.fetchData = false;
    },
    switchComponent(componentName) {
      this.currentComponent = componentName;
    },
    goToPSO(groupId) {
      this.groupId = groupId;
      this.switchComponent("ProgramSpecificOutcome");
    },
    inviteEnroll(info) {
      this.groupInfo = info;
      this.currentComponent = "InviteEnroll";
    },
    async GroupCreated(status) {
      this.currentComponent = "ViewGroups";
      this.groupInfo = {};
      status || this.groupInfo.status
        ? await this.loadGroups()
        : (this.currentComponent = "ViewGroups");
    },
    getGroup(groupData) {
      this.groupInfo = groupData;
      this.currentComponent = "Creation";
    },
    async getGroups() {
      this.usergroups = {};
      this.groupOptions.push({
        value: null,
        text:
          this.getCustomNames.userGroup.custom_name != null
            ? "Select " + this.getCustomNames.userGroup.custom_name + " (Scheme)"
            : "Select Usergroup (Scheme)",
        disabled: false,
      });
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/usergroups/allusergroups?subgroup_types_status=1`;
      await this.$axios
        .get(url)
        .then((response) => {
          response.data.forEach((group) => {
            this.usergroups[group.id] = {
              id: group.id,
              name: group.name,
              code: group.code,
              has_interdepartment_courses_group: group.has_interdepartment_courses_group,
            }
            this.groupOptions.push({
              value: group.id,
              text: group.name + " (" + group.code + ") "+(group.scheme ?? ''),
            });
          });
          response;
        })
        .catch((error) => {
          error;
        });
    },
    async getUsergroups() {
      let url;
      if (this.selectedSubgroup == 1) {
        url =
          this.$store.getters.getAPIKey.mainAPI +
          `/institutionuser/usersubgroups?with_admins=1&with_students_count=1`;
      } else {
        url =
          this.$store.getters.getAPIKey.mainAPI +
          "/usersubgroups/withadmins?with_admins=1&with_students_count=1";
      }
      await this.$axios
        .get(url)
        .then((response) => {
          this.groups = response.data.filter((subgroup) => (
            this.selectedFilterUsergroup ? subgroup.usergroup_id == this.selectedFilterUsergroup : true
          ));
        })
        .catch((error) => {
          error;
        });
    },
    async getFacultyDetails() {
      const url = this.$store.getters.getAPIKey.instituitionFaculty;
      await this.$axios
        .get(url)
        .then((response) => {
          this.adminList = [];
          this.adminList = response.data;
        })
        .catch((error) => {
          error;
        });
    },
  },
};
</script>

<style >
.groupSubBtns i {
  font-size: 20px !important;
  margin-right: 4px;
}

.groupSubgroups ul {
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 5px;
}

.groupSubgroups ul li {
  border: 3px solid #f8f9fa !important;
}

.groupSubgroups .tab-content {
  border: none !important;
}
</style>
