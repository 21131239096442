<template>
    <div>
        <div>
            <h4>Invite Students to {{ publicexam.name }}</h4>
            <b-form @submit.prevent="sendInvitations()">
                <h6 class="alert alert-success text-center" v-if="invitationSuccess">
                    Invitations Sent
                </h6>
                <!-- invite only- legacy modes -->
                <b-form-group v-if="invitationType == 'normal'" class="ml-4">
                    <b-form-tags input-id="tags-remove-on-delete"
                        :input-attrs="{ 'aria-describedby': 'tags-remove-on-delete-help' }"
                        v-model="invitation.invitee_array" separator="'
             ,;'" placeholder="Enter email/91 + 10 digit mobile number separated by commas" remove-on-delete
                        no-add-on-enter tag-pills tag-variant="primary" :class="{ 'is-invalid': responseErrors.invitee }"
                        v-b-popover.hover.bottom="'john8@gmail.com,918765******,test@gmail.com, test2@gmail.com'
                            "></b-form-tags>
                </b-form-group>
                <div v-if="invitationType == 'advanced'">
                    <b-form-group class="ml-4">
                        <b-form-group class="mb-0">
                            <label for="" class="required-label">Enter email separated by comma.
                                You may also copy and paste an entire excel column that contains email:</label>
                            <b-form-textarea v-model="emailMobileInputData" id="textarea-formatter" rows="3"
                                placeholder="student11@gmail.com,..."></b-form-textarea>
                        </b-form-group>
                        <br>
                        <b-form-group class="mb-0">
                            <label for="" class="required-label">Please enter the names of the students in the same order as
                                the
                                corresponding email/mobile number entered in the first field, and separate each name with a
                                comma. This
                                will ensure that the correct names are associated with the correct contact information.
                                Instead, you may
                                copy and paste an entire Excel column containing student names.
                                :</label>
                            <b-form-textarea v-model="studentsNameInputData" id="textarea-formatter" rows="3"
                                placeholder="Student 1,Student 2, Student 3"></b-form-textarea>
                        </b-form-group>
                        <br>
                        <b-button variant="success" size="md" @click="updateTableFromInputData()">Proceed</b-button>
                    </b-form-group>
                    <b-form-group class="ml-4">
                        <div v-if="(inviteesDatas.length > 0)">
                            <table class="table  table-striped table-bordered">
                                <thead class="thead-dark">
                                    <tr>
                                        <!--  <th>Roll No.</th>-->
                                        <th>Email/Mobile</th>
                                        <th>Student First Name</th>
                                        <th>Last Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(inviteeData, reindex) in inviteesDatas" :key="reindex">
                                        <!--<td>{{ inviteeData.roll_number }}</td>-->
                                        <td>{{ inviteeData.invitee }}</td>
                                        <td>{{ inviteeData.first_name }}</td>
                                        <td>{{ inviteeData.last_name }}</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>

                        <b-button v-if="(inviteesDatas.length > 0)" variant="primary" type="submit"
                            class="font-weight-bolder text-white"
                            :disabled="(sendInvites.disabled || inviteesDatas.length < 1)">{{ sendInvites.text }}</b-button>

                    </b-form-group>
                    <br>
                </div>

            </b-form>
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    props: {
        publicexam: [],
        hideModel: Function,
    },
    created() {
        this.smsCount = this.$store.getters.getSmsCount.smsBalance;
    },
    data() {
        return {
            invitationType: 'advanced', // 'normal', 'advanced' 
            invitationSuccess: false,
            smsCount: null,
            emailMobileInputData: '',
            studentsNameInputData: '',
            studentsRollNumberInputData: '',
            sendInvites: {
                text: "Send invitation to Participant",
                disabled: false,
            },
            inviteesDatas: [],
        };
    },
    methods: {
        async sendInvitations() {
            this.sendInvites.text = "Sending...";
            this.sendInvites.disabled = true;
            let directEnrollment = {
               type:1
            };

            if (this.invitationType == 'normal') {
                directEnrollment.invitee = this.invitation.invitee;
                directEnrollment.invitee_array = this.invitation.invitee_array;
            }
            if (this.invitationType == 'advanced') {
                directEnrollment.invite_with_profiles = this.inviteesDatas;
            }
            directEnrollment.public_exam_id = this.publicexam.id;
            let url = this.$store.getters.getAPIKey.mainAPI +
                `/publicexamparticipants`;   
            axios
                .post(url, directEnrollment)
                .then((response) => {
                    response;
                    this.$toast.success(`External Participant Successfully.`, {
                        top: "top",
                        duration: 3000,
                    })
                    // this.checkInvitees();
                    this.hideModel();
                })
        },
        async updateTableFromInputData() {
            this.inviteesDatas = [];
            let splitDataEmailMobile = await this.emailMobileInputData.replaceAll(' ', '').split(/[\n,]+/);
            let splitDataStudentsName = await this.studentsNameInputData.split(/[\n,]+/);
            //  let splitDataStudentsRollNumbers = await this.studentsRollNumberInputData.split(/[\n,]+/);
            if (this.emailMobileInputData == '' || this.studentsNameInputData == '' || splitDataEmailMobile.length != splitDataStudentsName.length) {
                this.$toast.error(`Please fill the columns /Number of Email/Mobile not matches number of Student name.`, {
                    top: "top",
                    duration: 3000,
                });
                return;
            }
            for (let index = 0; index < splitDataStudentsName.length; index++) {
                let names = splitDataStudentsName[index].trim().split(' ');
                let first_name = names[0];
                let last_name = splitDataStudentsName[index].substring(first_name.length + 1);
                this.inviteesDatas.push({
                    invitee: splitDataEmailMobile[index],
                    name: splitDataStudentsName[index],
                    first_name: first_name,
                    last_name: last_name,
                });
            }
            this.sendInvites.text = "Send invitation to Participant";
            this.sendInvites.disabled = false;
        },

    },
};
</script>
