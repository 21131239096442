<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="editParticipantsDetails" v-else>
      <b-overlay
        :show="loadApproval"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="md"
      >
        <div v-if="!addParticipants">
          <div
            class="noparticipants"
            v-if="
              existingParticipantsOptions.length == 0 &&
                participantsToBeDeleted.length == 0
            "
          >
            <div class="alert alert-danger font-weight-bolder text-center">
              No Participants Found!
            </div>
          </div>
          <div class="deleteParticipants" style="z-index: 1" v-else>
            <multiselect
              v-model="existingParticipantsOptions"
              tag-placeholder="Add this as new tag"
              placeholder="Search or add a tag"
              label="name"
              track-by="code"
              :multiple="true"
              :taggable="true"
              :options="[]"
              @remove="removeParticipantFromServer"
              :showNoResults="true"
              :searchable="false"
              @close="keepDropdownClosed()"
              @open="keepDropdownClosed()"
              ref="multiselect"
            ></multiselect>

            <div class="mt-2" v-if="participantsToBeDeleted.length != 0">
              <span class="font-weight-bolder"
                >Participants to be removed:</span
              >
              <div class="participantsToBeDeleted mt-2 d-flex flex-wrap">
                <p
                  style="font-size: 12px; font-weight: 300; background-color: #ff6666"
                  class="rounded p-1 text-white ml-2"
                  v-for="(dParticipant, index) in participantsToBeDeleted"
                  :key="index"
                >
                  {{ dParticipant.name }}
                </p>
              </div>
            </div>

            <div
              class="deleteBtn mt-3 text-right"
              v-if="participantsToBeDeleted.length != 0"
            >
              <b-button
                variant="danger"
                @click="prepareParticipantsToRemove()"
                :disabled="removeparticipantBtn.disabled"
                >{{ removeparticipantBtn.text }}</b-button
              >
              <b-button
                variant="info"
                class="ml-3"
                @click="getParticipants()"
                :disabled="removeparticipantBtn.disabled"
                >Cancel</b-button
              >
            </div>
          </div>
        </div>
        <div class="addParticipants mb-2 mt-2">
          <b-button
            @click="addParticipants = !addParticipants"
            size="sm"
            :variant="addParticipants ? 'danger' : 'primary'"
          >
            {{
              addParticipants ? "Close Participants" : "+Add participants"
            }}</b-button
          >
        </div>
        <div class="addParticipants mt-3" v-if="addParticipants">
          <AddParticipantsInEdit
            @participantsSynced="clearResponse(true)"
            @startSyncing="loadApproval = true"
            :surveyApproval="surveyApproval"
          />
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import AddParticipantsInEdit from "./AddParticipantsInEdit.vue";
export default {
  props: ["surveyApproval"],
  created() {
    this.getParticipants();
  },
  components: {
    AddParticipantsInEdit,
  },
  data() {
    return {
      fetchData: false,
      loadApproval: false,
      isOpen: false,
      addParticipants: false,
      existingParticipantsOptions: [],
      participantsToBeDeleted: [],
      removeparticipantBtn: {
        text: "Remove",
        disabled: false,
      },
    };
  },
  methods: {
    keepDropdownClosed() {
      this.$refs.multiselect.$el.blur();
    },
    removeParticipantFromServer(removedOption, id) {
      this.participantsToBeDeleted.push(removedOption);
      id;
    },
    getParticipants() {
      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.surveyApprovals +
        `/${this.surveyApproval.id}/participants`;
      this.$axios.get(url).then((response) => {
        this.participantsToBeDeleted = [];
        this.existingParticipantsOptions = [];
        this.addParticipants = false;
        this.loadApproval = false;
        const participants = response.data;
        participants.forEach((participant) => {
          this.existingParticipantsOptions.push({
            id: participant.id,
            name: participant.first_name + " " + (participant.last_name || ""),
            code: participant.id,
          });
        });

        this.fetchData = false;
      });
    },
    async prepareParticipantsToRemove() {
      this.removeparticipantBtn.text = "Removing...";
      this.removeparticipantBtn.disabled = true;
      this.loadApproval = true;

      let usersToBeRemoved = {
        institution_users: [],
      };
      this.participantsToBeDeleted.forEach((participant) => {
        usersToBeRemoved.institution_users.push(participant.id);
      });

      let participantToDelete = await this.removeParticipant(usersToBeRemoved);

      if (participantToDelete) {
        let msg =
          (await usersToBeRemoved.institution_users.length) == 1
            ? "Participant Removed"
            : "All Selected Participants Removed";
        this.$toast.success(msg, {
          position: "top",
          duration: 3000,
        });
        this.clearResponse(true);
      }
      if (!participantToDelete) {
        let msg = await "Something went wrong, Please try again!";
        this.clearResponse(false);
        return this.$toast.error(msg, {
          position: "top",
          duration: 3000,
        });
        // }
      }
    },
    async removeParticipant(usersToBeRemoved) {
      let removeStatus = null;
      const url =
        this.$store.getters.getAPIKey.surveyApprovals +
        `/${this.surveyApproval.id}/onlineregparticipants/detach`;

      await this.$axios
        .post(url, usersToBeRemoved)
        .then(() => {
          removeStatus = true;
        })
        .catch(() => {
          removeStatus = false;
        });
      return removeStatus;
    },
    clearResponse(status) {
      this.removeparticipantBtn.text = "Remove";
      this.loadApproval = false;
      setTimeout(() => {
        if (status) {
          this.getParticipants();
        }
        this.removeparticipantBtn.disabled = false;
      }, 3000);
    },
  },
};
</script>
