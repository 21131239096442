<template>
  <div class="container">
    <h3 style="text-align: center; font-size:18px !important;">
      Programme Auditors
    </h3>
    <b-col sm="12">
      <div class="table-responsive rounded">
        <table class="table table-striped table-bordered">
          <thead class="thead-dark text-left">
            <tr>
              <th>
                SI. No.
              </th>
              <th>
                Programme Name
              </th>
              <th>
                IQAC
              </th>
              <th>Internal Auditor</th>
              <th>External Auditor</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(usergroup, reindex) in usergroupsAndAuditorsAndIqacs" :key="reindex">
              <td>{{ reindex + 1 }}</td>
              <td>
                {{ usergroup.name }}-{{ usergroup.maingroup.code }} <br> <b-link
                  @click="openAddAuditorsModel(reindex)">Add
                  Auditor</b-link> | <b-link v-if="iqacPermission" @click="openAddIQACModel(reindex)">Add
                  IQAC</b-link>
              </td>
              <td>
                <ul class="m-0">
                  <li style="word-wrap: nowrap; border: none;" v-for="(staff, auindex) in usergroup.iqac_staffs"
                    :key="auindex">
                    {{ staff.first_name }} {{ staff.last_name }} ({{ staff.email }})
                    <i v-if="iqacPermission" class="fas fa-trash text-danger" style="font-size: 12px !important;"
                      @click="deleteIQACStaff(reindex, auindex)"></i>
                  </li>
                </ul>
              </td>
              <td>
                <ul class="m-0">
                  <span style="word-wrap: nowrap; " v-for="(auditor, auindex) in usergroup.auditors" :key="auindex">
                    <li style=" border: none;" v-if="auditor.institution_user_id">
                      {{ auditor.name }} ({{ auditor.email }}) | {{ auditor.academic_year }}-{{
                          auditor.academic_semester
                      }} | Permission
                      valid till {{
                          auditor.expire_at
                            ? moment(auditor.expire_at,
                              'YYYY-MM-DD').format('DD/MM/YYYY') : 'forever'
                      }}
                      <i class="fas fa-trash text-danger" style="font-size: 12px !important;"
                        @click="deleteAuditor(reindex, auindex)"></i>
                      <br>
                    </li>
                  </span>
                </ul>
              </td>

              <td>
                <ul class="m-0">
                  <span style="word-wrap: nowrap;" v-for="(auditor, auindex) in usergroup.auditors" :key="auindex">
                    <li style=" border: none;" v-if="(auditor.institution_user_id == null)">
                      {{ auditor.name }}( {{ auditor.email }}) | {{ auditor.academic_year }}-{{
                          auditor.academic_semester
                      }} | Permission
                      valid till {{
                          auditor.expire_at
                            ? moment(auditor.expire_at,
                              'YYYY-MM-DD').format('DD/MM/YYYY') : 'forever'
                      }}
                      <i class="fas fa-trash text-danger" style="font-size: 12px !important;"
                        @click="deleteAuditor(reindex, auindex)"></i>
                      <br>
                    </li>
                  </span>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-col>

    <b-modal id="AddAuditorModel" no-close-on-backdrop no-close-on-keyboard no-close-on-esc hide-footer hide-header
      centered size="md">
      <h3 class="text-center">Add Auditor</h3>

      <b-form-group label="Add new Auditor :">
        <div class="d-flex">
          <b-form-radio :value="false" class="ml-3" v-model="newAuditorFromInstitutionUsers">External </b-form-radio>
          <b-form-radio class="ml-3" :value="true" v-model="newAuditorFromInstitutionUsers">Internal </b-form-radio>
        </div>
        <span>
          <p>
            An invitation email will be sent to this email address
            {{ newAuditorFromInstitutionUsers ?
                'registered with ezygo.app' : ''
            }}
            <i v-b-popover.hover.top="'You have been invited by <Institution Name> as an auditor. You can now audit all reports by logging into Ezygo.app using the following email address:'"
              class="fa fa-info-circle text-info" aria-hidden="true"></i>
          </p>
        </span>
      </b-form-group>

      <b-form-group v-if="newAuditorFromInstitutionUsers">
        <label for="">Search/Select Faculties:</label>
        <multiselect v-model="newInstitutionUserAuditors" tag-placeholder="Add this as new tag"
          placeholder="Search faculty" label="name" track-by="id" :options="institutionUsers" :close-on-select="false"
          :multiple="true" :searchable="true" :internal-search="true" :taggable="true">
        </multiselect>
      </b-form-group>

      <div v-else>
        <b-form-group id="input-group-1" label-for="input-1" description="">
          <label class="required-label" for="">Name:</label>
          <b-form-input id="input-1" v-model="newAuditor.name" type="text" placeholder="Enter Name"></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-1" label-for="input-1" description="">
          <label class="required-label" for="">Email:</label>
          <b-form-input id="input-1" v-model="newAuditor.email" type="text" placeholder="Enter an email"></b-form-input>
        </b-form-group>
      </div>


      <b-form-group id="input-group-1" label-for="input-1" description="">
        <label class="required-label" for="">Academic Year:</label>

        <b-form-select
          @change="newAuditor.academic_year = selectedAcademicYearSemester[0]; newAuditor.academic_semester = selectedAcademicYearSemester[1];"
          v-model="selectedAcademicYearSemester" class="mb-3">
          <b-form-select-option :value="[null, null]">Please Choose</b-form-select-option>
          <b-form-select-option v-for="(yearSem, areindex) in academicYearSemesterOptions" :key="areindex"
            :value="yearSem.value">{{ yearSem.text }}</b-form-select-option>
        </b-form-select>
      </b-form-group>

      <b-form-group id="input-group-1" label-for="input-1" description="">
        <label class="required-label" for="">Permission Valid Till:</label><b-form-datepicker id="example-datepicker"
          v-model="newAuditor.expire_at" :min="newAuditorMinDate" :max="newAuditorMaxDate"></b-form-datepicker>
      </b-form-group>

      <b-row class="p-2">
        <b-col style="text-align:left;">
          <b-button variant="danger" class="text-white sm" size="sm"
            @click="$bvModal.hide('AddAuditorModel'); addAuditorUsergroupIndex = null;">Close</b-button>
        </b-col>
        <b-col style="text-align:right;">
          <b-button :disabled="isNewAuditorSubmitButtonDisable" variant="success" class="text-white sm" size="sm"
            @click="attachAuditor">Add</b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal id="AddIQACModel" no-close-on-backdrop no-close-on-keyboard no-close-on-esc hide-footer hide-header
      centered size="md">
      <h3 class="text-center">Add IQAC Staff</h3>


      <b-form-group>
        <label for="">Search/Select Faculties:</label>
        <multiselect v-model="newIQACStaffs" tag-placeholder="Add this as new tag" placeholder="Search faculty"
          label="name" track-by="id" :options="institutionUsers" :close-on-select="false" :multiple="true"
          :searchable="true" :internal-search="true" :taggable="true">
        </multiselect>
      </b-form-group>

      <b-row class="p-2">
        <b-col style="text-align:left;">
          <b-button variant="danger" class="text-white sm" size="sm"
            @click="$bvModal.hide('AddIQACModel'); addIQACUsergroupIndex = null;">Close</b-button>
        </b-col>
        <b-col style="text-align:right;">
          <b-button :disabled="(newIQACStaffs == null)" variant="success" class="text-white sm" size="sm"
            @click="attachIQACStaff">Add</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import acl from '../../authorization/acl';
// permissions to view this component 'manageAuditors' || 'manageIqacStaff'
import moment from 'moment';
export default {
  props: [],
  async created() {
    // if(! token )  this.$router.push('/auditor/login');
    // this.auditorPermission = await acl.isUserGranted("manageAuditors") ;
    this.iqacPermission = await acl.isUserGranted("manageIqacStaff");
    this.getUserGroupsWithAuditorsAndIqac();
  },
  components: {},
  data() {
    return {
      LoadData: false,
      usergroupsAndAuditorsAndIqacs: [],
      addAuditorUsergroupIndex: null,
      addIQACUsergroupIndex: null,
      newAuditor: { name: '', email: '', expire_at: '', academic_year: '', academic_semester: '' },
      newAuditorFromInstitutionUsers: false,
      newInstitutionUserAuditors: [],
      newIQACStaffs: [],
      institutionUsers: [],
      // auditorPermission:false,
      iqacPermission: false,
      selectedAcademicYearSemester: [null, null],
    };
  },
  computed: {
    academicYearSemesterOptions() {
      let yearSem = [];
      this.$settingAcedemicYearsAndSemester.forEach(year => {
        if (year.value != null && year.value != ["0", "0"])
          yearSem.push({ value: year.value, text: year.text });
      });
      return yearSem;
    },
    isNewAuditorSubmitButtonDisable() {
      // fill new auditor else select auditor faculty
      return (this.newAuditor.email == '' || this.newInstitutionUserAuditors.length < 1) && (this.newAuditor.expire_at == '' || this.newAuditor.academic_year == '' || this.newAuditor.academic_semester == '');
    },
    newAuditorMinDate() {
      var date = new Date();
      date.setDate(date.getDate() + 1);
      return date;
    },
    newAuditorMaxDate() {
      var date = new Date();
      date.setDate(date.getDate() + 45);
      return date;
    },
  },
  methods: {
    async attachIQACStaff() {
      const url = `${this.$store.getters.getAPIKey.mainAPI}/usergroupauditors/iqacstaff`;
      await this.$axios
        .post(url, {
          newIQACStaffs: this.newIQACStaffs.map(option => option.id),
          usergroup_id: this.usergroupsAndAuditorsAndIqacs[this.addIQACUsergroupIndex].id
        })
        .then(() => {
          this.$toast.success('IQAC added successfully', {
            position: "top",
            duration: 3000,
          });
          this.$bvModal.hide('AddIQACModel');
          this.addIQACUsergroupIndex = null;
          this.getUserGroupsWithAuditorsAndIqac();
        })
        .catch((error) => {
          let errortxt = "";
          if (error.response)
            if (error.response.status == 422) {
              let errormsgs = error.response.data.errors;
              for (const key in errormsgs) {
                errortxt = errormsgs[key][0];
              }
            } else {
              errortxt = error.response.data.message;
            }
          else {
            errortxt = "Failed to Create";
          }
          this.$toast.error(errortxt, {
            position: "top",
            duration: 7000,
          });
        });
    },
    async deleteIQACStaff(usergroupIndex, IQACIndex) {
      var result = confirm("Do you want to remove the IQAC Staff?");
      if (result) {
        const url = `${this.$store.getters.getAPIKey.mainAPI}/usergroupauditors/usergroups/${this.usergroupsAndAuditorsAndIqacs[usergroupIndex].id}/iqacstaff/${this.usergroupsAndAuditorsAndIqacs[usergroupIndex].iqac_staffs[IQACIndex].id}`;
        await this.$axios
          .delete(url)
          .then(() => {
            this.$toast.success('IQAC removed successfully', {
              position: "top",
              duration: 3000,
            });
            this.usergroupsAndAuditorsAndIqacs[usergroupIndex].iqac_staffs.splice(IQACIndex, 1);
          })
          .catch(() => {
            this.$toast.error("Failed to Remove IQAC", {
              position: "top",
              duration: 7000,
            });
          });
      }
    },
    async openAddIQACModel(usergroupIndex) {
      this.addIQACUsergroupIndex = usergroupIndex;
      // this.addAuditorUsergroup=usergroupId;
      this.newIQACStaffs = [];

      this.$bvModal.show('AddIQACModel');

      await this.getinstitutionUsers();
      this.usergroupsAndAuditorsAndIqacs[usergroupIndex].iqac_staffs.forEach(staff => {
        this.newIQACStaffs.push({
          id: staff.id,
          name: staff.first_name + ' ' + staff.last_name + '-' + staff.email,
        });
      });
    },
    async getinstitutionUsers() {
      if (this.institutionUsers.length > 0) return this.institutionUsers;

      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/institutionusers/faculties`;
      await this.$axios
        .get(url)
        .then((response) => {
          response.data.forEach(staff => {
            if (staff.enroll_status == 'active') this.institutionUsers.push({
              id: staff.id,
              name: staff.first_name + ' ' + staff.last_name + '-' + staff.email,
            });
          });
        })
        .catch((error) => {
          error;
        });
      return this.institutionUsers;
    },


    async attachAuditor() {
      const url = `${this.$store.getters.getAPIKey.mainAPI}/usergroupauditors`;
      await this.$axios
        .post(url, {
          name: this.newAuditor.name, email: this.newAuditor.email, expire_at: this.newAuditor.expire_at,
          academic_year: this.newAuditor.academic_year, academic_semester: this.newAuditor.academic_semester,
          usergroup_id: this.usergroupsAndAuditorsAndIqacs[this.addAuditorUsergroupIndex].id,
          institution_users: this.newAuditorFromInstitutionUsers ? this.newInstitutionUserAuditors.map(option => option.id) : null,
        })
        .then(() => {
          this.$toast.success('Auditor added successfully', {
            position: "top",
            duration: 3000,
          });
          // let groupAuditors = this.usergroupsAndAuditorsAndIqacs[this.addAuditorUsergroupIndex].auditors;
          // groupAuditors.forEach((auditor, index) => {
          //   if (auditor.email == this.newAuditor.email && auditor.academic_year == this.newAuditor.academic_year && auditor.academic_semester == this.newAuditor.academic_semester)
          //     groupAuditors.splice(index, 1);
          // });
          // groupAuditors.push(response.data);
          // this.usergroupsAndAuditorsAndIqacs[this.addAuditorUsergroupIndex].auditors = groupAuditors;
          this.$bvModal.hide('AddAuditorModel');
          this.addAuditorUsergroupIndex = null;
          this.newAuditor = { name: '', email: '', expire_at: '', academic_year: '', academic_semester: '' };
          this.newInstitutionUserAuditors = [];
          this.getUserGroupsWithAuditorsAndIqac();
        })
        .catch((error) => {
          let errortxt = "";
          if (error.response)
            if (error.response.status == 422) {
              let errormsgs = error.response.data.errors;
              for (const key in errormsgs) {
                errortxt = errormsgs[key][0];
              }
            } else {
              errortxt = error.response.data.message;
            }
          else {
            errortxt = "Failed to Create";
          }
          this.$toast.error(errortxt, {
            position: "top",
            duration: 7000,
          });
        });
    },
    async deleteAuditor(usergroupIndex, auditorIndex) {
      var result = confirm("Do you want to remove the auditor access?");
      if (result) {
        const url = `${this.$store.getters.getAPIKey.mainAPI}/usergroupauditors/${this.usergroupsAndAuditorsAndIqacs[usergroupIndex].auditors[auditorIndex].id}`;
        await this.$axios
          .delete(url)
          .then(() => {
            this.$toast.success('Auditor removed successfully', {
              position: "top",
              duration: 3000,
            });
            this.usergroupsAndAuditorsAndIqacs[usergroupIndex].auditors.splice(auditorIndex, 1);
          })
          .catch(() => {
            this.$toast.error("Failed to Remove Auditor", {
              position: "top",
              duration: 7000,
            });
          });
      }
    },
    openAddAuditorsModel(usergroupIndex) {
      this.addAuditorUsergroupIndex = usergroupIndex;
      // this.addAuditorUsergroup=usergroupId;
      this.selectedAcademicYearSemester = [null, null];
      this.newAuditor.expire_at = moment().add(7, 'days').format('YYYY-MM-DD');

      this.$bvModal.show('AddAuditorModel');

      this.getinstitutionUsers();
    },
    async getUserGroupsWithAuditorsAndIqac() {
      this.LoadData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usergroups?usergroupauditors=1&IqacStaffs=1`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.usergroupsAndAuditorsAndIqacs = response.data;
        })
        .catch((error) => {
          error;
        });
      this.LoadData = false;
    },
  },
};
</script>

<style  >

</style>
