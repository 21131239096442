<template>
  <div class="container">
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>

    <b-card header="Project Coordinators" class="text-center mt-2">
      <span v-if="loadCoordinators" class="text-warning">loading..</span>
      <b-button @click="addCoordinators()">Add/Edit Coordinator</b-button>
      <table class="table  table-striped table-bordered">
        <tr>
          <th>No.</th>
          <th>
            Name
          </th>
          <th>Email</th>
          <th></th>
        </tr>
        <tr v-for="(coordinator, expindex) in coordinators" :key="expindex">
          <td>{{ expindex + 1 }}</td>
          <td>{{ coordinator.first_name }} {{ coordinator.last_name }}</td>
          <td>{{ coordinator.email }}</td>
          <td>
            <b-button @click="deleteCoordinator(coordinator, expindex)">Remove</b-button>
          </td>
        </tr>
      </table>
      <p v-if="(coordinators.length == 0)" class="text-info">List empty</p>
    </b-card>

    <b-card header="Project Topics" class="text-center mt-3">
      <span v-if="loadTopics" class="text-warning">loading..</span>
      <b-button @click="updateTopic()">Add Topic</b-button>
      <table class="table  table-striped table-bordered">
        <tr>
          <th>No.</th>
          <th>Topic</th>
          <th>Supervisor</th>
          <th>Student group</th>
          <th></th>
        </tr>
        <tr v-for="(topic1, expindex) in topics" :key="expindex">
          <td>{{ expindex + 1 }}</td>
          <td>{{ topic1.topic }}</td>
          <td><span v-if="topic1.supervisor"> {{ topic1.supervisor.first_name }} {{ topic1.supervisor.last_name
          }}</span>
          </td>
          <td><span v-if="topic1.group"> {{ topic1.group.name }}</span></td>
          <td>
            <b-button @click="updateTopic(topic1)">Edit</b-button>
            <b-button @click="deleteTopic(topic1, expindex)">Remove</b-button>
          </td>
        </tr>
      </table>

      <p v-if="(topics.length == 0)" class="text-info">List empty</p>
    </b-card>


    <b-card header="Parameters for project evaluation" class="mt-3 text-center">
      <b-button class="m-2" v-if="!editHeaders" @click="editHeaders = true">Edit Options</b-button>
      <b-button class="m-2" v-if="editHeaders" @click="updateEvaluationHeaders()">Save</b-button>
      <span class="text-danger">
        Please contact Ezygo Support(+91 8330869500, support@ezygo.app) to add additional evaluation parameters as per the
        course requirement
      </span>
      <table class="table  table-striped table-bordered">
        <tr>
          <th>Enable</th>
          <th>No.</th>
          <th>
            Header
          </th>
          <th>
            Max. score
          </th>
        </tr>
        <tr v-for="(header, hrindex) in evaluationHeaders" :key="hrindex">
          <td>
            <b-form-checkbox :disabled="!editHeaders" v-model="header.enable"></b-form-checkbox>
          </td>
          <td>
            <span v-if="!editHeaders">{{ header.no }}</span>
            <b-form-input class="required" v-else v-model="header.no" type="text" placeholder="SI. No">
            </b-form-input>
          </td>
          <td>
            <span>{{ hrindex }}</span>
          </td>
          <td>
            <span v-if="!editHeaders">{{ header.maximum_mark }}</span>
            <b-form-input class="required" v-else v-model="header.maximum_mark" type="text" placeholder="Max. score">
            </b-form-input>
          </td>
        </tr>
      </table>

      <span v-if="loadHeaders" class="text-warning">loading..</span>
    </b-card>


    <b-card header="Evaluation" class="mt-3 text-center">
      <b-button v-if="canEnableEvaluation" @click="evaluationModel()">Evaluate</b-button>
      <br>
      <br>
      <b-button v-if="canEnableEvaluation" @click="projectEvaluationReport()">Evaluation Report
        download</b-button>
      <br>
      <br>

      <b-button v-if="canEnableEvaluation" @click="projectEvaluationSummaryReport()">Summary Report
        download</b-button>
      <br>
      <br>

      <b-button v-if="canEnableEvaluation" @click="projectTopicsReport()">Project Topics Report
        download</b-button>
      <br>
      <br>
      <p class="text-danger" v-if="!canEnableEvaluation">Please add Project
        Topics/Evaluation header to enable evaluation
      </p>

    </b-card>



    <!-- popup models for add/edit -->

    <b-modal id="coordinatorModel" hide-header hide-footer centered size="md">
      <div>
        <b-col>
          <b-form-group id="input-group-1" label="Project coordinators:" label-for="input-1">
            <div class="">
              <multiselect v-model="selectedCoordinators" tag-placeholder="Add this as new tag"
                placeholder="Choose Project coordinators" label="name" track-by="id" :options="faculties"
                :close-on-select="false" :multiple="true" :taggable="true"></multiselect>
            </div>
          </b-form-group>
          <b-button @click="updateCoordinators()" :disabled="loadCoordinators">{{ loadCoordinators ?
              'Please wait..' : 'Save'
          }}</b-button>
          <b-button @click="$bvModal.hide('coordinatorModel')">Cancel</b-button>
        </b-col>
      </div>
    </b-modal>

    <b-modal id="topicsModel" hide-header hide-footer centered size="md">
      <div>
        <b-col>
          <b-form-group id="input-group-2" label-for="input-2">
            <label for="" class="required-label">Project Topic of a student/group of students:</label>
            <b-form-input id="input-2" v-model="topic.topic" placeholder="Enter Topic"></b-form-input>
          </b-form-group>
          <b-form-group id="input-group-1" label-for="input-1">
            <label for="" class="required-label">Project Supervisor:</label>
            <select class="custom-select" v-model="topic.supervisor_id">
              <option :value="null">Select supervisor</option>
              <option v-for="(faculty, groupindex) in faculties" :key="groupindex" :value="faculty.id" :selected="
                faculty.id == topic.supervisor_id
              ">{{ faculty.name }}</option>
            </select>
          </b-form-group>

          <b-form-group id="input-group-1" label="Students group (optional):" label-for="input-1">
            <select class="custom-select" v-model="topic.group_id">
              <option :value="null">Select group</option>
              <option v-for="(group, groupindex) in courseGroups" :key="groupindex" :value="group.id" :selected="
                group.id == topic.group_id
              ">{{ group.name }}</option>
            </select>
            <br>
            <span style="color: #2929aa; text-decoration: underline; cursor: pointer;"
              @click="$bvModal.show('globalGroupModel')">Add/Edit Student groups</span>
          </b-form-group>
          <b-button @click="postTopic()" :disabled="loadTopics">{{ loadTopics ?
              'Please wait..' : 'Save'
          }}</b-button>
          <b-button @click="$bvModal.hide('topicsModel')">Cancel</b-button>
        </b-col>
      </div>
    </b-modal>

    <b-modal size="xl" id="evaluationModel" no-close-on-backdrop no-close-on-keyboard no-close-on-esc hide-header-close
      hide-header hide-footer>
      <p class="text-danger" v-if="loadEvaluation">Loading data..</p>
      <b-button class="m-2" @click="$bvModal.hide('evaluationModel')">Close</b-button>
      <b-button class="m-2" v-if="!loadEvaluation" @click="updateEvaluation()">Save</b-button>
      <br><br>
      <div>
        <table class="table  table-striped table-bordered">
          <tr>
            <th>Roll No.</th>
            <th>Name</th>
            <th>Submission date</th>
            <th>Presentation date</th>
            <th v-for="(header, hrindex) in enabledHeaders" :key="hrindex">
              {{ header.name }}({{ header.maximum_mark }})
            </th>
          </tr>
          <tr v-for="(student, studentindex) in students" :key="studentindex">
            <td>{{ student.roll_number }}</td>
            <td>{{ student.first_name }} {{ student.last_name }}</td>
            <td>
              <b-form-input v-if="evaluationData[student.institution_user_id].topic_id"
                v-model="evaluationData[student.institution_user_id].submission_date" placeholder="YYYY/MM/DD">
              </b-form-input>
              <span class="badge badge-pill badge-dark btn" role="button" v-b-tooltip.hover
                :title="'Add this student to a topic group to enable this field'" v-else>?</span>
            </td>
            <td>
              <b-form-input v-if="evaluationData[student.institution_user_id].topic_id"
                v-model="evaluationData[student.institution_user_id].presentation_date" placeholder="YYYY/MM/DD">
              </b-form-input>
              <span class="badge badge-pill badge-dark btn" role="button" v-b-tooltip.hover
                :title="'Add this student to a topic group to enable this field'" v-else>?</span>
            </td>
            <td v-for="(header, hrindex) in enabledHeaders" :key="hrindex">

              <b-form-input v-model="
              evaluationData[student.institution_user_id].scores[header.id]" :max="header.maximum_mark" type="number"
                placeholder="Enter score">
              </b-form-input>

            </td>
          </tr>
        </table>
      </div>
    </b-modal>



    <b-modal size="xl" id="globalGroupModel" no-close-on-backdrop no-close-on-keyboard no-close-on-esc hide-header
      hide-footer>
      <b-button @click="$bvModal.hide('globalGroupModel'); getCourseStudentGroups()">Close</b-button>
      <br>
      <StudentsGrouping :model_id="course_id" :model_type="'Course'" />
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import StudentsGrouping from "../globalgroup/dashboard.vue";
export default {
  props: ["course_id"],
  async created() {
    await Promise.all([
      this.getCoordinators(),
      this.getTopics(),
      this.getEvaluationHeaders(),
    ]);
    this.fetchData = false;
  },
  // components: {
  //   createModule: createModule,
  // },
  data() {
    return {
      fetchData: false,
      coordinators: [],
      selectedCoordinators: [],
      loadCoordinators: false,
      faculties: [],
      loadTopics: false,
      topics: [],
      topic: {
        id: '',
        topic: '',
        supervisor_id: null,
        group_id: null,
        course_id: null
      },
      courseGroups: [],
      evaluationHeaders: {
        ProjectProgressEvaluationByGuide: { id: "", enable: false, maximum_mark: "", no: "" },
        ProjectIdentificationAndModeling: { id: "", enable: false, maximum_mark: "", no: "" },
        ImplementingTechnicalSolutionsAndUsageOfTools: { id: "", enable: false, maximum_mark: "", no: "" },
        RelevanceOfTopic: { id: "", enable: false, maximum_mark: "", no: "" },
        RoleOfIndividualAndAttendance: { id: "", enable: false, maximum_mark: "", no: "" },
        InterimEvaluation: { id: "", enable: false, maximum_mark: "", no: "" },
        TwoInterimEvaluation: { id: "", enable: false, maximum_mark: "", no: "" },
        FinalEvaluation: { id: "", enable: false, maximum_mark: "", no: "" },
        QualityOfTheReport: { id: "", enable: false, maximum_mark: "", no: "" },
        Record: { id: "", enable: false, maximum_mark: "", no: "" },
        Viva: { id: "", enable: false, maximum_mark: "", no: "" },
        Report: { id: "", enable: false, maximum_mark: "", no: "" },
      },
      editHeaders: false,
      loadHeaders: false,
      students: [],
      loadEvaluation: false,
      evaluationData: {},
    };
  },
  components: {
    StudentsGrouping
  },
  computed: {
    canEnableEvaluation() {
      this.evaluationHeaders;
      if (this.topics.length > 0) {
        for (const key in this.evaluationHeaders) {
          const header = this.evaluationHeaders[key];
          if (header.enable) {
            return true;
          }
        }
      }
      return false;
    },
    enabledHeaders() {
      let headers = [];
      for (const key in this.evaluationHeaders) {
        const element = this.evaluationHeaders[key];
        if (element.enable && element.id != '') {
          headers.push({ name: key, id: element.id, maximum_mark: element.maximum_mark, no: element.no })
        }
      }
      return headers;
    },
  },
  methods: {
    async getTopics() {
      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/projecttopics?course=${this.course_id}`;
      await axios
        .get(url)
        .then((response) => {
          this.topics = response.data;
        });
    },
    async updateTopic(topic) {
      if (topic == null) {
        this.topic.id = '';
        this.topic.topic = '';
        this.topic.supervisor_id = '';
        this.topic.group_id = '';
        this.topic.course_id = this.course_id;
      } else {
        this.topic.id = topic.id;
        this.topic.topic = topic.topic;
        this.topic.supervisor_id = topic.supervisor_id;
        this.topic.group_id = topic.group_id;
        this.topic.course_id = topic.course_id;
      }

      this.$bvModal.show("topicsModel");
      this.loadTopics = true;
      if (this.faculties.length < 1) {
        await this.getFaculties();
      }
      if (this.courseGroups.length < 1) {
        await this.getCourseStudentGroups();
      }
      this.loadTopics = false;
    },
    async deleteTopic(topic, index) {
      this.loadTopics = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/projecttopics/${topic.id}`;
      await axios
        .delete(url)
        .then(() => {
          this.$toast.warning("Topic deleted", {
            position: "top",
            duration: 3000,
          });
          this.topics.splice(index, 1);
        })
        .catch(() => {
          this.$toast.error(
            "Failed to delete/ you cannot delete if Score is already added by this faculty",
            {
              position: "top",
              duration: 3000,
            }
          );
        });

      this.loadTopics = false;
    },

    async postTopic() {
      this.loadTopics = true;
      if (this.topic.id == "") {
        // create mode
        const url = this.$store.getters.getAPIKey.mainAPI + `/projecttopics`;
        await axios
          .post(url, {
            topic: this.topic.topic,
            supervisor_id: this.topic.supervisor_id,
            group_id: this.topic.group_id,
            course_id: this.topic.course_id,
          })
          .then(() => {
            this.getTopics();
          })
          .catch(() => {
            this.$toast.error("Failed to create", {
              position: "top",
              duration: 3000,
            });
            this.fetchData = false;
          });
      } else {
        const url =
          this.$store.getters.getAPIKey.mainAPI +
          `/projecttopics/${this.topic.id}`;
        await axios
          .put(url, {
            topic: this.topic.topic,
            supervisor_id: this.topic.supervisor_id,
            group_id: this.topic.group_id,
            course_id: this.topic.course_id,
          })
          .then(() => {
            this.getTopics();
          })
          .catch(() => {
            this.$toast.error("Failed to update", {
              position: "top",
              duration: 3000,
            });
            this.fetchData = false;
          });
      }
      this.$bvModal.hide("topicsModel");
      this.loadTopics = false;
    },

    async fetchCourseStudents() {
      let mainUrl = this.$store.getters.getAPIKey.createStudent;
      const url = mainUrl.replace("course_id", this.course_id);
      await this.$axios.get(url).then((response) => {
        this.students = [];
        this.students = response.data[0];
      });
    },
    async addCoordinators() {
      this.$bvModal.show("coordinatorModel");
      if (this.faculties.length < 1) {
        this.loadCoordinators = true;
        await this.getFaculties();
        this.loadCoordinators = false;
      }
    },
    async updateCoordinators() {
      this.loadCoordinators = true;
      let CoordinatorIds = [];
      this.selectedCoordinators.forEach(element => {
        CoordinatorIds.push(element.id)
      });
      const url = this.$store.getters.getAPIKey.mainAPI + `/projectcoordinators`;
      await axios
        .post(url, {
          course_id: this.course_id,
          faculty_ids: CoordinatorIds
        })
        .then(() => {
          this.getCoordinators();
        })
        .catch(() => {
          this.$toast.error("Failed to update, Please try again", {
            position: "top",
            duration: 3000,
          });
        });
      this.loadCoordinators = false;
      this.$bvModal.hide("coordinatorModel");
    },
    async getFaculties() {
      this.faculties = [];
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/institutionusers/faculties`;
      await axios
        .get(url)
        .then((response) => {
          response.data.forEach(faculty => {
            this.faculties.push({ id: faculty.id, name: `${faculty.first_name} ${faculty.last_name}(${faculty.email})` });
          });
        });
    },
    async deleteCoordinator(faculty, index) {
      this.loadCoordinators = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/projectcoordinators/${faculty.id}?course_id=${this.course_id}`;
      await axios
        .delete(url)
        .then(() => {
          this.$toast.warning("Faculty removed", {
            position: "top",
            duration: 3000,
          });
          this.coordinators.splice(index, 1);
        })
        .catch(() => {
          this.$toast.error(
            "Failed to remove/ you cannot remove if Score is already added by this faculty",
            {
              position: "top",
              duration: 3000,
            }
          );
        });

      this.loadCoordinators = false;
    },
    async getCoordinators() {
      this.loadCoordinators = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/projectcoordinators?course=${this.course_id}`;
      await axios
        .get(url)
        .then((response) => {
          this.coordinators = response.data;
          this.selectedCoordinators = [];
          this.coordinators.forEach(faculty => {
            this.selectedCoordinators.push({ id: faculty.id, name: `${faculty.first_name} ${faculty.last_name}(${faculty.email})` });
          });
        });

      this.loadCoordinators = false;
    },

    async getCourseStudentGroups() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/globalgroups?with_usercount=1&model_type=Course&model_id=${this.course_id}`;
      await axios
        .get(url)
        .then((response) => {
          this.courseGroups = response.data;
        })
        .catch(() => {
          this.$toast.error("Failed to get List", {
            position: "top",
            duration: 3000,
          });
        });
    },

    async getEvaluationHeaders() {
      this.loadHeaders = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/projectevaluationheaders?course_id=${this.course_id}`;
      await axios
        .get(url)
        .then((response) => {
          response.data.forEach(header => {
            this.evaluationHeaders[header.name].enable = true;
            this.evaluationHeaders[header.name].no = header.no;
            this.evaluationHeaders[header.name].id = header.id;
            this.evaluationHeaders[header.name].maximum_mark = header.maximum_mark;
          });
        });
      this.loadHeaders = false;
    },
    async updateEvaluationHeaders() {
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/projectevaluationheaders`;
      var postData = [];

      for (var headerKey in this.evaluationHeaders) {
        if (this.evaluationHeaders[headerKey].enable)
          postData.push({
            maximum_mark: this.evaluationHeaders[headerKey]
              .maximum_mark,
            name: headerKey,
            no: this.evaluationHeaders[headerKey].no,
          });
      }
      await axios
        .post(url, { projectHeaders: postData, course_id: this.course_id })
        .then((response) => {
          response;
        })
        .catch(() => {
          this.$toast.error(
            "Failed to update/ you cannot delete if Score is already added",
            {
              position: "top",
              duration: 5000,
            }
          );
        });
      this.editHeaders = false;
    },

    async evaluationModel() {
      this.loadEvaluation = true;
      this.$bvModal.show("evaluationModel");

      await Promise.all([
        this.getProjectEvaluation(),
        this.fetchCourseStudents()
      ]);
      this.loadEvaluation = false;
    },
    async getProjectEvaluation() {
      this.loadEvaluation = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/projectevaluations?course_id=${this.course_id}`;
      await axios
        .get(url)
        .then((response) => {
          this.evaluationData = response.data.evaluationData;
        });
      this.loadEvaluation = false;
    },

    async updateEvaluation() {
      this.loadEvaluation = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/projectevaluations`;
      await axios
        .post(url, { course_id: this.course_id, evaluationData: this.evaluationData })
        .then((response) => {
          response;

          this.$toast.success(
            "Score updated successfully",
            {
              position: "top",
              duration: 3000,
            }
          );
        })
        .catch(() => {
          this.$toast.error(
            "Failed to update",
            {
              position: "top",
              duration: 5000,
            }
          );
        });

      this.$bvModal.hide("evaluationModel");
      this.loadEvaluation = false;
    },


    async projectEvaluationReport() {
      this.loadEvaluation = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/projectevaluations/report/pdfexport`;
      await this.$axios
        .post(
          url,
          {
            course: this.course_id,
          },
          { responseType: "blob" }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "ProjectReportSummary.pdf");
          document.body.appendChild(link);
          link.click();
          link.remove();
          this.downloadData = false;

          // console.log(response);
        })
        .catch((error) => {
          let errorText = "Failed to Download, please check connectivity";
          if (error.response != undefined)
            errorText = error.response.status == 422 ? 'Custom report is not configured by admin' : "Failed to Download, please try again";
          this.$toast.error(errorText, {
            position: "top",
            duration: 3000,
          });
        });

      this.loadEvaluation = false;
    },


    async projectTopicsReport() {
      this.loadEvaluation = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/projectevaluations/topics/report/pdfexport`;
      await this.$axios
        .post(
          url,
          {
            course: this.course_id,
          },
          { responseType: "blob" }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "ProjectReportSummary.pdf");
          document.body.appendChild(link);
          link.click();
          link.remove();
          this.downloadData = false;

          // console.log(response);
        })
        .catch((error) => {
          let errorText = "Failed to Download, please check connectivity";
          if (error.response != undefined)
            errorText = error.response.status == 422 ? 'Custom report is not configured by admin' : "Failed to Download, please try again";
          this.$toast.error(errorText, {
            position: "top",
            duration: 3000,
          });
        });

      this.loadEvaluation = false;
    },


    async projectEvaluationSummaryReport() {
      this.loadEvaluation = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/projectevaluations/summary/report/pdfexport`;
      await this.$axios
        .post(
          url,
          {
            course: this.course_id,
          },
          { responseType: "blob" }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "ProjectReportSummary.pdf");
          document.body.appendChild(link);
          link.click();
          link.remove();
          this.downloadData = false;

          // console.log(response);
        })
        .catch((error) => {
          let errorText = "Failed to Download, please check connectivity";
          if (error.response != undefined)
            errorText = error.response.status == 422 ? 'Custom report is not configured by admin' : "Failed to Download, please try again";
          this.$toast.error(errorText, {
            position: "top",
            duration: 3000,
          });
        });

      this.loadEvaluation = false;
    },
  },
};
</script>

<style >
.tag-button button {
  font-size: 9px !important;
  padding: 3px;
}

.labEvaluation .modal-dialog {
  max-width: 100%;
  max-height: 100vh;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  position: fixed;
  z-index: 100000;
}

.labEvaluation .modal-body {
  overflow: scroll;
}

.labEvaluation .fixed-table-column {
  position: sticky;
  left: 0;
  background-color: gray;
}

</style>
