<template>
  <div class="container">
    <h4 class="text text-center text-bold">Add new lesson plan</h4>
    <b-form-group id="input-group-1" label-for="input-1" description="">
      <label class="required-label" for="">Si. No.:</label>
      <b-form-input id="input-1" v-model="lessonPlan.si_no" type="text" placeholder="Enter a Number"></b-form-input>
    </b-form-group>

    <b-form-group id="input-group-1" label-for="input-1" description="">
      <label class="required-label" for="">Lesson:</label>
      <b-form-input id="input-1" v-model="lessonPlan.lesson" type="text" placeholder="Enter a Lesson"></b-form-input>
    </b-form-group>

    <b-form-group id="input-group-1" label-for="input-1" description="">
      <label class="" for="">Required hours:</label>
      <b-form-input id="input-1" v-model="lessonPlan.required_hours" type="text"
        placeholder="Enter a Number"></b-form-input>
    </b-form-group>

    <b-form-group id="input-group-1" label-for="input-1" description="">
      <label class="" for="">Planned Date:</label><b-form-datepicker id="example-datepicker"
        v-model="lessonPlan.planned_dates"></b-form-datepicker>
    </b-form-group>

    <b-form-group id="input-group-1" label-for="input-1" description="">
      <label class="" for="">Cognitive Levels:</label>
      <multiselect v-model="lessonPlan.cognitive_levels"
        :options="['Remembering', 'Understanding', 'Applying', 'Analyzing', 'Evaluating', 'Creating', 'Knowledge']" :multiple="true"
        :close-on-select="false" :clear-on-select="false" placeholder="Select Cognitive levels"
        :preselect-first="false">
      </multiselect>
    </b-form-group>

    <b-form-group id="input-group-1" label-for="input-1" description="">
      <label class="" for="">Module:</label>
      <b-form-select v-model="lessonPlan.course_module_id" :options="courseModulesOptions" size="sm"></b-form-select>
    </b-form-group>


    <b-form-group id="input-group-1" label-for="input-1" description="">
      <label class="" for="">Outcomes:</label>
      <multiselect placeholder="outcome" v-model="lessonPlan.course_outcomes" :options="courseOutcomesOptions"
        :multiple="true" :close-on-select="false" track-by="value" label="text">
      </multiselect>

    </b-form-group>

    <div>
      <b-button variant="danger" class="mr-3" @click="$emit('cancel')">cancel</b-button>
      <b-button @click="prepareLessonPlans" variant="success">Submit</b-button>
    </div>

  </div>
</template>

<script>
// import moment from "moment";
export default {
  props: ["courseId", "date", "siNo", 'sessionCount'],
  async created() {
    await this.getCourseOutcomes();
    await this.getCourseModules();
    this.lessonPlan = {
      si_no: this.siNo,
      lesson: '',
      planned_dates: this.date,
      required_hours: this.sessionCount,
      course_id: this.courseId,
      course_outcomes: [],
      course_module_id: null,
      cognitive_levels: []
    },
      this.lessonBtn.text = 'save';
    this.lessonBtn.disabled = false;
  },
  components: {
  },
  data() {
    return {
      lessonBtn: {
        text: "Loading...",
        disabled: true,
      },
      courseModulesOptions: [],
      courseOutcomesOptions: [],
      lessonPlan: {
        si_no: '',
        lesson: '',
        planned_dates: '',
        required_hours: '1',
        course_id: '',
        course_outcomes: [],
        course_module_id: null,
        cognitive_levels: []
      },
    };
  },
  computed: {

  },
  methods: {

    async prepareLessonPlans() {
      this.lessonBtn = {
        text: "Saving...",
        disabled: true,
      };

      let postData = {
        si_no: this.lessonPlan.si_no,
        lesson: this.lessonPlan.lesson,
        planned_dates: this.lessonPlan.planned_dates,
        required_hours: this.lessonPlan.required_hours,
        course_id: this.lessonPlan.course_id,
        course_outcomes: this.lessonPlan.course_outcomes.map(obj => obj.value),
        course_module_id: this.lessonPlan.course_module_id,
        cognitive_levels: this.lessonPlan.cognitive_levels,
      };

      const url = this.$store.getters.getAPIKey.lessonplans;
      await this.$axios
        .post(url, postData)
        .then(() => {
          this.$toast.success('Lesson plan created successfully', {
            position: "top",
            duration: 3000,
          });
          this.$emit('success');
        })
        .catch((error) => {
          let errortxt = "";
          if (error.response)
            if (error.response.status == 422) {
              let errormsgs = error.response.data.errors;
              for (const key in errormsgs) {
                errortxt = errormsgs[key][0];
              }
            } else {
              errortxt = error.response.data.message;
            }
          else {
            errortxt = "Failed to Create";
          }
          this.$toast.error(errortxt, {
            position: "top",
            duration: 7000,
          });

          this.lessonBtn.text = 'Try again';
          this.lessonBtn.disabled = false;
        });

    },

    getCourseOutcomes() {
      let courseOutcomeUrl = this.$store.getters.getAPIKey.getCourseOutcomes;
      const url = courseOutcomeUrl.replace("course_id", this.courseId);
      this.$axios
        .get(url)
        .then((response) => {
          let outcomes = response.data;
          // this.courseOutcomesOptions = [
          //   { value: null, text: "Select Outcome", disabled: true },
          // ];
          outcomes.forEach((cOutcome) => {
            this.courseOutcomesOptions.push({
              value: cOutcome.id,
              text: cOutcome.code,
            });
          });
        })
        .catch(() => {
          // console.log(error.response);
        });
    },
    getCourseModules() {
      let courseModuleUrl = this.$store.getters.getAPIKey.getCourseModules;
      const url = courseModuleUrl.replace("course_id", this.courseId);
      this.$axios
        .get(url)
        .then((response) => {
          this.courseModulesOptions = [
            { value: null, text: "Select Module", disabled: true },
          ];
          let modules = response.data;
          modules.forEach((cModule) => {
            this.courseModulesOptions.push({
              value: cModule.id,
              text: cModule.module,
            });
          });
        })
        .catch(() => {
          // console.log(error.response);
        });
    },

    // test(){
    //   this.$emit('success');
    //   this.$emit('cancel');
    // },
  },
};
</script>

<style  >

</style>
