<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="attainmentCO" v-else>
      <b-row>
        <b-col md="12">
          <div class="viewOrConfig mb-3">
            <b-button size="sm" :variant="config ? 'success' : 'outline-primary'" class="font-weight-bolder"
              disabled>Configured</b-button>
            <!-- <b-button size="sm" disabled :variant="!config ? 'success' : 'outline-primary'"
              class="ml-4 font-weight-bolder">View Data</b-button> -->
          </div>
          <div class="table-responsive" v-if="config">
            <table class="table table-bordered table-striped">
              <thead class="thead-dark">
                <tr>
                  <th>
                    <b-form-checkbox @change="selectAllData" v-model="selectAll">{{
                        selectAll ? "Unselect All" : "Select All"
                    }}</b-form-checkbox>
                  </th>
                  <th>Roll No.</th>
                  <th>Admission No.</th>
                  <th>Name</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(student, studentIndex) in studentsOptions" :key="studentIndex">
                  <td class="align-middle">
                    <b-form-checkbox size="sm" v-model="selectedStudents" :value="student.id"></b-form-checkbox>
                  </td>
                  <td class="align-middle">
                    {{ student.roll_number }}
                  </td>
                  <td class="align-middle">
                    {{ student.admission_no }}
                  </td>
                  <td class="align-middle">
                    {{ student.name }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- <div class="table-responsive" v-else>
            <table class="table table-bordered table-striped">
              <thead class="thead-dark">
                <tr>
                  <th>Roll No.</th>
                  <th>Admission No.</th>
                  <th>Name</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(student, studentIndex) in students" :key="studentIndex">
                  <td class="align-middle">
                    {{ student.roll_number }}
                  </td>
                  <td class="align-middle">
                    {{ student.admission_no }}
                  </td>
                  <td class="align-middle">
                    {{ student.first_name || " " }}
                    {{ student.last_name || " " }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div> -->
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  props: ["courseReport"],
  async created() {
    this.fetchData = true;
    await this.getAttainmentConfigration();
    this.fetchData = false;
  },

  data() {
    return {
      fetchData: false,
      studentsOptions: [],
      selectedStudents: [],
      students: {},
      config: true,
      selectAll: true,
    };
  },
  methods: {
    selectAllData(status) {
      this.selectedStudents = [];
      if (status) {
        this.studentsOptions.forEach((co) => {
          this.selectedStudents.push(co.id);
        });
      } else {
        this.selectedStudents = [];
      }
    },
    // async getAttainmentData() {
    //   const url =
    //     this.$store.getters.getAPIKey.mainAPI + `/coattainment/getdata`;
    //   await this.$axios
    //     .post(url, {
    //       course: this.courseReport.course_id,
    //       step: "course_students",
    //     })
    //     .then((response) => {
    //       this.students = response.data;
    //     });
    // },
    async getAttainmentConfigration() {
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/coattainment/getconfig`;
      await this.$axios
        .post(url, {
          course: this.courseReport.course_id,
          step: "course_students",
        })
        .then((response) => {
          this.studentsOptions = [];
          let students = response.data.students;
          students.forEach((student) => {
            this.studentsOptions.push({
              id: student.id,
              name: student.first_name + " " + (student.last_name || ""),
              roll_number:
                student.roll_number != null
                  ? student.roll_number
                  : "RN",
              admission_no: student.admission_no,
            });
          });
          this.selectedStudents = response.data.selected_students;
        });
    },
    async setAttainmentConfigration() {
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/coattainment/setconfig`;
      await this.$axios
        .post(url, {
          course: this.courseReport.course_id,
          step: "course_students",
          course_students: this.selectedStudents,
        })
        .then(() => { });
    },
    // async getAttainmentCourseOutcomes() {
    //   const url =
    //     this.$store.getters.getAPIKey.mainAPI + `/coattainment/getdata`;
    //   await this.$axios
    //     .post(url, {
    //       course: this.courseReport.course_id,
    //       step: "course_students",
    //     })
    //     .then(() => { });
    // },
  },
};
</script>
