<template>
  <div>
    <b-col sm="12">
      <h3 style="text-align: center; font-size:18px !important;">
        Mark Attendance
      </h3>

      <b-form-group id="input-group-1" label-for="input-1" description="">
        <label class="required-label" for="">Course:</label>
        <b-form-select v-model="selectedSlots.course_id" :options="courses" size="sm"></b-form-select>
      </b-form-group>
      <b-form-group id="input-group-1" label-for="input-1" description="">
        <label class="required-label" for="">Date:</label><b-form-datepicker id="example-datepicker"
          v-model="selectedSlots.date"></b-form-datepicker>
      </b-form-group>
      <b-form-group>
        <label class="required-label" for="">Sessions:</label>
        <b-form-checkbox-group id="checkbox-group-1" v-model="selectedSlots.sessions" :options="sessions"
          name="flavour-1"></b-form-checkbox-group>
      </b-form-group>

      <b-button @click="$bvModal.hide('customDateAttendanceMarkModel')" class="mr-3" variant="danger">Cancel</b-button>
      <b-button @click="markNewAttendance" variant="primary">Proceed</b-button>
    </b-col>
  </div>
</template>

<script>
// import moment from "moment";
export default {
  props: ["sessionsProp"],
  async created() {
    if (this.sessionsProp == null || this.sessionsProp == undefined) {
      await this.getSessions();
    } else {
      this.sessionsProp.forEach((session) => {
        this.sessions.push({
          value: session.id,
          text: `${session.name}`,
        });
      });
    }

    this.getMyCourses();
  },
  components: {
  },
  data() {
    return {
      courses: [],
      sessions: [],
      selectedSlots: {
        date: new Date(),
        sessions: [],
        course_id: '',
        confirm_text: '', // pass to att mark form
      },
    };
  },
  methods: {
    markNewAttendance() {

      let tempDate = new Date(this.selectedSlots.date).toLocaleDateString();
      let sessionNames = '';
      this.sessions.forEach(session => {
        if (this.selectedSlots.sessions.includes(session.value)) sessionNames += session.text + ', ';
      });
      let tempCourse = '';
      this.courses.forEach(course => {
        if (course.value == this.selectedSlots.course_id) tempCourse = course.text;
      });
      this.selectedSlots.confirm_text = 'Mark attendance for ' +
        tempCourse + ' on ' + tempDate + ' and sessions: ' + sessionNames;

      this.$emit('markNewAttendance', this.selectedSlots)
    },

    async getSessions() {
      const url = this.$store.getters.getAPIKey.createSessions;
      await this.$axios
        .get(url)
        .then((response) => {
          let sessions = response.data;
          sessions.forEach((session) => {
            this.sessions.push({
              value: session.id,
              text: `${session.name}`,
            });
          });
        })
        .catch((error) => {
          error;
        });
    },

    async getMyCourses() {
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/institutionuser/courses`;
      await this.$axios
        .get(url)
        .then((response) => {
          let responseData = response.data;
          this.courses = [
            { value: null, text: "Select Course" },
          ];

          responseData.forEach((course) => {
            this.courses.push({
              value: course.id,
              text: `${course.name}(${course.usersubgroup.code}-${course.usersubgroup.usergroup.code})`,
            });
          });
        })
        .catch(() => {
          // console.log(error.response);
        });
    },
  },
};
</script>

<style  >

</style>
