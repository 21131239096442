<template>
  <div>
    <h4 class="text-center m-3" style="font-size: 17px !important;"> Manage {{ usergroup.name }} s Fee Structure</h4>

    <table class="table">
      <thead>
        <tr>
          <th style="width: 20px;">Si.No.</th>
          <th style="width: 250px;">Sub Category</th>
          <th>Fee Types</th>
          <th>{{ subgroupCustomName }}'s</th>
        </tr>
      </thead>
      <tbody v-for="(category, cindex) in financeCatagories" :key="cindex">
        <tr>
          <th colspan="4" class="text-center" style="font-size: 14px !important;">{{ category.name }}</th>
        </tr>
        <tr v-for="(subcategory, subindex) in category.finance_subcategories" :key="subindex">
          <td>{{ (subindex + 1) }}</td>
          <td>{{ subcategory.name }}</td>
          <td colspan="2">
            <div class="border p-2 m-1" v-for="(feeType, ftindex) in subcategory.finance_subsubcategories"
              :key="ftindex">
              <b-row>
                <b-col md="auto"><span class="mr-2">{{ feeType.name }}</span> 
                  <small style="font-size: 10px !important;">₹{{ feeType.amount }} | {{ feeType.start_date }}-{{ feeType.end_date !=null? feeType.end_date:'any time' }}. <br>
                  {{feeType.due_date != null ? 'Due on ' + feeType.due_date + '(₹' + feeType.fine_amount + ')' : ''}}
                </small>
                </b-col>
                <b-col md="auto">
                  <span>{{ feeType.name }} </span>
                  <b-link class="ml-2" @click="OpenAddSubgroupModel(feeType.id)">Add {{ subgroupCustomName }}</b-link>
                </b-col>
              </b-row>
            </div>
            <b-link @click="OpenNewFeeTypeModel(subcategory.id)">Add Fee Type</b-link>
          </td>
        </tr>
      </tbody>
    </table>

    <b-modal id="NewFeeTypeModel" no-close-on-backdrop no-close-on-keyboard no-close-on-esc hide-footer hide-header
      centered size="md">
      <h3 class="text-center">Add New Fee Type</h3>

      <b-form-group id="input-group-1" label-for="input-1" description="">
        <label class="required-label" for="">Si No:</label>
        <b-form-input id="input-1" v-model="newFeeType.si_no" type="text" placeholder="Enter Number"></b-form-input>
      </b-form-group>
      <b-form-group id="input-group-1" label-for="input-1" description="">
        <label class="required-label" for="">Name:</label>
        <b-form-input id="input-1" v-model="newFeeType.name" type="text"
          placeholder="Enter Category Name"></b-form-input>
      </b-form-group>
      <b-form-group id="input-group-1" label-for="input-1" description="">
        <label class="label" for="">Description:</label>
        <b-form-input id="input-1" v-model="newFeeType.description" type="text"
          placeholder="Enter a Description"></b-form-input>
      </b-form-group>
      <b-form-group id="input-group-1" label-for="input-1" description="">
        <label class="required-label" for="">Amount:</label>
        <b-form-input id="input-1" v-model="newFeeType.amount" type="text"
          placeholder="Enter a Amount in rupees"></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-1" label-for="input-1" description="">
        <label class="required-label" for="">Start Date:</label>
        <b-form-input id="input-1" v-model="newFeeType.start_date" type="text" placeholder=""></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-1" label-for="input-1" description="">
        <label class="required-label" for="">End Date:</label>
        <b-form-input id="input-1" v-model="newFeeType.end_date" type="text" placeholder=""></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-1" label-for="input-1" description="">
        <label class="required-label" for="">Due Date(Pay with Fine):</label>
        <b-form-input id="input-1" v-model="newFeeType.due_date" type="text" placeholder=""></b-form-input>
      </b-form-group>
      <b-form-group id="input-group-1" label-for="input-1" description="">
        <label class="label" for="">Fine Amount:</label>
        <b-form-input id="input-1" v-model="newFeeType.fine_amount" type="text"
          placeholder="Enter a Amount in rupees"></b-form-input>
      </b-form-group>

      <b-row class="p-2">
        <b-col style="text-align:left;">
          <b-button variant="danger" class="text-white sm" size="sm"
            @click="$bvModal.hide('NewFeeTypeModel');">Close</b-button>
        </b-col>
        <b-col style="text-align:right;">
          <b-button variant="success" class="text-white sm" size="sm" @click="AddNewFeeType">Add</b-button>
        </b-col>
      </b-row>
    </b-modal>


    <b-modal id="addSubgroupModel" no-close-on-backdrop no-close-on-keyboard no-close-on-esc hide-footer hide-header
      centered size="md">
      <h3 class="text-center">Add {{ subgroupCustomName }} To Fee Type</h3>
      <b-row>
        <b-col  v-for="(subgroup, ftindex) in usersubgroups"
              :key="ftindex">{{subgroup.name}} <b-link @click="addSubgroupToFee(subgroup.id)">Add</b-link> </b-col>
      </b-row>
      <!-- <b-row class="p-2">
        <b-col style="text-align:left;">
          <b-button variant="danger" class="text-white sm" size="sm"
            @click="$bvModal.hide('addSubgroupModel');">Close</b-button>
        </b-col>
        <b-col style="text-align:right;">
          <b-button variant="success" class="text-white sm" size="sm" @click="addSubgroupToFee">Add</b-button>
        </b-col>
      </b-row> -->
    </b-modal>
  </div>
</template>

<script>
//

export default {
  props: ['usergroup', 'financeCatagories'],
  async created() {
    // console.log(this.financeCatagories);
    // this.getUsergroupFinanceCatagories();
    this.subgroupCustomName = this.$store.getters.getCustomNamings.userSubgroup.custom_name ?? "Usersubgroup";
  },
  components: {},
  data() {
    return {
      subgroupCustomName: '',
      LoadData: false,
      newFeeType: {},
      addSubgroupToFeeType: { finance_subsubcategory_id: null, usersubgroup_id: [] },
      usersubgroups:[],
      // editMode: false,
      // financeSubcatagoryUsergroup:[],
    };
  },
  computed: {

  },
  methods: {
    async addSubgroupToFee(subgroupId) {
      this.addSubgroupToFeeType.usersubgroup_id.push(subgroupId);
      this.$bvModal.hide('addSubgroupModel');
      // api
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/financesubsubcatagories/usersubgroups`;
      await this.$axios
        .post(url, this.addSubgroupToFeeType)
        .then(() => {
          // this.getAllFinanceCatagories();
        })
        .catch((error) => {
          error;
        });
    },
    OpenAddSubgroupModel(feeTypeId) {
      this.addSubgroupToFeeType.finance_subsubcategory_id = feeTypeId;
      this.$bvModal.show('addSubgroupModel');
      if(this.usersubgroups.length<1)this.getUsersubgroups();
    },
    OpenNewFeeTypeModel(subcategoryId) {
      this.newFeeType.finance_subcategory_id = subcategoryId;
      this.$bvModal.show('NewFeeTypeModel');
    },
    async AddNewFeeType() {
      this.$bvModal.hide('NewFeeTypeModel');
      // api
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/financesubsubcatagories`;
      await this.$axios
        .post(url, this.newFeeType)
        .then(() => {
          this.getAllFinanceCatagories();
        })
        .catch((error) => {
          error;
        });
    },

    async getAllFinanceCatagories() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/financecatagories?allsubcatagories=1`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.financeCatagories = response.data;
          //`${group.name} (${group.code}) ${group.scheme ?? ''}`,
        })
        .catch((error) => {
          error;
        });
    },

    async getUsersubgroups() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usergroups/${this.usergroup.id}/usersubgroups`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.usersubgroups = response.data;
          //`${group.name} (${group.code}) ${group.scheme ?? ''}`,
        })
        .catch((error) => {
          error;
        });
    },
    // manageSubgroupFees(){

    // },
    // async getUsergroupFinanceCatagories(){
    //   const url =
    //     this.$store.getters.getAPIKey.mainAPI +
    //     `/financesubcatagories?usergroup=${this.usergroup.id}`;
    //   await this.$axios
    //     .get(url)
    //     .then((response) => {
    //       this.financeSubcatagoryUsergroup = response.data;
    //       //`${group.name} (${group.code}) ${group.scheme ?? ''}`,
    //     })
    //     .catch((error) => {
    //       error;
    //     });
    // },

  },
};
</script>

<style  scoped>
li {
  border: none !important;
}
</style>
