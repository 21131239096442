<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="autoEnrollment" v-else>
      <br />
      <h5 class="font-weight-bolder">
        Allow auto-enrollment of newly added students to courses
      </h5>
      <b-form-checkbox
        switch
        size="lg"
        class="ml-4"
        disabled
        v-model="checked"
      ></b-form-checkbox>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      checked: true,
      fetchData: false,
    };
  },
};
</script>
