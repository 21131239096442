<template>
  <div>
    <b-overlay style="position: static !important;" :show="loadTeachers" spinner-variant="primary" spinner-type="grow"
      spinner-small rounded="md">
      <div class="d-flex" style="height: 200px">
        <div class="courseNames w-50">
          <b-form-select :options="myCoursesOptions" v-model="myCourseId" @change="getCourseUsers"
            :disabled="disableChange"></b-form-select>
        </div>
        <div class="courseUsers w-50" v-if="courseUsers.length != 0" style="overflow-y: scroll">
          <div class="d-flex flex-wrap">
            <p v-for="(student, studentIndex) in courseUsers" :key="studentIndex"
              class="bg-success p-1 text-white rounded ml-2" style="font-size: 12px; font-weight: 600">
              {{ student.first_name || " " }} {{ student.last_name || " " }}
            </p>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
export default {
  props: ["myCoursesOptions", "surveyId", "survey", "disableChange"],
  async created() {
    if (this.survey.id && this.survey.course_id) {
      this.myCourseId = await this.survey.course_id;
      await this.getCourseUsers();
    }
  },
  data() {
    return {
      loadTeachers: false,
      myCourseId: null,
      courseUsers: [],
    };
  },
  methods: {
    async getCourseUsers() {
      this.loadTeachers = true;
      await this.myCourseId;
      await this.$emit("course_id", this.myCourseId);
      this.courseUsers = [];
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/course/${this.myCourseId}/students`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.courseUsers = response.data[0];
          this.loadTeachers = false;
          response;
        })
        .catch((error) => {
          this.loadTeachers = false;
          error;
        });
    },
  },
};
</script>
