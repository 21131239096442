<template>
  <div>
    <b-skeleton-table v-if="fetchData" :rows="4" :columns="6"
      :table-props="{ bordered: true, striped: true }"></b-skeleton-table>
    <div class="admins" v-else>
      <b-overlay :show="deleteAdmin" spinner-variant="primary" spinner-type="grow" spinner-small rounded="md">
        <div class="addAdmin mb-2">
          <b-button size="sm" variant="primary" v-if="!createAdmin" @click="createAdmin = !createAdmin">Add {{
              getAdminName
          }}</b-button>
        </div>

        <div class="createAdmin mb-2" v-if="createAdmin">
          <AddAdmins :editInfo="editInfo" :adminList="adminList" :existingAdmins="existingAdmins"
            @adminsCreated="adminsCreated" />
        </div>
        <table class="table table-striped table-bordered" v-else>
          <thead class="thead-dark">
            <tr>
              <th>Name</th>
              <th>Remove</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="existingAdmins.length == 0">
              <td colspan="100" class="text-center text-danger">
                No {{ getAdminName }} Assigned
              </td>
            </tr>
            <tr v-else v-for="(admin, adminIndex) in existingAdmins" :key="adminIndex">
              <td :class="{ 'align-middle': true, 'text-danger': admin.enroll_status != 'active' }">{{ admin.name }}</td>
              <td class="align-middle text-center">
                <span class="btn p-0 m-0 text-danger" @click="removeAdmin(admin.id, adminIndex)"><i
                    class="fas fa-trash"></i></span>
              </td>
            </tr>
          </tbody>
        </table>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import AddAdmins from "./AddAdmins";
export default {
  props: ["editInfo", "adminList", "getCustomNames"],
  async created() {
    this.fetchData = true;
    await this.getAdmins();
    this.fetchData = false;
  },
  components: {
    AddAdmins,
  },
  data() {
    return {
      fetchData: false,
      createAdmin: false,
      existingAdmins: [],
      deleteAdmin: false,
    };
  },
  computed: {
    getAdminName() {
      if (this.editInfo.group == true) {
        return this.getCustomNames.userGroupAdmin.custom_name != null
          ? this.getCustomNames.userGroupAdmin.custom_name
          : "Admin";
      } else if (this.editInfo.group == false) {
        return this.getCustomNames.userSubGroupAdmin.custom_name != null
          ? this.getCustomNames.userSubGroupAdmin.custom_name
          : "Admin";
      } else {
        return this.getCustomNames.maingroupAdmin.custom_name != null
          ? this.getCustomNames.maingroupAdmin.custom_name
          : "Admin";
      }
    },
  },
  methods: {
    adminsCreated(status) {
      if (status) {
        this.createAdmin = false;
        this.getAdmins();

        this.$emit("updateInstitutionUserCount", true);
      } else {
        this.createAdmin = false;
      }
    },
    async getAdmins() {
      this.fetchData = true;
      let url;
      if (this.editInfo.group == true) {
        url =
          this.$store.getters.getAPIKey.mainAPI +
          `/usergroup/${this.editInfo.id}/users`;
      } else if (this.editInfo.group == false) {
        url =
          this.$store.getters.getAPIKey.mainAPI +
          `/usersubgroup/${this.editInfo.id}/users`;
      } else {
        url =
          this.$store.getters.getAPIKey.mainAPI +
          `/maingroups/${this.editInfo.id}/users`;
      }

      await this.$axios
        .get(url)
        .then((response) => {
          this.existingAdmins = [];
          response.data.forEach((admin) => {
            if (
              admin.pivot.usergrouprole_id == 1 ||
              admin.pivot.maingrouprole_id == 1
            ) {
              this.existingAdmins.push({
                name: admin.first_name.concat(" ", admin.last_name),
                id: admin.id,
                institution_user_id: admin.pivot.institution_user_id,
                enroll_status: admin.enroll_status,
              });
            }
          });

          response;
        })
        .catch((error) => {
          error;
        });
      this.fetchData = false;
    },
    async removeAdmin(user_id, deletedElementFromArray) {
      this.deleteAdmin = true;
      let url;
      if (this.editInfo.group == true) {
        url = this.$store.getters.getAPIKey.deleteGroupUser.replace(
          "usergroup_user_id",
          user_id
        );
      } else if (this.editInfo.group == false) {
        url = this.$store.getters.getAPIKey.deleteSubGroupUser.replace(
          "usersubgroup_user_id",
          user_id
        );
      } else {
        url =
          this.$store.getters.getAPIKey.mainAPI + `/maingroupusers/${user_id}`;
      }

      await this.$axios
        .delete(url)
        .then((response) => {
          this.existingAdmins.splice(deletedElementFromArray, 1);
          this.$emit("updateInstitutionUserCount", true);
          response;
        })
        .catch((error) => {
          error;
        });
      this.deleteAdmin = false;
    },
  },
};
</script>
