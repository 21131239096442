<template>
  <div>
    <div class="enrollInvite">
      <div class="goBack text-right">
        <p @click="$emit('closeEnroll')" class="btn m-0">
          <i class="fas fa-window-close text-danger"></i>
        </p>
      </div>
      <b-tabs content-class="mt-3" lazy>
        <b-tab :title="subgroup.name" v-for="(subgroup, subgroupIndex) in subgroupComponents" :key="subgroupIndex">
          <component :getCustomNames="getCustomNames" :groupInfo="groupInfo" v-bind:is="subgroup.component"></component>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import InstituteGroupSubGroupEnrollment from "./InstituteGroupSubGroupEnrollment.vue";
import InstituteSubgroupInvite from "../../invite/InstituteSubgroupInvite.vue";
import EnrolledUsers from "./EnrolledUsers.vue";
export default {
  props: ["groupInfo"],
  async created() {
    if (this.groupInfo.type == (await "Subgroup")) {
      this.enrollUserToSubgroup();
    }
    if (
      this.view == (await "Enroll") &&
      this.groupInfo.type == (await "Group")
    ) {
      this.enrollUserToGroup();
    }
  },
  computed: {
    getCustomNames() {
      return this.$store.getters.getCustomNamings;
    },
  },
  components: {
    InstituteGroupSubGroupEnrollment,
    InstituteSubgroupInvite,
    EnrolledUsers,
  },
  data() {
    return {
      view: "Enroll",
      subgroupComponents: [
        { name: "Enrolled Student List", component: EnrolledUsers },
        { name: "Transfer/Promote", component: InstituteGroupSubGroupEnrollment },
        { name: "Invite Student", component: InstituteSubgroupInvite },
      ],
    };
  },
  methods: {
    async viewType(viewType) {
      this.view = await viewType;
      if (
        this.view == (await "Enroll") &&
        this.groupInfo.type == (await "Subgroup")
      ) {
        this.enrollUserToSubgroup();
      }
      if (
        this.view == (await "Enroll") &&
        this.groupInfo.type == (await "Group")
      ) {
        this.enrollUserToGroup();
      }
      if (this.view == (await "List")) {
        this.usersList();
      }
    },
    async enrollUserToSubgroup() {
      this.$refs.userEnroll.options = [];
      this.$refs.userEnroll.students = [];
      await this.$refs.userEnroll.studentDetails();
    },
    async enrollUserToGroup() {
      this.$refs.userEnroll.options = [];
      this.$refs.userEnroll.faculty = [];
      await this.$refs.userEnroll.facultyDetails();
    },
    async usersList() {
      this.$refs.usersList.fetchData = await true;
      if (this.groupInfo.type == "Subgroup") {
        await this.$refs.usersList.getEnrolledSubgroupUsers();
      } else {
        await this.$refs.usersList.getEnrolledGroupUsers();
      }

      this.$refs.usersList.fetchData = await false;
    },
    closeEnrollInvite() {
      this.$parent.enrollAndInvite = false;
      this.$parent.groupInfo.id = "";
      this.$parent.groupInfo.name = "";
    },
  },
};
</script>

<style >
.enrollInvite ul {
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 5px;
}

.enrollInvite ul li {
  border: 3px solid #f8f9fa !important;
}

.enrollInvite .tab-content {
  border: none !important;
}
</style>
