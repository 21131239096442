<template>
  <div>
    <div class="surveyApprovalListing">
      <div class="loading" v-if="fetchData">
        <load-data></load-data>
      </div>
      <b-row v-else>
        <b-col md="12">
          <div class="createSurveyApproval" v-if="approvalForm">
            <SurveyApprovalCreation
              :surveyApprovalInfo="surveyApprovalInfo"
              @surveyApprovalCreated="surveyApprovalCreated"
            />
          </div>
          <div class="approvalListing p-3" v-else>
            <div
              class="p-2 d-flex justify-content-between"
              style="
                border: 2px solid #eeeeeb;
                border-left-style: none;
                border-right-style: none;
              "
            >
              <b-button
                v-if="SurveyApprovalAdmin"
                variant="info"
                class="text-white"
                size="sm"
                @click="approvalForm = true"
                ><i class="fas fa-plus-square text-white mr-1"></i>Add New Approval Form</b-button
              >
            </div>
            <br />
            <b-overlay
              :show="loadApproval"
              spinner-variant="primary"
              spinner-type="grow"
              spinner-small
              rounded="md"
            >
              <div class="table-responsive">
                <table class="table table-bordered table-striped">
                  <thead class="thead-dark text-left">
                    <tr>
                      <th>Name</th>
                      <th v-if="SurveyApprovalAdmin">Edit</th>
                      <th v-if="SurveyApprovalAdmin">Hidden</th>
                      <th>Start</th>
                      <th>End</th>
                      <th v-if="SurveyApprovalAdmin">Public URL</th>
                      <th>Academic Year</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="surveyApprovals.length == 0">
                      <td colspan="100" class="vertical-align text-danger text-center">
                        No Approvals Found!
                      </td>
                    </tr>
                    <tr
                      v-else
                      v-for="(approval, approvalIndex) in surveyApprovals"
                      :key="approvalIndex"
                    >
                      <td class="align-middle text-center">
                        <span
                          :id="'approvalSummary' + approvalIndex"
                          >{{ approval.name }}</span
                        >
                        <br />
                        <div class="d-flex justify-content-between">
                          <b-button
                            variant="primary"
                            size="sm"
                            @click="goTo('ApprovalQues', approval.id)"
                            >Create Form</b-button
                          >
                          <b-button
                            variant="primary"
                            size="sm"
                            @click="$bvModal.show('manageApprovers' + approvalIndex)"
                            v-if="SurveyApprovalAdmin"
                            >Manage Approvers</b-button
                          >
                          <b-modal
                            :id="'manageApprovers' + approvalIndex"
                            :title="'Add/Remove Approver: ' + approval.name"
                            no-close-on-backdrop
                            no-close-on-keyboard
                            no-close-on-esc
                            hide-footer
                            size="lg"
                          >
                            <Approvers :registrationId="approval.id" />
                          </b-modal>
                        </div>
                        <b-button
                          variant="primary"
                          size="sm"
                          @click="goTo('ApprovalAns', approval.id)"
                          v-if="SurveyApprovalStudent"
                          >Submit Form</b-button
                        >
                        <b-tooltip
                          :target="'approvalSummary' + approvalIndex"
                          triggers="hover"
                          v-if="approval.summary != null"
                        >
                          {{ approval.summary }}
                        </b-tooltip>
                      </td>
                      <td class="align-middle text-center" v-if="SurveyApprovalAdmin">
                        <span
                          class="text-info btn m-0 p-0"
                          @click="getSurveyApproval(approval.id)"
                          ><i class="far fa-edit"></i
                        ></span>
                      </td>
                      <td class="align-middle text-center" v-if="SurveyApprovalAdmin">
                        <span
                          v-if="!approval.hidden"
                          @click="
                            hideApproval(approval.id, {
                              name: approval.name,
                              hidden: true,
                            })
                          "
                          class="btn p-0 m-0 text-info"
                          ><i class="far fa-eye"></i
                        ></span>
                        <span
                          @click="
                            hideApproval(approval.id, {
                              name: approval.name,
                              hidden: false,
                            })
                          "
                          class="btn p-0 m-0 text-info"
                          v-else
                          ><i class="fas fa-eye-slash"></i
                        ></span>
                      </td>

                      <td class="align-middle">
                        {{ approval.start_at.format("DD/MM/YYYY") }} <br />
                        {{ approval.start_at.format("hh:mm a") }}
                      </td>
                      <td class="align-middle">
                        {{ approval.end_at.format("DD/MM/YYYY") }} <br />
                        {{ approval.end_at.format("hh:mm a") }}
                      </td>

                      <td v-if="SurveyApprovalAdmin" class="align-middle">
                        <span
                          v-if="approval.public_id != null"
                          class="text-info text-center btn p-0 m-0"
                          :id="'url' + approvalIndex"
                        >
                          <i class="fas fa-link"></i>
                        </span>
                        <span class="text-danger" v-if="approval.public_id == null"
                          >No Link!</span
                        >

                        <b-popover
                          :target="'url' + approvalIndex"
                          triggers="hover"
                          placement="top"
                          v-if="approval.public_id != null"
                        >
                          <template #title>Click the link to copy</template>
                          <span
                            class="text-info btn p-0 m-0"
                            :ref="'url' + approvalIndex"
                            @click="copyLink(approvalIndex)"
                            >{{ publicEventUrl }}pf/{{ approval.public_id }}</span
                          >
                        </b-popover>
                      </td>
                      <td class="align-middle text-center">
                        {{ approval.academic_year }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-overlay>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import SurveyApprovalCreation from "./SurveyApprovalCreation.vue";
import Approvers from "./approvers/Approvers.vue";

export default {
  props: ["SurveyApprovalAdmin", "SurveyApprovalStudent"],
  async created() {
    this.publicEventUrl = process.env.VUE_APP_BACE_URL;
    await this.getSurveyApprovals();
  },
  components: {
    SurveyApprovalCreation,
    Approvers,
  },
  data() {
    return {
      publicEventUrl: "",
      fetchData: false,
      loadApproval: false,
      approvalForm: false,
      surveyApprovals: [],
      surveyApprovalInfo: {},
    };
  },
  methods: {
    async copyLink(index) {
      var elementSelect = this.$refs["url" + index];
      let Url = await elementSelect[0].innerHTML;
      this.$clipboard(Url);
      this.$toast.info("Copied to clipboard", {
        position: "top-right",
        duration: 3000,
      });
    },
    async getSurveyApprovals() {
      this.fetchData = true;
      const url = this.$store.getters.getAPIKey.surveyApprovals;
      await this.$axios
        .get(url)
        .then((response) => {
          this.surveyApprovals = [];
          const approvals = response.data;
          approvals.forEach((approval) => {
            this.surveyApprovals.push({
              id: approval.id,
              name: approval.name,
              summary: approval.summary,
              academic_year: approval.academic_year,
              start_at: moment(
                moment.utc(approval.start_at, "YYYY-MM-DD HH:mm:ss").toDate()
              ),
              end_at: moment(moment.utc(approval.end_at, "YYYY-MM-DD HH:mm:ss").toDate()),
              grouping_tag: approval.grouping_tag,
              comment_feedback: approval.comment_feedback,
              hidden: approval.hidden == 1 ? true : false,
              participants_only: approval.participants_only,
              is_anonymous: approval.is_anonymous,
              public_id: approval.public_id,
            });
          });

          this.fetchData = false;
          response;
        })
        .catch((error) => {
          this.fetchData = false;
          error;
        });
    },
    getSurveyApproval(surveyApprovalId) {
      this.loadApproval = true;
      const url = this.$store.getters.getAPIKey.surveyApprovals + `/${surveyApprovalId}`;
      this.$axios
        .get(url)
        .then((response) => {
          this.surveyApprovalInfo = {
            id: response.data.id,
            name: response.data.name,
            summary: response.data.summary,
            academic_year: response.data.academic_year,
            start_at: new Date(
              moment(
                moment.utc(response.data.start_at, "YYYY-MM-DD HH:mm:ss").toDate()
              ).format()
            ),
            end_at: new Date(
              moment(
                moment.utc(response.data.end_at, "YYYY-MM-DD HH:mm:ss").toDate()
              ).format()
            ),
            grouping_tag: response.data.grouping_tag,
            comment_feedback: response.data.comment_feedback == 1 ? true : false,
            hidden: response.data.hidden == 1 ? true : false,
            participants_only: response.data.participants_only == 1 ? true : false,
            is_anonymous: response.data.is_anonymous == 1 ? true : false,
            multiple_application: response.data.multiple_application == 1 ? true : false,
            finish_message: response.data.finish_message
              ? response.data.finish_message
              : "",
            final_approval_alert: response.data.final_approval_alert == 1 ? true : false,
          };
          this.approvalForm = true;
          this.loadApproval = false;
          response;
        })
        .catch((error) => {
          this.loadApproval = false;
          error;
        });
    },
    surveyApprovalCreated() {
      this.approvalForm = false;
      this.surveyApprovalInfo = {};
      this.getSurveyApprovals();
    },
    async hideApproval(approvalId, hideStatus) {
      this.loadApproval = true;
      const url = this.$store.getters.getAPIKey.surveyApprovals + `/${approvalId}`;
      await this.$axios
        .put(url, hideStatus)
        .then((response) => {
          const msg = hideStatus.hidden ? "Event Hidden" : "Event Visible";
          this.$toast.success(msg, {
            position: "top",
            duration: 3000,
          });
          this.getSurveyApprovals();
          this.loadApproval = false;
          response;
        })
        .catch((error) => {
          this.loadApproval = false;
          error;
        });
    },
    goTo(pathName, approvalId) {
      if (pathName == "ApprovalQues") {
        let routeData = this.$router.resolve({
          name: pathName,
          params: {
            approval_id: approvalId,
          },
        });
        window.open(routeData.href, "_blank");
      } else {
        this.$router.push({
          name: pathName,
          params: {
            approval_id: approvalId,
          },
        });
      }
    },
  },
};
</script>

<style >
.surveyApprovalListing .modal-title {
  font-size: 20px !important;
  font-weight: 900 !important;
}

.approvalListing button i {
  font-size: 10px !important;
}
</style>
