<template>
  <div>
    <div class="studentApplications">
      <div class="createApplication mb-2">
        <b-button
          variant="primary"
          v-if="
            studentApplications.multipleApplications ||
            studentApplications.applications.length == 0
          "
          size="sm"
          @click="goToApplication(null, 1)"
          >New Application</b-button
        >
      </div>
      <div class="table-responsive">
        <table class="table table-bordered table-striped">
          <thead class="thead-dark">
            <tr>
              <th>Applications</th>
              <th>Application Date</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(application, applicationIndex) in studentApplications.applications"
              :key="applicationIndex"
            >
              <td>{{ applicationIndex + 1 }}</td>
              <td>
                {{
                  application.created_at != null
                    ? convertTimeToLocal(application.created_at).format(
                        "DD/MM/YYYY hh:mm a"
                      )
                    : ""
                }}
              </td>
              <td>
                <p class="text-success" v-if="application.end_at != null">Submitted</p>
                <b-button
                  v-else
                  size="sm"
                  variant="primary"
                  @click="goToApplication(application.id, application.end_at)"
                >
                  Continue
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: ["studentApplications"],
  methods: {
    convertTimeToLocal(theTime) {
      let localTime = moment(moment.utc(theTime, "YYYY-MM-DD HH:mm:ss").toDate());
      return localTime;
    },
    goToApplication(applicationId, endAt) {
      if (endAt == null) {
        this.$router.push({
          name: this.studentApplications.pathName,
          params: {
            approval_id: btoa(this.studentApplications.approvalId),
            application_id: btoa(applicationId),
          },
        });
      } else {
        this.$router.push({
          name: this.studentApplications.pathName,
          params: {
            approval_id: btoa(this.studentApplications.approvalId),
            application_id: btoa(null),
          },
        });
      }
    },
  },
};
</script>
