<template>
  <div>
    <b-modal
      id="editParticipantsDetails"
      hide-footer
      hide-header
      no-close-on-backdrop
      no-close-on-keyboard
      no-close-on-esc
      size="xl"
      centered
    >
      <b-overlay
        :show="loadStudents"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="md"
      >
        <div class="loading" v-if="fetchData">
          <load-data></load-data>
        </div>
        <b-row v-else>
          <b-col md="12">
            <div class="enrollFacultyList">
              <multiselect
                v-model="selectedParticipants"
                tag-placeholder="Add this as new tag"
                placeholder="Add Participants Here..."
                label="name"
                track-by="code"
                :options="participantsOptions"
                :multiple="true"
                :taggable="true"
                @tag="addParticipant"
              ></multiselect>
            </div>

            <hr />
            <br />
            <div class="submitParticipants d-flex justify-content-between">
              <b-button
                variant="primary"
                @click="prepareSyncParticipantsPostData()"
                size="md"
                :disabled="
                  syncStudentBtn.disabled || selectedParticipants.length == 0
                "
                >{{ syncStudentBtn.text }}</b-button
              >

              <b-button
                variant="danger"
                :disabled="syncStudentBtn.disabled"
                size="md"
                @click="cancelSyncingParticipants()"
                >Cancel</b-button
              >
            </div>
          </b-col>
        </b-row>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: ["subgroupForStudents", "surveyId", "courseId"],
  async created() {
    await this.subgroups;
    await this.subgroupsStudentList();
  },
  data() {
    return {
      fetchData: false,
      loadStudents: false,
      subgroupStudents: [],
      subgroupsStudentsOptions: this.subgroups,
      selectedSubgroupsStudents: [],
      syncStudentBtn: {
        text: "SUBMIT",
        disabled: false,
      },
      participants: [],
      participantsOptions: [],
      selectedParticipants: [],
      existingParticipants: [],
    };
  },
  methods: {
    addParticipant(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
        id: newTag,
      };
      this.participantsOptions.push(tag);
      this.selectedParticipants.push(tag);
    },
    async checkParticipants() {
      await this.$bvModal.show("editParticipantsDetails");
      await this.listOfStudentsWhoCanParticipate();
      await this.listOfStudentsWhoCanParticipateOptions();
    },
    async listOfStudentsWhoCanParticipate() {
      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/course/${this.courseId}/students`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.participantsOptions = [];
          this.existingParticipants = [];
          const participants = response.data[0];
          this.existingParticipants = response.data[0];

          participants.forEach((student) => {
            this.selectedParticipants.push({
              name: student.first_name + " " + (student.last_name || ""),
              id: student.pivot.institution_user_id,
              code: student.id,
            });
          });
          this.fetchData = false;
          response;
        })
        .catch((error) => {
          this.$toast.error("Something went wrong!", {
            position: "top",
            duration: 3000,
          });
          this.cancelSyncingParticipants();
          error;
        });
    },
    //********** Students available for add to participants  **********/
    async listOfStudentsWhoCanParticipateOptions() {
      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.studentFeedbackSurvey +
        `/${this.surveyId}/participants/options`;
      await this.$axios
        .get(url)
        .then((response) => {
          const courses = response.data;
          const users = {};
          courses.forEach((course) => {
            course.institution_users.forEach((participant) => {
              users[participant.id] = {};
              users[participant.id] = {
                name:
                  participant.first_name + " " + (participant.last_name || ""),
                id: participant.id,
              };
            });
          });

          for (const participant in users) {
            this.participantsOptions.push({
              name: users[participant].name,
              id: users[participant].id,
              code: users[participant].id,
            });
          }

          this.fetchData = false;
          response;
        })
        .catch((error) => {
          this.$toast.error("Something went wrong!", {
            position: "top",
            duration: 3000,
          });
          this.cancelSyncingParticipants();
          error;
        });
    },
    async prepareSyncParticipantsPostData() {
      //Variable defined for API
      const studfbsurvey_students = [];
      await this.selectedParticipants.forEach((student) => {
        studfbsurvey_students.push(student.id);
      });

      this.syncStudentBtn.text = "Please Wait ...";
      this.syncStudentBtn.disabld = true;

      //********** Calling Sync Students API **********/
      const syncStudents = await this.syncParticipantsWithFeedbackSurvey(
        studfbsurvey_students
      );

      //***************API RESPONSE ****************
      //***************SUCCESS RESPONSE ****************
      if (syncStudents) {
        this.syncStudentBtn.text = await "Participants Linked.";
        await this.$toast.success(`Linking Participants successfully done.`, {
          position: "top",
          duration: 3000,
        });
        await this.cancelSyncingParticipants();
      }
      //***************Error RESPONSE ****************
      if (!syncStudents) {
        this.syncStudentBtn.text = "Submit";
        this.$toast.error(`Linking Participants failed, Please try again`, {
          position: "top",
          duration: 3000,
        });
      }
    },
    async syncParticipantsWithFeedbackSurvey(studfbsurvey_students) {
      let syncUrl = this.$store.getters.getAPIKey
        .studentFeedbackSurveySyncParticipants;
      const url = syncUrl.replace("survey_id", this.surveyId);
      let status = null;
      await this.$axios
        .post(url, { studfbsurvey_students: studfbsurvey_students })
        .then((response) => {
          status = true;
          response;
        })
        .catch((error) => {
          status = false;
          error;
        });
      return status;
    },
    cancelSyncingParticipants() {
      this.$bvModal.hide("editParticipantsDetails");
      this.participantsOptions = [];
      this.selectedParticipants = [];
      this.existingParticipants = [];
      this.syncStudentBtn.text = "Submit";
      this.syncStudentBtn.disabled = false;
    },

    subgroupsStudentList() {
      this.subgroupsStudentsOptions = [];
      this.subgroupStudents = [];
      this.selectedSubgroupsStudents = [];
      this.subgroupsStudentsOptions = this.subgroupForStudents;
      this.subgroupsStudentsOptions.forEach((subgroup) => {
        subgroup.disabled = false;
      });
    },
    async getSubgroupStudents(subgroupId) {
      this.loadStudents = true;
      const subgroupStudentId = await this.selectedSubgroupsStudents.findIndex(
        (subgroup) => {
          return subgroup == subgroupId;
        }
      );
      const subgroupUsersAdded = await this.subgroupsStudentsOptions.findIndex(
        (subgroup) => {
          return subgroup.value == subgroupId;
        }
      );
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usersubgroup/${subgroupId}/users`;
      await this.$axios.get(url).then((response) => {
        const subgroupUsers = response.data;
        if (subgroupUsers.length == 0) {
          this.$toast.warning("No users found!", {
            position: "top",
            duration: 3000,
          });
          this.selectedSubgroupsStudents.splice(subgroupStudentId, 1);
        } else {
          this.subgroupsStudentsOptions[subgroupUsersAdded].disabled = true;
          const checkStudentsExist = [];
          subgroupUsers.forEach((user) => {
            if (user.pivot.usersubgrouprole_id == 2) {
              this.subgroupStudents.push({
                id: user.pivot.institution_user_id,
                name: `${user.first_name} ${user.last_name}`,
              });
              checkStudentsExist.push(user.id);
            }
          });
          if (checkStudentsExist.length == 0) {
            this.$toast.warning("No users found!", {
              position: "top",
              duration: 3000,
            });
            this.subgroupsStudentsOptions[subgroupUsersAdded].disabled = false;
            this.selectedSubgroupsStudents.splice(subgroupStudentId, 1);
          }
        }
        response;
      });
      this.loadStudents = false;
    },
  },
};
</script>
