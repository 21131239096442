<template>
  <div class="container">
    <!--  <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>-->
    <div>
      <h3 style="text-align: center; font-size: 18px !important">
        Participants
      </h3>
      <div class="text-center">
        <span>
          <b-button class="mb-2" variant="primary" size="sm" @click="addInternalParticipants()" pill>
            Add Internal Participants</b-button>
        </span>
        <span>
          <b-button class="mb-2" variant="primary" size="sm" @click="addExternalParticipants()" pill>Add External
            Participants</b-button>
        </span>
      </div>
      <b-modal id="ManageExternalParticipants" no-close-on-backdrop no-close-on-keyboard no-close-on-esc hide-footer
        centered size="xl" lazy>
        <ExternalParticipants lazy :publicexam="publicexam" :hideModel="hideModel" />
      </b-modal>
      <b-modal id="ManageInternalParticipants" no-close-on-backdrop no-close-on-keyboard no-close-on-esc hide-footer
        centered size="lg" lazy>
        <div>
          <div class="addStudents">
            <b-form @submit.prevent="createStudents">
              <div>
                <div class="row">
                  <div class="col-sm-6 col-md-4 col-lg-3" v-for="(option, index) in subgroupOptions" :key="index">
                    <b-form-checkbox v-model="fromSubgroupsIds" :value="option.value"
                      @change="updateFromSubgroupsStudentsOptions(option.value)">{{ option.text }}
                    </b-form-checkbox>
                  </div>
                  <br />
                </div>
                <table v-if="fromSubgroupsIds.length" class="table table-striped table-bordered">
                  <thead>
                    <tr style="background-color: #a8a8a8">
                      <th v-for="(subgroupId, subgindex) in fromSubgroupsIds" :key="subgindex">
                        {{
                          subgroupOptions.find((obj) => obj.value == subgroupId)
                            .text
                        }}
                      </th>
                    </tr>
                  </thead>
                  <tbody style="font-weight: 300">
                    <tr>
                      <td v-for="(subgroupId, subgindex) in fromSubgroupsIds" :key="subgindex">
                        <b-link v-if="subgroupsStudentsOptions[subgroupId].length > 1"
                          @click="selectAllStudentsOn(subgroupId)">Select All</b-link>
                      </td>
                    </tr>
                    <tr>
                      <td v-for="(subgroupId, subgindex) in fromSubgroupsIds" :key="subgindex">
                        <span v-for="(stud, studindex) in subgroupsStudentsOptions[
                          subgroupId
                        ]" :key="studindex">
                          <b-form-checkbox v-if="stud.role==2" v-model="fromSubgroupsSelectedStudentIds" :value="stud.id" size="md">
                            {{ stud.name }}
                          </b-form-checkbox>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <br />
              <b-form-group>
                <div class="d-flex justify-content-between flex-wrap">
                  <b-button variant="primary" size="sm" type="submit">{{
                    addAdminBtn.text
                  }}</b-button>
                  <b-button variant="danger" @click="hideModel()" size="sm"
                    :disabled="addAdminBtn.disabled">Cancel</b-button>
                </div>
              </b-form-group>
            </b-form>
          </div>
        </div>
      </b-modal>
      <b-row>
        <b-col sm="12">
          <table class="table table-striped table-bordered">
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Type</th>
              <th></th>
            </tr>
            <tr v-for="(student, sKey) in students" :key="sKey">
              <td>{{ student.name }}</td>
              <td>{{ student.email }}</td>
              <td>
                <span v-if="student.institution_user_id"> Internal </span>
                <span v-if="!student.institution_user_id"> External </span>
              </td>
              <td>
                <i style="font-size: 12px !important" class="fas fa-trash-alt text-danger text-sm btn p-0 mx-1"
                  @click="removeParticipant(student.id, sKey)"></i>
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import ExternalParticipants from "./manageExternalParticipants.vue";
export default {
  props: ["publicexam"],
  async created() {
    await this.getAcademicYearSubgroups();
    await Promise.all([this.getPublicUsers(), this.getParticipants()]);
  },
  components: {
    ExternalParticipants,
  },
  data() {
    return {
      loadData: false,
      students: [],
      participantIds: [],
      addStudent: false,
      courseStudentsList: [],
      fetchData: false,
      subgroupOptions: [],
      fromSubgroupsIds: [],
      subgroupsStudentsOptions: {},
      fromSubgroupsSelectedStudentIds: [],
      usersubgroup_id: null,
      interDepartmentStudentsEnroll: true,
      addparticipant: false,
      addAdminBtn: {
        text: "Submit",
        disabled: false,
      },
    };
  },
  methods: {
    async removeParticipant(paricipantId, index) {
      if (confirm("Are you sure you want to delete participant?")) {
        var url =
          this.$store.getters.getAPIKey.mainAPI +
          `/publicexamparticipants/${paricipantId}`;
        await this.$axios
          .delete(url)
          .then(() => {
            this.students.splice(index, 1);
          })
          .catch((error) => {
            error;
          });
        this.$forceUpdate();
      }
    },
    async getParticipants() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/publicexamparticipants?public_exam_id=${this.publicexam.id}`;
      await axios
        .get(url)
        .then((response) => {
          this.students = response.data;
        })
        .catch(() => {
          this.$toast.error("Failed to load", {
            position: "top",
            duration: 3000,
          });
        });
    },
    async getPublicUsers() { },
    addInternalParticipants() {
      this.$bvModal.show("ManageInternalParticipants");
    },
    addExternalParticipants() {
      this.$bvModal.show("ManageExternalParticipants");
    },
    async createStudents() {
      let url =
        this.$store.getters.getAPIKey.mainAPI +
        `/publicexamparticipants`;

       
      // const students = {
      //   institution_users: this.fromSubgroupsSelectedStudentIds,
      // };
      //let institution_users=
      await this.selectedStudents.forEach((student) => {
        this.fromSubgroupsSelectedStudentIds.push(student.id);
      });
      await this.$axios
      .post(url, { public_exam_id: this.publicexam.id, students: this.fromSubgroupsSelectedStudentIds })
        .then((response) => {
          if(response.data){
            this.$toast.success(`Students added to ${this.publicexam.name}`, {
            position: "top",
            duration: 3000,
          });
          this.$emit("studentStatus", true);
          }
         
          // console.log(response);
        })
        .catch(() => {
          // console.log(error);
        });
      this.hideModel();
    },
    hideModel() {
      this.$bvModal.hide("ManageInternalParticipants");
      this.$bvModal.hide("ManageExternalParticipants");
      this.fromSubgroupsIds = [];
      this.fromSubgroupsSelectedStudentIds=[];
      this.getParticipants();
    },
    updateFromSubgroupsStudentsOptions(subgroupId) {
      if (this.fromSubgroupsIds.includes(subgroupId)) {
        this.usersubgroup_id = subgroupId;
        this.getSubgroupUsers();
      }
    },
    async getSubgroupUsers() {
      console.log(this.usersubgroup_id);
      var url =
        this.$store.getters.getAPIKey.mainAPI +
       
     //   /publicexams/usersubgroup/${this.usersubgroup_id}/users
        `/usersubgroup/${this.usersubgroup_id}/users`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.selectedStudents = [];
          this.StudentOptions = [];
          let students = response.data;
          this.subgroupsStudentsOptions[this.usersubgroup_id] = [];
          students.forEach((staff) => {
            if (!this.interDepartmentStudentsEnroll) {
              this.StudentOptions.push({
                name:
                  staff.first_name +
                  " " +
                  (staff.last_name || "") +
                  `( ${staff.email} - ${staff.register_no ?? ""})`,
                id: staff.pivot.institution_user_id,
                code: staff.id,
              });
            } else {
              this.subgroupsStudentsOptions[this.usersubgroup_id].push({
                name:
                  (staff.roll_number ?? "") +
                  " " +
                  staff.first_name +
                  " " +
                  (staff.last_name || ""),
                id: staff.pivot.institution_user_id,
                role: staff.pivot.usersubgrouprole_id,
              });
            }
          });
          this.$forceUpdate();
        })
        .catch(() => {
        });
      this.fetchData = false;
    },
    selectAllStudentsOn(subgroupId) {
      this.subgroupsStudentsOptions[subgroupId].forEach((stud) => {
        setTimeout(() => {
          this.fromSubgroupsSelectedStudentIds.push(stud.id);
        }, 100);
      });
    },
    cancelEditQuestion() {
      this.$bvModal.hide("editQuestionModel");
    },
    async getAcademicYearSubgroups() {
      let url =
        this.$store.getters.getAPIKey.mainAPI +
        `/institutionuser/usersubgroups?with_admins=1&with_students_count=1`;
      await this.$axios.get(url).then((response) => {
        this.subgroupOptions = [];
        response.data.forEach((subgroup) => {
          this.subgroupOptions.push({
            text: subgroup.name + "-" + subgroup.usergroup.code,
            value: subgroup.id,
          });
        });
      });
    },
  },
};
</script>

<style>
.tag-button button {
  font-size: 9px !important;
  padding: 3px;
}
</style>
