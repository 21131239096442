<template>
  <div>
    <div class="enrollUserToInstitute">
      <div class="enrollForm">
        <b-form @submit.prevent="enrollUser" class="d-flex align-items-center border-0">
          <b-input-group>
            <template #append>
              <!-- <b-input-group-text> -->
              <b-button variant="primary" type="submit" :disabled="enrollBtn.disabled">{{
                enrollBtn.text
              }}</b-button>
              <!-- </b-input-group-text> -->
            </template>
            <b-input
              placeholder="Enrollment/Invitation Code"
              v-model="enrollmentCode.invitation_code"
              :class="{ 'is-invalid': responseErrors.invitation_code }"
              required
            ></b-input>

            <span class="text-danger" v-if="responseErrors.invitation_code">{{
              responseErrors.invitation_code[0]
            }}</span>
          </b-input-group>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      enroll: false,
      enrollmentCode: {},
      responseErrors: {},
      enrollBtn: {
        text: "Go",
        disabled: false,
      },
    };
  },
  methods: {
    async enrollUser() {
      this.enrollBtn.text = "Please wait...";
      this.enrollBtn.disabled = true;
      const url = this.$store.getters.getAPIKey.acceptInvitation;
      await this.$axios
        .post(url, this.enrollmentCode)
        .then((response) => {
          this.enrollBtn.text = "Go";
          this.$toast.success(response.data.message, {
            position: "top",
            duration: 3000,
          });
          setTimeout(() => {
            window.location.reload();
            this.enrollBtn.disabled = false;
            this.enrolled = false;
          }, 3000);
        })
        .catch((error) => {
          this.responseErrors = error.response.data.errors;
          this.enrollBtn.text = "Go";
          setTimeout(() => {
            this.enrollBtn.disabled = false;
            this.responseErrors = {};
          }, 3000);
        });
    },
  },
};
</script>
