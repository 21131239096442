<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="courseStudents" v-else>
      <b-overlay :show="loadData" spinner-variant="primary" spinner-type="grow" spinner-small rounded="md">
        <b-button class="mb-2" variant="primary" size="sm" @click="addStudent = !addStudent"
          v-if="!addStudent && studentsToDelete.length == 0" pill>Add</b-button>
        <div class="addCourseStudents" :courseStudentModal="courseStudentModal" v-if="addStudent">
          <AddStudents :courseStudentModal="courseStudentModal" @addStudentStatus="addStudentStatus"
            :courseStudentsList="courseStudentsList" />
        </div>

        <div v-else>
          <div class="submitBtn mb-2 d-flex justify-content-between" v-if="studentsToDelete.length != 0">
            <b-button variant="danger" size="sm" :disabled="submitBtn.disabled" @click="prepareStudentsDeletion()">{{
                submitBtn.text
            }}</b-button>
          </div>
          <div class="mb-2">
            <span>
              Student should firstly added to the class before being able to enroll them to courses
            </span>
          </div>
          <table class="table table-striped table-bordered">
            <thead class="thead-dark text-left">
              <tr>
                <th></th>
                <th>Roll Number</th>
                <th>Name</th>
                <th v-if="viewStudentSubgroups">{{subgroupCustomName}}</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Guardian Mobile.</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(student, studentIndex) in courseStudentsList" :key="studentIndex">
                <td class="align-middle">
                  <b-form-checkbox v-model="studentsToDelete" :value="student.id">
                  </b-form-checkbox>
                </td>
                <td class="align-middle">
                  {{ !student.roll_number ? "RN" : student.roll_number }}
                </td>
                <td>
                  {{ student.first_name || " " }} {{ student.last_name || " " }}
                </td>
                <td v-if="viewStudentSubgroups">{{ student.usersubgroup ? student.usersubgroup : '' }}</td>
                <td class="align-middle">{{ student.email }}</td>
                <td class="align-middle">{{ student.mobile }}</td>
                <td class="align-middle">{{ student.guardian_mobile }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import AddStudents from "./AddStudents.vue";
export default {
  props: ["courseStudentModal"],
  async created() {
    this.subgroupCustomName = this.$store.getters.getCustomNamings.userSubgroup.custom_name ?? "Usersubgroup";
    this.fetchData = true;
    await this.getCourseStudents();
    this.fetchData = false;
  },
  components: {
    AddStudents,
  },
  data() {
    return {
      subgroupCustomName:'',
      fetchData: false,
      loadData: false,
      viewStudentSubgroups: false,
      courseStudentsList: [],
      studentsToDelete: [],
      addStudent: false,
      submitBtn: {
        text: "Delete",
        diabled: false,
      },
    };
  },
  methods: {
    async prepareStudentsDeletion() {
      if (
        confirm(
          `WARNING: You are going to 'remove' user/users from a course, this action will 'delete' the user data in this course (eg: attendance, course grouping, activity answer, survey responses, university exam scores, lab evaluation scores) and all these actions are not reversible`
        ) == false
      ) return
      this.loadData = await true;
      this.submitBtn = await {
        text: "Please wait...",
        diabled: true,
      };

      for (let user in this.studentsToDelete) {
        let userUrl = this.$store.getters.getAPIKey.deleteCourseUser;
        let url = userUrl.replace(
          "course_user_id",
          this.studentsToDelete[user]
        );
        await this.$axios
          .delete(url)
          .then(() => {
            if (user == this.studentsToDelete.length - 1) {
              this.$toast.success("Users Deleted!", {
                position: "top",
                duration: 3000,
              });

              this.$emit("studentsRemoved", true);
            }
          })
          .catch(() => {
            this.$toast.error("Something went wrong, Please try again!", {
              position: "top",
              duration: 3000,
            });
          });
      }
      await this.getCourseStudents();
      this.loadData = false;
      this.submitBtn = {
        text: "Delete",
        diabled: false,
      };
    },
    async getCourseStudents() {
      this.fetchData = true;
      this.studentsToDelete = [];
      let mainUrl = this.$store.getters.getAPIKey.createStudent;
      const url = mainUrl.replace("course_id", this.courseStudentModal.id);
      await this.$axios
        .get(url)
        .then((response) => {
          this.courseStudentsList = [];
          this.courseStudentsList = response.data[0];
          this.courseStudentsList.forEach(stud => {
            if (stud.usersubgroup != null) this.viewStudentSubgroups = true;
          });
          // this.courseStudentsList.sort(function(a, b) {
          //   return (
          //     a.roll_number - b.roll_number ||
          //     a.first_name.localeCompare(b.first_name)
          //   );
          // });
          response;
          this.fetchData = false;
        })
        .catch(() => {
          // console.log(error.response);
          this.fetchData = false;
        });
    },
    addStudentStatus(status) {
      if (status) {
        this.addStudent = !this.addStudent;
        this.$emit("newStudentAdded");
      } else {
        this.addStudent = !this.addStudent;
      }
    },
  },
};
</script>
