<template>
    <b-form @submit.prevent="preparePublicExamPostDataForApi()">
        <b-form-group class="">
            <label class="required-label" for="">Select Course:</label>
            <b-form-select :options="coursesOptions" v-model="exam.course_id" :disabled="courseDisabled">
            </b-form-select>
            <span class="input-error-message"> This will be an online exam</span>
        </b-form-group>
        <b-form-group class="">
            <label class="required-label" for="">Activity Name :</label>
            <b-form-input placeholder="Enter Activity Name..."
                :class="[{ 'form-group-error': $v.publicexam.name.$error }, 'required']"
                v-model="publicexam.name"></b-form-input>
            <span class="input-error-message" v-if="!$v.publicexam.name.required">Activity Name is required!</span>
        </b-form-group>
        <b-form-group label="Description:">
            <b-form-textarea placeholder="Enter topic..." rows="3" max-rows="6" v-model="publicexam.summary">
            </b-form-textarea>
        </b-form-group>
        <b-form-group>
            <label class="required-label"> Select Date and Time</label>
            <b-form-datepicker placeholder="Select Activity Date" v-model="publicexam.date"></b-form-datepicker>
            <br />
            <div class="time d-flex mt-2">
                <div>
                    <b-form-timepicker v-model="publicexam.examStart" locale="en"
                        placeholder="Activity Start Time"></b-form-timepicker>
                </div>
                <div class="ml-3">
                    <b-form-timepicker v-model="publicexam.examEnd" locale="en"
                        placeholder="Activity End Time"></b-form-timepicker>
                </div>
            </div>
        </b-form-group>
        <span class="input-error-message" v-if="!$v.publicexam.date.required">Activity Date is required!</span>
        <!-- common options  -->
        <b-form-group label="Make Activity Hidden for Students:">
            <b-form-checkbox switch size="md" v-model="publicexam.hidden"></b-form-checkbox>
        </b-form-group>
        <!-- ------------------------ -->
        <b-form-group>
            <div class="w-100 d-flex justify-content-between">
                <b-button variant="primary" size="md" type="submit" :disabled="publicexamBtn.disabled">{{ publicexamBtn.text
                }}
                </b-button>
                <b-button variant="danger" size="md" @click="cancel()" :disabled="publicexamBtn.disabled">Cancel</b-button>
            </div>
        </b-form-group>
    </b-form>
</template>
<script>
import {
    required,
    requiredIf
} from "vuelidate/lib/validators";
import moment from "moment";
export default {
    props: ["exam", "publicexamToBeUpdated"],
    components: {},
    async created() {
        this.fetchData = true;
        this.publicexam = this.publicexamToBeUpdated;

        this.hoursOptions = [{
            value: null,
            text: "Select Hour",
            disabled: true,
        },];
        for (let hour = 0; hour <= 12; hour++) {
            this.hoursOptions.push({
                value: hour < 10 ? "0" + hour : hour,
                text: hour < 10 ? "0" + hour : hour,
            });
        }
        this.minutesOptions = [{
            value: null,
            text: "Select Minute",
            disabled: true,
        },];
        for (let min = 0; min <= 60; min++) {
            this.minutesOptions.push({
                value: min < 10 ? "0" + min : min,
                text: min < 10 ? "0" + min : min,
            });
        }
        if (this.publicexam.id >0) {
            this.courseDisabled = true;
            await this.publicexam.starts_at;
            let theDate = await moment(
                moment.utc(this.publicexam.starts_at, "YYYY-MM-DD HH:mm:ss").toDate()
            );
            moment.utc(this.publicexam.starts_at).local();
            this.publicexam.date = new Date(theDate.format("YYYY/MM/DD"));
            this.publicexam.examStart = moment(
                moment.utc(this.publicexam.starts_at, "YYYY-MM-DD HH:mm:ss").toDate()
            ).format("HH:mm");
            this.publicexam.examEnd = moment(
                moment.utc(this.publicexam.end_at, "YYYY-MM-DD HH:mm:ss").toDate()
            ).format("HH:mm");

        }
        await this.getCourses();
        this.$forceUpdate();
    },
    data() {
        return {
            fetchData: false,
            loadExams: false,
            courseDisabled: false,
            differentScores: false,
            publicexam: {
                id: "",
                name: "",
                summary: "",
                date: "",
                start_at: "",
                end_at: "",
                course_id: "",
                hidden: false,
            },
            maxTimeAllowedToCompleteExam: {
                status: false,
                hours: "",
                minutes: "",
            },
            publicexamBtn: {
                text: "Submit",
                disabled: false,
            },
            coursesOptions: [],
            selectedCourse: null,
            hoursOptions: [],
            minutesOptions: [],
            selectedHour: null,
            selectedMinute: null,
        };
    },
    validations: {
        date: {
            required: requiredIf(function () {
                if (
                    this.exam.activity_type != "tutorial" &&
                    !this.exam.offline_activity
                ) {
                    return true;
                }
            }),
        },
        examStart: {
            required: requiredIf(function () {
                if (
                    this.exam.activity_type == "assessment" &&
                    !this.exam.offline_activity
                ) {
                    return true;
                }
            }),
        },
        examEnd: {
            required: requiredIf(function () {
                if (
                    this.exam.activity_type == "assessment" &&
                    !this.exam.offline_activity
                ) {
                    return true;
                }
            }),
        },
        publicexam: {
            name: {
                required,
            },
            date: {
                required,
            },
            examStart: {
                required,
            },
            examEnd: {
                required,
            },
        },
    },
    methods: {
        //********* Prepare Exam Post Data For API **********/
        numberValidation(e) {
            if (isNaN(e)) {
                e.preventDefault();
            }
        },
        async preparePublicExamPostDataForApi() {
            this.publicexamBtn.text = "Please wait...";
            this.publicexamBtn.disabled = true;
            this.loadExams = true;
            let startT =
                moment(this.publicexam.date).format("YYYY/MM/DD") + " " + this.publicexam.examStart;
            this.publicexam.starts_at = await moment(startT, "YYYY-MM-DD HH:mm:ss")
                .utc()
                .format();
            let endT =
                moment(this.publicexam.date).format("YYYY/MM/DD") + " " + this.publicexam.examEnd;
            this.publicexam.end_at = await moment(endT, "YYYY-MM-DD HH:mm:ss")
                .utc()
                .format();
            if (this.publicexam.id) {
                await this.updatePublicexam();
            } else {
                await this.createPublicexam();
            }
            this.clearResponse(true);
        },
        //********* Create Public Exam with API **********/
        async createPublicexam() {
            let url = this.$store.getters.getAPIKey.mainAPI + `/publicexams`;
            let status = null;
            await this.$axios
                .post(url, this.publicexam)
                .then((response) => {
                    status = true;
                    response;
                    this.publicexam = [];
                    this.$toast.success("Activity Successfully created", {
                        position: "top",
                        duration: 3000,
                    });
                })
                .catch((error) => {
                    let errortxt = "Failed to Create";
                    if (error.response && error.response.status == 422) {
                        let errormsgs = error.response.data.errors;
                        for (const key in errormsgs) {
                            errortxt = errormsgs[key][0];
                        }
                    } else {
                        errortxt = error.response.data.message;
                    }
                    this.$toast.error(errortxt, {
                        position: "top",
                        duration: 3000,
                    });
                    status = false;
                });
            return status;
        },
        //********* Update Public Exam with API **********/
        async updatePublicexam() {
            let url = this.$store.getters.getAPIKey.mainAPI + `/publicexams/${this.publicexam.id}`;
            let status = null;
            await this.$axios
                .put(url, this.publicexam)
                .then((response) => {
                    status = true;
                    response;
                    this.publicexam = [];
                    this.$toast.success("Activity Successfully updated", {
                        position: "top",
                        duration: 3000,
                    });
                })
                .catch((error) => {
                    let errortxt = "Failed to Create";
                    if (error.response && error.response.status == 422) {
                        let errormsgs = error.response.data.errors;
                        for (const key in errormsgs) {
                            errortxt = errormsgs[key][0];
                        }
                    } else {
                        errortxt = error.response.data.message;
                    }
                    this.$toast.error(errortxt, {
                        position: "top",
                        duration: 3000,
                    });
                    status = false;
                });
            return status;
        },
        //********* Reset Or Clear Response **********/
        cancel() {
            this.$emit("examFormCancelled");
        },
        clearResponse(status) {
            this.publicexamBtn.text = "Submit";
            this.publicexam = [];
            this.loadExams = false;
            setTimeout(() => {
                if (status) {
                    this.cancel();
                }
                this.publicexamBtn.disabled = false;
            }, 3000);
        },
        async getCourses() {
            this.loadCourses = true;
            const url =
                this.$store.getters.getAPIKey.mainAPI +
                `/institutionuser/courses/withusers`;
            await this.$axios
                .get(url)
                .then((response) => {
                    this.coursesOptions = [];
                    let courses = response.data;
                    this.coursesOptions = [{
                        value: null,
                        text: courses.length != 0 ? "Select Course" : "No Courses Found!",
                        disabled: true,
                    },];
                    courses.forEach((course) => {
                        this.coursesOptions.push({
                            value: `${course.id}`,
                            text: course.name +
                                " (" +
                                course.usersubgroup.usergroup.name +
                                " - " +
                                course.usersubgroup.name +
                                ")",
                        });
                    });
                    this.coursesOptions[courses.length]["value"] = "0";
                    this.coursesOptions[courses.length]["text"] = "Not linked to course";
                    response;
                })
                .catch((error) => {
                    error;
                });
            this.fetchData = await false;
        },
    },
};
</script>
