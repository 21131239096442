<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <b-row v-else>
      <b-col md="12">
        <h6 class="w-100 text-info btn">List of Users Enrolled</h6>
        <div id="listOfUsersEnrolled">
          <h6>{{ "Enrolled users for " + groupInfo.name }}</h6>

          <subgroup-users v-if="groupInfo.type == 'Subgroup'" :groupInfo="groupInfo"></subgroup-users>
          <div v-else class="p-3">
            <b-overlay :show="loadData" spinner-variant="primary" spinner-type="grow" spinner-small rounded="md">
              <div class="export text-right mb-2">
                <b-button variant="success" size="sm" :disabled="
                  exportCsvBtn.disabled || listOfUsersEnrolled.length == 0
                " @click="exportToExcel('xlsx')">{{ exportCsvBtn.text }}</b-button>
              </div>
              <table class="table table-striped table-bordered" ref="groupUsers">
                <thead class="thead-dark">
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="listOfUsersEnrolled.length == 0">
                    <td colspan="100" class="text-center text-danger">
                      No Users enrolled Yet!
                    </td>
                  </tr>
                  <tr v-else v-for="(user, index) in listOfUsersEnrolled" :key="index">
                    <td :class="{ 'align-middle': true, 'text-danger': user.enroll_status != 'active' }">
                      {{ user.first_name || " " }} {{ user.last_name || " " }}
                    </td>
                    <td class="align-middle">{{ user.email }}</td>
                    <td class="align-middle">{{ user.mobile }}</td>
                    <td class="align-middle">
                      <span class="text-danger btn p-0 m-0" @click="deleteEnrolledUser(user.id, index)">
                        <i class="fas fa-trash"></i></span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-overlay>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from "axios";
import SubgroupUsers from "./subgroup_users_edit/SubgroupUsers.vue";
import XLSX from "xlsx";
export default {
  props: ["groupInfo"],
  components: {
    subgroupUsers: SubgroupUsers,
  },
  data() {
    return {
      fetchData: false,
      loadData: false,
      listOfUsersEnrolled: [],
      mainArray: [],
      exportCsvBtn: {
        text: "Export to Excel",
        disabled: false,
      },
    };
  },
  methods: {
    async exportToExcel(type, fn, dl) {
      this.exportCsvBtn.text = "Please wait...";
      this.exportCsvBtn.disabled = true;
      var elt = this.$refs.groupUsers;
      var wb = await XLSX.utils.table_to_book(elt, { sheet: "Sheet JS" });
      this.exportCsvBtn.text = "Export to Excel";
      this.exportCsvBtn.disabled = false;
      return dl
        ? XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" })
        : XLSX.writeFile(
          wb,
          fn || this.groupInfo.name + "_users." + (type || "xlsx")
        );
    },
    showRollNumberField(userId) {
      this.deleteUserBtn[userId].addRollNumber = true;
      this.$forceUpdate();
    },
    hideRollNumberField(userId) {
      this.deleteUserBtn[userId].rollNumber = "";
      this.deleteUserBtn[userId].addRollNumber = false;
      this.$forceUpdate();
    },
    updateRollNumber(userId, institutionUserId) {
      this.addRollNumberBtn[userId].text = "Updating...";
      this.deleteUser.disabled = true;
      this.$forceUpdate();
      let groupUrl = this.$store.getters.getAPIKey.enrollSubGroupUser;
      const url = groupUrl.replace("usersubgroup_id", this.groupInfo.id);
      const studentData = {
        institution_users_roll_number: [],
      };
      studentData.institution_users_roll_number.push({
        institution_user_id: institutionUserId,
        roll_number: this.deleteUserBtn[userId].rollNumber,
      });

      axios
        .post(url, studentData)
        .then((response) => {
          this.addRollNumberBtn[userId].text = "";
          this.deleteUser.disabled = false;
          this.hideRollNumberField(userId);
          this.rollNumberAdded = true;
          this.getEnrolledSubgroupUsers();
          this.$forceUpdate();
          setTimeout(() => {
            this.rollNumberAdded = false;
          }, 3000);
          response;
        })
        .catch((error) => {
          error;
          this.addRollNumberBtn[userId].text = "";
          this.deleteUser.disabled = false;
          this.$forceUpdate();
        });
    },
    async getEnrolledSubgroupUsers() {
      let userList = this.$store.getters.getAPIKey.getEnrollSubgroupUsersList;
      const url = userList.replace("usersubgroup_id", this.groupInfo.id);

      await axios
        .get(url)
        .then((response) => {
          this.listOfUsersEnrolled = [];
          this.listOfUsersEnrolled = response.data;
          this.listOfUsersEnrolled = this.listOfUsersEnrolled.filter(
            (students) => {
              return students.pivot.usersubgrouprole_id == 2;
            }
          );
          this.listOfUsersEnrolled.forEach((user) => {
            this.deleteUserBtn[user.id] = {
              text: "",
              disabled: false,
              rollNumber: "",
              addRollNumber: false,
            };
            this.addRollNumberBtn[user.id] = {
              text: "",
              disabled: false,
            };
          });
          this.sortedArray();

          this.$forceUpdate();

          response;
        })
        .catch((error) => {
          error;
        });
    },
    sortedArray() {
      // this.listOfUsersEnrolled.sort(function(a, b) {
      //   return (
      //     a.roll_number - b.roll_number ||
      //     a.first_name.localeCompare(b.first_name)
      //   );
      // });
    },
    async getEnrolledGroupUsers() {
      let userList = this.$store.getters.getAPIKey.getEnrollUsersList;
      const url = userList.replace("usergroup_id", this.groupInfo.id);

      await axios
        .get(url)
        .then((response) => {
          this.listOfUsersEnrolled = [];
          this.listOfUsersEnrolled = response.data;

          response;
        })
        .catch((error) => {
          error;
        });
    },
    async deleteEnrolledUser(user_id, index) {
      this.loadData = true;
      let url = "";

      let userId = this.$store.getters.getAPIKey.deleteGroupUser;
      url = userId.replace("usergroup_user_id", user_id);

      axios
        .delete(url)
        .then((response) => {
          this.listOfUsersEnrolled.splice(index, 1);
          this.loadData = false;
          response;
        })
        .catch((error) => {
          this.loadData = false;
          error;
        });
    },
  },
};
</script>
