var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.fetchData)?_c('div',{staticClass:"loading"},[_c('load-data')],1):_c('div',{staticClass:"submitForms"},[_c('div',{staticClass:"approvalListing p-3"},[_c('b-modal',{attrs:{"id":"applicationStatus","no-close-on-backdrop":"","no-close-on-keyboard":"","no-close-on-esc":"","hide-footer":"","size":"lg","modal-class":"applicationStatus","title":_vm.eventName}},[_c('ApplicationStatus',{attrs:{"eventId":_vm.eventId,"applications":_vm.applications,"eventName":_vm.eventName}})],1),_c('b-overlay',{attrs:{"show":_vm.loadApproval,"spinner-variant":"primary","spinner-type":"grow","spinner-small":"","rounded":"md"}},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-bordered table-striped"},[_c('thead',{staticClass:"thead-dark"},[_c('tr',[_c('th',[_vm._v("Name")]),_c('th',[_vm._v("Start")]),_c('th',[_vm._v("End")]),_c('th',[_vm._v("Status")]),_c('th',[_vm._v("Academic Year")])])]),_c('tbody',[(_vm.surveyApprovals.length == 0)?_c('tr',[_c('td',{staticClass:"vertical-align text-danger text-center",attrs:{"colspan":"100"}},[_vm._v(" No Forms Found! ")])]):_vm._l((_vm.surveyApprovals),function(approval,approvalIndex){return _c('tr',{key:approvalIndex},[_c('td',{staticClass:"align-middle text-center"},[_c('span',{class:approval.summary != null ? 'text-info' : 'text-dark',attrs:{"id":'approvalSummary' + approvalIndex}},[_vm._v(_vm._s(approval.name))]),_c('br'),_c('b-modal',{attrs:{"id":'studentApplications' + approvalIndex,"no-close-on-backdrop":"","no-close-on-keyboard":"","no-close-on-esc":"","hide-footer":"","size":"lg","title":_vm.studentApplications.approvalName
                        ? _vm.studentApplications.approvalName
                        : ''}},[_c('StudentApplications',{attrs:{"studentApplications":_vm.studentApplications}})],1),_c('b-button',{attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.goTo(
                        approval.name,
                        'ApprovalAns',
                        approval.id,
                        approval.multiple_application,
                        approval.application,
                        approvalIndex
                      )}}},[_vm._v("Submit Form")]),(approval.summary != null)?_c('b-tooltip',{attrs:{"target":'approvalSummary' + approvalIndex,"triggers":"hover"}},[_vm._v(" "+_vm._s(approval.summary)+" ")]):_vm._e()],1),_c('td',{staticClass:"align-middle"},[_vm._v(" "+_vm._s(approval.start_at.format("DD/MM/YYYY"))+" "),_c('br'),_vm._v(" "+_vm._s(approval.start_at.format("hh:mm a"))+" ")]),_c('td',{staticClass:"align-middle"},[_vm._v(" "+_vm._s(approval.end_at.format("DD/MM/YYYY"))+" "),_c('br'),_vm._v(" "+_vm._s(approval.end_at.format("hh:mm a"))+" ")]),_c('td',{staticClass:"align-middle"},[(approval.application.length != 0)?_c('b-button',{attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.getApplicationStatus(
                        approval.id,
                        approval.name,
                        approval.application
                      )}}},[_vm._v("Check Status")]):_c('p',{staticClass:"text-danger",staticStyle:{"font-size":"12px","font-weight":"600"}},[_vm._v(" Application not submitted! ")])],1),_c('td',{staticClass:"align-middle"},[_vm._v(" "+_vm._s(approval.academic_year)+" ")])])})],2)])])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }