<template>
  <div>
    <div class="addCustomReport">
      <b-overlay :show="loadCustom" spinner-variant="primary" spinner-type="grow" spinner-small rounded="md">
        <b-form @submit.prevent="prepareCustomReport">
          <b-form-textarea id="IsoLogo" class="mt-2" rows="3" max-rows="6" v-if="report.template" style="display: none"
            v-model="templatesObject[report.template].variable_base64Encoded_logo.value"></b-form-textarea>

          <b-form-group>
            <b-form-select :options="reportsOptions" v-model="report.report"
              @change="report.template != null ? getVariables() : ''"></b-form-select>
          </b-form-group>
          <b-form-group>
            <b-form-select :options="templatesOptions" v-model="report.template"
              @change="report.report != null ? getVariables() : ''"></b-form-select>
          </b-form-group>
          <b-form-group v-if="report.template && report.template != null">
            <div class="vars d-flex justify-content-between align-items-center mb-2"
              v-for="(variable, variableIndex, index) in templatesObject[report.template]" :key="index">
              <div class="ques font-weight-bolder">{{ variableIndex }}</div>
              <div>
                <b-form-textarea v-if="templatesObject[report.template][variableIndex].attributes != 'image'"
                  :disabled="templatesObject[report.template][variableIndex].attributes == 'autofill'"
                  v-model="templatesObject[report.template][variableIndex].value" size="sm" rows="1" max-row="4">
                </b-form-textarea>

                <div v-else>
                  <b-popover v-if="
                    templatesObject[report.template][variableIndex].attributes ==
                    'image'
                  " target="currentLogo" triggers="hover" placement="top">
                    <img :src="templatesObject[report.template][variableIndex].value" class="img-fluid" alt="" />
                  </b-popover>
                  <b-button id="currentLogo" variant="info" size="sm" @click="changeImage = !changeImage" v-if="
                    templatesObject[report.template][variableIndex].value != null &&
                    !changeImage
                  ">Change Logo</b-button>

                  <b-form-file v-else v-model="fileImg" placeholder="Choose PNG, JPG"
                    accept="image/jpeg, image/png, image/gif" @change="checkIsoLogo"></b-form-file>
                </div>
              </div>
            </div>
          </b-form-group>

          <b-form-group>
            <div class="d-flex justify-content-between">
              <b-button variant="primary" type="submit" size="sm" :disabled="
                saveBtn.disabled || report.report == null || report.template == null
              ">{{ saveBtn.text }}</b-button>
              <b-button variant="danger" size="sm" :disabled="saveBtn.disabled"
                @click="$emit('cancelCustomReport', false)">Cancel</b-button>
            </div>
          </b-form-group>
        </b-form>
      </b-overlay>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.$set(this.report, "report", null);
    this.$set(this.report, "template", null);
  },
  props: ["reportsOptions", "templatesOptions", "templatesObject"],
  data() {
    return {
      report: {},
      variables: {},
      loadCustom: false,
      saveBtn: {
        text: "Submit",
        duration: 3000,
      },
      changeImage: false,
      fileImg: null,
      test: "",
      newTemplate:false,//if new template we can refresh the existing list
    };
  },
  methods: {
    async checkIsoLogo($event) {
      let isoImageConverter = document.getElementById("IsoLogo");
      const file = $event.target.files[0];
      if (file.size > 100000) {
        return this.$toast.error("Please select an image under 100KB", {
          position: "top",
          duration: 3000,
        });
      }
      var reader = new FileReader();
      reader.onloadend = await function () {
        isoImageConverter.value = reader.result;
      };

      await reader.readAsDataURL(file);
      isoImageConverter.focus();
    },

    async getVariables() {
      this.loadCustom = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/customreports/report/${this.report.report}/template/${this.report.template}`;
      await this.$axios
        .get(url)
        .then((res) => {
          this.variables = res.data.variables;

          if (res.data.variables) {
            for (const tempVal in this.templatesObject[this.report.template]) {
              // console.log(this.variables[tempVal]);
              // if (Object.keys(this.templatesObject[this.report.template])) {
              //   this.templatesObject[this.report.template][tempVal].value =
              //     res.data.variables[tempVal].value;
              // } else {
              if (this.variables[tempVal]) {
                this.templatesObject[this.report.template][
                  tempVal
                ].value = this.variables[tempVal];
              } else {
                this.templatesObject[this.report.template][tempVal].value = null;
              }
              // }
            }
          } else {
            this.newTemplate = true;
            for (let tempVal in this.templatesObject[this.report.template]) {
              this.templatesObject[this.report.template][tempVal].value = null;
            }
          }

          res;
        })
        .catch((err) => {
          err;
        });
      this.loadCustom = false;
    },
    async prepareCustomReport() {
      if (
        this.templatesObject[this.report.template] &&
        this.templatesObject[this.report.template].variable_base64Encoded_logo &&
        this.fileImg != null
      ) {
        this.templatesObject[
          this.report.template
        ].variable_base64Encoded_logo.value = await document.getElementById("IsoLogo")
          .value;
      }

      this.saveBtn = {
        text: "Saving...",
        disabled: true,
      };

      const theVariables = {};
      for (let v in this.templatesObject[this.report.template]) {
        this.$set(theVariables, v, this.templatesObject[this.report.template][v].value);
      }

      let postData = {
        report: this.report.report,
        template: this.report.template,
        variables: theVariables,
      };

      let saveCustomReport = await this.saveCustomReport(postData);
      if (saveCustomReport) {
        this.$toast.success("Successfully created", {
          position: "top",
          duration: 3000,
        });
        this.$emit("cancelCustomReport", this.newTemplate);
      }
      if (!saveCustomReport) {
        this.$toast.error("Something went wrong, please try again!", {
          position: "top",
          duration: 3000,
        });
      }
      this.saveBtn = {
        text: "Submit",
        disabled: false,
      };
    },
    async saveCustomReport(postData) {
      let status = null;
      const url = this.$store.getters.getAPIKey.mainAPI + `/customreports`;
      await this.$axios
        .post(url, postData)
        .then((response) => {
          status = true;

          response;
        })
        .catch((error) => {
          status = false;
          error;
        });
      return status;
    },
  },
};
</script>

<style >
.addCustomReport .variables div {
  margin-bottom: 10px;
}

.addCustomReport .variables span {
  font-size: 14px;
  font-weight: 600;
}

.addCustomReport .variables input {
  max-width: 200px;
  height: 30px;
}
</style>
