<template>
  <div>
    <b-skeleton-table v-if="fetchData" :rows="3" :columns="2" :table-props="{ bordered: true, striped: true }">
    </b-skeleton-table>
    <b-container v-if="readydata" class="mt-2">
      <b-row style="text-align:center;">
        <b-col md="12">
          <b-row align-v="center">
            <b-col class="">
              <button type="button" class="btn btn-outline-info btn-sm" @click="refreshAttendanceReport(-1)">
                <i class="fas fa-angle-left"></i>
              </button>
            </b-col>
            <b-col class="text-center px-0">
              <h5 class="mb-4" style="font-size: 1.5rem !important;">
                Student Attendance Preview as on <b-link style="font-size: 1.5rem !important;"
                  @click="openDateSubgroupsDateSelectionModel">
                  {{ `${this.attendanceSummaryDate.getDate()}/${this.attendanceSummaryDate.getMonth() +
                      1}/${this.attendanceSummaryDate.getFullYear()}`
                  }}</b-link>
              </h5>
              <b-modal id="dateSubgroupsDateSelectionModel" hide-footer hide-header size="sm">
                <b-form-datepicker no-close-on-select ref="datAttReportDatePicker" @input="handleDateSelection" v-model="datePickerValue"
                  :value-as-date="true"></b-form-datepicker>
              </b-modal>

              <!-- <div style="display: none;">
                <b-form-datepicker id="example-datepicker" v-model="attendanceSummaryDate"
                  class="mb-2"></b-form-datepicker>
              </div> -->
            </b-col>
            <b-col class=" ">
              <button type="button" class="btn btn-outline-info btn-sm" @click="refreshAttendanceReport(1)">
                <i class="fas fa-angle-right"></i>
              </button>
            </b-col>
          </b-row>

          <!-- <b-row>
            <div><i class="fas fa-angle-left"></i></div>
            <h5 class="mb-4" style="font-size: 1.5rem !important;">
              {{
                  attendanceSummaryDate
                    ? `Student Attendance Preview as on
                            ${this.attendanceSummaryDate.getDate()}/${this.attendanceSummaryDate.getMonth() +
                    1}/${this.attendanceSummaryDate.getFullYear()}`
                    : "Today's Student Attendance Preview"
              }}
            </h5>
            <div><i class="fas fa-angle-right"></i></div>
          </b-row> -->
          <b-row class="my-1">
            <b-col><b-button class="text-right" variant="info" size="sm" @click="downloadUnmarkedFaculties()">Download
                Defaulters List</b-button>
              <b-button class="m-1 ml-2 text-right" variant="info" size="sm"
                @click="$bvModal.show('subgroupsHolidaysModel');"> <i style="font-size: 14px !important;" class="fas fa-plus-square text-white text-sm mr-1"></i>
                Add Holiday</b-button>
              <b-button class="m-1 ml-2 text-right" variant="info" size="sm"
                @click="$bvModal.show('subgroupsSuspendsModel');"> <i style="font-size: 14px !important;" class="fas fa-plus-square text-white text-sm mr-1"></i>
                Suspend Class</b-button>
            </b-col>
            <b-col>
              <b-row>
                <div style="border:none;margin-bottom: 1px;"> <span class="px-3 holiday-row  mr-1 border-dark"
                    style="border: 2px solid;"></span>Holiday.
                </div>
                <div class="ml-2" style="border:none;margin-bottom: 1px;"> <span
                    class="px-3 suspended-cell  mr-1 border-dark" style="border: 2px solid;"></span>Class Suspended.
                </div>
              </b-row>
            </b-col>
            <b-col>
              <span class="text-info" v-if="(attendanceAlertMail === null)">Loading attendance alert settings</span>
              <div v-else>
                <b-form-checkbox @change="setUserAttendanceAlertPreferenceSetting" v-model="attendanceAlertMail"
                  name="checkbox-1">
                  Send me daily attendance report by mail (Mail will be automatically triggered at 6:00 pm)
                </b-form-checkbox>
              </div>
            </b-col>
          </b-row>
          <table class="table-bordered table table-striped">
            <thead class="">
              <tr>
                <th rowspan="2">Class/Sessions</th>
                <th v-for="(session, sessionIndex) in sessions" :key="sessionIndex">
                  {{ session.name }}
                </th>
              </tr>
            </thead>
            <tbody v-for="(usersubgroups, usersubgroupsIndex) in usergroupSubgroups" :key="usersubgroupsIndex">
              <tr>
                <td :colspan="10" style="background-color: silver;font-size: 1.2rem !important;">
                  {{ usersubgroups[0].usergroup.name }}-{{
                      usersubgroups[0].usergroup.code
                  }}
                </td>
              </tr>
              <tr v-for="(usersubgroup, usersubgroupIndex) in usersubgroups" :key="usersubgroupIndex"
                :class="{ 'holiday-row': subgroups_holidays[usersubgroup.id] }"
                v-b-popover.hover.top="(subgroups_holidays[usersubgroup.id] != null ? 'Holiday: ' + subgroups_holidays[usersubgroup.id] : '')
                + (subgroups_suspends[usersubgroup.id] != null ? ' Class Suspended: ' + subgroups_suspends[usersubgroup.id].name : '')">
                <td>
                  {{ usersubgroup.name }} ({{ usersubgroup.usergroup.code }})
                </td>

                <td v-for="(session, sessionIndex) in sessions" :key="sessionIndex"
                  :class="{ 'suspended-cell': subgroups_suspends[usersubgroup.id] != null ? (subgroups_suspends[usersubgroup.id].session_id.includes(session.id)) : false }">
                  <div :style="
                    subgroupSessionAtts[usersubgroup.id][session.id].present +
                      subgroupSessionAtts[usersubgroup.id][session.id]
                        .absent <
                      1
                      ? 'color: rgb(114, 0, 0);'
                      : ''
                  ">
                    <span style="color: rgb(114, 0, 0);" v-if="(subgroupSessionSchedules[
                      usersubgroup.id
                    ][session.id].length < 1 && subgroups_holidays[usersubgroup.id] == null)">
                      Timetable missing <br /></span>
                    <span v-for="(schedule, uIndex) in subgroupSessionSchedules[
                      usersubgroup.id
                    ][session.id]" :key="uIndex">
                      <b> {{ schedule.user }}-{{ schedule.course }} </b>, <br />
                    </span>

                    <span v-if="
                      subgroupSessionAtts[usersubgroup.id][session.id]
                        .present +
                      subgroupSessionAtts[usersubgroup.id][session.id]
                        .absent >
                      0
                    ">
                      {{
                          subgroupSessionAtts[usersubgroup.id][session.id]
                            .present
                      }}/
                      {{
                          subgroupSessionAtts[usersubgroup.id][session.id]
                            .present +
                          subgroupSessionAtts[usersubgroup.id][session.id]
                            .absent
                      }}
                      <span v-if="subgroupSessionAtts[usersubgroup.id][session.id].marked_bys.length">
                        <br />
                        Marked by:
                        <span v-for="(marked_by, uIndex) in subgroupSessionAtts[
                          usersubgroup.id
                        ][session.id].marked_bys" :key="uIndex">
                          {{ marked_by.user }}-{{ marked_by.course }} <br />
                        </span>
                      </span>
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </b-container>

    <b-modal id="subgroupsHolidaysModel" no-close-on-backdrop no-close-on-keyboard no-close-on-esc hide-footer centered
      size="lg">
      <div class="container">
        <UsersubgroupHolydays />
      </div>
    </b-modal>
    <b-modal id="subgroupsSuspendsModel" no-close-on-backdrop no-close-on-keyboard no-close-on-esc hide-footer centered
      size="lg">
      <div class="container">
        <usersubgroupSuspendclass />
      </div>
    </b-modal>
  </div>
</template>

<script>
import UsersubgroupHolydays from "../../../../usersubgroupHolydays/dashboard.vue";
import usersubgroupSuspendclass from "../../../../usersubgroupSuspendclass/dashboard.vue";
export default {
  props: ["date"],
  async created() {
    this.attendanceSummaryDate = this.date;
    if (!this.attendanceSummaryDate) this.attendanceSummaryDate = new Date();
    this.datePickerValue = this.attendanceSummaryDate;
    this.getMonitoringData();
    this.getUserAttendanceAlertPreferenceSetting();
  },
  components: { UsersubgroupHolydays,usersubgroupSuspendclass, },
  data() {
    return {
      readydata: false,
      fetchData: true,
      subgroupSessionSchedules: {},
      subgroupSessionAtts: {},
      sessions: [],
      usergroupSubgroups: {},
      attendanceSummaryDate: null,
      attendanceAlertMail: null,
      subgroups_holidays: {},
      subgroups_suspends: {},
      datePickerValue: null,
      // showDatepickerModal: false,
    };
  },
  methods: {
    async openDateSubgroupsDateSelectionModel(){
      await this.$bvModal.show('dateSubgroupsDateSelectionModel'); 
      // console.log(this.$refs.datAttReportDatePicker);
      // this.$refs.datAttReportDatePicker.click();
    },
    handleDateSelection() {
      this.attendanceSummaryDate = new Date(this.datePickerValue);
      this.refreshAttendanceReport(null);
    },
    refreshAttendanceReport(day) {
      this.readydata = false;
      this.fetchData = true;
      this.subgroupSessionSchedules = {};
      this.subgroupSessionAtts = {};
      this.sessions = [];
      this.usergroupSubgroups = {};
      this.subgroups_holidays = {};
      this.subgroups_suspends = {};
      if (day !== null)
        this.attendanceSummaryDate.setDate(
          this.attendanceSummaryDate.getDate() + day
        );
      this.getMonitoringData();
    },
    async downloadUnmarkedFaculties() {
      this.loadEvaluation = true;
      let postData = { today: true };
      if (this.attendanceSummaryDate)
        postData = {
          date: `${this.attendanceSummaryDate.getFullYear()}/${this.attendanceSummaryDate.getMonth() +
            1}/${this.attendanceSummaryDate.getDate()}`,
        };
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/attendancereports/usersubgroups/unmarkedfaculties/pdfexport`;
      await this.$axios
        .post(
          url, postData,
          { responseType: "blob" }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "defaulters.pdf");
          document.body.appendChild(link);
          link.click();
          link.remove();
          this.downloadData = false;
        })
        .catch(() => {
          this.$toast.error('Failed to Download, please try again', {
            position: "top",
            duration: 3000,
          });
        });

      this.loadEvaluation = false;
    },
    async getMonitoringData() {
      let postData = { today: true };
      if (this.attendanceSummaryDate)
        postData = {
          date: `${this.attendanceSummaryDate.getFullYear()}/${this.attendanceSummaryDate.getMonth() +
            1}/${this.attendanceSummaryDate.getDate()}`,
        };
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/attendancereports/usersubgroups/sessions/monitoring`;
      await this.$axios
        .post(url, postData)
        .then((response) => {
          this.subgroupSessionSchedules =
            response.data.subgroupSessionSchedules;
          this.subgroupSessionAtts = response.data.subgroupSessionAtts;
          this.sessions = response.data.sessions;
          let usersubgroups = response.data.usersubgroups;
          // let usergroupSubgroups = {};
          usersubgroups.forEach((usersubgroup) => {
            this.usergroupSubgroups[usersubgroup.usergroup.id] = [];
          });
          usersubgroups.forEach((usersubgroup) => {
            this.usergroupSubgroups[usersubgroup.usergroup.id].push(
              usersubgroup
            );
          });
          // this.fetchData = false;
          // for (var usgkey in this.usersubgroups) {
          //   for (var sskey in this.sessions) {
          //     this.subgroupsSessionsTableIndex.push({
          //       subgroup: usgkey,
          //       session: sskey,
          //     });
          //   }
          //   this.subgroupsSessionsTableIndex.push({
          //     subgroup: usgkey,
          //     session: null,
          //   });
          // }
          this.subgroups_holidays = response.data.subgroups_holidays;
          this.subgroups_suspends = response.data.subgroups_suspends;
          this.fetchData = false;
          this.readydata = true;
        })
        .catch((error) => {
          this.fetchData = false;
          error;
        });
    },


    getUserAttendanceAlertPreferenceSetting() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/institutionuser/setting/daily_subgroups_attendance_report_mail`;
      this.$axios
        .get(url)
        .then((response) => {
          this.attendanceAlertMail = response.data ? true : false;
        });
    },
    async setUserAttendanceAlertPreferenceSetting() {
      let temp = this.attendanceAlertMail;
      this.attendanceAlertMail = null;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/institutionuser/setting/daily_subgroups_attendance_report_mail`;
      await this.$axios
        .post(url, { daily_subgroups_attendance_report_mail: temp })
        .then((response) => {
          this.attendanceAlertMail = response.data.daily_subgroups_attendance_report_mail ? true : false;
        });
    },
  },
};
</script>

<style >
.holiday-row {
  background-color: rgb(207, 207, 104) !important;
}

.suspended-cell {
  background-color: rgb(255, 162, 162) !important;
}
</style>
