<template>
  <div>
    <div class="textBook">
      <br>

      <span style="color:green" v-if="saveReport">Updating......</span>
      <br>
      <table class="table  table-striped table-bordered">
        <tr>
          <th>Sl No</th>
          <th>Report Name</th>
          <th></th>
        </tr>
        <tr>
          <td colspan="3" style="text-align:center"><b>Course Reports</b></td>
        </tr>
        <tr v-for="(courseReport, cindex) in courseReportOptions" :key="cindex">
          <td>{{ cindex + 1 }}</td>
          <td>{{ (courseReport.report_name.replace(/_/g, ' ')).toUpperCase() }}</td>
          <td>
            <b-form-checkbox
              @change=updateCourseInchargeSignField(courseReport.report_name,courseReport.enable_course_in_charge_signature)
              :value="1" v-model="courseReport.enable_course_in_charge_signature">
              Enable Course In charge signature field
            </b-form-checkbox>
            <b-form-checkbox
              @change=updateAdvisorSignField(courseReport.report_name,courseReport.enable_advisor_signature) :value="1"
              v-model="courseReport.enable_advisor_signature">
              Enable Advisor signature field
            </b-form-checkbox>
            <b-form-checkbox @change=updateHodSignField(courseReport.report_name,courseReport.enable_hod_signature)
              :value="1" v-model="courseReport.enable_hod_signature">
              Enable HOD signature field
            </b-form-checkbox>
            <b-form-checkbox @change=updateDqacSignField(courseReport.report_name,courseReport.enable_dqac_signature)
              :value="1" v-model="courseReport.enable_dqac_signature">
              Enable DQAC signature field
            </b-form-checkbox>
            <b-form-checkbox
              @change=updatePrincipalSignField(courseReport.report_name,courseReport.enable_principal_signature)
              :value="1" v-model="courseReport.enable_principal_signature">
              Enable Principal signature field
            </b-form-checkbox>
          </td>
        </tr>
        <tr>
          <td colspan="3" style="text-align:center"><b>Class Reports</b></td>
        </tr>
        <tr v-for="(classReport, cindex) in classReportOptions" :key="cindex">
          <td>{{ courseReportOptions.length + cindex + 1 }}</td>
          <td>{{ (classReport.report_name.replace(/_/g, ' ')).toUpperCase() }}</td>
          <td>
            <b-form-checkbox
              @change=updateCourseInchargeSignField(classReport.report_name,classReport.enable_course_in_charge_signature)
              :value="1" v-model="classReport.enable_course_in_charge_signature">
              Enable Course In charge signature field
            </b-form-checkbox>
            <b-form-checkbox
              @change=updateAdvisorSignField(classReport.report_name,classReport.enable_advisor_signature) :value="1"
              v-model="classReport.enable_advisor_signature">
              Enable Advisor signature field
            </b-form-checkbox>
            <b-form-checkbox @change=updateHodSignField(classReport.report_name,classReport.enable_hod_signature)
              :value="1" v-model="classReport.enable_hod_signature">
              Enable HOD signature field
            </b-form-checkbox>
            <b-form-checkbox @change=updateDqacSignField(classReport.report_name,classReport.enable_dqac_signature)
              :value="1" v-model="classReport.enable_dqac_signature">
              Enable DQAC signature field
            </b-form-checkbox>
            <b-form-checkbox
              @change=updatePrincipalSignField(classReport.report_name,classReport.enable_principal_signature)
              :value="1" v-model="classReport.enable_principal_signature">
              Enable Principal signature field
            </b-form-checkbox>
          </td>
        </tr>
        <tr>
          <td colspan="3" style="text-align:center"><b>Survey Reports</b></td>
        </tr>
        <tr v-for="(surveyReport, cindex) in surveyReportOptions" :key="cindex">
          <td>{{ courseReportOptions.length + classReportOptions.length + cindex + 1 }}</td>
          <td>{{ (surveyReport.report_name.replace(/_/g, ' ')).toUpperCase() }}</td>
          <td>
            <b-form-checkbox
              @change=updateCourseInchargeSignField(surveyReport.report_name,surveyReport.enable_course_in_charge_signature)
              :value="1" v-model="surveyReport.enable_course_in_charge_signature">
              Enable Course In charge signature field
            </b-form-checkbox>
            <b-form-checkbox
              @change=updateAdvisorSignField(surveyReport.report_name,surveyReport.enable_advisor_signature) :value="1"
              v-model="surveyReport.enable_advisor_signature">
              Enable Advisor signature field
            </b-form-checkbox>
            <b-form-checkbox @change=updateHodSignField(surveyReport.report_name,surveyReport.enable_hod_signature)
              :value="1" v-model="surveyReport.enable_hod_signature">
              Enable HOD signature field
            </b-form-checkbox>
            <b-form-checkbox @change=updateDqacSignField(surveyReport.report_name,surveyReport.enable_dqac_signature)
              :value="1" v-model="surveyReport.enable_dqac_signature">
              Enable DQAC signature field
            </b-form-checkbox>
            <b-form-checkbox
              @change=updatePrincipalSignField(surveyReport.report_name,surveyReport.enable_principal_signature)
              :value="1" v-model="surveyReport.enable_principal_signature">
              Enable Principal signature field
            </b-form-checkbox>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
//import CopyCourseProperties from "./CopyCourseProperties.vue"
export default {
  props: ["usergroup_id"],
  async created() {
    await this.prepareReportDatas();
  },
  components: {
    // CopyCourseProperties,
  },
  data() {
    return {
      saveReport: false,
      edit: false,
      activity_name: '',
      copy_from: '',
      editbutton: 'Edit',
      courseReportOptions: [],
      classReportOptions: [],
      surveyReportOptions: []
    };
  },
  watch: {
  },
  methods: {
    async prepareReportDatas() {
      const url = this.$store.getters.getAPIKey.mainAPI + `/reportsettings?usergroup_id=${this.usergroup_id}`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.surveyReportOptions = response.data.surveyReports;
          this.classReportOptions = response.data.classReports;
          this.courseReportOptions = response.data.courseReports;
        })
        .catch((error) => {
          error;
        });
    },
    async updateCourseInchargeSignField(report_name, enable_course_in_charge_signature) {
      var signature_field = {
        field_name: 'enable_course_in_charge_signature',
        status: enable_course_in_charge_signature
      };
      this.updateReport(report_name, signature_field);
    },
    async updateAdvisorSignField(report_name, enable_advisor_signature) {
      var signature_field = {
        field_name: 'enable_advisor_signature',
        status: enable_advisor_signature
      };
      this.updateReport(report_name, signature_field);
    },
    async updateHodSignField(report_name, enable_hod_signature) {
      var signature_field = {
        field_name: 'enable_hod_signature',
        status: enable_hod_signature
      };
      this.updateReport(report_name, signature_field);
    },
    async updateDqacSignField(report_name, enable_dqac_signature) {
      var signature_field = {
        field_name: 'enable_dqac_signature',
        status: enable_dqac_signature
      };
      this.updateReport(report_name, signature_field);
    },
    async updatePrincipalSignField(report_name, enable_principal_signature) {
      var signature_field = {
        field_name : 'enable_principal_signature',
        status : enable_principal_signature
      };
      this.updateReport(report_name, signature_field);
    },
    async updateReport(report_name, signature_field) {
      this.saveReport = true;
      const url = this.$store.getters.getAPIKey.mainAPI + `/reportsettings`;
      await this.$axios
        .post(url, {
          usergroup_id: this.usergroup_id,
          report_name: report_name,
          signature_field: signature_field
        })
        .then((response) => {
          response;
          this.$toast.success("Updated", {
            position: "top",
            duration: 3000,
          });
          this.saveReport = false;
        })
        .catch((error) => {
          error;
        });
    }
  },

};
</script>

<style>
.textBook .ltp_credit {
  width: 25%;
  display: flex;
  justify-content: space-between;
}

.textBook .ltp_credit input {
  width: 150px;
  text-align: center;
}
</style>