<template>
  <div>
    <br /><br />
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="mappingTable" v-else>


      <div class="" style="border: 2px solid #17a2b8; padding: 15px; border-radius: 5px;">
        <ul>
          <!-- You have two options to copy course data: -->
          <!-- <li>
            Copy from University Resources <span style="color: #2929aa; text-decoration: underline; cursor: pointer;"
              @click="copy_from = 'template'; $bvModal.show('copyCoursePropertiesModel')">Click here to proceed</span>
          </li> -->
          <li>Copy from previous academic year <span
              style="color: #2929aa; text-decoration: underline; cursor: pointer;"
              @click="copy_from = 'myinstitute'; $bvModal.show('copyCoursePropertiesModel')">Click here to
              proceed</span>
          </li>
        </ul>
        <b-modal id="copyCoursePropertiesModel" no-close-on-backdrop no-close-on-keyboard no-close-on-esc hide-footer
          hide-header centered size="md">
          <CopyCourseProperties :copy_from_suggestion="copy_from" :course_id="courseId"
            @success="propertyCopyCompleted()" @cancel="$bvModal.hide('copyCoursePropertiesModel')" />
        </b-modal>
      </div>
      <div class="text-center m-2 text-primary"> OR </div>

      <div v-if=" editCourseComponents && $store.getters.defaultInstitute.userRole != 'Student'">
        <b-button class="mb-2" v-if="!editTable" size="sm" variant="primary" @click="editTable = true">Edit</b-button>
        <div v-else class="d-flex justify-content-between mb-2">
          <b-button variant="primary" @click="saveMappings()" size="sm" :disabled="saveChanges.disabled">{{
            saveChanges.text
            }}</b-button>
          <b-button size="sm" @click="cancel()" variant="danger">Cancel</b-button>
        </div>
      </div>
      <div class="mb-2" v-else>This data can only be edited by course instructor</div>

      <div class="d-flex">
        <div class="table-responsive" style="height: auto !important;">
          <table class="table table-bordered table-striped">
            <thead class="thead-dark text-left">
              <tr class="text-center">
                <th></th>
                <th style="min-width: 90px;" v-for="(pso, psoIndex) in enabledProgramSpecificOutcomes"
                  :key="psoIndex + 'psoHeader'">
                  {{ pso.code }}
                </th>
                <th style="min-width: 90px;" v-for="(po, poIndex) in enabledProgramOutcomes"
                  :key="poIndex + 'poHeader'">
                  {{ po.code }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(co, coIndex) in courseOutcomes" :key="coIndex + 'coIndex'">
                <td>{{ co.code }}</td>

                <td v-for="(pso, psoIndex) in enabledProgramSpecificOutcomes" :key="psoIndex + 'psoIndex'"
                  class="text-center align-middle">
                  <span v-if="!editTable" class="text-center">
                    <span v-if="coPsoMappingData[co.id][pso.id].level == 0 ||
                      coPsoMappingData[co.id][pso.id].level == 0.0
                      || coPsoMappingData[co.id][pso.id].level == 'NaN'">-</span>
                    <span v-else>
                      {{
                      coPsoMappingData[co.id][pso.id].level
                      }}
                    </span>
                  </span>
                  <div v-else>
                    <b-form-input v-model="coPsoMappingData[co.id][pso.id].level" style="text-align: center"
                      type="number" :max="highestMappingLevel" step=".1"></b-form-input>
                  </div>
                </td>

                <td v-for="(po, poIndex) in enabledProgramOutcomes" :key="poIndex + 'poIndex'"
                  class="text-center align-middle">
                  <span v-if="!editTable" class="text-center">
                    <span v-if="coPoMappingData[co.id][po.id].level == 0 ||
                      coPoMappingData[co.id][po.id].level == 0.0
                      || coPoMappingData[co.id][po.id].level == 'NaN'">-</span>
                    <span v-else>
                      {{
                      coPoMappingData[co.id][po.id].level
                      }}
                    </span>
                  </span>
                  <div v-else>
                    <b-form-input v-model="coPoMappingData[co.id][po.id].level" style="text-align: center" type="number"
                      :max="highestMappingLevel" step=".1"></b-form-input>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Avg</td>
                <td v-for="(pso, psoIndex) in enabledProgramSpecificOutcomes" :key="psoIndex + 'psoIndex'"
                  class="text-center align-middle">
                  {{ coPsoMappingDataAvg[pso.id]['avg'] ? coPsoMappingDataAvg[pso.id]['avg'].toFixed(1) : '-' }}
                </td>
                <td v-for="(po, poIndex) in enabledProgramOutcomes" :key="poIndex + 'poIndex'"
                  class="text-center align-middle">
                  {{ coPoMappingDataAvg[po.id]['avg'] ? coPoMappingDataAvg[po.id]['avg'].toFixed(1) : '-' }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="table-responsive" style="height: auto !important;">
        <table class="table table-bordered table-striped">
          <thead class="thead-dark text-left">
            <tr class="text-center">
              <th>COs</th>
              <th>PSOs</th>
              <th>POs</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="index in maximumCoPoPsoTableRows" :key="index">
              <td>
                <span v-if="courseOutcomes[index-1]">
                  {{ courseOutcomes[index-1].code }}-{{ courseOutcomes[index-1].outcome }}
                </span>
              </td>
              <td>
                <span v-if="programSpecificOutcomes[index-1]">
                  <b-form-checkbox :disabled="!editTable" v-model="enabledPsoIds" name="checkbox-psoOption"
                    :value="programSpecificOutcomes[index-1].id">
                    {{ programSpecificOutcomes[index-1].code }}-{{ programSpecificOutcomes[index-1].outcome }}
                  </b-form-checkbox>
                </span>
              </td>
              <td>
                <span v-if="programOutcomes[index-1]">
                  <b-form-checkbox :disabled="!editTable" v-model="enabledPoIds" name="checkbox-psoOption"
                    :value="programOutcomes[index-1].id">
                    {{ programOutcomes[index-1].code }}-{{ programOutcomes[index-1].outcome }}
                  </b-form-checkbox>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- <h5>COs</h5>
      <span class="text-danger" v-if="(courseOutcomes.length<1)">List is empty</span>
      <ul>
        <li v-for="co in courseOutcomes" :key="co.id">{{ co.code }}-{{ co.outcome }}
        </li>
      </ul>

      <h5>PSOs</h5>
      <span class="text-danger" v-if="(programSpecificOutcomes.length<1)">List is empty</span>
      <ul>
        <span v-for="pso in programSpecificOutcomes" :key="pso.id">
          <li v-if="editTable">
            <b-form-checkbox v-model="enabledPsoIds" name="checkbox-psoOption" :value="pso.id">
              {{ pso.code }}-{{ pso.outcome }}
            </b-form-checkbox>
          </li>
          <li v-if="!editTable && enabledPsoIds.includes(pso.id)"> {{ pso.code }}-{{ pso.outcome }}</li>
        </span>
      </ul>

      <h5>POs</h5>
      <span class="text-danger" v-if="(programOutcomes.length<1)">List is empty</span>
      <ul>
        <span v-for="po in programOutcomes" :key="po.id">
          <li v-if="editTable">
            <b-form-checkbox v-model="enabledPoIds" name="checkbox-poOption" :value="po.id">
              {{ po.code }}-{{ po.outcome }}
            </b-form-checkbox>
          </li>
          <li v-if="(enabledPoIds.includes(po.id) && !editTable)">
            {{ po.code }}-{{ po.outcome }}
          </li>
        </span>
      </ul> -->

    </div>

  </div>
</template>

<script>
// import CoPoMapping from "./CoPoMapping.vue";

import CopyCourseProperties from "../CopyCourseProperties.vue"
import axios from "axios";
export default {
  props: ["courseId", "usergroupId", "editCourseComponents"],
  async created() {
    this.loadCoPoPsoMapping();
    // this.fetchData = true;
  },
  components: {
    CopyCourseProperties
  },
  data() {
    return {
      fetchData: false,
      coPoMappingData: {},
      coPoMappingDataAvg: {},
      coPsoMappingData: {},
      coPsoMappingDataAvg:{},
      courseOutcomes: [],
      programOutcomes: [],
      programSpecificOutcomes: [],
      enabledPoIds: [],
      enabledPsoIds: [],
      saveChanges: {
        text: "Save Changes",
        disabled: false,
      },
      highestMappingLevel: null,
      copy_from: '',
      editTable: false,
    };
  },
  computed: {
    maximumCoPoPsoTableRows() {
      return Math.max(
        this.courseOutcomes.length,
        this.programOutcomes.length,
        this.programSpecificOutcomes.length,);
    },
    enabledProgramOutcomes() {
      return this.programOutcomes.filter(obj => this.enabledPoIds.includes(obj.id));
    },
    enabledProgramSpecificOutcomes() {
      return this.programSpecificOutcomes.filter(obj => this.enabledPsoIds.includes(obj.id));
    },
  },
  methods: {
    async loadCoPoPsoMapping() {
      this.editTable = false;
      this.fetchData = true;

      await Promise.all([
        this.getCourseOutcomes(),
        this.getProgramOutcomes(),
        this.getProgramSpecificOutcomes(),
        this.getMappingLevel(),
      ]);

      await this.courseOutcomes.forEach((co) => {
        this.$set(this.coPoMappingData, co.id, {});
        this.$set(this.coPsoMappingData, co.id, {});
        this.programOutcomes.forEach((po) => {
          this.$set(this.coPoMappingData[co.id], po.id, { level: Number(0) });
          this.$set(this.coPoMappingDataAvg, po.id, { sum: Number(0) ,count: Number(0) ,avg: Number(0) })
        })
        this.programSpecificOutcomes.forEach((pso) => {
          this.$set(this.coPsoMappingData[co.id], pso.id, { level: Number(0) });
          this.$set(this.coPsoMappingDataAvg, pso.id, { sum: Number(0) ,count: Number(0) ,avg: Number(0) })
        })
      });

      await Promise.all([
        this.getCoPoMapping(),
        this.getCoPsoMapping(),
      ]);

      // enable all by default
      if (this.enabledPoIds.length < 1 && this.enabledPsoIds.length < 1) {
        this.programOutcomes.forEach((po) => {
          this.enabledPoIds.push(po.id);
        });
        this.programSpecificOutcomes.forEach((pso) => {
          this.enabledPsoIds.push(pso.id);
        });
      }

      this.fetchData = false;
    },

    async getCourseOutcomes() {
      let courseOutcomeUrl = this.$store.getters.getAPIKey.getCourseOutcomes;
      const url = courseOutcomeUrl.replace("course_id", this.courseId);
      await axios
        .get(url)
        .then((response) => {
          this.courseOutcomes = response.data;
        })
        .catch(() => {
          // console.log(error.response);
        });
    },
    async getProgramOutcomes() {
      const url = this.$store.getters.getAPIKey.programOutcome + `?usergroup=${this.usergroupId}`;
      await axios
        .get(url)
        .then((response) => {
          this.programOutcomes = response.data;
        })
        .catch(() => {
        });
    },
    async getProgramSpecificOutcomes() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usergroup/${this.usergroupId}/programmespecificoutcomes`;
      await axios
        .get(url)
        .then((response) => {
          // console.log("getProgramSpecificOutcomes");
          this.programSpecificOutcomes = response.data;
        })
        .catch(() => {
        });
    },
    async getMappingLevel() {
      const settingKey = "co_po_mapping_level";
      let mainUrl = this.$store.getters.getAPIKey.institutionSettings;
      const url = mainUrl.replace("setting_key", settingKey);
      await axios
        .get(url)
        .then((response) => {
          this.highestMappingLevel = response.data;
        })
        .catch(() => {
          // console.log(error.response);
        });
    },

    async getCoPoMapping() {
      let mainUrl = this.$store.getters.getAPIKey.getcopoMapping;
      const url = mainUrl.replace("course_id", this.courseId);
      await axios
        .get(url)
        .then((response) => {
          let coPoMappingsResponse = response.data;
          coPoMappingsResponse.forEach(mapping => {
            this.coPoMappingData[mapping.course_outcome_id][mapping.programme_outcome_id].level = mapping.level;
            if (!this.enabledPoIds.includes(mapping.programme_outcome_id)) this.enabledPoIds.push(mapping.programme_outcome_id);
            if (mapping.level > 0) {
              this.coPoMappingDataAvg[mapping.programme_outcome_id]['sum'] += Number(mapping.level);
              this.coPoMappingDataAvg[mapping.programme_outcome_id]['count']++;
            }
          });
          this.programOutcomes.forEach((po) => {
            this.coPoMappingDataAvg[po.id]['avg']
              = Number(this.coPoMappingDataAvg[po.id]['sum']) /
              Number(this.coPoMappingDataAvg[po.id]['count'])
          })
        })
        .catch(() => {
          // console.log(error.response);
        });
    },
    // Get CoPso Mapping
    async getCoPsoMapping() {
      // this.fetchData = true;
      let mainUrl = this.$store.getters.getAPIKey.getcopsoMapping;
      const url = mainUrl.replace("course_id", this.courseId);
      await axios
        .get(url)
        .then((response) => {
          let coPsoMappingsResponse = response.data;
          coPsoMappingsResponse.forEach(mapping => {
            this.coPsoMappingData[mapping.course_outcome_id][mapping.programme_specificoutcome_id].level = mapping.level;
            if (!this.enabledPsoIds.includes(mapping.programme_specificoutcome_id)) this.enabledPsoIds.push(mapping.programme_specificoutcome_id);
            if (mapping.level > 0) {
              this.coPsoMappingDataAvg[mapping.programme_specificoutcome_id]['sum'] += Number(mapping.level);
              this.coPsoMappingDataAvg[mapping.programme_specificoutcome_id]['count']++;
            }
          });
          this.programSpecificOutcomes.forEach((pso) => {
            this.coPsoMappingDataAvg[pso.id]['avg']
              = Number(this.coPsoMappingDataAvg[pso.id]['sum']) /
              Number(this.coPsoMappingDataAvg[pso.id]['count'])
          })
        })
        .catch(() => {
          // console.log(error.response);
        });
    },

    propertyCopyCompleted() {
      this.$bvModal.hide('copyCoursePropertiesModel')
      // this.cancel();
      this.loadCoPoPsoMapping();
    },
    async cancel() {
      this.loadCoPoPsoMapping();
    },



    async saveMappings() {
      this.saveChanges.text = "Saving....";
      this.saveChanges.disabled = true;

      let course_outcome_ids = [];
      await this.courseOutcomes.forEach((co) => {
        course_outcome_ids.push(co.id);
      });

      let error = false;

      let coPoMappingPostData = [];
      await this.courseOutcomes.forEach((co) => {
        this.programOutcomes.forEach((po) => {
          if (this.enabledPoIds.includes(po.id)) coPoMappingPostData.push({
            course_outcome_id: co.id,
            programme_outcome_id: po.id,
            level: parseFloat(this.coPoMappingData[co.id][po.id].level).toFixed(1),
          });
        });
      });
      this.$store.getters.getAPIKey.copoMapping;
      let url = this.$store.getters.getAPIKey.copoMapping;
      await axios
        .post(url, {
          co_po_mapping: coPoMappingPostData,
          course_outcomes: course_outcome_ids,
        })
        .then(() => {

        })
        .catch(() => {
          error = true;
          // console.log(error.response);
        });



      let coPsoMappingPostData = [];
      await this.courseOutcomes.forEach((co) => {
        this.programSpecificOutcomes.forEach((pso) => {
          if (this.enabledPsoIds.includes(pso.id)) coPsoMappingPostData.push({
            course_outcome_id: co.id,
            programme_specificoutcome_id: pso.id,
            level: parseFloat(this.coPsoMappingData[co.id][pso.id].level).toFixed(1),
          });
        });
      });
      url = this.$store.getters.getAPIKey.copsoMapping;
      await axios
        .post(url, { co_pso_mapping: coPsoMappingPostData })
        .then(() => {
        })
        .catch(() => {
          error = true;
          // console.log(error.response);
        });

      if (error) {
        this.$toast.error("CO-PO CO-PSO Mapping failed to save.", {
          position: "top",
          duration: 5000,
        });
      } else {
        this.$toast.success("Mappings saved successfully", {
          position: "top",
          duration: 5000,
        });
        this.editTable = false;
      }
      this.saveChanges.text = "Save";
      this.saveChanges.disabled = false;
      this.loadCoPoPsoMapping();

    },

  },
};
</script>

<style >
.mappingTable table tr td,
.mappingTable table th {
  font-size: 12px;
}
</style>
