<template>
  <div>
    <div v-if="usersubgroupId">
      <div class="loading" v-if="loadCourses">
        <load-data></load-data>
      </div>
      <div v-else>
        <b-modal id="courseReports" :title="courseReport.name" no-close-on-backdrop no-close-on-keyboard no-close-on-esc
          hide-footer centered size="lg" modal-class="courseReports">
          <CourseReports :getCustomNames="getCustomNames" :courseReport="courseReport" />
        </b-modal>

        <b-modal id="courseAdmins" :title="courseAdminModal.name + ': ' + 'Course Instructors'" no-close-on-backdrop
          no-close-on-keyboard no-close-on-esc hide-footer centered size="lg" @close="checkAdminStatus">
          <CourseAdmins @adminRemoved="adminRemoved" @newCourseAdminsAdded="getCourseDetails()"
            :courseAdminModal="courseAdminModal" :getCustomNames="getCustomNames" :faculties="faculties" />
        </b-modal>

        <b-modal id="courseStudents" :title="courseStudentModal.name + ': ' + 'Students'" no-close-on-backdrop
          no-close-on-keyboard no-close-on-esc hide-footer centered size="lg"
          @close="courseStudentModal.deletion ? getCourseDetails() : ''">
          <CourseStudents :courseStudentModal="courseStudentModal" @studentsRemoved="courseStudentModal.deletion = true"
            @newStudentAdded="getCourseDetails()" :getCustomNames="getCustomNames" />
        </b-modal>
        <div class="coursesBtns p-2 " style="
          border: 2px solid #eeeeeb;
          border-left-style: none;
          border-right-style: none;
        " v-if="!addCourse && editCourse">
          <!-- <div> -->
          <b-button ref="addCourse" size="sm" variant="info" @click="createCourse()"><i
              class="fas fa-plus-square text-white"></i>Add New Course</b-button>
          <b-button variant="info" size="sm" class="ml-3" v-if="!addCourse && !showUpdateCourse"
            @click="getCourseDetails()" siz>
            <i class="fas fa-sync-alt text-white"></i>
            Refresh
          </b-button>

          <!-- <b-button class="ml-5" ref="" size="sm" variant="danger" @click="$emit('closeList')"><i
                class="fas fa-times text-white"></i>Close</b-button> -->
          <!-- </div> -->
          <!-- <b-button
            @click="openYoutubeLinks('Courses')"
            style="backgroundColor: #FF0000; border: none"
            size="sm"
          >
            <i class="fab fa-youtube"></i>
            Video Tutorial
          </b-button> -->
        </div>
        <create-course v-if="showCreateCourse" ref="createCourse" @courseCreated="showCreateCourse = false"
          :faculties="faculties" :getCustomNames="getCustomNames" :courseTypesOptions="courseTypesOptions"
          :courseCategoriesOptions="courseCategoriesOptions" :subgroupPreSelection="usersubgroupId" :subgroupType="subgroupType"></create-course>
        <update-course class="updateCourse" ref="updateCourse" :courseInfo="courseDetails" :courseId="courseId"
          :courseUsers="courseUsers" :editCourse="editCourse" v-if="showUpdateCourse"
          @updateClosed="showUpdateCourse = false" :getCustomNames="getCustomNames" :faculties="faculties"
          :courseTypesOptions="courseTypesOptions" :courseCategoriesOptions="courseCategoriesOptions"
          :subgroupType="subgroupType"></update-course>

        <div v-if="!showUpdateCourse && !showCreateCourse">
          <br />
          <div class="noCoursesFound" v-if="courses.length == 0">
            <h6 class="text-center text-danger">No courses created yet!</h6>
          </div>
          <div class="couresDetails" v-else>
            <b-form-group v-if="!usersubgroupId">
              <div class="d-flex justify-content-between align-items-center p-3" style="
                border: 2px solid #eeeeeb;
                border-left-style: none;
                border-right-style: none;
              ">
                <multiselect v-model="selectedSubgroup" deselect-label="Can't remove this value" track-by="value"
                  label="name" :placeholder="'Select' + getCustomNames.userSubgroup.custom_name != null
                  ? getCustomNames.userSubgroup.custom_name : 'Subgroup'" :options="subgroupOptions"
                  :searchable="false" :allow-empty="false" style="width: 300px">
                  <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.name }}</strong></template>
                </multiselect>
                <b-form-input v-model="search" placeholder="Search by course name/ course code"
                  style="width: 300px; height: 40px"></b-form-input>
              </div>
            </b-form-group>

            <!-- <div class="sortByDropdown mb-2 w-50">

          </div> -->

            <div class="p-3">
              <div class=" rounded">
                <table class="table table-striped table-bordered">
                  <thead class="thead-dark text-left">
                    <tr>
                      <th>Si No</th>
                      <th>Course</th>
                      <th>Code</th>
                      <th>Course Type</th>
                      <th>Sub Type</th>
                      <th>Edit</th>
                      <th>Instructor Details</th>
                      <th>Enrolled Students</th>
                      <th>Course Reports</th>
                      <th>Academic Year</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="filteredCourses.length == 0">
                      <td colspan="100" class="text-danger text-center">
                        No Course Found!
                      </td>
                    </tr>
                    <tr v-else v-for="(course, index) in filteredCourses" :key="index" class="text-left">
                      <td class="align-middle">
                        {{ course.si_no }}
                      </td>
                      <td class="align-middle">
                        <span
                          :class="{ 'text-danger': course.deleted_at, 'text-success': courseTypesObj[course.course_type_id] == 'Non Curricular' }"
                          v-if="(course.usersubgroup_id != usersubgroupId)">
                          {{ course.name }}
                        </span>
                        <p v-else
                          :class="[course.deleted_at ? 'text-danger' : courseTypesObj[course.course_type_id] == 'Non Curricular' ? 'text-success' : 'text-info', 'btn p-0 m-0 font-weight-bolder text-capitalize']"
                          @click="$bvModal.show('coursesOptions' + course.id)">
                          {{ course.name }}
                        </p>
                        <div v-if="(course.deleted_at != null)">
                          <vue-countdown :time="timeToDeleteIn(course.deleted_at)" v-slot="{ hours, minutes, seconds }">
                            <b-badge variant="danger" style="white-space: nowrap; margin: 2px;">Delete in {{
                                hours
                            }}:{{ minutes
}}:{{ seconds }}
                            </b-badge><b-link
                              @click="$bvModal.show('cancelDeleteCourseConfirmationModel'); deleteCourseModel.id = course.id">
                              <b-badge variant="success">Cancel Deletion</b-badge></b-link>
                          </vue-countdown>
                        </div>
                      </td>

                      <td class="align-middle">
                        {{ course.code }}-{{ course.usersubgroup.code }}-{{
                            course.usersubgroup.usergroup.code
                        }}
                      </td>
                      <td class="align-middle">
                        {{
                            course.course_category_id != null
                              ? courseCategoriesObj[course.course_category_id]
                              : "Not Applicable"
                        }}
                      </td>
                      <td class="align-middle">
                        {{
                            course.course_type_id != null
                              ? courseTypesObj[course.course_type_id]
                              : "-"
                        }}
                      </td>
                      <td class="align-middle">
                        <span v-if="courseAction.enable && course.usersubgroup_id == usersubgroupId">
                          <span v-b-tooltip.hover title="Edit course" class="text-info btn m-0 p-0"
                            @click="updateCourse(course.id)"><i class="fas fa-pen ml-3"></i></span>

                          <!-- <b-modal :id="'copyCourseModel' + course.id" :title="course.name + ' Cloning'"
                            no-close-on-backdrop no-close-on-keyboard no-close-on-esc hide-footer modal-class="">
                            <CoursesCloning :course_id="course.id" :course_name="course.name"
                              @newCourseAdded="getCourseDetails()" />
                          </b-modal>
                          <span @click="
                            $bvModal.show('copyCourseModel' + course.id)
                          " v-b-tooltip.hover title="Copy course & create new" class="text-info btn m-0 p-0"><i
                              style="color:green;" class="fas fa-copy ml-3"></i></span> -->
                          <span v-if="(course.deleted_at == null)" v-b-tooltip.hover title="Delete course & all datas"
                            class="text-info btn m-0 p-0" @click="openDeleteCourseModel(course.id, course.name)"><i
                              style="color:red;" class="fas fa-trash ml-3"></i></span>
                        </span>
                        <span v-if="
                          !courseAction.enable &&
                          courseAction.courseId == course.id
                        ">{{ courseAction.status }}</span>
                      </td>
                      <td class="align-middle">
                        <b-popover :target="'disabled-courseAdmin' + index" triggers="hover" placement="top"
                          v-if="instructorsList[course.id].length != 0">
                          <b-list-group style="height: 200px; overflow-y: scroll">
                            <b-list-group-item v-for="({ first_name, last_name, enroll_status },
                            adminIndex) in instructorsList[course.id]" :key="adminIndex">
                              <span :class="{ 'text-danger': enroll_status != 'active' }"> {{ first_name.concat(" ",
                                  last_name)
                              }}</span>
                            </b-list-group-item>
                          </b-list-group>
                        </b-popover>
                        <span :id="'courseAdmin' + index" @click="course.usersubgroup_id == usersubgroupId ? getCourseAdmins(
                          course.id,
                          course.name,
                          instructorsList[course.id]
                        ) : null">
                          <span class="text-info btn p-0 m-0" v-if="instructorsList[course.id].length != 0">
                            {{ instructorsList[course.id][0].first_name }} {{ instructorsList[course.id][0].last_name }}
                            <span v-if="instructorsList[course.id].length > 1" :id="'courseAdmin' + index">
                              &
                              {{
                                  instructorsList[course.id].length - 1 + " other"
                              }}
                            </span>
                          </span>
                          <span v-else class="text-danger btn">
                            No Instructors Assigned!
                          </span>
                        </span>
                      </td>

                      <td class="align-middle">
                        <span @click="course.usersubgroup_id == usersubgroupId ?
                          getCourseStudentsFromModal(
                            course.id,
                            course.name,
                            studentsList[course.id],
                            course.usersubgroup_id
                          ) : null
                        ">
                          <span class="text-info btn p-0 m-0" v-if="studentsList[course.id].length != 0">
                            {{ studentsList[course.id].length }}
                            {{
                                studentsList[course.id].length > 1
                                  ? "Students"
                                  : "Student"
                            }}
                          </span>
                          <span v-else class="text-danger btn">
                            No Students Enrolled
                          </span>
                        </span>
                      </td>
                      <td class="text-center align-middle">
                        <span class="btn p-0 m-0 text-info" @click="
                          dataRequiredForCourseReportsAPI(
                            course.id,
                            course.usersubgroup.usergroup.id,
                            course.usersubgroup_id,
                            course.code,
                            course.usersubgroup.usergroup.code,
                            course.usersubgroup.code,
                            course.name
                          )
                        "><i class="fas fa-file-alt"></i></span>
                      </td>
                      <td class="align-middle">
                        {{ course.academic_year }}
                        {{ course.academic_semester }}
                      </td>
                      <td class="text-center align-middle">
                        <b-modal :id="'coursesOptions' + course.id" :title="course.name + ' Configuration'"
                          no-close-on-backdrop no-close-on-keyboard no-close-on-esc hide-footer
                          modal-class="coursesOptions">
                          <CoursesOptions :courseInfo="course" :course_id="course.id" :course_type="course.course_type_id != null
                          ? courseTypesSlugs[course.course_type_id]
                          : '-'" :courseInstructor="courseInstructor" :courseId="course.id"
                            :usergroupId="course.usersubgroup.usergroup.id" :editCourseComponents="editCourseComponents"
                            @CourseUpdated="CourseUpdated" />
                        </b-modal>

                        <span v-if="course.usersubgroup_id == usersubgroupId" class="btn p-0 m-0 text-info"
                          @click="$bvModal.show('coursesOptions' + course.id)">
                          <i class="fas fa-bars"></i>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      Courses is moved to
      {{
          getCustomNames.userSubgroup.custom_name != null
            ? getCustomNames.userSubgroup.custom_name
            : "Usersubgroup"
      }}
      tab, and listed under each
      {{
          getCustomNames.userSubgroup.custom_name != null
            ? getCustomNames.userSubgroup.custom_name
            : "Usersubgroup"
      }}
    </div>

    <b-modal id="deleteCourseConfirmationModel" hide-header-close hide-footer hide-header centered size="sm">
      <h6 style="font-size: 18px; font-weight: 600" class="text-danger">
        Are you sure you want to delete {{ deleteCourseModel.name }}
      </h6>
      <div>
        <b-form-group id="input-group-1" label-for="input-1">
          <label class="required-label" for="">Type 'DELETE' and press delete button to complete deletion:</label>
          <b-form-input id="input-1" v-model="deleteCourseModel.confirmText" type="text"
            placeholder="Enter  DELETE"></b-form-input>
        </b-form-group>
      </div>
      <div>
        <b-form-group id="input-group-1" label-for="input-1">
          <label class="required-label" for="">Enter a brief explanation of the reason for deletion (Minimum 10
            character):</label>
          <b-form-input id="input-1" v-model="deleteCourseModel.reason" type="text"
            placeholder="Enter Reason"></b-form-input>
        </b-form-group>
      </div>
      <div class="buttons">
        <b-button :disabled="(deleteCourseModel.confirmText != 'DELETE' || deleteCourseModel.reason.length < 10)"
          size="sm" variant="primary" @click="deleteCourse()">Delete</b-button>
        <b-button size="sm" variant="danger" class="ml-2"
          @click="$bvModal.hide('deleteCourseConfirmationModel')">Cancel</b-button>
      </div>
    </b-modal>
    <b-modal id="cancelDeleteCourseConfirmationModel" hide-header-close hide-footer hide-header centered size="sm">
      <h6 style="font-size: 18px; font-weight: 600" class="text-danger">
        Are you sure you want to proceed
      </h6>
      <div>
        <b-form-group id="input-group-1" label-for="input-1">
          <label class="required-label" for="">Enter a brief explanation of the reason for cancellation of deletion
            (Minimum 10 character):</label>
          <b-form-input id="input-1" v-model="deleteCourseModel.reason" type="text"
            placeholder="Enter Reason"></b-form-input>
        </b-form-group>
      </div>
      <div class="buttons">
        <b-button :disabled="deleteCourseModel.reason.length < 10" size="sm" variant="primary"
          @click="CancelDeletion()">Cancel Deletion</b-button>
        <b-button size="sm" variant="danger" class="ml-2"
          @click="$bvModal.hide('cancelDeleteCourseConfirmationModel')">Cancel</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import VueCountdown from "@chenfengyuan/vue-countdown";
import CreateCourse from "../Course/CreateCourse.vue";
import UpdateCourse from "../Course/UpdateCourse.vue";
import CourseAdmins from "./CourseAdmins.vue";
import CourseStudents from "./CourseStudents.vue";
import axios from "axios";
import acl from "../../../../authorization/acl.js";

import CourseReports from "./CourseReports.vue";
import CoursesOptions from "./CoursesOptions.vue";
// import CoursesCloning from "./CoursesCloning.vue";
import moment from 'moment';
export default {
  props: ["editCourseComponents", "usersubgroupId", "subgroupType"],
  async created() {
    this.loadCourses = true;
    if (this.usersubgroupId) {
      this.selectedSubgroup = {
        value: this.usersubgroupId,
        text: "",
      };
      const canEditCourse = await acl.isUserGranted("editCourse");
      this.editCourse = canEditCourse;
      const courseInstructor = await acl.isUserGranted("courseInstructor");
      this.courseInstructor = courseInstructor;
      await this.getInstitutionFaculties();
      // await this.getUserSubgroups();
      await this.getCourseTypes();
      await this.getCourseCategories();
      await this.getCourseDetails();
      this.loadCourses = false;
    }
  },
  components: {
    CreateCourse: CreateCourse,
    UpdateCourse: UpdateCourse,
    CourseReports,
    CoursesOptions,
    // CoursesCloning,
    CourseAdmins,
    CourseStudents,
    VueCountdown,
  },
  data() {
    return {
      courseReport: {
        course_id: null,
        usergroup_id: null,
        usersubgroup_id: null,
        course_code: null,
        usergroup_code: null,
        usersubgroup_code: null,
        name: null,
      },
      courseInstructor: null,
      loadLessons: false,
      deleteLesson: false,
      showUpdateCourse: false,
      showCreateCourse: false,
      courseUpdate: false,
      search: "",
      tabIndex: 0,
      loadCourses: false,
      fetchData: false,
      lessonCreation: false,
      updateLessonDetails: {
        id: null,
        lesson: {},
      },
      editCourse: null,
      addCourse: false,
      courses: [],
      coursesWithUsers: [],
      instructorsList: [],
      studentsList: [],
      courseDetails: {},
      courseId: "",
      courseUsers: [],
      lessonList: {},
      courseStudents: {},
      reportBtn: {
        text: "Download PDF",
        disabled: false,
      },
      lessonView: {},
      courseAdminModal: {},
      courseStudentModal: {},
      faculties: [],
      subgroups: [],
      sortBySubgroup: null,
      selectedSubgroup: null,
      subgroupOptions: [],
      courseTypesOptions: [],
      courseTypesObj: {},
      courseCategoriesOptions: [],
      courseCategoriesObj: {},
      courseTypesSlugs: {},
      courseAction: { enable: true, courseId: null, status: "" },
      deleteCourseModel: { id: null, name: null, confirmText: '', reason: '' },
    };
  },
  computed: {
    getCustomNames() {
      return this.$store.getters.getCustomNamings;
    },
    filteredCourses() {
      if (this.search) {
        return this.coursesWithUsers.filter((course) => {
          let cName = course.name;
          let cCode = course.code;
          let cGroupCode = course.usersubgroup.usergroup.code;
          let cSubgroupCode = course.usersubgroup.code;

          return this.search
            .toLowerCase()
            .split(" ")
            .every(
              (v) =>
                cName.toLowerCase().includes(v) ||
                cCode.toLowerCase().includes(v) ||
                cGroupCode.toLowerCase().includes(v) ||
                cSubgroupCode.toLowerCase().includes(v)
            );
        });
      }
      // else if (this.selectedSubgroup != null) {
      //   return this.coursesWithUsers.filter((subgroup) => {
      //     return subgroup.usersubgroup_id == this.selectedSubgroup.value;
      //   });
      // } 
      else {
        return this.coursesWithUsers;
      }
    },
  },
  methods: {
    timeToDeleteIn(deleted_at) {
      // Given UTC time from the API response
      // deleted_at
      // Step 1: Add 24 hours to the API response time
      const futureTime = moment.utc(deleted_at, 'YYYY-MM-DD HH:mm:ss').add(24, 'hours');
      // Step 2: Get the current UTC time using moment.js
      const currentUTCTime = moment.utc();
      // Step 3: Calculate the difference in seconds
      const timeDifferenceInSeconds = futureTime.diff(currentUTCTime, 'seconds');
      return timeDifferenceInSeconds * 1000;
      // console.log(moment());
      // console.log(moment.utc());
      // console.log(moment.utc(deleted_at));
      // console.log(moment.utc().diff(moment.utc(deleted_at), 'seconds'));
      // console.log((86400 - moment.utc().diff(moment.utc(deleted_at), 'seconds')));
      // return ((86400 - moment.utc().diff(moment.utc(deleted_at), 'seconds')) * 1000);
    },
    openDeleteCourseModel(id, name) {
      this.deleteCourseModel = { id: id, name: name, confirmText: '', reason: '' };
      this.$bvModal.show("deleteCourseConfirmationModel");
    },
    async getCourseTypes() {
      const url = this.$store.getters.getAPIKey.mainAPI + `/coursetypes `;
      await this.$axios.get(url).then((res) => {
        this.courseTypesOptions = [
          {
            value: null,
            text: "Select Sub Type",
          },
        ];
        res.data.forEach(({ id, name }) => {
          this.$set(this.courseTypesObj, id, name);
          this.courseTypesOptions.push({
            value: id,
            text: name,
          });
        });
        res.data.forEach(({ id, slug }) => {
          this.$set(this.courseTypesSlugs, id, slug);
        });
      });
    },
    async getCourseCategories() {
      const url = this.$store.getters.getAPIKey.mainAPI + `/coursetypes/categories`;
      await this.$axios.get(url).then((res) => {
        this.courseCategoriesOptions = [
          {
            value: null,
            text: "Not Applicable",
          },
        ];
        res.data.forEach(({ id, name }) => {
          this.$set(this.courseCategoriesObj, id, name);
          this.courseCategoriesOptions.push({
            value: id,
            text: name,
          });
        });
      });
    },
    async CourseUpdated() {
      await this.getCourseDetails();
    },
    async testing(name) {
      let test = await this.openYoutubeLinks(name);
      if (test) {
        this.$bvModal.show("youtubeLinks");
      }
    },
    // async getUserSubgroups() {
    //   const url =
    //     this.$store.getters.getAPIKey.mainAPI +
    //     "/institutionuser/usersubgroups";
    //   await axios
    //     .get(url)
    //     .then((response) => {
    //       this.subgroups = response.data;
    //       // this.subgroups.forEach((subgroup) => {
    //       //   this.subgroupOptions.push({
    //       //     value: subgroup.id,
    //       //     name: subgroup.usergroup.code + " - " + subgroup.code,
    //       //   });
    //       // });
    //       // if (this.subgroups.length != 0) {
    //       //   this.selectedSubgroup = this.subgroupOptions[0];
    //       // }
    //       // response;
    //     })
    //     .catch((error) => {
    //       error;
    //     });
    // },
    sortCoursesBySubgroup(subgroupId) {
      if (this.sortBySubgroup != null && this.sortBySubgroup == subgroupId) {
        return (this.sortBySubgroup = null);
      }
      this.sortBySubgroup = subgroupId;
    },
    getCourseAdmins(courseId, courseName, courseAdmins) {
      this.courseAdminModal = {};
      this.$set(this.courseAdminModal, "id", courseId);
      this.$set(this.courseAdminModal, "name", courseName);
      this.$set(this.courseAdminModal, "admins", courseAdmins);
      this.$set(
        this.courseAdminModal,
        "totalAdmins",
        Number(courseAdmins.length)
      );
      this.$set(
        this.courseAdminModal,
        "remainingAdmins",
        Number(courseAdmins.length)
      );
      this.$bvModal.show("courseAdmins");
    },

    getCourseStudentsFromModal(
      courseId,
      courseName,
      courseStudents,
      usersubgroupId
    ) {
      this.courseAdminModal = {};
      this.$set(this.courseStudentModal, "id", courseId);
      this.$set(this.courseStudentModal, "name", courseName);
      this.$set(this.courseStudentModal, "students", courseStudents);
      this.$set(this.courseStudentModal, "deletion", false);

      this.$set(this.courseStudentModal, "usersubgroup_id", usersubgroupId);

      this.$bvModal.show("courseStudents");
    },
    adminRemoved(remainingAdmins) {
      this.courseAdminModal.remainingAdmins = remainingAdmins;
    },
    checkAdminStatus() {
      if (
        Number(this.courseAdminModal.admins.length) !=
        Number(this.courseAdminModal.remainingAdmins)
      ) {
        this.getCourseDetails();
      }
    },
    async getInstitutionFaculties() {
      //get institutionfaculties
      const url = this.$store.getters.getAPIKey.instituitionFaculty;
      await axios
        .get(url)
        .then((response) => {
          this.faculties = [];
          this.faculties = response.data;
        })
        .catch(() => {
          // console.log(error.response);
        });
    },
    dataRequiredForCourseReportsAPI(courseId, groupId, subgroupId, courseCode, groupCode, subgroupCode, courseName) {
      this.courseReport.course_id = courseId;
      this.courseReport.usergroup_id = groupId;
      this.courseReport.usersubgroup_id = subgroupId;
      this.courseReport.course_code = courseCode;
      this.courseReport.usergroup_code = groupCode;
      this.courseReport.usersubgroup_code = subgroupCode;
      this.courseReport.name = courseName;
      this.$bvModal.show("courseReports");
    },

    checkInstructors(courseId) {
      this.lessonView[courseId] = false;
      this.lessonView[courseId] = true;
    },
    async downloadLessonPlanReportPDF(courseId) {
      this.reportBtn = {
        text: "Downloading....",
        disabled: true,
      };

      const url =
        this.$store.getters.getAPIKey.mainAPI + `/lessonplans/report/pdfexport`;
      await this.$axios
        .post(url, { course: courseId }, { responseType: "blob" })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "lessonPlansReport.pdf");
          document.body.appendChild(link);
          link.click();
          link.remove();
          // this.loadReport = false;
          this.reportBtn = {
            text: "Download PDF",
            disabled: false,
          };
          // console.log(response);
        })
        .catch(() => {
          this.reportBtn = {
            text: "Download PDF",
            disabled: false,
          };
          // this.loadReport = false;
          //handle 422------------------------------------------------------------------------------------------------------------------
          // console.log(error.response);
        });
    },
    async getCourseDetails() {
      this.loadCourses = true;

      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usersubgroup/${this.usersubgroupId}/courses/withusers?interdepartmental_courses=1&`;
      await axios
        .get(url)
        .then((response) => {
          //
          this.courses = response.data;
          this.coursesWithUsers = response.data;
          this.instructorsList = {};
          this.studentsList = {};
          this.courses.forEach((courseUsers) => {
            this.$set(this.lessonView, courseUsers.id, false);
            this.instructorsList[courseUsers.id] = [];
            this.studentsList[courseUsers.id] = [];
            courseUsers.institution_users.forEach((user) => {
              if (user.pivot.courserole_id == 1) {
                this.instructorsList[courseUsers.id].push(user);
                // this.instructorsList[courseUsers.id] = user;
              }
              if (user.pivot.courserole_id == 2) {
                this.studentsList[courseUsers.id].push(user);
              }
            });
          });
          this.loadCourses = false;
          // console.log(response);
        })
        .catch(() => {
          // console.log(error.response);
        });
    },
    async getCourseStudents(course_id) {
      this.fetchData = true;
      let mainUrl = this.$store.getters.getAPIKey.createStudent;
      const url = mainUrl.replace("course_id", course_id);
      await axios
        .get(url)
        .then((response) => {
          const users = response.data[0];
          this.courseStudents = {};
          this.courseStudents[course_id] = [];
          users.forEach((user) => {
            this.courseStudents[course_id].push(user);
          });
          this.sortedArray(course_id);
          this.fetchData = false;
          // console.log(response);
        })
        .catch(() => {
          // console.log(error.response);
        });
    },
    sortedArray(course_id) {
      this.courseStudents[course_id].sort(function (a, b) {
        return (
          a.roll_number - b.roll_number ||
          a.first_name.localeCompare(b.first_name)
        );
      });
    },

    async createCourse() {
      this.addCourse = true;
      this.showCreateCourse = await true;
      await this.showCreateCourse;
      // this.$refs.createCourse.fillData();
    },

    async updateCourse(course_id) {
      this.loadCourses = true;
      this.courseDetails = {};
      this.courseId = "";
      this.courseUsers = [];

      let updateUrl = this.$store.getters.getAPIKey.updateCourse;
      const url = updateUrl.replace("course_id", course_id);
      let updateUrlWIthUsers = this.$store.getters.getAPIKey.getCourseWithUsers;
      const usersList = updateUrlWIthUsers.replace("course_id", course_id);
      await axios
        .get(url)
        .then((response) => {
          this.courseId = response.data.id;
          this.courseDetails.si_no = response.data.si_no;
          this.courseDetails.name = response.data.name;
          this.courseDetails.code = response.data.code;
          this.courseDetails.academic_year = response.data.academic_year;
          this.courseDetails.usersubgroup_id = response.data.usersubgroup_id;
          this.courseDetails.enable_laboratory = response.data.enable_laboratory;
          if (
            response.data.start_year != null &&
            response.data.end_year != null
          ) {
            this.courseDetails.start_year = response.data.start_year;
            this.courseDetails.end_year = response.data.end_year;
          }
          this.courseDetails.usersubgroup = response.data.usersubgroup;
          this.courseDetails.usergroup = response.data.usergroup;
          this.courseDetails.course_type_id = response.data.course_type_id;
          this.courseDetails.course_category_id = response.data.course_category_id;

          this.courseId = response.data.id;
          axios.get(usersList).then((response) => {
            this.courseUsers = response.data;
          });
          this.showUpdateCourse = true;
          this.addCourse = true;
          this.loadCourses = false;
          // console.log(response);
        })
        .catch(() => {
          this.loadCourses = false;
          // console.log(error.response);
        });
    },

    async CancelDeletion() {
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/courses/${this.deleteCourseModel.id}/canceldeletion`;
      this.$axios
        .post(url, { reason: this.deleteCourseModel.reason })
        .then(() => {
          this.$bvModal.hide('cancelDeleteCourseConfirmationModel');
          this.deleteCourseModel = { id: null, name: '', confirmText: '', reason: '' };
          this.getCourseDetails();
        });
    },
    deleteCourse() {
      let course_id = this.deleteCourseModel.id;
      let name = this.deleteCourseModel.name;
      this.courseAction = {
        enable: false,
        courseId: course_id,
        status: "Deleting...",
      };
      if (
        confirm(
          `WARNING: You are going to delete ${name}, this action will also delete all the data related (eg: co, dettach course instructors, course scedules, boardexam config, modules, lessonplans, co_po_pso_mapping,course_properties, text_books, reference_docs...) `
        ) == true
      ) {
        const url =
          this.$store.getters.getAPIKey.mainAPI + `/courses/${course_id}`;
        this.$axios
          .delete(url, {
            data: { reason: this.deleteCourseModel.reason },
          })
          .then(() => {
            // console.log(response)
            this.$toast.success(`${name} deletion initiated successfully`, {
              position: "top-right",
              duration: 6000,
            });
            this.courseAction = { enable: true, courseId: null, status: "" };

            this.deleteCourseModel = { id: null, name: '', confirmText: '', reason: '' };
            this.$bvModal.hide("deleteCourseConfirmationModel");
            this.getCourseDetails();
          })
          .catch((error) => {
            // console.log(error);
            this.$toast.error(
              `${name} is failed to delete, ` + error.response.data.message,
              {
                position: "top-right",
                duration: 6000,
              }
            );
            error;
            this.courseAction = { enable: true, courseId: null, status: "" };
          });
      } else {
        this.courseAction = { enable: true, courseId: null, status: "" };
      }
    },
    totalUsers() { },
  },
};
</script>

<style  scoped>
.coursesBtns i {
  font-size: 12px !important;
  margin-right: 4px;
}

.courseReports .modal-dialog {
  max-width: 100%;
  max-height: 100vh;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  position: fixed;
  z-index: 100000;
}

.courseReports .modal-body {
  overflow: scroll;
}

.coursesOptions .modal-dialog {
  max-width: 100%;
  max-height: 100vh;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  position: fixed;
  z-index: 100000;
}

.coursesOptions .modal-body {
  overflow: scroll;
}
</style>
