<template>
  <div class="container">
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div v-if="!fetchData">
      <b-row class="control-buttons">
        <b-col v-if="resourceaction == 'list'" style="text-align:left;">
          <b-button v-if="$store.getters.defaultInstitute.userRole != 'Student'" variant="success" class="text-white sm"
            size="sm" @click="createSuspendForm"><i class="fas fa-plus-square text-white mr-1"></i> Suspend
            Session</b-button>
        </b-col>
        <b-col v-if="resourceaction != 'list'" style="text-align:right;">
          <b-button variant="danger" class="text-white sm" size="sm" :disabled="actionButton.disabled"
            @click="resourceaction = 'list'">Cancel</b-button>
        </b-col>
      </b-row>
      <div v-if="(resourceaction == 'list')">
        <div v-if="(usersubgroup_id != null)">
          <h3 style="text-align: center; font-size:18px !important;">
            Suspended Sessions
          </h3>
          <div class="table-responsive rounded">
            <table class="table table-striped table-bordered">
              <thead class="thead-dark text-left">
                <tr>
                  <th>
                    SI. No.
                  </th>
                  <th>
                    Date
                  </th>
                  <th>
                    Name
                  </th>
                  <th>sessions</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(suspend, suspendindex) in suspends" :key="suspendindex">
                  <td>{{ suspendindex + 1 }}</td>
                  <td>{{ moment(suspend.date, 'YYYY-MM-DD').format('DD/MM/YYYY') }}
                    -{{ weekdayNames[new Date(suspend.date).getDay()] }}
                  </td>
                  <td>
                    {{ suspend.name }}
                  </td>
                  <td>{{ suspend.session_id.map(sessionId => sessionObjects[sessionId].name).join(', ') }}</td>
                  <td>
                    <span class="text-info btn m-0 p-0 sm" size="sm" :disabled="actionButton.disabled"
                      @click="deleteSuspend(suspendindex)"><i style="color:red;" class="fas fa-trash ml-3"></i></span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- suspend list and managment for hod and admin  -->
        <div v-else>
          <table class="table table-striped table-bordered">
            <thead class="thead-dark text-left">
              <tr>
                <th>
                  SI. No.
                </th>
                <th>
                  Date
                </th>
                <th>
                  Sessions
                </th>
                <th>
                  Name
                </th>
                <th>
                  {{ subgroupCustomName }}
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(suspend, suspindex) in dateSuspends" :key="suspindex">
                <td>{{ suspindex + 1 }}</td>
                <td>{{ moment(suspend.date, 'YYYY-MM-DD').format('DD/MM/YYYY') }}
                  -{{ weekdayNames[new Date(suspend.date).getDay()] }}
                </td>
                <td>
                  {{ suspend.sessions.map(sessionId => sessionObjects[sessionId].name).join(', ')}}
                </td>
                <td>
                  {{ suspend.suspend_name.join(', ') }}
                </td>
                <td>
                  {{ suspend.usersubgroups.map(subgroup => subgroup.code+'-'+subgroup.usergroup.code).join(', ')}}
                  <!-- <span v-for="(subgroup, subindex) in suspend.usersubgroups" :key="subindex">
                    {{ subgroup.code }}-{{ subgroup.usergroup.code }}
                    <span v-if="(suspend.usersubgroups.length > 1)" class="text-info btn m-0 p-0 sm" size="sm"
                      :disabled="actionButton.disabled" @click="removeHolydays(holyday.date, subgroup.id)"><i
                        style="color:red;" class="fas fa-trash ml-3"></i></span> <br>
                  </span> -->
                </td>
                <td>
                  <span class="text-info btn m-0 p-0 sm" size="sm" :disabled="actionButton.disabled"
                    @click="removeSuspend(suspend.date, suspend.sessions, suspend.usersubgroups)"><i style="color:red;"
                      class="fas fa-trash ml-3"></i></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="resourceaction == 'create'">
        <div>
          <h3 style="text-align: center; font-size:18px !important;">
            New Session Suspend Action
          </h3>
          <b-row>
            <b-col sm="6">
              <b-form-group id="input-group-1" label-for="input-1" description="">
                <label class="required-label" for="">Suspend Reason:</label>
                <b-form-input id="input-1" v-model="suspend.name" type="text" placeholder="Enter a Reason"
                  required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="6" v-if="(suspend.date_type === null)">
              <b-form-group label="Choose Date type">
                <b-form-radio-group required v-model="suspend.date_type" name="radio-sub-component">
                  <b-form-radio value="single_date">Single day</b-form-radio>
                  <b-form-radio value="date_range">Date Range</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>
            <b-col sm="6" v-if="(suspend.date_type === 'single_date')">
              <b-form-group id="input-group-2" label-for="input-2" description="">
                <label class="required-label" for="">Date:</label>
                <b-form-datepicker id="example-datepicker111" v-model="suspend.date" class="mb-2"></b-form-datepicker>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="(suspend.date_type === 'date_range')">
            <b-col sm="6">
              <b-form-group id="input-group-2" label-for="input-2" description="">
                <label class="required-label" for="">Start Date:</label>
                <b-form-datepicker id="example-datepicker11" v-model="suspend.start_date"
                  class="mb-2"></b-form-datepicker>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group id="input-group-2" label-for="input-2" description="">
                <label class="required-label" for="">End Date:</label>
                <b-form-datepicker id="example-datepicker1" v-model="suspend.upto_date"
                  class="mb-2"></b-form-datepicker>
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-group>
            <label class="required-label" for="">Sessions:</label>
            <b-form-checkbox-group value-field="id" text-field="name" id="checkbox-group-1" v-model="suspend.session_id"
              :options="sessionObjects" name="flavour-1"></b-form-checkbox-group>
          </b-form-group>
          <b-row v-if="(usersubgroup_id == null)">
            <b-col>
              <b-form-group :label="'Select '+subgroupCustomName+':'" >
                <b-form-checkbox-group id="checkbox-group-2" v-model="suspend.usersubgroup_id" 
                  name="flavour-2">
                  <b-form-checkbox v-for="(subgroup, subindex) in allusersubgroups" :key="subindex" :value="subgroup.value">{{subgroup.text}}</b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" style="text-align:right;">
              <b-button :disabled="actionButton.disabled" variant="success" class="text-white sm" size="sm"
                @click="createSuspend">Submit</b-button>
            </b-col>
          </b-row>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  props: ["usersubgroup_id"],
  async created() {
    this.subgroupCustomName = this.$store.getters.getCustomNamings.userSubgroup.custom_name ?? "Usersubgroup";
    await this.getSessions()
    await this.getSubgroupSuspends();
  },
  components: {},
  data() {
    return {
      fetchData: false,
      subgroupCustomName: '',
      resourceaction: 'list',
      actionButton: {
        text: "Submit",
        disabled: false,
      },
      // usersubgroups: [], // subgroups in holydays list 
      suspends: [], // for a usersubgroup
      allusersubgroups: [],
      suspend: { name: '', usersubgroup_id: [], date: null, session_id: [], date_type: null, start_date: null, upto_date: null },
      weekdayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      sessionObjects: {},
      dateSuspends: [],// for admon,hod
    };
  },
  methods: {
    // async removeHolydays(date, subgroupId) {
    //   const url =
    //     this.$store.getters.getAPIKey.mainAPI +
    //     `/usersubgroupholydays/delete`;
    //   await this.$axios
    //     .post(url, { date: date, usersubgroup: subgroupId })
    //     .then(() => {
    //       this.$toast.success("Holiday deleted successfully", {
    //         position: "top",
    //         duration: 3000,
    //       });
    //       this.getSubgroupHolydays();
    //     })
    //     .catch((error) => {
    //       error;
    //       this.$toast.error("Failed to deleted Holiday", {
    //         position: "top",
    //         duration: 3000,
    //       });
    //     });
    // },
    async createSuspend() {
      this.actionButton = {
        text: "Saving...",
        disabled: true,
      };
      // this.suspend.usersubgroup_id = [this.usersubgroup_id];
      // this.usersubgroup_id != null ? [this.usersubgroup_id] : this.selectedSubgroups.map(option => option.value)
      let url = this.$store.getters.getAPIKey.mainAPI + `/usersubgroupsuspendclasses`;
      await this.$axios.post(url, this.suspend)
        .then(() => {
          this.$toast.success("Class Suspended Successfully", {
            position: "top",
            duration: 3000,
          });
          this.actionButton = {
            text: "Save",
            disabled: false,
          };
          this.getSubgroupSuspends();
        })
        .catch((error) => {
          this.actionButton = {
            text: "Save",
            disabled: false,
          };
          let errortxt = "";
          if (error.response)
            if (error.response.status == 422) {
              let errormsgs = error.response.data.errors;
              for (const key in errormsgs) {
                errortxt = errormsgs[key][0];
              }
            } else {
              errortxt = error.response.data.message;
            }
          else {
            errortxt = "Failed to Create";
          }
          this.$toast.error(errortxt, {
            position: "top",
            duration: 3000,
          });
          return;
        });
    },
    async createSuspendForm() {
      if (this.allusersubgroups.length < 1 && this.usersubgroup_id == null) await this.getAllUsersubgroups();
      this.suspend = { name: '', usersubgroup_id: [], date: null, session_id: [], date_type: null, start_date: null, upto_date: null };
      if (this.usersubgroup_id != null) {
        this.suspend.usersubgroup_id = [this.usersubgroup_id];
      } else {
        this.suspend.usersubgroup_id = this.allusersubgroups.map(option => option.value);
      }
      this.resourceaction = 'create';
    },
    async getSubgroupSuspends() {
      this.resourceaction = "list";
      this.fetchData = true;
      this.suspends = [];
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usersubgroupsuspendclasses` + (this.usersubgroup_id != null ? `?usersubgroup=${this.usersubgroup_id}` : '');
      await this.$axios
        .get(url)
        .then((response) => {
          if (this.usersubgroup_id != null) {
            this.suspends = response.data;
          } else {
            this.dateSuspends = response.data;
          }
          this.fetchData = false;
        })
        .catch((error) => {
          error;
          this.fetchData = false;
        });
    },

    //   this.actionButton = {
    //     text: "Saving...",
    //     disabled: true,
    //   };
    //   const url =
    //     this.$store.getters.getAPIKey.mainAPI +
    //     `/usersubgroupholydays/${this.holydays[holyindex].id}`;
    //   await this.$axios
    //     .delete(url)
    //     .then(() => {
    //       this.$toast.success(this.subgroupCustomName + " removed from Holiday", {
    //         position: "top",
    //         duration: 3000,
    //       });
    //       this.holydays.splice(holyindex, 1)
    //     })
    //     .catch((error) => {
    //       error;
    //       this.$toast.error(this.subgroupCustomName + " failed to remove", {
    //         position: "top",
    //         duration: 3000,
    //       });
    //     });

    //   this.actionButton = {
    //     text: "Saving...",
    //     disabled: false,
    //   };
    // },

    async deleteSuspend(suspendindex) {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usersubgroupsuspendclasses/${this.suspends[suspendindex].id}`;
      await this.$axios
        .delete(url)
        .then(() => {
          this.$toast.success("Class suspension removed successfully", {
            position: "top",
            duration: 3000,
          });
          this.suspends.splice(suspendindex, 1)
        })
        .catch((error) => {
          error;
          this.$toast.error("Failed to removed suspension", {
            position: "top",
            duration: 3000,
          });
        });
    },

    async removeSuspend(date, sessions, subgroups) {
      const subgroupsIds = subgroups.map(item => item.id);
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usersubgroupsuspendclasses/delete`;
      await this.$axios
        .post(url, { date: date, sessions: sessions, usersubgroups: subgroupsIds })
        .then(() => {
          this.$toast.success("Class suspension removed successfully", {
            position: "top",
            duration: 3000,
          });
          this.getSubgroupSuspends();
        })
        .catch((error) => {
          error;
          this.$toast.error("Failed to removed suspension", {
            position: "top",
            duration: 3000,
          });
        });
    },


    // async getAllUsersubgroups() {
    //   const url =
    //     this.$store.getters.getAPIKey.mainAPI +
    //     `/institutionuser/usersubgroups`;
    //   await this.$axios
    //     .get(url)
    //     .then((response) => {
    //       let allusersubgroups = response.data;
    //       allusersubgroups.forEach(group => {
    //         this.allusersubgroups.push({
    //           text: group.code + ' ' + group.usergroup.code,
    //           value: group.id
    //         });
    //       });
    //     })
    //     .catch((error) => {
    //       error;
    //     });
    // },

    async getSessions() {
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/sessions`;
      await this.$axios
        .get(url)
        .then((response) => {
          let sessions = response.data;
          sessions.forEach(session => {
            this.sessionObjects[session.id] = session;
            // this.sessionOptions.push({
            //   text:
            // });
          });
        })
        .catch((error) => {
          error;
        });
    },

    async getAllUsersubgroups() {
      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/institutionuser/usersubgroups`;
      await this.$axios
        .get(url)
        .then((response) => {
          let allusersubgroups = response.data;
          allusersubgroups.forEach(group => {
            this.allusersubgroups.push({
              text: group.code + ' ' + group.usergroup.code,
              value: group.id
            });
          });
        })
        .catch((error) => {
          error;
        });

      this.fetchData = false;
    },
  },
};
</script>

<style >
.tag-button button {
  font-size: 9px !important;
  padding: 3px;
}
</style>
