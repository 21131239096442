<template>
  <div>
    <b-row>
      <b-col class="customcard">
        <!-- <b-card class="shadow  bg-white rounded" align="center">
                  <b-card-text
                    ></b-card-text
                  >
                </b-card> -->
        <div v-if="$root.default_academic_year[0] == '0'" style="min-height:120px"> Please select correct academic year
          to view data.</div>
        <div v-else style=" text-align: -webkit-center;">
          <b-modal id="dateSubgroupsMonitoringModel" hide-footer hide-header size="xl">
            <DateSubgroupsAttMonitoring :date="attendanceSummaryDate" />
          </b-modal>

          <b-row align-v="center">
            <b-col class="text-left col-auto">
              <button type="button" class="btn btn-outline-info btn-sm" @click="refreshDashboardCardsAttData(-1)">
                &lt;
              </button>
            </b-col>
            <b-col class="text-center px-0">
              <h5>
                {{
                    attendanceSummaryDate
                      ? `${this.attendanceSummaryDate.getDate()}/${this.attendanceSummaryDate.getMonth() +
                      1}/${this.attendanceSummaryDate.getFullYear()}`
                      : "Today's"
                }}
                Student Attendance Preview
              </h5>
            </b-col>
            <b-col class="text-right col-auto">
              <button type="button" class="btn btn-outline-info btn-sm" @click="refreshDashboardCardsAttData(1)">
                >
              </button>
            </b-col>
          </b-row>
          <b-row v-if="!attendanceSummary.dataLoaded" class="text-center" align-h="center" align-v="center">
            <b-spinner :variant="randomColor()"></b-spinner>
          </b-row>
          <!-- <b-card-text></b-card-text> -->
          <div class="mt-1" v-else v-b-modal.dateSubgroupsMonitoringModel v-on:mouseover="activeAttendanceCard = true"
            v-on:mouseleave="activeAttendanceCard = false">
            <b-row align-h="center" v-if="attendanceSummary.emptyAttendance">
              <span>No Attendance Marked</span>
            </b-row>
            <b-row v-else align-v="center" v-for="(session, sessionIndex) in attendanceSummary.sessions"
              :key="sessionIndex">
              <b-col cols="2" v-if="activeAttendanceCard">
                {{ session.name }}
              </b-col>
              <b-col>
                <b-progress style="background-image: linear-gradient(to right, #f4f6ffa6 , #f4cfb9a6) !important;"
                  :height="activeAttendanceCard ? '12px' : '4px'" class="m-1" :max="attendanceSummary.studentsCount"
                  v-b-tooltip.hover :title="
                    'Marked for students: ' +
                    attendanceSummary.sessionAttCounts[session.id].totel +
                    '/' +
                    attendanceSummary.studentsCount +
                    ', Present: ' +
                    attendanceSummary.sessionAttCounts[session.id].present +
                    '/' +
                    attendanceSummary.sessionAttCounts[session.id].totel +
                    ', Absent: ' +
                    (Number(
                      attendanceSummary.sessionAttCounts[session.id].totel
                    ) -
                      Number(
                        attendanceSummary.sessionAttCounts[session.id].present
                      )) +
                    '/' +
                    attendanceSummary.sessionAttCounts[session.id].totel +
                    ', Unmarked: ' +
                    (Number(attendanceSummary.studentsCount) -
                      Number(
                        attendanceSummary.sessionAttCounts[session.id].totel
                      ))
                  ">
                  <b-progress-bar :label="
                    activeAttendanceCard
                      ? `${(
                        (attendanceSummary.sessionAttCounts[session.id]
                          .present /
                          attendanceSummary.studentsCount) *
                        100
                      ).toFixed()}%`
                      : ''
                  " variant="success" :value="
  attendanceSummary.sessionAttCounts[session.id].present
"></b-progress-bar>
                  <b-progress-bar :label="
                    activeAttendanceCard
                      ? `${(
                        ((attendanceSummary.sessionAttCounts[session.id]
                          .totel -
                          attendanceSummary.sessionAttCounts[session.id]
                            .present) /
                          attendanceSummary.studentsCount) *
                        100
                      ).toFixed()}%`
                      : ''
                  " variant="danger" :value="
  attendanceSummary.sessionAttCounts[session.id].totel -
  attendanceSummary.sessionAttCounts[session.id].present
"></b-progress-bar>
                </b-progress>
                <!-- <b-progress
                            height="3px"
                            :value="
                              attendanceSummary.sessionAttCounts[session.id]
                                .totel / attendanceSummary.studentsCount
                            "
                          ></b-progress> -->
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
      <!-- <b-col class="customcard">
        <div style=" text-align: -webkit-center;">
          <b-row align-h="center">
            <h5>Today's Attendance Marked by Faculties</h5>
          </b-row>
          <b-row v-if="!attendanceSummary.dataLoaded" class="text-center" align-h="center" align-v="center">
            <b-spinner :variant="randomColor()"></b-spinner>
          </b-row>
          <b-row v-else>
            <b-col>
              <b-row align-v="center" v-for="(session, sessionIndex) in attendanceSummary.sessions" :key="sessionIndex">
                <b-col>
                  <b-progress height="4px" class="m-1" :max="
                    attendanceSummary.sessionAttFacultyCounts[session.id]
                      .totel
                  " v-b-tooltip.hover :title="
                    'Marked by faculty:' +
                      attendanceSummary.sessionAttFacultyCounts[session.id]
                        .marked +
                      '/' +
                      attendanceSummary.sessionAttFacultyCounts[session.id]
                        .totel
                  " :value="
                    attendanceSummary.sessionAttFacultyCounts[session.id]
                      .marked
                  "></b-progress>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </b-col> -->
      <b-col class="customcard">
        <div v-if="$root.default_academic_year[0] == '0'" style="min-height:120px"> Please select correct academic year
          to view data.</div>
        <div v-else style=" text-align: -webkit-center; padding: 6px;">
          <b-row align-h="center">
            <h5>Warnings and Errors Preview</h5>
          </b-row>

          <b-row v-if="instiConfigErrorsLoading" class="text-center" align-h="center" align-v="center">
            <b-spinner :variant="randomColor()"></b-spinner>
          </b-row>
          <b-row v-else>
            <b-col class="p-0">
              <div v-b-modal.instiConfigErrorsModel v-b-tooltip.hover
                :title="'You have ' + instiConfigErrors.length + ' errors'" class="circle circle-error">
                {{
                    instiConfigErrors.length > 0 ? instiConfigErrors.length : "✓"
                }}
              </div>
            </b-col>
            <b-col class="p-0">
              <div v-b-modal.instiConfigErrorsModel v-b-tooltip.hover
                :title="'You have ' + instiConfigWarnings.length + ' warnings'" class="circle circle-warning">
                {{
                    instiConfigWarnings.length > 0
                      ? instiConfigWarnings.length
                      : "✓"
                }}
              </div>
            </b-col>
            <!-- <b-col class="p-0">
              <div v-b-tooltip.hover :title="'Tou have ' + 0 + ' messages'"
                :class="0 <1 ? 'circle circle-gray' :'circle circle-info'">
                0
              </div>
            </b-col> -->
            <b-col class="p-0">
              <div v-b-tooltip.hover :title="
                instiConfigErrors.length < 1
                  ? 'Everything done'
                  : 'Please clear errors & warnings'
              " :class="
  instiConfigErrors.length < 1
    ? 'circle circle-success'
    : 'circle circle-gray'
">
                ✓
              </div>
            </b-col>
          </b-row>
        </div>
        <b-modal id="instiConfigErrorsModel" hide-footer hide-header size="lg">
          <table class="table  table-striped table-bordered">
            <tr>
              <th>
                SI. No.
              </th>
              <th>
                Description
              </th>
            </tr>
            <tr v-if="instiConfigErrors.length">
              <td colspan="2" style="background-color: #e54646;">Errors</td>
            </tr>
            <tr v-for="(instiConfigError, errorindex) in instiConfigErrors" :key="'error' + errorindex">
              <td>{{ errorindex + 1 }}</td>
              <td>
                {{ instiConfigError }}
              </td>
            </tr>

            <tr v-if="instiConfigWarnings.length">
              <td colspan="2" style="background-color:  #e3c622;">Warnings</td>
            </tr>
            <tr v-for="(instiConfigWarning, warningindex) in instiConfigWarnings" :key="'waring' + warningindex">
              <td>{{ warningindex + 1 }}</td>
              <td>
                {{ instiConfigWarning }}
              </td>
            </tr>
          </table>
        </b-modal>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// import acl from "../../authorization/acl.js";
import DateSubgroupsAttMonitoring from "../templates/Dashboard/attendence/monitoring/dateSubgroupsAttMonitoring.vue";
export default {
  components: {
    DateSubgroupsAttMonitoring,
  },
  props: [],
  async created() {
    // this.instituitionAdminRole = await acl.isUserGranted(
    //   "editInstituitionSettings"
    // );
    setTimeout(
      function () {
        this.getDashboardCardsAttData();
        this.getDashboardCardsConfigAlerts();
      }.bind(this),
      3000
    );
  },
  data() {
    return {
      // instituitionAdminRole: false,
      activeAttendanceCard: false,
      attendanceSummaryDate: null,
      attendanceSummary: {
        dataLoaded: false,
        studentsCount: 0,
        sessions: [],
        sessionAttCounts: {}, //{27:{totel: 0, present: 0},}
        sessionAttFacultyCounts: {}, //{27:{totel: 0, marked: 0},}
        emptyAttendance: true,
      },
      instiConfigErrorsLoading: true,
      instiConfigErrors: [],
      instiConfigWarnings: [],
    };
  },
  methods: {
    refreshDashboardCardsAttData(day) {
      if (!this.attendanceSummaryDate) this.attendanceSummaryDate = new Date();
      this.attendanceSummaryDate.setDate(
        this.attendanceSummaryDate.getDate() + day
      );
      this.getDashboardCardsAttData();
    },
    async getDashboardCardsAttData() {
      this.attendanceSummary.dataLoaded = false;
      this.attendanceSummary.emptyAttendance = true;
      let dateString = "";
      if (this.attendanceSummaryDate)
        dateString = `${this.attendanceSummaryDate.getFullYear()}/${this.attendanceSummaryDate.getMonth() +
          1}/${this.attendanceSummaryDate.getDate()}`;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/attendancereports/attendancesummary/count` +
        (this.attendanceSummaryDate ? `?date=${dateString}` : "");
      await this.$axios
        .get(url)
        .then((response) => {
          // console.log(response.data);
          this.attendanceSummary.studentsCount = response.data.studentsCount;
          this.attendanceSummary.sessions = response.data.sessions;
          this.attendanceSummary.sessionAttCounts =
            response.data.sessionAttCounts;
          this.attendanceSummary.sessionAttFacultyCounts =
            response.data.sessionAttFacultyCounts;

          for (const key in this.attendanceSummary.sessionAttCounts) {
            if (this.attendanceSummary.sessionAttCounts[key].totel > 0) this.attendanceSummary.emptyAttendance = false;
          }
          this.attendanceSummary.dataLoaded = true;
        })
        .catch(() => { });
    },

    async getDashboardCardsConfigAlerts() {
      this.instiConfigErrorsLoading = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/institution/institutionuser/configuration/alerts`;
      await this.$axios
        .get(url)
        .then((response) => {
          // console.log(response.data);
          this.instiConfigErrors = response.data.errors;
          this.instiConfigWarnings = response.data.warnings;
          this.instiConfigErrorsLoading = false;
        })
        .catch(() => {
          this.instiConfigErrorsLoading = false;
        });
    },

    randomColor() {
      return [
        "primary",
        "secondary",
        "danger",
        "warning",
        "success",
        "info",
        "light",
        "dark",
      ].sort(() => 0.5 - Math.random())[0];
    },
  },
};
</script>

<style >
.customcard {
  padding: 10px;
  margin: 7px;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0.75rem;
  box-shadow: 0 0 2rem 1rem #8392a426;
}

.circle {
  margin: 3px;
  width: 70px;
  line-height: 50px;
  border-radius: 50%;
  text-align: center;
  font-size: 30px !important;
  font-weight: 900;
}

.circle-error {
  border: 10px solid rgb(187, 11, 11);
}

.circle-warning {
  border: 10px solid rgb(213, 183, 12);
}

.circle-info {
  border: 10px solid rgb(27, 91, 173);
}

.circle-success {
  border: 10px solid rgb(11, 162, 57);
}

.circle-gray {
  border: 10px solid rgb(202, 208, 204);
  color: rgb(200, 207, 202);
}
</style>
