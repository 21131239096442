<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="liveClassReportForm p-4" v-else>
      <b-overlay
        :show="loadData"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="md"
      >
        <b-form class="border-0" @submit.prevent="getLiveClassReport()">
          <b-form-group>
            <b-form-select
              :options="userSubgroupsOptions"
              v-model="liveClass.subgroup"
            ></b-form-select>
          </b-form-group>
          <b-form-group>
            <b-row>
              <b-col md="6">
                <label for="example-datepickerfrom">From:</label>
                <b-form-datepicker
                  id="example-datepickerfrom"
                  v-model="date_from"
                  class="mb-2"
                ></b-form-datepicker>
              </b-col>
              <b-col md="6">
                <label for="example-datepickerto">To:</label>
                <b-form-datepicker
                  id="example-datepickerto"
                  v-model="date_upto"
                  class="mb-2"
                ></b-form-datepicker>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="liveClass.subgroup != null && liveClass.reportDate"
          >
            <b-button type="submit" variant="primary" size="sm"
              >Generate Report</b-button
            >
          </b-form-group>
        </b-form>
        <b-modal
          id="liveClassReport"
          no-close-on-backdrop
          no-close-on-keyboard
          no-close-on-esc
          hide-footer
          modal-class="courseReport"
          :aria-hidden="true"
          :tabindex="-1"
        >
          <LiveClassReport
            :liveClass="liveClass"
            :liveClassReport="liveClassReport"
          />
        </b-modal>
      </b-overlay>
    </div>
  </div>
</template>

<script>
// import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import LiveClassReport from "./LiveClassReport.vue";
import moment from "moment";
export default {
  async created() {
    this.fetchData = true;
    this.getUserSubgroups();
    this.$set(this.liveClass, "subgroup", null);
  },
  data() {
    return {
      fetchData: false,
      userSubgroupsOptions: [],
      liveClass: {},
      date_from: null,
      date_upto: null,
      loadData: false,
      liveClassReport: [],
    };
  },
  components: {
    // DatePicker,
    LiveClassReport,
  },
  computed: {
    getCustomNames() {
      return this.$store.getters.getCustomNamings;
    },
  },
  methods: {
    async getLiveClassReport() {
      this.loadData = true;
      const postData = {
        start_date: this.date_from,
        upto_date: this.date_upto,
      };
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usersubgroup/${this.liveClass.subgroup.id}/googleevents/reports`;
      await this.$axios
        .post(url, postData)
        .then((response) => {
          this.liveClassReport = [];
          this.liveClassReport = response.data;
          this.liveClassReport.forEach((report) => {
            let start = moment(report.start_datetime);
            let end = moment(report.end_datetime);
            report.start_datetime = start;
            report.end_datetime = end;
          });
          this.$bvModal.show("liveClassReport");
          response;
        })
        .catch((error) => {
          error;
        });
      this.loadData = false;
    },
    async getUserSubgroups() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        "/institutionuser/usersubgroups";
      await this.$axios
        .get(url)
        .then((response) => {
          this.userSubgroupsOptions = [
            {
              value: null,
              text:
                this.getCustomNames.userSubgroup.custom_name != null
                  ? "Select " + this.getCustomNames.userSubgroup.custom_name
                  : "Select Usersubgroup",
            },
          ];
          const subgroups = response.data;
          subgroups.forEach((subgroup) => {
            this.userSubgroupsOptions.push({
              value: {
                id: subgroup.id,
                name: subgroup.name + " - " + subgroup.usergroup.name,
              },
              text: subgroup.name,
            });
          });
          response;
        })
        .catch((error) => {
          error;
        });
      this.fetchData = false;
    },
  },
};
</script>

<style >
.courseReport .modal-dialog {
  max-width: 100%;
  max-height: 100vh;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  position: fixed;
  z-index: 100000;
}
.courseReport .modal-body {
  overflow: scroll;
}
</style>
