<template>
  <div>
    <b-overlay :show="deleteAdmin" spinner-variant="primary" spinner-type="grow" spinner-small rounded="md">

      <div class="existingAdmins">
        <div class="admins">
          <span v-for="({ id, name, institution_user_id, enroll_status }, adminIndex) in admins" :key="adminIndex">
            <span :class="{ 'text-danger': enroll_status != 'active' }">{{ name }} </span>
            <span class="text-danger btn p-0 m-0 ml-1"
              @click="deleteUsergroupUser(id, adminIndex, institution_user_id)">
              <i class="fas fa-trash"></i>
            </span>
          </span>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
export default {
  props: ["groupInfo"],
  created() {
    this.groupInfo.admins.forEach((admin) => {
      this.admins.push({
        name: admin.first_name.concat(" ", admin.last_name),
        id: admin.id,
        institution_user_id: admin.pivot.institution_user_id,
        enroll_status: admin.enroll_status,
      });
    });
  },
  data() {
    return {
      admins: [],
      deleteAdmin: false,
    };
  },
  methods: {
    async deleteUsergroupUser(
      user_id,
      deletedElementFromArray,
      institution_user_id
    ) {
      this.deleteAdmin = true;
      let url;
      if (this.groupInfo.group == true)
        url = this.$store.getters.getAPIKey.deleteGroupUser.replace(
          "usergroup_user_id",
          user_id
        );
      else if (this.groupInfo.group == false) {
        url = this.$store.getters.getAPIKey.deleteGroupUser.replace(
          "usersubgroup_user_id",
          user_id
        );
      } else {
        url = this.$store.getters.getAPIKey.mainAPI + `/maingroupusers/${user_id}`;
      }
      await this.$axios
        .delete(url)
        .then((response) => {
          this.admins.splice(deletedElementFromArray, 1);
          this.$emit("updateAdminsList", institution_user_id);
          this.$toast.success("Admin Successfully Removed", {
            position: "top",
            duration: 3000,
          });
          response;
        })
        .catch((error) => {
          error;
        });
      this.deleteAdmin = false;
    },
  },
};
</script>

<style >
.existingAdmins .admins {
  display: flex;
  flex-wrap: wrap;
}

.existingAdmins .admins span {
  background-color: #4285F4;
  color: #ffffff;
  margin: 5px;
  padding: 4px;
  border-radius: 10px;
  font-size: 12px !important;
  display: flex;
  align-items: center;
}

.existingAdmins .admins span:first-child {
  margin-left: 0px;
}

.existingAdmins i {
  font-size: 12px !important;
}
</style>
