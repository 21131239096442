<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="groupUsers" v-else>
      <b-overlay :show="loadData" spinner-variant="primary" spinner-type="grow" spinner-small rounded="md">
        <div class="addFaculty" v-if="createFaculty">
          <AddGroupUser :groupModal="editInfo" :listOfUsersEnrolled="listOfUsersEnrolled"
            @addFacultyStatus="addFacultyStatus" />
        </div>
        <div v-else>
          <div class="export d-flex justify-content-between mb-2">
            <b-button variant="primary" v-if="groupUsersToDelete.length == 0" size="sm"
              @click="createFaculty = !createFaculty">Add Faculty To Department</b-button>
            <b-button variant="danger" v-else size="sm" :disabled="removeBtn.disabled" @click="deleteEnrolledUser()">{{
                removeBtn.text
            }}</b-button>
            <b-button variant="success" size="sm" :disabled="
              exportCsvBtn.disabled ||
              listOfUsersEnrolled.length == 0 ||
              groupUsersToDelete.length != 0
            " @click="exportToExcel('xlsx')">{{ exportCsvBtn.text }}</b-button>
          </div>
          <table class="table table-striped table-bordered" ref="groupUsers">
            <thead class="thead-dark">
              <tr>
                <th></th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Course reports</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="listOfUsersEnrolled.length == 0">
                <td colspan="100" class="text-center text-danger">
                  No Users enrolled Yet!
                </td>
              </tr>
              <tr v-else v-for="(user, index) in listOfUsersEnrolled" :key="index">
                <td class="align-middle">
                  <b-form-checkbox v-model="groupUsersToDelete" :value="user.id"></b-form-checkbox>
                </td>
                <td :class="{ 'align-middle': true, 'text-danger': user.enroll_status != 'active' }">
                  {{ user.first_name }} {{ user.last_name || " " }}
                </td>
                <td class="align-middle">{{ user.email }}</td>
                <td class="align-middle">{{ user.mobile }}</td>
                <td>
                  <span v-if="!facultyCourses[user.institution_user_id].loaded">Loading...</span>
                  <span v-else v-for="(course, cindex) in facultyCourses[
                    user.institution_user_id
                  ].courses" :key="cindex">
                    <button @click="
                      opencourseReportsModel(course, user.institution_user_id)
                    ">
                      {{ course.code }}
                    </button>
                    &nbsp;
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-overlay>
    </div>

    <b-modal v-if="selectedFacultyId != null" id="courseReports" :title="selectedCourse.name" no-close-on-backdrop
      no-close-on-keyboard no-close-on-esc hide-footer centered size="lg" modal-class="courseReports">
      <CourseReports :getCustomNames="getCustomNames" :courseReport="selectedCourse"
        :institutionUserId="selectedFacultyId" />
    </b-modal>
  </div>
</template>

<script>
import XLSX from "xlsx";
import AddGroupUser from "./AddGroupUser.vue";
import CourseReports from "../Course/CourseReports.vue";
export default {
  props: ["editInfo"],
  async created() {
    this.fetchData = true;
    await this.getEnrolledGroupUsers();
    this.listOfUsersEnrolled.forEach((user) => {
      this.$set(this.facultyCourses, user.institution_user_id, {
        courses: [],
        loaded: false,
      });

      // this.$set(this.facultyCourses[user.id], courses, []);
      // this.$set(this.facultyCourses[user.id], loaded, false);
    });
    this.fetchData = false;
    this.getfacultyCourses();
  },
  components: {
    AddGroupUser,
    CourseReports,
  },
  data() {
    return {
      listOfUsersEnrolled: [],
      fetchData: false,
      loadData: false,
      groupUsersToDelete: [],
      createFaculty: false,
      exportCsvBtn: {
        text: "Export to Excel",
        disabled: false,
      },
      removeBtn: {
        text: "Remove",
        disabled: false,
      },
      facultyCourses: {}, // faculty id: loaded:false, courses:[]
      selectedCourse: null,
      selectedFacultyId: null,
    };
  },
  methods: {
    async opencourseReportsModel(course, facultyId) {
      this.selectedCourse = course;
      this.selectedFacultyId = facultyId;
      this.$bvModal.show("courseReports");
      this.$bvModal.show("courseReports");
    },
    getCustomNames() {
      return this.$store.getters.getCustomNamings;
    },
    getfacultyCourses() {
      this.listOfUsersEnrolled.forEach((user) => {
        const url =
          this.$store.getters.getAPIKey.mainAPI +
          `/institutionusers/${user.institution_user_id}/courses`;
        this.$axios
          .get(url)
          .then((response) => {
            var courses = response.data;
            courses.forEach((course) => {
              this.facultyCourses[user.institution_user_id].courses.push({
                course_id: course.id,
                usergroup_id: course.usersubgroup.usergroup_id,
                usersubgroup_id: course.usersubgroup_id,
                name: course.name,
                code: course.code,
              });
            });
            this.facultyCourses[user.institution_user_id].loaded = true;
          })
          .catch(() => {
            this.facultyCourses[user.institution_user_id].loaded = true;
          });
      });
    },
    async getEnrolledGroupUsers() {
      // this.fetchData = true;
      // let userList = this.$store.getters.getAPIKey.getEnrollUsersList;
      // const url = userList.replace("usersubgroup_id", this.editInfo.id);
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/maingroups/${this.editInfo.id}/users` +
        `?role=2`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.listOfUsersEnrolled = [];
          this.listOfUsersEnrolled = response.data;
          // this.fetchData = false;
          response;
        })
        .catch((error) => {
          error;
          // this.fetchData = false;
        });
    },
    async deleteEnrolledUser() {
      this.loadData = true;

      const users = {
        maingroupusers: this.groupUsersToDelete,
      };
      // let userId = this.$store.getters.getAPIKey.deleteGroupUser;
      // url = userId.replace("usergroup_user_id", this.groupUsersToDelete[user]);
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/maingroupusers/delete`;

      await this.$axios
        .post(url, users)
        .then((response) => {
          this.$emit("addFacultyStatus");
          response;
        })
        .catch((error) => {
          error;
        });

      await this.getEnrolledGroupUsers();
      this.loadData = false;
    },

    async exportToExcel(type, fn, dl) {
      this.exportCsvBtn.text = "Please wait...";
      this.exportCsvBtn.disabled = true;
      var elt = this.$refs.groupUsers;
      var wb = await XLSX.utils.table_to_book(elt, { sheet: "Sheet JS" });
      this.exportCsvBtn.text = "Export to Excel";
      this.exportCsvBtn.disabled = false;
      return dl
        ? XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" })
        : XLSX.writeFile(
          wb,
          fn || this.editInfo.name + "_users." + (type || "xlsx")
        );
    },
    addFacultyStatus(status) {
      if (status) {
        this.createFaculty = !this.createFaculty;
        this.$emit("addFacultyStatus");
        this.getEnrolledGroupUsers();
      } else {
        this.createFaculty = !this.createFaculty;
      }
    },
  },
};
</script>
