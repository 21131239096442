<template>
  <div class="container">
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div v-if="!fetchData">
      <div>
        <b-row>
          <b-col sm="12">
            <b-form-group>
              <div class="" style="text-align:right;">
                <div>
                  <b-button v-if="!editMode" @click="editMode = true" type="submit" variant="primary" size="md">Edit
                  </b-button>
                </div>
              </div>
            </b-form-group>

            <table class="table  table-striped table-bordered">
              <tr>
                <th>
                  Planned No. of hours
                </th>
                <th>
                  <b-form-input v-if="editMode" placeholder="No. of hours" type="number" v-model="planned_houres">
                  </b-form-input>
                  <span v-else>
                    {{ planned_houres }}
                  </span>
                </th>
              </tr>
            </table>

            <table class="table  table-striped table-bordered">
              <tr>
                <th>
                  SI. No.
                </th>
                <th>
                  Name
                </th>
                <th>
                  Limitting %
                </th>
              </tr>
              <tbody v-for="(atttype, reindex) in attendancetypes" :key="reindex">
                <tr v-if="['Present','Absent'].includes(atttype.name)">
                </tr>
                <tr v-else>

                  <td>{{ reindex + 1 }}</td>
                  <td>
                    {{ atttype.name }}
                  </td>
                  <td>
                    <b-form-input v-if="editMode" placeholder="Percentage" type="number" max="100"
                      v-model="attendanceTypeLimits[atttype.id]"></b-form-input>

                    <span v-if="!editMode">
                      {{ attendanceTypeLimits[atttype.id] }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>

            <b-form-group>
              <div class="" style="text-align:right;">
                <div>
                  <b-button v-if="editMode" @click="updateCourse()" type="submit" variant="primary" size="md"
                    :disabled="additionalBtn.disabled">{{ additionalBtn.text }}</b-button>
                </div>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["courseInfo"],
  async created() {
    this.fetchData = true;

    await this.$set(this.course, "name", this.courseInfo.name);
    await this.$set(this.course, "code", this.courseInfo.code);
    this.$set(
      this.course,
      "attendance_type_limits",
      this.courseInfo.property.attendance_type_limits
    );
    this.planned_houres = this.courseInfo.property.planned_houres;
    await this.getAttendanceTypes();

    this.fetchData = false;
  },
  data() {
    return {
      fetchData: true,
      course: {},
      editMode: false,
      attendancetypes: [],
      attendanceTypeLimits: {},
      planned_houres: null,
      additionalBtn: {
        text: "Submit",
        disabled: false,
      },
    };
  },
  methods: {
    async updateCourse() {
      this.additionalBtn.text = "Please Wait...";
      this.additionalBtn.disabled = true;
      this.course.attendance_type_limits = this.attendanceTypeLimits;
      this.course.planned_houres = this.planned_houres;
      const url =
        this.$store.getters.getAPIKey.updateCourse.replace(
          "course_id",
          this.courseInfo.id
        ) + `?course_property=1`;

      await this.$axios
        .put(url, this.course)
        .then(() => {
          this.$toast.success("Updated successfully", {
            position: "top",
            duration: 3000,
          });
          this.editMode = false;
          this.$emit("CourseUpdated");
        })
        .catch(() => {
          this.$toast.error("Failed to update", {
            position: "top",
            duration: 3000,
          });
        });
      this.additionalBtn.text = "Submit";
      this.additionalBtn.disabled = false;
    },

    async getAttendanceTypes() {
      // this.fetchData = true;
      const url = this.$store.getters.getAPIKey.attendenceType;
      await this.$axios.get(url).then((response) => {
        let attendanceTypes = response.data;
        attendanceTypes.forEach((attendenceType) => {
          this.attendancetypes.push({
            id: attendenceType.id,
            name: attendenceType.name,
          });
          this.attendanceTypeLimits[attendenceType.id] = null;
          if (this.course.attendance_type_limits) {
            if (attendenceType.id in this.course.attendance_type_limits) {
              this.attendanceTypeLimits[
                attendenceType.id
              ] = this.course.attendance_type_limits[attendenceType.id];
            }
          }
          // this.attendanceTypesObjects[attendenceType.id] = attendenceType;
        });
      });
      // return attendanceTypes;
    },
  },
};
</script>
