<template>
  <div>
    <div class="textBook">
      <br>
      <b-button variant="info" @click="$bvModal.show('AddActivityModel')">
        <i class="fas fa-plus-square text-white"></i>Add Activity Name</b-button>
      <b-modal id="AddActivityModel" no-close-on-backdrop no-close-on-keyboard no-close-on-esc hide-footer centered
        size="md">
        <div class="" style="border: 2px solid #17a2b8; padding: 15px; border-radius: 5px;">
          <b-form-group>
            <label>Activity Name:</label>
            <b-form-input style="width:400px" placeholder="Enter Activity name"
              v-model="activity_name"></b-form-input></b-form-group>
          <span style="color:green" v-if="saveactivity">Activity Saving ......</span>
          <b-button variant="info" class="m-2"
            @click="$bvModal.hide('AddActivityModel'); saveActivity()">Save</b-button>
        </div>
      </b-modal>
      <b-form-checkbox v-if="activities.length > 0" @change=updateActivitySettings() :value="1"
        v-model="usergroup.disable_manual_activity_name">
        <b>Allow users to choose activity name from below list only</b>
      </b-form-checkbox>
      <table class="table  table-striped table-bordered">
        <tr>
          <th>Sl No</th>
          <th>Activity Name</th>
          <th></th>
        </tr>
        <tr v-for="(activity, aindex) in activities" :key="aindex">
          <td>{{ aindex + 1 }}</td>
          <td><span v-if="!enableEditActivity[activity.id]">
              {{ activity.activity_name }}
            </span>
            <b-form-input v-else style="width:400px" v-model="activity.activity_name"></b-form-input>
          </td>
          <td>
            <b-button variant="info" v-if="editbutton=='Edit'" @click="enableEdit(activity)">{{ editbutton }}</b-button>
            <b-button v-if="enableEditActivity[activity.id]" variant="info" @click="enableEdit(activity)">{{ editbutton }}</b-button>
            <b-button variant="danger" class="test-danger" @click="deleteActivity(activity.id)">Delete</b-button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
//import CopyCourseProperties from "./CopyCourseProperties.vue"
export default {
  props: ["usergroup_id"],
  async created() {
    await this.getActivity();
    await this.getUsergroup();
  },
  components: {
    // CopyCourseProperties,
  },
  data() {
    return {
      activities: {},
      saveactivity: false,
      enableEditActivity: {},
      activity_name: '',
      copy_from: '',
      editbutton: 'Edit',
      usergroup: []
    };
  },
  methods: {
    async getUsergroup(){
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usergroups/${this.usergroup_id}`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.usergroup = response.data;
        })
        .catch((error) => {
          error;
        });
    },
    async enableEdit(activity) {
      this.enableEditActivity[activity.id] = true;
      if (this.editbutton == 'Edit') {
        this.editbutton = 'Save';
      }
      else {
        this.editActivity(activity);
      }
    },
    async editActivity(activity) {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/activitysettings/${activity.id}`;
      axios
        .put(url, activity)
        .then(() => {
          this.$toast.success("Activity Updated", {
            position: "top",
            duration: 3000,
          });
          this.getActivity();
          this.enableEditActivity[activity.id] = false
          this.editbutton = 'Edit';
          // this.fetchData = false;
        })
        .catch(() => {
          this.$toast.error("Failed to update", {
            position: "top",
            duration: 3000,
          });
        });
    },
    updateActivitySettings() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usergroups/${this.usergroup_id}`;
      axios
        .put(url,this.usergroup)
        .then(() => {
          if(this.activities.length>0){
            this.$toast.success("Updated", {
            position: "top",
            duration: 3000,
          });
          } 
        })
        .catch(() => {
          this.$toast.error("Failed to update", {
            position: "top",
            duration: 3000,
          });
        });
    },
    async deleteActivity(activity_id) {
      if (confirm("Are you sure you want to delete this Activity?")) {
        const url =
          this.$store.getters.getAPIKey.mainAPI + `/activitysettings/${activity_id}`;
        await this.$axios
          .delete(url)
          .then(() => {
            this.$toast.success("Activity Deleted", {
              position: "top",
              duration: 3000,
            });
            this.getActivity();
            // this.fetchData = false;
          })
          .catch(() => {
            this.$toast.error(
              "Failed to delete",
              {
                position: "top",
                duration: 3000,
              }
            );
          });
      }
    },
    async saveActivity() {
      this.saveactivity = true;
      const url = this.$store.getters.getAPIKey.mainAPI + `/activitysettings`;
      await this.$axios
        .post(url, {
          usergroup_id: this.usergroup_id,
          activity_name: this.activity_name
        })
        .then((response) => {
          response;
          this.activity_name = null;
          this.saveactivity = false;
          this.$toast.success("Activity Saved", {
            position: "top",
            duration: 3000,
          });
          this.getActivity();
        })
        .catch((error) => {
          error;
        });
    },
    async getActivity() {
      const url = this.$store.getters.getAPIKey.mainAPI + `/activitysettings?usergroup_id=${this.usergroup_id}`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.activities = response.data;
          this.enableEditActivity = {};
          this.activities.forEach(activity => {
            this.enableEditActivity[activity.id] = false;
          });
          if(this.activities.length == 0){
            this.usergroup.disable_manual_activity_name = 0;
            this.updateActivitySettings();
          }
        })
        .catch((error) => {
          error;
        });
    }
  },
};
</script>

<style>
.textBook .ltp_credit {
  width: 25%;
  display: flex;
  justify-content: space-between;
}

.textBook .ltp_credit input {
  width: 150px;
  text-align: center;
}
</style>