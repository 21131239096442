<template>
  <div>
    <div class="obe">
      <InstitutionSettingsDelivery />
    </div>
  </div>
</template>

<script>
import InstitutionSettingsDelivery from "./InstitutionSettingsDelivery.vue";

// import SettingsProgramOutcome from "./SettingsProgramOutcome.vue";

export default {
  components: {
    InstitutionSettingsDelivery,
    // SettingsProgramOutcome,
  },
};
</script>
