<template>
  <div>
    <div class="createModule">
      <b-form @submit.prevent="prepareGroupCreation()">
        <b-form-group>
          <h3 class="text-center font-weight-bolder text-info">
            {{ groupInfo.mainGroup ? "Edit" : "Create" }}
            {{
                getCustomNames.mainGroup.custom_name != null
                  ? getCustomNames.mainGroup.custom_name
                  : "Maingroup"
            }}
          </h3>
        </b-form-group>
        <b-form-group v-if="groupInfo.mainGroup">
          <DisplayExistingAdmins @updateAdminsList="adminDeleted" :groupInfo="groupInfo" />
        </b-form-group>
        <b-form-group>
          <b-form-input :placeholder="
            getCustomNames.mainGroup.custom_name != null
              ? getCustomNames.mainGroup.custom_name + ' Name'
              : 'Maingroup Name'
          " v-model="group.name" required></b-form-input>
        </b-form-group>
        <b-form-group>
          <b-form-input :placeholder="
            getCustomNames.mainGroup.custom_name != null
              ? getCustomNames.mainGroup.custom_name + ' Code'
              : 'Maingroup Code'
          " v-model="group.code" required></b-form-input>
        </b-form-group>
        <b-form-group>
          <multiselect v-model="group.institution_users" tag-placeholder="Add this as new tag" :placeholder="
            getCustomNames.maingroupAdmin.custom_name != null
              ? 'Add ' + getCustomNames.maingroupAdmin.custom_name
              : 'Add Maingroup Name'
          " label="name" track-by="code" :options="adminsOptions" :multiple="true" :taggable="true"
            :close-on-select="false" @tag="addTag"></multiselect>
        </b-form-group>
        <b-form-group>
          <div class="d-flex justify-content-between">
            <b-button type="submit" variant="primary" :disabled="groupButton.disabled">{{ groupButton.text }}</b-button>
            <b-button @click="$emit('mainGroupCreated', false)" variant="danger"
              :disabled="groupButton.disabled">Cancel</b-button>
          </div>
        </b-form-group>
      </b-form>
    </div>
  </div>
</template>

<script>
import DisplayExistingAdmins from "./DisplayExistingAdmins";
export default {
  props: ["adminList", "existingAdmins", "groupInfo", "getCustomNames"],
  async created() {
    if (this.groupInfo.mainGroup) {
      this.group = this.groupInfo.mainGroup;
    }
    this.$set(this.group, "institution_users", []);
    if (this.groupInfo.admins && this.groupInfo.admins.length != 0) {
      await this.groupInfo.admins.forEach((admin) => {
        this.$set(
          this.existingAdminsObj,
          admin.pivot.institution_user_id,
          true
        );
      });
    }
    this.getFaculties();
  },
  components: {
    DisplayExistingAdmins,
  },
  data() {
    return {
      group: {},
      adminsOptions: [],
      groupButton: {
        text: "Submit",
        disabled: false,
      },
      adminsObject: {},
      existingAdminsObj: {},
    };
  },
  methods: {
    async prepareGroupCreation() {
      this.groupButton = {
        text: "Please wait...",
        disabled: true,
      };

      const groupData = {
        name: this.group.name,
        code: this.group.code,
      };

      const createGroup = await this.createUsergroup(groupData);
      const admins = {
        institution_users: [],
        maingroup_id: createGroup.id,
        maingrouprole_id: 1,
      };
      if (this.group.institution_users.length != 0)
        await this.group.institution_users.forEach((admin) =>
          admins.institution_users.push(admin.id)
        );
      if (createGroup.status) {
        if (admins.institution_users.length != 0) {
          this.$toast.info("Assigning admins....", {
            position: "top-right",
            duration: 3000,
          });
          await this.assignUsergroupAdmins(admins, createGroup.id);
        }
        this.$toast.success(`${this.group.name} is successfully created.`, {
          position: "top",
          duration: 3000,
        });
        this.handleResponse(true);
      }
      if (!createGroup.status) {
        this.handleResponse(false);
      }
    },
    async createUsergroup(postData) {
      let url;
      let method;
      if (this.groupInfo.mainGroup) {
        method = "put";
        url =
          this.$store.getters.getAPIKey.mainAPI +
          `/maingroups/${this.groupInfo.mainGroup.id}`;
      } else {
        method = "post";
        url = this.$store.getters.getAPIKey.mainAPI + `/maingroups`;
      }

      const createGroup = await this.$axios[method](url, postData)
        .then((res) => {
          return {
            status: true,
            id: res.data.id,
          };
        })
        .catch((err) => {
          return {
            status: false,
            err: err.response.message,
          };
        });
      return createGroup;
    },
    async assignUsergroupAdmins(postData, groupId) {
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/maingroups/${groupId}/users`;
      await this.$axios.put(url, postData);
    },
    handleResponse(status) {
      this.groupButton = {
        text: status ? "Created" : "Submit",
        disabled: status ? true : false,
      };
      if (status) {
        this.$emit("mainGroupCreated", true);
      }
    },
    async getFaculties() {
      await this.adminList.forEach((admin) => {
        if (
          !this.existingAdminsObj[admin.id] ||
          this.existingAdminsObj[admin.id] == undefined
        ) {
          if (admin.enroll_status == 'active') this.adminsOptions.push({
            name: admin.first_name.concat(" ", admin.last_name) + `(${admin.email})`,
            id: admin.id,
            code: admin.id,
          });
        }
      });
    },
    adminDeleted(id) {
      this.existingAdminsObj[id] = false;
      this.getFaculties();
      this.$emit("updateAdminStatus");
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
        id: newTag,
      };
      this.adminsOptions.push(tag);
      this.group.institution_users.push(tag);
    },
  },
};
</script>
