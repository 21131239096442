<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="applicationStatus" v-else>
      <b-overlay
        :show="loadApproval"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="md"
      >
        <h3 class="text-info">{{ eventName }}</h3>
        <div class="applicationForms mb-2 d-flex flex-wrap">
          <b-button
            size="sm"
            :variant="
              activeApplication == applicationIndex
                ? 'outline-dark'
                : 'outline-success'
            "
            v-for="(application, applicationIndex) in applications"
            :key="applicationIndex"
            @click="getApplicationStatus(application.id, applicationIndex)"
          >
            <span>Form {{ applicationIndex + 1 }} </span> <br />

            {{
              application.end_at != null
                ? convertTimeToLocal(application.end_at).format(
                    "DD/MM/YYYY hh:mm a"
                  )
                : ""
            }}
          </b-button>
        </div>
        <div
          class="application border border-info rounded p-3 d-flex justify-content-between flex-wrap bg-white"
          v-if="currentApplication != null"
        >
          <div
            class="question w-50 mb-2"
            v-for="(question, questionIndex) in approvalQuestions"
            :key="questionIndex"
          >
            <p class="ques">{{ question.question_no }}) {{ question.name }}</p>
            <p class="ans text-info ml-3" v-if="question.answer != null">
              {{ question.answer }}
            </p>
            <div class="d-flex flex-column">
              <span
                class="text-dark ml-3"
                v-for="(file, fileIndex) in question.files"
                :key="fileIndex"
              >
                <a
                  :href="userAttachments + file.file_path + file.file_name"
                  target="_blank"
                  class="text-decoration-none"
                  style="font-size: 12px; font-weight: 600"
                  >{{ file.file_name }}</a
                >
              </span>
            </div>
          </div>
        </div>
        <div class="approvers mt-2">
          <div
            class="approver mb-2 border border-primary p-2 rounded"
            v-for="(approver, approverIndex) in approversList"
            :key="approverIndex"
          >
            <div class="approverDetails row">
              <p class="name text-capitalize col-md-5">
                Name:
                {{ approver.institution_user.first_name
                }}{{ approver.institution_user.last_name || "" }}
              </p>
              <p class="col-md-2">
                Role: <span>{{ approver.role }}</span>
              </p>
              <p class="col-md-5 text-right">
                Status:

                <span
                  class="text-success"
                  v-if="
                    applicationStatus[approver.id] &&
                      applicationStatus[approver.id][currentApplication] &&
                      applicationStatus[approver.id][currentApplication]
                        .approval_status == 'approved'
                  "
                  >Approved</span
                >
                <span
                  class="text-danger"
                  v-else-if="
                    applicationStatus[approver.id] &&
                      applicationStatus[approver.id][currentApplication] &&
                      applicationStatus[approver.id][currentApplication]
                        .approval_status == 'rejected'
                  "
                  >Rejected</span
                >
                <span
                  class="text-success"
                  v-else-if="
                    applicationStatus[approver.id] &&
                      applicationStatus[approver.id][currentApplication] &&
                      applicationStatus[approver.id][currentApplication]
                        .approval_status == 'indirect_approved'
                  "
                  >Indirect Approved</span
                >
                <span
                  class="text-info"
                  v-else-if="
                    applicationStatus[approver.id] &&
                      applicationStatus[approver.id][currentApplication] &&
                      applicationStatus[approver.id][currentApplication]
                        .approval_status == 'resubmission'
                  "
                  >Resubmission</span
                >

                <span class="text-warning" v-else>Pending</span>
              </p>
            </div>
            <p>
              Comments:
              <span
                class="text-dark"
                v-if="
                  applicationStatus[approver.id] &&
                    applicationStatus[approver.id][currentApplication] &&
                    applicationStatus[approver.id][currentApplication]
                      .comments != null
                "
              >
                {{
                  applicationStatus[approver.id][currentApplication].comments
                }}
              </span>
              <span v-else class="text-danger">No Comments!</span>
            </p>
          </div>
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: ["applications", "eventName", "eventId"],
  async created() {
    this.fetchData = await true;
    await this.getApprovers();
    await this.getApprovalQuestions();
    await this.applications.reverse();
    if (this.applications.length != 0) {
      await this.getApplicationStatus(this.applications[0].id, 0);
    }
    this.userAttachments = await this.$store.getters.getAPIKey.userAttachments;
    this.fetchData = await false;
  },
  data() {
    return {
      fetchData: false,
      loadApproval: false,
      approvalQuestions: [],
      approvers: [],
      approversObject: {},
      approversList: [],
      currentApplication: null,
      userAttachments: null,
      activeApplication: null,
      applicationStatus: {},
    };
  },
  methods: {
    convertTimeToLocal(theTime) {
      let localTime = moment(
        moment.utc(theTime, "YYYY-MM-DD HH:mm:ss").toDate()
      );
      return localTime;
    },
    async getApprovalQuestions() {
      const url =
        this.$store.getters.getAPIKey.surveyApprovals +
        `/${this.eventId}/onlineregquestions`;
      await this.$axios.get(url).then((response) => {
        this.approvalQuestions = [];
        let questions = response.data;

        questions.forEach((ques) => {
          this.approvalQuestions.push({
            id: ques.id,
            question_no: ques.question_no,
            name: ques.name,
            summary: ques.summary,
            answer: null,
            files: [],
          });
        });
      });
    },
    async getApplicationStatus(applicationId, activeIndex) {
      if (this.activeApplication == activeIndex) {
        return;
      }
      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/onlineregapplications/${applicationId}/onlinereganswers `;
      await this.$axios
        .get(url)
        .then((response) => {
          let answers = response.data;

          this.approvalQuestions.forEach((question) => {
            answers.forEach((answer) => {
              if (question.id == answer.question_id) {
                question.answer = answer.answer;
                question.files = answer.files;
              }
            });
          });
          this.currentApplication = applicationId;
          this.activeApplication = activeIndex;
          response;
        })
        .catch((error) => {
          error;
          //
        });
      await this.getApplicationApprovalStatus(applicationId);
    },
    async getApprovers() {
      await this.registrationId;
      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.surveyApprovals +
        `/${this.eventId}/onlineregapprovers`;
      this.$axios
        .get(url)
        .then((response) => {
          this.approversList = response.data;

          response;
        })
        .catch((error) => {
          error;
        });
    },
    async getApplicationApprovalStatus(applictionId) {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/onlineregapplications/${applictionId}/onlineregapprovals`;
      await this.$axios
        .get(url)
        .then((response) => {
          let appStatus = response.data;
          appStatus.forEach((status) => {
            this.$set(this.applicationStatus, status.approver_id, {});
            this.$set(
              this.applicationStatus[status.approver_id],
              status.application_id,
              {
                approval_status: status.approval_status,
                comments: status.comments,
              }
            );
          });
          // this.applicationStatus = response.data;

          response;
        })
        .catch((error) => {
          error;
        });
      this.fetchData = false;
    },
  },
};
</script>

<style >
.applicationStatus h3 {
  font-size: 20;
  font-weight: 600;
  text-align: center;
  margin-bottom: 10px;
}

.applicationStatus .application p.ques {
  font-size: 18px;
  font-weight: 600;
  margin: 0px;
}
.applicationStatus .application p.ans {
  font-size: 16px;
  font-weight: 600;
}

.applicationStatus .approvers .approver p {
  font-size: 14px;
  font-weight: 600;
}
.applicationStatus .applicationForms button {
  margin-bottom: 5px;
}
.applicationStatus .applicationForms button {
  margin-left: 10px;
}
</style>
