var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.fetchData)?_c('div',{staticClass:"loading"},[_c('load-data')],1):_c('div',{staticClass:"copyUsersFromUsersubgroup"},[_c('b-form',{staticClass:"border-0 w-50",on:{"submit":function($event){$event.preventDefault();return _vm.prepareSubgroupUsersForCopy($event)}}},[_c('b-form-group',{attrs:{"label":"Select Academic Year"}},[_c('b-form-select',{attrs:{"options":_vm.$acedemicYears},on:{"change":_vm.getUsergroups},model:{value:(_vm.academicYear),callback:function ($$v) {_vm.academicYear=$$v},expression:"academicYear"}})],1),(_vm.academicYear != null)?_c('b-form-group',[_c('b-form-select',{attrs:{"options":_vm.usergroupOptions},on:{"change":_vm.getUserSubgroups},model:{value:(_vm.fromUsergroup),callback:function ($$v) {_vm.fromUsergroup=$$v},expression:"fromUsergroup"}})],1):_vm._e(),(_vm.fromUsergroup != null)?_c('b-form-group',[_c('b-form-select',{class:{
            'form-group-error': _vm.$v.copyUsersToSubgroup.from_subgroup.$error,
          },attrs:{"options":_vm.subgroupsOptions},on:{"change":_vm.getSubgroupStudents},model:{value:(_vm.copyUsersToSubgroup.from_subgroup),callback:function ($$v) {_vm.$set(_vm.copyUsersToSubgroup, "from_subgroup", $$v)},expression:"copyUsersToSubgroup.from_subgroup"}}),(
          !_vm.$v.copyUsersToSubgroup.from_subgroup.required &&
          _vm.$v.copyUsersToSubgroup.from_subgroup.$dirty
        )?_c('span',{staticClass:"input-error-message"},[_vm._v(" Please Select a "+_vm._s(_vm.getCustomNames.userSubgroup.custom_name != "" ? _vm.getCustomNames.userSubgroup.custom_name : "subgroup")+" to copy from! ")]):_vm._e()],1):_vm._e(),_c('table',{staticClass:"table  table-striped table-bordered"},_vm._l((_vm.groupedStudents),function(groupedStuds,hrindex){return _c('tr',{key:hrindex},_vm._l((groupedStuds),function(stud,sindex){return _c('td',{key:sindex},[_c('b-form-checkbox',{attrs:{"value":stud.pivot.institution_user_id},model:{value:(_vm.studentIds),callback:function ($$v) {_vm.studentIds=$$v},expression:"studentIds"}}),_c('span',{class:{ 'text-primary': stud.pivot.usersubgrouprole_id == 2, 'text-info': stud.pivot.usersubgrouprole_id == 1 }},[_vm._v(_vm._s(stud.roll_number)+" "),(stud.roll_number)?_c('span',[_vm._v(".")]):_vm._e(),_vm._v(" "+_vm._s(stud.first_name)+" "+_vm._s(stud.last_name)+"("+_vm._s(stud.email)+")")])],1)}),0)}),0),_c('b-form-group',[(
          _vm.academicYear != null && _vm.copyUsersToSubgroup.from_subgroup != null && _vm.studentIds.length > 0
        )?_c('b-button',{attrs:{"type":"submit","variant":"primary","size":"sm","disabled":_vm.button.disabled}},[_vm._v(" "+_vm._s(_vm.button.text)+" ")]):_vm._e()],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }