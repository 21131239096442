<template>
  <div>

    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="custom-naming" v-else>
      <div class="editBtn mb-2 text-right">
        <b-button variant="info" @click="edit = !edit">EDIT</b-button>
      </div>

      <b-form @submit.prevent="prepareCustomNaming">
        <b-form-group>
          <h6 class="font-weight-bolder text-center text-info">
            ADD CUSTOM NAMING
          </h6>
        </b-form-group>
        <b-form-group
          :label="'Custom Name for ' + optionIndex"
          class="text-capitalize"
          v-for="(option, optionIndex) in customNamingOptions"
          :key="optionIndex"
        >
          <b-form-input
            v-model="customNamingOptions[optionIndex]"
            v-if="edit"
          ></b-form-input>
          <span class="font-weight-bolder" v-else>
            {{
              customNamingOptions[optionIndex] != ""
                ? customNamingOptions[optionIndex]
                : "-"
            }}
          </span>
        </b-form-group>
        <b-form-group v-if="edit">
          <b-button
            variant="primary"
            type="submit"
            size="md"
            :disabled="btn.disabled"
            >{{ btn.text }}</b-button
          >
        </b-form-group>
      </b-form>
    </div>
  </div>
</template>

<script>
export default {
  async created() {
    this.fetchData = true;
    await this.getCustomNamingsOptions();
    await this.getCustomNamings();
    this.fetchData = false;
  },
  data() {
    return {
      fetchData: false,
      customNamingOptions: {},
      customNamings: {},
      btn: {
        text: "Submit",
        disabled: false,
      },
      edit: false,
    };
  },
  computed: {
    getCustomNaming() {
      return this.$store.getters.getCustomNamings;
    },
  },
  methods: {
    async prepareCustomNaming() {
      this.btn = {
        text: "Please wait....",
        disabled: true,
      };
      const custom_namings = [];
      for (let naming in this.customNamingOptions) {
        custom_namings.push({
          key_name: naming,
          custom_name: this.customNamingOptions[naming],
        });
      }

      const customNamingStatus = await this.storeCustomNamings(custom_namings);
      if (customNamingStatus) {
        this.$toast.success("Successfuly Saved", {
          position: "top",
          duration: 3000,
        });
        await this.$store.dispatch("getCustomNamings");
        await this.getCustomNamings();
      } else {
        this.$toast.error("Something went wrong!", {
          position: "top",
          duration: 3000,
        });
        return (this.btn = {
          text: "Submit",
          disabled: false,
        });
      }

      this.btn = {
        text: "Submit",
        disabled: false,
      };
      this.edit = false;
    },
    async storeCustomNamings(postData) {
      const url = this.$store.getters.getAPIKey.mainAPI + "/customnamings";
      let status = await this.$axios
        .post(url, { custom_namings: postData })
        .then(() => {
          return true;
        })
        .catch(() => {
          return false;
        });
      return status;
    },
    async getCustomNamings() {
      this.fetchData = true;
      if (Object.keys(this.getCustomNaming).length != 0) {
        for (let custom in this.getCustomNaming)
          this.getCustomNaming[custom].custom_name != null
            ? (this.customNamingOptions[custom] = this.getCustomNaming[
                custom
              ].custom_name)
            : "";
      }
      this.fetchData = false;
    },
    async getCustomNamingsOptions() {
      const url =
        this.$store.getters.getAPIKey.mainAPI + "/customnamings/options";
      await this.$axios.get(url).then((res) =>
        res.data.forEach((option) => {
          if (
            option != "faculty" &&
            option != "courseInstructor" &&
            option != "courseStudent" &&
            option != "groupStudent"
          )
            this.$set(this.customNamingOptions, option, "");
        })
      );
    },
  },
};
</script>
