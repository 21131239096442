<template>
  <div>
    <b-overlay
      :show="loadData"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="md"
    >
      <div class="loading" v-if="fetchData">
        <load-data></load-data>
      </div>
      <div class="attendenceReports p-3" v-else>
        <b-form @submit.prevent="generateReport()" class="w-50 border-0">
          <b-form-group>
            <b-form-radio
              v-if="subgroups"
              v-model="reportType.name"
              value="Subgroup"
              inline
              :disabled="reportType.disabled"
              size="lg"
              @change="getUserSubgroups('Subgroup')"
            >
              {{
                this.getCustomNames.userSubgroup.custom_name != null
                  ? this.getCustomNames.userSubgroup.custom_name
                  : "Usersubgroups"
              }}
            </b-form-radio>
            <b-form-radio
              v-if="courses"
              v-model="reportType.name"
              value="Course"
              inline
              :disabled="reportType.disabled"
              size="lg"
              @change="getCourses('Course')"
              >Courses</b-form-radio
            >
            <b-form-radio
              v-if="myCourses"
              v-model="reportType.name"
              value="MyCourse"
              inline
              :disabled="reportType.disabled"
              size="lg"
              @change="getMyCourses()"
              >My Courses</b-form-radio
            >
          </b-form-group>
          <div class="selectReportType" v-if="subgroupsOrCourses.length != 0">
            <b-form-select
              :options="subgroupsOrCourses"
              v-model="selectedCourseOrSubgroup"
              required
              @change="selectReport"
            ></b-form-select>
          </div>
          <b-form-group label="Select Date">
            <b-row>
              <b-col md="6">
                <label for="example-datepickerfrom">From:</label>
                <b-form-datepicker
                  id="example-datepickerfrom"
                  v-model="date_from"
                  class="mb-2"
                ></b-form-datepicker>
              </b-col>
              <b-col md="6">
                <label for="example-datepickerto">To:</label>
                <b-form-datepicker
                  id="example-datepickerto"
                  v-model="date_upto"
                  class="mb-2"
                ></b-form-datepicker>
              </b-col>
            </b-row>
          </b-form-group>

          <b-form-group>
            <b-button
              type="Submit"
              :disabled="reportBtn.disabled || !reports.id"
              variant="primary"
              size="md"
              >{{ reportBtn.text }}</b-button
            >
          </b-form-group>
        </b-form>
        <subgroup-students-report
          ref="studentsSubgroupReport"
          :reports="reports"
          :courseId="null"
          v-if="reportOf == 'Subgroup'"
        ></subgroup-students-report>
        <course-students-detailed-report
          @courseReportLoaded="loadData = false"
          @closeReport="closeReport"
          ref="studentsAttendenceReport"
          :reports="reports"
          v-if="reportOf == 'Course' || 'MyCourse'"
        ></course-students-detailed-report>
      </div>
      <br />
    </b-overlay>
  </div>
</template>

<script>
import SubgroupStudentsReport from "./SubgroupStudentsReport.vue";
import CourseStudentsDetailedReport from "./CourseStudentsDetailedReport.vue";
// import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
import acl from "../../../../../authorization/acl.js";
// import Multiselect from "vue-multiselect";

export default {
  async created() {
    this.fetchData = true;
    const myCourses = await acl.isUserGranted("myCoursesInAttendenceReport");
    this.myCourses = myCourses;
    const subgroups = await acl.isUserGranted("subgroupsInAttendenceReport");
    this.subgroups = subgroups;
    const courses = await acl.isUserGranted("coursesInAttendenceReport");
    this.courses = courses;
    this.fetchData = false;
  },
  components: {
    SubgroupStudentsReport,
    CourseStudentsDetailedReport,
    // DatePicker,
    // Multiselect,
  },
  data() {
    return {
      fetchData: false,
      myCourses: null,
      subgroups: null,
      courses: null,
      today: moment(),
      reportDate: "",
      date_upto: null,
      date_from: null,
      search: "",
      loadData: false,
      selectedReportName: null,
      reportType: {
        name: "",
        disabled: false,
      },
      reportBtn: {
        text: "Generate Report",
        disabled: false,
      },
      reportOf: "",
      reports: {},
      subgroupsOrCourses: [],
      selectedCourseOrSubgroup: null,
    };
  },

  methods: {
    closeReport() {
      this.reportOf = "";
    },
    prepareReportDates() {
      // const separateDate = this.reportDate;
      const fromDate = moment(this.date_from).format("YYYY/MM/DD");
      const toDate = moment(this.date_upto).format("YYYY/MM/DD");
      this.reports.from = fromDate;
      this.reports.to = toDate;
    },
    async generateReport() {
      this.loadData = true;
      if (this.date_from && this.date_upto) {
        await this.prepareReportDates();
      }
      if (this.reportType.name != "" && this.reportType.name == "Subgroup") {
        this.reportOf = await "Subgroup";
        await this.reportOf;
        await this.$refs.studentsSubgroupReport.prepareSubgroupReport();
      }
      if (this.reportType.name != "" && this.reportType.name == "Course") {
        this.reportOf = await "Course";
        await this.reportOf;
        await this.$refs.studentsAttendenceReport.prepareCourseStudentsReport();
      }

      if (this.reportType.name != "" && this.reportType.name == "MyCourse") {
        this.reportOf = await "MyCourse";
        await this.reportOf;
        await this.$refs.studentsAttendenceReport.prepareCourseStudentsReport();
      }
      this.loadData = false;
    },
    selectReport(selectedObject, id) {
      id;

      this.$set(this.reports, "name", selectedObject.name);
      this.$set(this.reports, "tableName", selectedObject.tableName);
      this.$set(this.reports, "id", selectedObject.id);
    },
    resetSubgroupCourses() {
      this.search = "";
      this.subgroupsOrCourses = [];
      this.selectedReportName = null;
      this.reports = {};
    },
    getUserSubgroups(subgroup) {
      this.resetSubgroupCourses();
      this.selectedReportName = subgroup;
      this.loadData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        "/institutionuser/usersubgroups";
      this.$axios
        .get(url)
        .then((response) => {
          let custom =
            +this.getCustomNames.userSubgroup.custom_name != null
              ? "Select " + this.getCustomNames.userSubgroup.custom_name
              : "Select Usersubgroup";
          this.subgroupsOrCourses = [
            { value: null, text: custom, disabled: true },
          ];
          const subgroups = response.data;
          subgroups.forEach((subgroup) => {
            this.subgroupsOrCourses.push({
              value: {
                id: subgroup.id,
                tableName: subgroup.name + " - " + subgroup.usergroup.name,
                name: subgroup.usergroup.code + " - " + subgroup.code,
              },
              text: subgroup.usergroup.code + " - " + subgroup.code,
            });
          });
          response;
          this.loadData = false;
        })
        .catch((error) => {
          error;
          this.loadData = false;
        });
    },
    getCourses(course) {
      this.resetSubgroupCourses();
      this.selectedReportName = course;
      this.loadData = true;
      let mainUrl = this.$store.getters.getAPIKey.createCourse;
      const url = mainUrl + "/withusers";
      this.$axios
        .get(url)
        .then((response) => {
          let courses = response.data;
          this.subgroupsOrCourses = [
            { value: null, text: "Select Course", disabled: true },
          ];
          courses.forEach((course) => {
            this.subgroupsOrCourses.push({
              value: {
                id: course.id,
                tableName:
                  course.name +
                  " - " +
                  course.usersubgroup.name +
                  " - " +
                  course.usersubgroup.usergroup.name,
                name:
                  course.name +
                  " (" +
                  course.usersubgroup.code +
                  " - " +
                  course.usersubgroup.usergroup.code +
                  ")",
              },
              text:
                course.name +
                " (" +
                course.usersubgroup.code +
                " - " +
                course.usersubgroup.usergroup.code +
                ")",
            });
          });
          response;
          this.loadData = false;
        })
        .catch((error) => {
          error;
          this.loadData = false;
        });
    },
    getMyCourses() {
      this.resetSubgroupCourses();
      this.loadData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/institutionuser/courses/withusers`;
      this.$axios
        .get(url)
        .then((response) => {
          let courses = response.data;
          this.subgroupsOrCourses = [
            { value: null, text: "Select Course", disabled: true },
          ];
          courses.forEach((course) => {
            this.subgroupsOrCourses.push({
              value: {
                id: course.id,
                tableName:
                  course.name +
                  " - " +
                  course.usersubgroup.name +
                  " - " +
                  course.usersubgroup.usergroup.name,
                name:
                  course.name +
                  " (" +
                  course.usersubgroup.code +
                  " - " +
                  course.usersubgroup.usergroup.code +
                  ")",
              },
              text:
                course.name +
                " (" +
                course.usersubgroup.code +
                " - " +
                course.usersubgroup.usergroup.code +
                ")",
            });
          });
          response;
          this.loadData = false;
        })
        .catch((error) => {
          error;
          this.loadData = false;
        });
    },
  },
  computed: {
    getCustomNames() {
      return this.$store.getters.getCustomNamings;
    },
    filteredDataRequiredForReports() {
      if (this.search) {
        return this.subgroupsOrCourses.filter((data) => {
          let name = data.name;
          let code = data.code;
          return this.search
            .toLowerCase()
            .split(" ")
            .every(
              (v) =>
                name.toLowerCase().includes(v) || code.toLowerCase().includes(v)
            );
        });
      } else {
        return this.subgroupsOrCourses;
      }
    },
  },
};
</script>

<style ></style>
