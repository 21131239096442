var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"approversListing"},[(_vm.fetchData)?_c('div',{staticClass:"loading"},[_c('load-data')],1):_c('b-container',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{staticClass:"mb-2",attrs:{"variant":_vm.approverForm ? 'danger' : 'primary',"size":"sm"},on:{"click":function($event){_vm.approverForm = !_vm.approverForm}}},[_vm._v(_vm._s(_vm.approverForm ? "Close" : "Add Approver"))]),(_vm.approverForm)?_c('div',{staticClass:"approverForm"},[_c('CreateApprovers',{attrs:{"approverInfo":_vm.approverInfo,"registrationId":_vm.registrationId,"approvers":_vm.approvers},on:{"cancelled":_vm.emitResponseFromApproverCreation}})],1):_vm._e(),_c('div',{staticClass:"table-responsive"},[_c('b-overlay',{attrs:{"show":_vm.loadApprovers,"spinner-variant":"primary","spinner-type":"grow","spinner-small":"","rounded":"md"}},[_c('table',{staticClass:"table table-bordered table-striped"},[_c('thead',{staticClass:"thead-dark"},[_c('tr',[_c('th',[_vm._v("Sr No.")]),_c('th',[_vm._v("Name")]),_c('th',[_vm._v("Role")]),_c('th',[_vm._v("Order")]),_c('th',[_vm._v("Edit")]),_c('th',[_vm._v("Remove")]),_c('th',[_vm._v("Special Privilege")])])]),_c('tbody',[(_vm.approvers.length == 0)?_c('tr',[_c('td',{staticClass:"align-middle text-center text-danger",attrs:{"colspan":"100"}},[_vm._v(" No Approvers Set! ")])]):_vm._l((_vm.approvers),function(approver,approverIndex){return _c('tr',{key:approverIndex},[_c('td',{staticClass:"align-middle"},[_vm._v(_vm._s(approverIndex + 1))]),_c('td',{staticClass:"align-middle"},[_vm._v(" "+_vm._s(approver.name)+" ")]),_c('td',{staticClass:"align-middle"},[_vm._v(_vm._s(approver.role))]),_c('td',{staticClass:"align-middle"},[_vm._v(_vm._s(approver.order))]),_c('td',{staticClass:"align-middle"},[(!_vm.approverForm)?_c('span',{staticClass:"text-info btn m-0 p-0",on:{"click":function($event){return _vm.getApprover(
                            approver.id,
                            approver.name,
                            approver.institution_user_id,
                            approver.role,
                            approver.order,
                            approver.approve_all_same_order
                          )}}},[_c('i',{staticClass:"far fa-edit"})]):_vm._e()]),_c('td',{staticClass:"align-middle"},[(!_vm.approverForm)?_c('span',{staticClass:"btn p-0 m-0 text-danger",on:{"click":function($event){return _vm.removeApprover(approver.id, approverIndex)}}},[_c('i',{staticClass:"fas fa-trash"})]):_vm._e()]),_c('td',{staticClass:"align-middle"},[(!_vm.approverForm)?_c('b-form-checkbox',{on:{"change":function($event){return _vm.updateSpecialPrivilege(
                            approver.id,
                            approver.approve_all_same_order,
                            approver.institution_user_id,
                            approver.role
                          )}},model:{value:(approver.approve_all_same_order),callback:function ($$v) {_vm.$set(approver, "approve_all_same_order", $$v)},expression:"approver.approve_all_same_order"}}):_vm._e()],1)])})],2)])])],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }