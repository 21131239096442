<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="attainmentCO" v-else>
      <b-row>
        <b-col md="12">
          <div class="viewOrConfig mb-3">
            <b-button size="sm" :variant="config ? 'success' : 'outline-primary'" class="font-weight-bolder"
              disabled>Choose PO & PSO</b-button>
            <b-button size="sm" disabled :variant="!config ? 'success' : 'outline-primary'"
              class="ml-4 font-weight-bolder">CO/PO-PSO Mapping</b-button>
          </div>

          <div class=" " v-if="config">

            <div class="table-responsive">
              <table class="table table-bordered table-striped">
                <thead class="thead-dark text-left">
                  <tr>
                    <th>
                      <b-form-checkbox @change="selectAllPo" v-model="selectAllPoCheckbox">{{
                        selectAllPoCheckbox
                        ? "Unselect All"
                        : "Select All"
                        }}</b-form-checkbox>
                    </th>
                    <th>code</th>
                    <th>programme outcome</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(po, subgroupIndex) in programme_outcomes" :key="subgroupIndex">
                    <td class="align-middle">
                      <b-form-checkbox size="sm" v-model="programme_outcomes[subgroupIndex].checked">
                      </b-form-checkbox>
                    </td>
                    <td class="align-middle">
                      {{ po.code }}
                    </td>
                    <td class="align-middle">
                      {{ po.outcome }}
                    </td>
                  </tr>
                </tbody>
              </table>

            </div>
            <div class="table-responsive">
              <table class="table table-bordered table-striped">
                <thead class="thead-dark text-left">
                  <tr>
                    <th>
                      <b-form-checkbox @change="selectAllPso" v-model="selectAllPsoCheckbox">{{
                        selectAllPsoCheckbox
                        ? "Unselect All"
                        : "Select All"
                        }}</b-form-checkbox>
                    </th>
                    <th>code</th>
                    <th>programme specific outcome</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(pso, subgroupIndex) in programme_specificoutcomes" :key="subgroupIndex">
                    <td class="align-middle">
                      <b-form-checkbox size="sm" v-model="programme_specificoutcomes[subgroupIndex].checked">
                      </b-form-checkbox>
                    </td>
                    <td class="align-middle">
                      {{ pso.code }}
                    </td>
                    <td class="align-middle">
                      {{ pso.outcome }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div v-else>
            <div class="table-responsive">
              <table class="table table-bordered table-striped">
                <thead class="thead-dark text-left">
                  <tr>
                    <th rowspan="2">CO</th>
                    <th :colspan="Object.keys(programme_outcomes).length">PO</th>
                    <th :colspan="Object.keys(programme_specificoutcomes).length">PSO</th>
                  </tr>
                  <tr>
                    <th v-for="(po, pokey) in programme_outcomes" :key="('po' + pokey)">{{ po.code }}</th>
                    <th v-for="(pso, psokey) in programme_specificoutcomes" :key="('pso' + psokey)">
                      {{ pso.code }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(co, cokey) in course_outcomes" :key="('co' + cokey)">
                    <td>{{ co.code }}</td>
                    <td v-for="(po, pokey) in programme_outcomes" :key="('po' + pokey)">
                      <span
                        v-if="coPoPsoMappingData[co.id].poMapping[po.id].level == 0 || coPoPsoMappingData[co.id].poMapping[po.id].level == 0.0 || coPoPsoMappingData[co.id].poMapping[po.id].level == 'NaN' || !coPoPsoMappingData[co.id].poMapping[po.id].level">-</span>
                      <span v-else>
                        {{
                        coPoPsoMappingData[co.id].poMapping[po.id].level
                        }}
                      </span>
                    </td>
                    <td v-for="(pso, psokey) in programme_specificoutcomes" :key="('pso' + psokey)">
                      <span
                        v-if="coPoPsoMappingData[co.id].psoMapping[pso.id].level == 0 || coPoPsoMappingData[co.id].psoMapping[pso.id].level == 0.0 || coPoPsoMappingData[co.id].psoMapping[pso.id].level == 'NaN' || !coPoPsoMappingData[co.id].psoMapping[pso.id].level">-</span>
                      <span v-else>
                        {{
                          coPoPsoMappingData[co.id].psoMapping[pso.id].level
                        }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  props: ["courseReport"],
  async created() {
    this.fetchData = true;
    await this.getAttainmentConfigration();
    this.fetchData = false;
  },

  data() {
    return {
      fetchData: false,
      config: true,
      programme_outcomes: [],
      programme_specificoutcomes: [],
      selectAllPoCheckbox: true,
      selectAllPsoCheckbox: true,
      coPoPsoMappingData: {},
      course_outcomes: [],
    };
  },
  methods: {
    async getAttainmentConfigration() {
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/coattainment/getconfig`;
      await this.$axios
        .post(url, {
          course: this.courseReport.course_id,
          step: "co_po_pso_mapping",
        })
        .then((response) => {
          this.programme_outcomes = response.data.programme_outcomes;
          this.programme_outcomes.forEach((po) => {
            po.checked = true;
          });
          this.programme_specificoutcomes =
            response.data.programme_specificoutcomes;
          this.programme_specificoutcomes.forEach((pso) => {
            pso.checked = true;
          });
        });
    },
    async setAttainmentConfigration() {

      let programme_outcomes = [];
      await this.programme_outcomes.forEach((po) => {
        if (po.checked) programme_outcomes.push(po.id);
      });
      let programme_specificoutcomes = [];
      await this.programme_specificoutcomes.forEach((pso) => {
        if (pso.checked) programme_specificoutcomes.push(pso.id);
      });

      const url =
        this.$store.getters.getAPIKey.mainAPI + `/coattainment/setconfig`;
      await this.$axios
        .post(url, {
          course: this.courseReport.course_id,
          step: "co_po_pso_mapping",
          programme_outcomes: programme_outcomes,
          programme_specificoutcomes: programme_specificoutcomes,
        })
        .then(() => { });
    },
    async getAttainmentData() {
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/coattainment/getdata`;
      await this.$axios
        .post(url, {
          course: this.courseReport.course_id,
          step: "co_po_pso_mapping",
        })
        .then((response) => {
          // this.surveys = response.data;
          this.coPoPsoMappingData = response.data.coPoPsoMappingData;
          this.programme_specificoutcomes = response.data.programme_specific_outcomes;
          this.programme_outcomes = response.data.programme_outcomes;
          this.course_outcomes = response.data.course_outcomes;
        });
    },
    async getAttainmentReport() {
      // const url =
      //   this.$store.getters.getAPIKey.mainAPI + `/coattainment/report`;
      // await this.$axios
      //   .post(url, {
      //     course: this.courseReport.course_id,
      //     step: "co_po_pso_mapping",
      //   })
      //   .then((response) => {
      //     // this.reports = response.data;
      //     response;
      //   });
    },

    selectAllPo() {
      this.programme_outcomes.forEach((po) => {
        po.checked = this.selectAllPoCheckbox;
      });

      this.$forceUpdate();
    },
    selectAllPso() {
      this.programme_specificoutcomes.forEach((pso) => {
        pso.checked = this.selectAllPsoCheckbox;
      });

      this.$forceUpdate();
    },
  },
};
</script>
