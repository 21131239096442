<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="universityExamForm" v-else>
      <b-overlay :show="loadData" spinner-variant="primary" spinner-type="grow" spinner-small rounded="md">
        <!-- <b-modal id="sampleUniversityExcel" no-close-on-backdrop no-close-on-keyboard no-close-on-esc hide-footer
          centered size="lg" title="Sample Excel Sheet">
          <div class="mb-2" style="font-size: 14px; font-weight: 600">
            Excel sheet should match the exact format: <br />
            1) First Row will consist of course codes. <br />
            2) From Second row it will have student registration number along
            with grade for that particular course.
            <br />
            --Sample Excel Sheet Image is given below:
          </div>
          <div>
            <img src="../../../../assets/images/universityExcelSample.png" alt="" class="img-fluid" />
          </div>
        </b-modal> -->
        <b-form @submit.prevent="prepareToUploadScores">
          <h4 class="mb-2 text-center text-info" style="font-size: 20px; font-weight: 600">
            University Exam
          </h4>
          <div class="m-1 row" v-if="boardExam.mark_entry_type=='subject_wise'">
            <div>Import excel file ( <b-link @click="$bvModal.show('importExcelSampleModel')">Sample excel
                format</b-link> ):</div>
            <b-form-file v-model="importExcelFile" accept=".xlsx" size="sm" @change="importScoreExcel"
              placeholder="Choose an excel file or drop it here..." drop-placeholder="Drop file here...">
            </b-form-file>

            <b-modal id="importExcelSampleModel" size="md" title="Import excel for score" hide-footer>
              <div>
                <p style="font-size: 14px; font-weight: 600">Scores Excel Sheet</p>
                <h6 style="font-size: 14px; font-weight: 900">
                  Note: Please do not include headings in the spreadsheet used to import data.
                  Enter only the grades.
                </h6>

                <p>Sample excel format to import grade</p>
                <img src="../../../../assets/images/importGradeExcelSample.png" alt=""
                  class="img-fluid mx-auto d-block" />
                <br>
              </div>
            </b-modal>
          </div>
          <!-- <div class="mb-2 text-right">
            <span class="btn p-0 m-0 text-info border-bottom" @click="$bvModal.show('sampleUniversityExcel')"
              style="font-size: 12px; font-weight: 600">Sample Excel Sheet</span>
          </div> -->

          <b-form-group>
            <div class="table-responsive">
              <table class="table table-bordered table-striped">
                <thead class="thead-dark">
                  <tr>
                    <th></th>
                    <th></th>
                    <th v-for="(course, courseIndex) in courses" :key="courseIndex">
                      <b-form-datepicker placeholder="Pick Date" size="sm" v-model="course.exam_date"
                        :date-format-options="{ day: 'numeric', month: 'numeric', year: '2-digit' }" :class="{
                          ' form-group-error ': (missingDateCourses.includes(`${course.id}`) && (course.exam_date == null || course.exam_date == '')),
                        }">
                      </b-form-datepicker>
                      <br />
                      <span :class="{
                        'text-danger': (missingDateCourses.includes(`${course.id}`) && (course.exam_date == null || course.exam_date == '')),
                      }">{{ course.code }}</span>
                      <b-button v-if="boardExam.mark_entry_type=='co_wise'" variant="info" class="p-0 m-0" @click="
                        courseCoEvaluation(course)
                      "> <small style="font-size: 85% !important;">CO Evaluation</small></b-button>
                      <b-button variant="info" class="p-0 m-0" v-if="boardExam.mark_entry_type=='question_wise'" @click="
                       manageExamQuestions(course.id)
                      "> <small style="font-size: 85% !important;">Set Question Paper</small></b-button>
                      <b-button variant="info" class="p-0 m-0" v-if="boardExam.mark_entry_type=='question_wise'" @click="
                      universityexamEvaluation(course.id)
                      "> <small style="font-size: 85% !important;">Update Mark</small></b-button>  
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(student, studentIndex) in students" :key="studentIndex">
                    <td class="bg-dark text-white">
                      {{ student.roll_number }}
                    </td>
                    <td class="bg-dark text-white">
                      {{ student.name }} <br />
                      {{ student.register_no }}
                    </td>
                    <td v-for="(course, courseIndex) in courses" :key="courseIndex">
                      <b-form-input v-if="course.studentIds.includes(student.id)&boardExam.mark_entry_type=='subject_wise'"
                        v-model="scores[course.id][student.id].grade" class="text-center"
                        placeholder="Grade"></b-form-input>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-form-group>

          <b-form-group>
            <div class="d-flex justify-content-between">
              <b-button v-if="boardExam.mark_entry_type=='subject_wise'" variant="primary" type="submit" size="sm">Save</b-button>
              <b-button variant="danger" size="sm" @click="finish()">Close
              </b-button>
            </div>
          </b-form-group>
        </b-form>
      </b-overlay>
    </div>

    <b-modal id="courseCOEvaluationModel" no-close-on-backdrop no-close-on-keyboard no-close-on-esc hide-footer
      hide-header centered size="xl">
      <UniversityExamUpdateCourseCoScore :course="coEvaluationCourse" :boardExam="boardExam"
        @finishUpdateScore="$bvModal.hide('courseCOEvaluationModel');" />
    </b-modal>
  </div>
</template>

<script>
import UniversityExamUpdateCourseCoScore from "./UniversityExamUpdateCourseCoScore.vue";
import readXlsxFile from "read-excel-file";
export default {
  props: ["boardExam"],
  components: {
    UniversityExamUpdateCourseCoScore,
  },
  async created() {
    this.fetchData = true;
    await Promise.all([
      this.getCourses(),
      this.getStudents(),
      this.getScores(),
    ]);
    this.courses.forEach(course => {
      course.exam_date = course.id in this.course_exam_dates_response ? this.course_exam_dates_response[course.id] : null;
      this.scores[course.id] = {};
      this.students.forEach(student => {
        if (course.studentIds.includes(student.id)) this.scores[course.id][student.id] = { grade: '' };
      });
    });
    this.scoresResponse.forEach(score => {
      if (score.course_id in this.scores) {
        if (score.student_id in this.scores[score.course_id])
          this.scores[score.course_id][score.student_id].grade = score.grade;
      }
    });
    // get courses with enrolled stud ids avilable 
    // get students
    // get grades of university exam

    // if need open one of course co score evaluation popup


    this.fetchData = false;
  },
  data() {
    return {
      fetchData: false,
      loadData: false,
      courses: [],
      students: [],
      scoresResponse: [],
      scores: {},
      coEvaluationCourse: null,
      importExcelFile: null,
      course_exam_dates_response: {},
      missingDateCourses: [] // to show error that date is mandatory when enter score
    };
  },
  methods: {
    manageExamQuestions(course) {
      let route = this.$router.resolve({
          path: `/university_exam/manage_exam_question/${this.boardExam.id}/${course}`
      });
      window.open(route.href, '_blank');
    },
    universityexamEvaluation(course) {
      let route = this.$router.resolve({
          path: `/university_exam/manage_exam_evaluation/${this.boardExam.id}/${course}`
      });
      window.open(route.href, '_blank');
    },
    async importScoreExcel(event) {
      let excelData = [];
      let error = false;
      await readXlsxFile(event.target.files[0]).then((data) => {
        excelData = data;
      }).catch(() => {
        error = true;
        this.$toast.error("Error: unsupported file. Please make sure the file extension is '.xlsx' / please check the file again", {
          position: "top",
          duration: 7000,
        });
      });
      if (excelData.length > this.students.length || excelData[0].length > this.courses.length) {
        this.$toast.error(`Error: Excel row count(${excelData.length}) and column count(${excelData[0].length}) should be less than or equal to students count(${this.students.length}) and courses count(${this.courses.length})`, {
          position: "top",
          duration: 7000,
        });
        error = true;
      }

      for (let row = 0; row < this.students.length; row++) {
        if (row >= excelData.length) { break; }
        for (let col = 0; col < this.courses.length; col++) {
          if (col >= excelData[row].length) { break; }
          if ((this.courses[col].id in this.scores) && (this.students[row].id in this.scores[this.courses[col].id])) {
            this.scores[this.courses[col].id][this.students[row].id].grade = excelData[row][col];
          }
        }
      }

      this.importExcelFile = null;
      this.$forceUpdate();
      if (!error) this.$toast.success("Imported successfully, please save the data.", {
        position: "top",
        duration: 3000,
      });

    },
    async courseCoEvaluation(course) {
      this.coEvaluationCourse = course;

      this.$bvModal.show('courseCOEvaluationModel');
    },
    async prepareToUploadScores() {
      this.missingDateCourses = [];
      this.$forceUpdate();
      // this.universityBtn = {
      //   text: "Please wait...",
      //   disabled: true,
      // };
      let postData = {
        boardexam_id: this.boardExam.id,
        boardexam_courses_students_score: [],
        courses_exam_date: {},
      };
      this.courses.forEach(course => {
        if (course.exam_date != "" && course.exam_date != null)
          postData.courses_exam_date[course.id] = course.exam_date;
      });

      let missingDateCourses = [];
      for (const course_id in this.scores) {
        const courseStudsGrade = this.scores[course_id];
        for (const student_id in courseStudsGrade) {
          const score = courseStudsGrade[student_id];
          if (score.grade != "" && score.grade != null) {
            postData.boardexam_courses_students_score.push({
              grade: score.grade,
              course_id: course_id,
              student_id: student_id,
            });
            if (!(missingDateCourses.includes(course_id))) missingDateCourses.push(course_id);
          }
        }
      }

      missingDateCourses.forEach(courseId => {
        let course = this.courses.find(course => `${course.id}` === courseId);
        if (course.exam_date == "" || course.exam_date == null) this.missingDateCourses.push(courseId);
      });


      if (this.missingDateCourses.length < 1) {
        const uploadStudentScores = await this.storeScoresToDb(postData);

        if (uploadStudentScores) {
          this.$toast.success("Scores Uploaded Successfully", {
            position: "top",
            duration: 3000,
          });
          this.finish();
        }
        if (!uploadStudentScores) {
          this.$toast.error("Something went wrong, please try again", {
            position: "top",
            duration: 7000,
          });
        }
      } else {
        this.$forceUpdate();
        this.$toast.error("Exam date of a course is mandatory use the score in reports", {
          position: "top",
          duration: 7000,
        });
      }

      // this.universityBtn = {
      //   text: "Submit",
      //   disabled: false,
      // };
    },

    async storeScoresToDb(postData) {
      let status = null;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/boardexamscores`;
      await this.$axios
        .post(url, postData)
        .then((response) => {
          status = true;
          response;
        })
        .catch((error) => {
          status = false;
          error;
        });
      return status;
    },



    async getCourses() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usersubgroup/${this.boardExam.usersubgroup_id}/courses/withusers?interdepartmental_courses=1`;
      await this.$axios
        .get(url)
        .then((response) => {
          let courses = response.data;
          // courses with enrolled users , 
          courses.forEach(course => {
            let studIds = [];
            course.institution_users.forEach(stud => {
              if (stud.pivot.courserole_id == 2) studIds.push(stud.institution_user_id);
            });
            this.courses.push({
              id: course.id,
              name: course.name,
              code: course.code,
              studentIds: studIds,
              exam_date: null,
            })
          });
        })
        .catch((error) => {
          error;
        });
    },

    async getStudents() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usersubgroup/${this.boardExam.usersubgroup_id}/users`;
      await this.$axios
        .get(url)
        .then((response) => {
          let students = response.data;
          // courses with enrolled users ,
          students.forEach(student => {
            if (student.pivot.usersubgrouprole_id == 2)
              this.students.push({
                id: student.institution_user_id,
                name: student.first_name + ' ' + student.last_name,
                admission_no: student.admission_no,
                register_no: student.register_no,
                roll_number: student.roll_number,
              })
          });
        })
        .catch((error) => {
          error;
        });
    },

    async getScores() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/boardexamscores?boardexam=${this.boardExam.id}`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.scoresResponse = response.data.scores;
          this.course_exam_dates_response = response.data.course_exam_dates;
        })
        .catch((error) => {
          error;
        });
    },
    finish() {
      this.$emit("finishUpdateScore");
    },
  },
};
</script>
