<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <b-container v-else>
      <b-row>
        <b-col md="12">
          <div class="lessonPlans">
            <h4>
              {{
                  updateLessonDetails.id == null
                    ? "Add Lesson Details"
                    : "Update Lesson Details"
              }}
            </h4>

            <br />
            <div v-if="!lessonPlanForm">
              <div class="" style="border: 2px solid #17a2b8; padding: 15px; border-radius: 5px;">
                <ul>
                  You have three options to copy course data:
                  <li>
                    Copy from University Resources <span
                      style="color: #2929aa; text-decoration: underline; cursor: pointer;"
                      @click="copy_from = 'template'; $bvModal.show('copyCoursePropertiesModel')">Click here to
                      proceed</span>
                  </li>
                  <li>Copy from previous academic year <span
                      style="color: #2929aa; text-decoration: underline; cursor: pointer;"
                      @click="copy_from = 'myinstitute'; $bvModal.show('copyCoursePropertiesModel')">Click here to
                      proceed</span>
                  </li>
                  <li>Import data using an Excel sheet <span
                      style="color: #2929aa; text-decoration: underline; cursor: pointer;"
                      @click="$emit('goto-excel');">Click here to
                      proceed</span>
                  </li>
                </ul>
                <b-modal id="copyCoursePropertiesModel" no-close-on-backdrop no-close-on-keyboard no-close-on-esc
                  hide-footer hide-header centered size="md">
                  <CopyCourseProperties :copy_from_suggestion="copy_from" :course_id="courseId"
                    @success="propertyCopyCompleted()" @cancel="$bvModal.hide('copyCoursePropertiesModel')" />
                </b-modal>
              </div>
              <div class="text-center m-2 text-primary"> OR </div>

              <p>Enter Manually <span style="color: #2929aa; text-decoration: underline; cursor: pointer;"
                  @click="(lessonPlanForm = true)">Click here to
                  proceed</span></p>
            </div>

            <b-form v-if="lessonPlanForm" @submit.prevent="prepareLessonPlans()">
              <h6 class="alert alert-success text-center" v-if="lessonCreated != ''">
                {{ lessonCreated }}
              </h6>
              <div v-for="(lessonP, lessonIndex) in lessonPlans" :key="lessonIndex">
                <b-form-group class="text-right" v-if="lessonIndex != 0">
                  <span class="text-danger btn p-0 m-0" @click="removeLesson(lessonIndex)"><i
                      class="fas fa-minus-circle fa-2x"></i></span>
                </b-form-group>
                <b-form-group label="Si. No.">
                  <b-form-input placeholder="Enter Si. No." v-model="lessonP.si_no"></b-form-input>
                </b-form-group>
                <b-form-group label="Lesson">
                  <b-form-textarea placeholder="Enter Topic" rows="3" v-model="lessonP.lesson"
                    :class="{ 'is-invalid': responseErrors.lesson }"></b-form-textarea>
                  <span class="text-danger" v-if="responseErrors.lesson">{{
                      responseErrors.lesson[0]
                  }}</span>
                </b-form-group>

                <b-form-group label="Planned Date:">
                  <b-form-input v-model="lessonP.planned_dates" :class="{ 'is-invalid': responseErrors.planned_dates }" 
                  placeholder="14/02/2023,15/02/2023,..." 
                  ></b-form-input>
                  <!-- <date-picker v-model="lessonP.planned_date" :class="{ 'is-invalid': responseErrors.planned_date }"
                    format="DD/MM/YYYY" placeholder="Select Date" @change="checkDate">
                    </date-picker> -->
                    <span>Enter Date in DD/MM/YYYY format separated by comma.</span>
                  <span class="text-danger" v-if="responseErrors.planned_dates">{{ responseErrors.planned_dates[0]
                  }}</span>
                </b-form-group>

                <b-form-group label="No. of hours required">
                  <b-form-input placeholder="No. of hours required"
                    :class="{ 'is-invalid': responseErrors.required_hours }" v-model="lessonP.required_hours">
                  </b-form-input>

                  <span class="text-danger" v-if="responseErrors.required_hours">{{ responseErrors.required_hours[0]
                  }}</span>
                </b-form-group>

                <b-form-group label="Select Course Outcome:">
                  <multiselect v-model="lessonP.course_outcomes" :options="courseOutcomesOptions" :multiple="true"
                    :close-on-select="false" :clear-on-select="false" placeholder="Select CO's" label="text"
                    track-by="value" :preselect-first="false">
                  </multiselect>
                </b-form-group>

                <b-form-group label="Select Module:">
                  <b-form-select :options="courseModulesOptions" v-model="lessonP.course_module_id"></b-form-select>
                </b-form-group>

                <b-form-group label="Cognitive Levels:">
                  <multiselect v-model="lessonP.cognitive_levels"
                    :options="['Remembering', 'Understanding', 'Applying', 'Analyzing', 'Evaluating', 'Creating', 'Knowledge']"
                    :multiple="true" :close-on-select="false" :clear-on-select="false"
                    placeholder="Select Cognitive levels" :preselect-first="false">
                  </multiselect>
                </b-form-group>
              </div>

              <b-form-group>
                <div class="d-flex flex-row justify-content-between">
                  <b-button variant="danger" size="md" @click="cancel()" :disabled="lessonBtn.disabled">Close</b-button>
                  <div>
                    <b-button type="submit" variant="primary" size="md" :disabled="lessonBtn.disabled">{{ lessonBtn.text
                    }}</b-button>
                  </div>
                </div>
              </b-form-group>
            </b-form>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import CopyCourseProperties from "../CopyCourseProperties.vue"
import axios from "axios";
// import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
// import moment from "moment";
export default {
  props: ["courseId", "updateLessonDetails"],
  components: {
    // DatePicker, 
    CopyCourseProperties,
  },
  async created() {
    this.fetchData = true;
    await this.getCourseOutcomes();
    await this.getCourseModules();

    // this.fetchData = false;
    if (this.updateLessonDetails.id != null) {
      this.lessonPlanForm = true;
      this.lessonPlans[0].si_no = this.updateLessonDetails.lesson.si_no;
      this.lessonPlans[0].lesson = this.updateLessonDetails.lesson.lesson;
      this.lessonPlans[0].planned_dates = this.updateLessonDetails.lesson.planned_dates;

      this.lessonPlans[0].required_hours = this.updateLessonDetails.lesson.required_hours;
      this.lessonPlans[0].course_id = this.updateLessonDetails.lesson.course_id;
      this.lessonPlans[0].course_module_id = this.updateLessonDetails.lesson.course_module_id;
      this.lessonPlans[0].cognitive_levels = this.updateLessonDetails.lesson.cognitive_levels;

      let outcomes = this.updateLessonDetails.lesson.course_outcomes;
      if (outcomes.length != 0) {
        outcomes.forEach((outcome) => {
          this.lessonPlans[0].course_outcomes.push({
            value: outcome.id,
            text: outcome.code,
          });
        });
      } else {
        this.lessonPlans[0].course_outcomes = [];
      }
    }
  },

  data() {
    return {
      fetchData: false,
      lessonPlanForm: false,
      lessonPlans: [
        {
          si_no: "",
          lesson: "",
          planned_dates: "",
          required_hours: "",
          course_id: this.courseId,
          course_outcomes: [],
          course_module_id: null,
          cognitive_levels: [],
        },
      ],
      lessonCreated: "",
      lessonBtn: {
        text: "Submit",
        disabled: false,
      },
      responseErrors: {},
      courseOutcomesOptions: [],
      courseModulesOptions: [],
      copy_from: '',
    };
  },
  methods: {
    propertyCopyCompleted() {
      this.$bvModal.hide('copyCoursePropertiesModel')
      this.$emit("courseCreated", this.courseId);
      this.cancel();
    },
    addMoreLesson() {
      this.lessonPlans.push({
        si_no: "",
        lesson: "",
        planned_dates: "",
        required_hours: "",
        course_id: this.courseId,
        outcome: "",
        module: "",
        cognitive_levels: [],
      });
    },
    removeLesson(removeLesson) {
      this.lessonPlans.splice(removeLesson, 1);
    },
    async prepareLessonPlans() {
      this.lessonBtn.text = "Please wait...";
      this.lessonBtn.disabled = true;
      for (let lessonP in this.lessonPlans) {
        const co = [];
        await this.lessonPlans[lessonP].course_outcomes.forEach((outcome) => {
          co.push(outcome.value);
        });

        let lessonData = await {
          si_no: this.lessonPlans[lessonP].si_no,
          lesson: this.lessonPlans[lessonP].lesson,
          planned_dates: this.lessonPlans[lessonP].planned_dates,
          // moment(this.lessonPlans[lessonP].planned_date).format(
          //   "YYYY/MM/DD"
          // ),
          required_hours: this.lessonPlans[lessonP].required_hours ?? 0,
          course_id: this.lessonPlans[lessonP].course_id,
          course_outcomes: co,
          course_module_id: this.lessonPlans[lessonP].course_module_id,
          cognitive_levels: this.lessonPlans[lessonP].cognitive_levels,
        };

        let lessonPlanCreation =
          this.updateLessonDetails.id == null
            ? await this.createLesson(lessonData)
            : await this.updateLesson(lessonData);

        if (lessonPlanCreation) {
          if ((await this.updateLessonDetails.id) != null) {
            this.lessonCreated = "Lesson plan updated successfully.";
            this.$emit(
              "courseCreated",
              this.updateLessonDetails.lesson.course_id
            );
            this.clearResponse();
            // this.$emit("cancelUpdate");
          } else {
            await this.lessonPlans.length;

            if (this.lessonPlans.length > 1) {
              await this.lessonPlans.splice(lessonP, 1);
            } else {
              this.lessonCreated = "Lesson plan created successfully.";
              this.$toast.success("Lesson Plans created successfully.", {
                position: "top",
                duration: 3000,
              });
              this.clearResponse();
              await this.$emit("courseCreated", this.courseId);
            }
          }
        }
        if (!lessonPlanCreation) {
          this.clearResponse();
        }
      }
    },
    async createLesson(lessonData) {
      let status = null;
      const url = this.$store.getters.getAPIKey.lessonplans;

      await this.$axios
        .post(url, lessonData)
        .then(() => {
          status = true;
          // console.log(response);
        })
        .catch((error) => {
          this.responseErrors = error.response.data.errors;
          status = false;
          // console.log(error.response);
        });
      return status;
    },
    async updateLesson(lessonData) {
      let status = null;
      const url =
        this.$store.getters.getAPIKey.lessonplans +
        `/${this.updateLessonDetails.id}`;

      await axios
        .put(url, lessonData)
        .then(() => {
          status = true;
          // console.log(response);
        })
        .catch((error) => {
          this.responseErrors = error.response.data.errors;
          status = false;

          // console.log(error.response);
        });
      return status;
    },
    getCourseOutcomes() {
      let courseOutcomeUrl = this.$store.getters.getAPIKey.getCourseOutcomes;
      const url = courseOutcomeUrl.replace("course_id", this.courseId);
      axios
        .get(url)
        .then((response) => {
          let outcomes = response.data;
          if (outcomes.length != 0) {
            outcomes.forEach((cOutcome) => {
              this.courseOutcomesOptions.push({
                value: cOutcome.id,
                text: cOutcome.code,
              });
            });
          }
          // console.log(response);
        })
        .catch(() => {
          // console.log(error.response);
        });
    },
    getCourseModules() {
      let courseModuleUrl = this.$store.getters.getAPIKey.getCourseModules;
      const url = courseModuleUrl.replace("course_id", this.courseId);
      axios
        .get(url)
        .then((response) => {
          this.courseModulesOptions = [
            { value: null, text: "Select Module", disabled: true },
          ];
          let modules = response.data;
          if (modules.length != 0) {
            modules.forEach((cModule) => {
              this.courseModulesOptions.push({
                value: cModule.id,
                text: cModule.module,
              });
            });
          }
          this.fetchData = false;
          // console.log(response);
        })
        .catch(() => {
          // console.log(error.response);
        });
    },
    clearResponse() {
      this.lessonBtn.text = "Submit";
      this.lessonBtn.disabled = false;
      setTimeout(() => {
        if (this.lessonCreated != "") {
          this.lessonCreated = "";
          this.cancel();
        }
        this.responseErrors = {};
      }, 3000);
    },
    cancel() {
      if (this.updateLessonDetails.id != null) {
        this.$emit("cancelUpdate");
      }
      this.lessonPlans = [
        {
          si_no: "",
          lesson: "",
          planned_dates: "",
          required_hours: "",
          course_id: [],
          course_outcomes: null,
          course_module_id: null,
          cognitive_levels: [],
        },
      ];
      this.$emit("cancel");
    },
  },
  watch: {
    courseId: {
      deep: true,
      handler: function () {
        if (this.courseId != null) {
          for (let i = 0; i < this.lessonPlans.length; i++) {
            this.lessonPlans[i].course_id = this.courseId;
          }
        }
      },
    },
  },
};
</script>

<style >
.lessonPlans form label {
  font-size: 18px;
  font-weight: bold;
}
</style>
