<template>
  <div>
    <b-row>
      <b-col md="12">
        <div class="createOutcome">
          <br />

          <div v-if="!courseOutcomeForm">
            <div class="" style="border: 2px solid #17a2b8; padding: 15px; border-radius: 5px;">
              <ul>
                You have three options to copy course data:
                <li>
                  Copy from University Resources <span
                    style="color: #2929aa; text-decoration: underline; cursor: pointer;"
                    @click="copy_from = 'template'; $bvModal.show('copyCoursePropertiesModel')">Click here to
                    proceed</span>
                </li>
                <li>Copy from previous academic year <span
                    style="color: #2929aa; text-decoration: underline; cursor: pointer;"
                    @click="copy_from = 'myinstitute'; $bvModal.show('copyCoursePropertiesModel')">Click here to
                    proceed</span>
                </li>

                <li>Import data using an Excel sheet <span
                    style="color: #2929aa; text-decoration: underline; cursor: pointer;"
                    @click="$emit('goto-excel');">Click here to
                    proceed</span>
                </li>
              </ul>
              <b-modal id="copyCoursePropertiesModel" no-close-on-backdrop no-close-on-keyboard no-close-on-esc
                hide-footer hide-header centered size="md">
                <CopyCourseProperties :copy_from_suggestion="copy_from" :course_id="courseId"
                  @success="propertyCopyCompleted()" @cancel="$bvModal.hide('copyCoursePropertiesModel')" />
              </b-modal>
            </div>
            <div class="text-center m-2 text-primary"> OR </div>

            <p>Enter Manually <span style="color: #2929aa; text-decoration: underline; cursor: pointer;"
                @click="(courseOutcomeForm = true)">Click here to
                proceed</span></p>
          </div>
          <b-form v-if="courseOutcomeForm" @submit.prevent="
            currentCourseOutcome.id
              ? updateCourseOutcome()
              : createCourseOutcome()
          ">
            <h6 class="alert alert-success text-center" v-if="responseSuccess.created">
              {{ responseSuccess.created[0] }}
            </h6>
            <div v-for="(outcome, outcomeIndex) in outcomes" :key="outcomeIndex">
              <b-form-group class="text-right" v-if="outcomeIndex != 0">
                <span class="text-danger btn p-0 m-0" @click="removeOutcome(outcomeIndex)"><i
                    class="fas fa-minus-circle fa-2x"></i></span>
              </b-form-group>
              <b-form-group label="Si. No.:">
                <b-form-input placeholder="Enter Si. No." v-model="outcome.si_no"></b-form-input>
              </b-form-group>
              <b-form-group label="Course Outcome:">
                <b-form-input placeholder="Enter Outcome" :class="{ 'is-invalid': responseErrors.code }"
                  v-model="outcome.code"></b-form-input>
                <span class="text-danger" v-if="responseErrors.code">{{
                    responseErrors.code[0]
                }}</span>
              </b-form-group>

              <b-form-group label="Description:">
                <b-form-textarea placeholder="Enter Description" rows="3"
                  :class="{ 'is-invalid': responseErrors.outcome }" v-model="outcome.outcome"></b-form-textarea>

                <span class="text-danger" v-if="responseErrors.outcome">{{
                    responseErrors.outcome[0]
                }}</span>
              </b-form-group>


              <b-form-group label="Cognitive Levels:">
                <multiselect v-model="outcome.cognitive_levels"
                  :options="['Remembering', 'Understanding', 'Applying', 'Analyzing', 'Evaluating', 'Creating', 'Knowledge']"
                  :multiple="true" :close-on-select="false" :clear-on-select="false"
                  placeholder="Select Cognitive levels" :preselect-first="false">
                </multiselect>
              </b-form-group>
            </div>

            <b-form-group>

              <div class="d-flex flex-row justify-content-between">
                <b-button variant="danger" size="md" @click="cancel()" :disabled="outcomeBtn.disabled">Close</b-button>
                <div>
                  <b-button type="submit" variant="primary" size="md" :disabled="outcomeBtn.disabled">{{ outcomeBtn.text
                  }}</b-button>
                </div>
              </div>
            </b-form-group>
          </b-form>
        </div>
      </b-col>
    </b-row>
    <!-- </b-container> -->
  </div>
</template>

<script>
import CopyCourseProperties from "../CopyCourseProperties.vue"
import axios from "axios";
export default {
  props: ["courseId", "currentCourseOutcome"],
  created() {
    if (this.currentCourseOutcome.id) {
      this.courseOutcomeForm = true;
      this.outcomes[0].si_no = this.currentCourseOutcome.si_no;
      this.outcomes[0].code = this.currentCourseOutcome.code;
      this.outcomes[0].outcome = this.currentCourseOutcome.outcome;
      this.outcomes[0].cognitive_levels = this.currentCourseOutcome.cognitive_levels;
    }
  },

  data() {
    return {
      courseOutcomeForm: false,
      outcomes: [{ code: "", si_no: "", outcome: "", course_id: this.courseId, cognitive_levels: [] }],
      outcomeCreated: "",
      outcomeBtn: {
        text: "Submit",
        disabled: false,
      },
      responseErrors: {},
      responseSuccess: {},
      copy_from: '',
    };
  },
  components: {
    CopyCourseProperties,
  },
  methods: {
    propertyCopyCompleted() {
      this.$bvModal.hide('copyCoursePropertiesModel');
      this.$emit("getCourseOutcomes");
      this.cancel();
    },
    addMoreOutcome() {
      this.outcomes.push({
        si_no: "",
        name: "",
        description: "",
        level: "",
        course_id: this.courseId,
        cognitive_levels: [],
      });
    },
    removeOutcome(removeOutcome) {
      this.outcomes.splice(removeOutcome, 1);
    },
    async createCourseOutcome() {
      this.outcomeBtn.text = "Please wait...";
      this.outcomeBtn.disabled = true;
      const url = this.$store.getters.getAPIKey.courseOutcomes;
      this.outcomes.forEach((cOutcome, cOutcomeIndex) => {
        const courseOutcome = {
          si_no: cOutcome.si_no,
          code: cOutcome.code,
          outcome: cOutcome.outcome,
          course_id: cOutcome.course_id,
          cognitive_levels: cOutcome.cognitive_levels,
        };
        axios
          .post(url, courseOutcome)
          .then(() => {
            if (this.outcomes.length == 1) {
              this.responseSuccess["created"] = [
                `${cOutcome.code} is successfully created!`,
              ];
              this.clearResponse();
            }
            if (this.outcomes.length > 1) {
              this.outcomes.splice(cOutcomeIndex, 1);
            }

            // console.log(response);
          })
          .catch((error) => {
            this.responseErrors = error.response.data.errors;
            this.clearResponse();
            // console.log(error.response);
          });
      });
    },
    updateCourseOutcome() {
      this.outcomeBtn.text = "Updating...";
      this.outcomeBtn.disabled = true;
      const url =
        this.$store.getters.getAPIKey.courseOutcomes +
        `/${this.currentCourseOutcome.id}`;

      const courseOutcome = {
        si_no: this.outcomes[0].si_no,
        code: this.outcomes[0].code,
        outcome: this.outcomes[0].outcome,
        course_id: this.outcomes[0].course_id,
        cognitive_levels: this.outcomes[0].cognitive_levels,
      };
      axios
        .put(url, courseOutcome)
        .then(() => {
          this.responseSuccess["created"] = [
            `${this.outcomes[0].code} is successfully updated!`,
          ];

          this.clearResponse();

          // console.log(response);
        })
        .catch((error) => {
          this.responseErrors = error.response.data.errors;
          this.clearResponse();
          // console.log(error.response);
        });
    },
    clearResponse() {
      this.outcomeBtn.text = "Submit";
      setTimeout(() => {
        if (this.responseSuccess.created) {
          this.$emit("getCourseOutcomes");
          this.cancel();
        }

        this.outcomeBtn.disabled = false;
        this.responseErrors = {};
        this.responseSuccess = {};
      }, 3000);
    },

    cancel() {
      (this.outcomes = [{ code: "", si_no: "", outcome: "", course_id: this.courseId, cognitive_levels: [] }]),
        this.$emit("cancel");
    },
  },
};
</script>

<style >
.outcomes form label {
  font-size: 18px;
  font-weight: bold;
}
</style>
