<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="examCategory" v-else>
      <h5 class="font-weight-bolder">Exam Category</h5>
      <b-overlay
        :show="loadCategories"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="md"
      >
        <b-button
          variant="primary"
          @click="addExamCategory = true"
          v-if="!addExamCategory"
          size="sm"
          pill
          >Add Exam Category</b-button
        >

        <div class="examCategoryForm" v-else>
          <b-form @submit.prevent="prepareExamCategoryForAPI()">
            <b-form-group>
              <b-form-input
                placeholder="Category Name"
                v-model="examCategory.name"
                :class="{ 'form-group-error': $v.examCategory.name.$error }"
                class="text-center"
                @blur="$v.examCategory.name.$touch"
              ></b-form-input>
              <span
                class="input-error-message"
                v-if="
                  !$v.examCategory.name.required && $v.examCategory.name.$dirty
                "
                >Exam Category Name is required</span
              >
            </b-form-group>
            <b-form-group>
              <b-form-input
                placeholder="Category Description"
                v-model="examCategory.summary"
                :class="{ 'form-group-error': $v.examCategory.summary.$error }"
                class="text-center"
                @blur="$v.examCategory.summary.$touch"
              ></b-form-input>
              <span
                class="input-error-message"
                v-if="
                  !$v.examCategory.summary.required &&
                    $v.examCategory.summary.$dirty
                "
                >Exam Category Description is required</span
              >
            </b-form-group>
            <b-form-group>
              <div class="d-flex justify-content-between">
                <b-button
                  variant="primary"
                  size="sm"
                  type="submit"
                  :disabled="categoryBtn.disabled"
                  >{{ categoryBtn.text }}</b-button
                >
                <b-button
                  variant="danger"
                  size="sm"
                  :disabled="categoryBtn.disabled"
                  @click="cancel()"
                  >Close</b-button
                >
              </div>
            </b-form-group>
          </b-form>
        </div>
        <div
          class="examCategoriesList"
          v-if="examCategories.length != 0 && !addExamCategory"
        >
          <br />
          <div class="table-responsive">
            <table class="table table-striped table-bordered">
              <thead class="thead-dark">
                <tr>
                  <th>Exam Category</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(category, categoryIndex) in examCategories"
                  :key="categoryIndex"
                >
                  <td class="text-center">
                    <span class="text-info" :id="'examSummary' + categoryIndex">
                      {{ category.name }}</span
                    >

                    <b-tooltip
                      :target="'examSummary' + categoryIndex"
                      triggers="hover"
                    >
                      <p>{{ category.summary }}</p>
                    </b-tooltip>
                  </td>
                  <td class="text-center">
                    <span
                      @click="getExamCategory(category.id)"
                      class="btn m-0 p-0 text-info"
                      ><i class="far fa-edit"></i
                    ></span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
  created() {
    this.getExamCategories();
  },
  data() {
    return {
      examCategory: {},
      examCategoryToUpdate: {},
      loadCategories: false,
      addExamCategory: false,
      fetchData: false,
      categoryBtn: {
        text: "Submit",
        disabled: false,
      },
      examCategories: [],
      responseErrors: {},
    };
  },
  validations: {
    examCategory: {
      name: {
        required,
      },
      summary: {
        required,
      },
    },
  },
  methods: {
    //EXAM CATEGORY POST DATA PREPARING AND CREATING CATEGORY
    async prepareExamCategoryForAPI() {
      await this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.categoryBtn.text = "Please wait";
        this.categoryBtn.disabled = true;
        this.loadCategories = true;
        let examCategory;
        if (this.examCategoryToUpdate.id) {
          examCategory = await this.UpdateExamCategory();
        } else {
          examCategory = await this.CreateExamCategory();
        }

        if (examCategory) {
          this.$toast.success(
            this.examCategoryToUpdate.id
              ? this.examCategory.name + " " + "successfully updated."
              : this.examCategory.name + " " + "successfully created.",
            {
              position: "top",
              duration: 3000,
            }
          );
          this.clearResponse(true);
        }
        if (!examCategory) {
          this.$toast.error("Something went wrong, Please try again!", {
            position: "top",
            duration: 3000,
          });
          this.clearResponse(false);
        }
      }
    },
    async CreateExamCategory() {
      const url = this.$store.getters.getAPIKey.examCategory;
      let status = null;
      await this.$axios
        .post(url, this.examCategory)
        .then((response) => {
          status = true;
          response;
        })
        .catch((error) => {
          this.responseErrors = error.response.data.errors;
          status = false;
          error;
        });
      return status;
    },
    async UpdateExamCategory() {
      const url =
        this.$store.getters.getAPIKey.examCategory +
        `/${this.examCategoryToUpdate.id}`;
      let status = null;
      await this.$axios
        .put(url, this.examCategory)
        .then((response) => {
          status = true;
          response;
        })
        .catch((error) => {
          status = false;
          error;
        });
      return status;
    },
    //EXAM CATEGORY GET DATA GETTNG EXISTING DATA AND UPDATING
    async getExamCategories() {
      this.fetchData = await true;
      const url = this.$store.getters.getAPIKey.examCategory;
      await this.$axios
        .get(url)
        .then((response) => {
          this.examCategories = response.data;
          response;
        })
        .catch((error) => {
          error;
        });
      this.fetchData = await false;
    },
    async getExamCategory(examCategoryId) {
      this.loadCategories = true;
      const url =
        this.$store.getters.getAPIKey.examCategory + `/${examCategoryId}`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.examCategoryToUpdate = response.data;
          this.examCategory.name = this.examCategoryToUpdate.name;
          this.examCategory.summary = this.examCategoryToUpdate.summary;
          this.addExamCategory = true;
          response;
        })
        .catch((error) => {
          error;
        });
      this.loadCategories = await false;
    },
    //CLEARING RESPONSE FOR UI
    clearResponse(status) {
      this.categoryBtn.text = "Submit";
      this.loadCategories = false;
      setTimeout(() => {
        if (status) {
          this.addExamCategory = false;
          this.examCategory = {};
          this.getExamCategories();
        }
        this.responseErrors = {};
        this.$v.$reset();
        this.examCategoryToUpdate = {};
        this.categoryBtn.disabled = false;
      }, 3000);
    },
    cancel() {
      this.addExamCategory = false;
      this.examCategory = {};
      this.examCategoryToUpdate = {};
      this.$v.$reset();
    },
  },
};
</script>

<style ></style>
