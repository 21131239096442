<template>
  <div>
    <b-modal
      id="courseReport"
      no-close-on-backdrop
      no-close-on-keyboard
      no-close-on-esc
      hide-footer
      modal-class="courseReport"
    >
      <div class="loading" v-if="fetchData">
        <load-data></load-data>
      </div>
      <div v-else>
        <div class="closeButton">
          <b-button
            class="ml-2"
            variant="primary"
            style="font-size: 14px; font-weight: 600"
            size="sm"
            :disabled="exportCsvBtn.disabled || students.length == 0"
            @click="exportCourseReportToExcel('xlsx')"
            >{{ exportCsvBtn.text }}</b-button
          >
          <b-button
            style="font-size: 14px; font-weight: 600"
            class="ml-2"
            variant="primary"
            size="sm"
            @click="downloadAttendenceReport()"
            :disabled="exportCsvBtn.disabled || students.length == 0"
          >
            Download Attendance Report
          </b-button>
        </div>
        <br />
        <!-- course students detailed attendance report -->
        <div v-if="showCourseStudentsDetailedReport && students.length != 0">
          <b-overlay :show="loadReport" rounded="sm">
            <template #overlay>
              <b-icon
                icon="stopwatch"
                variant="info"
                scale="3"
                shift-v="8"
                shift-h="8"
                class="reportIcon"
              ></b-icon>
            </template>
            <table
              class="table table-striped table-bordered"
              ref="attendanceReport"
            >
              <thead>
                <tr>
                  <th colspan="100" class="align-middle text-center">
                    <span>{{ reports.tableName }}</span>
                  </th>
                </tr>
                <tr>
                  <th></th>
                  <th>Students</th>
                  <th
                    v-for="(attendanceDate, datesIndex) in attendanceDates"
                    :key="datesIndex"
                    class="text-center"
                  >
                    <div class="d-flex flex-column">
                      <span>{{
                        attendanceDate.date_time.format("DD-MM-YYYY")
                      }}</span>
                    </div>
                  </th>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th
                    v-for="(attendanceDate, datesIndex) in attendanceDates"
                    :key="datesIndex"
                    class="text-center"
                  >
                    <div class="d-flex flex-column">
                      <span>
                        {{
                          sessionsObjects[attendanceDate.session_id].name
                        }}</span
                      >
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="(student, studentIndex) in students"
                  :key="studentIndex"
                >
                  <td>
                    {{
                      student.pivot.roll_number == null
                        ? "RN"
                        : student.pivot.roll_number
                    }}
                  </td>
                  <td>
                    {{ student.first_name || " " }}
                    {{ student.last_name || " " }}
                  </td>

                  <td
                    v-for="(attendanceDate, datesIndex) in attendanceDates"
                    :key="datesIndex"
                    class="text-center"
                  >
                    {{
                      courseStudentsAttendancedatesAttendancetype[student.id][
                        attendanceDate.id
                      ]
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </b-overlay>
        </div>

        <p class="text-danger" v-else>No Data Found!</p>

        <h3>Consolidated report</h3>
        <subgroup-students-report
          @closeReport="null"
          ref="studentsSubgroupReport"
          :reports="reports"
          :courseId="reports.id"
        ></subgroup-students-report>
      </div>
    </b-modal>
  </div>
</template>

<script>
import SubgroupStudentsReport from "./SubgroupStudentsReport.vue";
import axios from "axios";
import moment from "moment";
import XLSX from "xlsx";
moment;
export default {
  props: ["reports"],

  components: {
    SubgroupStudentsReport,
  },
  data() {
    return {
      loadReport: false,
      //-----------------------------------------Export To CSV-------------------------------------------------
      exportCsvBtn: {
        text: "Export to Excel",
        disabled: false,
      },
      //---------------------------------------------type 1-----------------------------------------------------
      fetchData: false,
      showCourseStudentsDetailedReport: false,
      session: [],
      sessionsObjects: {}, //access with id
      attendanceDates: [],
      courseStudentsAttendancedatesAttendancetype: {},
      students: [],
      studentsObjects: {}, //access with id
      attendanceTypes: [],
      attendanceTypesObjects: {}, //access with id
      report: {
        from: "",
        to: "",
        status: null,
      },
      statusOptions: [],
      reportBtn: {
        text: "Search",
        disabled: false,
      },
    };
  },
  methods: {
    async downloadAttendenceReport() {
      this.loadReport = await true;
      this.exportCsvBtn.disabled = await true;
      await this.downloadAttendenceReportPDF();
      await this.downloadAttendenceSummaryPDF();
      this.loadReport = await false;
      this.exportCsvBtn.disabled = await false;
    },
    async downloadAttendenceReportPDF() {
      // this.exportCsvBtn.disabled = true;
      // this.loadReport = true;
      let postData = {
        start_date: this.reports.from,
        upto_date: this.reports.to,
        // usersubgroup: 13,
        course: this.reports.id,
      };
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/attendancereports/coursestudents/detailed/pdfexport`;
      await axios
        .post(url, postData, { responseType: "blob" })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "courseStudentsDetaildReport.pdf");
          document.body.appendChild(link);
          link.click();
          link.remove();

          response;
        })
        .catch((error) => {
          error;
        });
    },
    async downloadAttendenceSummaryPDF() {
      let postData = {
        start_date: this.reports.from,
        upto_date: this.reports.to,

        course: this.reports.id,
      };
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/attendancereports/coursestudents/summery/pdfexport`;
      await axios
        .post(url, postData, { responseType: "blob" })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "courseStudentsSummaryReport.pdf");
          document.body.appendChild(link);
          link.click();
          link.remove();

          response;
        })
        .catch((error) => {
          error;
        });
    },
    async exportCourseReportToExcel(type, fn, dl) {
      this.exportCsvBtn.text = "Please wait...";
      this.exportCsvBtn.disabled = true;
      var elt = this.$refs.attendanceReport;
      var wb = await XLSX.utils.table_to_book(elt, { sheet: "Sheet JS" });
      this.exportCsvBtn.text = "Export to Excel";
      this.exportCsvBtn.disabled = false;
      return dl
        ? XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" })
        : XLSX.writeFile(
            wb,
            fn ||
              (this.reports.name + "." || "courseStudents.") + (type || "xlsx")
          );
    },
    closeReport() {
      this.$bvModal.hide("courseReport");
      this.$emit("closeReport");
    },
    //----------------------------------------type 1-------------------------------------------------
    async getReportStatusOptions() {
      this.statusOptions = await [
        { value: null, text: "Select Status", disabled: true },
      ];
    },
    async prepareCourseStudentsReport() {
      this.$bvModal.show("courseReport");
      this.fetchData = true;
      this.showCourseStudentsDetailedReport = false;
      await this.getReportStatusOptions();
      await this.getAttendenceTypes();
      await this.getSessions();

      let report = await this.getCourseStudentsReport();

      let courseStudentsAttendancedatesAttendancetype = {};

      let students = (this.students = report.students);
      await students.forEach((student) => {
        this.studentsObjects[student.id] = student;
      });

      let attendanceDates = (this.attendanceDates = report.attendanceDates);

      let dataArray = {};
      await students.forEach((student) => {
        dataArray[student.id] = {};
        attendanceDates.forEach((attendanceDate) => {
          dataArray[student.id][attendanceDate.id] = "";
        });
      });
      courseStudentsAttendancedatesAttendancetype = dataArray;

      let attendances = report.attendances;
      let attendanceTypes = this.attendanceTypesObjects;

      await attendances.forEach((attendance) => {
        if (
          courseStudentsAttendancedatesAttendancetype[attendance.attendee_id] !=
          undefined
        ) {
          courseStudentsAttendancedatesAttendancetype[attendance.attendee_id][
            attendance.attendance_date_id
          ] = attendanceTypes[attendance.attendance_type_id].code;
        }
      });

      this.courseStudentsAttendancedatesAttendancetype = courseStudentsAttendancedatesAttendancetype;
      await this.attendanceDates.forEach((date) => {
        const modifiedDate = date.date_time;
        date.date_time = moment(modifiedDate);
      });

      await this.sortedArray();
      await this.sortAttendanceByDate();
      this.showCourseStudentsDetailedReport = true;
      this.fetchData = false;
    },

    async sortAttendanceByDate() {
      await this.attendanceDates.sort(function(a, b) {
        return new Date(a.date_time) - new Date(b.date_time);
      });
    },

    async sortedArray() {
      //  await this.students.sort(function (a, b) {
      //     return (
      //       a.pivot.roll_number - b.pivot.roll_number ||
      //       a.first_name.localeCompare(b.first_name)
      //     );
      //   });
      return;
    },

    async getCourseStudentsReport() {
      let postData = {
        start_date: this.reports.from,
        upto_date: this.reports.to,
        // usersubgroup: 13,
        course: this.reports.id,
      };

      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/attendancereports/coursestudents/detailed`;
      let reports = [];
      await axios
        .post(url, postData)
        .then((response) => {
          reports = response.data;
          response;
        })
        .catch((error) => {
          //handle 422------------------------------------------------------------------------------------------------------------------
          error;
        });

      return reports;
    },
    async getSessions() {
      const url = this.$store.getters.getAPIKey.createSessions;
      await axios.get(url).then((response) => {
        response;
        let sessions = response.data;
        sessions.forEach((session) => {
          this.$set(this.sessionsObjects, session.id, session);
          // this.sessionsObjects[session.id] = session;
        });

        this.sessions = sessions;
      });
    },

    async prepareStudentsTotelAttendance(
      dateSessionCourseStudentAttendancetype
    ) {
      //calculate Total attendamce
      let studentAttendanceTotel = {}; // maximum and totel attendance of each students

      await dateSessionCourseStudentAttendancetype.forEach((attendance) => {
        studentAttendanceTotel[attendance.student] = {
          totelAttendance: 0,
          maximumAttendance: 0,
        };
      });

      await this.$forceUpdate();

      await dateSessionCourseStudentAttendancetype.forEach((attendance) => {
        if (
          this.attendanceTypesObjects[attendance.attendanceType]
            .positive_report_value == 1
        ) {
          studentAttendanceTotel[attendance.student].totelAttendance += 1;
        }
        studentAttendanceTotel[attendance.student].maximumAttendance += 1;
      });
      await this.$forceUpdate();

      return studentAttendanceTotel;
    },

    async prepareAttendanceAndDates(attendanceDates, attendances) {
      //this will combine attendance date session course student attendavcetype etc
      let attendanceDateArray = {};
      await attendanceDates.forEach((attendanceDate) => {
        attendanceDateArray[attendanceDate.id] = attendanceDate;
      });

      let attendanceArray = [];
      await attendances.forEach((attendance) => {
        attendanceArray.push({
          course: attendanceDateArray[attendance.attendance_date_id].course_id,
          date: attendanceDateArray[attendance.attendance_date_id].date_time,
          session:
            attendanceDateArray[attendance.attendance_date_id].session_id,
          student: attendance.attendee_id,
          attendanceType: attendance.attendance_type_id,
        });
      });

      return attendanceArray;
    },

    async getAttendenceTypes() {
      // this.fetchData = true;
      const url = this.$store.getters.getAPIKey.attendenceType;
      // let attendanceTypes = [];
      await axios.get(url).then((response) => {
        let attendanceTypes = response.data;
        attendanceTypes.forEach((attendenceType) => {
          this.attendanceTypesObjects[attendenceType.id] = attendenceType;
        });

        this.attendanceTypes = attendanceTypes;
        // this.fetchData = false;
      });
      // return attendanceTypes;
    },
  },
};
</script>

<style >
.courseReport .modal-dialog {
  max-width: 100%;
  max-height: 100vh;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  position: fixed;
  z-index: 100000;
}
.courseReport .modal-body {
  overflow: scroll;
}

.reportIcon {
  animation: loadReport 3s linear infinite;
}

@keyframes loadReport {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
