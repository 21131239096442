<template>
  <div>
    <!-- <customDateAttendanceMarking v-if="view == 'custom_date'" 
      @markNewAttendance="markNewAttendanceFromSchedule" /> -->
    <myScheduleAttendanceMarking v-if="viewSchedule" @markNewAttendance="markNewAttendanceFromSchedule" />
    
    <!-- <editCourseAttendanceFormWithReport v-if="view == 'edit_report'" :date="null" /> -->
    <!-- <editSubgroupAttendanceFormWithReport v-if="view == 'edit_subgroup_report'" :date="null" /> -->
  </div>
</template>

<script>
// import moment from "moment";
import myScheduleAttendanceMarking from './myScheduleAttendanceMarking.vue';
// import studentsAttendanceMarkingForm from './studentsAttendanceMarkingForm.vue';
// import editCourseAttendanceFormWithReport from './editCourseAttendanceFormWithReport.vue';
// import editSubgroupAttendanceFormWithReport from './editSubgroupAttendanceFormWithReport.vue';
// import customDateAttendanceMarking from './customDateAttendanceMarking';
export default {
  async created() {
    // await this.getSessions();
    this.viewSchedule = true;
  },
  props: [""],
  components: {
    myScheduleAttendanceMarking,
    // studentsAttendanceMarkingForm,
    // editCourseAttendanceFormWithReport,
    // customDateAttendanceMarking,
    // editSubgroupAttendanceFormWithReport,
  },
  data() {
    return {
      viewSchedule: true,
      // view: 'schedule', //[schedule, edit_report, new_attendance_form]
      // sessions: [],
      markAttendanceFor: {
        sessions: [], date: '', course_id: '', confirm_text: ''
      },
      // attendanceRequestFrom: '',
    };
  },
  methods: {
    successAttendanceMarkForm() {
      // this.$bvModal.hide('studentsAttendanceMarkingFormModel');
      // close attendance mark form  & finish
      // this.view = this.attendanceRequestFrom;
      this.markAttendanceFor = {
        sessions: [], date: '', course_id: '', confirm_text: ''
      };
      // this.viewSchedule = true;
    },
    cancelAttendanceMarkForm() {
      // this.$bvModal.hide('studentsAttendanceMarkingFormModel');
      // clear user selected datas if not needed
      //set scgedule view or attendance mark view

      // this.view = this.attendanceRequestFrom;
      this.markAttendanceFor = {
        sessions: [], date: '', course_id: '', confirm_text: ''
      };
      // this.viewSchedule = true;
    },
    markNewAttendanceFromSchedule(selectedSlots) {
      // this.viewSchedule = false;
      // console.log(selectedSlots);
      this.markAttendanceFor.sessions = selectedSlots.sessions;
      this.markAttendanceFor.date = selectedSlots.date;
      this.markAttendanceFor.course_id = selectedSlots.course_id;
      this.markAttendanceFor.confirm_text = selectedSlots.confirm_text;
      // set markAttendanceFor
      // this.attendanceRequestFrom = this.view;
      // this.view = 'new_attendance_form';
      // this.$bvModal.show('studentsAttendanceMarkingFormModel');
    },
    async getSessions() {
      // this.sessionObjects = {};
      const url = this.$store.getters.getAPIKey.createSessions;
      await this.$axios
        .get(url)
        .then((response) => {
          this.sessions = response.data;
          // this.sessions.forEach(session => {
          //   this.sessionObjects[session.id] = session;
          // });
        })
        .catch((error) => {
          error;
        });
    },
  },
};
</script>

<style  >
.markAttendence .modal-dialog {
  max-width: 100%;
  max-height: 100vh;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  position: fixed;
}

.markAttendence .modal-body {
  overflow: scroll;
}
</style>
