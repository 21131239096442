<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="coursesOptions" v-else>
      <b-row>
        <b-col md="12">
          <b-card>
            <div class="courseDetails d-flex justify-content-between flex-wrap">
              <div class="name d-flex">
                <h6 class="text-dark">Name:</h6>
                <h6 class="text-info ml-2">
                  {{ courseInfo.name }} ({{ courseInfo.code }})
                </h6>
              </div>

              <div class="subgroup d-flex">
                <h6 class="text-dark">
                  {{
                  getCustomNames.userSubgroup.custom_name != null
                  ? getCustomNames.userSubgroup.custom_name
                  : "Usersubgroup"
                  }}
                  Code -
                  {{
                  getCustomNames.userGroup.custom_name != null
                  ? getCustomNames.userGroup.custom_name
                  : "Usergroup"
                  }}
                  Code :
                </h6>
                <h6 class="text-info ml-2">
                  {{ courseInfo.usersubgroup.code }} -
                  {{ courseInfo.usersubgroup.usergroup.code }}
                </h6>
              </div>
              <div class="code d-flex">
                <h6 class="text-dark">Academic Year:</h6>
                <h6 class="text-info ml-2">{{ courseInfo.academic_year }}</h6>
              </div>
            </div>
          </b-card>
          <br />
        </b-col>

        <b-col md="2">
          <b-list-group>
            <b-list-group-item :active="currentOption == optionIndex ? true : false"
              v-for="(option, optionIndex) in coursesOptions" :key="optionIndex"
              @click="switchCourseComponent(option.component, optionIndex)">
              {{ option.text }}
            </b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col md="10">
          <component v-bind:is="currentComponent" :courseInfo="course" :course_id="course_id" :courseId="courseId"
            :courseInstructor="courseInstructor" :usergroupId="usergroupId" :editCourseComponents="editCourseComponents"
            @CourseUpdated="getAdditionalCourseInfo()"></component>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import LessonPlanListing from "./lesson_plan/LessonPlanListing.vue";
import CourseResourcelink from "./course_resourcelink/resourcelinks.vue";
import Outcome from "./program_outcome/Outcome.vue";
import Module from "./module/Module.vue";
import CoPoPsoTimetable from "./copopsomapping/CoPoPsoTimetable.vue";
import UniversityExamConfig from "../university_exam/UniversityExamConfig.vue";
import TextBookReferesnces from "./TextBookReferesnces.vue";
import AdditionalInfo from "./AdditionalInfo";
import StudentsGrouping from "./students_grouping/studentsgrouping.vue";
import AttendancetypeLimit from "./attendancetypeLimit.vue";
import LabDashboard from "../../../courseLab/LabDashboard.vue";
import SeminarDashboard from "../../../courseSeminar/SeminarDashboard.vue";
import ProjectDashboard from "../../../courseProject/ProjectDashboard.vue";
TextBookReferesnces;

export default {
  props: [
    "courseInfo",
    "course_id",
    "courseId",
    "courseInstructor",
    "usergroupId",
    "editCourseComponents",
    "studentTab",
    "course_type",
  ],
  async created() {
    console.log(this.courseInfo);
    this.fetchData = true;
    if (this.$store.getters.defaultInstitute.userRole == "Student") {
      this.coursesOptions = [
        { text: "Text Book & References", component: "TextBookReferesnces" },
        { text: "Course Outcome", component: "Outcome" },
        { text: "Course Syllabus", component: "Module" },
        // { text: "CO-PO/PSO Mapping", component: "CoPoPsoTimetable" },
        { text: "Additional Info", component: "AdditionalInfo" },
        { text: "Resource & Links", component: "CourseResourcelink" },
      ];
    } else {
      this.coursesOptions = [
        { text: "Attendance type Limits", component: "AttendancetypeLimit" },
        //  { text: "LABORATORY", component: "LabDashboard" },
         { text: "SEMINAR", component: "SeminarDashboard" },
        //  { text: "PROJECT", component: "ProjectDashboard" },
        { text: "Text Book & References", component: "TextBookReferesnces" },
        { text: "Course Outcome", component: "Outcome" },
        { text: "Course Syllabus", component: "Module" },
        { text: "Lesson Plans", component: "LessonPlanListing" },
        { text: "CO-PO/PSO Mapping", component: "CoPoPsoTimetable" },
        {
          text: "University Exam",
          component: "UniversityExamConfig",
        },
        { text: "Additional Info", component: "AdditionalInfo" },
        { text: "Resource & Links", component: "CourseResourcelink" },
        { text: "Students grouping", component: "StudentsGrouping" },
      ];
      if(this.course_type =='laboratory' || this.courseInfo.enable_laboratory==1 ) 
      this.coursesOptions.splice(1, 0, { text: "LABORATORY", component: "LabDashboard" });
      if(this.course_type =='project') 
      this.coursesOptions.splice(1, 0, { text: "PROJECT", component: "ProjectDashboard" });
    }
    this.currentComponent = this.coursesOptions[0].component;
    await this.getAdditionalCourseInfo();
    this.fetchData = false;
    // console.log(this.coursesOptions);
  },
  components: {
    LessonPlanListing,
    Outcome,
    Module,
    CoPoPsoTimetable,
    UniversityExamConfig,
    TextBookReferesnces,
    AdditionalInfo,
    CourseResourcelink,
    StudentsGrouping,
    AttendancetypeLimit,
    LabDashboard,
    SeminarDashboard,
    ProjectDashboard
  },
  data() {
    return {
      fetchData: false,
      currentOption: 0,
      currentComponent: "",
      course: {},
      coursesOptions: [],
    };
  },
  computed: {
    getCustomNames() {
      return this.$store.getters.getCustomNamings;
    },
  },
  methods: {
    async getAdditionalCourseInfo() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/courses/${this.courseInfo.id}?course_property=1`;
      await this.$axios.get(url).then((res) => {
        this.course = res.data;
      });
    },
    switchCourseComponent(selectedComponent, optionIndex) {
      this.currentComponent = selectedComponent;
      this.currentOption = optionIndex;
    },
  },
};
</script>

<style >
.coursesOptions .list-group .list-group-item {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
  cursor: pointer;
  background-color: #007bff;
  color: #ffffff;
  border-radius: 10px;
}

.coursesOptions .list-group .list-group-item.active {
  color: #007bff;
  background-color: #ffffff;
}
</style>
