<template>
  <div class="institutionProfile">
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <b-row v-else>
      <b-col md="12">
        <p class="text-info" v-if="institution_users.length != 0">
          Note: Only approved users will be listed.
        </p>
        <b-form-checkbox switch v-model="tableView">Table View</b-form-checkbox>
        <div class="d-flex justify-content-center mb-3" v-if="!tableView">
          <b-button type="submit" size="md" variant="primary" @click="prepareCreatePorfile()"
            v-if="userProfiles.length != 0" :disabled="profileButton.disabled">{{ profileButton.text }}</b-button>

          <b-button size="md" @click="skipProfile()" :disabled="profileButton.disabled" variant="danger" class="ml-3">
            Cancel</b-button>
        </div>
      </b-col>
      <b-col md="3">
        <div class="institutionUsers">
          <b-list-group>
            <b-list-group-item class="bg-white" v-if="institutionApprovedUsers.length == 0">
              <p class="text-danger">No Users Found!</p>
            </b-list-group-item>
            <b-list-group-item v-for="(user, userIndex) in institutionApprovedUsers" :key="userIndex"
              :disabled="tableView ? true : false" :active="activeProfile == userIndex ? true : false" :class="
                userIndex != 0
                  ? 'btn m-0 p-0 text-center mt-2'
                  : 'btn m-0 p-0 text-center'
              " @click="getProfile(user, userIndex)">
              <p>{{ user.first_name || " " }} {{ user.last_name || " " }}</p>
            </b-list-group-item>
          </b-list-group>
        </div>
      </b-col>
      <b-col md="9">
        <InstitutionUserTable v-if="tableView" :users="userProfiles" :questions="questions"
          :questionsAns="questionsAns" />
        <div v-else>
          <div class="text-right">
            <b-button variant="info" class="editBtn mb-1" @click="editProfiles = !editProfiles">{{ editProfiles ?
                "Cancel" : "Edit"
            }}</b-button>
          </div>
          <div class="noProfiles" v-if="userProfiles.length == 0">
            <p class="text-danger text-center">No users selected!</p>
          </div>

          <div id="institutionProfile" v-else v-for="(user, userIndex) in userProfiles" :key="userIndex"
            class="mt-2 bg-white mb-3">
            <div class="userProfile border border-info rounded p-2">
              <h6 class="title text-center mb-2 text-info">
                Institution Profile({{ user.institution_role.name }})
              </h6>
              <div class="profile">
                <div class="info d-flex flex-wrap align-items-center">
                  <div class="heading">First Name</div>
                  <div class="input">
                    <b-input v-model="user.first_name" :class="{ 'is-invalid': responseErrors.first_name }"
                      placeholder="First Name" v-if="editProfiles"></b-input>
                    <span v-else class="text-secondary font-weight-bolder">{{
                        user.first_name
                    }}</span>
                  </div>
                </div>

                <div class="info d-flex flex-wrap align-items-center">
                  <div class="heading">Last Name</div>
                  <div class="input">
                    <b-input v-model="user.last_name" :class="{ 'is-invalid': responseErrors.last_name }"
                      placeholder="Last Name" v-if="editProfiles"></b-input>
                    <span v-else class="text-secondary font-weight-bolder">{{
                        user.last_name || ""
                    }}</span>
                  </div>
                </div>

                <div class="info d-flex flex-wrap align-items-center">
                  <div class="heading">Email</div>
                  <div class="input">
                    <b-input v-model="user.email" :class="{ 'is-invalid': responseErrors.email }" placeholder="Email"
                      v-if="editProfiles"></b-input>
                    <span v-else class="text-secondary font-weight-bolder">{{
                        user.email
                    }}</span>
                  </div>
                </div>

                <div class="info d-flex flex-wrap align-items-center">
                  <div class="heading">Mobile</div>
                  <div class="input">
                    <b-input v-model="user.mobile" :class="{ 'is-invalid': responseErrors.mobile }" placeholder="Mobile"
                      v-if="editProfiles"></b-input>
                    <span v-else class="text-secondary font-weight-bolder">{{
                        user.mobile
                    }}</span>
                  </div>
                </div>

                <div class="info d-flex flex-wrap align-items-center" v-if="user.institution_role.name == 'student'">
                  <div class="heading">Guardian Mobile.</div>
                  <div class="input">
                    <b-input v-model="profile.guardian_mobile" placeholder="Guardian Mobile." v-if="editProfiles">
                    </b-input>
                    <span v-else class="text-secondary font-weight-bolder">{{
                        user.mobile
                    }}</span>
                  </div>
                </div>

                <div class="info d-flex flex-wrap align-items-center" v-if="user.institution_role.name == 'student'">
                  <div class="heading">Guardian Email</div>
                  <div class="input">
                    <b-input v-model="profile.guardian_email" placeholder="Guardian Email" v-if="editProfiles">
                    </b-input>
                    <span v-else class="text-secondary font-weight-bolder">{{
                        user.mobile
                    }}</span>
                  </div>
                </div>

                <div class="info d-flex flex-wrap align-items-center">
                  <div class="heading">Date of Birth</div>
                  <div class="input">
                    <b-input v-model="user.birth_date" :class="{ 'is-invalid': responseErrors.birth_date }"
                      placeholder="Date of Birth" v-if="editProfiles"></b-input>
                    <span v-else class="text-secondary font-weight-bolder">{{
                        user.birth_date == null ? "-" : user.birth_date
                    }}</span>
                  </div>
                </div>

                <div class="info d-flex flex-wrap align-items-center">
                  <div class="heading">Admission No.</div>
                  <div class="input">
                    <b-input v-model="user.admission_no" :class="{ 'is-invalid': responseErrors.admission_no }"
                      placeholder="Admission No." v-if="editProfiles"></b-input>
                    <span v-else class="text-secondary font-weight-bolder">{{
                        user.admission_no == null ? "-" : user.admission_no
                    }}</span>
                  </div>
                </div>

                <div class="info d-flex flex-wrap align-items-center">
                  <div class="heading">
                    Board/ <br />
                    University Reg. No.
                  </div>
                  <div class="input">
                    <b-input v-model="user.register_no" :class="{ 'is-invalid': responseErrors.register_no }"
                      placeholder="Board Uni./Reg. No." v-if="editProfiles"></b-input>
                    <span v-else class="text-secondary font-weight-bolder">{{
                        user.register_no == null ? "-" : user.register_no
                    }}</span>
                  </div>
                </div>
              </div>
              <hr class="border rounded border-dark" />
              <!--ADDDRESS-->

              <h6 class="title text-center mb-2 text-info">Address</h6>
              <div class="address">
                <div class="info d-flex align-items-center">
                  <div class="heading">House No.</div>
                  <div class="input">
                    <b-input placeholder="Apartment" v-if="editProfiles"></b-input>
                    <span v-else class="text-secondary font-weight-bolder">Apartment</span>
                  </div>
                </div>

                <div class="info d-flex  align-items-center">
                  <div class="heading">Street</div>
                  <div class="input">
                    <b-input v-model="user.address_street" :class="{ 'is-invalid': responseErrors.address_street }"
                      placeholder="Street" v-if="editProfiles"></b-input>
                    <span v-else class="text-secondary font-weight-bolder">{{
                        user.address_street == null ? "-" : user.address_street
                    }}</span>
                  </div>
                </div>

                <div class="info d-flex  align-items-center">
                  <div class="heading">City</div>
                  <div class="input">
                    <b-input v-model="user.address_city" :class="{ 'is-invalid': responseErrors.address_city }"
                      placeholder="City" v-if="editProfiles"></b-input>
                    <span v-else class="text-secondary font-weight-bolder">{{
                        user.address_city == null ? "-" : user.address_city
                    }}</span>
                  </div>
                </div>

                <div class="info d-flex  align-items-center">
                  <div class="heading">State</div>
                  <div class="input">
                    <b-input v-model="user.address_state" :class="{ 'is-invalid': responseErrors.address_state }"
                      placeholder="State" v-if="editProfiles"></b-input>
                    <span v-else class="text-secondary font-weight-bolder">{{
                        user.address_state == null ? "-" : user.address_state
                    }}</span>
                  </div>
                </div>

                <div class="info d-flex  align-items-center">
                  <div class="heading">Country</div>
                  <div class="input">
                    <b-input v-model="user.address_country" :class="{ 'is-invalid': responseErrors.address_country }"
                      placeholder="Country" v-if="editProfiles"></b-input>
                    <span v-else class="text-secondary font-weight-bolder">{{
                        user.address_country == null ? "-" : user.address_country
                    }}</span>
                  </div>
                </div>

                <div class="info d-flex  align-items-center">
                  <div class="heading">Pincode</div>
                  <div class="input">
                    <b-input v-model="user.address_postcode" :class="{ 'is-invalid': responseErrors.address_postcode }"
                      placeholder="Street" v-if="editProfiles"></b-input>
                    <span v-else class="text-secondary font-weight-bolder">{{
                        user.address_postcode == null
                          ? "-"
                          : user.address_postcode
                    }}</span>
                  </div>
                </div>
              </div>

              <!--Collected DATA-->
              <hr class="border rounded border-dark" />
              <h6 class="title text-center mb-2 text-info">
                Collected User Data
              </h6>
              <UserProfileQuesAns :questionsAns="questionsAns" :questions="questions" :userProfileInfo="user" />
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from "axios";
import UserProfileQuesAns from "./UserProfileQuesAns.vue";
import InstitutionUserTable from "./InstitutionUserTable.vue";
export default {
  props: [
    "userId",
    "institutionUsersList",
    "institution_users",
    "institutionUsersObject",
  ],
  async created() {
    this.fetchData = true;
    await this.instituitionUserDetails();
    await this.addProfile();
    // await this.prepareProfileUsersObject();
    this.fetchData = false;
  },
  components: {
    UserProfileQuesAns,
    InstitutionUserTable,
  },
  data() {
    return {
      fetchData: false,
      institutionApprovedUsers: [],
      selected: undefined,
      activeStatus: [],
      userProfiles: [],
      successResponses: [],
      profile: {},
      profileButton: {
        text: "Submit",
        disabled: false,
      },
      responseErrors: {},
      responseSuccess: {},
      userDetails: {},
      profileName: false,
      userProfileInfo: {},
      // institutionUsersObject: {},
      activeProfile: null,
      tableView: false,
      editProfiles: false,
      questionsAns: {},
      questions: [],
    };
  },
  methods: {
    async getProfile(userInfo, userIndex) {
      this.activeProfile = userIndex;
      const user = {
        id: userInfo.id,
        first_name: userInfo.first_name,
        last_name: userInfo.last_name,
        email: userInfo.user.email,
        mobile: userInfo.mobile,
        birth_date: userInfo.birth_date,
        guardian_mobile: userInfo.guardian_mobile,
        guardian_email: userInfo.guardian_email,
        role: userInfo.institution_role.name,
        admission_no: userInfo.admission_no,
        register_no: userInfo.register_no,
      };
      const profileIndex = await this.userProfiles.findIndex((profile) => {
        return profile.id == user.id;
      });
      this.userProfiles.splice(profileIndex, 1);
      this.userProfiles.unshift(user);
    },
    async instituitionUserDetails() {
      if (this.institution_users.length != 0) {
        await this.institution_users.forEach((user) => {
          if (
            this.institutionUsersObject[user] &&
            this.institutionUsersObject[user].institution_approved == 1
          ) {
            this.institutionApprovedUsers.push(
              this.institutionUsersObject[user]
            );
          }
        });
      }
    },
    async addProfile() {
      await this.institutionApprovedUsers.forEach((userInfo) => {
        this.userProfiles.push(userInfo);
        // this.userProfiles.unshift({
        //   id: userInfo.id,
        //   first_name: userInfo.first_name,
        //   last_name: userInfo.last_name,
        //   email: userInfo.user.email,
        //   mobile: userInfo.mobile,
        //   birth_date: userInfo.birth_date,
        //   guardian_mobile: userInfo.guardian_mobile,
        //   role: userInfo.institution_role.name,
        //   admission_no: userInfo.admission_no,
        //   register_no: userInfo.register_no,
        // });
      });
    },
    async prepareCreatePorfile() {
      this.profileButton.text = "Please Wait...";
      this.profileButton.disabled = true;
      for (let i in this.userProfiles) {
        let profilesData = await {
          first_name: this.userProfiles[i].first_name,
          last_name: this.userProfiles[i].last_name,
          email: this.userProfiles[i].email,
          mobile: this.userProfiles[i].mobile,
          birth_date: this.userProfiles[i].birth_date,
          guardian_mobile:
            this.userProfiles[i].guardian_mobile == null
              ? null
              : this.userProfiles[i].guardian_mobile,
          guardian_email:
            this.userProfiles[i].guardian_email == null
              ? null
              : this.userProfiles[i].guardian_mobile,
          admission_no: this.userProfiles[i].admission_no,
          register_no: this.userProfiles[i].register_no,
          address_street: this.userProfiles[i].address_street,
          address_city: this.userProfiles[i].address_city,
          address_state: this.userProfiles[i].address_state,
          address_country: this.userProfiles[i].address_country,
          address_postcode: this.userProfiles[i].address_postcode,
        };
        let profileCreate = await this.createProfile(profilesData, i);

        if (profileCreate == "Completed") {
          this.responseSuccess["created"] = "PROFILES UPDATED";
          this.$toast.success("Profiles Updated", {
            position: "top",
            duration: 3000,
          });
          this.clearResponse();
        }

        if (!profileCreate) {
          this.$toast.error("Something Went wrong, please check inputs", {
            position: "top",
            duration: 3000,
          });

          this.clearResponse();
          return;
        }
      }
    },
    async createProfile(profilesData, i) {
      const url =
        this.$store.getters.getAPIKey.institutionUserProfile +
        this.userProfiles[i].id;

      let created = null;
      await axios
        .put(url, profilesData)
        .then((response) => {
          if (i == this.userProfiles.length - 1) {
            created = "Completed";
          } else {
            created = "Working";
          }
          response;
        })
        .catch((error) => {
          this.responseErrors = error.response.data.errors;

          created = false;
        });

      return created;
    },
    async removeFromProfileList() {
      if (this.successResponses.length != 0) {
        await this.userProfiles.forEach((profile, index) => {
          const id = profile.id;
          this.successResponses.forEach((success) => {
            if (id == success) {
              this.userProfiles.splice(index, 1);
            }
          });
        });
        this.successResponses = [];
      }
    },
    clearResponse() {
      this.$forceUpdate();
      this.profileButton.text = "Submit";
      setTimeout(() => {
        if (this.responseSuccess["created"]) {
          this.skipProfile();
          this.$parent.institutionUsers();
          this.responseSuccess = {};
        }
        this.responseErrors = {};
        this.profileButton.disabled = false;
      }, 3000);
    },
    skipProfile() {
      this.$emit("profilesFinished");
      this.profile = {};
      this.userDetails = {};
      this.userProfiles = [];
      this.institutionApprovedUsers = [];
    },
  },
};
</script>

<style >
#modifySpecificProfile .modal-dialog {
  max-width: 100%;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  position: fixed;
  z-index: 100000;
}

.modifySpecificProfile .modal-body {
  overflow: scroll;
  background-color: #eeeeeb;
}

#modifySpecificProfile {
  width: 100%;
  text-align: center;
}

.instituitionProfile .modal-dialog {
  max-width: 100%;
  max-height: 100vh;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  position: fixed;
  z-index: 100000;
}

.instituitionProfile .modal-body {
  overflow: scroll;
  background-color: #eeeeeb;
}

.institutionUsers .list-group .list-group-item {
  width: 200px;
  padding: 0px;
  margin: 0px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  margin-bottom: 5px;
  background-color: #0069d9;
  color: #ffffff;
}

.institutionUsers .list-group .list-group-item p {
  margin: 0px;
  padding: 0px;
}

.institutionUsers .list-group .list-group-item.active {
  background-color: #ffffff;
  color: #0069d9;
}

.institutionProfile .col-md-3 {
  flex: 0 0 20%;
  max-width: 20%;
}

#institutionProfile .userProfile .profile .info,
#institutionProfile .userProfile .address .info {
  min-width: 33% !important;
}

#institutionProfile .userProfile .profile,
#institutionProfile .userProfile .address {
  display: flex;
  flex-wrap: wrap;
}

#institutionProfile .userProfile .profile .info,
#institutionProfile .userProfile .address .info {
  margin-bottom: 10px;
}

#institutionProfile .userProfile .profile .info .heading,
#institutionProfile .userProfile .address .info .heading {
  font-size: 14px;
  font-weight: 900;
  min-width: 40%;
}

#institutionProfile .userProfile .profile .info .input,
#institutionProfile .userProfile .address .info .input {
  margin-left: 5px;
  min-width: 40%;
}

#institutionProfile .userProfile .title {
  font-size: 18px;
  font-weight: 900;
}
</style>
