<template>
  <div>
    <div class="settingsMobileView" v-if="mobileView">
      <b-row no-gutters>
        <b-col md="12">
          <h4 class="text-dark mt-2 font-weight-bolder">Settings</h4>
        </b-col>
        <b-col md="12">
          <div class="goBack text-right">
            <p @click="back()" class="btn m-0">
              <i class="fas fa-window-close fa-4x text-danger"></i>
            </p>
          </div>
          <br />
          <settings-mobile-view
            :selectedInstitute="selectedInstitute"
          ></settings-mobile-view>
        </b-col>
      </b-row>
    </div>
    <div class="settings pb-5" v-else>
      <b-container fluid>
        <b-row>
          <b-col md="4">
            <br /><br /><br />
            <b-list-group>
              <b-list-group-item
                :active="currentSetting == settingIndex ? true : false"
                @click="getSettingComponent(setting.component, settingIndex)"
                v-for="(setting, settingIndex) in settingComponents"
                :key="settingIndex"
              >
                {{ settingIndex + 1 }}) {{ setting.name }}
              </b-list-group-item>
            </b-list-group>
          </b-col>
          <b-col md="8">
            <div class="goBack text-right">
              <p @click="back()" class="btn">
                <i class="fas fa-window-close text-danger"></i>
              </p>
            </div>

            <br />
            <component
              @instituteUpdated="instituteUpdated"
              :selectedInstitute="selectedInstitute"
              v-bind:is="currentComponent"
            ></component>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import InstitutionSettingsAttendence from "./InstitutionSettingsAttendence.vue";
import InstitutionSettingsSessions from "./InstitutionSettingsSessions.vue";
import AutoEnrollmentSettings from "./AutoEnrollmentSettings.vue";
import ExamCategory from "./ExamCategory.vue";
// import BoardExamsList from "./BoardExamsList.vue";
import settingsMobileView from "./SettingsMobileView.vue";
import ObeSettings from "./ObeSettings.vue";
import InstituteProfileAttachedQuestions from "./institute_profile_data/InstituteProfileAttachedQuestions.vue";
import SmsTransactions from "./SmsTransactions.vue";
import EditInstitutionDetails from "@/dashboard/EditInstitutionDetails.vue";
import CustomReport from "./custom_report/CustomReport.vue";
import CustomNaming from "./CustomNaming";
import ActivityLog from "./ActivityLog";
// import HostelDashboard from "../../../../hostel/dashboard.vue";
export default {
  props: ["selectedInstitute"],
  created() {
    if (this.screenWidth <= 600) {
      this.mobileView = true;
    } else {
      this.mobileView = false;
    }
  },
  components: {
    institutionAttendence: InstitutionSettingsAttendence,
    institutionSessions: InstitutionSettingsSessions,
    settingsMobileView: settingsMobileView,
    autoEnrollment: AutoEnrollmentSettings,
    ExamCategory,
    ObeSettings,
    // BoardExamsList,
    InstituteProfileAttachedQuestions,
    SmsTransactions,
    EditInstitutionDetails,
    CustomReport,
    CustomNaming,
    ActivityLog,
    // HostelDashboard,
  },
  data() {
    return {
      screenWidth: screen.width,
      mobileView: false,
      currentComponent: "EditInstitutionDetails",
      currentSetting: 0,
      settingComponents: [
        { name: "Institute", component: "EditInstitutionDetails" },
        {
          name: "User Profile",
          component: "InstituteProfileAttachedQuestions",
        },
        { name: "Timetable", component: "institutionSessions" },
        {
          name: "Attendance",
          component: "institutionAttendence",
        },
        { name: "Activities", component: "ExamCategory" },
        {
          name: "Course Settings",
          component: "autoEnrollment",
        },

        { name: "OBE Settings", component: "ObeSettings" },
        // { name: "University Exams", component: "BoardExamsList" },
        { name: "SMS", component: "SmsTransactions" },
        { name: "Custom Report", component: "CustomReport" },
        { name: "Custom Naming", component: "CustomNaming" },
        // { name: "Hostels", component: "HostelDashboard" },
        { name: "Activity Log", component: "ActivityLog" },
      ],
    };
  },
  methods: {
    instituteUpdated(updatedInstitute) {
      this.$emit("updatedSelectedInstitute", updatedInstitute);
    },
    getSettingComponent(componentName, settingIndex) {
      this.currentSetting = settingIndex;
      this.currentComponent = componentName;
    },
    back() {
      this.$store.commit("setInstituteSettings");
    },
  },
};
</script>

<style >
.settings {
  background-color: white;
}

.settings .list-group .list-group-item {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
  cursor: pointer;
}
</style>
