<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="universityExamForm" v-else>
      <b-overlay :show="loadData" spinner-variant="primary" spinner-type="grow" spinner-small rounded="md">
        <b-form @submit.prevent="prepareToUploadScores">
          <h4 class="mb-2 text-center text-info" style="font-size: 20px; font-weight: 600">
            Update CO Scores for exam: {{ boardExam.name }}, {{ course != null ? 'Course:' + course.name : '' }}
          </h4>

          <div class="d-flex" v-if="(multipleEvaluation && evaluationType == null)">
            <b-form-radio v-model="evaluationType" size="md" :value="'gradeEvaluation'">Grade evaluation</b-form-radio>
            <b-form-radio v-model="evaluationType" class="ml-4" size="md" :value="'coEvaluation'">CO wise score
              evaluation
            </b-form-radio>
          </div>

          <div class="m-1 row" v-if="evaluationType == 'coEvaluation'">
            <div>Import excel file( <b-link @click="$bvModal.show('importExcelSampleModel1')">Sample excel format</b-link> ):</div>
            <b-form-file v-model="importExcelFile" accept=".xlsx" size="sm" @change="importScoreExcel"
              placeholder="Choose an excel file or drop it here..." drop-placeholder="Drop file here...">
            </b-form-file>
            <b-modal id="importExcelSampleModel1" size="md" title="Import excel for score" hide-footer>
              <div>
                <p style="font-size: 14px; font-weight: 600">Scores Excel Sheet</p>
                <h6 style="font-size: 14px; font-weight: 900">
                  Note: Please do not include headings in the spreadsheet used to import data.
                  Enter only the values.
                </h6>

                <p>Sample excel format to import marks</p>
                <img src="../../../../assets/images/scores.png" alt="" class="img-fluid mx-auto d-block" />
                <br>
              </div>
            </b-modal>
          </div>
          <div class="m-1 row" v-if="evaluationType != ''">
            <div>Exam Date:</div>
            <b-form-datepicker placeholder="Pick Date" size="sm" v-model="exam_date"
              :date-format-options="{ day: 'numeric', month: 'numeric', year: '2-digit' }">
            </b-form-datepicker>
          </div>
          <b-form-group>
            <div class="table-responsive">
              <table class="table table-bordered table-striped">
                <thead class="thead-dark">
                  <tr v-if="evaluationType == 'gradeEvaluation'">
                    <th></th>
                    <th></th>
                    <th>Grade</th>
                  </tr>
                  <tr v-if="evaluationType == 'coEvaluation'">
                    <th></th>
                    <th></th>
                    <th v-for="(co, courseIndex) in courseoutcomes" :key="courseIndex">
                      {{ co.code }}
                      <b-form-input v-model="co_max_scores[co.id]" class="text-center"
                        placeholder="Max. Score"></b-form-input>
                    </th>
                    <!-- <th>Total</th> -->
                  </tr>
                </thead>
                <tbody v-if="evaluationType == 'gradeEvaluation'">
                  <tr v-for="(student, studentIndex) in students" :key="studentIndex">
                    <td class="bg-dark text-white">
                      {{ student.roll_number }}
                    </td>
                    <td class="bg-dark text-white">
                      {{ student.name }} <br />
                      {{ student.register_no }}
                    </td>
                    <td>
                      <b-form-input v-model="grades[student.id]" class="text-center" placeholder="Score"></b-form-input>
                    </td>
                  </tr>
                </tbody>
                <tbody v-if="evaluationType == 'coEvaluation'">
                  <tr v-for="(student, studentIndex) in students" :key="studentIndex">
                    <td class="bg-dark text-white">
                      {{ student.roll_number }}
                    </td>
                    <td class="bg-dark text-white">
                      {{ student.name }} <br />
                      {{ student.register_no }}
                    </td>
                    <td v-for="(co, courseIndex) in courseoutcomes" :key="courseIndex">
                      <b-form-input v-model="scores[student.id][co.id]" class="text-center"
                        placeholder="Score"></b-form-input>
                    </td>
                    <!-- <td>{{studentsTotalScore[student.id].score}}/{{studentsTotalScore[student.id].max}}</td> -->
                  </tr>
                </tbody>
              </table>
            </div>
          </b-form-group>

          <b-form-group>
            <div class="d-flex justify-content-between">
              <b-button variant="primary" type="submit" size="sm">Save</b-button>
              <b-button variant="danger" size="sm" @click="finish()">Close
              </b-button>
            </div>
          </b-form-group>
        </b-form>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import readXlsxFile from "read-excel-file";
export default {
  props: ["boardExam", "course", "courseId", "multipleEvaluation"],
  async created() {
    this.fetchData = true;
   /* if (!this.multipleEvaluation) {
      this.evaluationType = 'coEvaluation';
    }*/
    if(this.boardExam.mark_entry_type=='subject_wise'){
      this.evaluationType = 'gradeEvaluation';
    }
    if(this.boardExam.mark_entry_type=='co_wise'){
      this.evaluationType = 'coEvaluation';
    }
    this.course_id = this.courseId != null ? this.courseId : this.course.id;
    await Promise.all([
      this.getCourseoutcomes(),
      this.getStudents(),
      this.getScores(),
    ]);

    this.courseoutcomes.forEach(co => {
      this.co_max_scores[co.id] = null;
      if (co.id in this.co_max_scores_response) this.co_max_scores[co.id] = this.co_max_scores_response[co.id];
    });

    this.students.forEach(student => {
      this.scores[student.id] = {};
      this.grades[student.id] = null;
      this.courseoutcomes.forEach(co => {
        this.scores[student.id][co.id] = '';
      });
    });
    this.scoresResponse.forEach(score => {
      if (score.student_id in this.scores) {
        if (score.co_score != null) {
          for (const coId in score.co_score) {
            const scoreOfCo = score.co_score[coId];
            if (coId in this.scores[score.student_id])
              this.scores[score.student_id][coId] = scoreOfCo;
          }
          this.evaluationType = 'coEvaluation';
        } else {
          this.evaluationType = 'gradeEvaluation';
          this.grades[score.student_id] = score.grade;
        }
      }
    });
    // get courses with enrolled stud ids avilable 
    // get students
    // get grades of university exam

    // if need open one of course co score evaluation popup


    this.fetchData = false;
  },
  data() {
    return {
      course_id: null,
      fetchData: false,
      loadData: false,
      courseoutcomes: [],
      students: [],
      scoresResponse: [],
      scores: {},
      grades: {},
      evaluationType: null,//coEvaluation, gradeEvaluation
      importExcelFile: null,
      exam_date: null,
      co_max_scores: {},
      co_max_scores_response: {},
    };
  },
  methods: {
    async importScoreExcel(event) {
      let excelData = [];
      let error = false;
      await readXlsxFile(event.target.files[0]).then((data) => {
        excelData = data;
      }).catch(() => {
        error = true;
        this.$toast.error("Error: unsupported file. Please make sure the file extension is '.xlsx' / please check the file again", {
          position: "top",
          duration: 7000,
        });
      });
      if (excelData.length > this.students.length || excelData[0].length > this.courseoutcomes.length) {
        this.$toast.error(`Error: Excel row count(${excelData.length}) and column count(${excelData[0].length}) should be less than or equal to students count(${this.students.length}) and courseoutcomes count(${this.courseoutcomes.length})`, {
          position: "top",
          duration: 7000,
        });
        error = true;
      }

      for (let row = 0; row < this.students.length; row++) {
        if (row >= excelData.length) { break; }
        for (let col = 0; col < this.courseoutcomes.length; col++) {
          if (col >= excelData[row].length) { break; }
          if (isNaN(excelData[row][col]) && excelData[row][col] != null) {
            this.$toast.error(`Score in Row: ${row + 1}  Col: ${col + 1} (${excelData[row][col]}) is not a Number. or make it empty`, {
              position: "top",
              duration: 7000,
            });
            error = true;
            row = row + this.students.length;
            break;
          }
          this.scores[this.students[row].id][this.courseoutcomes[col].id] = excelData[row][col];
        }
      }

      this.importExcelFile = null;
      this.$forceUpdate();
      if (!error) this.$toast.success("Imported successfully, please save the data.", {
        position: "top",
        duration: 3000,
      });

    },
    async prepareToUploadScores() {
      // this.universityBtn = {
      //   text: "Please wait...",
      //   disabled: true,
      // };
      let postData = {};
      if (this.evaluationType == 'gradeEvaluation') {
        postData = {
          boardexam_id: this.boardExam.id,
          course_id: this.course_id,
          course_scores: [],
          exam_date: this.exam_date,
        };
        for (const student_id in this.grades) {
          const studGrade = this.grades[student_id];
          if (studGrade != "" && studGrade != null) postData.course_scores.push({
            student_id: student_id,
            grade: studGrade,
          });
        }
      } else { // coEvaluation
        postData = {
          boardexam_id: this.boardExam.id,
          course_id: this.course_id,
          // course_scores: [],
          course_co_scores: [],
          co_max_scores:this.co_max_scores,
          exam_date: this.exam_date,
        };
        for (const student_id in this.scores) {
          const studCoScores = this.scores[student_id];
          postData.course_co_scores.push({
            student_id: student_id,
            co_score: studCoScores,
          });
        }
      }


      const uploadStudentScores = await this.storeScoresToDb(postData);

      if (uploadStudentScores) {
        this.$toast.success("Scores Uploaded Successfully", {
          position: "top",
          duration: 3000,
        });
        this.finish();
      }
      if (!uploadStudentScores) {
        this.$toast.error("Something went wrong, please try again", {
          position: "top",
          duration: 7000,
        });
      }

      // this.universityBtn = {
      //   text: "Submit",
      //   disabled: false,
      // };
    },

    async storeScoresToDb(postData) {
      let status = null;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/boardexamscores`;
      await this.$axios
        .post(url, postData)
        .then((response) => {
          status = true;
          response;
        })
        .catch((error) => {
          status = false;
          error;
        });
      return status;
    },

    async getCourseoutcomes() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/course/${this.course_id}/courseoutcomes`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.courseoutcomes = response.data;
        })
        .catch((error) => {
          error;
        });
    },
    async getStudents() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/course/${this.course_id}/students`;
      await this.$axios
        .get(url)
        .then((response) => {
          let students = response.data[0];
          // courses with enrolled users ,
          students.forEach(student => {
            // if (student.pivot.courserole_id == 2)
            this.students.push({
              id: student.institution_user_id,
              name: student.first_name + ' ' + student.last_name,
              // admission_no: student.admission_no,
              // register_no: student.register_no,
              roll_number: student.roll_number,
            })
          });
        })
        .catch((error) => {
          error;
        });
    },

    async getScores() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/boardexamscores?boardexam=${this.boardExam.id}&course=${this.course_id}`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.scoresResponse = response.data.scores;
          let dateResponse = response.data.course_exam_dates;
          this.co_max_scores_response = response.data.co_max_scores ?? [];
          if (this.course_id in dateResponse) this.exam_date = dateResponse[this.course_id];
        })
        .catch((error) => {
          error;
        });
    },
    finish() {
      this.$emit("finishUpdateScore");
    },
  },
};
</script>
