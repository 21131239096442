<template>
  <div class="container">
    <br /><br />
    <div class="loading" v-if="loadTimetable">
      <load-data></load-data>
    </div>
    <div class="row" v-else>
      <div class="col-md-12">
        <div class="text-right videoTimetable mb-2">
          <!-- <b-button
            v-if="$store.getters.defaultInstitute.userRole != 'Student'"
            @click="openYoutubeLinks('Timetable')"
            style="backgroundColor: #FF0000; border: none"
            size="sm"
          >
            <i class="fab fa-youtube"></i>
            Video Tutorial
          </b-button> -->
        </div>
        <b-row>
          <b-col>
            <b-form-group>
              <div style="
              border: 2px solid #eeeeeb;
              border-left-style: none;
              border-right-style: none;
            " class="p-2">
                <b-form-select @change="computeCourseScheduleTable(true)" :options="facultyOptions"
                  v-model="selectedFalculty" :disabled="fetchData"></b-form-select>
              </div>
            </b-form-group>
          </b-col>
          <b-col v-if="selectedFalculty">
            <b-button variant="success" @click="downloadMySchedulePdf"> Download Schedule PDF Report</b-button>
          </b-col>
        </b-row>

        <div class="loading" v-if="fetchData">
          <load-data></load-data>
        </div>
        <div class="table-responsive" v-else>
          <table class="table table-striped table-bordered">
            <thead class="thead-dark">
              <tr>
                <th style="width: 10%" class="text-center">
                  <span class="text-white"><i class="fas fa-user-clock"></i></span>
                </th>
                <th v-for="(session, index) in sessions" :key="index" scope="col" style="width: 10%"
                  class="text-center">
                  {{ session.name }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(day, dayIndex) in workdays" :key="dayIndex">
                <td>{{ day.name }} Date</td>

                <td v-for="(session, sessionIndex) in sessions" :key="sessionIndex" style="padding: 2px">
                  <div v-for="(schedule, scheduleIndex) in instructorSchedules[
                    day.id
                  ][session.id]" :key="scheduleIndex" class="d-flex flex-column">
                    <p class="font-weight-bolder" :id="'schedule' + sessionIndex">
                      {{
    schedule.course_code +
    " (" +
    schedule.usersubgroup_code +
    ")"
                      }}
                    </p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <b-list-group></b-list-group>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  async created() {
    this.fetchData = true;
    await this.getInstitutionFaculties();
    await this.getSessions();
    await this.getWorkdays();
    // await this.prepareWorkdayDate();

    await this.computeCourseScheduleTable(false);
    // await this.getInstructorSchedules();
    this.fetchData = false;
    // await this.computeCourseScheduleTable();
    // this.getCourseInstructors();

    await this.$forceUpdate();
  },
  data() {
    return {
      loadTimetable: false,
      fetchData: false,
      instructorSchedules: {},
      sessions: [],
      workdays: [],
      dateOfWorkdays: {},
      selectedFalculty: null,
      facultyOptions: [],
    };
  },

  methods: {

    async downloadMySchedulePdf() {
      // this.loadingPdf=true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/institutionuser/courseschedules/report/pdfexport`;
      await this.$axios
        .post(
          url, { institution_user: this.selectedFalculty }, { responseType: "blob" }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "faculty_personal_schedules.pdf");
          document.body.appendChild(link);
          link.click();
          link.remove();
          this.downloadData = false;
        })
        .catch(() => {
          this.$toast.error('Failed to download', {
            position: "top",
            duration: 7000,
          });
        });
      // this.loadingPdf=false;
    },
    async getInstitutionFaculties() {
      //get institutionfaculties
      const url = this.$store.getters.getAPIKey.instituitionFaculty;
      await axios
        .get(url)
        .then((response) => {
          this.facultyOptions = [
            { value: null, text: "Choose Faculty", disabled: true },
          ];
          const faculties = response.data;
          faculties.forEach(({ id, first_name, last_name }) => {
            this.facultyOptions.push({
              value: id,
              text: first_name.concat(" ", last_name),
            });
          });
          this.facultyOptions.sort((a, b) => a.text.localeCompare(b.text));
          this.$forceUpdate();
          response;
        })
        .catch((error) => {
          error;
        });
    },
    async getInstructorSchedules() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/institutionusers/${this.selectedFalculty}/courseschedules`;

      await axios
        .get(url)
        .then((response) => {
          var instructorSchedules = response.data;
          instructorSchedules.forEach((schedule) => {
            this.instructorSchedules[schedule.workday_id][
              schedule.session_id
            ].push({
              course_id: schedule.course_user.course.id,
              course: schedule.course_user.course.name,
              course_code: schedule.course_user.course.code,
              usersubgroup: schedule.usersubgroup.name,
              usersubgroup_code: schedule.usersubgroup.code,
            });
          });
          response;
          this.fetchData = false;
          this.$forceUpdate();
        })
        .catch((error) => {
          error;
        });
    },

    async getSpecificInstructorSchedules() {
      this.fetchData = true;
      // this.instructorSchedules = {};
      let mainUrl =
        this.$store.getters.getAPIKey.mainAPI +
        "/institutionuser/institutionuser_id/courseschedules";
      const url = mainUrl.replace("institutionuser_id", this.selectedFalculty);

      await axios
        .get(url)
        .then((response) => {
          // this.instructorSchedules = {};
          var instructorSchedules = response.data;
          // this.instructorSchedules = {};
          // this.instructorSchedules = [];

          instructorSchedules.forEach((schedule) => {
            this.instructorSchedules[schedule.workday_id][
              schedule.session_id
            ].push({
              course: schedule.course_user.course.name,
              course_code: schedule.course_user.course.code,
              usersubgroup: schedule.usersubgroup.name,
              usersubgroup_code: schedule.usersubgroup.code,
            });
          });

          response;
          this.$forceUpdate();
          this.fetchData = false;
        })
        .catch((error) => {
          this.fetchData = false;
          error;
        });
    },

    async getSessions() {
      const url = this.$store.getters.getAPIKey.createSessions;
      await axios
        .get(url)
        .then((response) => {
          this.sessions = response.data;
        })
        .catch((error) => {
          error;
        });
    },

    async getWorkdays() {
      const url = this.$store.getters.getAPIKey.createworkdays;
      await axios
        .get(url)
        .then((response) => {
          this.workdays = response.data;
        })
        .catch((error) => {
          error;
        });
    },

    // async prepareWorkdayDate() {
    //   var date = new Date();
    //   var weekdays = [
    //     "Sunday",
    //     "Monday",
    //     "Tuesday",
    //     "Wednesday",
    //     "Thursday",
    //     "Friday",
    //     "Saturday",
    //   ];
    //   await weekdays.forEach(() => {
    //     this.dateOfWorkdays[weekdays[date.getDay()]] = date
    //       .toISOString()
    //       .slice(0, 10);
    //     date.setDate(date.getDate() - 1); //make a day before
    //   });
    // },

    async computeCourseScheduleTable(specificSchedule) {
      await this.workdays.forEach((day) => {
        this.instructorSchedules[day.id] = {};
        this.sessions.forEach((session) => {
          this.instructorSchedules[day.id][session.id] = [];
        });
      });
      if (specificSchedule) {
        this.getSpecificInstructorSchedules();
      }
    },
  },
};
</script>
<style scoped>
.buttons {
  margin-top: 35px;
}

.videoTimetable i {
  font-size: 10px !important;
}
</style>
